import type { ReactNode } from 'react'
import React from 'react'
import styles from './index.module.less'

interface IStatsProps {
  label?: ReactNode
  value: number
}

const Stats: React.FC<IStatsProps> = (props) => {
  const { label, value } = props

  return (
    <div className={styles.root}>
      <p className={styles.value}>{value || 0}</p>
      {label ? <p className={styles.label}>{label}</p> : null}
    </div>
  )
}

Stats.displayName = 'Stats'
export default React.memo(Stats)
