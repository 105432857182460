import { formatCurrency } from '../../utils/currency'
import { awaitConfirm } from '@/components/ModalConfirmWithPromise'
import { PaymentTypeEnum } from '@/constant'
import { useSelector } from '@/store'
import type { FormInstance } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const useConfirmCustomFreightShipment = (form: FormInstance) => {
  const { t } = useTranslation()
  const codValue = useSelector((state) => state.newServiceProvider.value)
  const osFreightErrorCode = useSelector((state) => state.newServiceProvider?.props?.osFreightErrorCode)

  const confirmCustomFreightShip = useCallback(
    async (type?: 'edit' | 'ship') => {
      const {
        provider_cod_amount,
        provider_cod_amount_target,
        provider_cod_currency_target,
        provider_custom_freight_currency_code,
        provider_custom_freight,
        provider_payment_type,
        provider_cod_currency_code,
      } = form.getFieldsValue()

      // no cod, skip the following steps
      if (PaymentTypeEnum.cod !== provider_payment_type) {
        return Promise.resolve()
      }

      const codCurrencyConverted = codValue?.codCurrencyConverted

      const finalCodCurrencyCode = codCurrencyConverted ? provider_cod_currency_target : provider_cod_currency_code
      const finalCodAmount = codCurrencyConverted ? provider_cod_amount_target : provider_cod_amount

      const cod = `${finalCodCurrencyCode}${formatCurrency(finalCodAmount)}`
      const freight = `${provider_custom_freight_currency_code}${formatCurrency(provider_custom_freight)}`
      let tips = ''

      /** 计算错误 */
      const errorCode = `freight.calculate.error.${osFreightErrorCode}`

      if (provider_custom_freight && provider_custom_freight_currency_code !== finalCodCurrencyCode) {
        tips = t('freight.confirm.case.difference', {
          cod: cod,
          freight: freight,
        })
      } else if (osFreightErrorCode && t(errorCode) !== errorCode) {
        tips = t('freight.comfirm.case.oneship', {
          cod,
        })
      }

      if (!tips) return

      const isShip = type === 'ship'

      return awaitConfirm({
        throwError: true,
        centered: true,
        title: isShip ? t('freight.confirm.title') : t('freight.confirm.title.save'),
        content: tips,
        okText: isShip ? t('freight.confirm.button') : t('newOrder.save'),
        cancelText: t('common.cancel'),
      })
    },
    [codValue?.codCurrencyConverted, form, osFreightErrorCode, t]
  )
  return {
    confirmCustomFreightShip,
  }
}
