import type { Assign } from 'utility-types'

/** 服务商类型 */
export type ProviderType =
  | 'GHTK'
  | 'JANIO'
  | 'KERRY'
  | 'NINJA_VAN'
  | 'POSLAJU'
  | 'FLASH'
  | '4PX'
  | 'SEN_HONG'
  | 'YTO'
  | 'DHL'
  | 'HCT'
  | 'HKXH'
  | 'HKZEEK'
  | 'KERRY_SOUTHEAST'
  | 'VNP'
  | 'VNP'
  | 'HK_KERRY'
  | 'QXPRESS'
  | 'MYFLASH'
  | 'lalamove'
  | 'PANDAGO'
  | 'UPS'

export type FilterProviderType = {
  value: string
  label: string
}

export type FilterProviderList = Assign<
  FilterProviderType,
  {
    icon
  }
>

/**
 * 可自己申请子账号的服务商
 */
export const SUB_ACCOUNT_COMPANY = ['SEVEN_ELEVEN_B2C', 'FMTB2C', 'HCT', 'UPS']
