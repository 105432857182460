import { useCallback } from 'react'
import { useSelector } from '@/store'
import type { IUniqStoreKey } from './type'

const getUniqPlanStatusKey = (uid: string) => `${uid}_plan_status`

export function useStorePlanStatus() {
  const uid = useSelector((state) => state.user?.info?.uid)

  const getStorePlanStatus = useCallback(
    (storeType: keyof IUniqStoreKey) => {
      const uniqPlanStatusKey = getUniqPlanStatusKey(uid)
      let storePlanData: IUniqStoreKey = null
      try {
        storePlanData = JSON.parse(localStorage.getItem(uniqPlanStatusKey))
      } catch {
        // do nothing
      }
      return storePlanData?.[storeType]
    },
    [uid]
  )

  return {
    getStorePlanStatus,
  }
}
