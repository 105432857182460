import React from 'react'
import { useTranslation } from 'react-i18next'
import { OneButton } from '@yy/one-ui'
import NoFoundPic from '@/static/images/404.png'
import { oneshipLogo } from '@/utils/countryVersion'
import styles from './index.module.less'

export const ErrorPage: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.errorPage}>
      <img src={oneshipLogo} alt="logo" />
      <div className={styles.nofound}>{t('common.error.noFound')}</div>
      <div className={styles.refresh}>{t('common.error.refreshTips')}</div>
      <img src={NoFoundPic} alt="nofound" />
      <div>
        <OneButton
          type="primary"
          onClick={() => {
            window.location.reload()
          }}
        >
          {t('common.refresh')}
        </OneButton>
      </div>
    </div>
  )
}
