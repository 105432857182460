import { useUrlSearch } from '../useUrlSearch'

export function useSourceType() {
  const { sourceType: _sourceType } = useUrlSearch()
  const sourceType = Number(_sourceType)

  return {
    sourceType,
  }
}
