import React from 'react'
import { useTranslation } from 'react-i18next'
import orderEmptyPic from '@/static/images/order-empty.jpg'
import classes from './index.module.less'

type Props = {
  type?: 'valid' | 'invalid'
  onClick?: () => void
}

/**
 * 订单管理完全没数据
 */
export const BatchListValidEmpty: React.FC<Props> = ({ type = 'valid', onClick }) => {
  const { t } = useTranslation()

  const isValid = type === 'valid'

  return (
    <div className={classes.wrapper}>
      <img className={classes.img} width="360" src={orderEmptyPic} alt="" />
      <div className={classes.text}>
        {isValid ? (
          <>
            {t('order.batch.list.empty.valid')}
            <span onClick={onClick} className={classes.editBtn}>
              {t('order.batch.list.empty.edit')}
            </span>
          </>
        ) : (
          <>{t('order.batch.list.empty.invalid')}</>
        )}
      </div>
    </div>
  )
}
