import type { RouteConfig } from 'react-router-config'
import { Order } from '@yy/one-icon'
import { withAsync } from '@/components/Async'
import { withAuthRedirect } from '@/components/Authorized/hoc/withAuthRedirect'
import Blank from '@/layouts/Blank'

const routes: RouteConfig[] = [
  {
    alias: 'bill_management',
    path: '/billManagement',
    component: withAuthRedirect(Blank, { first: '/billManagement/codBill' }),
    menuTitle: 'codBill.billManagement',
    icon: Order,
    permissionCode: 'bills',
    routes: [
      // COD账单管理
      {
        alias: 'cod_bill_management',
        path: '/codBill',
        permissionCode: 'bills:codBill',
        component: withAsync(() => import(/* webpackChunkName: "SellingLink" */ '@/containers/BillManagement/CodBill')),
        menuTitle: 'codBill.codBillManagement',
        exact: true,
      },

      {
        alias: 'freight_bill_management',
        path: '/freight-bill',
        permissionCode: 'bills:freightBill',
        component: withAsync(() => import('@/containers/BillManagement/FreightBill')),
        menuTitle: 'bill.page.title',
        exact: true,
      },

      {
        alias: 'freight_bill_detail_management',
        path: '/freight-bill/:id',
        component: withAsync(() => import('@/containers/BillManagement/FreightBillDetail')),
        hideMenu: true,
      },
    ],
  },
]

export default routes
