import type { HeaderType } from './constant'
import { HeaderKeysMap, SettingHeaderMap, SettingSortMap, UnSelectKeysMap } from './constant'

export interface ColumnKey {
  column: string
  isSelect: boolean
}

export function formatKeys(columnList: ColumnKey[], type: HeaderType) {
  columnList = columnList || []
  const clientHeaderKeys = HeaderKeysMap[type]
  const unSelectKeys = UnSelectKeysMap[type]
  // 排序列表
  const sortKeys = columnList.map((item) => item.column)
  // 勾选列
  const selectKeys = columnList?.filter((item) => !!item?.isSelect).map((item) => item.column)

  /**
   * 新增的表头
   * @description
   * 兼容前端后期新增的列，不在初始返回的数据中，
   * `存量用户`可能存在已有配置，这时后端不可能刷数；
   * 前端通过补全数据让存量用户能增加新表头。
   */
  const salesExtra = clientHeaderKeys.filter((item) => !sortKeys.includes(item))

  // 过滤掉不默认勾选的key
  const extraSelectKeys = salesExtra.filter((item) => !unSelectKeys.includes(item))
  if (salesExtra.length > 0) {
    sortKeys.push(...salesExtra)
  }

  if (extraSelectKeys.length > 0) {
    selectKeys.push(...extraSelectKeys)
  }

  return {
    [SettingHeaderMap[type]]: selectKeys,
    [SettingSortMap[type]]: sortKeys,
  }
}
