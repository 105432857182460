import { useSelector } from '@/store'
import React, { useMemo } from 'react'
import { useMemoizedFn } from '@/hooks/utilsHooks'
import { track } from '@/services/track'
import type { GiveawayParams } from '@/services/track/types'
import { postAddOptEvent } from '@/services/apis/giveaway'
import { REF_OBJECT } from '@/constant'
import type { GiveawayActivityRes } from '@/services/apis/giveaway/type'
import { useMount } from 'ahooks'
import { formatTime } from '@/utils'
import dayjs from 'dayjs'

/** 优惠活动埋点上报 */
export function useGiveawayTracker() {
  const startTimeRef = React.useRef<number>()

  useMount(() => {
    startTimeRef.current = Date.now()
  })
  const countryCode = useSelector((state) => state.user.info.countryCode)
  const createTime = useSelector((state) => state.user.info.createTime)
  const sellerId = useSelector((state) => state.user.info.sellerId)

  // 公共字段
  const trackData = useMemo<GiveawayParams>(
    () => ({
      user_client: 'PC',
      user_country: countryCode,
      register_time: formatTime(createTime, 'yyyy-MM-dd HH:mm:ss'),
      seller_id: sellerId,
    }),
    [countryCode, createTime, sellerId]
  )

  /** 弹窗打开事件 */
  const trackGiveawayModalOpen = useMemoizedFn(() => {
    postAddOptEvent('os_giveaway')
    track('giveawayModalOpen', trackData)
  })

  /** 活动点击事件 */
  const trackGiveawayClick = useMemoizedFn(() => {
    track('giveawayClick', trackData)
  })

  /** 问卷页用户离开事件 */
  const trackGiveawayQuestionnaireLeave = useMemoizedFn((params?: GiveawayParams) => {
    const questionnaire_stay_time = Date.now() - startTimeRef.current
    track('giveawayQuestionnaireLeave', {
      ...trackData,
      questionnaire_stay_time: `${questionnaire_stay_time}ms`,
      ...params,
    })
  })

  return {
    trackGiveawayModalOpen,
    trackGiveawayClick,
    trackGiveawayQuestionnaireLeave,
  }
}

interface GiveawayDetailType extends GiveawayActivityRes {
  /** 数量 */
  qty?: number
  /** 数量的单位 */
  qtyUnit?: string
  /** 优惠活动弹窗图片显示地址 */
  modalPic?: string
  /** 活动赠送金额 */
  price: string
  effectiveDay?: number
}

/** 获取活动信息 */
export function useGetGiveawayDetail(): GiveawayDetailType {
  const { effectiveStartTime, effectiveEndTime, ...activity } = useSelector((state) => state.giveaway.activity || (REF_OBJECT as GiveawayActivityRes))
  const countryCode = useSelector((state) => state.user.info.countryCode)
  const languageType = useSelector((state) => state.language.languageType)
  const userCreateTime = useSelector((state) => state.user.info.createTime)

  // 获取当前优惠金额和币种
  const giveawayDiscountDetail = activity?.discountDetails?.find((item) => item.key === 'os_topup')
  const currentCountryTopup = giveawayDiscountDetail?.topup?.qtyList?.find((item) => item.countryCode === countryCode)

  // 获取当前弹窗图片配置
  const giveawayFeatureDetails = activity?.featureDetails?.find((item) => item.key === 'os_giveaway')
  const { imageUrlPc: defaultLangPic } = giveawayFeatureDetails?.giveaway?.languageList.find((item) => item.isDefault) || {}
  const { imageUrlPc } = giveawayFeatureDetails?.giveaway?.languageList.find((item) => item.language === languageType) || {}
  const effectiveRange = React.useMemo(() => {
    switch (activity.timeType) {
      case 2:
        return {
          effectiveStartTime: dayjs(userCreateTime).format('YYYY-MM-DD'),
          effectiveEndTime: dayjs(userCreateTime)
            .add(activity.countDown ?? 0, 'second')
            .format('YYYY-MM-DD'),
        }
      default:
        return {
          effectiveStartTime,
          effectiveEndTime,
        }
    }
  }, [activity.countDown, activity.timeType, effectiveEndTime, effectiveStartTime, userCreateTime])
  return {
    ...activity,
    ...effectiveRange,
    qty: currentCountryTopup?.qty,

    qtyUnit: currentCountryTopup?.qtyUnit,
    effectiveDay: giveawayDiscountDetail?.topup?.effectiveDay,
    modalPic: imageUrlPc || defaultLangPic,
    price: `${currentCountryTopup?.qty} ${currentCountryTopup?.qtyUnit}`,
  }
}
