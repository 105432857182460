import { useDebounce, useUpdateEffect } from 'ahooks'
import { Form, InputNumber, Space } from 'antd'
import { isEqual, isNil } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OneSelect } from '@yy/one-ui'
import styles from './index.module.less'

export enum IntervalType {
  EqualTo = 1,
  LessThanOrEqualTo,
  GreaterThanOrEqualTo,
  BoundedInterval,
}
export interface IntervalValue {
  intervalType: IntervalType
  intervalValue?: number[]
}

interface IIntervalInput {
  onChange?: (value: IntervalValue) => void
  value?: IntervalValue
  min?: number
  max?: number
}

const IntervalTypeOptions = [
  {
    labelKey: 'common.equal',
    value: IntervalType.EqualTo,
  },
  {
    labelKey: 'common.lessOrEqual',
    value: IntervalType.LessThanOrEqualTo,
  },
  {
    labelKey: 'common.greaterOrEqual',
    value: IntervalType.GreaterThanOrEqualTo,
  },
  {
    labelKey: 'common.boundedInterval',
    value: IntervalType.BoundedInterval,
  },
]

const IntervalInput: React.FC<IIntervalInput> = ({ min, max, value, onChange }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [state, setState] = React.useState<IntervalValue>()
  const dState = useDebounce(state, { wait: 300 })
  React.useEffect(() => {
    setState((state) => {
      if (isEqual(state, value)) return state
      return value
    })
  }, [value])
  useUpdateEffect(() => {
    form.setFieldsValue(state)
  }, [state])

  const input = useMemo(() => {
    return (
      <Form.Item
        noStyle
        shouldUpdate={(prev, curr) => {
          return prev.intervalType !== curr.intervalType
        }}
      >
        {({ getFieldsValue }) => {
          const { intervalType } = getFieldsValue() as IntervalValue
          switch (intervalType) {
            case IntervalType.BoundedInterval:
              return (
                <Form.Item noStyle shouldUpdate={(prev, curr) => prev.intervalValue !== curr.intervalValue}>
                  {({ getFieldsValue }) => {
                    const { intervalValue } = getFieldsValue() as IntervalValue
                    const [start, end] = intervalValue ?? []
                    const required = isNil(start) !== isNil(end)
                    const startMax = (end ?? max ?? Infinity) - 1
                    const endMin = (start ?? min ?? -Infinity) + 1
                    return (
                      <Space split={<div className={styles.split}>~</div>} size={9} align="start">
                        <Form.Item
                          name={['intervalValue', 0]}
                          rules={[
                            {
                              required,
                              message: t('common.input.placeholder'),
                            },
                          ]}
                        >
                          <InputNumber
                            min={min}
                            precision={0}
                            max={startMax}
                            addonBefore={<div className={styles.prefix}>≤</div>}
                            className={styles.rangeInput}
                            placeholder={t('common.input.placeholder')}
                          />
                        </Form.Item>

                        <Form.Item
                          rules={[
                            {
                              required,
                              message: t('common.input.placeholder'),
                            },
                          ]}
                          name={['intervalValue', 1]}
                        >
                          <InputNumber
                            min={endMin}
                            max={max}
                            precision={0}
                            addonAfter={<div className={styles.prefix}>≤</div>}
                            className={styles.rangeInput}
                            placeholder={t('common.input.placeholder')}
                          />
                        </Form.Item>
                      </Space>
                    )
                  }}
                </Form.Item>
              )
            default:
              return (
                <Form.Item noStyle name={['intervalValue', 0]}>
                  <InputNumber precision={0} min={min} max={max} className={styles.numberInput} placeholder={t('common.numberInput.placeholder')} />
                </Form.Item>
              )
          }
        }}
      </Form.Item>
    )
  }, [max, min, t])
  useUpdateEffect(() => {
    onChange?.(dState)
  }, [dState])
  return (
    <Form
      form={form}
      initialValues={{
        intervalType: IntervalType.EqualTo,
      }}
      onValuesChange={(changeValue, value) => {
        if (!isNil(changeValue.intervalType)) {
          form.resetFields([
            ['intervalValue', 0],
            ['intervalValue', 1],
          ])
        } else {
          setTimeout(() => {
            form.validateFields().then(() => {
              setState(value)
            })
          }, 0)
        }
      }}
      className={styles.flexBox}
    >
      <Form.Item name="intervalType" noStyle>
        <OneSelect className={styles.select}>
          {IntervalTypeOptions.map((item) => {
            return (
              <OneSelect.Option value={item.value} key={item.value}>
                {t(item.labelKey)}{' '}
              </OneSelect.Option>
            )
          })}
        </OneSelect>
      </Form.Item>
      {input}
    </Form>
  )
}

export default IntervalInput

type WithPrefix<T extends string, R extends object> = {
  [K in keyof R as `${T}${Capitalize<K & string>}`]: R[K]
}

export const intervalValueTransform = <T extends string>(
  prefix: T,
  value?: IntervalValue
): WithPrefix<
  T,
  | {
      condition: IntervalType
      totalMin?: number
      totalMax?: number
    }
  | undefined
> => {
  if (!value) return
  const { intervalType, intervalValue } = value
  const [start, end] = intervalValue ?? []
  const result = {
    [`${prefix}Condition`]: intervalType,
  }
  switch (intervalType) {
    case IntervalType.EqualTo:
    case IntervalType.GreaterThanOrEqualTo:
      return {
        ...result,
        [`${prefix}Min`]: start,
      }

    case IntervalType.LessThanOrEqualTo:
      return {
        ...result,
        [`${prefix}Max`]: start,
      }
    case IntervalType.BoundedInterval:
      return {
        ...result,
        [`${prefix}Min`]: start,
        [`${prefix}Max`]: end,
      }
  }
}
