import { createSlice } from '@reduxjs/toolkit'
import type * as Types from './types'
import { createSetReducers } from '../storeUtils'
import type { AppDispatch, AppGetState } from '..'
import { postPickGoodOrderQuery } from '@/services/apis/pickGoods'
import type { PickGoodOrderQueryRequest, PickGoodOrderQueryRes } from '@/services/apis/pickGoods/types'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: Types.PickGoodsState = {
  list: [],
  loading: false,
  pageNum: 1,
  pageSize: 25,
  total: null,
  checkedRows: [],
  currentQuery: {},
  totalItemNum: null,
}

const pickGoodsSlice = createSlice({
  name: 'pickGoods',
  initialState,
  reducers: {
    ...createSetReducers(initialState),
    setListByRes(state, { payload }: PayloadAction<PickGoodOrderQueryRes>) {
      return {
        ...state,
        list: payload.data,
        pageNum: payload.pageNum,
        pageSize: payload.pageSize,
        total: payload.total,
        totalItemNum: payload.totalItemNum,
        loading: false,
      }
    },
    resetCheckedRows(state) {
      return {
        ...state,
        checkedRows: [],
      }
    },
  },
})

const actions = pickGoodsSlice.actions

export const { setCheckedRows, resetCheckedRows } = actions

/**
 * 发出请求搜索
 * @param params
 * @returns
 */
const fetchList = (params: PickGoodOrderQueryRequest) => async (dispatch: AppDispatch) => {
  dispatch(actions.setLoading(true))
  try {
    const res = await postPickGoodOrderQuery(params)
    dispatch(actions.setListByRes(res.data))
    dispatch(actions.setCurrentQuery({ ...params }))
  } catch (error) {
    dispatch(actions.setLoading(false))
    throw error
  }
}

/**
 * 页码变更
 * @param page
 * @returns
 */
export const pageChange = (pageNum: number, pageSize: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const currentQuery = getState().pickGoods.currentQuery
  await dispatch(fetchList({ ...currentQuery, pageNum, pageSize }))
}

/**
 * 搜索条件变更变更
 * @param params
 * @returns
 */
export const searchList = (params: PickGoodOrderQueryRequest) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { pageSize } = getState().pickGoods
  await dispatch(fetchList({ ...params, pageNum: 1, pageSize }))
}

/**
 * 用当前搜索条件刷新
 * @param params
 * @returns
 */
export const refreshList = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { currentQuery } = getState().pickGoods
  await dispatch(fetchList({ ...currentQuery }))
}

export default pickGoodsSlice.reducer
