// 浏览器兼容，或者封装浏览器工具函数
import { OneModal } from '@yy/one-ui'

import i18n from '@/services/i18n'

/**
 * 用于避免用户禁用了弹出式窗口，导致我们异步请求后的window.open打开新窗口失败
 * 会弹窗让用户再点击一遍，然后同步调用，就不算弹出式窗口了
 * @param args
 * @returns
 */
export function windowOpen(...args: Parameters<typeof window.open>): Promise<Window> {
  return new Promise((res, ret) => {
    const win = window.open(...args)
    if (win) return res(win)
    // 打开失败了，弹窗让用户重新点一遍
    OneModal.confirm({
      title: i18n.t('common.openPage'),
      onCancel: () => ret('reject'),
      onOk: () => res(window.open(...args)),
      okText: i18n.t('common.confirmBtn'),
      cancelText: i18n.t('common.cancel'),
    })
  })
}

/**
 * 下载文件改名
 * @param url
 * @param name
 */
export const downloadFile = (url: string, name: string) => {
  window.URL = window.URL || window.webkitURL

  const xhr = new XMLHttpRequest(),
    a = document.createElement('a')
  let file

  xhr.open('GET', url, true)
  xhr.responseType = 'blob'
  xhr.onload = function () {
    file = new Blob([xhr.response], { type: 'application/octet-stream' })
    a.href = window.URL.createObjectURL(file)
    a.download = name // Set to whatever file name you want
    // Now just click the link you created
    // Note that you may have to append the a element to the body somewhere
    document.body.appendChild(a)
    // for this to work in Firefox
    a.click()
  }
  xhr.send()
}
