import { uniqBy, omit } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OneInput, OneSelect } from '@yy/one-ui'
import type { IProvinceResVO } from '@/services/apis/address/types'
import { useSelector } from '@/store'
import { filterOptionIncludes } from '@/utils'
import { decodeLanguageAndProvince } from '@/utils/address'
import type { SelectProps } from 'antd/es/select'

export interface INewSelectProps<ValueType = any> extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  list: IProvinceResVO[]
  onValueChange?: SelectProps['onChange']
}

const OBJECT_REF = {}

export const ProvinceSelect: React.FC<INewSelectProps> = (props) => {
  const { list, ...restProps } = props
  const { t } = useTranslation()
  const languageLabMap = useSelector((state) => state.configSlice?.languageLabMap || OBJECT_REF)

  const childrenContent = useMemo(() => {
    if (!list || !list.length) return null

    if (list?.length === 1) {
      return uniqBy(list?.[0]?.nodes || [], 'name')?.map((item) => {
        const value = `${item.language}_${item.name}`

        return (
          <OneSelect.Option title={item.name} name={item.name} language={item.language} key={value} value={value}>
            {item.name}
          </OneSelect.Option>
        )
      })
    }

    return list.map((item) => {
      return (
        <OneSelect.OptGroup language key={item?.language} label={languageLabMap?.[item?.language] || item?.language} title={item.language}>
          {uniqBy(item?.nodes || [], 'name')?.map((item) => {
            const value = `${item.language}_${item.name}`

            return (
              <OneSelect.Option title={item.name} name={item.name} language={item.language} key={value} value={value}>
                {item.name}
              </OneSelect.Option>
            )
          })}
        </OneSelect.OptGroup>
      )
    })
  }, [languageLabMap, list])

  const realProps = useMemo(() => {
    return {
      showSearch: true,
      placeholder: t('common.select.placeholder'),
      filterOption: filterOptionIncludes,
      ...omit(restProps, 'onValueChange'),
    }
  }, [restProps, t])

  // 如果返回的多语言匹配不中，就丢掉后端设置的多语言
  const realValue = useMemo(() => {
    const { language } = decodeLanguageAndProvince(props?.value)

    if (!language) return props?.value

    const languageItem = list.find((item) => item.language === language)
    // detect the value in the list
    const addressValue = languageItem?.nodes?.find((item) => `${item.language}_${item.name}` === props?.value)

    if (addressValue) {
      return props?.value
    }

    return props?.value?.replace(/.*_/, '')
  }, [list, props?.value])

  const onChange = useCallback(
    (...props) => {
      restProps?.onChange?.apply(null, props)
      restProps?.onValueChange?.apply(null, props)
    },
    [restProps?.onChange, restProps?.onValueChange]
  )

  // 没有数据
  if (!list?.length) {
    return <OneInput placeholder={t('common.input.placeholder')} value={realValue} onChange={props?.onChange as any} disabled={realProps.disabled} />
  }

  return (
    <OneSelect {...realProps} onChange={onChange} value={realValue}>
      {childrenContent}
    </OneSelect>
  )
}

export default ProvinceSelect
