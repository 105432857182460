import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { intervalValueTransform } from '@/components/IntervalInput'
import { GET_DEFAULT_PAGE_SIZE } from '@/constant'
import Api from '@/services/http'
import { orderUrl } from '@/services/url'
import type { AppDispatch } from '@/store'
import { setTableLoading } from '@/store/commonSlice'
import { isLogisticsArchive } from '@/utils'
import * as Types from './type'
import { omit } from 'lodash'

const initialState: Types.OrderState = {
  searchRecord: null,
  immediateSearchRecord: null,
  reverseSearchRecord: null,
  list: [],
  pagination: {
    pageSize: GET_DEFAULT_PAGE_SIZE(),
    pageNum: 1,
    total: 0,
    orderByType: 1,
    immediateOrderByType: 1,
    reverseOrderByType: 1,
  },
  orderDetailInfo: null,
  listTabValue: 0,
  listImmediateTabValue: 0,
  listReverseTabValue: 0,
  rowChecked: [],
}

/**
 *
 * @param rows 新的row
 * @param preserveRow store的row
 * @returns
 */
const formatSelectRows = (rows: Types.OrderItemType[], preserveRow: Types.RowCheckedType[]) => {
  return rows.map((row) => {
    // 以前就存过的值，直接返回
    const preserveItem = preserveRow.find((item) => item.logisticsNo === row.logisticsNo)
    if (preserveRow.find((item) => item.logisticsNo === row.logisticsNo)) {
      return preserveItem
    }

    const { extInfo } = row as Types.OrderItemType

    const extInfoObj = JSON.parse(extInfo)
    return {
      ...row,
      shipDate: extInfoObj?.shipDate,
    }
  })
}

const orderSlice = createSlice({
  name: 'logistics',
  initialState,
  reducers: {
    setLogisticsSearchRecord(state, action: PayloadAction<Types.OrderState['searchRecord']>) {
      return {
        ...state,
        searchRecord: action.payload,
      }
    },
    setImmediateLogisticsSearchRecord(state, action: PayloadAction<Types.OrderState['immediateSearchRecord']>) {
      return {
        ...state,
        immediateSearchRecord: action.payload,
      }
    },
    // 逆向物流
    setReverseLogisticsSearchRecord(state, action: PayloadAction<Types.OrderState['reverseSearchRecord']>) {
      return {
        ...state,
        reverseSearchRecord: action.payload,
      }
    },
    setOrderDetailInfo(state, action: PayloadAction<Types.ResOrderDetailType>) {
      return {
        ...state,
        orderDetailInfo: action.payload,
      }
    },
    setPagination(state, action: PayloadAction<Partial<Types.PaginationType>>) {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      }
    },
    setList(state, action: PayloadAction<any>) {
      return {
        ...state,
        list: action.payload,
      }
    },
    setRowChecked(state, action: PayloadAction<Types.OrderItemType[]>) {
      const rowChecked = formatSelectRows(action.payload, state.rowChecked)
      return {
        ...state,
        rowChecked,
      }
    },
    setNewRowChecked(state, action: PayloadAction<Types.OrderItemType[]>) {
      const rowChecked = formatSelectRows(action.payload, [])
      return {
        ...state,
        rowChecked,
      }
    },
    resetRowChecked(state) {
      return {
        ...state,
        rowChecked: [],
      }
    },
    setTabValue(state, action: PayloadAction<{ value: number }>) {
      return {
        ...state,
        listTabValue: action.payload.value,
      }
    },
    setImmediateTabValue(state, action: PayloadAction<{ value: number }>) {
      return {
        ...state,
        listImmediateTabValue: action.payload.value,
      }
    },
    setReverseTabValue(state, action: PayloadAction<{ value: number }>) {
      return {
        ...state,
        listReverseTabValue: action.payload.value,
      }
    },
    resetSearch(state) {
      // 重置时排序方式不需要重置
      const pagination = {
        ...initialState.pagination,
        orderByType: state.pagination.orderByType,
        immediateOrderByType: state.pagination.immediateOrderByType,
      }
      const { rowChecked } = initialState
      return {
        ...state,
        pagination,
        rowChecked,
      }
    },
    reset(state) {
      // 重置时排序方式不需要重置
      const pagination = {
        ...initialState.pagination,
        orderByType: state.pagination.orderByType,
        immediateOrderByType: state.pagination.immediateOrderByType,
      }
      return {
        ...initialState,
        listTabValue: state.listTabValue,
        listImmediateTabValue: state.listImmediateTabValue,
        listReverseTabValue: state.listReverseTabValue,
        searchRecord: state.searchRecord,
        immediateSearchRecord: state.immediateSearchRecord,
        pagination,
      }
    },
  },
})

/** 订单详情 */
export const queryOrderDetail = (data: Types.QueryTrace) => async (dispatch: AppDispatch) => {
  const response = await Api.toast.post<Types.ResOrderDetailType>('/api/order_query/get', data)
  // 如果是即时物流的订单不做任何处理
  if (response.data.onDemand) return response.data
  dispatch(orderSlice.actions.setOrderDetailInfo(response.data))
  return response.data
}

const getQueryField = (type) => {
  // 关键字采用全文搜索
  if (type === 'queryWord') {
    return 'fullSearchText'
  }

  // 发件人信息
  if (type === 'queryField') {
    return 'senderSearchText'
  }
  return ''
}
/** 订单查找 */
export const transformData = (data: Types.SearchFormType, tabValue: number) => {
  if (!data) return

  const archive = isLogisticsArchive(tabValue)
  const {
    search_text,
    search_type,
    time_range,
    shipment_status,
    reverse_status,
    ship_way,
    departure,
    service_provider,
    waybill_status,
    delivery_type_name,
    time_type,
    filter_tag,
    has_insurance,
    logistics_good_number,
    logistics_good_types,
  } = data
  const searchArr = search_text ? search_text.trim().split(/\s+|,/) : []

  const engineSearch = {
    queryWord: search_type === 'queryWord' || search_type === 'queryField' ? search_text : '', // 关键字
    consigneeMobile: search_type === 'consigneeMobile' ? search_text : '', // 收件人手机号
    numberList: search_type === 'numberList' ? searchArr.filter(Boolean) : null, // 多单号混合搜索(orderNo、oneshipNo、expressNo、bulkNo)
    queryField: getQueryField(search_type), // 两个类型 发件信息搜索-senderSearchText， 全文搜索-fullSearchText
  }

  return {
    status: (reverse_status || shipment_status)?.map((item) => item.value),
    paymentType: ship_way?.length === 2 || ship_way?.length === 0 ? null : ship_way?.[0].value,
    allCodes: !search_type ? searchArr : null, // 'all'
    ...engineSearch,
    waybillStatus: waybill_status?.map((item) => item.value), // 面单状态
    companyCodeList: service_provider?.map((item) => item.value), //服务产品编码 承运商号
    startTime: time_range ? time_range[0] : null,
    endTime: time_range ? time_range[1] : null,
    locationName: departure?.map((item) => item.label),
    deliveryTypes: delivery_type_name?.map((item) => item.value), //配送方式
    timeType: time_type,
    tag: archive ? ['ARCHIVED'] : void 0, //归档的tab
    notTag: !archive ? ['ARCHIVED'] : void 0, //除归档的tab
    tagIds: filter_tag?.length ? filter_tag.map((item) => item.value) : null, // 标签
    hasInsurance: has_insurance?.length === 1 ? has_insurance?.[0].value : null, // 是否购买保险
    ...intervalValueTransform('goodsQuantityTotal', logistics_good_number), // 物品数量
    ...intervalValueTransform('goodsTypeTotal', logistics_good_types), // 物品类型
  }
}

/** 获取运单列表 */
export const queryOrderList =
  (params: Types.SearchFormType & Types.SearchPaginationType & { logisticsType?: Types.LogisticsType }) =>
  async (dispatch: AppDispatch, getState): Promise<void> => {
    dispatch(setTableLoading({ loading: true }))

    const { order: orderState } = getState()
    const { orderByType, immediateOrderByType, reverseOrderByType, pageNum: defaultPageNum } = orderState.pagination
    const { pageNum = defaultPageNum, logisticsType, ...searchData } = params

    // 区分lalamove运单排序方式还是普通运单排序方式
    const [curOrderByType, tabValue] = (() => {
      switch (logisticsType) {
        // 逆向物流
        case Types.LogisticsType.reverse: {
          dispatch(setReverseLogisticsSearchRecord({ ...omit(params, 'pageNum'), orderByType: reverseOrderByType }))
          return [reverseOrderByType, orderState.listReverseTabValue]
        }
        // 即时物流
        case Types.LogisticsType.ondemand: {
          dispatch(setImmediateLogisticsSearchRecord({ ...omit(params, 'pageNum'), orderByType: immediateOrderByType }))
          return [immediateOrderByType, orderState.listImmediateTabValue]
        }
      }

      // 普通物流
      dispatch(setLogisticsSearchRecord({ ...omit(params, 'pageNum'), orderByType }))
      return [orderByType, orderState.listTabValue]
    })()

    const trans = transformData(searchData, tabValue) || { status: [] }
    const newData = {
      pageSize: GET_DEFAULT_PAGE_SIZE(),
      pageNum,
      orderByType: curOrderByType,
      updateOrderBy: true,
      ...trans,
      logisticsType,
    }

    try {
      const response = await Api.toast.takeLatest<Types.ResponseQueryType>('queryLogisticsList', 'post', orderUrl.query, newData)
      const { data } = response
      const list = Array.isArray(data.data) ? data.data.map((item, ind) => ({ ...item, ind: `${data.pageNum}_${ind}` })) : []
      dispatch(
        orderSlice.actions.setPagination({
          pageNum: data?.pageNum,
          pageSize: data?.pageSize,
          total: data?.total,
        })
      )
      dispatch(orderSlice.actions.setList(list))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
    dispatch(setTableLoading({ loading: false }))
  }

export const sortSelectRows = (rows: Types.RowCheckedType[]) => {
  return rows.sort((a, b) => {
    const [aPageNum, aInd] = a.ind?.split('_') || []
    const [bPageNum, bInd] = b.ind?.split('_') || []
    // 排序，按页码和下标比较
    if (aPageNum === bPageNum) {
      return Number(aInd) - Number(bInd)
    }
    return Number(aPageNum) - Number(bPageNum)
  })
}
export const {
  setLogisticsSearchRecord,
  setImmediateLogisticsSearchRecord,
  setReverseLogisticsSearchRecord,
  setPagination,
  setOrderDetailInfo,
  setTabValue,
  setImmediateTabValue,
  setReverseTabValue,
  resetSearch,
  reset,
  setRowChecked,
  resetRowChecked,
  setNewRowChecked,
} = orderSlice.actions
export default orderSlice.reducer
