import React from 'react'
import { useTranslation } from 'react-i18next'
import { OneModal } from '@yy/one-ui'
import AddressSelect from './components/AddressSelect'
import Container from './Context'
import { useGetDefaultAddress } from './hooks'
import styles from './index.module.less'
import type { AddressChooseModalProps, IOnFinishParams } from './type'
import { convertSenderAddress, convertSigneeAddress } from './util'

const AddressChooseModal: React.FC<AddressChooseModalProps> = ({ title, senderAddresses, consigneeAddresses, onFinish, onCancel, ...args }) => {
  const { t } = useTranslation()
  const { state: fetchAddressState, fetch: fetchDefaultAddress } = useGetDefaultAddress()

  const { setSenderIndex, senderIndex, consigneeIndex, setConsigneeIndex } = Container.useContainer()

  const titleMap = {
    title: t('mergeAddressModal.title'),
    senderTitle: t('mergeAddressModal.senderTitle'),
    receiveTitle: t('mergeAddressModal.receiveTitle'),
  }

  const onOk = () => {
    const originSenderAddress = senderAddresses?.length ? senderAddresses[senderIndex] : null
    const originConsigneeAddress = consigneeAddresses?.length ? consigneeAddresses[consigneeIndex] : null

    const params: IOnFinishParams = {}

    // 组装发货地址
    if (originSenderAddress) {
      const senderAddress = {
        origin: originSenderAddress?.address,
        address: originSenderAddress?.address ? convertSenderAddress(originSenderAddress?.address) : null,
        orderNo: originSenderAddress?.orderNos?.[0],
      }
      params.senderAddress = senderAddress
    }

    // 组装收货地址
    if (originConsigneeAddress) {
      const consigneeAddress = {
        origin: originConsigneeAddress?.address,
        address: originConsigneeAddress?.address ? convertSigneeAddress(originConsigneeAddress?.address) : null,
        orderNo: originConsigneeAddress?.orderNos?.[0],
      }
      params.consigneeAddress = consigneeAddress
    }
    onFinish?.(params)
  }

  const handleCancel = async () => {
    const res = await fetchDefaultAddress()
    onCancel?.(res?.data)
  }

  return (
    <OneModal
      width={860}
      title={title ?? titleMap.title}
      {...args}
      cancelButtonProps={{
        loading: fetchAddressState.loading,
        ...args.cancelButtonProps,
      }}
      onOk={onOk}
      onCancel={handleCancel}
      okText={t('frame.confirm')}
      cancelText={t('frame.cancel')}
    >
      {/* 发货地址 */}
      {senderAddresses?.length > 0 ? (
        <div className={styles.selectWrap}>
          <div className={styles.title}>{titleMap.senderTitle}</div>

          <AddressSelect
            options={senderAddresses.map((item) => ({
              ...item,
              address: convertSenderAddress(item.address),
            }))}
            onChange={(index) => setSenderIndex(index)}
            value={senderIndex}
          />
        </div>
      ) : null}

      {/* 收货地址 */}
      {consigneeAddresses?.length > 0 ? (
        <div className={styles.selectWrap}>
          <div className={styles.title}>{titleMap.receiveTitle}</div>

          <AddressSelect
            options={consigneeAddresses.map((item) => ({
              ...item,
              address: convertSigneeAddress(item.address),
            }))}
            type="consignee"
            onChange={(index) => setConsigneeIndex(index)}
            value={consigneeIndex}
          />
        </div>
      ) : null}
    </OneModal>
  )
}

AddressChooseModal.displayName = 'AddressChooseModal'
export default (props: AddressChooseModalProps) => {
  return (
    <Container.Provider>
      <AddressChooseModal {...props} />
    </Container.Provider>
  )
}
