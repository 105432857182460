import React, { useCallback, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { ERROR_TYPE_ENUM } from '@/services/sentry/constants'
import { newCustomError } from '@/services/sentry/errorWrapper'

const SourceLoadError: React.FC = () => {
  const errorHandler = useCallback((e) => {
    const target = e.target || e.srcElement

    // js或者css 加载失败
    if (target instanceof HTMLScriptElement || target instanceof HTMLLinkElement) {
      const isScriptLabel = target instanceof HTMLScriptElement
      const message = target.outerHTML

      Sentry.captureException(newCustomError(`Loading ${isScriptLabel ? 'JS' : 'CSS'} failed! ${message}`), (scope) => {
        scope.setTag('type', ERROR_TYPE_ENUM.RESOURCE)
        return scope
      })
    }
  }, [])

  useEffect(() => {
    window.addEventListener('error', errorHandler, true)
    return () => window.removeEventListener('error', errorHandler)
  }, [errorHandler])

  return <></>
}

SourceLoadError.displayName = 'SourceLoadError'
export default SourceLoadError
