import type * as Types from '@/types'

/** 语言种类 */
export type LangType = 'zh-hans-cn' | 'zh-hant-tw' | 'en' | 'zh-hant-hk' | 'ja'

/** 语言 */
export type LanguageType = {
  label: string
  lang: string
  languageType: LangType
  hidden?: boolean
  antd: string
}

/** 语言 */
export const LANGUAGE: Types.Dict<Omit<LanguageType, 'label'>> = [
  {
    label: 'English',
    lang: 'en',
    languageType: 'en',
    antd: 'en_US',
  },
  {
    label: '中文繁體（香港）',
    lang: 'zh-HK',
    languageType: 'zh-hant-hk',
    antd: 'zh_HK',
  },
  {
    label: '中文繁體（台湾）',
    lang: 'zh-TW',
    languageType: 'zh-hant-tw',
    antd: 'zh_TW',
  },
  {
    label: '中文简体',
    lang: 'zh-CN',
    languageType: 'zh-hans-cn',
    antd: 'zh_CN',
  },
  {
    label: 'Japaness',
    lang: 'ja',
    languageType: 'ja',
    antd: 'ja_JP',
  },
]

export const LANGUAGE_MAP = LANGUAGE.reduce((acc, next) => {
  acc[next.lang] = next
  return acc
}, {} as Record<string, LanguageType>)
