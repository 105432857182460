import type { ValuesType } from 'utility-types'
import type * as Types from '@/types'

/** 已取消 */
export const CANCELLED = 0

/** 新建 */
export const TO_BE_DELIVERED = 1

/** 待揽收 */
export const TO_PICK_UP = 2

/** 已签收 */
export const RECEIVED = 3

/** 问题件 */
export const ERROR = 4

/** 已退件 */
export const RETURNED = 5

/** 退货在途 */
export const RETURNING = 6

/** 已揽收 */
export const PROCESS = 21

/** 在途中 */
export const SHIPPING = 22

/** 到达派件城市 */
export const REACHED = 23

/** 派送中 */
export const DELIVERING = 24

/** 已到店 */
export const ARRIVED_STORE = 24002

/** 拒收 */
export const REJECTED = 25

/** 待发货子状态 */
export const TO_BE_DELIVERED_SUB_STATUS = {
  /** 新建 */
  PROCESS: 1000,
  /** 发货失败 */
  FAIL: 1001,
  /** 运营处理中 */
  OPERATION_PROCESS: 1002,
  /** 确认3PL下单结果，重试后仍无法确认结果，标记为3PL处理中 */
  PROCESS_3PL: 1005,
} as const

/**
 * 订单状态
 * @todo
 * 这里可以优化成下面数据结构保持一致
 * 统一使用 Types.Dict
 */
export const ORDER_STATUS = {
  CANCELLED,
  TO_BE_DELIVERED,
  TO_PICK_UP,
  RECEIVED,
  ERROR,
  RETURNED,
  RETURNING,
  PROCESS,
  SHIPPING,
  REACHED,
  DELIVERING,
  REJECTED,
  ARRIVED_STORE,
} as const

type OrderStatus = ValuesType<typeof ORDER_STATUS> | ValuesType<typeof TO_BE_DELIVERED_SUB_STATUS>

/** 订单Tab(筛选专用) */
export const ORDER_TAB: Types.Dict<{ value: OrderStatus[] }> = [
  {
    value: [],
    label: 'logistics.list.all',
  },
  {
    value: [TO_BE_DELIVERED_SUB_STATUS.PROCESS, TO_BE_DELIVERED_SUB_STATUS.FAIL],
    label: `logistics.list.pendingToShip`,
  },
  {
    value: [TO_PICK_UP],
    label: `enums.waybillStatus.${TO_PICK_UP}`,
  },
  {
    value: [PROCESS, SHIPPING, REACHED, DELIVERING],
    label: 'logistics.list.shipping',
  },
  {
    value: [RECEIVED],
    label: 'logistics.list.completed',
  },
  {
    value: [ERROR, REJECTED, RETURNED, RETURNING],
    label: 'logistics.list.unusual',
  },
  {
    value: [CANCELLED],
    label: 'logistics.list.cancelled',
  },
]

/** 订单状态对应的Label */
export const ORDER_STATUS_LABEL: Types.DictLabel<OrderStatus, { color: string }> = {
  // 待发货以及子状态
  [TO_BE_DELIVERED]: {
    label: `enums.logisticStatus.${TO_BE_DELIVERED}`,
    color: 'warning',
  },
  [TO_PICK_UP]: {
    label: `enums.logisticStatus.${TO_PICK_UP}`,
    color: 'warning',
  },
  [PROCESS]: {
    label: 'logistics.list.pickedUp',
    color: 'processing',
  },
  [SHIPPING]: {
    label: 'logistics.list.shipping',
    color: 'processing',
  },
  [DELIVERING]: {
    label: 'logistics.list.delivering',
    color: 'processing',
  },
  [REACHED]: {
    label: 'logistics.list.reached',
    color: 'processing',
  },
  [RECEIVED]: {
    label: 'logistics.list.received',
    color: 'success',
  },
  [REJECTED]: {
    label: 'logistics.list.rejected',
    color: 'default',
  },
  [RETURNING]: {
    label: 'logistics.list.returning',
    color: 'default',
  },
  [RETURNED]: {
    label: 'logistics.list.returned',
    color: 'default',
  },
  [CANCELLED]: {
    label: 'logistics.list.cancelled',
    color: 'default',
  },
  [ERROR]: {
    label: 'logistics.list.error',
    color: 'error',
  },
  [TO_BE_DELIVERED_SUB_STATUS.PROCESS]: {
    label: `enums.logisticStatus.${TO_BE_DELIVERED_SUB_STATUS.PROCESS}`,
    color: 'warning',
  },
  [TO_BE_DELIVERED_SUB_STATUS.OPERATION_PROCESS]: {
    label: 'logistics.list.failed',
    color: 'error',
  },
  [TO_BE_DELIVERED_SUB_STATUS.FAIL]: {
    label: 'logistics.list.failed',
    color: 'error',
  },
  [TO_BE_DELIVERED_SUB_STATUS.PROCESS_3PL]: {
    label: `enums.logisticStatus.${TO_BE_DELIVERED_SUB_STATUS.PROCESS}`,
    color: 'warning',
  },
  [ARRIVED_STORE]: {
    label: 'logistics.list.arrivedStore',
    color: 'warning',
  },
}

export type ShipmentStatusDict = Types.Dict<{ value: OrderStatus }>

/** 运单状态 */
export const SHIPMENT_STATUS: Types.Dict<{ value: OrderStatus }> = [
  {
    value: CANCELLED,
    label: 'logistics.list.cancelled',
  },
  {
    value: TO_BE_DELIVERED_SUB_STATUS.PROCESS,
    label: `enums.logisticStatus.${TO_BE_DELIVERED_SUB_STATUS.PROCESS}`,
  },
  {
    value: TO_BE_DELIVERED_SUB_STATUS.FAIL,
    label: 'logistics.list.failed',
  },
  {
    value: TO_PICK_UP,
    label: `enums.logisticStatus.${TO_PICK_UP}`,
  },
  {
    value: RECEIVED,
    label: 'logistics.list.received',
  },
  {
    value: ERROR,
    label: 'logistics.list.error',
  },
  {
    value: RETURNED,
    label: 'logistics.list.returned',
  },
  {
    value: RETURNING,
    label: 'logistics.list.returning',
  },
  {
    value: PROCESS,
    label: 'logistics.list.pickedUp',
  },
  {
    value: SHIPPING,
    label: 'logistics.list.shipping',
  },
  {
    value: REACHED,
    label: 'logistics.list.reached',
  },
  {
    value: DELIVERING,
    label: 'logistics.list.delivering',
  },
  {
    value: ARRIVED_STORE,
    label: 'logistics.list.arrivedStore',
  },
  {
    value: REJECTED,
    label: 'logistics.list.rejected',
  },
]

/** 退货单状态 */
export const REVERSE_STATUS: Types.Dict<{ value: OrderStatus }> = [
  {
    value: CANCELLED,
    label: 'logistics.list.cancelled',
  },
  {
    value: TO_BE_DELIVERED_SUB_STATUS.PROCESS,
    label: `enums.logisticStatus.${TO_BE_DELIVERED_SUB_STATUS.PROCESS}`,
  },
  {
    value: TO_BE_DELIVERED_SUB_STATUS.FAIL,
    label: 'logistics.list.failed',
  },
  {
    value: TO_PICK_UP,
    label: `enums.logisticStatus.${TO_PICK_UP}`,
  },
  {
    value: RECEIVED,
    label: 'logistics.list.received',
  },
  {
    value: ERROR,
    label: 'logistics.list.error',
  },
  {
    value: RETURNED,
    label: 'logistics.list.returned',
  },
  {
    value: RETURNING,
    label: 'logistics.list.returning',
  },
  {
    value: PROCESS,
    label: 'logistics.list.pickedUp',
  },
  {
    value: SHIPPING,
    label: 'logistics.list.shipping',
  },
  {
    value: REACHED,
    label: 'logistics.list.reached',
  },
  {
    value: DELIVERING,
    label: 'logistics.list.delivering',
  },
  {
    value: ARRIVED_STORE,
    label: 'logistics.list.arrivedStore',
  },
  {
    value: REJECTED,
    label: 'logistics.list.rejected',
  },
]

/** 运单追踪展示的运单状态 */
export const LOGISTICS_STATUS_TRACKING_OPTION: Types.Dict<{ value: OrderStatus }> = [
  {
    value: CANCELLED,
    label: 'logistics.list.cancelled',
  },
  {
    value: TO_BE_DELIVERED,
    label: `enums.logisticStatus.${TO_BE_DELIVERED}`,
  },
  {
    value: TO_PICK_UP,
    label: `enums.logisticStatus.${TO_PICK_UP}`,
  },
  {
    value: RECEIVED,
    label: 'logistics.list.received',
  },
  {
    value: ERROR,
    label: 'logistics.list.error',
  },
  {
    value: RETURNED,
    label: 'logistics.list.returned',
  },
  {
    value: RETURNING,
    label: 'logistics.list.returning',
  },
  {
    value: PROCESS,
    label: 'logistics.list.pickedUp',
  },
  {
    value: SHIPPING,
    label: 'logistics.list.shipping',
  },
  {
    value: REACHED,
    label: 'logistics.list.reached',
  },
  {
    value: DELIVERING,
    label: 'logistics.list.delivering',
  },
  {
    value: ARRIVED_STORE,
    label: 'logistics.list.arrivedStore',
  },
  {
    value: REJECTED,
    label: 'logistics.list.rejected',
  },
]
export const SHIPED_STATUS = ['delivery', 'canceled', 'closed', 'finished']

export enum PickupTypesEnum {
  /** 上门揽收 */
  PICK_UP = 1,
  /** 商家自寄 */
  DROP_OFF = 2,
}

export const PICKUP_TYPES_MAP = {
  [PickupTypesEnum.PICK_UP]: 'courier.detail.comeHome',
  [PickupTypesEnum.DROP_OFF]: 'courier.detail.selfDelivery',
}

export const REVERSE_PICKUP_TYPES_MAP = {
  [PickupTypesEnum.PICK_UP]: 'reverse.pickup',
  [PickupTypesEnum.DROP_OFF]: 'order.batchCreate.dropoff',
}

export const ADDRESS_TYPES_MAP = {
  /** 超商取货 */
  STORE_STREET: 'courier.detail.storePickup',
  /** 宅配 */
  HOME_STREET: 'courier.detail.homeDelivery',
  /** 自取 */
  PO_BOX: 'courier.detail.pickupYour',
  /** 自取 */
  POST_HOUSE: 'courier.detail.pickupYour',
}

export const COD_CURRENCY_TYPE = {
  /** 1 关联目的国币种 */
  ASSOCIATE: 1,
  /** 2 指定币种 */
  SPECIFIED: 2,
}

export enum DeliveryTypeEnum {
  /** 店配 */
  PICKUP_IN_STORE = 4,
}
export const UPDATE_STATION_TYPE = [
  /** 门店关转事件 */
  '18',
  /** 门店修改失败事件 */
  '20',
]
// 归档类型
export enum ACHIVE_TYPE {
  ARCHIVED = 'ARCHIVED', // 手动归档
  AUTO_ARCHIVED = 'AUTO_ARCHIVED', // 自动归档
}

export const ACHIVE_TYPE_MAP = {
  [ACHIVE_TYPE.ARCHIVED]: 'order.list.archivedType.ARCHIVED', // 手动归档
  [ACHIVE_TYPE.AUTO_ARCHIVED]: 'order.list.archivedType.AUTO_ARCHIVED', // 自动归档
}
