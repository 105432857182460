import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { OneModal } from '@yy/one-ui'
import styles from './index.module.less'

const usePackageExistBillModal = (method = 'push') => {
  const { t } = useTranslation()
  const history = useHistory()
  const show = useCallback(() => {
    const toBill = () => {
      history[method]('/account/subscribe/bills')
    }
    const clickShowDetailBtn = () => {
      modal.destroy()
      toBill()
    }
    const modal = OneModal.warn({
      title: t('subscribe.package.pendingBillModal.title'),
      okText: t('frame.isee'),
      content: (
        <div>
          <span>{t('subscribe.package.pendingBillModal.content')}</span>
          <a onClick={clickShowDetailBtn}>
            <span className={styles.showDetailText}>{t('subscribe.package.pendingBillModal.showDetail')}</span>
            <svg className={styles.verticalAlignMiddle} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.6001 8.7999L12.6001 13.5999C12.6001 13.759 12.5369 13.9116 12.4244 14.0242C12.3118 14.1367 12.1592 14.1999 12.0001 14.1999L2.4001 14.1999C2.06873 14.1999 1.8001 13.9313 1.8001 13.5999L1.8001 3.9999C1.8001 3.66853 2.06873 3.3999 2.4001 3.3999L7.2001 3.3999L7.2001 4.5999L3.0001 4.5999L3.0001 12.9999L11.4001 12.9999L11.4001 8.7999L12.6001 8.7999Z"
                fill="#02c8c6"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.59995 1.80005H13.5999C13.9313 1.80005 14.1999 2.06868 14.1999 2.40005V6.40005H12.9999V3.84858L8.42421 8.42431L7.57568 7.57578L12.1514 3.00005H9.59995V1.80005Z"
                fill="#02c8c6"
              />
            </svg>
          </a>
        </div>
      ),
      // onOk: toBill,
    })
  }, [history, t, method])
  return show
}
export default usePackageExistBillModal
