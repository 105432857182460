import type { RouteConfig } from 'react-router-config'
import { withAsync } from '@/components/Async'
import Blank from '@/layouts/Blank'

const routes: RouteConfig[] = [
  {
    path: '/user',
    component: Blank,
    routes: [
      {
        path: '/login',
        component: withAsync(() => import(/* webpackChunkName: "User/Terms" */ '@/containers/User/component/RedirectToOneclub')),
        exact: true,
      },
      {
        path: '/register',
        component: withAsync(() => import(/* webpackChunkName: "User/Terms" */ '@/containers/User/component/RedirectToOneclub')),
        exact: true,
      },
      {
        path: '/appeal',
        component: withAsync(() => import(/* webpackChunkName: "User/Terms" */ '@/containers/User/Appeal')),

        exact: true,
      },
    ],
  },
]

export default routes
