// 提供localStorage工具函数

type IStorage = {
  interComConfig: IInterComConfig
  i18nextLng: string
  __acrossTabSessionStorage__: Record<string, string>
  privacy_view: Record<string, string>
}
class StorageUtil {
  get<K extends keyof IStorage>(key: K): IStorage[K] {
    const data = localStorage.getItem(key)

    if (!data) return null

    try {
      const parseData = JSON.parse(data)
      return parseData
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('parse localStorage error, key is ', key)
      return null
    }
  }

  set<K extends keyof IStorage>(key: K, data: IStorage[K]) {
    // 不允许存储undefined，因为无法正确JSON.parse
    if (data === undefined) return
    localStorage.setItem(key, !!data ? JSON.stringify(data) : '')
    return true
  }

  remove(key: keyof IStorage) {
    localStorage.removeItem(key)
    return true
  }
}

const storageUtil = new StorageUtil()

/**
 * 可以把多个key归纳存储到一个local key上，方便清理和覆盖
 * 适合那些希望使用固定前缀作为localStorage key的场景
 * @param globalKey
 */
export const getMultiKeyLocalStorage = (globalKey: keyof IStorage) => {
  /**
   * 获取存储的所有内容
   * @returns
   */
  const getAllItem = () => {
    const obj = storageUtil.get(globalKey)
    if (obj === null) return {}
    return typeof obj === 'object' ? obj : {}
  }

  /**
   * 获取某个key的值
   * @param key
   * @returns
   */
  const getItem = (key: string) => {
    const globalLocal = getAllItem()
    if (!(key in globalLocal)) return null
    const val = globalLocal[key]
    if (typeof val !== 'string') {
      removeItem(key)
      return null
    }
    return val
  }
  /**
   * 存储单个key
   * @param key
   * @param value
   */
  const setItem = (key: string, value: string) => {
    const globalLocal = getAllItem()
    globalLocal[key] = value
    storageUtil.set(globalKey, globalLocal)
  }
  /**
   * 删除单个key的值
   * @param key
   */
  const removeItem = (key: string) => {
    const globalLocal = getAllItem()
    delete globalLocal[key]
    storageUtil.set(globalKey, globalLocal)
  }
  /**
   * 移除所有key
   */
  const clearAllItem = (keys?: string[]) => {
    if (Array.isArray(keys)) {
      const globalLocal = getAllItem()
      keys.forEach((key) => delete globalLocal[key])
      storageUtil.set(globalKey, globalLocal)
    } else storageUtil.remove(globalKey)
  }

  return {
    getItem,
    setItem,
    removeItem,
    clearAllItem,
    getAllItem,
  }
}

export default storageUtil
