import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { clearBatchShipData } from '@/store/newBatchShipSlice'
import { useIntercomWatchRouter } from '@/utils/intercom'

const WatchRouter = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { updateRouterForInterCom } = useIntercomWatchRouter()

  useEffect(() => {
    history.listen((location) => {
      const path = location.pathname
      // clear batch ship's data
      if (!['/ship/order/batch/edit', '/ship/order/batch'].includes(path)) {
        dispatch(clearBatchShipData())
      }
      updateRouterForInterCom()
    })
  }, [dispatch, history, updateRouterForInterCom])

  return <></>
}

export default WatchRouter
