import type { RouteConfig } from 'react-router-config'
import { withAsync } from '@/components/Async'
import { withAuthorized } from '@/components/Authorized'
import Blank from '@/layouts/Blank'

const routes: RouteConfig[] = [
  {
    path: '/middleware',
    component: withAuthorized(Blank),
    routes: [
      {
        path: '/station-711',
        component: withAsync(() => import(/* webpackChunkName: "Common/Station711" */ '@/containers/Common/Station711')),
        exact: true,
      },
    ],
  },
]

export default routes
