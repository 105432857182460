import { Dedupe as DedupeIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { FILTER_CODES } from '@/constant/udbBizCode'
import { ERROR_TYPE_ENUM } from './constants'

/** 过滤&处理错误 (https://docs.sentry.io/platforms/javascript/configuration/filtering/) */
// 自定义过滤信息
const FILTER_SET = [
  'USER_NOT_VALID',
  'COOKIE_EXPIRED',
  'ResizeObserver loop limit exceeded',
  'Loading chunk',
  'Loading CSS chunk',
  'connect.facebook.net/',
  'apis.google.com/',
  'not login',
  'the referenceNo has exists!',
  'address number exceeded',
  'not found the order logistics no',
  'insufficient balance',
  'the phone number is incorrect!',
  'Unauthorized service product',
  'order is pending!',
  'Express order batch delivery failed',
  'must not be null at the same time',
  'file missing orders!',
  'www.googletagmanager.com',
  'length must be less than or equal to',
  'cancel delivery fail',
  'The current status cannot be shipped',
  'snapshot has expire',
  'not a valid number!',
  'seller unauthorized!',
  'The template header does not match',
  'is an unsupported currency',
  '请求已取消',
  'order status must be undelivery!',
  'expressType config not found by expressType [',
]
const REG_FILTER_SET = [/sellerId\[.*\] originOrderNo\[.*\] existed/]

/** 判断报错信息是否需要过滤 */
const needFilter = (message: string) => FILTER_SET.some((item) => message.indexOf(item) !== -1) || REG_FILTER_SET.some((item) => item.test(message))

/** sentry上报前钩子函数 */
const beforeSend = (event, hint) => {
  const exception = event?.exception?.values[0] || {}

  // 不存在message或者符合自定义过滤或者udb主动抛出的错误都过滤
  if (!event.message && (!exception.value || needFilter(exception.value) || FILTER_CODES.includes(hint.originalException?.code))) return null

  switch (exception.type) {
    // 过滤接口状态码为200的没有catch的rejection，因为reject的不是Error，可以采用这种方式过滤
    // 如果是我们自己写的promise，出现没有catch的reject一般都是代码出错会抛出Error
    case 'UnhandledRejection':
      if (exception.value.indexOf('Non-Error promise rejection') !== -1) return null
      break
    case 'Error':
      // 过滤 Google Analytics 等第三方库抛出的非Error类型的错误
      if (exception.value.indexOf('Non-Error exception') !== -1) return null
      break
    case 'CustomError':
      if (['system error', '系统错误', '系统异常'].every((item) => exception.value.indexOf(item) === -1)) {
        event.level = Sentry.Severity.Warning
      } else {
        event.tags['type'] = ERROR_TYPE_ENUM.HTTP
      }
      break
    default:
      break
  }

  if (event.tags && !event.tags['type']) {
    event.tags['errorType'] = exception.type
    event.tags['type'] = ERROR_TYPE_ENUM.JS
  }

  return event
}

if (
  process.env.SENTRY_DEBUG === 'true' ||
  (process.env.NODE_ENV === 'production' && process.env.BUILD_ENV === 'CI' && process.env.APP_ENV && process.env.SENTRY_DSN && process.env.GIT_COMMIT_HASH)
) {
  try {
    type SentryHijackXMLHttpRequest = XMLHttpRequest & {
      __sentry_headers__?: Record<string, string>
    }

    const noConflitSetRequestHeader = XMLHttpRequest.prototype.setRequestHeader
    XMLHttpRequest.prototype.setRequestHeader = function (this: SentryHijackXMLHttpRequest, name, value) {
      const response = noConflitSetRequestHeader.call(this, name, value)
      if (typeof this.__sentry_headers__ !== 'object') {
        this.__sentry_headers__ = {}
      }

      this.__sentry_headers__[name] = value
      return response
    }

    Sentry.init({
      /** 告诉 SDK 上报错误/异常的地方 */
      dsn: process.env.SENTRY_DSN || '',
      /** 开启 DEBUG 模式 */
      debug: process.env.SENTRY_DEBUG === 'true' && process.env.APP_ENV !== 'product',
      /** 代码的版本号 Release 版本号, 可以确定当前的错误/异常属于哪一个发布的版本 */
      release: process.env.SENTRY_RELEASE,
      /** 上报当前错误/异常发生的环境 */
      environment: process.env.APP_ENV,
      autoSessionTracking: false,
      integrations: [
        new Integrations.BrowserTracing(),
        // 消除了某些事件的重复数据
        new DedupeIntegration(),
      ],
      /** 黑名单 */
      blacklistUrls: [/^localhost/, /extensions\//i, /^chrome:\/\//i, /^chrome-extensions:\/\//i, /^file:\/\//i],
      /** 随机上报事件的概率 */
      tracesSampleRate: 0,
      beforeBreadcrumb(breadcrumb, hint) {
        switch (breadcrumb.category) {
          // 过滤 CONSOLE LOG WARN
          case 'console': {
            if (hint.level !== 'error') {
              return null
            }

            break
          }

          // XHR 添加数据
          case 'xhr': {
            // 设置 traceid
            const xhr: SentryHijackXMLHttpRequest = hint.xhr
            const { traceparent: traceId } = xhr.__sentry_headers__ || {}

            if (traceId) {
              breadcrumb.data = Object.assign(breadcrumb.data || {}, {
                traceId: traceId.split('-')[1],
              })
            }

            break
          }

          // 用户操作
          case 'ui.click': {
            const { event } = hint
            const target: HTMLElement = event.target
            const content = target.innerText || ''
            if (content) {
              breadcrumb.data = Object.assign(breadcrumb.data || {}, {
                content: content.slice(0, 6),
              })
            }

            break
          }
        }

        return breadcrumb
      },
      beforeSend,
    })

    if (process.env.SENTRY_DEBUG === 'true') {
      const captureException = Sentry.captureException.bind(Sentry)
      const captureMessage = Sentry.captureMessage.bind(Sentry)
      Object.assign(window, { captureException, captureMessage })
    }
  } catch (exception) {
    // eslint-disable-next-line no-console
    console?.error(exception)
  }
}
