import type { ProviderType } from '@/constant'

type TSupportedAddressTypes = 'STORE_STREET' | 'HOME_STREET'

export interface LogisticsOrderItemType {
  appId: 'SL101' | 'ONE01' // 接入系统
  itemNum: number
  orderNo: string
  originOrderNo: string
  consigneeName: string
  consigneePhone: string
  consigneeCountry: string
  consigneeProvince: string
  consigneeZipCode?: string
  consigneeDistrict?: string
  consigneeCity: string
  companyCode: string
  /** 服务商名称 */
  companyName: string
  expressTypeCode: string
  expressTypeName: string // 服务商名称
  orderAmount: number
  codAmount: number
  currencyCode: string
  paymentStatus: string
  consigneeStationCode?: string
  consigneeAddress?: string
  consigneeAddressTwo?: string
  store: {
    /** 初始化状态集 */
    userStoreSeq: string
    /** 三方系统id */
    appId: string
    /** 店铺id */
    storeId: string
    /** 店铺名称 */
    storeName: string
    /** 店铺别名 */
    storeAlias: string
    /** 店铺网址 */
    storeWebsite: string
    /** 店铺email */
    email: string
    /** 店铺授权状态 */
    authStatus: number
    /** 店铺初始化拉单状态 */
    pullStatus: number
    /** 店铺默认地址id */
    defaultAddressNo: string
    /** 店铺默认语言编码 */
    defaultLanguageCode: string
    /** 店铺图标地址 */
    logoUrl: string
  }
  /** 汇率 */
  currencyRate?: number
  /** 汇率更新时间(等同版本信息）率 */
  fetchTime?: number
  /** 是否转换了货币 1=转换过，-1=没转换 没资格转 */
  currencyConverted?: -1 | 0 | 1
  /** 原币种 */
  originCurrencyCode?: string
  /** 原实付金额 */
  originOrderAmount?: number
}
/** 初始化状态集 */
export interface InitialState extends DepartureState, ExpressState, DeliveryState {
  /** 是否设置为统一服务产品, 不可逆 */
  isUnified?: boolean
  logisticsOrderVos: LogisticsOrderItemType[]
  batchDeliveryValidation: BatchDeliveryValidateState | null
  customLogisticPickupTimeState: CustomLogisticPickupTimeState | null
  customLogisticPaymentTypeState: CustomLogisticPaymentTypeState | null
}

// ------ 发货地址 ---------

/** 发货状态集 */
export interface DepartureState {
  /** 发货地址类型 */
  departureType?: DepartureType
  /** 已选中的发货地址 */
  departureSelected?: DepartureSelected
}

/** 发货地址选项 */
export type DepartureType = 'departureCustom' | 'departureAddr'

/** 已选的发货地址 */
export interface DepartureSelected {
  addressNo?: string
  locationName?: string
  name?: string
  mobile?: string
  email?: string
  countryCode: string
  provinceCode?: string
  province?: string
  cityCode?: string
  city?: string
  districtCode?: string
  district?: string
  townCode?: string
  town?: string
  address?: string
  addressTwo?: string
  zipCode?: string
  area?: string
}

// ------ 快递列表 ---------

/** 快递状态集 */
export interface ExpressState {
  /** 快递列表 */
  expressList?: ExpressList
  /** 已选快递 */
  expressSelected?: Express
}

/** 快递服务 */
export interface Express {
  /** 快递服务产品编码 */
  expressTypeCode: string
  /** 服务产品名称, 标准的 */
  expressTypeName: string
  /** 承运商编码 */
  companyCode: ProviderType
  /** 承运商名称 */
  companyName: string
  /** 承诺送达时间/物流时效 */
  promisedTime: string
  /** 支持的的揽收方式: 1:上门揽收 2:自邮 */
  supportedPickupTypes: Array<1 | 2>
  /** 预约提货日期 */
  requiredPickupDates: string[]
  /** 预约提货时间 */
  requiredPickupTimeslots: string[]
  /** 支持的支付方式 */
  settlementModes: number[]
  remark?: string
  /** 1 支持保险, 0 不支持保险 */
  insuranceSupport: 1 | 0
}

/** 快递服务列表 */
export type ExpressList = Express[]

// ------ 快递服务 ---------

/** 快递服务相关状态集 */
export interface DeliveryState {
  /** 批量订单的物流信息 */
  batchDeliveryLogistics?: BatchDeliveryLogisticsState
  /** 批量订单的验证信息 */
  batchDeliveryValidation?: BatchDeliveryValidateState
  /** 自定义揽货时间 */
  customLogisticPickupTimeState?: CustomLogisticPickupTimeState
}

/** 请求批量订单发货物流信息参数 */
export interface GetBatchDeliveryLogisticsParams {
  orders: string[]
  senderAddress: {
    countryCode: string
    provinceName?: string
    cityName?: string
    districtName?: string
  }
}

/** 批量订单发货物流信息 */
export interface BatchDeliveryLogisticsState {
  /** 物流信息按服务产皮分组列表,串接物流才有 */
  logisticsInfos: Array<{
    /** 服务产品 */
    expressType: {
      /** 快递服务产品编码 */
      expressTypeCode: string
      /** 服务产品名称, 标准的 */
      expressTypeName: string
      /** 承运商编码 */
      companyCode: ProviderType
      /** 承运商名称 */
      companyName: string
      /** 承诺送达时间/物流时效 */
      promisedTime: string
      /** 支持的的揽收方式: 1:上门揽收 2:自邮 */
      supportedPickupTypes: Array<1 | 2>
      /** 预约提货日期 */
      requiredPickupDates: string[]
      /** 预约提货时间 */
      requiredPickupTimeslots: string[]
      /** 支持的支付方式 */
      settlementModes: number[]

      /**税号 */
      taxNumber: string
      /**EORI号码 */
      customIdNo: string
      /**服务编码 */
      channelSellerId: string
      /**收件人姓名 */
      consigneeName: string
      /**consigneeIdType */
      consigneeIdType: string
      /**证件号码 */
      consigneeIdNo: string

      /** 服务商是否需要子账号 */
      needSubAccount?: number
      /**
       * 服务产品支持保险
       */
      insuranceSupport: 0 | 1
    }
    /** oneship订单id */
    orderNos: string[]
  }>
  /** 自定义物流推荐服务产品，默认根据发货地址选第一个 */
  customRecommendExpress: {
    /** 快递服务产品编码 */
    expressTypeCode: string
    /** 服务产品名称, 标准的 */
    expressTypeName: string
    /** 承运商编码 */
    companyCode: ProviderType
    /** 承运商名称 */
    companyName: string
    /** 承诺送达时间/物流时效 */
    promisedTime: string
    /** 支持的的揽收方式: 1:上门揽收 2:自邮 */
    supportedPickupTypes: Array<1 | 2>
    /** 预约提货日期 */
    requiredPickupDates: string[]
    /** 预约提货时间 */
    requiredPickupTimeslots: string[]
  }
  /** 自定义物流数,需要指定服务产品 */
  customLogisticsOrders: number
  /** 自定物流订单号 */
  orderNos: string[]
  /**订单寄件方式是否是店配 */
  storeDeliveryOrder?: boolean
}

/** 批量订单的验证信息 */
export interface BatchDeliveryValidateState {
  validCount: number
  total: number
  logisticsOrderVos?: LogisticsOrderItemType[] // 不适合放这里todo
}

/** 自定义揽货时间 */
export interface CustomLogisticPickupTimeState {
  [orderNo: string]: {
    requiredPickupDates?: string[]
    requiredPickupTimeslots?: string[]
    monthlyAccount?: string
    /**税号 */
    taxNumber?: string
    /**EORI号码 */
    customIdNo?: string
    /**服务编码 */
    channelSellerId?: string
  }
}

/** 自定义揽货时间 */
export interface CustomLogisticPaymentTypeState {
  [orderNo: string]: {
    /** 支付方式 */
    shipmentPaymentType?: number
    /** 是否购买保险 */
    shipmentInsuranceType?: number
  }
}

// ------ 请求参数 ---------

/** 请求物流列表参数 */
export interface GetExpressListParams {
  senderAddressName: {
    countryCode: string
    provinceName?: string
    cityName?: string
    districtName?: string
  }
  cod?: boolean
  pageSize?: number
  pageNum?: number
  supportedAddressTypes?: TSupportedAddressTypes[]
}

/** 批量发货参数 */
export interface BatchDeliveryParams {
  payType: number
  orders: Array<{
    expressTypeCode: string
    pickupType: number
    requiredPickupDates: string[]
    requiredPickupTimeslots: string[]
    timezone: string
    orderNo: string
  }>
  address: {
    locationName?: string
    senderReferenceNo: string
    senderIdNo?: string
    senderIdType?: number
    senderCountryCode: string
    senderProvince?: string
    senderCity?: string
    senderDistrict?: string
    senderTown?: string
    senderName: string
    senderPhone?: string
    senderMobile?: string
    senderAddress: string
    senderAddressTwo?: string
    senderZipCode?: string
    senderRemark?: string
    senderEmail?: string
  }
}

/** 批量验证参数 */
export interface BatchDeliveryValidateParams {
  orders: Array<{
    expressTypeCode: string
    pickupType: number
    requiredPickupDates: string[]
    requiredPickupTimeslots: string[]
    timezone: string
    orderNo: string
  }>
  address: {
    locationName?: string
    senderReferenceNo: string
    senderIdNo?: string
    senderIdType?: number
    senderCountryCode: string
    senderProvince?: string
    senderCity?: string
    senderDistrict?: string
    senderTown?: string
    senderName: string
    senderPhone?: string
    senderMobile?: string
    senderAddress: string
    senderAddressTwo?: string
    senderZipCode?: string
    senderRemark?: string
    senderEmail?: string
  }
}

/** 请求地址列表参数 */
export interface GetAreaParams {
  countryCode?: string
  code?: string
  lang?: string
}

/** 自动转换 */
export enum CurrencyConverted {
  CONVERTED = 1,
  NOT_CONVERT = 0,
  CANT_CONVERT = -1,
}
export interface ConvertedInfo {
  isConverted: boolean
  rate: {
    // 汇率
    currencyRate: number
    currencyCode: string // todo 是否要传币种？对接入参数
    fetchTime: number // 汇率更新时间(等同版本信息）
    originCurrencyCode: string // 转换前的货币
  }
}
