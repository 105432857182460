import { Col, Form } from 'antd'
import React, { useCallback, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OneSelect } from '@yy/one-ui'
import type { AreaFormProps } from '@/bizComponent/AntdForm'
import { AreaForm } from '@/bizComponent/AntdForm'
import CountryWithCode from '@/bizComponent/CountryWithCode'
import { useSelector } from '@/store'
import { filterCountryOption } from '@/utils'

/**
 *  收货地址 国家省市区
 */

export interface ArrivalAreaFormRefTypes {
  clearCountryCode: () => void
  countryChange: (countryCode: string) => void
}

const { Option } = OneSelect

export const ArrivalAreaForm = React.forwardRef<
  ArrivalAreaFormRefTypes,
  Partial<
    AreaFormProps & {
      countryLabel?: string
    }
  >
>(({ countryLabel, ...props }, ref) => {
  const { t } = useTranslation()

  const [countryCode, setCountryCode] = useState('')

  useImperativeHandle(ref, () => ({
    clearCountryCode: () => {
      setCountryCode('')
    },
    countryChange,
  }))

  /** 国家列表 */
  const arrivalDics = useSelector((state) => state.dictionary.filterArrivalCountries)

  const countryChange = useCallback(async (countryCode: string) => {
    setCountryCode(countryCode)
  }, [])

  return (
    <>
      {/* 国家 */}
      <Col {...props?.colProps}>
        <Form.Item
          label={countryLabel || t('order.create.countryRegion')}
          name="arrival_countryCode"
          required={false}
          rules={[{ required: true, message: t('common.formValidRequired') }]}
        >
          <OneSelect
            filterOption={filterCountryOption}
            onChange={countryChange}
            placeholder={t('order.create.countryRegion')}
            className="country-select"
            showSearch
            dropdownMatchSelectWidth={200}
            disabled={arrivalDics.length === 0}
          >
            {arrivalDics?.map((item) => (
              <Option key={item.countryCode} value={item.countryCode} title={item.countryName}>
                <CountryWithCode item={item} />
              </Option>
            ))}
          </OneSelect>
        </Form.Item>
      </Col>
      <AreaForm countryCode={countryCode} areaType="arrival" showRequired={false} {...props} />
    </>
  )
})

ArrivalAreaForm.displayName = 'ArrivalAreaForm'
