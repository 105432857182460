import type { FormInstance } from 'antd'
import i18n from '@/services/i18n'
import { useSelector } from '@/store'
import type { LogisticsOrderInfo } from '@/store/order/createSlice/types'
import { isCNWebsiteFn } from '@/utils/address'
import { useAddressLocalLanage } from '../useLocalConfig'
import { useMemoizedFn } from '../utilsHooks'

export function useWarnningErrorAddress(form: FormInstance) {
  const userCountryCode = useSelector((state) => state.user?.info?.countryCode)
  const provinces = useSelector((state) => state.recipientAddressForm.provinces)

  const hasProvinces = provinces?.length > 0
  const { _t } = useAddressLocalLanage()

  const warnningErrorAddress = useMemoizedFn((order: Partial<Pick<LogisticsOrderInfo, 'address'>>, options?: { skipDeparture?: boolean; skipArrival?: boolean }) => {
    const senderProvinceKey = 'departure_province'
    const consigneeProvinceKey = 'arrival_province'
    const { address } = order || {}
    const { skipDeparture, skipArrival } = options || {}
    const formData = form.getFieldsValue()

    // 为了国际版不卡单
    if (!isCNWebsiteFn() || !hasProvinces) return

    const fields = []

    // 为空不执行
    if (!skipArrival && address && !address?.consigneeAddressLanguages?.length && formData[consigneeProvinceKey]) {
      fields.push({
        name: consigneeProvinceKey,
        value: form.getFieldValue(consigneeProvinceKey),
        errors: [
          i18n.t('province.error.select.again', {
            province: _t(formData.arrival_countryCode, 'stateProvince'),
          }),
        ],
      })
    }

    /**
     * 为空
     * 店配
     * 都不执行
     */
    if (!skipDeparture && address && !address?.senderAddressLanguages?.length && !address?.consigneeStationCode && formData[senderProvinceKey]) {
      fields.push({
        name: senderProvinceKey,
        value: form.getFieldValue(senderProvinceKey),
        errors: [
          i18n.t('province.error.select.again', {
            province: _t(userCountryCode, 'stateProvince'),
          }),
        ],
      })
    }

    /**
     * 没有语言，且是跨境，需要卡单让用户重新选择
     * https://shopline.yuque.com/docs/share/f9fbc131-4907-4f66-b1a8-848162b8d858?#
     */
    if (fields.length) {
      form.setFields(fields)
    }
  })

  return {
    warnningErrorAddress,
  }
}
