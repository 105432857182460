import type * as Types from '@/types'

const STORE_STREET = '4'
const HOME_STREET = '1'
type deliveryType = '4' | '1'

export type DeliveryTypeDict = Types.Dict<{ value: string }>

export const DELIVERYTYPE_LABEL: Types.DictLabel<deliveryType> = {
  '4': 'logistics.list.storeDelivery',
  '1': 'logistics.list.houseDelivery',
}

export const DELIVERYTYPE: DeliveryTypeDict = [
  {
    label: DELIVERYTYPE_LABEL[STORE_STREET],
    value: STORE_STREET,
  },
  {
    label: DELIVERYTYPE_LABEL[HOME_STREET],
    value: HOME_STREET,
  },
]

export const ARRIVAL_METHOD = {
  HOUSE: 1, //宅配
  STORE: 2, //店配
}

export const ARRIVAL_METHOD_PICKUP = {
  HOUSE: 'RESIDENTIAL', //住宅
  COMPANY: 'COMMERCIAL', //公司
}
