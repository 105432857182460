export default {
  /** 读普通消息 */
  readMessage: '/api/user/message/read',
  /** 读下载消息 */
  readDownload: '/api/order/export/read',
  /** 获取未读消息&&异步任务 */
  queryUnRead: '/api/user/message/unread',
  /** 下载列表 */
  downloadList: '/api/order/export/query',
  /** 消息列表 */
  messageList: '/api/user/message/list',
  /** 下载地址 */
  s3DownloadUrl: '/api/order/export/list_export_file_url',
}
