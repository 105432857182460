/** api来源 */
export const API = 0

/** 手动创建来源 */
export const MUTUAL = 1

export const SOURCE_TYPE = {
  API,
  MUTUAL,
}
