export const IS_CHINA_CRORSS_ITEMS = {
  parcel_goods_code: '',
  parcel_goods_name: '',
  parcel_goods_cname: '',
  parcel_goods_ename: '',
  parcel_goods_hscode: '',
  parcel_goods_quantity: 1,
  parcel_goods_price: 1,
}

export const IS_CRORSS_BORDER_ITEMS = {
  parcel_goods_code: '',
  parcel_goods_name: '',
  parcel_goods_ename: '',
  parcel_goods_hscode: '',
  parcel_goods_quantity: 1,
  parcel_goods_price: 1,
}

export const NORMAL_ITEMS = {
  parcel_goods_code: '',
  parcel_goods_name: '',
  parcel_goods_quantity: 1,
  parcel_goods_price: 1,
}

export const CHINESE_RE = /[\u4e00-\u9fa5]+/
export const CHINESE_DOT1_RE = /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009]+/
export const CHINESE_DOT2_RE = /[\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]+/
