import { ANTD_RULES } from '../../antdRules'
import * as FIELDS from '../../constants'
import InputBase from '../InputBaseAntd'
import AntInputSelect from './components/AntInputSelect'
import styles from './index.module.less'
import { FormItem } from '@/bizComponent/AntdForm/DynamicFormItemGroup'
import GloablCountryList from '@/bizComponent/GlobalCountryList'
import { fetchRateList } from '@/services/apis/common'
import { useDispatch, useSelector } from '@/store'
import { setProps, updateCodValue } from '@/store/newServiceProviderSlice'
import { getProductsRateList } from '@/store/parcelSlice'
import { Info, Plus, Trash } from '@yy/one-icon'
import { OneButton, OneSecondaryButton, OneTable, OneTooltip } from '@yy/one-ui'
import type { FormInstance } from 'antd'
import { Form } from 'antd'
import type { ColumnType } from 'antd/lib/table'
import { divide, times } from 'number-precision'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { FromEmptyItem } from '@/bizComponent/AntdForm/FromEmptyItem'
import { isCodCurrencyCodeSpecified } from '@/utils'
import classNames from 'classnames'

const ORIGINAL_COUNTRY_KEY = 'originCountryCode'

/** 需要外部的表单字段 */

interface ParcelFormProps {
  currency?: string
  isEdit?: boolean
  /** 是否跨境 */
  isCrossBorder: boolean
  /** 是否中国跨境 */
  isChinaCrossBorder: boolean
  /** 展示商品规格 是否订单转运单*/
  showGoodSize?: boolean
  /** 编辑运单时标记是否为订单转运单 */
  markOrderToLogisticEdit?: -1 | 1 | 0
  form: FormInstance
  parcelItem: typeof FIELDS.NORMAL_ITEMS
  isHKCrossBorder?: boolean
}

/** 包裹信息表单 */
const ParcelForm: React.FC<ParcelFormProps> = (props) => {
  const { form: formInstance, parcelItem } = props
  const dispatch = useDispatch(formInstance)
  const { t } = useTranslation()
  const { currencyDics } = useSelector((state) => state.dictionary)
  const currencyInfo = useSelector((state) => state.parcelSlice.currencyInfo)
  // selected provider's companyCode
  const provider = useSelector((state) => state.newServiceProvider?.provider)
  const { fetchTime, currencyRate } = currencyInfo || {}
  const { props: storeProps } = useSelector((state) => state.parcelSlice)
  /** 展示商品规格 是否订单转运单*/
  const { isShowCheckbox, isCrossBorder, isChinaCrossBorder, isConverted: checkCurrency, IsIndonesia, isUS2US, senderCountry } = storeProps || {}

  // 列
  interface ColumnCellProps {
    id?: string
    label?: string
    name?: string
    placeholder?: string
    suffix?: JSX.Element
  }

  // 切换转换币种
  const changeConvertedCurrency = useCallback(
    (unit: string) => {
      formInstance.setFieldsValue({ parcel_converted_currency_code: unit })
      // 币种改变汇率价格

      const source = formInstance.getFieldValue('parcel_currency_code')
      dispatch(getProductsRateList({ source, target: unit }, formInstance))
    },
    [dispatch, formInstance]
  )

  const changeOriginCurrencyCode = useCallback(
    async (unit: string) => {
      formInstance.setFieldsValue({ parcel_currency_code: unit })
      // 更新商品币种，COD币种跟商品币种保存一致(特殊渠道特殊处理)
      const provider_cod_amount = formInstance.getFieldValue('provider_cod_amount')
      // cod金额使用商品的币种作为原币种转一次：
      // 1.如果转成功，updateCodValue内部会将COD币种同步为商品币种
      const { codCurrencyConverted } = await dispatch(
        updateCodValue({
          // 如果为非cod服务产品，provider_cod_amount为空，所以这里不能取’provider_cod_amount‘
          codFee: provider_cod_amount,
          currencyCode: unit,
        })
      )
      // 如下场景，COD币种跟随商品币种切换：
      // 1.cod币种会发生币种转换。
      // 2.COD币种不发生币种转换，服务产品为非指定币种服务产品
      // 3.COD币种不发生币种转换，服务产品为指定币种，且商品币种在指定币种范围内。
      if (
        codCurrencyConverted ||
        (!codCurrencyConverted && !isCodCurrencyCodeSpecified(provider?.codCurrencyType)) ||
        (!codCurrencyConverted && isCodCurrencyCodeSpecified(provider?.codCurrencyType) && provider?.supportedCurrencies.includes(unit))
      ) {
        formInstance.setFieldsValue({ provider_cod_currency_code: unit })
      } else if (!codCurrencyConverted && isCodCurrencyCodeSpecified(provider?.codCurrencyType) && !provider?.supportedCurrencies.includes(unit)) {
        // 非币种转换，指定币种服务产品，商品币种没有在指定范围内，要切换为指定币种的第一个
        formInstance.setFieldsValue({ provider_cod_currency_code: provider?.supportedCurrencies?.[0] })
      }
      if (checkCurrency) {
        // 币种改变汇率价格
        const target = formInstance.getFieldValue('parcel_converted_currency_code')
        dispatch(getProductsRateList({ source: unit, target }, formInstance))
      }
      // 服务产品币种联动
      dispatch(
        setProps({
          currencyCode: unit,
        })
      )
    },
    [checkCurrency, dispatch, formInstance, provider?.codCurrencyType, provider?.supportedCurrencies]
  )

  // 商品单价修改，币种转换处理
  const changePrice = useCallback(() => {
    if (checkCurrency) {
      // 币种改变汇率价格
      const target = formInstance.getFieldValue('parcel_converted_currency_code')
      const parcel_currency_code = formInstance.getFieldValue('parcel_currency_code')
      dispatch(getProductsRateList({ source: parcel_currency_code, target }, formInstance))
    }
  }, [checkCurrency, dispatch, formInstance])

  // 内容属性
  interface ColumnCellContentProps extends ColumnCellProps {
    columnIndex?: number
    rowIndex?: number
    field?: any
    remove?: (index: number | number[]) => void
    inputProps?: Parameters<typeof InputBase>[0]
  }
  // 渲染内容
  const renderBody = useCallback(
    (props: ColumnCellContentProps) => {
      const { name, columnIndex, rowIndex, field, remove, inputProps } = props

      if (columnIndex === 0)
        return (
          <div className={styles.parcelFormItem}>
            <Form.Item>
              <div className={styles.num}>{rowIndex + 1}</div>
            </Form.Item>
          </div>
        )
      if (!name) {
        return null
      }
      if (name === 'action') {
        if (rowIndex === 0) {
          return null
        }
        return (
          <div className={styles.parcelFormItem}>
            <Form.Item>
              <OneButton type="text" icon={<Trash className={styles.trashIcon} onClick={() => remove(rowIndex)} />} />
            </Form.Item>
          </div>
        )
      }
      if (name === 'parcel_goods_price') {
        return (
          <div className={styles.parcelFormItem}>
            <Form.Item shouldUpdate={(pre, next) => pre.parcel_currency_code !== next.parcel_currency_code}>
              {(form) => {
                const changeCurrencyCode = form.getFieldValue('parcel_currency_code')
                /**
                 * 默认币种跟收货国家币种
                 * @see convertedCurrencyCode /store/parcelSlice/index.ts
                 */
                return (
                  <AntInputSelect
                    names={[[field.name, name]]}
                    units={currencyDics}
                    disableSelect={rowIndex !== 0 || checkCurrency}
                    value={changeCurrencyCode}
                    onChange={changePrice}
                    onCurrencyChange={async (val) => {
                      await changeOriginCurrencyCode(val)
                      // 币种改变触发校验
                      form.validateFields()
                    }}
                    rules={[ANTD_RULES[name]({ currencyCode: changeCurrencyCode })]}
                  />
                )
              }}
            </Form.Item>
          </div>
        )
      }
      if (name === 'parcel_goods_converted_price') {
        return (
          <div className={styles.parcelFormItem}>
            <Form.Item shouldUpdate={(pre, next) => pre.parcel_converted_currency_code !== next.parcel_converted_currency_code}>
              {() => {
                const changeCurrencyCode = formInstance.getFieldValue('parcel_converted_currency_code')
                return (
                  <AntInputSelect
                    names={[[field.name, 'parcel_goods_converted_price']]}
                    units={currencyDics}
                    disableSelect={rowIndex !== 0}
                    disableInput
                    value={changeCurrencyCode}
                    rules={[ANTD_RULES['parcel_goods_price']({ currencyCode: changeCurrencyCode, notValidate: false })]}
                    onCurrencyChange={(val) => {
                      changeConvertedCurrency(val)
                      formInstance.validateFields()
                    }}
                  />
                )
              }}
            </Form.Item>
          </div>
        )
      }

      if (name === ORIGINAL_COUNTRY_KEY) {
        return (
          <div className={styles.parcelFormItem}>
            <Form.Item preserve={false} shouldUpdate={(pre, next) => pre[ORIGINAL_COUNTRY_KEY] !== next[ORIGINAL_COUNTRY_KEY]}>
              {() => {
                return (
                  <FormItem name={[field.name, ORIGINAL_COUNTRY_KEY]} rules={ANTD_RULES['original_countryCode']()}>
                    <GloablCountryList style={{ minWidth: 200 }} />
                  </FormItem>
                )
              }}
            </Form.Item>
          </div>
        )
      }

      return (
        <div className={styles.parcelFormItem}>
          <FormItem rules={ANTD_RULES[name] ? [ANTD_RULES[name]()] : null} {...field} name={[field.name, name]} style={{ minWidth: '200px' }}>
            <InputBase {...inputProps}></InputBase>
          </FormItem>
        </div>
      )
    },
    [currencyDics, checkCurrency, changePrice, changeOriginCurrencyCode, formInstance, changeConvertedCurrency]
  )
  /** 列配置 */
  const antdColumns: Array<ColumnType<any> & { hidden?: boolean; id: string; name?: string }> = useMemo(() => {
    const commonOpt = [
      {
        width: 60,
        id: 'index',
        title: t('order.create.index'),
        dataIndex: '',
        name: '',
        render: (text, record, index) => renderBody({ rowIndex: index, name: 'index', columnIndex: 0, field: { name: record.name } }),
      },
      {
        width: 200,
        id: 'productSku',
        name: 'parcel_goods_code',
        dataIndex: 'parcel_goods_code',
        title: t('order.list.orderDetailProductCode'),
        render: (text, record, index) => renderBody({ rowIndex: index, name: 'parcel_goods_code', field: { name: record.name } }),
      },
      {
        width: 200,
        id: 'productName',
        name: 'parcel_goods_name',
        dataIndex: 'parcel_goods_name',
        title: t('order.create.itemName'),
        render: (text, record, index) => renderBody({ rowIndex: index, name: 'parcel_goods_name', field: { name: record.name, rules: [ANTD_RULES.parcel_goods_name(true)] } }),
      },
      {
        width: 200,
        id: 'size',
        name: 'parcel_goods_size',
        dataIndex: 'parcel_goods_size',
        title: t('order.goods.size'),
        render: (text, record, index) => renderBody({ rowIndex: index, name: 'parcel_goods_size', field: { name: record.name } }),
      },
      {
        width: 200,
        id: 'cName',
        name: 'parcel_goods_cname',
        dataIndex: 'parcel_goods_cname',
        title: t('order.create.tableHeaderCName'),
        render: (text, record, index) => renderBody({ rowIndex: index, name: 'parcel_goods_cname', field: { name: record.name } }),
        hidden: !isCrossBorder || !isChinaCrossBorder,
      },
      {
        width: 200,
        id: 'enName',
        name: 'parcel_goods_ename',
        dataIndex: 'parcel_goods_ename',
        title: t('order.create.tableHeaderENName'),
        render: (text, record, index) =>
          renderBody({ rowIndex: index, name: 'parcel_goods_ename', field: { name: record.name, rules: isUS2US ? [] : [ANTD_RULES.parcel_goods_ename()] } }),
        hidden: !isCrossBorder && !isUS2US,
      },
      {
        width: 200,
        id: ORIGINAL_COUNTRY_KEY,
        name: ORIGINAL_COUNTRY_KEY,
        dataIndex: ORIGINAL_COUNTRY_KEY,
        title: t('order.create.tableHeader.orginalCountry'),
        render: (text, record, index) => renderBody({ rowIndex: index, name: ORIGINAL_COUNTRY_KEY, field: { name: record.name } }),
        hidden: !isCrossBorder,
      },
      {
        width: 200,
        id: 'hsCode',
        name: 'parcel_goods_hscode',
        dataIndex: 'parcel_goods_hscode',
        title: t('order.parcelTableHeader.HsCode'),
        render: (text, record, index) => renderBody({ rowIndex: index, name: 'parcel_goods_hscode', field: { name: record.name } }),
        hidden: !isCrossBorder && !IsIndonesia && senderCountry != 'US',
      },
      {
        width: 200,
        id: 'goodsMaterial',
        name: 'parcel_goods_material',
        dataIndex: 'parcel_goods_material',
        render: (text, record, index) => renderBody({ rowIndex: index, name: 'parcel_goods_material', field: { name: record.name } }),

        title: (
          <span className="flex align_c" style={{ minWidth: '200px' }}>
            {t('order.create.goodsMaterial')}
            <OneTooltip title={t('order.create.fillByEnglish')} placement="top" className={styles.tooltip}>
              <div>
                <Info className={styles.infoIcon} />
              </div>
            </OneTooltip>
          </span>
        ),
        hidden: !isChinaCrossBorder,
      },
      {
        id: 'goodsPurpose',
        name: 'parcel_goods_purpose',
        dataIndex: 'parcel_goods_purpose',
        render: (text, record, index) => renderBody({ rowIndex: index, name: 'parcel_goods_purpose', field: { name: record.name } }),

        title: (
          <span className="flex align_c" style={{ minWidth: '200px' }}>
            {t('order.create.goodsPurpose')}
            <OneTooltip title={t('order.create.fillByEnglish')} placement="top" className={styles.tooltip}>
              <div>
                <Info className={styles.infoIcon} />
              </div>
            </OneTooltip>
          </span>
        ),
        hidden: !isChinaCrossBorder,
      },
      {
        id: 'goodsWeight',
        name: 'parcel_goods_weight',
        dataIndex: 'parcel_goods_weight',
        render: (text, record, index) =>
          renderBody({
            rowIndex: index,
            name: 'parcel_goods_weight',
            field: { name: record.name, rules: isUS2US ? [] : [ANTD_RULES.parcel_goods_weight()] },
            inputProps: { suffix: 'kg' },
          }),
        title: t('order.create.goodsWeight'),
        hidden: !isCrossBorder && !isUS2US,
      },
      {
        width: 200,
        id: 'quantity',
        name: 'parcel_goods_quantity',
        dataIndex: 'parcel_goods_quantity',
        title: t('order.create.quantity'),
        render: (text, record, index) => renderBody({ rowIndex: index, name: 'parcel_goods_quantity', field: { name: record.name } }),
      },
      {
        width: 200,
        id: 'price',
        name: 'parcel_goods_price',
        dataIndex: 'parcel_goods_price',
        title: (
          <span className="flex align_c" style={{ minWidth: '200px' }}>
            {t('order.create.price')}{' '}
            <OneTooltip title={t('order.price.tips')} placement="top" className={styles.tooltip}>
              <div>
                <Info className={styles.infoIcon} />
              </div>
            </OneTooltip>
          </span>
        ),
        render: (text, record, index) => renderBody({ rowIndex: index, name: 'parcel_goods_price', field: { name: record.name } }),
      },
      {
        width: 200,
        id: 'parcel_goods_converted_price',
        name: 'parcel_goods_converted_price',
        dataIndex: 'parcel_goods_converted_price',
        title: (
          <span className="flex align_c" style={{ minWidth: '200px' }}>
            {t('order.rate.autoCurrencyTableHead')}
            <OneTooltip title={<Trans i18nKey={'order.rate.tips'} components={[<a href="https://www.xe.com/" target="_blank" />]} />} placement="top" className={styles.tooltip}>
              <div>
                <Info className={styles.infoIcon} />
              </div>
            </OneTooltip>
          </span>
        ),
        render: (text, record, index) => renderBody({ rowIndex: index, name: 'parcel_goods_converted_price', field: { name: record.name } }),
        hidden: !checkCurrency || !isShowCheckbox,
      },
      {
        width: 200,
        id: 'parcel_goods_url',
        name: 'parcel_goods_url',
        dataIndex: 'parcel_goods_url',
        title: t('parcel.commodity.url'),
        render: (text, record, index) => renderBody({ rowIndex: index, name: 'parcel_goods_url', field: { name: record.name } }),
        hidden: !isChinaCrossBorder,
      },
    ]
    return commonOpt.filter((item) => !item.hidden)
  }, [t, isCrossBorder, isChinaCrossBorder, IsIndonesia, checkCurrency, isShowCheckbox, renderBody, isUS2US, senderCountry])

  useEffect(() => {
    const parcel_show_keys = antdColumns.map((item) => item.name).filter(Boolean)

    formInstance.setFieldsValue({
      parcel_show_keys,
    })
  }, [antdColumns, formInstance])

  const handleAppend = useCallback(
    async (addFnc) => {
      const { parcel_currency_code: sourceCurrency, parcel_converted_currency_code: targetCurrency } = formInstance.getFieldsValue([
        'parcel_currency_code',
        'parcel_converted_currency_code',
      ])

      if (checkCurrency && isShowCheckbox) {
        const reqParams = {
          fetchTime,
          currencyRate,
          sourceAmountList: [times(Number(parcelItem.parcel_goods_price), 100)],
          sourceCurrency,
          targetCurrency,
        }
        const res = await fetchRateList(reqParams)
        if (res.bizCode === 'SUCCESS') {
          const { targetAmountList } = res?.data
          const convertedPrice = targetAmountList?.[0]
          const divideValue = !!convertedPrice || convertedPrice === 0 ? divide(Number(convertedPrice) || 0, 100) : ''
          addFnc({ ...parcelItem, parcel_goods_converted_price: divideValue })
          return
        }
      }

      addFnc(parcelItem)
    },
    [parcelItem, checkCurrency, currencyRate, fetchTime, formInstance, isShowCheckbox]
  )

  return (
    <div className={styles.Container}>
      <FromEmptyItem name="parcel_show_keys" />
      {/* 商品信息表单 */}
      <Form.List name="parcel_commodities">
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              <OneTable
                scroll={{ x: 900 }}
                columns={antdColumns.concat([
                  {
                    width: 100,
                    id: 'action',
                    title: '',
                    dataIndex: '',
                    render: (_, record, index) => renderBody({ rowIndex: index, columnIndex: antdColumns.length, name: 'action', field: { name: record.name }, remove }),
                  },
                ])}
                dataSource={fields}
                pagination={false}
                className={classNames(styles.noHover, styles.parcelFormTable)}
              />
              <Form.Item>
                <Form.ErrorList errors={errors} />

                <OneSecondaryButton
                  data-id="shipment_comdition_add"
                  className={styles.addition}
                  onClick={() => {
                    handleAppend(add)
                  }}
                  icon={<Plus />}
                >
                  {t('newOrder.add')}
                </OneSecondaryButton>
              </Form.Item>
            </>
          )
        }}
      </Form.List>
    </div>
  )
}

ParcelForm.displayName = 'ParcelForm'

export default ParcelForm
export { FIELDS }
