import { uniqBy } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { OneSelect } from '@yy/one-ui'
import CountryWithCode from '@/bizComponent/CountryWithCode'
import { useSelector } from '@/store'
import { filterCountryOption } from '@/utils'

export type TypeGlobalCountryListProps = {
  /** default false */
  textMode?: boolean
} & React.ComponentProps<typeof OneSelect>

const GlobalCountryList: React.FC<TypeGlobalCountryListProps> = ({ textMode, value, ...props }) => {
  const countryList = useSelector((state) => state.dictionary.countryDics)
  const countryMap = useSelector((state) => state.dictionary.countryMapDics)
  const { t } = useTranslation()

  if (textMode) {
    return <>{countryMap[value]?.countryName || ''}</>
  }

  return (
    <OneSelect showSearch filterOption={filterCountryOption} placeholder={t('common.select.placeholder')} className="original-country-select" value={value} {...props}>
      {uniqBy(countryList, 'countryCode').map((country) => (
        <OneSelect.Option title={country.countryName} key={country.countryCode} value={country.countryCode}>
          <CountryWithCode item={country} />
        </OneSelect.Option>
      ))}
    </OneSelect>
  )
}

export default GlobalCountryList
