import { OneMessage } from '@yy/one-ui'
import i18n from '@/services/i18n'
import store from '@/store'
import type { ITaskResultProps } from '@/store/notificationSlice/type'
import { logger } from '@/utils/logger'
import { getWebSoketMsgById } from '@/services/apis'

import { ERROR_TYPE_ENUM } from '@/services/sentry/constants'
import * as Sentry from '@sentry/react'

export { useVersionUpdate } from './useVersionUpdate'
export { useSocket } from './useSocket'
export * from './utils'

export type IExtraTaskOptions = {
  wsMsgId?: string
}

const traceTimeoutError = (taskId: string) => {
  const error = new Error(`websocket error: timeout`)
  const errorMessage = error.message
  Sentry.captureException(error, (scope) => {
    scope.setTag('type', ERROR_TYPE_ENUM.WS)
    scope.setTag('taskId', taskId)
    scope.setExtras({ message: errorMessage, error })
    scope.addBreadcrumb({
      type: 'error',
      category: 'ws',
      level: Sentry.Severity.Error,
      message: errorMessage,
      data: {
        taskId: taskId,
      },
    })

    return scope
  })
  logger.track({ type: 'action', event_type: 'error', event_target: 'ws', data: { taskId: taskId, message: errorMessage }, url: '' })
}

const lastTry = async (wsMsgId?: string) => {
  if (!wsMsgId) return null

  try {
    const { data } = await getWebSoketMsgById(wsMsgId)

    return data.success ? JSON.parse(data.payload) : null
  } catch {
    return null
  }
}

export type AwaitAsyncTaskOptions = {
  timeout?: number
  /** 任务失败时是否toast出来 */
  errorToast?: boolean
  /** 任务失败时是否抛错出来 */
  throwError?: boolean
  taskType?: string
  /** 传入的id类型 */
  idType?: 'nonce' | 'taskId'
  /** 超时是否抛出错误 */
  throwTimeoutError?: boolean
} & IExtraTaskOptions
/**
 * 等待某个异步任务推送
 * 如果没有配置throwTimeoutError，超时会返回null，请一定要处理超时错误
 * @param id
 * @returns
 */
// export async function awaitAsyncTask(id: string, timeout?: number): Promise<ITaskResultProps>
export async function awaitAsyncTask(id: string, options?: AwaitAsyncTaskOptions): Promise<ITaskResultProps>
export async function awaitAsyncTask(id: string, timeoutOrOptions: AwaitAsyncTaskOptions | number = { timeout: 60 * 1000 }) {
  let unsubscribe = null
  const options = typeof timeoutOrOptions === 'number' ? { timeout: timeoutOrOptions } : timeoutOrOptions
  const check = () => {
    const taskResultsMapAll = store.getState().notification.taskResultsMapAll
    const task = taskResultsMapAll.find((t) => {
      /** nonce is the temporaily key for the sepcial topics */
      const msgId = options.idType === 'nonce' ? t.nonce : t.taskId
      if (options.taskType) return id === msgId && t.taskType === options.taskType
      return id === msgId
    })

    // 处理错误
    if (options.errorToast && task && !task.response?.success) {
      const key = `system.error.${task.response?.code}`
      const parametes = { ...task.response?.extMap }
      const i18nText = i18n.t(key, parametes)
      const message = key === i18nText ? task.response?.message : i18nText
      OneMessage.error(message)
    }
    return task
  }
  // 先检查一下taskId是否已经存在
  const task = check()
  let timeoutHandler: NodeJS.Timeout
  if (task) {
    if (!task.response?.success && options.throwError) return Promise.reject(task)
    else return Promise.resolve(task)
  }

  // 如果没有，则等待通知
  const taskPromise = new Promise<ITaskResultProps>((resolve, reject) => {
    unsubscribe = store.subscribe(() => {
      const task = check()
      if (!task) return
      unsubscribe()
      clearTimeout(timeoutHandler)
      if (!task.response?.success && options.throwError) reject(task)
      else resolve(task)
    })
  })

  const timeoutPromise = new Promise<null>((resolve, reject) => {
    timeoutHandler = setTimeout(async () => {
      unsubscribe && unsubscribe()
      traceTimeoutError(id)

      try {
        const res = await lastTry(options.wsMsgId)

        if (res) {
          return resolve(res)
        }

        if (options.throwTimeoutError) reject(new Error('timeout'))
        else resolve(res)
      } catch {
        reject(new Error('timeout'))
      }
    }, options.timeout)
  })

  let res = null
  try {
    res = await Promise.race([taskPromise, timeoutPromise])
  } catch (error) {
    if (error?.message === 'timeout' && options.errorToast) {
      OneMessage.error(i18n.t('system.error.2'))
    }
    throw error
  }

  return res
}
