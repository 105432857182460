import type { LogisticsOrderInfo } from '../order/createSlice/types'
import type { ExpressListData } from '../serviceProviderSlice/types'
import { CUSTOM_FREIGHT_REASON_ENUM } from './enum'
import { SHIP_TYPE_ENUM } from '@/store/enum'
import type { IExpressData, IExpressProps, IProviderAddressVO, IProviderFormValues, IServiceProviderVO, ISetProviderProps } from './types'
import {
  calculateTotalPrice,
  convertAddress,
  formatProvinceUtil,
  getCodFee,
  getDeliveryTime,
  getExpressConfig,
  getPremium,
  getServiceProvider,
  getShipmentServiceType,
  handlePayment,
  handleSubAccount,
  initialPickupTimeWithChange,
  logger,
  shouldClearInsuranceAmountFn,
  sortProviderList,
} from './utils'
import { COD_TAB, SHIPMENT_PICKUP_TYPE } from '@/bizComponent/ServiceProvider/enum'
import { CurrencyConverted } from '@/store/order/batchCreateSlice/types'
import type { ProviderType } from '@/constant'
import { INSURANCE_SUPPORT_TYPE, INSURANCE_TYPE, PaymentTypeEnum, SAAS_FLAG } from '@/constant'
import { postFeeConvert } from '@/services/apis/feeConvert'
import i18n from '@/services/i18n'
import type { AppDispatch, AppGetState, AppThunk, AppThunkDispatch } from '@/store'
import { isCodCurrencyCodeSpecified, isKerryHk2TWFn, formatSenderAddressToService, formatRecipientAddressToService } from '@/utils'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { OneMessage } from '@yy/one-ui'
import type { FormInstance } from 'antd'
import { pick } from 'lodash'
import moment from 'moment'
import { divide, times } from 'number-precision'
import { convertStageToLogisticsType, isReverse } from '../utils'
import { ORDER_TYPE } from '@/store/parcelSlice/types'
import { getParcelInfo, handleCalculateFreightAndFeeItems } from './utils/feeItem'
import { DELIVER_VALUE_MAP } from '@/services/apis/sales/type'
import { sentryReport } from '@/services/sentry/report'
export * from './utils/feeItem'

const initialState: Partial<IServiceProviderVO> = {
  /**
   * 发货类型
   * @todo
   * 因为第二步与第三步均有发货的特殊场景
   * 这里应该统一在一个 reducer 中维护
   * @see /store/parcelSlice/index.ts
   */
  type: `${SHIP_TYPE_ENUM.NORMAL}`,
  tab: `${COD_TAB.ALL}`,
  filterArr: [],
  premiumLoading: false,
  codConvertLoading: false,
  codConvertResolved: true,
  freightLoading: false,
}

const configSlice = createSlice({
  name: 'newServiceProvider',
  initialState,
  reducers: {
    setProviderOptions(state, action: PayloadAction<IServiceProviderVO>) {
      return {
        ...state,
        ...action.payload,
      }
    },
    update(state, action: PayloadAction<Partial<IServiceProviderVO>>) {
      return {
        ...state,
        ...action.payload,
      }
    },
    /**
     * 更新依赖的 query
     * @param state
     * @param action
     * @returns
     */
    updatePropsAction(state, action: PayloadAction<IExpressProps>) {
      const props = action.payload
      return {
        ...state,
        props,
      }
    },
  },
})

/**
 * @todo
 * update 方法如果传入 Partial<T> 则无法正确入参，变成 update(unknow)
 * 这里开启后会导致不分 update 调用类型不一致
 */

export const { setProviderOptions, update, updatePropsAction } = configSlice.actions
export const setProps = (props: ISetProviderProps) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { newServiceProvider: state, dictionary } = getState()
  const { cover, type, ...payload } = props

  // 覆盖
  if (cover) {
    return {
      ...state,
      props: payload,
    }
  }

  const addressVO = {
    ...(state.props?.addressVO || {}),
    ...(payload?.addressVO || {}),
  }

  // update
  const updateProps = {
    ...(state.props || {}),
    ...payload,
    addressVO: formatProvinceUtil(addressVO),
  } as IExpressProps

  // 兼容字段
  if (props.arrival_method) {
    updateProps.supportedAddressTypes = [props.arrival_method == 1 ? 'HOME_STREET' : 'STORE_STREET']
  }
  // 第一次初始化，可能没有 currencyCode
  if (props?.addressVO?.arrival_countryCode && !state?.props?.currencyCode && !props?.currencyCode) {
    const { arrival_countryCode: arrivalCountry } = props?.addressVO
    const arrivalCurrencyCode = dictionary?.countryMapDics?.[arrivalCountry]?.currency
    if (arrivalCurrencyCode) {
      updateProps.currencyCode = arrivalCurrencyCode
    }
  }

  if (type) {
    await dispatch(
      update({
        type,
      })
    )
  }
  await dispatch(updatePropsAction(updateProps))

  /** 修改了收货国家 */
  if (payload?.addressVO?.arrival_countryCode) {
    dispatch(updateShippingInfo())
  }

  if (props.previousShipmentServiceType) {
    dispatch(
      update({
        previousShipmentServiceType: props.previousShipmentServiceType,
      })
    )
  }

  if (props.previousCodFee) {
    dispatch(
      update({
        previousCodFee: props.previousCodFee,
      })
    )
  }
}

const initConfig = {
  setStore: true,
  toast: false,
}

type IFetchProviderList = {
  setStore?: boolean
  toast?: boolean
  curExpTypeCode?: string
  orderCodCurrencyCode?: string
  form?: FormInstance
}

export const fetchProviderList = (config?: IFetchProviderList) => async (dispatch, getState: AppGetState, form: FormInstance) => {
  const { setStore = true, toast, curExpTypeCode, orderCodCurrencyCode, form: inputForm } = Object.assign({}, initConfig, config || {})
  const currentForm = form || inputForm

  const state = getState()
  const { user, newServiceProvider } = state
  const { isPrepaid } = user?.info ?? {}
  const departure_countryCode = currentForm?.getFieldValue('departure_countryCode')

  setStore &&
    dispatch(
      update({
        listLoading: true,
      })
    )
  try {
    const { list, expressList } = await getServiceProvider({
      isPrepaid,
      props: {
        ...newServiceProvider.props,
        addressVO: Object.assign({}, newServiceProvider?.props?.addressVO, departure_countryCode ? { departure_countryCode } : {}),
        queryDeliveryDate: true,
        curExpTypeCode: curExpTypeCode || newServiceProvider?.provider?.expressTypeCode,
      },
      type: newServiceProvider.type,
      orderCodCurrencyCode,
      provider_pickup_type: currentForm?.getFieldValue('provider_pickup_type'),
      partitionInfo: {
        ...getParcelInfo(currentForm),
      },
    })

    if (toast && !list?.length) {
      OneMessage.error(i18n.t('newOrder.noMatch'))
    }
    setStore &&
      dispatch(
        update({
          list,
          expressList,
        })
      )
    return list
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('get service provider list error', error)
    return []
  } finally {
    setStore &&
      dispatch(
        update({
          listLoading: false,
        })
      )
  }
}
export interface HandleOptionsData {
  provider: Partial<IExpressData>
  formValues?: IProviderFormValues
}

export const handleExtraConfig = (data: HandleOptionsData) => async (_: AppDispatch, getState: AppGetState, form: FormInstance) => {
  const { newServiceProvider } = getState()
  const { props } = newServiceProvider
  const { provider, formValues } = data
  const departure_countryCode = form.getFieldValue('departure_countryCode')
  const { type } = newServiceProvider

  /**
   * 获取揽收时间和税号展示配置
   * pickupTime 是跟服务产品具体指挂钩的，因此这里需要考虑数据默认值跟服务产品默认值的情况，因此他的初始化Options是根据具体值来确定的，而不是无脑取服务产品的值
   */
  const config = await (async () => {
    const { supportedPickupTypes, expressTypeCode, priceSettingInfo } = provider

    const expressConfig = await getExpressConfig({
      formValues,
      supportedPickupTypes,
      expressTypeCode,
      address: {
        senderCountryCode: props.addressVO?.departure_countryCode || departure_countryCode,
        consigneeCountryCode: props.addressVO?.arrival_countryCode,
      },
      priceSettingInfo,
      logisticsType: convertStageToLogisticsType(type),
      type,
    })

    // 默认选中的税号类型
    const defaultTaxType = formValues?.provider_tax_type || expressConfig?.extraConfig?.find((item) => item.key === 'customerTax')?.options?.[0]?.option
    return { ...expressConfig, defaultTaxType }
  })()

  return config
}

/**
 * 处理服务产品的 options
 * @description
 * 这里provider的来源可能是默认的服务产品信息，也有可能是用户在服务产品列表选择的信息
 */
export const handleOptions = (data: HandleOptionsData & { isInit?: boolean }) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { provider, formValues, isInit } = data
  const state = getState()
  const {
    newServiceProvider: { type },
    user: { walletList, info },
  } = state

  const senderCountryCode = info?.countryCode
  const { companyCode, settlementModes, estimatedCost, expressTypeCode, needSubAccount, saasFlag, supportedCheckoutTypes, freightCurrencyCode } = provider

  /** cod 选项 */
  const provider_payment_type = (() => {
    if (formValues?.provider_payment_type) {
      return formValues.provider_payment_type
    }

    const { type, filterArr, previousShipmentServiceType } = state.newServiceProvider

    const provider_payment_type = getShipmentServiceType({
      supportedCheckoutTypes,
      type,
      filterArr,
      previousShipmentServiceType,
    })

    return provider_payment_type
  })()

  /** 货到付款金额 */
  const provider_cod_amount = (() => {
    if (formValues?.provider_cod_amount) {
      return formValues.provider_cod_amount
    }

    const { type, previousCodFee, props } = state.newServiceProvider
    const orderCodAmount = props?.apiCodAmount
    const provider_cod_amount = getCodFee({
      provider_payment_type: provider_payment_type && Number(provider_payment_type),
      type,
      previousCodFee,
      orderCodAmount,
    })

    return provider_cod_amount
  })()

  /** 支付方式 */
  const paymentData = (() => {
    const isPrepaid = state?.user?.info?.isPrepaid
    const userCurrencyCodeList = walletList.map((wallet) => wallet.currencyCode)
    const freightCurrencyCodeNoInUserCurrencyCodeList = freightCurrencyCode && !userCurrencyCodeList.includes(freightCurrencyCode)

    const paymentData = handlePayment({
      companyCode,
      settlementModes,
      estimatedCost,
      isPrepaid,
      saasFlag,
      type,
      freightCurrencyCodeNoInUserCurrencyCodeList,
    })

    return paymentData
  })()

  const { dateData, extraConfig, defaultTaxType, supportStationPickUp } = await dispatch(
    handleExtraConfig({
      formValues,
      provider,
    })
  )

  /** 预约派送时间 */
  const deliveryTimeData = getDeliveryTime({
    provider,
    formValues: pick(formValues, ['provider_delivery_date', 'provider_delivery_time']),
  })

  const isKerryHk2TW = (() => {
    const { addressVO } = state.newServiceProvider.props ?? {}
    if (addressVO && senderCountryCode && addressVO.arrival_countryCode) {
      return isKerryHk2TWFn({
        companyCode,
        senderCountryCode,
        consigneeCountryCode: addressVO.arrival_countryCode,
      })
    } else {
      logger('发货国家或收货国家没有初始化')
      return false
    }
  })()

  /** 子账号的问题同 getPickupTime */
  const subAccountData = await (async () => {
    if (needSubAccount) {
      const { list, value } = await handleSubAccount({
        expressTypeCode,
        pickupType: companyCode === 'UPS' ? 0 : formValues?.provider_pickup_type ?? dateData.provider_pickup_type,
      })

      // 揽收方式为dropoff 到店寄件 时子账号返回空,toast配置账号
      if (!isInit && !list.length && dateData.provider_pickup_type === SHIPMENT_PICKUP_TYPE.dropOff) {
        OneMessage.error(i18n.t('system.error.LST-123677-B0012'))
      }

      return {
        list,
        defaultValue: value,
      }
    }

    return {
      list: [],
      defaultValue: '',
    }
  })()

  return {
    subAccountData,
    isKerryHk2TW,
    dateData,
    paymentData,
    provider_payment_type,
    provider_cod_amount,
    deliveryTimeData,
    extraConfig,
    defaultTaxType,
    supportStationPickUp,
  }
}

export const handlePickupMethodChangeWithSubAccount =
  (pickupType: number): AppThunk =>
  async (dispatch, getState, form: FormInstance) => {
    const state = getState()
    const { needSubAccount, expressTypeCode, companyCode } = state.newServiceProvider.provider ?? {}
    if (!needSubAccount) return

    const { list, value } = await handleSubAccount({
      expressTypeCode,
      // ups使用通用子账号
      pickupType: companyCode === 'UPS' ? 0 : pickupType,
    })

    // 揽收方式为dropoff 到店寄件 时子账号返回空,toast配置账号
    if (!list.length && pickupType === SHIPMENT_PICKUP_TYPE.dropOff) {
      OneMessage.error(i18n.t('system.error.LST-123677-B0012'))
    }

    dispatch(
      update({
        subAccountOption: list.map((item) => ({ value: item.accountNo, label: item.remark })),
      })
    )
    form?.setFieldsValue({
      provider_channel_seller_id: value,
    })
  }

/**
 * 处理选中服务产品
 * @returns
 */
export const handleSelectServiceProviderSlice =
  (provider: ExpressListData, inputForm?: FormInstance) => async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance) => {
    const formInstance = inputForm ?? form
    await dispatch(calculateGoodsPrice(formInstance, provider))
    await dispatch(clearCustomFreight(formInstance))
    const { newServiceProvider, user } = getState()

    const { previousProvider, type, props } = newServiceProvider
    const fieldsValue = formInstance.getFieldsValue()
    // 处理 options
    const { dateData, paymentData, subAccountData, isKerryHk2TW, provider_payment_type, provider_cod_amount, deliveryTimeData, extraConfig, defaultTaxType, supportStationPickUp } =
      await dispatch(
        handleOptions({
          provider,
          // 退货的时候收方式在前面选择了，所以要保留揽收方式
          formValues: isReverse(type) ? pick(fieldsValue, 'provider_pickup_type') : undefined,
        })
      )
    const finalProviderCodAmount = previousProvider ? form.getFieldValue('provider_cod_amount') : provider_cod_amount // 如果为切换服务产品，则provider保留原值

    dispatch(
      update({
        providerExtraConfig: extraConfig,
        providerDateOptions: dateData.shipmentPickupDateOptions,
        providerTimeOptions: dateData.shipmentPickupTimeRangOptions,
        supportStationPickUp,
        providerDeliveryDateOptions: deliveryTimeData.providerDeliveryDateOptions,
        providerPaymentOptions: paymentData.options,
        subAccountOption: subAccountData.list?.map((item) => ({ value: item.accountNo, label: item.remark })),
        isKerryHk2TW,
        provider,
        previousShipmentServiceType: provider_payment_type,
        props: { ...newServiceProvider.props, isAutoMatch: false },
      })
    )

    const preInsuranceType = form.getFieldValue('provider_insurance_type') //  上一次的值
    const preInsuranceAmount = form.getFieldValue('provider_insured_amount') //  上一次的值
    const supportIns = provider.insuranceSupport === INSURANCE_SUPPORT_TYPE.YES // 当前的服务产品是否支持保险

    /**
     * 服务商为 GTHK 必须买保险
     * @see https://shopline.yuque.com/mlu41d/gy9ldi/dbmtq9
     */
    /** 是否默认购买保险 */
    const provider_insurance_type = (() => {
      if (supportIns) {
        if (provider.companyCode === ('GHTK' as ProviderType)) {
          return INSURANCE_TYPE.YES
        }

        return preInsuranceType
      }

      return INSURANCE_TYPE.NO
    })()
    const shouldClearInsuranceAmount = shouldClearInsuranceAmountFn({
      preInsuranceType,
      provider,
      previousProvider,
      defaultCurrencyCode: user.info.currencyCode,
      type,
    })

    const formValues = {
      provider_cod_amount: finalProviderCodAmount,
      provider_payment_type,
      provider_pay_type: paymentData.defaultValue,
      provider_pickup_type: dateData.provider_pickup_type,
      provider_pickup_date: dateData.provider_pickup_date,
      provider_pickup_time_slots: dateData.provider_pickup_time_slots,
      provider_channel_seller_id: subAccountData.defaultValue,
      provider_insurance_type,
      provider_insured_amount: shouldClearInsuranceAmount ? '' : preInsuranceAmount,
      provider_premium: '', // 需要清空保费，会重新计算
      provider_delivery_date: deliveryTimeData.provider_delivery_date,
      provider_delivery_time: deliveryTimeData.provider_delivery_time,
      provider_tax_type: defaultTaxType,
      provider_consignee_name: fieldsValue?.arrival_fullName,
    } as Record<string, string | number>

    if (!fieldsValue.provider_cod_currency_code) {
      // 如果表单中COD币种没有值，说明之前没有选择服务产品，此时需要初始化cod币种：
      // 1.如果订单原始币种与商品币种一致，说明没有修改过商品币种，此时初始化为订单原始COD币种
      // 2.如果订单原始币种与商品币种不一致，说明已经修改过商品币种，此时初始化为商品的币种
      Object.assign(formValues, {
        provider_cod_currency_code: props?.orderInfo?.orderAmountCurrencyCode === fieldsValue.parcel_currency_code ? props?.apiCodCurrencyCode : fieldsValue.parcel_currency_code,
      })
    }

    // set it frist, because the calculate feeItems needs this field value
    formInstance?.setFieldsValue({
      provider_pickup_type: dateData.provider_pickup_type,
    })

    // 币种转换或自定义运费期间，有可能会做COD还原的逻辑，如果有还原，表单COD值需要重新设值
    let recoveryProviderCodAmount
    // 切换服务产品，cod币种处理：
    // 1.切换至指定COD币种的服务产品：币种转换-目标币种需要使用服务产品配置的特定币种；非币种转换-cod币种需要使用服务产品配置的特定币种
    if (isCodCurrencyCodeSpecified(provider?.codCurrencyType)) {
      const { codCurrencyConverted, sellerCustomFreight, providerCodAmount } = await dispatch(
        updateCodValue(
          {
            codFee: finalProviderCodAmount,
            currencyCode: fieldsValue.provider_cod_currency_code || formValues.provider_cod_currency_code,
            targetCurrencyCode: provider?.targetCodCurrencyCode,
          },
          'manual'
        )
      )
      if (!codCurrencyConverted) {
        if (provider?.targetCodCurrencyCode !== (fieldsValue.provider_cod_currency_code || formValues.provider_cod_currency_code)) {
          // 如果币种变了，又没有进行币种转换，则清空金额
          formValues.provider_cod_amount = null
          formValues.provider_custom_freight = sellerCustomFreight ? divide(sellerCustomFreight, 100) : sellerCustomFreight
        } else {
          recoveryProviderCodAmount = providerCodAmount
        }
        Object.assign(formValues, {
          provider_cod_currency_code: provider?.targetCodCurrencyCode,
        })
      } else {
        recoveryProviderCodAmount = providerCodAmount
      }
      // 2.指定COD币种的服务产品切换至普通服务产品，cod币种需要还原为普通逻辑：币种转换-目标币种为收货国家币种；非币种转换-cod币种为商品币种
    } else if (isCodCurrencyCodeSpecified(previousProvider?.codCurrencyType)) {
      const { codCurrencyConverted, providerCodAmount } = await dispatch(
        updateCodValue(
          {
            codFee: finalProviderCodAmount,
            currencyCode: fieldsValue.parcel_currency_code, // 币种重置为商品币种
          },
          'manual'
        )
      )
      // 非币种转换：从特殊渠道切换到非特殊渠道，币种重置为商品币种，金额清空
      if (!codCurrencyConverted) {
        Object.assign(formValues, {
          provider_cod_currency_code: fieldsValue.parcel_currency_code,
        })
        if (formValues.provider_cod_currency_code !== fieldsValue.provider_cod_currency_code) {
          // 如果币种变了，又没有进行币种转换，则清空金额
          formValues.provider_cod_amount = null
        }
      }
      recoveryProviderCodAmount = providerCodAmount
    } else {
      const { providerCodAmount } = await dispatch(
        updateCodValue(
          {
            codFee: finalProviderCodAmount,
            currencyCode: fieldsValue.provider_cod_currency_code || formValues.provider_cod_currency_code,
          },
          'manual'
        )
      )
      recoveryProviderCodAmount = providerCodAmount
    }
    if (recoveryProviderCodAmount) {
      formValues.provider_cod_amount = recoveryProviderCodAmount
    }
    formInstance?.setFieldsValue({
      ...formValues,
    })
    // 切换服务产品，会修改是否代收货款表单值（provider_payment_type），代收货款金额需要重新校验（避免代收货款金额disabled的情况下有些校验信息没有清除）
    formInstance.validateFields(['provider_cod_amount'])

    /**
     * 如果为 GTHK 则默认为购买保险, 这里需要重新计算商品价格, 包含自动转换货币逻辑
     * @see https://shopline.yuque.com/mlu41d/gy9ldi/dbmtq9
     */
    if (supportIns && provider.companyCode === ('GHTK' as ProviderType) && type !== 'batch' && type !== 'reverse-batch') {
      if (formValues.provider_insurance_type === INSURANCE_TYPE.YES) {
        await dispatch(handleInsuranceTypeYes({ forceCalculate: true }))
      }
    }

    // 重新计算保费
    if (preInsuranceAmount && supportIns && preInsuranceType === INSURANCE_TYPE.YES) {
      await dispatch(handleCalculatePremium(preInsuranceAmount))
    }

    dispatch(handleCalculateFreightAndFeeItems(formInstance))
  }

/**
 * 根据 expressCode 初始化服务产品
 * 不传 form 的话，可能拿到的 form 实例不是同一个
 * @param expressCode
 * @returns
 */
export const getProviderWithExpressCode =
  (expressCode: string, orderCodCurrencyCode?: string) =>
  async (dispatch: AppThunkDispatch, _: AppGetState, form: FormInstance): Promise<{ provider?: ExpressListData; isSame: boolean }> => {
    const list = await dispatch(
      fetchProviderList({
        setStore: false,
        /** for not filter currently order's express */
        curExpTypeCode: expressCode,
        orderCodCurrencyCode,
        form,
      })
    )

    const sortedList = sortProviderList(list, {
      filter: true,
    })

    const index = sortedList.findIndex((item) => item.expressTypeCode === expressCode)
    const provider = index !== -1 ? sortedList[index] : sortedList?.[0]
    return {
      provider: {
        ...provider,
      },
      isSame: index !== -1,
    }
  }

export interface InitProviderAndForm {
  provider: Partial<IExpressData>
  // 指定币种服务产品目标币种需要根据商品币种判断：如果商品币种在服务产品指定币种，则目标币种为商品币种，否则为指定币种第一个
  formValues?: IProviderFormValues & { parcel_currency_code?: string }
  props?: IExpressProps & Partial<Pick<IServiceProviderVO, 'type'>>
}

/**
 * 从订单详情初始化
 * @description
 * *注意:*
 * 编辑场景, 表单详情是以服务产品信息为基础, 部分修改了服务产品选项
 * 如果只走服务产品初始化逻辑, 会导致后续逻辑错误
 * 例如下面 handleOptions 函数中 getPickupTime
 * 所需要的 supportedPickupTypes 为 provider 基础信息,
 * 实际上订单详情信息已经修改过服务产品的表单选项,
 * 所以会导致初始化值跟 options 无法对上;
 * 所以函数中的操作均以 `formValues` 的值优先级最高
 * 只会设置name为 provider_ 开头的表单值
 */
export const initProviderAndForm = (payload: InitProviderAndForm) => async (dispatch: AppThunkDispatch, _getState: AppGetState, form: FormInstance) => {
  const { provider, formValues, props } = payload
  // 初始化，先重置cod币种转换相关数据
  dispatch(
    update({
      value: {
        codCurrencyRate: undefined,
        codFetchTime: undefined,
        codCurrencyConverted: false,
      },
      codConvertResolved: true,
    })
  )
  // 先 set props
  if (props) {
    await dispatch(setProps(props))
  }
  // 如果 Provider 为空，不初始化后面
  if (!provider || !provider.companyCode || !provider?.expressTypeCode) {
    return
  }

  // 处理 options
  /** 这里如果是用provider的话需要考虑是初始化的场景还是选择服务产品初始化的场景 */
  const { dateData, paymentData, subAccountData, isKerryHk2TW, provider_cod_amount, provider_payment_type, deliveryTimeData, extraConfig, defaultTaxType, supportStationPickUp } =
    await dispatch(handleOptions({ provider, formValues, isInit: true }))
  dispatch(
    update({
      providerExtraConfig: extraConfig,
      providerDateOptions: dateData.shipmentPickupDateOptions,
      providerTimeOptions: dateData.shipmentPickupTimeRangOptions,
      supportStationPickUp,
      providerDeliveryDateOptions: deliveryTimeData.providerDeliveryDateOptions,
      providerDeliveryTimeOptions: deliveryTimeData.providerDeliveryTimeOptions,
      providerPaymentOptions: paymentData.options,
      subAccountOption: subAccountData.list?.map((item) => ({ value: item.accountNo, label: item.remark })),
      isKerryHk2TW,
      previousCodFee: props?.type !== 'merge' ? formValues?.provider_cod_amount || undefined : undefined,
      previousShipmentServiceType: formValues?.provider_payment_type || undefined,
      provider,
    })
  )
  const updateFormValues: Record<string, any> = {
    provider_cod_amount,
    provider_cod_currency_code: formValues?.provider_cod_currency_code,
    provider_payment_type,
    provider_pay_type: paymentData.defaultValue,
    provider_pickup_type: dateData.provider_pickup_type,
    provider_pickup_date: dateData.provider_pickup_date,
    provider_pickup_time_slots: dateData.provider_pickup_time_slots,
    provider_channel_seller_id: subAccountData.defaultValue,
    provider_tax_type: defaultTaxType,
  }

  // 如果有传入值，优先使用 form 的值
  /** 这里会存在一个问题，会用订单给的formValues覆盖服务产品的默认信息，但是options的又是用服务产品默认信息获取的  */
  if (formValues) {
    Object.assign(updateFormValues, {
      ...formValues,
      provider_channel_seller_id: formValues.provider_channel_seller_id || subAccountData.defaultValue,
      provider_pickup_type: formValues.provider_pickup_type ?? dateData.provider_pickup_type,
      provider_invoice_date: formValues.provider_invoice_date ? moment(formValues.provider_invoice_date) : undefined,
    })
  }

  Object.assign(updateFormValues, {
    provider_pay_type: paymentData.list.includes(updateFormValues.provider_pay_type) ? updateFormValues.provider_pay_type : paymentData.defaultValue,
  })

  Object.assign(updateFormValues, {
    provider_delivery_time: deliveryTimeData.provider_delivery_time,
    provider_delivery_date: deliveryTimeData.provider_delivery_date,
  })

  const finalFormValues = pick(
    updateFormValues,
    Object.keys(updateFormValues).filter((key) => key.startsWith('provider_'))
  )

  /** 增值服务 */
  finalFormValues.provider_service_types = updateFormValues.provider_service_types

  const { codCurrencyConverted } = await dispatch(
    updateCodValue(
      {
        codFee: provider_cod_amount,
        currencyCode: formValues?.provider_cod_currency_code,
      },
      'manual'
    )
  )

  //指定COD币种的服务产品，非币种转换的情况下，且指定币种与原始COD币种不一致的情况下，cod币种需要切换为指定币种：
  const finalTargetCodCurrencyCode = provider?.supportedCurrencies?.includes(formValues.parcel_currency_code) ? formValues.parcel_currency_code : provider?.targetCodCurrencyCode
  const shouldSwitchToProviderTargetCodCurrencyCode = () => {
    return (
      !codCurrencyConverted &&
      isCodCurrencyCodeSpecified(provider?.codCurrencyType) &&
      finalTargetCodCurrencyCode &&
      finalTargetCodCurrencyCode !== formValues?.provider_cod_currency_code
    )
  }

  if (shouldSwitchToProviderTargetCodCurrencyCode()) {
    // 默认选中第一个
    Object.assign(finalFormValues, {
      provider_cod_currency_code: finalTargetCodCurrencyCode,
    })
    // 重置COD币种后，金额也重置
    Object.assign(finalFormValues, {
      provider_cod_amount: '',
    })
  }
  form?.setFieldsValue(finalFormValues)
  dispatch(handleCalculateFreightAndFeeItems(form))
}

const clearCustomFreight =
  (inputForm: FormInstance): AppThunk =>
  async (dispatch) => {
    await dispatch(
      setProps({
        osFreightErrorCode: undefined,
      })
    )

    inputForm.setFieldsValue({
      provider_custom_freight_currency_code: undefined,
      provider_custom_freight: undefined,
    })
  }

/**
 * 修改揽收方式
 * @param provider_pickup_type
 * @returns
 */
export const handleShipmentPickupTypeChange =
  (provider_pickup_type: number): AppThunk =>
  async (dispatch, getState, form: FormInstance) => {
    const state = getState()
    const providerDateOptions = state.newServiceProvider.providerDateOptions
    const { providerTimeOptions, ...formValues } = initialPickupTimeWithChange(provider_pickup_type, {
      providerDateOptions,
    })

    dispatch(
      update({
        providerTimeOptions,
      })
    )

    form.setFieldsValue(formValues)
    dispatch(handleShipmentDateChange(formValues.provider_pickup_date))

    dispatch(
      updateCodValue({
        codFee: form.getFieldValue('provider_cod_amount'),
      })
    )
  }

export const handleKeepPreviousCodFee =
  (codFee): AppThunk =>
  (dispatch) => {
    dispatch(
      update({
        previousCodFee: codFee,
      })
    )
  }

/** 监听 cod 选项 */
export const handleShipmentServerType =
  (provider_payment_type: number): AppThunk =>
  async (dispatch, getState, form: FormInstance) => {
    const { newServiceProvider } = getState()

    /** 因为第一次没有值，所以没有初始化 */
    const { previousCodFee, previousCodFeeTarget, props } = newServiceProvider
    if (provider_payment_type === PaymentTypeEnum.cod) {
      const { parcel_currency_code, provider_cod_currency_code } = form.getFieldsValue()
      let finalCodAmount: string | number = ''
      if (previousCodFee) {
        finalCodAmount = previousCodFee
      } else if (provider_cod_currency_code === parcel_currency_code) {
        finalCodAmount = props?.calculateCodFee
      }
      form.setFieldsValue({
        provider_cod_amount: finalCodAmount, // 如果第一次没有COD值，则赋值为计算的值，否则赋值为上一次的值
        provider_cod_amount_target: previousCodFee ? previousCodFeeTarget : 0,
      })
      await dispatch(
        updateCodValue({
          codFee: finalCodAmount,
        })
      )
    } else {
      dispatch(handleCalculateFreightAndFeeItems(form))
    }

    dispatch(
      update({
        previousShipmentServiceType: provider_payment_type,
      })
    )
  }

interface HandleInsuranceTypeYesOptions {
  forceCalculate?: boolean
  orderInfo?: LogisticsOrderInfo
}

/**
 * 监听选择购买保险服务
 * @returns
 */
export const handleInsuranceTypeYes =
  (options?: HandleInsuranceTypeYesOptions): AppThunk =>
  async (dispatch, getState, form: FormInstance) => {
    const { forceCalculate = false, orderInfo } = options || {}
    const state = getState()
    const { isPrepaid, countryCode } = state.user.info
    const provider = state.newServiceProvider.provider
    const supportIns = provider.insuranceSupport === INSURANCE_SUPPORT_TYPE.YES // 当前的服务产品是否支持保险

    if (isPrepaid && supportIns && !provider.insuranceSetting && provider.saasFlag !== SAAS_FLAG.YES) {
      // 预付用户，没配保费模板提示
      OneMessage.info(i18n.t('insurance.setting.info'))
    }

    const departure_countryCode = form.getFieldValue('departure_countryCode') || state?.newServiceProvider?.props?.addressVO?.departure_countryCode

    const { currency } = state?.dictionary?.countryDics.find((item) => item.countryCode === departure_countryCode) || {}

    // 运费币种（也是包裹价值币种）
    const freightCurrencyCode = provider.freightCurrencyCode || currency || countryCode
    if (provider.saasFlag !== SAAS_FLAG.YES) {
      if (orderInfo && freightCurrencyCode !== orderInfo.feeInfo.freightCurrencyCode) {
        // 包裹价值币种不等于原包裹价值币种，需要清除包裹价值和保费
        form.setFieldsValue({
          provider_insured_amount: '',
          provider_premium: '',
        })
        return
      }
    }

    // 包裹价值，优先用后端的数据
    if (forceCalculate !== true && form.getFieldValue('provider_insured_amount')) {
      dispatch(handleCalculatePremium(form.getFieldValue('provider_insured_amount')))
      return
    }

    const hasCurrencyRate = state.common.setting.CURRENCY_RATE.dataValue === '1'
    const maxInsuredAmount = state.newServiceProvider.provider.insuranceSetting?.maxInsuredAmount

    const { parcel_commodities, parcel_currency_code } = form.getFieldsValue()
    const totalPrice = await calculateTotalPrice({
      parcel_commodities,
      currencyCode: parcel_currency_code,
      freightCurrencyCode,
      hasCurrencyRate,
      maxInsuredAmount,
    })

    form.setFieldsValue({
      provider_insured_amount: totalPrice,
    })

    dispatch(handleCalculatePremium(totalPrice))
  }

/**
 * 计算保费
 * @param provider_insured_amount
 * @returns
 */
export const handleCalculatePremium =
  (provider_insured_amount: string | number): AppThunk =>
  async (dispatch, getState, form: FormInstance) => {
    if (!provider_insured_amount) {
      return
    }

    await form.validateFields(['provider_insured_amount'])

    const state = getState()
    const countryDics = state.dictionary.countryDics
    const { freightCurrencyCode } = state.newServiceProvider.provider

    const { addressVO: inputAddressVO, supportedAddressTypes, parcel_weight, parcel_weight_unit, parcel_height, parcel_length, parcel_width } = state.newServiceProvider.props
    const { type } = state.newServiceProvider
    const { expressTypeCode, insuranceSetting } = state.newServiceProvider.provider

    if (!insuranceSetting) {
      // 没配置保费模板，不计算保费
      form.setFieldsValue({
        provider_premium: '',
      })
      return
    }

    const isHome = supportedAddressTypes?.[0] === 'HOME_STREET'
    const addressVO = convertAddress({
      addressVO: inputAddressVO,
      type,
      isHome,
    })
    const insuredAmount = times(Number(provider_insured_amount), 100)
    const currencyCode = countryDics.filter((item) => item.countryCode === inputAddressVO.departure_countryCode)?.[0].currency

    const params = [
      {
        expressTypeCode,
        metadatas: [
          {
            weightUnit: parcel_weight_unit || 'kg',
            weight: Number(parcel_weight),
            length: Number(parcel_length),
            width: Number(parcel_width),
            height: Number(parcel_height),
            lengthUnit: 'cm',
            insuredAmount,
            calculatePremium: true,
            calculateFreight: false,
            currencyCode: freightCurrencyCode, // 保费币种跟着运费币种
            metadataId: Math.floor(Math.random() * Math.pow(10, 20)) + '',
            senderAddress: addressVO?.senderAddressName,
            consigneeAddress: addressVO?.consigneeAddressName,
            priceChannelCustomParam: {
              // UPS新增渠道特殊字段
              currencyCode: currencyCode, // 发件国家币种
              insuredAmount, // 包裹价值
              insuranceType: 1, // 是否购买保险
              pickupType: form.getFieldValue('provider_pickup_type'),
            },
          },
        ],
      },
    ]

    dispatch(
      update({
        premiumLoading: true,
      })
    )

    try {
      const { premium, currencyCode } = await getPremium(params)
      form.setFieldsValue({
        provider_premium: typeof premium === 'number' ? divide(premium, 100) : '',
      })
      // 如果保费币种与运费币种不一致，sentry上报
      if (currencyCode && freightCurrencyCode && currencyCode !== freightCurrencyCode) {
        sentryReport(`attention: insurance currencyCode(${currencyCode}) is different from freight currencyCode(${freightCurrencyCode})`)
      }
    } catch (error) {}

    dispatch(
      update({
        premiumLoading: false,
      })
    )
  }

/**
 * 监听揽收日期
 * @param provider_pickup_date
 * @returns
 */
export const handleShipmentDateChange =
  (provider_pickup_date: string): AppThunk =>
  (dispatch, getState, form: FormInstance) => {
    const state = getState()
    const timeOptions = state.newServiceProvider.providerDateOptions?.find((item) => item.date === provider_pickup_date)?.timeIntervals ?? []
    const provider_pickup_time_slots = timeOptions?.[0]

    dispatch(
      update({
        providerTimeOptions: timeOptions,
      })
    )

    form?.setFieldsValue({
      provider_pickup_time_slots,
    })
  }

/**
 * 更新地址数据
 * @param payload
 * @returns
 */
export const updateAddressVO = (payload: IProviderAddressVO) => (dispatch, getState: AppGetState) => {
  const { newServiceProvider } = getState()
  const { props } = newServiceProvider

  const addressVO = {
    ...(props?.addressVO || {}),
    ...payload,
  }

  dispatch(
    updatePropsAction({
      ...props,
      addressVO: formatProvinceUtil(addressVO),
    })
  )
}

/**
 * 清除现有服务产品
 * @returns
 */
export const clearProvider = (): AppThunk => (dispatch, getState, form: FormInstance) => {
  dispatch(
    update({
      list: [],
      type: SHIP_TYPE_ENUM.NORMAL,
      props: {},
      previousCodFee: undefined,
      previousShipmentServiceType: undefined,
      providerDeliveryDateOptions: [],
      providerDeliveryTimeOptions: [],
    })
  )

  dispatch(clearSelectedProvider({ inputForm: form }))
}

/**
 * 清除现有币种转换数据
 * @returns
 */
export const clearCodValue = (): AppThunk => (dispatch) => {
  dispatch(
    update({
      value: {
        codCurrencyRate: undefined,
        codFetchTime: undefined,
        codCurrencyConverted: false,
      },
      codConvertResolved: true,
    })
  )
}

/**
 * 清除现有服务产品和列表
 * @returns
 */
export const clearSelectedProvider = (data: { inputForm?: FormInstance; clearForm?: boolean }) => (dispatch, getState, form: FormInstance) => {
  const { inputForm, clearForm = true } = data
  dispatch(
    update({
      provider: null,
      providerDateOptions: [],
      providerTimeOptions: [],
      providerPaymentOptions: [],
      tab: 0,
      filterArr: [],
      previousCodFee: undefined,
      previousShipmentServiceType: undefined,
      previousProvider: undefined,
    })
  )

  // 不需要清除表单的情况
  if (!clearForm) {
    return
  }
  /**
   * 优先使用外面的
   */
  ;(inputForm || form)?.setFieldsValue({
    provider_payment_type: undefined,
    provider_cod_amount: undefined,

    /**
     *  发货方式
     */
    provider_pickup_type: undefined,

    /**
     * 预约揽收日期
     */
    provider_pickup_date: undefined,

    /**
     * 预约揽收时间
     */
    provider_pickup_time_slots: undefined,

    /**
     * 支付方式
     */
    provider_pay_type: undefined,

    /**
     * 月结账号
     */
    provider_monthly_account: undefined,

    /**
     * 月结子账号
     */
    provider_channel_seller_id: undefined,

    /**
     * 证件类型
     */
    provider_consignee_id_type: undefined,

    /**
     * 证件号
     */
    provider_consignee_id_num: undefined,

    /**
     * 税号类型
     */
    provider_tax_type: undefined,

    /**
     * 税号
     */
    provider_tax_no: undefined,

    /**
     * EORI号码
     */
    provider_custom_id_no: undefined,

    /**
     * 收件人姓名
     */
    provider_consignee_name: undefined,

    provider_invoice_date: undefined,
    provider_package_path: undefined,
    provider_invoice_no: undefined,
    provider_package_desc: undefined,
    provider_hazmat_type_code: undefined,

    provider_custom_freight: undefined,
    provider_custom_freight_currency_code: undefined,
  })
}

/**
 * 是否需要清除服务产品
 * @returns
 */
export const shouldClearProvider = (params?: { shouldShowError?: boolean }) => async (dispatch, getState: AppGetState, form: FormInstance) => {
  const { shouldShowError = true } = params || {}

  const { newServiceProvider } = getState()

  const { provider } = newServiceProvider

  const parcel_currency_code = form.getFieldValue('parcel_currency_code')

  const list: IExpressData[] = await dispatch(
    fetchProviderList({
      setStore: true,
      toast: shouldShowError,
      curExpTypeCode: provider?.expressTypeCode,
      orderCodCurrencyCode: parcel_currency_code, // 将商品币种传给服务端，服务端判断
      form,
    })
  )

  const sortedList = sortProviderList(list, {
    filter: true,
  })

  /**
   * if there has not any selected provider no need to update the following logic
   */
  if (!provider) {
    return
  }

  const updateProvider = sortedList.find((item) => item?.expressTypeCode === provider?.expressTypeCode)

  // 是否需要清除选中的 provider
  if (!updateProvider) {
    // 这里是第三步的时候如果服务产品不再支持的情况下，不清除 form
    dispatch(clearSelectedProvider({ clearForm: false }))

    // 如果不清除就更新一下服务产品
  } else {
    // 更新服务产品
    dispatch(
      update({
        provider: {
          ...(provider || {}),
          ...updateProvider,
        },
      })
    )

    // 更新支付方式选项
    const { defaultValue } = dispatch(handlePayTypeOptions()) || {}

    //  update extra config
    const formValues = form.getFieldsValue()

    const configData = await dispatch(
      handleExtraConfig({
        formValues,
        provider,
      })
    )

    dispatch(
      update({
        providerExtraConfig: configData.extraConfig,
        providerDateOptions: configData?.dateData?.shipmentPickupDateOptions,
        providerTimeOptions: configData?.dateData?.shipmentPickupTimeRangOptions,
        supportStationPickUp: configData.supportStationPickUp,
      })
    )

    const updateFormValues = {
      provider_pickup_type: configData?.dateData.provider_pickup_type,
      provider_pickup_date: configData?.dateData.provider_pickup_date,
      provider_pickup_time_slots: configData?.dateData?.provider_pickup_time_slots,
      provider_tax_type: configData?.defaultTaxType,
      provider_pay_type: form.getFieldValue('provider_pay_type') || defaultValue,
    }

    form.setFieldsValue(updateFormValues)

    // 此处应该调用创单时将商品价格和数量计算得出初始COD金额逻辑（运费试算跳转创单，有初始服务产品，如果不调用的话，就不会走这段逻辑，所以此处需调用）
    await dispatch(calculateGoodsPrice(form, updateProvider))

    /** recalculate the custom freight */
    await dispatch(
      updateCodValue({
        codFee: form.getFieldValue('provider_cod_amount'),
      })
    )
  }
}

/** 支付方式选项
 * 根据服务产品运费更新付款方式options
 * 用户币种钱包没有此服务产品运费币种，只支持【到付现结】2(因为到付现结是线下给运费的，不扣钱包钱)
 * 算不出运费，也只支持【到付现结】
 */
export const handlePayTypeOptions = (): AppThunk<Pick<ReturnType<typeof handlePayment>, 'defaultValue' | 'options'>> => (dispatch, getState) => {
  const { user, newServiceProvider } = getState()
  const { provider, type } = newServiceProvider

  if (provider) {
    const walletList = user.walletList
    const isPrepaid = user.info.isPrepaid
    const { freightCurrencyCode, saasFlag, estimatedCost = 0, companyCode, settlementModes } = provider
    const userCurrencyCodeList = walletList.map((wallet) => wallet.currencyCode)
    const freightCurrencyCodeNoInUserCurrencyCodeList = freightCurrencyCode && !userCurrencyCodeList.includes(freightCurrencyCode)

    const { options, defaultValue } = handlePayment({
      companyCode,
      settlementModes,
      estimatedCost,
      isPrepaid,
      saasFlag,
      type,
      freightCurrencyCodeNoInUserCurrencyCodeList,
    })

    dispatch(
      update({
        providerPaymentOptions: options,
      })
    )

    return { options, defaultValue }
  }

  return null
}

/**
 * 更新临时存储表单值
 * @param updateValue
 * @returns
 */
export const updateValue = (updateValue?: IServiceProviderVO['value']) => async (dispatch) => {
  dispatch(
    update({
      value: updateValue,
    })
  )
}

const isNumber = (value: unknown) => typeof value === 'number'

export const updateCodValue =
  (data: { codFee: number | string; currencyCode?: string; inputForm?: FormInstance; targetCurrencyCode?: string }, type: 'manual' | 'auto' = 'auto') =>
  async (dispatch, getState: AppGetState, form: FormInstance) => {
    const isAuto = type === 'auto'
    const { codFee, currencyCode, inputForm } = data
    const state = getState()
    const { provider, props } = state.newServiceProvider
    const { props: storeProps } = state.parcelSlice
    const { orderInfo: originOrderInfo, supportedAddressTypes } = props

    const isHome = supportedAddressTypes?.[0] === 'HOME_STREET'

    /** 用户的币种开关设置 */
    const setting = state?.common['setting'] as any
    const isConvertedOpen = !!Number(setting?.['CURRENCY_RATE']?.dataValue)
    const isCustomFreightOpen = !!Number(setting?.['SELLER_FEE_SETTING_SWITCH']?.dataValue)

    const finalForm = inputForm || form

    const handleCodHandleFeeCalculate = () => {
      if (isAuto) {
        dispatch(handleCalculateFreightAndFeeItems(finalForm))
      }
    }

    /** 用户设置的币种及自定义运费开关都关闭 */
    if (!isConvertedOpen && !isCustomFreightOpen) {
      // if there is not provider being selected, not necessary to calculate
      if (provider) {
        handleCodHandleFeeCalculate()
      }

      return {
        codCurrencyConverted: false,
        sellerCustomFreight: undefined,
      }
    }

    const values = finalForm?.getFieldsValue()
    // 转换前币种取值优先级：传入的转换前币种 > 表单中cod原始币种
    const finalCurrencyCode = currencyCode || values['provider_cod_currency_code']

    const shouldRecovery =
      originOrderInfo?.salesOrderAmount &&
      isNumber(originOrderInfo?.salesOrderFreight) &&
      // 是否扣减运费，记录是否修改过，修改会置空
      props?.deductedFreight &&
      // 原订单币种是否与现有cod币种一致
      originOrderInfo?.salesOrderCurrencyCode === finalCurrencyCode
    // 如果要计算扣减，则传入订单原始orderAmount给后端计算扣减
    const finalCodFee = shouldRecovery ? originOrderInfo?.salesOrderAmount : times(Number(codFee), 100)
    // 运费没填，不调用接口计算计算币种转换，需要前端判断是否是发生币种转换的情况
    if (!finalCodFee) {
      // 如果COD金额为空，清空自定义运费及转换后COD金额（需要有COD才能算出自定义运费）
      form.setFieldsValue({
        provider_custom_freight: undefined,
        provider_cod_currency_target: undefined,
      })
      // cod金额为空，需要前端判断是否能币种转换，逻辑如下：
      // 1.如果为指定币种服务产品，cod原始币种不在指定币种范围内，则判断为会发生币种转换
      // 2.如果为关联目的地币种，COD原始币种与目标币种不一致，则判断为会发生币种转换
      const canConverted = () => {
        if (isCodCurrencyCodeSpecified(provider?.codCurrencyType)) {
          return !provider?.supportedCurrencies?.includes(finalCurrencyCode)
        } else {
          return finalCurrencyCode !== provider?.targetCodCurrencyCode
        }
      }
      // 原始币种与目标币种不一致，默认为满足币种转换的情况
      if (isConvertedOpen && canConverted()) {
        // 重置转换后金额
        form.setFieldsValue({
          provider_cod_amount_target: 0,
        })
        handleCodHandleFeeCalculate()
        return {
          codCurrencyConverted: true,
          sellerCustomFreight: undefined,
        }
      } else {
        handleCodHandleFeeCalculate()
        return {
          codCurrencyConverted: false,
          sellerCustomFreight: undefined,
        }
      }
    }
    const address = {
      ...formatSenderAddressToService({
        ...props?.addressVO,
        ...(values.departure_countryCode ? { departure_countryCode: values.departure_countryCode } : {}),
      }),
      ...formatRecipientAddressToService({ ...props?.addressVO }),
    }
    const feeConvertInfo = {
      currencyCode: finalCurrencyCode,
      sellerCodAmount: finalCodFee,
      sellerCodCurrencyCode: finalCurrencyCode,
    }
    // 除了运单编辑，其他场景都需要计算自定义运费
    const needSellerCustomFreight = storeProps?.orderType !== ORDER_TYPE.LOGISTICS_EDIT

    dispatch(
      update({
        codConvertLoading: true,
        codConvertResolved: false,
      })
    )
    try {
      const res = await postFeeConvert({
        expressTypeCode: provider?.expressTypeCode,
        width: props?.parcel_width as number,
        height: props?.parcel_height as number,
        length: props?.parcel_length as number,
        weight: props?.parcel_weight as number,
        address,
        feeConvertInfo,
        needSellerCustomFreight,
        isECommerce: originOrderInfo?.isECommerce,
        // 是否扣减运费，记录是否修改过，修改会置空,修改过cod,就不需要进行原始运费扣减
        salesOrderFreight: props?.deductedFreight ? originOrderInfo?.salesOrderFreight : 0,
        salesOrderCurrencyCode: props?.deductedFreight ? originOrderInfo?.salesOrderCurrencyCode : '',
        partitionInfo: {
          pickupType: finalForm?.getFieldValue('provider_pickup_type'),
          deliveryType: isHome ? DELIVER_VALUE_MAP.HOME : DELIVER_VALUE_MAP.STORE,
        },
      })
      if (res.bizCode === 'SUCCESS') {
        const {
          sellerCodAmount,
          sellerCodCurrencyCode,
          codFetchTime,
          codCurrencyRate,
          applySellerCustomFreight,
          sellerCustomFreightErrorCode,
          sellerCustomFreight,
          sellerCustomFreightCurrencyCode,
          deductedFreight,
        } = res?.data
        const codCurrencyConverted = res?.data.codCurrencyConverted === CurrencyConverted.CONVERTED
        const shipmentCodFeeTarget = divide(Number(sellerCodAmount), 100)
        dispatch(
          update({
            value: {
              codCurrencyRate: codCurrencyConverted ? codCurrencyRate : undefined,
              codFetchTime: codCurrencyConverted ? codFetchTime : undefined,
              codCurrencyConverted: codCurrencyConverted,
            },
            previousCodFeeTarget: shipmentCodFeeTarget,
          })
        )
        if (codCurrencyConverted) {
          form.setFieldsValue({
            provider_cod_currency_code: finalCurrencyCode,
            provider_cod_currency_target: sellerCodCurrencyCode,
            provider_cod_amount_target: shipmentCodFeeTarget,
          })
        }
        form.setFieldsValue({
          provider_custom_freight_currency_code: sellerCustomFreightCurrencyCode,
          provider_custom_freight: divide(sellerCustomFreight || 0, 100),
        })
        if (applySellerCustomFreight) {
          // 应用自定义运费
          await dispatch(
            setProps({
              osFreightErrorCode: undefined,
            })
          )
        } else {
          /**
           * 设置自定义运费错误
           */
          const setCustomFreightError = async (error: string) => {
            // price error with the OneShip custom freight calculation
            type IFreightErrorType = `${CUSTOM_FREIGHT_REASON_ENUM}`
            await dispatch(
              setProps({
                osFreightErrorCode: error as IFreightErrorType,
              })
            )
          }

          // 应用自定义运费失败
          if (sellerCodCurrencyCode && sellerCustomFreightCurrencyCode && sellerCodCurrencyCode !== sellerCustomFreightCurrencyCode) {
            setCustomFreightError(CUSTOM_FREIGHT_REASON_ENUM.CAN_NOT_USE)
          } else if (sellerCustomFreightErrorCode) {
            setCustomFreightError(sellerCustomFreightErrorCode)
          }
        }

        // 处理扣减逻辑
        // 如果没有应用自定义运费，又有原始扣减值，表单中的COD要需要恢复减去运费的值
        let providerCodAmount = undefined
        if (shouldRecovery) {
          if (!deductedFreight) {
            providerCodAmount = divide(finalCodFee, 100)
            form.setFieldsValue({
              provider_cod_amount: providerCodAmount,
            })
          } else {
            providerCodAmount = divide(finalCodFee - deductedFreight, 100)
          }
          form.setFieldsValue({
            provider_cod_amount: providerCodAmount,
          })
        }
        handleCodHandleFeeCalculate()
        return {
          codCurrencyConverted,
          sellerCustomFreight,
          providerCodAmount,
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      dispatch(
        update({
          codConvertResolved: true,
          codConvertLoading: false,
        })
      )
    }

    return {}
  }

export const updateProviderDeliveryTimeOptions = (provider_delivery_date) => (dispatch: AppDispatch, getState: AppGetState, form: FormInstance) => {
  const { providerDeliveryDateOptions } = getState().newServiceProvider
  const providerDeliveryTimeOptions = providerDeliveryDateOptions.find((item) => item.date === provider_delivery_date)?.timeIntervals ?? []
  dispatch(update({ providerDeliveryTimeOptions }))
  form.setFieldsValue({ provider_delivery_time: providerDeliveryTimeOptions?.[0] })
}

export const updateShippingInfo = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  const state = getState()
  const { newServiceProvider, user } = state

  const { countryCode: senderCountryCode } = user.info ?? {}
  const companyCode = newServiceProvider.provider?.companyCode

  const isKerryHk2TW = (() => {
    const { addressVO } = state.newServiceProvider.props ?? {}
    if (addressVO && senderCountryCode && addressVO.arrival_countryCode) {
      return isKerryHk2TWFn({
        companyCode,
        senderCountryCode,
        consigneeCountryCode: addressVO.arrival_countryCode,
      })
    } else {
      logger('发货国家或收货国家没有初始化')
      return false
    }
  })()

  dispatch(
    update({
      isKerryHk2TW,
    })
  )
}

export const calculateGoodsPrice = (form: FormInstance, provider: ExpressListData) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { newServiceProvider } = getState()
  const { parcel_commodities, parcel_currency_code, provider_cod_currency_code, provider_payment_type } = form.getFieldsValue()

  const { type, previousCodFee } = newServiceProvider
  const finalCodCurrencyCode = isCodCurrencyCodeSpecified(provider.codCurrencyType) ? provider?.targetCodCurrencyCode : provider_cod_currency_code
  if (type === 'create' && !previousCodFee) {
    const total =
      parcel_commodities?.reduce((acc, next) => {
        return (acc += times(next.parcel_goods_price || 0, next.parcel_goods_quantity || 1))
      }, 0) || ''

    if (typeof total !== 'number') {
      return
    }
    const updateProps = {
      // 创建订单，订单原始COD金额和币种赋值为商品价格及数量计算出的值
      apiCodAmount: times(total, 100),
      apiCodCurrencyCode: parcel_currency_code,
    } as { [key: string]: string | number }

    // 还没选择过服务产品，或者服务产品为指定币种服务产品，且指定币种与商品币种一致
    if (!provider_cod_currency_code || finalCodCurrencyCode === parcel_currency_code) {
      updateProps.calculateCodFee = total
      // 如果默认为代收货款，将计算的金额直接赋值表单代收货款
      if (provider_payment_type === PaymentTypeEnum.cod) {
        form.setFieldsValue({
          provider_cod_amount: total,
        })
      }
    }
    await dispatch(setProps(updateProps))
  }
}

/**
 * get current currency and codAmount
 */
export const getCurrentCodValue = (inputForm?: FormInstance) => async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance) => {
  const currentForm = inputForm || form

  const { newServiceProvider } = getState()
  const { value } = newServiceProvider
  const { provider_cod_currency_target, provider_cod_currency_code, provider_cod_amount_target, provider_cod_amount } = currentForm.getFieldsValue()

  const codCurrencyConverted = value?.codCurrencyConverted

  const finalCodCurrencyCode = codCurrencyConverted ? provider_cod_currency_target : provider_cod_currency_code
  const finalCodAmount = codCurrencyConverted ? provider_cod_amount_target : provider_cod_amount

  return {
    codCurrencyCode: finalCodCurrencyCode,
    codAmount: finalCodAmount,
  }
}

/**
 * get deduct values
 */
export const getDeductValues = (inputForm?: FormInstance) => async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance) => {
  const { newServiceProvider } = getState()
  const { props } = newServiceProvider
  const currentForm = inputForm || form
  const values = currentForm.getFieldsValue()
  const salesOrderCurrencyCode = props?.orderInfo?.salesOrderCurrencyCode
  const { deductedFreight, deductedFreightCurrencyCode } = props

  const { provider_cod_currency_code } = values

  const isDeductFreightEnable = salesOrderCurrencyCode && salesOrderCurrencyCode === provider_cod_currency_code

  if (!isDeductFreightEnable) return {}

  return {
    deductedFreight,
    deductedFreightCurrencyCode,
  }
}

/**
 * 初始化逆向现结币种
 * @param inputForm
 * @returns
 */
export const initProviderReverseFreight = (inputForm?: FormInstance) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const fieldsValue = inputForm.getFieldsValue()
  const {
    dictionary: { countryMapDics },
    newServiceProvider: { provider },
  } = getState()
  const departureCurrency = countryMapDics[fieldsValue.departure_countryCode]?.currency

  const formValues = {
    provider_reverse_freight_currency: departureCurrency,
    provider_reverse_freight: null,
  }

  if (provider.freightCurrencyCode === formValues.provider_reverse_freight_currency && provider.freight) {
    // 如果运费币种跟发货国家币种一致，则初始化逆向现结的退货代收金额
    Object.assign(formValues, {
      provider_reverse_freight: divide(Number(provider.freight), 100),
    })
  }
  inputForm.setFieldsValue(formValues)
}

export default configSlice.reducer
