/**
 * 发货场景标识
 * @see /bizComponent/ServiceProvider/Container/FormContainer/index.tsx
 */
export enum SHIP_TYPE_ENUM {
  NORMAL = 'normal',
  CREATE = 'create',
  BATCH = 'batch',
  MERGE = 'merge',
  BATCH_MERGE = 'betch-merge',
  LOGISTIC_EDIT = 'logistic-edit',
  /**
   * 批量逆向物流特殊场景的标识
   * @description
   * - 没有 COD
   */
  REVERSE_BATCH = 'reverse-batch',
  /**
   * 逆向物流特殊场景的标识
   * @description
   * - 没有 COD
   */
  REVERSE = 'reverse',
  /**
   * 逆向物流编辑特殊场景的标识
   * @description
   * - 没有 COD
   */
  REVERSE_EDIT = 'reverse-edit',
}
