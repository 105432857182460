import HTTP from '@/services/http'
import { oneConfig } from '@/services/one-config'
import type { OneConfigRoot } from '@yy/one-config/libs/one-config'

/**
 * 获取多语言平台的页面配置
 * @param key 配置的key
 */
export const getConfig = async (key?: string) => {
  const { data } = await HTTP.get(process.env.CONFIG_URL, { withCredentials: false })
  return key ? data[key] : data
}

export interface OneshipOrderExportKeysConfig {
  title: string
  key: string
  suffix?: string
  group?: boolean
  children?: OneshipOrderExportKeysConfig[]
}

/** 获取oms配置 */
export const getTargetOmsConfig = async <T extends keyof OneConfigRoot>(key: T) => {
  return oneConfig.get(key)
}
