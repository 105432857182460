import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from '@/store'
import { useCNSign } from './useCNSign'

export function useGuide(afterHandle) {
  const history = useHistory()
  const guideInfo = useSelector((state) => state.common.guideInfo)
  const checkServiceApply = useCNSign()
  /** 添加发货地址 */
  const handleToAddress = useCallback(() => {
    // TODO 调接口检查是否要打开弹窗，存到store里
    // 也可以在本页面加载后就去查，这样跳转比较快
    const task = guideInfo?.taskDetailResBos?.find((i) => i.guideTaskType === 'ADDRESS_GUIDE_TASK')
    if (!task?.completeStatus) {
      localStorage.setItem('firstAddress', '1')
    }
    history.push('/setting/addressbook')
    afterHandle?.()
  }, [history, guideInfo, afterHandle])

  /** 创建一个订单 */
  const handleToCreateOrder = useCallback(async () => {
    const pass = await checkServiceApply()
    if (!pass) return
    history.push('/ship/order/create')
    afterHandle?.()
  }, [checkServiceApply, history, afterHandle])

  /** 自定义跟踪页面 */
  const handleToTrack = useCallback(() => {
    history.push('/track/setting')
    afterHandle?.()
  }, [history, afterHandle])

  return { handleToAddress, handleToCreateOrder, handleToTrack }
}
