import { formatTime } from '@/utils'
import { startOfToday, subDays } from 'date-fns'
import React, { useMemo } from 'react'

interface IDateRangeProps {
  range: number
}

const DateRange: React.FC<IDateRangeProps> = (props) => {
  const { range = 30 } = props

  // 有需要可以封装成hook
  const [start, end] = useMemo(() => {
    const start = subDays(startOfToday(), range - 1)
    const end = Date.now()
    return [formatTime(start, 'yyyy/MM/dd'), formatTime(end, 'yyyy/MM/dd')]
  }, [range])

  return <span>{start + '~' + end}</span>
}

DateRange.displayName = 'DateRange'
export default React.memo(DateRange)
