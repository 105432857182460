// @app/oneship/src/bizComponent/Immediate/Steps/index.tsx
// 与ImmediateSteps强绑定，主要为了处理formValues监听处理，以及表单内公共数据问题
import type { FormInstance } from 'antd'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { getExpressList, getExpressServiceList, getSubAccount } from '@/services/apis'
import type { ExpressTypeServiceListRequest } from '@/services/apis/order/type'
import type { AppDispatch, AppGetState } from '@/store'
import { createSetReducers } from '../storeUtils'
import type * as Types from './type'
import type { PandagoStationVo } from '../serviceProviderSlice/types'
import { getTargetOmsConfig } from '@/utils'

let form: FormInstance<Types.ImmediateStepsFormValues> = null

const initialState: Types.ImmediateStepsState = {
  formValues: {},
  step: 0,
  provider: null,
  providerList: null,

  serviceList: null,
  senderStations: null,
  originOrderInfo: null,
  feeData: null,
  lalamoveServiceTranslate: null,
}

const immediateStepsSlice = createSlice({
  name: 'immediateSteps',
  initialState,
  reducers: {
    ...createSetReducers(initialState),
    setForm(state, action: PayloadAction<FormInstance<Types.ImmediateStepsFormValues>>) {
      form = action.payload
      return state
    },
    resetImmediateSteps() {
      form = null
      return initialState
    },
  },
})
const actions = immediateStepsSlice.actions
export const { setStep, setProvider, resetImmediateSteps, setOriginOrderInfo, setFeeData, setSenderStations } = actions

/**
 * 类似form.setFieldsValue
 */
export const setFieldsValue = (values: Partial<Types.ImmediateStepsState['formValues']>) => (dispatch: AppDispatch, getState: AppGetState) => {
  const state = getState().immediateSteps
  const oldValues = { ...state.formValues }
  form?.setFieldsValue(values)
  const newValues = { ...form?.getFieldsValue() }

  if (oldValues.expressTypeCode && newValues.expressTypeCode !== oldValues.expressTypeCode) {
    // 如果切换了服务产品
    // 重置数据
    dispatch(actions.setServiceList(null))
    dispatch(actions.setSenderStations(null))
  }
  dispatch(actions.setFormValues({ ...form?.getFieldsValue() }))
}

/**
 * 初始化lalamove翻译
 */
export const initLalamoveServiceTranslate = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  if (getState().immediateSteps.lalamoveServiceTranslate) return
  const res = await getTargetOmsConfig('lalamoveServiceTranslate')
  dispatch(actions.setLalamoveServiceTranslate(res))
}

/**
 * 初始化即时物流服务类型选择
 * @param params
 * @returns
 */
export const initExpressServiceList = (params: ExpressTypeServiceListRequest) => async (dispatch: AppDispatch) => {
  dispatch(actions.setServiceList(null))
  dispatch(setFieldsValue({ service_type: null, special_requests: null }))
  const res = await getExpressServiceList(params)
  dispatch(actions.setServiceList(res.data.services))
}

export const initForm = (formInstance: FormInstance<Types.ImmediateStepsFormValues>) => (dispatch: AppDispatch) => {
  form = formInstance
  dispatch(setStep(0))
  dispatch(setFeeData(null))
  dispatch(setFieldsValue({}))
}

/**
 * 初始化即时物流服务产品
 * @param params
 * @returns
 */
export const initExpressList = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  const res = await getExpressList({
    logisticsType: 'ondemand',
    senderCountryCode: [getState().user.info.selfArea],
  })
  dispatch(actions.setProviderList(res.data.data))
}

export const initSenderStations = (expressTypeCode: string, callBack?: (stations: PandagoStationVo[]) => void) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const res = await getSubAccount({
    expressTypeCode,
    pickupType: 1,
  })

  const departure = getState().immediateSteps.formValues.departure

  const stations = res.data?.pandagoStations || []
  dispatch(actions.setSenderStations(stations))
  if (stations && stations.length && !departure) {
    const station = stations.find((s) => s.isDefaultAddress)
    if (!station) return
    dispatch(
      setFieldsValue({
        departure: {
          area: {
            province: station.province,
            city: station.city,
            district: station.district,
            town: (station as any).town,
          },
          address: station.address,
          fullName: station.name,
          mobile: station.phone,
          addressLanguage: (station as any).addressLanguages?.[0] || '',
          addressItem: station as any,
          addressNo: station.clientAddressNo,
        },
      })
    )
  }
  callBack && callBack(stations)
}

export default immediateStepsSlice.reducer
