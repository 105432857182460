import React from 'react'
import { useTranslation } from 'react-i18next'
import { OneButton, OneModal } from '@yy/one-ui'
import { neverRemindApi } from '@/services/apis'
import close from '@/static/images/guide/guideClose.png'
import styles from './index.module.less'

/**
 * 新手任务不再提醒弹窗
 */
interface CloseGuideModalProps {
  show: boolean
  onCancel: () => void
}

const CloseGuideModal: React.FC<CloseGuideModalProps> = ({ show, onCancel }) => {
  const { t } = useTranslation()
  const handleCloseGuide = () => {
    neverRemindApi()
    onCancel()
  }
  return (
    <OneModal
      width={680}
      maskClosable={false}
      closable={false}
      visible={show}
      footer={null}
      onCancel={onCancel}
      className={styles.modal}
      bodyStyle={{ padding: 0, display: 'flex' }}
    >
      <div className={styles.left}>
        <img src={close} alt="" />
      </div>
      <div className={styles.right}>
        <p className={styles.title}>{t('dashbord.guide.closeTips')}</p>
        <p className={styles.content}>{t('dashbord.guide.touchUs')}</p>
        <p className={styles.end} dangerouslySetInnerHTML={{ __html: t('dashbord.guide.forMoreInfo') }}></p>
        <div>
          <OneButton onClick={handleCloseGuide} type="primary">
            {t('dashbord.guide.confirmClose')}
          </OneButton>
          <OneButton onClick={onCancel} style={{ marginLeft: '16px' }}>
            {t('dashbord.guide.cancel')}
          </OneButton>
        </div>
      </div>
    </OneModal>
  )
}

CloseGuideModal.displayName = 'CloseGuideModal'
export default CloseGuideModal
