// 提供权限控制显示隐藏
import React, { useCallback, useMemo } from 'react'
import { useSelector } from '@/store'
import { forEachTree } from '@/utils'

// todo: 如果后续权限复杂到一定程度，可以传入表达式来处理permissionCode
interface PermissionControlProps {
  /** 权限code，可以传入单个权限和多个权限编码，逗号分割 */
  permissionCode: string
  /** 当传入多个权限编码时，是使用“与”关系还是“或”来判断是否允许子组件展示，默认or */
  matchMode?: 'or' | 'and'
}

/**
 * 提供权限控制显示隐藏hook
 * @param props
 * @returns
 */
export function usePermissionControl() {
  const permissionTree = useSelector((s) => s.user.permissionTree)
  /**
   * 返回是否拥有权限，当权限树不存在时（未初始化完成）返回false
   */
  const hasPermission = useCallback(
    (permissionCode: PermissionControlProps['permissionCode'], matchMode?: PermissionControlProps['matchMode']) => {
      if (!permissionTree) return false
      const permissionCodes = Array.isArray(permissionCode) ? permissionCode : permissionCode.split(',')
      if (matchMode === 'and') {
        return permissionCodes.every((code) => forEachTree(permissionTree, (t) => t.resourceCode === code))
      }
      return permissionCodes.some((code) => forEachTree(permissionTree, (t) => t.resourceCode === code))
    },
    [permissionTree]
  )

  return hasPermission
}

/**
 * 判断是否拥有权限
 * @param permissionCode
 * @param matchMode
 * @returns
 */
export function usePermission(permissionCode: PermissionControlProps['permissionCode'], matchMode?: PermissionControlProps['matchMode']) {
  const hasPermission = usePermissionControl()
  return hasPermission(permissionCode, matchMode)
}

/**
 * 提供权限控制显示隐藏
 * 使用时
 * <PermissionControl permissionCode="123">
 *   <AnyComponent/>
 * </PermissionControl>
 * @param props
 * @returns
 */
const PermissionControl: React.FC<PermissionControlProps> = (props) => {
  const { permissionCode, matchMode = 'or', children } = props
  const hasPermission = usePermissionControl()
  const has = useMemo(() => hasPermission(permissionCode, matchMode), [permissionCode, matchMode, hasPermission])

  return <>{typeof children === 'function' ? children(has) : has ? children : null}</>
}

PermissionControl.displayName = 'PermissionControl'
export default PermissionControl
