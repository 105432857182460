import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { getExpressList as apiGetExpressList } from '@/services/apis/express'
import type { ExpressListData, ExpressListPayload } from '@/services/apis/express/type'
import type { AppDispatch } from '@/store'
import type * as Types from './types'

const initialState: Types.CreateOrderState = {
  hasBookService: false,
  departureRadioType: 'departureAddr',
  arrivalRadioType: 'arrivalAddr',
  serviceList: null,
  isEdit: undefined,
  isShip: false,
  currentExpressTypeCode: '',
}

const createOrderSlice = createSlice({
  name: 'createOrder',
  initialState,
  reducers: {
    setHasBookService(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        hasBookService: action.payload,
      }
    },
    setDepartureRadioType(state, action: PayloadAction<Types.TDepartureRadioType>) {
      return {
        ...state,
        departureRadioType: action.payload,
      }
    },
    setArrivalRadioType(state, action: PayloadAction<Types.TArrivalRadioType>) {
      return {
        ...state,
        arrivalRadioType: action.payload,
      }
    },
    setServiceList(state, action: PayloadAction<Types.TServiceList>) {
      return {
        ...state,
        serviceList: action.payload,
      }
    },
    setIsEdit(state, action: PayloadAction<boolean | undefined>) {
      return {
        ...state,
        isEdit: action.payload,
      }
    },
    setIsShip(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isShip: action.payload,
      }
    },
    setCurrentExpressTypeCode(state, action: PayloadAction<string>) {
      return {
        ...state,
        currentExpressTypeCode: action.payload,
      }
    },
    setExpressList(state, action: PayloadAction<ExpressListData[]>) {
      return {
        ...state,
        expressList: action.payload,
      }
    },
  },
})

/** 不接入 store */
export const fetchExpressList = async (payload: ExpressListPayload) => {
  const response = await apiGetExpressList({
    ...payload,
    pageSize: 999, // 临时
    needStationType: true,
  })

  const { bizCode, bizMessage, message, data } = response
  if (bizCode === 'SUCCESS') {
    const expresses = data?.data || []
    return expresses
  }

  return Promise.reject({ bizCode, bizMessage, message })
}

export const getExpressList = (payload: ExpressListPayload) => async (dispatch: AppDispatch) => {
  const expresses = await fetchExpressList(payload)
  dispatch(createOrderSlice.actions.setExpressList(expresses))
  return expresses
}

export const { setHasBookService, setDepartureRadioType, setArrivalRadioType, setServiceList, setIsEdit, setIsShip, setCurrentExpressTypeCode } = createOrderSlice.actions
export default createOrderSlice.reducer
