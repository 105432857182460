import { mapService } from '../utils'
import { fetchMapUrl } from '@/services/apis'
import { windowOpen } from '@/utils/browser'
import eventBus, { MESSAGE_TYPE } from '@/utils/eventbus'
import { EnvironmentOutlined } from '@ant-design/icons'
import { OneSelect, OneTooltip } from '@yy/one-ui'
import type { SelectProps } from 'antd'
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react'

/** 获取地图返回值 */
export interface MapRespVo {
  detailAddress: string
  /** 门店编号 */
  storeId: string
  /** 门店 */
  name: string
  /** 省 */
  province: string
  /** 市 */
  city: string
  /** 区 */
  district: string
  /** 门店地址 */
  address: string
}

/** 通信返回值 */
export interface BlackCatMessage {
  detail: MapRespVo & { code: string }
}

export interface BlackCatSelectProps<VT> extends SelectProps<VT> {
  /** 温层 */
  storageCdt?: string
  /** 服务商编码 */
  companyCode: string
  /** 目的国家 */
  countryCode: string
}

export interface BlackCatSelectAPI {
  close: () => void
}

export const BlackCatSelect = React.forwardRef<BlackCatSelectAPI, BlackCatSelectProps<MapRespVo>>((props, ref) => {
  const { storageCdt, companyCode, countryCode, value, onChange, ...restProps } = props
  const windowRef = useRef<Window>()
  const secretRef = useRef<string>()

  /** 内容 */
  const content = useMemo(
    () => (value?.storeId ? [value?.storeId, value?.name, value?.address].filter((item) => !!item).join('-') : ''),
    [value?.address, value?.name, value?.storeId]
  )
  /** 接口 */
  useImperativeHandle(ref, () => ({
    close: () => {
      windowRef.current?.close()
      windowRef.current = undefined
    },
  }))

  /** 选择 */
  const openMapSelect = useCallback(async () => {
    const code = Math.floor(Math.random() * 1e9).toString(32)
    const callbackUrl = `${location.origin}/middleware/station-711?code=${code}`
    const mapType = mapService[companyCode]
    const { data } = await fetchMapUrl({ callbackUrl, storageCdt, mapType, destAreaCode: countryCode })

    // 如果重复打开就关闭
    if (windowRef.current && !windowRef.current.closed) {
      windowRef.current.close()
      windowRef.current = undefined
    }

    secretRef.current = code
    windowRef.current = await windowOpen(data.url, '_blank')
  }, [companyCode, countryCode, storageCdt])

  /** 关闭 */
  useEffect(() => {
    return () => {
      windowRef.current?.close()
      windowRef.current = undefined
    }
  }, [])

  /** 通信 */
  useEffect(() => {
    const receiveMessage = (message: BlackCatMessage) => {
      const { detail } = message
      const { code, ...value } = detail
      if (code === secretRef.current) {
        onChange(value, { label: content })
      }
    }

    eventBus.listen(MESSAGE_TYPE, receiveMessage)
    return () => {
      eventBus.unListen(MESSAGE_TYPE, receiveMessage)
    }
  }, [onChange, content])

  return (
    <OneSelect {...(restProps as any)} value={content || undefined} onClick={openMapSelect} dropdownStyle={{ display: 'none' }} suffixIcon={<EnvironmentOutlined />}>
      {content ? (
        <OneSelect.Option value={content} style={{ display: 'block' }}>
          <OneTooltip placement="topLeft" title={content}>
            {content}
          </OneTooltip>
        </OneSelect.Option>
      ) : null}
    </OneSelect>
  )
})

BlackCatSelect.displayName = 'BlackCatSelect'
