export enum BizCodeEnum {
  SELLER_TO_BUY_SAAS = 'LST-123677-B1104',
  SELLER_TO_BUY_CASH_ON_DELIVERY_COUNT = 'LST-123672-B1112',
  NO_CREATE_SELLER_CONFIG = 'LST-123677-B1103',
  REMOTE_ERROR = 'LST-134673-G0001',
  CREATE_SASS_SUB_ACCOUNT_PICK_UP = 'LST-134443-B1110',
  CREATE_SASS_SUB_ACCOUNT_DROP_OFF = 'LST-134443-B1111',
  SNAPSHOT_EXPIRE = 'LST-13443-B2010',
  SL_SINGLE_SHIP_ERROR = 'LST-123677-B0026',
  SL_BATCH_SHIP_ERROR = 'LST-123677-B0027',
  SL_MERGE_SHIP_ERROR = 'LST-123677-B0028',
  SHIP_ERROR_APPEAL = 'LST-123677-B0031',
  EDIT_ERROR_APPEAL = 'LST-123677-B0032',
}
