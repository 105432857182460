import type { FormInstance } from 'antd'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { is711CrossBorder } from '@/bizComponent/RecipientAddressForm/utils'
import { ADDRESSMAP, I18N_PREFIX } from '@/constant'
import { ARRIVAL_METHOD } from '@/constant/arrivalMethod'
import { postBatchVerify } from '@/services/apis/address'
import { decodeLanguageAndProvince } from '@/utils/address'
import type { ValidResultVo } from '@/services/apis/address/types'
import { useSelector } from '@/store'
import { SHIPMENT_PICKUP_TYPE } from '@/bizComponent/ServiceProvider/enum'

type IVerifyAddressOptions = {
  recipientAddressVeriify?: boolean
  isReverse?: boolean
}

/**
 * 校验收发地址
 * @param form
 * @returns
 */
export function useVerifyAddressFn(form: FormInstance, options?: IVerifyAddressOptions) {
  const finalOptions = Object.assign(
    {},
    {
      recipientAddressVeriify: true,
    },
    options
  )

  const { recipientAddressVeriify, isReverse } = finalOptions
  const [loading, setLoading] = useState<boolean>(false)
  const verifyAddressFormRef = useRef<() => Promise<never>>()
  const { t } = useTranslation()
  const filterSenderCountries = useSelector((state) => state.dictionary.filterSenderCountries)
  const filterArrivalCountries = useSelector((state) => state.dictionary.filterArrivalCountries)
  const senderCountryCodes = useMemo(() => filterSenderCountries.map((item) => item.countryCode), [filterSenderCountries])
  const arrivalCountryCodes = useMemo(() => filterArrivalCountries.map((item) => item.countryCode), [filterArrivalCountries])
  const countryDicsMap = useSelector((state) => state.dictionary.countryMapDics)

  /* 校验地址表单数据 */
  const verifyAddressForm = useCallback(async () => {
    try {
      const value = form.getFieldsValue()

      const { arrival_method, departure_countryCode, arrival_countryCode, arrival_store_companyCode, provider_pickup_type } = value
      // for call from another place no only the steps
      if (!departure_countryCode || (recipientAddressVeriify && !arrival_countryCode)) return
      const isCrossBorder = departure_countryCode !== arrival_countryCode
      const departureCountryCode = value.departure_countryCode
      const departireProvince = value.departure_province
      const departureCity = value.departure_city
      const departureVerifyParams = {
        addressType: 1,
        mobile: value.departure_mobile,
        phone: value.departure_mobile,
        countryCode: departureCountryCode,
        province: decodeLanguageAndProvince(departireProvince)?.province || '',
        city: departureCity,
        district: value.departure_district,
        town: value.departure_town,
        address: value.departure_address,
        addressTwo: value.departure_addressTwo,
        zipCode: value.departure_zipCode,
        email: value.departure_email,
        name: value.departure_fullName,
      }

      // 收件地址和发货地址分开两次校验
      const arrivalCountryCode = value.arrival_countryCode
      const arrivalProvince = value.arrival_province
      const arrivalCity = value.arrival_city
      const arrivalVerifyParams = {
        addressType: 2,
        countryCode: arrivalCountryCode,
        mobile: value.arrival_mobile,
        phone: value.arrival_mobile,
        province: decodeLanguageAndProvince(arrivalProvince)?.province || '',
        city: arrivalCity,
        district: value.arrival_district,
        town: value.arrival_town,
        address: value.arrival_address,
        addressTwo: value.arrival_addressTwo,
        zipCode: value.arrival_zipCode,
        email: value.arrival_email,
        name: value.arrival_fullName,
      }

      setLoading(true)

      // 只有宅配，或者店配711跨境才需要校验发货地址
      const isVerifyRecipientAddress = arrival_method === ARRIVAL_METHOD.home || (arrival_method === ARRIVAL_METHOD.store && is711CrossBorder(arrival_store_companyCode))
      // 退货并且到店寄件则不校验发货地址了
      const isVerifyDepartureAddress = !(isReverse && provider_pickup_type === SHIPMENT_PICKUP_TYPE.dropOff)
      const verifyList = [isVerifyDepartureAddress ? departureVerifyParams : null, isVerifyRecipientAddress && recipientAddressVeriify ? arrivalVerifyParams : null].filter(Boolean)

      const { data } = await postBatchVerify({
        verifyList,
        crossBoarder: isCrossBorder,
      })

      const validResults: ValidResultVo[] = [].concat(...data.batchValidResults.map((item) => item.validResults))

      if (validResults.length > 0) {
        const fields = validResults.map((item) => {
          // 把所有校验失败的输入框 setError
          const name = ADDRESSMAP[item.fieldNames[0]]
          const targetValue = value[name]
          const errors = [t(I18N_PREFIX + item.messageCode, item?.messageData || {})]

          return {
            name,
            value: targetValue,
            errors,
          }
        })

        form.setFields(fields)

        return Promise.reject(fields)
      }
    } catch (error) {
      return Promise.reject(error)
    } finally {
      setLoading(false)
    }
  }, [form, recipientAddressVeriify, t, isReverse])

  verifyAddressFormRef.current = verifyAddressForm

  /** 校验收发货国家是否已授权 */
  const verifyAddressCountry = useCallback(
    (isPlatformLogisticsType: boolean) => {
      const value = form.getFieldsValue()
      const { departure_countryCode, arrival_countryCode } = value
      const isSenderInvalid = departure_countryCode && !senderCountryCodes?.includes(departure_countryCode)
      const isConsigneeInvalid = isPlatformLogisticsType ? false : arrival_countryCode && !arrivalCountryCodes?.includes(arrival_countryCode)

      const fields = []
      if (isSenderInvalid)
        fields.push({
          name: 'departure_countryCode',
          value: departure_countryCode,
          errors: [t('error.senderCountry', { senderCountry: countryDicsMap?.[departure_countryCode]?.countryName })],
        })
      if (isConsigneeInvalid)
        fields.push({
          name: 'arrival_countryCode',
          value: arrival_countryCode,
          errors: [t('error.consigneeCountry')],
        })
      if (fields.length) {
        form.setFields(fields)
        throw fields
      }
    },
    [form, senderCountryCodes, arrivalCountryCodes, t, countryDicsMap]
  )

  return { verifyAddressForm, verifyLoading: loading, verifyAddressFormRef, verifyAddressCountry }
}
