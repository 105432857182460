import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '@/services/i18n'
import { useSelector } from '../../store/index'
import { noDecimalsCurrency } from '@/constant'

const CHINESE_RE = /[\u4e00-\u9fa5]+/
const CHINESE_DOT1_RE = /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009]+/
const CHINESE_DOT2_RE = /[\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]+/

export const ANTD_RULES = {
  /** 中文品名 */
  parcel_goods_cname: () => {
    return {
      validator: async (_, value = '') => {
        if (!value) {
          throw Error(i18n.t('common.formValidRequired'))
        }
        if (value.length > 256) {
          throw Error(i18n.t('common.formValidLengthLessThan', { length: 256 }))
        }
        if (!CHINESE_RE.test(value) && !CHINESE_DOT1_RE.test(value) && !CHINESE_DOT2_RE.test(value)) {
          throw Error(i18n.t('common.mustContainChinese'))
        }
        return true
      },
    }
  },
  /** 英文品名 */
  parcel_goods_ename: (required = true) => {
    return {
      validator: async (_, value: string) => {
        if (!required && !value) return

        if (!value) {
          throw Error(i18n.t('common.formValidRequired'))
        }
        if (value.length > 256) {
          throw Error(i18n.t('common.formValidLengthLessThan', { length: 256 }))
        }
        if (CHINESE_RE.test(value) || CHINESE_DOT1_RE.test(value) || CHINESE_DOT2_RE.test(value)) {
          throw Error(i18n.t('common.mustNotContainChinese'))
        }
        return true
      },
    }
  },
  /** 海关编码 */
  parcel_goods_hscode: () => {
    return {
      validator: async (_, value: string) => {
        if (value?.length > 10) {
          throw Error(i18n.t('common.formValidLengthLessThan', { length: 10 }))
        }
        return true
      },
    }
  },
  /** 商品价格 */
  parcel_goods_price: (options) => {
    return {
      validator: async (_, price: string | number) => {
        // 标识不需要校验 直接返回true
        if (options.notValidate) {
          return true
        }
        price = price?.toString()
        const value = price?.replace(/\s+/g, '')
        const num = Number(value)

        // 区别设置校验
        // if (options?.setting) {
        //   if (value === '0') {
        //     throw Error(i18n.t('setting.converted.limit.price', { min: 0, max: 2 }))
        //   }
        // }

        if (!value && value !== '0') {
          throw Error(i18n.t('common.formValidRequired'))
        }
        if (!options.currencyCode) {
          throw Error(i18n.t('common.formValidRequired'))
        }

        // 先判断是否为数字
        if (parseFloat(value) !== num) {
          // throw Error(options?.setting ? i18n.t('setting.converted.limit.price', { min: 0, max: 2 }) : i18n.t('order.price.parcelItemsPriceLimitHasDot'))
          throw Error(i18n.t('order.price.parcelItemsPriceLimitHasMaxDot', { max: 2 }))
        }

        /**
         * 不允许有小数
         * @description
         * 以下国家/地区币种不允许有小数
         */
        const noDecimals = noDecimalsCurrency.includes(options.currencyCode)
        if (noDecimals) {
          // 不能有小数位
          if (value?.indexOf('.') !== -1 || Number(value) < 0) {
            // throw Error(options?.setting ? i18n.t('setting.converted.limit.price', { min: 0, max: 2 }) : i18n.t('order.price.parcelItemsPriceLimit'))
            throw Error(i18n.t('order.price.parcelItemsPriceLimit'))
          }
        } else {
          // 小数位不能大于2`
          if (!/^\d+(\.\d{1,2})?$/.test(value) || Number(value) < 0) {
            // throw Error(options?.setting ? i18n.t('setting.converted.limit.price', { min: 0, max: 2 }) : i18n.t('order.create.parcelItemsPriceLimitHasDot'))
            throw Error(i18n.t('order.price.parcelItemsPriceLimitHasMaxDot', { max: 2 }))
          }
        }

        return true
      },
    }
  },

  parcel_goods_name: (required = false) => {
    return {
      validator: async (_, value = '') => {
        if (!required && !value) return true

        if (!value) {
          throw Error(i18n.t('common.formValidRequired'))
        }
        if (value.length > 256) {
          throw Error(i18n.t('common.formValidLengthLessThan', { length: 256 }))
        }
        return true
      },
    }
  },

  /* 商品编码 */
  parcel_goods_code: () => {
    return {
      required: false,
    }
  },
  /** 商品数目 */
  parcel_goods_quantity: () => {
    return {
      validator: async (_, value: string) => {
        if (!value) {
          throw Error(i18n.t('common.formValidRequired'))
        }
        const re = /^[1-9]\d*$/
        if (!re.test(value)) {
          throw Error(i18n.t('common.formValidType', { type: i18n.t('common.formValidTypeInteger') }))
        }
        return true
      },
    }
  },
  /** 商品长宽高 */
  parcel_length: (props: { required: boolean; max: number }) => {
    const maxLength = props?.max ?? 1000
    return {
      validator: async (_, value: string) => {
        if (!value && !props?.required) return true
        const length = Number(value)

        if (length <= 0) throw Error(i18n.t<string>('common.formValidMoreThan', { min: 0 }))
        if (length >= maxLength) throw Error(i18n.t<string>('common.formValidLessThan', { max: maxLength }))
        if (!/^[1-9][0-9]*$/.test(value)) throw Error(i18n.t<string>('order.create.parcelSizeLimit'))
        return true
      },
    }
  },
  /** 商品材质 */
  parcel_goods_material: () => {
    return {
      validator: async (_, value: string) => {
        if (value?.length > 200) {
          throw Error(i18n.t<string>('common.formValidLengthLessThan', { length: 200 }))
        }
        return true
      },
    }
  },
  /** 商品用途 */
  parcel_goods_purpose: () => {
    return {
      validator: async (_, value: string) => {
        if (value?.length > 200) {
          throw Error(i18n.t<string>('common.formValidLengthLessThan', { length: 200 }))
        }
        return true
      },
    }
  },
  /** 商品重量 */
  parcel_goods_weight: () => {
    return {
      validator: async (_, value) => {
        const text = value

        // const requireList = ['DHL_EXPRESS', 'HKPOST_E_EXPRESS', 'HK_POST']

        // const isRequired = companyCode && requireList.some((key) => companyCode?.toUpperCase()?.includes(key))

        // 不填就不校验
        if (!text) return true

        const re = /^-?\d+(\.\d+)?$/
        if (!re.test(text)) {
          throw Error(i18n.t('common.formValidType', { type: i18n.t('common.formValidTypeNumber') }))
        }
        // 一定是数字
        const weight = Number(text)
        if (weight <= 0) {
          throw Error(i18n.t('common.formValidMoreThan', { min: 0 }))
        }

        // 小数位不能大于3
        const maxDot = /^\d+(\.\d{1,3})?$/
        if (!maxDot.test(text)) {
          throw Error(i18n.t('order.create.parcelWeightNumberDot'))
        }

        return true
      },
    }
  },

  /** 订单备注 */
  remark: () => {
    return [
      {
        validator: async (_, value: string) => {
          const valueLessThan = 80
          if (value?.length > valueLessThan) {
            throw Error(i18n.t<string>('common.formValidLengthLessThan', { length: valueLessThan }))
          }
          return true
        },
      },
    ]
  },

  /** 原订单号 */
  orgin_order: () => {
    return [
      {
        validator: async (_, value: string) => {
          const valueLessThan = 64
          if (value?.length > valueLessThan) {
            throw Error(i18n.t<string>('common.formValidLengthLessThan', { length: valueLessThan }))
          }
          return true
        },
      },
    ]
  },

  // original_countryCode: (props: { companyCode?: string }) => {
  //   return [
  //     {
  //       validator: async (_, value: string) => {
  //         if (props?.companyCode?.toUpperCase().includes('DHL') && !value) {
  //           throw new Error(i18n.t<string>('common.please.select'))
  //         }
  //         return true
  //       },
  //     },
  //   ]
  // },
  // 依赖第三阶段的服务产品现在会有比较大的问题
  original_countryCode: () => {
    return [
      {
        validator: async () => {
          return true
        },
      },
    ]
  },

  parcel_size: (props: { values: Record<string, any> }) => {
    const valueLength = Object.values(props?.values)?.filter(Boolean)?.length || 0

    return [
      {
        validator: async () => {
          if (valueLength > 0 && valueLength < 3) {
            throw new Error(i18n.t('order.create.parcelSizeWholeLitmit'))
          }
          return true
        },
      },
    ]
  },

  /** goods url length validator */
  parcel_goods_url: () => {
    return [
      {
        validator: async (_, value: string) => {
          const valueLessThan = 200
          if (value?.length > valueLessThan) {
            throw Error(i18n.t<string>('common.formValidLengthLessThan', { length: valueLessThan + 1 }))
          }
          return true
        },
      },
    ]
  },
  /** 关联退货订单号 */
  reverseOrderNo: () => {
    return [
      {
        validator: async (_, value: string[]) => {
          if (!value) {
            return true
          }

          value.forEach((value) => {
            const valueLessThan = 64
            if (value?.length > valueLessThan) {
              throw Error(i18n.t<string>('common.formValidLengthLessThan', { length: valueLessThan }))
            }
          })

          const sizeLessThan = 50
          if (value.length > sizeLessThan) {
            throw Error(i18n.t<string>('common.formValidLengthLessThanOrder', { length: sizeLessThan }))
          }

          return true
        },
      },
    ]
  },
  parcel_quantity: () => {
    return [
      {
        validator: async (_, value) => {
          if (!value && value !== 0) return true
          if (value % 1 !== 0) throw new Error(i18n.t('order.create.parcelSizeLimit'))
          if (value < 1 || value > 50) {
            throw new Error(i18n.t('common.formValid.numberRange', { min: 0, max: 50 }))
          }
          return true
        },
      },
    ]
  },
}

export const useConfigRule = () => {
  const { t } = useTranslation()

  const { weightLimit, parcelLengthLimit } = useSelector((state) => {
    const weight = Number(state.configSlice.weightLimit)
    const parcelLengthLimit = Number(state.configSlice.parcelLengthLimit)

    return { weightLimit: isNaN(weight) ? 30 : weight, parcelLengthLimit: isNaN(parcelLengthLimit) ? 1000 : parcelLengthLimit }
  })

  const rules = useMemo(() => {
    return {
      parcel_weight: (required = true) => ({
        validator: async (_, value) => {
          if (!required && !value) return true

          const text = value
          if (!text) {
            throw Error(t('common.formValidRequired'))
          }
          const re = /^-?\d+(\.\d+)?$/
          if (!re.test(text)) {
            throw Error(t('common.formValidType', { type: t('common.formValidTypeNumber') }))
          }
          // 一定是数字
          const weight = Number(text)
          if (weight <= 0) {
            throw Error(t('common.formValidMoreThan', { min: 0 }))
          }
          if (weight > weightLimit) {
            throw Error(t('common.formValidLessEqualThan', { max: weightLimit }))
          }

          // 小数位不能大于3
          const maxDot = /^\d+(\.\d{1,3})?$/
          if (!maxDot.test(text)) {
            throw Error(t('order.create.parcelWeightNumberDot'))
          }

          return true
        },
      }),
      parcel_length: (props?: { required: boolean; max?: number }) => ANTD_RULES.parcel_length({ max: parcelLengthLimit, ...props }),
    }
  }, [t, weightLimit, parcelLengthLimit])

  return {
    rules,
  }
}
