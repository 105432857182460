import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { IAppId } from '@/components/PlatformAvatar'
import Api from '@/services/http'
import { storeUrl } from '@/services/url'
import type { AppDispatch } from '@/store'
import { setGuideQueue } from '../userSlice'
import type * as Types from './types'

export const SL_APPID = 'SL101'

const initialState: Types.StoreState = {
  storeList: [],
  pagination: {
    pageSize: 999,
    pageNum: 1,
    total: 0,
  },
  settingExist: true,
}

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStoreList(state, action: PayloadAction<Types.StoreState['storeList']>) {
      return {
        ...state,
        storeList: action.payload,
      }
    },
    setPagination(state, action: PayloadAction<Types.StoreState['pagination']>) {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      }
    },
    setSettingExist(state, action: PayloadAction<Types.StoreState['settingExist']>) {
      return {
        ...state,
        settingExist: action.payload,
      }
    },
  },
})

export const { setStoreList, setPagination, setSettingExist } = storeSlice.actions

/** 已绑定渠道店铺列表 */
export const queryStoreList = (params?: Types.QueryStoreListType) => async (dispatch: AppDispatch) => {
  const response = await Api.admin.post<Types.ResponseQueryType>(storeUrl.storeList, {
    ...params,
  })

  const { data: rstData } = response

  const list = rstData?.data || []
  dispatch(setStoreList(list))
  // 如果有设置，就把storeSetting新人指引设置成已展示
  if (rstData?.settingExist) dispatch(setGuideQueue({ key: 'storeSetting' }))
  dispatch(
    setPagination({
      pageNum: rstData?.pageNum,
      pageSize: rstData?.pageSize,
      total: rstData?.total,
    })
  )
  dispatch(setSettingExist(rstData?.settingExist))

  return list
}

export const bindStore = async (data: { code?: string; shop?: string; appId?: IAppId; extra?: { timestamp?: string; sign?: string; ticket?: string } }) => {
  const res = await Api.toast.post<{ bindState: string }>(storeUrl.bindStore, {
    ...data,
    extra: {
      redirectUrl: `${process.env.DOMAIN_IO}/stores/list`,
      ...(data.extra ?? {}),
    },
  })
  return res
}

/** 解除渠道店铺绑定 */
export const unbindStore = async (params: { storeId: string; appId: string; userStoreSeq: string }) => {
  await Api.toast.post(storeUrl.unbindStore, {
    ...params,
  })

  return { succ: true }
}

/** 同步店铺订单 */
export const syncOrder = async (params: Types.SyncOrderType) => {
  await Api.toast.post(storeUrl.SyncOrder, params)
  return { succ: true }
}

/** 更新店铺 */
export const updateStoreInfo = async (params: Types.UpdateOrderType) => {
  const { bizCode, bizMessage, message } = await Api.admin.post(storeUrl.updateStore, params)
  if (bizCode === 'SUCCESS') {
    return { succ: true }
  }

  return Promise.reject({ succ: false, message, bizMessage })
}

/** 更新店配COD配置 */
export const updatePaymentInfo = async (params: Types.UpdatePaymentType) => {
  const { bizCode, bizMessage, message } = await Api.admin.post(storeUrl.updatePayment, params)
  if (bizCode === 'SUCCESS') {
    return { succ: true }
  }

  return Promise.reject({ succ: false, message, bizMessage })
}

export default storeSlice.reducer
