import type React from 'react'
import type { RouteConfigComponentProps } from 'react-router-config'
import { renderRoutes } from 'react-router-config'

const Blank: React.FC<RouteConfigComponentProps<any>> = (props) => {
  const { route } = props
  if (route) {
    return renderRoutes(route.routes)
  }

  return null
}
Blank.displayName = 'Blank'

export default Blank
