import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Api from '@/services/http'
import { courierUrl } from '@/services/url'
import type { AppDispatch } from '@/store'
import type { CourierState, ICourierProps } from './types'

const initialState: CourierState = {
  authCouriers: [],
  unAuthCouriers: [],
}

const commonSlice = createSlice({
  name: 'courier',
  initialState,
  reducers: {
    setCouriers(state, action: PayloadAction<Record<string, ICourierProps[]>>): CourierState {
      return {
        authCouriers: action.payload.authExpressTypes,
        unAuthCouriers: action.payload.unAuthExpressTypes,
      }
    },
  },
})

export const { setCouriers } = commonSlice.actions
export default commonSlice.reducer

export const fetchCourier = () => async (dispatch: AppDispatch) => {
  const response = await Api.admin.post(courierUrl.getCourierList)
  const { bizCode, data, bizMessage, message } = response
  if (bizCode === 'SUCCESS' && data && data.expressTypeVo) {
    dispatch(setCouriers(data.expressTypeVo))
    return Promise.resolve({ bizCode, bizMessage, message })
  }
  return Promise.reject({ bizCode, bizMessage, message })
}
