import React, { useEffect, useState } from 'react'
import { Card, Row, Space } from 'antd'
import { OneButton } from '@yy/one-ui'
import { useTranslation, Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useGetGiveawayDetail, useGiveawayTracker } from '@/bizComponent/Giveaway/hook'
import styles from './index.module.less'
import giveawayPic from '@/static/images/giveaway.png'
import PermissionControl from '@/bizComponent/PermissionControl'
import { useSelector } from '@/store'
import { GiveawayActivityStatus } from '@/services/apis/giveaway/type'

/** 优惠活动卡片 */
export const GiveawayCard: React.FC = () => {
  const { t } = useTranslation()

  const history = useHistory()

  const { trackGiveawayClick } = useGiveawayTracker()

  const uid = useSelector((state) => state.user.info.uid)

  const { price, effectiveEndTime, effectiveStartTime, seq, status, ruleKey, giveawayForSeller, expireDate } = useGetGiveawayDetail()

  const localKey = `${ruleKey}_${uid}_giveawayCardNeverShow`

  // 活动卡片是否展示
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (status === GiveawayActivityStatus.NotParticipate) {
      setShow(true)
    } else if (status === GiveawayActivityStatus.Received) {
      // 优惠领取成功，同时没设置不再提醒
      // 赠送后，该商家ID 有钱包流水为「充值」记录，或「调账且修改了余额有效期」的记录（含调增调减），该样式不再展示
      // 赠送金额失效日到期后，该样式不再展示
      if (localStorage.getItem(localKey) !== '1' && !giveawayForSeller && moment().valueOf() < expireDate) {
        setShow(true)
      }
    }
  }, [expireDate, giveawayForSeller, localKey, status])

  // 是否已成功参与活动
  const hasJoined = status === GiveawayActivityStatus.Received

  const handleNoRemind = () => {
    setShow(false)
    localStorage.setItem(localKey, '1')
  }

  const toQuestionnairePage = () => {
    trackGiveawayClick()
    history.push(`/questionnaire/${seq}`)
  }

  if (!show) {
    return null
  }

  return (
    <Card
      title={
        <>
          {t('promotion.free.tooltips')}
          <span className={styles.badge}>New</span>
        </>
      }
      className={styles.card}
      extra={
        hasJoined && (
          <span className={styles.noRemind} onClick={handleNoRemind}>
            {t('dashbord.guide.neverRemain')}
          </span>
        )
      }
    >
      <Row justify="space-between">
        <div className={styles.left}>
          <p>
            {hasJoined ? null : (
              <span>
                {effectiveStartTime} ~ {effectiveEndTime}
              </span>
            )}
            <Trans
              i18nKey={!hasJoined ? 'promotion.card.joinTip' : 'promotion.card.joinSuccess'}
              values={{
                startTime: effectiveStartTime,
                endTime: !hasJoined ? effectiveEndTime : moment(expireDate).format('YYYY-MM-DD'),
                price: price,
              }}
              components={[<span className={styles.price} />]}
            />
          </p>
          {!hasJoined ? (
            <OneButton type="primary" onClick={toQuestionnairePage}>
              {t('promotion.question.write')}
            </OneButton>
          ) : (
            <Space size={12}>
              <PermissionControl permissionCode="ship:order:create">
                <OneButton
                  type="primary"
                  onClick={() => {
                    history.push('/ship/order/create')
                  }}
                >
                  {t('logistics.create.ship')}
                </OneButton>
              </PermissionControl>
              <OneButton
                onClick={() => {
                  history.push('/wallet')
                }}
              >
                {t('wallet.recharge')}
              </OneButton>
            </Space>
          )}
        </div>
        <div className={styles.right}>
          <img src={giveawayPic} alt="" />
        </div>
      </Row>
    </Card>
  )
}

GiveawayCard.displayName = 'GiveawayCard'
