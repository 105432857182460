import { useRequest } from 'ahooks'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { OneMessage } from '@yy/one-ui'
import usePackageExistBillModal from '@/hooks/subscribe/usePackageExistBillModal'
import * as subscriptionService from '@/services/apis/subscribe'
import type { IPackageStatusRes } from '@/services/apis/subscribe/type'
import { useDispatch, useSelector } from '@/store'
import { setSubStatus } from '@/store/subscribeSlice'

export function useSubscriptionStatus() {
  const dispatch = useDispatch()
  const subStatus = useSelector((state) => state.subscribeSlice?.subStatus)
  const statusData = subStatus || ({} as IPackageStatusRes)
  const showPackageExistBillModal = usePackageExistBillModal()
  const history = useHistory()

  const [isCheckingBefore2PlanPage, setIsCheckingBefore2PlanPage] = useState(false)

  const {
    run: getSubscriptionStatus,
    loading: isLoading,
    data,
  } = useRequest(() => subscriptionService.subscriptionStatus(), {
    // TODO 因为切换路由后，需要获取订阅状态，目前是采用监听路由，路由变化获取最新状态的。
    // 为了防止redirect场景，重复获取，这里加了debounce。采用websocket获取订阅状态后，这里可以去掉
    debounceInterval: 500,
    formatResult(resp) {
      return resp
    },
    onSuccess(resp) {
      dispatch(setSubStatus(resp?.data))
    },
    onError(e) {
      e?.message && OneMessage.error(e.message)
    },
  })

  const { run: getUnpayBill, loading: getUnpayBillLoading } = useRequest(() => subscriptionService.getUnpaidSubBill(), {
    formatResult(resp) {
      return resp
    },
    onError(e) {
      e?.message && OneMessage.error(e.message)
    },
  })

  const subscriptionStatusService = () => {
    getSubscriptionStatus()
  }

  const toPlanPage = () => {
    setIsCheckingBefore2PlanPage(true)
    return new Promise(async (resolve, reject) => {
      let hasUnpaidOrder = null
      try {
        // 验证是否有未支付订单
        const resp = await getUnpayBill()
        hasUnpaidOrder = resp.data.id
      } catch {
        // do noting
        // 获取获取未支付订单失败, 就跳转购买套餐页面。不影响流程
        reject()
      } finally {
        resolve(true)
        setIsCheckingBefore2PlanPage(false)
      }

      if (hasUnpaidOrder) {
        showPackageExistBillModal()
      } else {
        history.push('/account/subscribe/package')
      }
    })
  }

  return {
    statusData,
    isTrial: statusData.subscription?.subType === 'trial',
    isExpired: statusData.subscription?.status === 'expired',
    isFreeAndUnactive: statusData.currentFree && statusData.previousUnactivePaid,

    isCheckingBefore2PlanPage,

    isLoading,
    subscriptionStatusService,
    getUnpayBillService: getUnpayBill,
    isSuccess: data?.bizCode === 'SUCCESS',
    getUnpayBillLoading,
    toPlanPage,
  }
}
