import i18n from '@/services/i18n'

// 域名格式校验
export const domainRule = () => {
  return {
    validator: async (_, value) => {
      const reg = /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/
      if (!reg.test(value)) {
        return Promise.reject(i18n.t('subscribe.trackingSetting.domainValid'))
      }
      return Promise.resolve()
    },
  }
}
