import type { SHIP_TYPE_ENUM } from '@/store/enum'

/** 包裹表单数据 */
export interface IParcelFormValues extends ParcelFormFields {
  /** 重量 */
  parcel_weight?: string | number
  /** 单位 */
  parcel_weight_unit?: string
  /** 参考号 */
  parcel_order_no?: string
  /** 备注 */
  parcel_sender_remark?: string
  /** 单价币种 */
  parcel_currency_code?: string
  /** 转换后单价币种 */
  parcel_converted_currency_code?: string
  /** 币种切换 */
  parcel_check_currency?: boolean
  parcel_show_keys?: string[]
  /** 退货原订单号 */
  reverseOrderNo?: string[]
  /** 包裹数量 */
  parcel_quantity?: number
}

/** 初始化参数 */
export type IInitialParcel = {
  /** 场景类型 */
  type?: SHIP_TYPE_ENUM
  // 订单||运单 format之后的表单值
  formValues?: IParcelFormValues
  props: {
    /** 订单原币种 */
    originCurrencyCode?: string
    /** 转换后的币种 */
    convertedCurrencyCode?: string
    /** 地址收货国家 */
    arrivalCountry?: string
    /** 发货国家 */
    senderCountry?: string
    /** 订单场景类型 1新建订单 2订单转运单 3运单编辑 */
    orderType?: number
    /** 来源是否为api订单 */
    sourceType?: number
    isConverted?: boolean
    /** 是否符合币种转换的场景 */
    isConvertedScene?: boolean
    isShowCheckbox?: boolean
    isChinaCrossBorder?: boolean
    isCrossBorder?: boolean
    isCN2US?: boolean
    /**
     * 是否美国发美国
     * https://shopline.yuque.com/mlu41d/ltch1x/gydndl0kfgfgquoo#yWGrV
     */
    isUS2US?: boolean
    isHKCrossBorder?: boolean
    // ID local
    IsIndonesia?: boolean
    /** 订单实付金额的原始数据：如果后端返回的订单详情数据，已经币种转换，则存储订单的originOrderAmount,如果没有币种转换，则存储的是订单的orderAmount */
    /* 批量合单发货场景，orderAmount要传给后端（其他发货场景不用），如果触发币种转换，orderAmount也要跟着转，所以此处记录下订单原始“订单实付金额” */
    /** 单位：分 */
    originOrderAmount?: number
    /** 1.平台物流, 2:LMS物流 */
    expressLogisticsType?: 1 | 2
  }
}

export type IUpdateProps = Pick<IInitialParcel, 'formValues'> & { cover?: boolean }
export interface InitialState {
  /**
   * 场景类型
   * @see @app/oneship/src/store/parcelSlice/enum.ts
   */
  type: `${SHIP_TYPE_ENUM}`
  currencyInfo: CurrencyInfo
  props?: Partial<IInitialParcel['props']>
}
export interface GetProductsRateParams {
  source?: string
  target?: string
  parcel_commodities?: ParcelFormFields['parcel_commodities']
}

export type CurrencyInfo = {
  // 原币种
  originCurrencyCode?: string
  // 转换过
  hasConverted?: boolean
  // 汇率版本
  fetchTime?: number
  // 当前汇率
  currencyRate?: number
  // 转换后的订单实付金额
  convertedOrderAmount?: number
}

/** 表单拥有的字段 */
export interface ParcelFormFields extends ParcelSizeFields {
  /** 包裹信息数组 */
  parcel_commodities?: Array<{
    /** 商品编码 */
    parcel_goods_code?: string
    /** 商品名 */
    parcel_goods_name?: string
    /** 数量 */
    parcel_goods_quantity?: number
    /**商品价格 */
    parcel_goods_price?: number
    /** 中文品名 */
    parcel_goods_cname?: string
    /** 英文品名 */
    parcel_goods_ename?: string
    /** 海关编码 */
    parcel_goods_hscode?: string
    /** 商品材质 */
    parcel_goods_material?: string
    /** 商品用途 */
    parcel_goods_purpose?: string
    /** 商品规格 */
    parcel_goods_size?: string
    /** 转换后的价格 */
    parcel_goods_converted_price?: number
    /** 商品重量 */
    parcel_goods_weight?: number

    /** 原产国 */
    originCountryCode?: string

    parcel_goods_url?: string
  }>
}
// 尺寸
export interface ParcelSizeFields {
  parcel_length?: string // 长
  parcel_width?: string // 宽
  parcel_height?: string // 高
  parcel_length_unit?: string // 尺寸单位
}

export enum ORDER_TYPE {
  /** 订单创建 */
  ORDER_CREATE = 1,
  /** 订单转运单（编辑 */
  ORDER_EDIT = 2,
  /** 订单转运单（发货 */
  ORDER_SHIP = 4,
  /** 运单编辑 */
  LOGISTICS_EDIT = 3,
}
