import _logo from '@/static/images/logo.svg'

// 提供区分国家版本的工具
export interface Config {
  /** 特定国家的i18n地址 */
  i18n?: string
  imgs?: Record<string, string>
  /** 轨迹域名 */
  tracking?: string
  contactUrl?: string

  applySource: 'ONESHIP_CROSS_BORDER_DASH' | 'ONESHIP_INTERNATIONAL_DASH' | 'ONESHIP_VN_DASH'
  /** 统一账号app参数 */
  account_appId?: string
  /** 站点名字 */
  brand?: 'OneShip' | 'OneClub'
}

/**
 * 国家对应域名，国际版为GLOBAL
 */
export const COUNTRY_DOMAIN_MAP = {
  GLOBAL: process.env.DOMAIN_IO,
  CN: process.env.DOMAIN_CN,
  VN: process.env.DOMAIN_VN,
}

export type CountryVersion = keyof typeof COUNTRY_DOMAIN_MAP

type CountryVersionMap<T> = Partial<Record<CountryVersion, T>>

/**
 * 当前存在的特定国家版本号
 */
export const exceptCountries = Object.keys(COUNTRY_DOMAIN_MAP).filter((k) => k !== 'GLOBAL') as Exclude<CountryVersion, 'GLOBAL'>[]

// function importImgs(r) {
//   const map = {}
//   r.keys().forEach((key) => (map[key] = r(key)))
//   return map
// }

/**
 * 版本名映射国家配置
 */
const COUNTRY_CONFIG: CountryVersionMap<Partial<Config>> = {
  GLOBAL: {
    applySource: 'ONESHIP_INTERNATIONAL_DASH',
    brand: 'OneShip',
  },
  VN: {
    i18n: process.env.I18N_URL_VN,
    tracking: 'oneclub.vn',
    contactUrl: 'https://www.oneclub.vn/',

    applySource: 'ONESHIP_VN_DASH',
    account_appId: 'osvn',
    brand: 'OneClub',
  },
  CN: {
    applySource: 'ONESHIP_CROSS_BORDER_DASH',
    brand: 'OneShip',
  },
}
/** 当前国家版本名 */
export const countryVersion = (Object.keys(COUNTRY_DOMAIN_MAP).find((key) => COUNTRY_DOMAIN_MAP[key]?.includes(self.location.host)) ?? 'GLOBAL') as CountryVersion
export const countryPick = <T>(obj: CountryVersionMap<T>): T => {
  return obj[countryVersion] ?? obj[''] ?? obj['GLOBAL']
}

/** 当前版本特殊配置 */
export const countryConfig = countryPick(COUNTRY_CONFIG)

/**
 * 引用不同国家版本图片
 * @param url
 * @returns
 */
export const imgURL = (url: string) => {
  return countryConfig.imgs?.[url] ?? null
}

/**
 * 判断当前代码所属国家版本
 * @description
 * 当前这种方案是根据国家域名进行判断，
 * 因为研发通常是通过 `localhost` 的方式进行开发的，
 * 因此该种判断在业务中容易出现无法 DEBUG 现象；
 * 如果为 LOGO 等展示形式可以使用该方法进行判断，
 * 其他业务逻辑中建议使用状态机 `user?.info?.countryCode` 获取。
 */
export const countryIs = Object.keys(COUNTRY_DOMAIN_MAP).reduce((p, key) => {
  p[key] = countryVersion === key
  return p
}, {}) as Required<CountryVersionMap<boolean>>

/** 本土站点 */
export const localCountries = Object.keys(COUNTRY_DOMAIN_MAP).filter((item) => item !== 'GLOBAL' && item !== 'CN')

/** oneship logo */
export const oneshipLogo = imgURL('./images/logo.svg') || _logo
