import type * as Types from '@/types'

type ShipWayType = 1 | 2

export type ShipWayDict = Types.Dict<{ value: ShipWayType }>

export const SHIPWAY_LABEL: Types.DictLabel<ShipWayType> = {
  '1': 'logistics.list.COD',
  '2': 'logistics.list.nonCOD',
}

export const SHIPWAY: ShipWayDict = [
  {
    label: SHIPWAY_LABEL[1],
    value: 1,
  },
  {
    label: SHIPWAY_LABEL[2],
    value: 2,
  },
]

export const SETVICE_TYPE = {
  /** cod */
  cod: 1,
  /** 不使用cod */
  noCod: 2,
}
