import { useEffect, useState } from 'react'
import { ResizeObserver } from '@juggle/resize-observer'

type IDomObject = Omit<ResizeObserverEntry['contentRect'], 'toJSON'> & {
  offsetLeft: number
  offsetTop: number
  scrollHeight: number
  scrollWidth: number
  clientWidth: number
  clientHeight: number
}

/**
 * 观察dom节点变化，获取dom大小
 * @param id dom节点id
 * @returns
 */
export const useResizeDomRect = (id: string) => {
  const [domRect, setDomRect] = useState<IDomObject>()

  useEffect(() => {
    try {
      if (!ResizeObserver) return
      const myObserver = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return
          }

          const dom = entries[0]
          const offsetLeft = (dom.target as HTMLElement)?.offsetLeft
          const offsetTop = (dom.target as HTMLElement)?.offsetTop
          const scrollHeight = (dom.target as HTMLElement)?.scrollHeight
          const scrollWidth = (dom.target as HTMLElement)?.scrollWidth
          const clientWidth = (dom.target as HTMLElement)?.clientWidth
          const clientHeight = (dom.target as HTMLElement)?.clientHeight

          const data = {
            ...dom.contentRect.toJSON(),
            offsetLeft,
            offsetTop,
            scrollHeight,
            scrollWidth,
            clientHeight,
            clientWidth,
          }

          setDomRect(data)
        })
      })
      const contentEle = document.getElementById(id)
      contentEle && myObserver.observe(contentEle)
      return () => {
        myObserver.disconnect()
      }
    } catch (error) {}
  }, [id])

  return domRect
}
