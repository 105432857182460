import type { FormInstance } from 'antd'
import { flatten, pick } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { fetchAreaList, getAddressListChildren, getProvinceList } from '@/services/apis'
import type { AppDispatch, AppGetState } from '@/store'
import { updateAddressVO } from '@/store/newServiceProviderSlice'
import { setSenderCountryInfo } from '@/store/dictionarySlice'
import { decodeLanguageAndProvince } from '@/utils/address'
import type * as Types from './types'

export const initialState = {
  provinces: [],
  cities: [],
  districts: [],
  towns: [],
  loading: false,
  language: '',
}

export const senderAddressSlice = createSlice({
  name: 'senderAddressForm',
  initialState,
  reducers: {
    update(state, action) {
      const { payload } = action
      return { ...state, ...payload }
    },
    clearSenderAddress(state) {
      return { ...state, ...pick(initialState, ['provinces', 'cities', 'districts', 'towns', 'language']) }
    },
  },
})

export const { actions, reducer } = senderAddressSlice
export default reducer
export const { clearSenderAddress } = actions
/**
 * 初始化发货国家
 * 只会设置name为 departure_ 开头的表单值
 * @param params
 * @returns
 */
export const initSenderAddressForm =
  (params?: { formValues?: Types.ISenderAddressFormValues }) => async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance<Types.ISenderAddressFormValues>) => {
    const { formValues = {} } = params || {}
    const { departure_countryCode, departure_province, departure_city, departure_district } = formValues

    /**
     * 因为 selectSenderAddressProvinceNew 里面有很多获取表单的值，
     * 因此这里优先设置发货地址
     */
    form.setFieldsValue({ departure_countryCode, departure_province, departure_city, departure_district })

    const state = getState()
    const languageType = state?.language?.languageType
    const filterSenderCountries = state?.dictionary?.filterSenderCountries
    const senderCountryLanguage = filterSenderCountries.find((item) => item.countryCode === departure_countryCode)?.language
    departure_countryCode &&
      (await dispatch(
        fetchSenderAddressProvinceListNew({
          countryCode: departure_countryCode,
          languageOrder: [languageType, 'en', senderCountryLanguage].filter(Boolean),
        })
      ))

    departure_countryCode && departure_province && (await dispatch(selectSenderAddressProvinceNew(departure_province)))
    departure_countryCode && departure_province && departure_city && (await dispatch(selectSenderAddressCityNew(departure_city)))
    departure_countryCode && departure_province && departure_city && departure_district && (await dispatch(selectSenderAddressDistrictNew(departure_district)))

    // 最后再执行一次，比如 city 会清空 district
    dispatch(
      updateAddressVO({
        departure_countryCode: formValues?.departure_countryCode,
        departure_city: formValues?.departure_city,
        departure_district: formValues?.departure_district,
        departure_town: formValues?.departure_town,
        departure_province: formValues?.departure_province,
        departure_zipCode: formValues?.departure_zipCode,
        departure_address: formValues?.departure_address,
        departure_addressTwo: formValues?.departure_addressTwo,
        departure_fullName: formValues.departure_fullName,
        departure_mobile: formValues.departure_mobile,
      })
    )

    const updateFormValues = pick(
      formValues,
      Object.keys(formValues).filter((key) => key.startsWith('departure_'))
    )

    form.setFieldsValue(updateFormValues)
  }

/** 获取省列表 */
export const fetchSenderAddressProvinceList = (params?: Types.FetchDepartureAreaListParams) => async (dispatch: AppDispatch) => {
  const provinces = await fetchAreaList(params)
  dispatch(actions.update({ provinces }))
}

/** 获取省列表 */
export const fetchSenderAddressProvinceListNew = (params?: Parameters<typeof getProvinceList>[0]) => async (dispatch: AppDispatch) => {
  const data = await getProvinceList(params)
  const provinces = data?.data?.results || []
  dispatch(actions.update({ provinces }))

  return provinces
}

/** 获取发货可视配置 */
export const getSenderAddressVisibility = () => (_dispatch: AppDispatch, getState: AppGetState, form: FormInstance) => {
  const { address } = getState()
  const countryCode = form.getFieldValue('departure_countryCode')
  const addressConfig = address?.addressConfig?.[countryCode]

  /* 只有省份隐藏 */
  const isProvinceHidden = !addressConfig.provinceVisibility && addressConfig.cityVisibility && addressConfig.districtVisibility

  /* 只有城市隐藏 */
  const isCityHidden = !addressConfig.cityVisibility && addressConfig.provinceVisibility && addressConfig.districtVisibility

  /* 只有区显示 */
  const isOnlyDistrictShow = addressConfig.districtVisibility && !addressConfig.provinceVisibility && !addressConfig.cityVisibility

  return { isProvinceHidden, isCityHidden, isOnlyDistrictShow }
}

/** 选择发货国家 */
export const selectSenderAddressCountryNew = (value: string) => async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance) => {
  // const { isProvinceHidden } = dispatch(getSenderAddressVisibility())
  // const { departure_countryCode } = form.getFieldsValue()
  // const { province, language } = decodeLanguageAndProvince(value)

  const state = getState()
  const languageType = state?.language?.languageType
  const filterSenderCountries = state.dictionary?.filterSenderCountries
  const senderCountryLanguage = filterSenderCountries.find((country) => country.countryCode === value)?.language
  const provinces = await dispatch(
    fetchSenderAddressProvinceListNew({
      countryCode: value,
      languageOrder: [languageType, 'en', senderCountryLanguage].filter(Boolean),
    })
  )

  form.setFieldsValue({
    departure_province: undefined,
    departure_city: undefined,
    departure_district: undefined,
    departure_town: undefined,
    departure_address: undefined,
    departure_addressTwo: undefined,
    departure_zipCode: undefined,
  })

  // 更新状态机中选中的
  const list = getState()?.dictionary?.countryDics
  const senderCountryInfo = list?.find((item) => item.countryCode === value)
  senderCountryInfo && dispatch(setSenderCountryInfo(senderCountryInfo))

  await dispatch(actions.update({ provinces, cities: undefined, districts: undefined }))
}

/** 选择发货省 */
export const selectSenderAddressProvinceNew = (value: string) => async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance) => {
  const { isCityHidden } = dispatch(getSenderAddressVisibility())
  const { departure_countryCode } = form.getFieldsValue()

  const { province, language } = decodeLanguageAndProvince(value)
  const cities = await (async () => {
    // 没有语言和国家，请求也会报错
    if (!language || !departure_countryCode) return []

    const citiesResponse = await getAddressListChildren({
      countryCode: departure_countryCode,
      parentAddressLevel: 1,
      parentAddressLanguage: language,
      parentAddressName: province,
      addressChildrenLevelNumber: 3,
    })

    const cities = citiesResponse?.data?.nodes || []

    return cities
  })()

  let districts = []
  if (isCityHidden) {
    /** 市隐藏的情况 省变化 -> 更新区 */
    districts = flatten(cities.map((item) => item.child))

    // 如果是英文再切换
    if (language === 'en') {
      // 需要进行省市区的特殊处理
      districts = districts.sort((a, b) => (a.name > b.name ? 1 : -1))
    }
  }

  form.setFieldsValue({
    departure_city: undefined,
    departure_district: undefined,
    departure_town: undefined,
  })
  await dispatch(
    updateAddressVO({
      departure_province: province,
      departure_city: undefined,
      departure_district: undefined,
      departure_town: undefined,
    })
  )

  await dispatch(actions.update({ cities, districts, language }))
}

/**
 * 选择发货城市
 * 初始化或者改变国家城市使用
 */
export const selectSenderAddressCityNew = (value: string) => async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance) => {
  const { senderAddressForm: state } = getState()
  const { cities } = state
  const { isOnlyDistrictShow, isCityHidden } = await dispatch(getSenderAddressVisibility())
  let districts = []

  // 初始化的情况兼容
  const { language } = state

  /* 只有区显示的情况 */
  // 为啥只有发件有，收件没有得情况
  if (isOnlyDistrictShow) {
    districts = flatten(cities.map((item) => item?.child)).filter(Boolean)
    if (language === 'en') {
      districts = districts.sort((a, b) => (a.name > b.name ? 1 : -1))
    }
    return districts
  }

  districts = (() => {
    if (cities?.length) {
      /* 市隐藏的情况 */
      if (isCityHidden) {
        // 初始化的时候会覆盖province初始化的district值，因此需要重新赋值
        let dis = flatten(cities.map((item) => item?.child)).filter(Boolean)
        if (language === 'en') {
          dis = dis.sort((a, b) => (a.name > b.name ? 1 : -1))
        }
        return dis
      }

      const city = cities.find((item) => item?.name === value)
      return city?.child || []
    }

    return []
  })()

  form.setFieldsValue({
    departure_district: undefined,
    departure_town: undefined,
  })

  await dispatch(
    updateAddressVO({
      departure_city: value,
      departure_district: undefined,
      departure_town: undefined,
    })
  )

  await dispatch(actions.update({ districts }))
}

/** 选择发货区 */
export const selectSenderAddressDistrictNew = (value: string) => async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance) => {
  const { senderAddressForm: state } = getState()
  const { isCityHidden } = await dispatch(getSenderAddressVisibility())

  const { districts } = state
  const district = districts.find((item) => item.name === value)

  dispatch(updateAddressVO({ departure_district: value, departure_town: undefined }))
  // clear town
  form.setFieldsValue({
    departure_district: value,
    departure_town: undefined,
  })

  dispatch(
    actions.update({
      towns: district?.child || [],
    })
  )

  // 马来西亚旧数据如果有市，切换区后会带着错误的区过去
  if (isCityHidden) {
    form.setFieldsValue({ departure_city: undefined })
  }
}
