import API from '@/services/http'
import type * as TYPES from './types'
import type { IExtraTaskOptions } from '@/hooks'

export * as TYPES from './types'

/**  create the prepare batch merge */
export const postPreprocess = (params: TYPES.SalesOrderBatchCombinedDeliveryPreprocessRequest) => {
  return API.toast.post<{
    taskId: string
  }>('/api/sales/order/batch_combined/preprocess', params)
}

/**
 * **接口** [查询批量勾选合单发货分组列表↗](http://api-doc-sl.inshopline.com/#/project/74/feature%2F20221103_1295_batch_combined_delivery/interface/a2cb6154424af509b0b3349961a8cb2a)
 * @请求头 `POST /api/sales/order/batch_combined/group/page`
 * @更新时间 `2022-10-11 15:09:19`
 */
export function getMergeBatchGroupList(params?: TYPES.SalesOrderBatchCombinedDeliveryGroupPageRequest) {
  return API.toast.post<TYPES.GroupPageRes>(`/api/sales/order/batch_combined/group/page`, params)
}

/**
 * **接口** [查询批量勾选合单发货分组内部详情↗](http://api-doc-sl.inshopline.com/#/project/74/feature%2F20221103_1295_batch_combined_delivery/interface/26d2079411b78f973dcbfc38ee0b4c22)
 * @请求头 `POST /api/sales/order/batch_combined/group/data/list`
 * @更新时间 `2022-10-11 15:09:19`
 */
export function getGroupChildrenOrderList(params?: TYPES.SalesOrderBatchCombinedDeliveryGroupDataListRequest) {
  return API.toast.post<{ data: TYPES.DataListRes[]; total: number }>(`/api/sales/order/batch_combined/group/data/list`, params)
}

/**
 * **接口** [查询批量勾选合单发货分组↗](http://api-doc-sl.inshopline.com/#/project/74/feature%2F20221103_1295_batch_combined_delivery/interface/df894a7e3568e33c32a494ac33371e3e)
 * @请求头 `POST /api/sales/order/batch_combined/group/get`
 * @更新时间 `2022-10-11 15:09:19`
 */
export function postGroupGet(params?: TYPES.SalesOrderBatchCombinedDeliveryGroupGetRequest) {
  return API.toast.post<TYPES.GroupGetRes>(`/api/sales/order/batch_combined/group/get`, params)
}

/**
 * **接口** [修改批量勾选合单发货分组↗](http://api-doc-sl.inshopline.com/#/project/74/feature%2F20221103_1295_batch_combined_delivery/interface/a54f447e8f1e5bb465a26ec4e1d88866)
 * @请求头 `POST /api/sales/order/batch_combined/group/modify`
 * @更新时间 `2022-10-11 15:09:19`
 */
export function postGroupModify(params?: TYPES.SalesOrderBatchCombinedDeliveryGroupModifyRequest) {
  return API.toast.post<TYPES.GroupModifyRes>(`/api/sales/order/batch_combined/group/modify`, params)
}

/**
 * **接口** [删除批量勾选合单发货分组↗](http://api-doc-sl.inshopline.com/#/project/74/feature%2F20221103_1295_batch_combined_delivery/interface/5f10e4bdcae21889cf68bf7395339757)
 * @请求头 `POST /api/sales/order/batch_combined/group/delete`
 * @更新时间 `2022-10-11 15:09:19`
 */
export function postGroupDelete(params?: TYPES.SalesOrderBatchCombinedDeliveryGroupDeleteRequest) {
  return API.toast.post<TYPES.GroupDeleteRes>(`/api/sales/order/batch_combined/group/delete`, params)
}

/**
 * **接口** [删除批量勾选合单发货分组内部信息↗](http://api-doc-sl.inshopline.com/#/project/74/feature%2F20221103_1295_batch_combined_delivery/interface/431324e2ebc271fd049b545e0b41ff9b)
 * @请求头 `POST /api/sales/order/batch_combined/group/data/delete`
 * @更新时间 `2022-10-11 15:09:19`
 */
export function postDeleteChildrenOrder(params?: TYPES.SalesOrderBatchCombinedDeliveryGroupDataDeleteRequest) {
  return API.toast.post<TYPES.DataDeleteRes>(`/api/sales/order/batch_combined/group/data/delete`, params)
}

/**
 * **接口** [批量勾选合单发货↗](http://api-doc-sl.inshopline.com/#/project/74/feature%2F20221103_1295_batch_combined_delivery/interface/8ea254f643881c7940a3dfec988a7671)
 * @请求头 `POST /api/sales/order/batch_combined/delivery`
 * @更新时间 `2022-10-11 15:09:19`
 */
export function postDelivery(params?: TYPES.SalesOrderBatchCombinedDeliveryRequest) {
  return API.toast.post<TYPES.DeliveryRes>(`/api/sales/order/batch_combined/delivery`, params)
}

/**
 * **接口** [查询服务产品预约派送时间↗](http://api-doc-sl.inshopline.com/#/project/74/feature%2F20221103_1295_batch_combined_delivery/interface/59124b0291cd6f8d90bc147bed936194)
 * @请求头 `POST /api/express/delivery_time/list`
 * @更新时间 `2022-10-11 15:09:19`
 */
export function postDeliveryTimeList(data?: TYPES.ExpressTypeDeliveryTimeListRequest) {
  return API.toast.post<TYPES.DeliveryTimeListRes>(`/api/express/delivery_time/list`, data)
}

/**
 * **接口** [查询分组服务产品↗](http://api-doc-sl.inshopline.com/#/project/74/feature%2F20221103_1295_batch_combined_delivery/interface/fa16a4342601b90cb815b7b7a783430a)
 * @请求头 `POST /api/sales/order/batch_combined/group/express_type/list`
 * @更新时间 `2022-10-11 15:09:19`
 */
export function postExpressTypeList(data?: TYPES.SalesOrderBatchCombinedDeliveryGroupExpressTypeListRequest) {
  return API.toast.post<{
    expressTypeGroups: TYPES.ExpressTypeListRes[]
  }>(`/api/sales/order/batch_combined/group/express_type/list`, data)
}

/**
 * **接口** [查询分组服务产品↗](http://api-doc-sl.inshopline.com/#/project/74/feature%2F20221103_1295_batch_combined_delivery/interface/fa16a4342601b90cb815b7b7a783430a)
 * @请求头 `POST /api/sales/order/batch_combined/group/express_type/list`
 * @更新时间 `2022-10-11 15:09:19`
 */
export function postExpressTypeListV2(data?: TYPES.SalesOrderBatchCombinedDeliveryGroupExpressTypeListRequest) {
  return API.toast.post<{
    expressTypeGroups: TYPES.ExpressTypeListRes[]
  }>(`/api/sales/order/batch_combined/group/express_type/list/v2`, data)
}

/** 查看 task 是否过期 */
export const detectSnapshotExpired = (data?: { taskId: string; wsMsgId?: string }) => {
  return API.toast.post<{
    taskId: string
    taskSnapshotVersion: string
  }>('/api/sales/order/batch_combined/task/validate', { ...data, newVersion: true })
}

/**
 * **接口** [查询批量勾选合单发货分组↗](http://api-doc-sl.inshopline.com/#/project/74/feature%2F20221103_1295_batch_combined_delivery/interface/df894a7e3568e33c32a494ac33371e3e)
 * @请求头 `POST /api/sales/order/batch_combined/group/data/page
 * @更新时间 `2022-10-11 15:09:19`
 */
export function postGroupGetDetailPage(params?: TYPES.SalesOrderBatchCombinedDeliveryGroupGetRequest) {
  return API.toast.post<TYPES.GroupGetPageRes>(`/api/sales/order/batch_combined/group/data/page`, params)
}

/**
 * **接口** [设置分组配置↗](http://api-doc-sl.inshopline.com/#/project/74/feature%2F20221103_1295_batch_combined_delivery/interface/e957ff28811aa9a8f74e6030c7b7e862)
 * @请求头 `POST /api/sales/order/batch_combined/group/setting/set`
 * @更新时间 `2022-10-21 15:18:58`
 */
export function postSettingSet(data?: TYPES.SalesOrderBatchCombinedDeliveryGroupSettingSetRequest) {
  return API.toast.post(`/api/sales/order/batch_combined/group/setting/set`, data)
}

export const queryPickUpTime = (payload: TYPES.PickUpTimeType) => {
  return API.admin.post<TYPES.ResponseProviderInfo>('/api/express/pick_up_time', payload)
}

export const setGroupAddress = (payload: TYPES.SalesOrderBatchCombinedDeliveryGroupAddressSetRequest) => {
  return API.toast.post('/api/sales/order/batch_combined/group/address/set', payload)
}

/**
 * **接口** [全量设置:↗](http://api-doc-sl.inshopline.com/#/project/60/feature%2F20230309_2067_batch_delivery/interface/7009c588c1e907fe9eb0922eca9e7136)
 * @请求头 `POST /api/sales/order/batch_combined/full/update`
 * @更新时间 `2023-02-21 14:32:50`
 */
export function postFullUpdate(payload?: TYPES.BatchDeliveryFullUpdateInfoVoRequest & IExtraTaskOptions) {
  return API.toast.post('/api/sales/order/batch_combined/full/update', payload)
}

/** 保存修改信息 */
export function saveMergeOrders(data: { taskSnapshotVersion: string }) {
  return API.toast.post('/api/sales/order/batch_combined/save', data)
}
