import { useAsyncFn } from 'react-use'
import { getAddress } from '@/services/apis/addressmatch'

export const useGetDefaultAddress = () => {
  const [state, fetch] = useAsyncFn(() => getAddress({ isDefault: true }), [])

  return {
    state,
    fetch,
  }
}
