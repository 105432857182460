import React from 'react'
import type { RouteConfig } from 'react-router-config'
import RedirectOrder from '@/layouts/RedirectOrder'

// 兼容外部跳oneship的路由层级改动
const routes: RouteConfig[] = [
  {
    path: '/stores/list',
    component() {
      return <RedirectOrder to="/setting/stores/list" />
    },
    exact: true,
  },
  {
    path: '/order/list',
    component() {
      return <RedirectOrder to="/ship/order/list" />
    },
    exact: true,
  },
]

export default routes
