import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { AppDispatch } from '@/store'

import type * as Types from './types'
import { getSettleMentSdkToken } from '@/services/apis/subscribe'
import { getTargetOmsConfig } from '@/utils'

const initialState: Types.IInitialState = {
  subStatus: null,
  subscribePakcageConfig: null,
  accessTokenInfo: {},
}

const subscribeSlice = createSlice({
  name: 'subscribeSlice',
  initialState,
  reducers: {
    setSubStatus(state, action: PayloadAction<any>) {
      return {
        ...state,
        subStatus: action.payload,
      }
    },
    setPacakgeConfig(state, action: PayloadAction<Types.IInitialState['subscribePakcageConfig']>) {
      return {
        ...state,
        subscribePakcageConfig: action.payload,
      }
    },
    seAccessTokenInfo(state, action: PayloadAction<Types.IInitialState['accessTokenInfo']>) {
      return {
        ...state,
        accessTokenInfo: action.payload,
      }
    },
  },
})

export const { setSubStatus, seAccessTokenInfo } = subscribeSlice.actions

/** 获取订阅套餐配置 */
export const fetchSubscribePacakgeConfig = () => async (dispatch: AppDispatch) => {
  const config = await getTargetOmsConfig('AccountSubscribePackage')
  dispatch(subscribeSlice.actions.setPacakgeConfig(config))
  return config
}

/** 获取订阅sdk token */
export const getSdkToken = () => async (dispatch: AppDispatch, getState) => {
  const { subscribeSlice } = getState()
  const { accessTokenInfo } = subscribeSlice

  // 还在有效时间内，不用请求
  if (accessTokenInfo.expireTime && accessTokenInfo.expireTime > new Date().getTime()) {
    return
  }

  const { data } = await getSettleMentSdkToken()
  dispatch(seAccessTokenInfo(data))
}

export default subscribeSlice.reducer
