import type { IAddressVO, IConsigneeAddressItem, ISenderAddressItem } from './type'

export interface AddressContent {
  address: string
  addressTwo: string
  province: string
  city: string
  district: string
  zipCode: string
  town?: string
  countryCode: string
}

const CHINA_AREA = ['CN', 'TW', 'HK']

export const generateAddress: (p: AddressContent) => string = (addressContent) => {
  const { addressTwo = '', address = '', province = '', city = '', district = '', town = '', zipCode = '', countryCode = '' } = addressContent
  const result = []
  let res = ''
  result.push(address)
  result.push(addressTwo)
  if (CHINA_AREA.includes(countryCode)) {
    result.push(province)
    result.push(city)
    result.push(district)
    result.push(town)
  } else {
    result.push(town)
    result.push(district)
    result.push(city)
    result.push(province)
  }
  res = result.filter((item) => !!item).join(` `)
  zipCode && (res = res + `, ` + zipCode)
  return res
}

export const concatAddress = (addressContent: Partial<IAddressVO> & { country?: string }): string => {
  const { addressTwo = '', address = '', province = '', city = '', district = '', zipCode = '', country = '', town = '' } = addressContent

  return generateAddress({
    addressTwo,
    address,
    province,
    city,
    town,
    district,
    zipCode,
    countryCode: country,
  })
}

export const convertSenderAddress = (address: Partial<ISenderAddressItem>) => {
  return Object.entries(address ?? {}).reduce((acc, [key, value]) => {
    const replaceKey = key.replace('sender', '').replace(/(.)/, (v) => v?.toLowerCase())

    acc[replaceKey] = value
    return acc
  }, {} as any) as Partial<IAddressVO>
}

export const convertSigneeAddress = (address: Partial<IConsigneeAddressItem>): Partial<IAddressVO> => {
  return Object.entries(address ?? {}).reduce((acc, [key, value]) => {
    const replaceKey = key.replace('consignee', '').replace(/(.)/, (v) => v?.toLowerCase())

    acc[replaceKey] = value
    return acc
  }, {} as any) as Partial<IAddressVO>
}
