import type * as TYPES from './type'
import API from '@/services/http'
/**
 * **接口** [费用转换↗](https://api-doc-sl.inshopline.com/#/project/Java/60/feature%2F20230706_2792_cod_convert/interface/aeff7c4ec92436239e4ad7a437c5d0d7)
 * @请求头 `POST /api/fee/convert`
 * @更新时间 `2023-06-13 15:52:51`
 */
export function postFeeConvert(data?: TYPES.FeeConvertRequest) {
  return API.admin.post<TYPES.ConvertRes>(`/api/fee/convert`, data)
}
