import API from '@/services/http'
import { awaitAsyncTask, createWsMsgId } from '@/hooks'
import type { AwaitAsyncTaskOptions } from '@/hooks'
import { uuid } from './common'
import { logger } from './logger'

type HttpWithAsyncTaskOptions = Omit<AwaitAsyncTaskOptions, 'idType' | 'timeout'> & {
  /** 超时，单位 秒 */
  timeout: number | string
}

export const httpWithAsyncTask = async (url: string, param: Record<string, any>, options?: HttpWithAsyncTaskOptions) => {
  const id = uuid()
  const data = {
    ...param,
    nonce: id,
  }
  const timeStart = Date.now()
  // logger.track({ type: 'request', url, trace_id: id, data })
  const extraTaskOptions = createWsMsgId()

  const promise = API.toast.post(url, {
    ...data,
    ...extraTaskOptions,
  })

  try {
    const task = await awaitAsyncTask(id, {
      errorToast: true,
      throwError: true,
      throwTimeoutError: true,
      ...options,
      timeout: Number(options.timeout) * 1000,
      idType: 'nonce',
      ...extraTaskOptions,
    })
    const result = await promise
    logger.track({ type: 'response', url, trace_id: id, tti: Date.now() - timeStart, data: '' })
    return { result, task }
  } catch (error) {
    logger.track({ type: 'action', event_type: 'error', event_target: 'api', data: error, url, trace_id: id })
    throw error
  }
}
