import type { OneConfigRoot } from '@yy/one-config/libs/one-config'
import type { AppThunkDispatch } from '@/store'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { getTargetOmsConfig } from '@/utils'

type IConfig = Partial<OneConfigRoot['oneship-config']> & { ready: boolean; notificationDuration?: number }

const initialState: IConfig = {
  ready: false,
}

const configSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setConfig(state, action: PayloadAction<IConfig>) {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const { setConfig } = configSlice.actions

export const getConfig = async (dispatch: AppThunkDispatch, getState) => {
  /**
   * 有了就直接返回
   */
  if (typeof getState === 'function' && getState().configSlice.ready) {
    return getState().configSlice
  }

  const configRes = await getTargetOmsConfig('oneship-config')

  const config = {
    ready: true,
    ...configRes,
    notificationDuration: 5000,
  } as IConfig

  dispatch(setConfig(config))

  return config
}

export default configSlice.reducer
