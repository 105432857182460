import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { AppGetState, AppThunkDispatch } from '@/store'
import { decodeLanguageAndProvince } from '../../utils/address'
import type { IAddressMatchState } from './types'

const initialState: IAddressMatchState = {
  show: false,
  addressVO: undefined,
}

/**
 * 地址匹配
 */
const addressMatchSlice = createSlice({
  name: 'addressMatch',
  initialState,
  reducers: {
    update(state, action: PayloadAction<IAddressMatchState>) {
      const updateState: IAddressMatchState = {
        show: action.payload.show,
      }

      if (action.payload.addressVO) {
        updateState.addressVO = {
          ...(updateState.addressVO || {}),
          ...action.payload.addressVO,
        }
      }

      return {
        ...state,
        ...updateState,
      }
    },
  },
})

export const { update } = addressMatchSlice.actions

export const updateAddressShow = (show?: boolean) => (dispatch: AppThunkDispatch, getState: AppGetState) => {
  const { addressMatch } = getState()
  dispatch(
    update({
      /**
       * 有就用外面的，不是就修改值
       */
      show: typeof show === 'boolean' ? show : !addressMatch.show,
    })
  )
}

/**
 * 设置地址匹配
 * @param data
 * @returns
 */
export const setAddressMatchAddressVo = (data: IAddressMatchState['addressVO']) => (dispatch: AppThunkDispatch) => {
  const addressVO = {
    ...data,
    province: decodeLanguageAndProvince(data.province)?.province,
  }
  dispatch(
    update({
      show: true,
      addressVO,
    })
  )
}

/**
 * 恢复初始状态
 * @returns
 */
export const clearSetAddressMatch = () => (dispatch: AppThunkDispatch) => {
  dispatch(
    update({
      show: false,
      addressVO: undefined,
    })
  )
}

export default addressMatchSlice.reducer
