import moment from 'moment'

export function formatObject2Undefined<T extends Record<string, any>>(data?: T) {
  if (!data) return undefined

  return (Object.keys(data) as Array<keyof T>).reduce((acc, key) => {
    acc[key] = data[key] === '' ? undefined : data[key]
    return acc
  }, {} as T)
}

export const formatDateFn = (date, format = 'YYYY-MM-DD HH:mm:ss', defaultString = '') => {
  try {
    return date ? moment(date).format(format) : defaultString
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('parse Date error，source date is ', date)
  }
  return ''
}

/**
 * 是否平台物流
 * @param expressLogisticsType
 * @returns
 */
export function isPlatformLogistics(expressLogisticsType) {
  return expressLogisticsType === 1
}
