import { noDecimalsCurrency } from '@/constant'
import i18n from '@/services/i18n'
import { countryIs } from '@/utils/countryVersion'
import type { RuleObject, StoreValue, ValidatorRule } from 'rc-field-form/es/interface'
import type { Assign } from 'utility-types'

type Options = {
  checkRequired?: boolean
}
interface OptionsParams {
  checkRequired?: boolean
}

type Rule = Assign<
  Omit<ValidatorRule, 'validator'>,
  {
    validator?(rule: RuleObject, value: StoreValue): Promise<any>
  }
>

declare function Create<R extends Record<string, ((...args: any[]) => Rule) | (() => Rule)>>(rules: R): R

/**
 * antd表单自定义校验规则
 */

interface OptionsParams {
  checkRequired?: boolean
  maxLength?: number
}

/** antd表单自定义校验规则 */
export default (((rules) => rules) as typeof Create)({
  /* 地址1 */
  address: () => {
    return {
      validator: async (_, value: string) => {
        const length = value?.length
        const maxLength = 256
        if (length > maxLength) {
          throw new Error(i18n.t('common.formValidLengthLessThan', { length: maxLength }))
        }
      },
    }
  },
  /* 地址2 */
  addressTwo: () => {
    return {
      validator: async (_, value: string) => {
        const length = value?.length
        const maxLength = 256
        if (length > maxLength) {
          throw new Error(i18n.t('common.formValidLengthLessThan', { length: maxLength }))
        }
      },
    }
  },
  /* 邮箱，地址簿专用 */
  addressEmail: (options?: OptionsParams) => {
    return {
      validator: async (_, value: string) => {
        const { checkRequired = true } = options || {}
        const length = value?.length
        if (checkRequired && !length) {
          throw Error(i18n.t('common.formValidRequired'))
        }
        if (value && !/^([A-Za-z0-9_\-\+\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/.test(value)) {
          throw Error(i18n.t('common.formValidEmail'))
        }
        if (length > 64) {
          throw Error(i18n.t('common.formValidLengthLessThan', { length: 64 }))
        }
      },
    }
  },
  /* 姓名 */
  fullName: (options?: Options) => {
    return {
      validator: async (_, value: string) => {
        const length = value?.length
        if (options.checkRequired && !length) {
          throw new Error(i18n.t('common.formValidRequired'))
        }
      },
    }
  },
  /* 邮箱 */
  email: (options?: OptionsParams) => {
    return {
      validator: async (_, value: string) => {
        const { checkRequired = true } = options || {}
        const length = value?.length
        if (checkRequired && !length) {
          throw Error(i18n.t('common.formValidRequired'))
        }
      },
    }
  },
  /* 多个邮箱 */
  multiEmail: () => {
    return {
      validator: async (_, values: string[]) => {
        if (!values?.length) {
          throw Error(i18n.t('common.formValidRequired'))
        }
        values.forEach((value) => {
          if (value && !/^([A-Za-z0-9_\-\+\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/.test(value)) {
            throw Error(i18n.t('common.formValidEmail'))
          }
          if (length > 64) {
            throw Error(i18n.t('common.formValidLengthLessThan', { length: 64 }))
          }
        })
      },
    }
  },
  /* 手机，地址簿专用 */
  addressMobile: (options?: OptionsParams & { checkIsCN?: boolean }) => {
    return {
      validator: async (_, value: string) => {
        const { checkRequired = true, maxLength = 30, checkIsCN = false } = options || {}
        const length = value?.length
        if (checkRequired && !length) {
          throw Error(i18n.t('common.formValidRequired'))
        }
        if (value && !/^\+?\d+$/.test(value) && !countryIs.CN && checkIsCN) {
          // 跨境收发货、地址本不校验
          throw Error(i18n.t('common.formValidMobile'))
        }
        if (length > maxLength) {
          throw Error(i18n.t('common.formValidLengthLessThan', { length: maxLength }))
        }

        return true
      },
    }
  },
  /* 手机 */
  mobile: (options?: OptionsParams & { checkIsCN?: boolean }) => {
    return {
      validator: async (_, value: string) => {
        const { checkRequired = true } = options || {}
        const length = value?.length
        if (checkRequired && !length) {
          throw Error(i18n.t('common.formValidRequired'))
        }
      },
    }
  },
  /* 必填 */
  required: () => {
    return {
      required: true,
      message: i18n.t('common.formValidRequired'),
    }
  },
  /* 字符必填通用 */
  requiredStringOption: (props: { required?: boolean; trim?: boolean }) => {
    return {
      validator: async (_, value: string) => {
        const val = props.trim ? value?.replace(/(^\s*)|(\s*$)/g, '') : value
        if (props.required && !val?.length) {
          throw Error(i18n.t('common.formValidRequired'))
        }
        return true
      },
    }
  },
  /* 上传通用 */
  requiredUpload: (props: { required?: boolean }) => {
    return {
      validator: async (_, value: string) => {
        if (props?.required && !value) {
          throw Error(i18n.t('common.upload.required'))
        }
        return true
      },
    }
  },
  /* 最大长度 */
  maxLength: (max: number, required = true) => {
    return {
      validator: async (_, value: string) => {
        if (required && !value?.length) {
          throw Error(i18n.t('common.formValidRequired'))
        }
        if (value?.length > max) {
          throw Error(i18n.t('common.formValidLengthLessThan', { length: max }))
        }
      },
    }
  },
  /** 包裹价值 */
  provider_insured_amount: (options: { currencyCode: string; minInsuranceValue?: number; maxInsuranceValue?: number; required?: boolean }) => {
    return {
      validator: async (_, price: string | number) => {
        const required = typeof options.required === 'undefined' ? true : options.required
        price = price?.toString()
        const value = price?.replace(/\s+/g, '')

        if (!value) {
          if (required) throw Error(i18n.t('common.formValidRequired'))
          return true
        }

        /**
         * 不允许有小数
         * @description
         * 以下国家/地区币种不允许有小数
         */
        const noDecimals = noDecimalsCurrency.includes(options.currencyCode)

        if (noDecimals) {
          // 只支持正整数，不能有小数位
          if (!/^[1-9]\d*$/.test(value)) {
            throw Error(i18n.t('order.create.parcelItemsPriceLimit'))
          }
        } else {
          // 小数位不能大于2
          if (!/^\d+(\.\d{1,2})?$/.test(value) || Number(value) <= 0) {
            throw Error(i18n.t('setting.converted.limit.price', { min: 0, max: 2 }))
          }
        }

        const { maxInsuranceValue, minInsuranceValue = 0 } = options || {}
        // 最小值必须有, 这里只需要判断最大值即可
        if (typeof maxInsuranceValue === 'number') {
          if (Number(value) <= minInsuranceValue || Number(value) > maxInsuranceValue) {
            throw Error(i18n.t('order.create.providerInsuredAmountRnage', { max: maxInsuranceValue, min: minInsuranceValue }))
          }
        } else {
          if (Number(value) <= minInsuranceValue) {
            throw Error(i18n.t('order.create.providerInsuredAmountMoreThan', { min: minInsuranceValue }))
          }
        }

        return true
      },
    }
  },
  /* 参考号 */
  originNo: () => {
    return {
      validator: async (_, value: string) => {
        const length = value?.length
        if (length > 64) {
          throw new Error(i18n.t('order.copy.newOriginNO.limit'))
        }
      },
    }
  },
  /** 余额监控 */
  balanceThreshold: (options: { currencyCode: string }) => {
    return {
      validator: async (_, amount: number) => {
        if (!amount && amount !== 0) {
          throw Error(i18n.t('common.formValidRequired'))
        }

        const amountString = String(amount)

        /**
         * 不允许有小数
         * @description
         * 以下国家/地区币种不允许有小数
         */
        const noDecimals = noDecimalsCurrency.includes(options.currencyCode)

        if (noDecimals) {
          // 支持整数，不能有小数位
          if (!/^-?\d+$/.test(amountString)) {
            throw Error(i18n.t('newOrder.parcelSizeLimit'))
          }
        } else {
          // 小数位不能大于2
          if (!/^-?\d+(.\d{0,2})?$/.test(amountString)) {
            throw Error(i18n.t('order.create.freeNumberDot'))
          }
        }
      },
    }
  },
  /** 通用重量校验 */
  weight: (params?: Options) => {
    return {
      validator: async (_, w: string | number) => {
        const { checkRequired = true } = params || {}
        if (!checkRequired && !w && typeof w !== 'number') {
          return
        }

        if (checkRequired) {
          if (!w && typeof w !== 'number') {
            throw Error(i18n.t('common.formValidRequired'))
          }
        }

        const value = String(w)

        const re = /^-?\d+(\.\d+)?$/
        if (!re.test(value)) {
          throw Error(i18n.t('common.formValidType', { type: i18n.t('common.formValidTypeNumber') }))
        }

        if (Number(value) < 0) {
          throw Error(i18n.t('common.formValid.weight', { num: 0 }))
        }

        // 整数位不能大于8
        const maxInt = /^\d{1,8}(\.\d+)?$/
        if (!maxInt.test(value)) {
          throw Error(i18n.t('common.formValid.integer', { n: 8 }))
        }

        // 小数位不能大于3
        const maxDot = /^\d+(\.\d{1,3})?$/
        if (!maxDot.test(value)) {
          throw Error(i18n.t('order.create.parcelWeightNumberDot'))
        }
      },
    }
  },
  /**
   * 通用金额规则
   * @param options
   * @returns
   */
  amount: (options: { currencyCode: string; min?: number; max?: number; required?: boolean; noDecimals?: boolean }) => {
    return {
      validator: async (_, price: string | number) => {
        const required = typeof options.required === 'undefined' ? true : options.required
        price = price?.toString()
        const value = price?.replace(/\s+/g, '')
        const { max, min = 0 } = options || {}

        if (!value) {
          if (required) throw Error(i18n.t('common.formValidRequired'))
          return true
        }

        /**
         * 不允许有小数
         * @description
         * 以下国家/地区币种不允许有小数
         */
        const noDecimals = options.noDecimals || noDecimalsCurrency.includes(options.currencyCode)
        if (noDecimals) {
          // 只支持正整数，不能有小数位
          if (!/^[1-9]\d*$/.test(value)) {
            throw Error(i18n.t('order.create.parcelItemsPriceLimit'))
          }
        } else {
          // 小数位不能大于2
          if (!/^\d+(\.\d{1,2})?$/.test(value) || Number(value) <= 0) {
            throw Error(i18n.t('setting.converted.limit.price', { min, max: 2 }))
          }
        }

        // 最小值必须有, 这里只需要判断最大值即可
        if (typeof max === 'number') {
          if (Number(value) <= min || Number(value) >= max) {
            throw Error(i18n.t('common.formValidRange', { max, min }))
          }
        } else {
          if (Number(value) <= min) {
            throw Error(i18n.t('common.formValidMoreThan', { min }))
          }
        }

        // 整数位不能大于15
        const maxInt = /^\d{1,15}(\.\d+)?$/
        if (!maxInt.test(value)) {
          throw Error(i18n.t('common.formValid.integer', { n: 15 }))
        }

        return true
      },
    }
  },
  /**
   * 校验下拉选择值是否符合选项
   */
  selectOptionsRule: (options: { selectOptions?: { value: string | number }[]; required?: boolean; name?: string }) => {
    return {
      validator: async (_, val: string | number) => {
        const { selectOptions, required, name } = options
        if (val === '' || val === null || val === undefined) {
          if (required) throw Error(i18n.t('common.formValidRequired'))
          return
        }

        if (selectOptions?.every((op) => op.value !== val)) throw Error(i18n.t('common.illegalVal', { name: name || '' }))
      },
    }
  },
  /* 售卖国家 */
  saleTargetCountry: () => {
    return {
      validator: async (_, value: string[]) => {
        const length = value?.length
        if (!length) {
          throw new Error(i18n.t('selling.areaCountry.limit', { num: 1 }))
        }
      },
    }
  },
  /** 库存 */
  stock: () => {
    return {
      validator: async (_, stock: string | number) => {
        stock = stock?.toString()
        const value = stock?.replace(/\s+/g, '')

        if (!value) {
          return
        }

        if (!/^[0-9]\d*$/.test(value)) {
          throw Error(i18n.t('order.price.parcelItemsPriceLimit'))
        }
      },
    }
  },
  /** 库存 */
  integer: () => {
    return {
      validator: async (_, stock: string | number) => {
        stock = stock?.toString()
        const value = stock?.replace(/\s+/g, '')

        if (!value) {
          return
        }

        if (!/^[0-9]\d*$/.test(value)) {
          throw Error(i18n.t('order.price.parcelItemsPriceLimit'))
        }
      },
    }
  },
})

export const EMPTY_RULES = [{ validator: () => Promise.resolve() }]
