import { useMemo, useState } from 'react'

interface TableHeaderSortState {
  /** 排序的表头key */
  tableHeaderKeys: string[]
  /** 勾选显示的表头key */
  selectedRowKeys: string[]
}
/**
 * 记录表格排序的状态
 */
export function useTableSort() {
  const [headerSortState, setHeaderSortState] = useState<TableHeaderSortState>({
    tableHeaderKeys: [],
    selectedRowKeys: [],
  })

  /**
   * 排序后的表头字段
   */
  const headerSortKeys = useMemo(() => {
    const { tableHeaderKeys, selectedRowKeys } = headerSortState
    return tableHeaderKeys.filter((key) => selectedRowKeys.includes(key))
  }, [headerSortState])

  return {
    headerSortKeys,
    headerSortState,
    setHeaderSortState,
  }
}
