// 业务相关的工具函数
import { SHIP_TYPE_ENUM } from './enum'
import { LogisticsType } from './logisticsSlice/type'

/** 是否为退货 */
export const isReverse = (stage: `${SHIP_TYPE_ENUM}`) => {
  return [`${SHIP_TYPE_ENUM.REVERSE}`, `${SHIP_TYPE_ENUM.REVERSE_BATCH}`, `${SHIP_TYPE_ENUM.REVERSE_EDIT}`].includes(stage)
}

/** 将场景转换成 logisticsType */
export const convertStageToLogisticsType = (stage: `${SHIP_TYPE_ENUM}`) => {
  if (isReverse(stage)) {
    return LogisticsType.reverse
  }

  return LogisticsType.express
}
