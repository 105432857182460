import { useBoolean, useMount } from 'ahooks'
import type { FormInstance } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useRecipientAddressForm } from '@/bizComponent/RecipientAddressForm'
import { is711CrossBorder, isCatService, isMapService } from '@/bizComponent/RecipientAddressForm/utils'
import AddressChooseModal from '@/components/AddressChooseModal'
import type { IOnCancel, IOnfinish } from '@/components/AddressChooseModal/type'
import { useShip, useWarnningErrorAddress } from '@/hooks'
import { useGetStation } from '@/hooks/useChooseStation'
import { useDispatch, useSelector } from '@/store'
import { setAddressMatchAddressVo } from '@/store/addressMatchSlice'
import { setProps as setProviderProps } from '@/store/newServiceProviderSlice'
import type { LogisticsOrderInfo } from '@/store/order/createSlice/types'
import { handleArrivalCountryChange } from '@/store/parcelSlice'
import { initRecipientAddressForm } from '@/store/recipientAddressSlice'
import { initSenderAddressForm } from '@/store/senderAddressSlice'
import { encodeLanguageAndProvince } from '@/utils/address'
import { formatRecipientAddressFormValues } from '@/utils/format'
import { LogisticsType } from '@/store/logisticsSlice/type'
import { useTranslation } from 'react-i18next'

export function useMergeAddressModal(form: FormInstance, isReverse: boolean) {
  const dispatch = useDispatch(form)
  const { warnningErrorAddress } = useWarnningErrorAddress(form)
  const senderCountryCode = useSelector((state) => state.user?.info?.countryCode)
  const { t } = useTranslation()

  const { update: updateArrivalCountry } = useRecipientAddressForm(form, {
    logisticsType: isReverse ? LogisticsType.reverse : LogisticsType.express,
  })

  const { getShipInfo } = useShip<{ orderInfo: LogisticsOrderInfo }>()
  const shipInfo = useMemo(() => getShipInfo(), [getShipInfo])

  const [chooseModalVisible, chooseModalVisibleHandle] = useBoolean(false)

  useMount(() => {
    /**
     * 有重复的地址则打开合单发货弹窗
     */
    const hasAddress = shipInfo?.ext?.orderInfo?.consigneeAddressVos?.length || shipInfo?.ext?.orderInfo?.senderAddressVos?.length
    /* 合单发货，展示收货地址弹窗，只在刚进入的时候展示一次 */
    if (hasAddress) {
      chooseModalVisibleHandle.setTrue()
    }
  })

  const { handleGetStation } = useGetStation({ cancelButton: false })

  const initSenderAddress = useCallback(
    async (departureAddress) => {
      // 重新初始化发货地址
      await dispatch(
        initSenderAddressForm({
          formValues: {
            departure_countryCode: departureAddress.departure_countryCode,
            departure_city: departureAddress.departure_city,
            departure_province: departureAddress.departure_province,
            departure_district: departureAddress.departure_district,
            departure_town: departureAddress.departure_town,
            departure_zipCode: departureAddress.departure_zipCode,
            departure_address: departureAddress.departure_address,
            departure_addressTwo: departureAddress.departure_addressTwo,
            departure_fullName: departureAddress.departure_fullName,
            departure_mobile: departureAddress.departure_mobile,
          },
        })
      )

      // 初始化服务产品依赖请求数据
      await dispatch(
        setProviderProps({
          addressVO: {
            departure_city: departureAddress.departure_city,
            departure_province: departureAddress.departure_province,
            departure_zipCode: departureAddress.departure_zipCode,
            departure_district: departureAddress.departure_district,
            departure_town: departureAddress.departure_town,
            departure_address: departureAddress.departure_address,
            departure_addressTwo: departureAddress.departure_addressTwo,
            departure_fullName: departureAddress.departure_fullName,
            departure_mobile: departureAddress.departure_mobile,
          },
        })
      )
    },
    [dispatch]
  )

  const handleOnFinish: IOnfinish = useCallback(
    async (params) => {
      const addrssInfo = {}

      // 处理发货地址
      if (params.senderAddress) {
        // 合并地址
        Object.assign(addrssInfo, params?.senderAddress?.origin)
        /**
         * 设置发货地址
         */
        const { address, origin } = params.senderAddress

        const departure_province = encodeLanguageAndProvince({
          province: address.province,
          languages: origin.senderAddressLanguages,
        })

        const departureAddress = {
          departure_mobile: address.mobile || address.phone,
          departure_fullName: address.name,
          departure_email: address.email,
          departure_addressTwo: address.addressTwo,
          departure_address: address.address,
          departure_zipCode: address.zipCode,
          departure_countryCode: address.countryCode,
          departure_province: departure_province,
          departure_city: address.city,
          departure_district: address.district,
          departure_town: address.town,
        }
        form.setFieldsValue(departureAddress)

        await initSenderAddress(departureAddress)
      }

      chooseModalVisibleHandle.setFalse()

      // 处理收货地址
      if (params.consigneeAddress) {
        const { address, orderNo, origin } = params.consigneeAddress

        /**
         * 非店配
         */
        if (!address.stationCode) {
          const arrival_countryCode = form.getFieldValue('arrival_countryCode')

          const arrival_province = encodeLanguageAndProvince({
            province: address.province,
            languages: origin.consigneeAddressLanguages,
          })

          // 合并地址
          Object.assign(addrssInfo, params.consigneeAddress?.origin)

          if (arrival_countryCode !== address.countryCode) {
            await updateArrivalCountry({
              arrivalCountryCode: address.countryCode,
            })
            // 更新币种信息
            await dispatch(handleArrivalCountryChange(address.countryCode))
          }
          const arrivalAddress = {
            arrival_province,
            arrival_method: 1,
            arrival_countryCode: address.countryCode,
            arrival_city: address.city,
            arrival_district: address.district,
            arrival_town: address.town,
            arrival_zipCode: address.zipCode,
            arrival_address: address.address,
            arrival_addressTwo: address.addressTwo,
            arrival_fullName: address.name,
            arrival_email: address.email,
            arrival_mobile: address.mobile || address.phone,
            arrival_isSave: false,
          }

          form.setFieldsValue(arrivalAddress)

          await dispatch(
            initRecipientAddressForm({
              formValues: arrivalAddress,
            })
          )

          // 初始化服务产品依赖请求数据
          await dispatch(
            setProviderProps({
              addressVO: {
                arrival_countryCode: arrivalAddress.arrival_countryCode,
                arrival_province: arrivalAddress.arrival_province,
                arrival_city: arrivalAddress.arrival_city,
                arrival_district: arrivalAddress.arrival_district,
                arrival_town: arrivalAddress.arrival_town,
                arrival_zipCode: arrivalAddress.arrival_zipCode,
                arrival_address: arrivalAddress.arrival_address,
                arrival_addressTwo: arrivalAddress.arrival_addressTwo,
                arrival_fullName: arrivalAddress.arrival_fullName,
                arrival_mobile: arrivalAddress.arrival_mobile,
              },
            })
          )

          // 地址匹配
          await dispatch(
            setAddressMatchAddressVo({
              countryCode: arrivalAddress.arrival_countryCode,
              province: arrivalAddress.arrival_province,
              city: arrivalAddress.arrival_city,
              district: arrivalAddress.arrival_district,
              town: arrivalAddress.arrival_town,
              address: arrivalAddress.arrival_address,
              addressTwo: arrivalAddress.arrival_addressTwo,
              zipCode: arrivalAddress.arrival_zipCode,
              appId: shipInfo?.ext?.orderInfo.appId,
            })
          )
        }

        /**
         * 店配
         */
        if (address.stationCode) {
          // 黑猫不请求门店地址
          const isBlackCat = isCatService(shipInfo?.formValues?.arrival_store_companyCode)
          const station = isBlackCat ? null : await handleGetStation({ stationCode: address.stationCode, orderNo, stationName: address.stationName })
          const stationRequiredProps = {
            orderNo,
            stationCode: station?.stationCode || address.stationCode,
          }

          if (!station) {
            // 没有门店信息, 黑猫用所选的地址匹配；其他不匹配使用接口默认数据填充
            const stationAddress = isCatService
              ? {
                  ...origin,
                  companyCode: shipInfo?.formValues?.arrival_store_companyCode,
                  expressTypeCode: shipInfo?.provider?.expressTypeCode,
                }
              : shipInfo?.ext?.orderInfo?.address
            const data = formatRecipientAddressFormValues({
              addressInfo: stationAddress as any,
              orderInfo: shipInfo?.ext?.orderInfo,
            })
            form.setFieldsValue(data)
            if (data.arrival_countryCode) {
              // 更新币种信息
              await dispatch(handleArrivalCountryChange(data.arrival_countryCode))
            }
            await dispatch(
              initRecipientAddressForm({
                stationRequiredProps,
                formValues: {
                  arrival_countryCode: data.arrival_countryCode,
                  arrival_store_companyCode: data.arrival_store_companyCode,
                  arrival_store_province: data.arrival_store_province,
                  arrival_store_city: data.arrival_store_city,
                  arrival_method: data.arrival_method,
                  arrival_station_type: data.arrival_station_type,
                  arrival_zipCode: data.arrival_zipCode,
                },
              })
            )

            // 初始化服务产品依赖请求数据
            await dispatch(
              setProviderProps({
                stationSeq: data.arrival_station_type + '',
                arrival_method: data.arrival_method,
                companyCode: data.arrival_store_companyCode,
                addressVO: {
                  arrival_countryCode: data.arrival_countryCode,
                  arrival_store_province: data.arrival_store_province,
                  arrival_store_city: data.arrival_store_city,
                },
              })
            )
            return
          }

          const stationArrivalAddress = {
            arrival_countryCode: station.countryCode,
            arrival_method: 2,
            arrival_store_companyCode: station.companyCode,
            arrival_store_station: station.stationSeq || '',
            arrival_station_type: station.stationType,
            arrival_store_province: station.province,
            arrival_store_city: station.city,
            arrival_store_district: station.district,
            arrival_zipCode: address?.zipCode || '',
            arrival_fullName: address?.name || '',
            arrival_mobile: address?.mobile || address?.phone || '',
            arrival_email: address?.email || '',
            ...(isMapService(station.companyCode, ['TCAT']) && {
              arrival_store_711station: {
                storeId: station?.stationCode,
                name: address?.stationName ?? '',
                /** 711跨境电子地图门店初始化不需要展示地址 */
                address: is711CrossBorder(station?.companyCode) ? '' : station?.address ?? '',
                province: station?.province ?? '',
                city: station?.city ?? '',
                district: station?.district ?? '',
              },
            }),
            // 711跨境保存所选的地址信息
            ...(is711CrossBorder(station.companyCode) && {
              arrival_province: encodeLanguageAndProvince({
                province: address.province,
                languages: origin.consigneeAddressLanguages,
              }),
              arrival_city: address.city,
              arrival_district: address.district,
              arrival_address: address.address,
              arrival_addressTwo: address.addressTwo,
            }),
          }

          if (is711CrossBorder(station.companyCode)) {
            // 合并地址
            Object.assign(addrssInfo, params.consigneeAddress?.origin)
          }

          form.setFieldsValue(stationArrivalAddress)

          await dispatch(
            initRecipientAddressForm({
              stationRequiredProps,
              formValues: stationArrivalAddress,
            })
          )

          // 更新币种信息
          await dispatch(handleArrivalCountryChange(stationArrivalAddress.arrival_countryCode))

          // 初始化服务产品依赖请求数据
          await dispatch(
            setProviderProps({
              stationSeq: stationArrivalAddress.arrival_station_type + '',
              arrival_method: stationArrivalAddress.arrival_method,
              companyCode: station.companyCode,
              addressVO: {
                arrival_countryCode: stationArrivalAddress.arrival_countryCode,
                arrival_store_province: stationArrivalAddress.arrival_store_province,
                arrival_store_city: stationArrivalAddress.arrival_store_city,
              },
            })
          )
        }
      }

      warnningErrorAddress({ address: addrssInfo as any }, { skipDeparture: !params.senderAddress, skipArrival: !params.consigneeAddress })
    },
    [
      chooseModalVisibleHandle,
      dispatch,
      form,
      handleGetStation,
      initSenderAddress,
      shipInfo?.ext?.orderInfo,
      shipInfo?.formValues?.arrival_store_companyCode,
      shipInfo?.provider?.expressTypeCode,
      updateArrivalCountry,
      warnningErrorAddress,
    ]
  )

  /**
   * 处理取消情况
   * @param address
   */
  const handleCancel: IOnCancel = useCallback(
    async (address) => {
      if (!isReverse) {
        if (address) {
          const provine = encodeLanguageAndProvince({
            province: address.province,
            languages: address.addressLanguages,
          })
          const departureAddress = {
            departure_province: provine,

            departure_mobile: address.mobile,
            departure_fullName: address.name,
            departure_email: address.email,
            departure_addressTwo: address.addressTwo,
            departure_address: address.address,
            departure_zipCode: address.zipCode,
            departure_countryCode: address.countryCode,
            departure_city: address.city,
            departure_district: address.district,
            departure_town: address.town,
          }

          form.setFieldsValue(departureAddress)

          // 重新初始化发货地址
          await initSenderAddress(departureAddress)
        } else {
          const departureAddress = {
            departure_countryCode: senderCountryCode,
          }

          form.setFieldsValue(departureAddress)

          // 重新初始化发货地址
          await initSenderAddress(departureAddress)
        }
      }
      chooseModalVisibleHandle.setFalse()
    },
    [chooseModalVisibleHandle, form, initSenderAddress, senderCountryCode, isReverse]
  )

  /* 新合单发货弹窗 */
  const mergeAddressModal = useMemo(() => {
    if (!shipInfo?.ext) return <></>

    return (
      <AddressChooseModal
        closable={false}
        maskClosable={false}
        visible={chooseModalVisible}
        title={isReverse ? t('mergeAddressModal.title.reverse') : undefined}
        onFinish={handleOnFinish}
        onCancel={handleCancel}
        senderAddresses={shipInfo?.ext?.orderInfo?.senderAddressVos}
        consigneeAddresses={shipInfo?.ext?.orderInfo?.consigneeAddressVos}
      />
    )
  }, [chooseModalVisible, handleCancel, handleOnFinish, shipInfo?.ext, t, isReverse])

  return {
    mergeAddressModal,
  }
}
