import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
// import { hasSellerAuthExpressType } from '@/services/apis'
import { useSelector } from '@/store'
import { awaitConfirm } from '@/components/ModalConfirmWithPromise'
import styles from './index.module.less'
import { OneModal, OneRadio, OneSteps } from '@yy/one-ui'
import { OsContractStatusEnum, postContractDetail } from '@/services/apis'
import { windowOpen } from '@/utils/browser'
/**
 * 跨境商家需要校验签约状态才可以发货
 * @returns
 */
export const useCNSign = () => {
  const { t } = useTranslation()
  const { info } = useSelector((s) => s.user)
  const { lang } = useSelector((s) => s.language)
  const step = useRef<number>(0)
  const selectUrlIndex = useRef<number>()

  const toOCSign = () => {
    window.open(`${process.env.ONECLUB_URL}/admin/sign`)
  }

  return useCallback(async () => {
    // 跨境版本与预付才需要签约
    if (info.selfArea === 'CN' && info.isPrepaid) {
      // 签约状态
      const { data } = await postContractDetail()
      const list = data?.oaContractUrls
      // 已签约完成
      if (data?.osContractStatus === OsContractStatusEnum.SIGNED) {
        return true
      }
      // 没有过签约或者签约未完成，发起签约
      if (data?.osContractStatus === OsContractStatusEnum.UNSIGNED) {
        // 签约中 step2
        if (data.oaContractUrls?.length && data?.oaContractUrls?.some((it) => it.thirdSignStates.find((state) => ['REALNAME', 'SENT'].includes(state)))) {
          step.current = 1
        }

        return await awaitConfirm({
          title: t('sign.title'),
          width: lang === 'zh-CN' ? 620 : 860,
          closeIcon: () => null,
          maskClosable: true,
          content: (
            <div className={styles.content}>
              <p className={styles.titleDec}>{t(`sign.step.${step.current}.content`)}</p>
              <OneSteps current={step.current}>
                <OneSteps.Step title={t(`sign.step.0.title`)} />
                <OneSteps.Step title={t(`sign.step.1.title`)} />
                <OneSteps.Step title={t(`sign.step.2.title`)} />
              </OneSteps>
            </div>
          ),
          okText: t(`sign.confirm.${step.current}`),
          onOk: () => {
            if (step.current === 0) {
              toOCSign()
            } // 已经发起过合同,签署中
            else if (step.current === 1 && list?.length) {
              const defalutSelectIndex = 0

              if (list?.length > 1) {
                selectUrlIndex.current = defalutSelectIndex
                // 多个合同
                OneModal.confirm({
                  title: t('sign.url.modal.title'),
                  content: (
                    <>
                      {t('sign.url.modal.content')}
                      <OneRadio.Group onChange={(e) => (selectUrlIndex.current = e.target.value)} defaultValue={defalutSelectIndex}>
                        {list?.map(
                          (
                            item,
                            index // 不能保证phone和oaContractUrl是唯一的不重复的，用index
                          ) => (
                            <OneRadio key={item.oaContractUrl + index} value={index}>
                              {item.phone}
                            </OneRadio>
                          )
                        )}
                      </OneRadio.Group>
                    </>
                  ),
                  cancelText: t('frame.cancel'),
                  okText: t('frame.confirm'),
                  onOk: () => {
                    if (selectUrlIndex.current === undefined) {
                      return Promise.reject()
                    }
                    const url = list[selectUrlIndex.current]?.oaContractUrl
                    windowOpen(url)
                    selectUrlIndex.current = undefined
                  },
                  onCancel: () => {
                    selectUrlIndex.current = undefined
                  },
                })
              } else {
                const url = list[defalutSelectIndex]?.oaContractUrl
                windowOpen(url)
              }
            }
            return Promise.reject(false)
          },

          cancelText: t('sign.reconfirm'),
          cancelButtonProps: {
            style: {
              display: step.current === 1 ? undefined : 'none',
            },
            onClick: toOCSign,
          },
        })
      }
    }
    return true
  }, [info.isPrepaid, info.selfArea, lang, t])
}
