import type { RouteConfig } from 'react-router-config'
import { withAsync } from '@/components/Async'
import Blank from '@/layouts/Blank'

const routes: RouteConfig[] = [
  // 运费试算
  {
    alias: 'shipping_fee_estimation',
    path: '/freight',
    component: Blank,
    menuTitle: 'freight.title',
    exact: false,
    permissionCode: 'ship:freight',
    routes: [
      {
        path: '/',
        component: withAsync(() => import(/* webpackChunkName: "Freight" */ '@/containers/Freight')),
        exact: true,
      },
    ],
  },
]

export default routes
