import API from '@/services/http'
import type { LogisticsOrderInfo } from '@/store/order/createSlice/types'
import type { OrderEditRequest } from '../order/type'
import type * as types from './type'
import { httpWithAsyncTask } from '@/utils/httpWithAsyncTask'
import type { IExtraTaskOptions } from '@/hooks'

export * as types from './type'

/**
 * **接口** [批量勾选发货-预处理↗](http://api-doc-sl.inshopline.com/#/project/60/feature%2F20230309_2067_batch_delivery/interface/c0b210acd505cb9d7b3453eb6f468eb5)
 * @请求头 `POST /api/sales/order/batch-delivery/preprocess`
 * @更新时间 `2023-02-21 14:32:51`
 */
export function postPreprocess(options: { orderNos: string[] } & IExtraTaskOptions) {
  return API.toast.post<{ taskId: string }>(`/api/sales/order/batch-delivery/preprocess`, { ...options, logisticsType: 'express' })
}

/**
 * **接口** [批量勾选发货-分页↗](http://api-doc-sl.inshopline.com/#/project/60/feature%2F20230309_2067_batch_delivery/interface/8be72a5b0bcc987ef0404ad68895cfcf)
 * @请求头 `POST /api/sales/order/batch-delivery/page`
 * @更新时间 `2023-02-21 14:32:51`
 */
export function postBatchDeliveryPage(data?: types.SalesOrderBatchDeliveryPageRequest) {
  return API.toast.post<types.BatchDeliveryPageRes>(`/api/sales/order/batch-delivery/page`, data)
}

/**
 * **接口** [查询分组服务产品↗](http://api-doc-sl.inshopline.com/#/project/60/feature%2F20230309_2067_batch_delivery/interface/fa83f42dfaa633f73eecc7805497c8c0)
 * @请求头 `POST /api/sales/order/batch-delivery/express-type/list`
 * @更新时间 `2023-02-21 14:32:51`
 */
export function postExpressTypeList(taskId: string) {
  return API.toast.post<types.ExpressTypeListRes>(`/api/sales/order/batch-delivery/express-type/list`, { taskId })
}

/**
 * **接口** [查询分组服务产品↗](https://api-doc-sl.inshopline.com/#/project/Java/74/feature%2F2633_multi_sender_country/interface/190edbe5157490ef9a08b05032f84673)
 */
export function postExpressTypeListV2(taskId: string) {
  return API.toast.post<types.ExpressTypeListResV2>(`/api/sales/order/batch-delivery/express-type/list/v2`, { taskId })
}

/**
 * **接口** [批量更新信息：↗](http://api-doc-sl.inshopline.com/#/project/60/feature%2F20230309_2067_batch_delivery/interface/113a8a9dcdc5b287942f8891eed5f670)
 * @请求头 `POST /api/sales/order/batch-delivery/batch/update`
 * @更新时间 `2023-02-21 14:32:51`
 */
export function postBatchUpdate(data?: types.BatchDeliveryBatchUpdateRequest) {
  return API.toast.post(`/api/sales/order/batch-delivery/batch/update`, data)
}

export function postBatchShip(taskId: string) {
  return API.toast.post('/api/sales/order/batch-delivery/submit', {
    taskId,
  })
}

/**
 * **接口** [全量更新信息：↗](http://api-doc-sl.inshopline.com/#/project/60/feature%2F20230309_2067_batch_delivery/interface/47d0d5f52c3cb75943cc64665a4a8014)
 * @请求头 `POST /api/sales/order/batch-delivery/full/update`
 * @更新时间 `2023-02-21 14:32:51`
 */
export function postFullUpdate(data?: types.BatchDeliveryFullUpdateRequest & IExtraTaskOptions) {
  return API.toast.post<{ taskId: string }>(`/api/sales/order/batch-delivery/full/update`, data)
}

/**
 * **接口** [全量更新信息：↗]并监听处理完成websocket
 * @请求头 `POST /api/sales/order/batch-delivery/full/update`
 * @更新时间 `2023-02-21 14:32:51`
 */
export function postFullUpdateAndWaitTask(data: types.BatchDeliveryFullUpdateRequest, timeOut: number) {
  return httpWithAsyncTask(`/api/sales/order/batch-delivery/full/update`, data, { timeout: timeOut, errorToast: false, throwError: false })
}

export function getOrderDetail(data: { taskId: string; orderNo: string; logisticsType?: string }) {
  return API.toast.post<LogisticsOrderInfo>('/api/sales/order/batch-delivery/detail', data)
}

export function modifyOrder(data: OrderEditRequest) {
  return API.toast.post('/api/sales/order/batch-delivery/modify', data)
}
/** 保存修改信息 */
export function saveOrders(data: { taskId: string; nonce: string }) {
  return API.toast.post('/api/sales/order/batch-delivery/save', data)
}
