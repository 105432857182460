import type { FormInstance } from 'antd'
import { useCallback } from 'react'
import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux'
import type { ThunkAction, ThunkDispatch } from 'redux-thunk'
import type { Action } from '@reduxjs/toolkit'
import { configureStore } from '@reduxjs/toolkit'
import type { RootState } from '@/store/rootReducer'
import rootReducer from '@/store/rootReducer'

// 中间件集合
const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  })

// 全局store
const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
})

// 热重载
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    /* eslint-disable-next-line @typescript-eslint/no-var-requires */
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

// 最原始的dispatch
export declare function AppDispatchOverload<T extends typeof store.dispatch>(...args: Parameters<T>): ReturnType<T>
export declare function AppDispatchOverload<T extends (dispatch: typeof AppDispatchOverload, getState: AppGetState, ...extraArguments: any[]) => any>(handle: T): ReturnType<T>

export type AppDispatch = typeof AppDispatchOverload

// 最原始的getState
export type AppGetState = typeof store.getState

// It is important to use Action as last type argument, does not work with any.
export type AppThunkDispatch = ThunkDispatch<RootState, undefined, Action>

// 给dispatch加上type
export const useDispatch = (form?: FormInstance): AppThunkDispatch => {
  const thunkDispatch = useReduxDispatch<AppThunkDispatch>()
  const dispatch = useCallback(
    (thunkAction) => {
      if (typeof thunkAction === 'object' && typeof thunkAction?.type === 'string') {
        return thunkDispatch(thunkAction)
      }

      if (typeof thunkAction === 'function') {
        return thunkDispatch((_, getState) => {
          return thunkAction(dispatch, getState, form)
        })
      }
    },
    [form, thunkDispatch]
  )

  return dispatch
}

// 给useSelector加上rootState的默认type
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

// 同步action的type
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

// 异步action的type
export type AsyncAppThunk<ReturnType = void> = ThunkAction<Promise<ReturnType>, RootState, unknown, Action<string>>

export default store
