type IIn18Resource = Record<string, string>

/**
 * 修改多语言品牌
 * @param data
 * @returns
 */
export const changedBrand = (data: IIn18Resource): IIn18Resource => {
  if (window.location.host.includes('oneclub.vn')) {
    const convertedData = Object.entries(data).reduce((acc, [key, value]) => {
      acc[key] = value.replace(/(\{\{.*?\}\}|oneship)/gi, (text) => {
        // ignore the {{}} values
        if (/\{\{.*\}\}/.test(text)) {
          return text
        }
        return 'OneClub'
      })

      return acc
    }, {} as IIn18Resource)
    return convertedData
  }

  return data
}
