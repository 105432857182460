import { Form } from 'antd'
import c from 'classnames'
import React from 'react'
import styles from './index.module.less'
import type { FormItemProps } from 'antd/lib/form'

/**
 * 加强了批量隐藏的功能
 */
export { default as DynamicEnhanceFormItem } from '../DynamicFormItemGroup/FormItem'
export interface IDynamicProps extends FormItemProps {
  hide?: boolean
  disabled?: boolean
}

export const DynamicFormItem: React.FC<IDynamicProps> = (props) => {
  const { hide, children, disabled, rules, className, ...rest } = props

  return (
    <Form.Item {...rest} className={c(className, { [styles.hidden]: hide, [styles.disabled]: disabled })} rules={hide ? void 0 : rules}>
      {children}
    </Form.Item>
  )
}

DynamicFormItem.displayName = 'DynamicFormItem'
export default DynamicFormItem
