import { useCallback, useEffect } from 'react'
import { OneMessage } from '@yy/one-ui'
import { useModalConfirm } from '@/bizComponent/ModalConfirm'
import { BizCodeEnum } from '@/constant'
import http from '@/services/http'
import i18n from '@/services/i18n'

// 不需要全局拦截提示toast的code
// stoken过期: -13， 已注册：2002
const udbWhiteCode = ['-13', '2002']
const adminWhiteCode = []

const GlobalSnackbars: React.FC = () => {
  const { SellerToBuySaasConfirm, SellerToCreateSellerAccountConfirm, MergeShipSnapshotExpireConfirm, CreateSassSubAccountConfirm, SellerToBuyCashOnDeliveryCountConfirm } =
    useModalConfirm()

  /**
   * 是否展示toast等ui
   */
  const handleShowToast = useCallback(
    (rejection) => {
      // 错误码匹配到多语言，自动提示
      const usei18nToast = rejection?.bizCode && !adminWhiteCode.includes(rejection?.bizCode)
      const usei18nSubToast = rejection?.code && !adminWhiteCode.includes(rejection?.code) // 订阅模块

      if (usei18nToast) {
        const extraData = {
          ...rejection?.data,
          ...(typeof rejection?.extMap === 'object' ? rejection?.extMap : {}),
        }
        const i18nResult = i18n.t([`system.error.${rejection.bizCode}`, rejection.bizCode /*fallback*/], {
          // Inject all of data into the i18n toast
          ...extraData,
        })
        // 只有匹配到指定的多语言code，才全局提示
        if (i18nResult === i18n.t(`system.error.${rejection.bizCode}`, extraData)) {
          OneMessage.error(i18nResult)
          return true
        }
      }

      if (usei18nSubToast) {
        // 订阅模块 为了区分订阅的全局错误，加了一个命名空间
        const i18nSubResult = i18n.t([`system.subError.${rejection.code}`, rejection.code /*fallback*/])
        const i18nErrorResult = i18n.t([`system.error.${rejection.code}`, '' /*fallback*/]) // 兼容bizcode的多语言
        // 只有匹配到指定的多语言code，才全局提示
        if (i18nSubResult === i18n.t(`system.subError.${rejection.code}`)) {
          OneMessage.error(i18nSubResult)
          return true
        } else if (i18nErrorResult) {
          OneMessage.error(i18nErrorResult)
          return true
        }

        // 处理远端错误 调用第三方接口异常，统一标识
        if (rejection?.code === BizCodeEnum.REMOTE_ERROR) {
          const errorCode = rejection?.errors?.[1]
          if (errorCode) {
            const i18nResult = i18n.t([`system.remoteError.${errorCode}`, errorCode /*fallback*/])
            // 只有匹配到指定的多语言code，才全局提示
            if (errorCode && i18nResult === i18n.t(`system.remoteError.${errorCode}`)) {
              OneMessage.error(i18nResult)
            } else {
              OneMessage.error(i18n.t('system.remoteError.customUnknownError'))
            }
            return true
          }
        }
      }

      // 业务错误，ui统一处理
      const bizHandleFnMap = {
        [BizCodeEnum.SELLER_TO_BUY_SAAS]: SellerToBuySaasConfirm, // 权益不足提示
        [BizCodeEnum.SELLER_TO_BUY_CASH_ON_DELIVERY_COUNT]: SellerToBuyCashOnDeliveryCountConfirm, // cash on delivery 权益不足提示
        [BizCodeEnum.NO_CREATE_SELLER_CONFIG]: SellerToCreateSellerAccountConfirm, // 商家渠道配置不存在提示
        [BizCodeEnum.CREATE_SASS_SUB_ACCOUNT_PICK_UP]: CreateSassSubAccountConfirm,
        [BizCodeEnum.CREATE_SASS_SUB_ACCOUNT_DROP_OFF]: CreateSassSubAccountConfirm,
        [BizCodeEnum.SNAPSHOT_EXPIRE]: MergeShipSnapshotExpireConfirm, // 合单发货快照过期
      }
      if (typeof bizHandleFnMap[rejection?.bizCode] === 'function') {
        const fn = bizHandleFnMap[rejection.bizCode]
        fn(rejection.bizCode)
        return true
      }

      return false
    },
    [SellerToBuySaasConfirm, SellerToBuyCashOnDeliveryCountConfirm, SellerToCreateSellerAccountConfirm, CreateSassSubAccountConfirm, MergeShipSnapshotExpireConfirm]
  )

  // admin HTTP 拦截器
  useEffect(() => {
    const id = http.admin.interceptors.response.use(
      (response) => response,
      (rejection) => {
        const hasToast = handleShowToast(rejection)
        if (hasToast) {
          // 修改数据，去掉message等，避免在发起请求者的catch中再次提示message
          return Promise.reject({
            data: null,
            bizCode: rejection.bizCode,
          })
        }

        return Promise.reject(rejection)
      }
    )

    return () => {
      http.admin.interceptors.response.eject(id)
    }
  }, [handleShowToast])

  // udb拦截器
  useEffect(() => {
    const id = http.udb.interceptors.response.use(
      (response) => response,
      (rejection) => {
        const shouldToast = !udbWhiteCode.includes(rejection?.rescode) && rejection?.rescode
        if (shouldToast) {
          const i18nResult = i18n.t([`udb.rescode.${rejection.rescode}`, rejection.resmsg /*fallback*/])

          OneMessage.error(i18nResult)
        }

        return Promise.reject(rejection)
      }
    )

    return () => {
      http.udb.interceptors.response.eject(id)
    }
  }, [])

  // toast HTTP 拦截器
  useEffect(() => {
    const id = http.toast.interceptors.response.use(
      (response) => response,
      (rejection) => {
        const hasToast = handleShowToast(rejection)
        if (hasToast) {
          // 修改数据，去掉message等，避免在发起请求者的catch中再次提示message
          return Promise.reject({
            data: null,
            bizCode: rejection.bizCode,
            rejection,
          })
        }

        // 错误提示
        if (rejection?.message && rejection?.message !== '请求已取消') {
          OneMessage.error(rejection.message)
        }

        return Promise.reject(rejection)
      }
    )

    return () => {
      http.toast.interceptors.response.eject(id)
    }
  }, [handleShowToast])

  return null
}

export default GlobalSnackbars
