import type { ReactNode } from 'react'
import React from 'react'
import { Helmet } from 'react-helmet'
import type { MatchedRoute } from 'react-router-config'
import { matchRoutes, renderRoutes } from 'react-router-config'
import { useSocket } from '@/hooks'
import { useSelector } from '@/store'
import type { MenuDataItem } from '@/utils/route'
import { antRoutes } from '@/utils/route'
import PageLayout from '../PageLayout'
import styles from './index.module.less'
import { useCookieSdk } from '@/hooks/useCookieSdk'

interface PrimaryLayoutProps {
  route: {
    routes: MenuDataItem[]
  }
  location: Location
  children?: ReactNode
}

const PrimaryLayout: React.FC<PrimaryLayoutProps> = (props) => {
  useCookieSdk()

  const displayWallet = useSelector((state) => state.user?.info?.displayWallet)
  const { route, location } = props

  route.routes = route.routes.filter((item) => {
    if (item.menuTitle == 'menu.Balance') {
      if (!displayWallet) {
        //不是预付不能进钱包菜单
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  })

  const matchedRoutes: MatchedRoute<string>[] = matchRoutes([route], location.pathname)
  const currentRoute = matchedRoutes[matchedRoutes.length - 1].route

  // 接入socket
  useSocket()

  return (
    <>
      <Helmet>
        <html className={styles.root} />
        <body className={styles.body} />
      </Helmet>
      <PageLayout routers={antRoutes(route.routes)} hasSider={!currentRoute.noSide} noModal={currentRoute.noModal} matchedRoutes={matchedRoutes}>
        {renderRoutes(route.routes)}
      </PageLayout>
    </>
  )
}

export default PrimaryLayout
