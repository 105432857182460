import type { Assign } from 'utility-types'
import { ACT, APP_ID, EVENT_ID_NAME_LIST } from './constants'
import type { TrackBasicParams, TrackCoreParams } from './types'

export const SLQ_ENV_MAP: Record<string, 'DEV' | 'TEST' | 'FAT' | 'PRO'> = {
  /* dev 环境没有数据展示, 所以这里开发环境直接使用 测试环境配置 */
  develop: 'TEST',
  test: 'TEST',
  preview: 'FAT',
  product: 'PRO',
}

/** 默认配置 */
const DefaultEventOptions: Omit<TrackBasicParams, keyof TrackCoreParams> = {
  act: ACT,
  release: process.env.TRACE_RELEASE,
  env: SLQ_ENV_MAP[process.env.APP_ENV],
  project_id: APP_ID,
}

/** 上报sdk基础配置项 */
export const configure = <T extends keyof typeof EVENT_ID_NAME_LIST>(type: T) => {
  return <D extends Record<string, any>>(data: D): Assign<D, TrackBasicParams> => {
    const { event_id, event_name, intercept } = EVENT_ID_NAME_LIST[type]
    const finalData = typeof intercept === 'function' ? intercept(data) : data
    return Object.assign({}, finalData, DefaultEventOptions, { event_id, event_name })
  }
}
