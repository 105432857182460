import type { RouteConfig } from 'react-router-config'
import { Balance } from '@yy/one-icon'
import { withAsync } from '@/components/Async'
import RouteAuth from '@/components/RouteAuth'

const routes: RouteConfig[] = [
  {
    alias: 'wallet',
    path: '/wallet',
    component: RouteAuth,
    icon: Balance,
    menuTitle: 'menu.Balance',
    // permissionCode: 'wallet',
    routes: [
      {
        path: '/',
        component: withAsync(() => import(/* webpackChunkName: "Wallet" */ '@/containers/Wallet')),
        exact: true,
      },
      /** 流水记录 */
      {
        path: '/record',
        component: withAsync(() => import(/* webpackChunkName: "Wallet" */ '@/containers/Wallet/Record')),
        exact: true,
      },
      {
        path: '/pay',
        component: withAsync(() => import(/* webpackChunkName: "WalletPay" */ '@/containers/Wallet/Pay')),
        exact: true,
      },
    ],
  },
]

export default routes
