import type { AppDispatch, AppGetState, AppThunk } from '..'
import { OneModal } from '@yy/one-ui'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { GET_DEFAULT_PAGE_SIZE } from '@/constant'
import i18n from '@/services/i18n'
import { getImmediateBatchBaseOrderInfos } from '@/services/apis'
import { postFullUpdateAndWaitTask } from '@/services/apis/batchship'
import type { BatchDeliveryFullUpdateRequest } from '@/services/apis/batchship/type'
import type { BatchDeliveryOrderResVo } from '@/services/apis/order/type'
import type { CreateType } from '@/store/stepSlice/types'
import { getUrlParam } from '@/utils/getUrlParam'
import { EnumTabs } from './enum'
import type * as Types from './types'

export * from './enum'

export const getKeyInfo = (tab: string) => {
  const isInvalid = tab === EnumTabs.INVALID
  const isValid = tab === EnumTabs.VALID
  const listKey = isInvalid ? 'invalidList' : 'validList'
  return {
    listKey,
    isInvalid,
    isValid,
  }
}

const initialState: Types.IInitialState = {
  tab: EnumTabs.VALID,
  validList: [],
  invalidList: [],
  pagination: {
    pageSize: GET_DEFAULT_PAGE_SIZE(),
    pageNum: 1,
    total: 0,
  },
  loading: false,
  totalFreight: 0,
  /** 服务产品列表 */
  providerList: [],
  settingList: [],
  taskId: '',
  invalidCorrectAddressCount: 0,
  validCorrectAddressCount: 0,
  validCount: 0,
  invalidCount: 0,
}

/** 批量发货 */
const immediateBatchShip = createSlice({
  name: 'immediateBatchShip',
  initialState: {
    ...initialState,
    taskId: getUrlParam('taskId'),
  },
  reducers: {
    update(state, action: PayloadAction<Partial<Types.IInitialState>>) {
      const pagination = {
        ...(state.pagination || {}),
        ...(action.payload.pagination || {}),
      }

      return {
        ...state,
        ...action.payload,
        pagination,
      }
    },
    updateOrder(state) {
      return {
        ...state,
      }
    },
    setTaskId(state, action: PayloadAction<string>) {
      return {
        ...state,
        taskId: action.payload,
      }
    },
    /** 更新列表数据 */
    updateOrderInfoMatchedAddress(state, action: PayloadAction<Types.IMatchAddress & { orderNo: string }>) {
      const payload = action.payload

      const { tab, validList, invalidList } = state

      const keyInfo = getKeyInfo(tab)

      const list = keyInfo.isInvalid ? invalidList.slice() : validList.slice()

      const index = list.findIndex((item) => item.orderNo == payload.orderNo)

      list[index] = {
        ...list[index],
        address: {
          ...(list[index]?.address || {}),
          ...payload,
        },
        // 已替换清空智能地址
        correctAddress: null,
        correct: true,
      }
      return {
        ...state,
        [keyInfo.listKey]: list,
      }
    },
  },
})

export const { update, updateOrderInfoMatchedAddress, setTaskId } = immediateBatchShip.actions

// 更新订单
export const updateOrder =
  (data: { orderInfo: CreateType; tab: string; order: BatchDeliveryOrderResVo }): AppThunk =>
  async (dispatch) => {
    const { orderInfo, tab, order } = data

    // 编辑非法订单
    if (tab === EnumTabs.INVALID) {
      dispatch(
        updateInvalidOrder({
          order,
          orderInfo,
        })
      )
    } else {
      // 编辑合法订单
      dispatch(
        updateValidOrder({
          order,
          orderInfo,
        })
      )
    }
  }

// 更新订单
export const updateValidOrder =
  (data: { orderInfo: CreateType; order: BatchDeliveryOrderResVo }): AppThunk =>
  async (dispatch, getState) => {
    const { orderInfo, order } = data

    const state = getState()
    const { immediateBatchShip } = state

    const { validList = [] } = immediateBatchShip

    const list = validList.slice()

    const index = list.findIndex((item) => item.orderNo == order.orderNo)

    list.splice(index, 1)
    const newOrder = {
      ...order,
      orderInfo,
      // 清空智能地址
      correctAddress: order?.correctAddress,
      correct: false,
      expressInfo: null,
    }

    list.unshift(newOrder)

    dispatch(
      update({
        validList: list,
      })
    )
  }

export const updateInvalidOrder =
  (data: { orderInfo: CreateType; order: BatchDeliveryOrderResVo }): AppThunk =>
  async (dispatch, getState) => {
    const { orderInfo, order } = data

    const state = getState()
    const { immediateBatchShip } = state

    const { invalidList = [], validList = [] } = immediateBatchShip

    const newInvalidList = invalidList.slice()
    // 从无效订单列表删除
    const index = invalidList.findIndex((item) => item.orderNo == order.orderNo)
    // 无效订单存在该单号才删除，否则会把最后的订单误删掉
    if (index !== -1) {
      newInvalidList.splice(index, 1)
    }
    const newValidList = validList.slice()

    const newOrder = {
      ...order,
      orderInfo,
      // 编辑重新校验仍有智能匹配地址
      correctAddress: order?.correctAddress,
      correct: false,
    }
    const validIndex = newValidList.findIndex((item) => item.orderNo == order.orderNo)
    if (validIndex === -1) {
      newValidList.unshift(newOrder)
    }
    dispatch(
      update({
        validList: newValidList,
        invalidList: newInvalidList,
      })
    )
  }

export const updateValidMatchedOrder =
  (data: { orderNo: string }): AppThunk =>
  async (dispatch, getState) => {
    const { orderNo } = data

    const state = getState()
    const { immediateBatchShip } = state

    const { invalidList = [], validList = [] } = immediateBatchShip

    const newInvalidList = invalidList.slice()
    // 从无效订单列表删除
    const index = invalidList.findIndex((item) => item.orderNo == orderNo)
    const [order] = newInvalidList.splice(index, 1)

    const newValidList = validList.slice()

    newValidList.unshift(order)

    dispatch(
      update({
        validList: newValidList,
        invalidList: newInvalidList,
      })
    )
  }

export const clearBatchShipData = (): AppThunk => async (dispatch) => {
  dispatch(
    update({
      ...initialState,
    })
  )
}

/** 智能匹配全部替换地址后批量更新列表地址信息 */
export const updateOrderInfoBatchMatchedAddress = (matchAddress: Array<{ orderNo: string; address: Types.IMatchAddress }>) => (dispatch: AppDispatch, getState: AppGetState) => {
  const { tab, validList, invalidList } = getState().immediateBatchShip
  const keyInfo = getKeyInfo(tab)
  const list = keyInfo.isInvalid ? invalidList.slice() : validList.slice()
  const newList = list.map((item) => {
    const matchConvertAddress = matchAddress?.find((el) => el.orderNo === item.orderNo)?.address
    if (matchConvertAddress) {
      return {
        ...item,
        address: { ...item.address, ...matchConvertAddress },
        correctAddress: null,
        correct: true,
      }
    } else {
      return item
    }
  })
  dispatch(update({ [keyInfo.listKey]: newList }))
}

export const getBatchShipOrderInfos = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  dispatch(update({ loading: true }))
  try {
    const {
      immediateBatchShip: { taskId, tab },
    } = getState()
    const baseParam = {
      pageNum: 1,
      pageSize: 50,
      logisticsType: 'ondemand',
      taskId,
    }
    // 即时物流批量订单获取
    const resList = await getImmediateBatchBaseOrderInfos({
      ...baseParam,
      validGroup: Number(tab),
    })
    const { data, invalidCount, validCount, summaryFees, validCorrectAddressCount, invalidCorrectAddressCount } = resList.data

    // 初始化参数
    dispatch(
      update({
        [tab === EnumTabs.VALID ? 'validList' : 'invalidList']: data,
        pagination: {
          total: invalidCount + validCount,
        },
        loading: false,
        summaryFees,
        validCorrectAddressCount,
        invalidCorrectAddressCount,
        validCount,
        invalidCount,
      })
    )
  } catch (error) {
    dispatch(update({ loading: false }))
  }
}

export const handleMessage = (res) => {
  const key = `system.error.${res?.response?.code}`
  const parametes = {
    ...res?.extMap,
    privacyAppealOrderNos: res?.extMap?.privacyAppealOrderNos?.join(',') || '',
  }
  const message = key === i18n.t(key, parametes) ? res?.message : i18n.t(key, parametes)

  OneModal.error({
    content: message || i18n.t('system.remoteError.SYSTEM_ERROR'),
    centered: true,
    okText: i18n.t('common.Ikonw'),
  })
}

export const fullBatchUpdate =
  (data: BatchDeliveryFullUpdateRequest['data']): AppThunk =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState()
    const { batchshipTimeout } = state.configSlice
    const { tab, taskId } = state.immediateBatchShip

    try {
      const { task } = await postFullUpdateAndWaitTask(
        {
          taskId,
          valid: tab === EnumTabs.VALID,
          data,
        },
        Number(batchshipTimeout)
      )
      if (task?.response?.success) {
        dispatch(getBatchShipOrderInfos())
      } else {
        handleMessage(task.response)
      }
    } finally {
      dispatch(update({ loading: false }))
    }
  }

export default immediateBatchShip.reducer
