import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { LanguageType } from '@/constant/language'
import { LANGUAGE_MAP } from '@/constant/language'
import store, { useDispatch, useSelector } from '@/store'
import { setLanguage } from '@/store/languageSlice'
import type { OneConfigRoot } from '@yy/one-config/libs/one-config'

// 国家默认语言 主要给轨迹设置那边用的
export const countryLangTypeMap = {
  CN: 'zh-hans-cn',
  HK: 'zh-hant-hk',
  TW: 'zh-hant-tw',
  VN: 'vi',
  TH: 'th',
  ID: 'id',
  JP: 'ja',
}
const defualtLangugeMap = {} as OneConfigRoot['oneship-config']['languageDefaultMap']
export const getLanguageList = (code?: string) => {
  const config = store.getState().configSlice.languageDefaultMap || defualtLangugeMap
  const countrySet = new Set(Object.keys(config?.countryMap || {}))

  const upperCode = code?.toUpperCase()

  // 作弊功能
  if (localStorage.getItem('os-show-me-language')) {
    return config?.other?.map((code) => LANGUAGE_MAP[code]).filter(Boolean)
  }

  if (!upperCode) {
    return []
  } else if (countrySet.has(upperCode)) {
    const list = config.countryMap[upperCode]?.map((code) => LANGUAGE_MAP[code])
    return list?.map((item) => {
      if (item?.label?.includes('中文繁體')) {
        return {
          ...item,
          label: '中文繁體',
        }
      }
      return item
    })
  } else {
    return config.other?.map((code) => LANGUAGE_MAP[code]).filter((item) => Boolean(item))
  }
}

const showMeLanguage = () => {
  localStorage.setItem('os-show-me-language', '1')
  location.reload()
}

;(window as unknown as any).showMeLanguage = showMeLanguage

export const useLanguage = () => {
  const languageList = useSelector((state) => state.user?.languageList)
  const dispatch = useDispatch()
  const { i18n } = useTranslation()

  // 国家Code / lang object
  const handleChangeLanguage = useCallback(
    async (inputLang: LanguageType | string) => {
      let lang: LanguageType
      if (typeof inputLang === 'string') {
        const countryCode = inputLang?.toUpperCase()
        const languageList = getLanguageList(countryCode)
        lang = languageList[0]
      } else {
        lang = inputLang
      }

      // 兜底，匹配不到
      lang = lang || LANGUAGE_MAP.en

      await dispatch(setLanguage(lang))
      i18n.changeLanguage(lang?.lang)
    },
    [dispatch, i18n]
  )

  useEffect(() => {
    const run = async () => {
      if (!languageList.length) return

      const localLang = languageList?.find((item) => item?.lang === window.localStorage.i18nextLng)
      const lang = localLang || languageList[0]

      // 只可以 fixed 一些选择了原本不支持的语言的用户
      if (lang && window.localStorage.i18nextLng !== lang?.lang) {
        handleChangeLanguage(lang)
      }
    }

    run()
  }, [dispatch, handleChangeLanguage, i18n, languageList])

  const localLanguageLang = useMemo(() => {
    const selfLanguageInfo = languageList?.find((item) => item?.lang === window.localStorage.i18nextLng)

    const defaultLang = selfLanguageInfo || LANGUAGE_MAP.en

    return {
      defaultLang,
      lang: defaultLang?.lang,
      languageType: defaultLang.languageType,
    }
  }, [languageList])

  const handleFixedLocalLanguage = useCallback(
    (countryCode: string) => {
      if (!window.localStorage.getItem('os-init-user-default-lang')) {
        window.localStorage.setItem('os-init-user-default-lang', '1')
        handleChangeLanguage(countryCode)
      }
    },
    [handleChangeLanguage]
  )

  return {
    languageList,
    localLanguageLang,
    handleChangeLanguage,
    handleFixedLocalLanguage,
  }
}
