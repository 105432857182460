import type { AppDispatch, AppGetState } from '..'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type * as Types from './types'
import { createSetReducers } from '../storeUtils'
import { batchCreatePage, batchUpdateOrder } from '@/services/apis'
import type { BatchCreateOrderVo } from '@/services/apis/sales/type'

/**
 * redux初始化默认值
 */
const initialState: Types.BatchCreateOrderState = {
  stepUploadIsLock: false,
  serviceUploadResult: null,
  calculateLoading: false,
  fileId: '',
  taskId: null,
  pagination: {
    currentPage: 0,
    pageSize: 25,
    total: 0,
  },
}

/**
 * 切片
 * name：批量创建订单
 * initialState：初始化值
 * reducers：当前模块reducer的集合，触发action后，就触发reducers
 */
const batchCreateOrderSlice = createSlice({
  name: 'immediateBatchCreate',
  initialState,
  reducers: {
    ...createSetReducers(initialState),
    setStepUploadIsLock(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        stepUploadIsLock: action.payload,
      }
    },
    setServiceUploadResult(state, action: PayloadAction<any>) {
      return {
        ...state,
        serviceUploadResult: action.payload,
      }
    },
    setCalculateLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        calculateLoading: action.payload,
      }
    },
    setFileId(state, action: PayloadAction<Types.BatchCreateOrderState['fileId']>) {
      return {
        ...state,
        fileId: action.payload,
      }
    },
    reset() {
      return {
        ...initialState,
      }
    },
  },
})

export const { setStepUploadIsLock, setServiceUploadResult, setCalculateLoading, setFileId, reset } = batchCreateOrderSlice.actions
const actions = batchCreateOrderSlice.actions

export const initTask = (taskId: string) => async (dispatch: AppDispatch) => {
  dispatch(actions.setTaskId(taskId))
  dispatch(
    actions.setPagination({
      currentPage: 1,
      pageSize: 25,
      total: 0,
    })
  )
  dispatch(paginationChange())
}

export const paginationChange = (currentPage?: number, pageSize?: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { pagination, taskId } = getState().immediateBatchCreate
  const pageNum = currentPage || pagination.currentPage
  const { data } = await batchCreatePage({ taskId, pageSize: pageSize || pagination.pageSize, pageNum })
  dispatch(
    actions.setPagination({
      pageSize: pageSize || pagination.pageSize,
      currentPage: pageNum,
      total: data.total,
    })
  )
  dispatch(setServiceUploadResult(data))
}

export const updateBatchOrder = (orders: BatchCreateOrderVo[]) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { taskId } = getState().immediateBatchCreate
  await batchUpdateOrder({ taskId, orders })
  dispatch(paginationChange())
}

export default batchCreateOrderSlice.reducer
