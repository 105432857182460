import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Api from '@/services/http'
import { userUrl } from '@/services/url'
import type { AppDispatch } from '@/store'
import type * as Types from './types'
import cloneDeep from 'lodash/cloneDeep'

const initialState: Types.ServiceProviderState = {
  provider: null,
  addressInfo: null,
  serviceList: null,
  lastCalcFeeResult: [],
  subAccountDetails: [],
}

const { actions, reducer } = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    // 更新选中的服务商
    setSelectedProvider(state, action) {
      return {
        ...state,
        provider: action.payload,
      }
    },
    setAddressInfo(state, action: PayloadAction<Types.IAddressInfo>) {
      return {
        ...state,
        addressInfo: action.payload,
      }
    },
    setServiceList(state, action: PayloadAction<Types.TServiceList>) {
      return {
        ...state,
        serviceList: action.payload,
      }
    },
    setExpressList(state, action: PayloadAction<Types.ExpressListData[]>) {
      return {
        ...state,
        expressList: action.payload,
      }
    },
    setLastCalcFeeResult(state, action: PayloadAction<Types.FeeObject[]>) {
      return {
        ...state,
        lastCalcFeeResult: action.payload,
      }
    },
    setSubAccountDetails(state, action: PayloadAction<Types.ISubAccountItem[]>) {
      return {
        ...state,
        subAccountDetails: action.payload,
      }
    },
  },
})

export const getSubAccount = (payload: Types.ISubAccountPayload) => async (dispatch: AppDispatch) => {
  const response = await Api.admin.post<Types.ISubAccountInfo>(userUrl.subAccount, payload)
  const { data, bizCode } = response

  if (bizCode === 'SUCCESS') {
    const { expressTypeCode, subAccountDetails } = data || {}
    dispatch(setSubAccountDetails(cloneDeep(subAccountDetails)))
    return {
      expressTypeCode,
      subAccountDetails,
    }
  }
}
export const { setSelectedProvider, setAddressInfo, setServiceList, setLastCalcFeeResult, setSubAccountDetails } = actions

export default reducer
