import type { FormInstance } from 'antd'
import { Form } from 'antd'
import { useCallback } from 'react'
import type { ValuesType } from 'utility-types'
import { is711CrossBorder } from '@/bizComponent/RecipientAddressForm/utils'
import { ADDRESS_TYPE, ARRIVAL_METHOD } from '@/constant'
import { addressAdd } from '@/services/apis'
import type { IAddress } from '@/store/addressSlice/types'

export function useCreateAddressFn(inputForm?: FormInstance) {
  const createAddress = useCallback(async (address: IAddress, type: ValuesType<typeof ADDRESS_TYPE>) => {
    switch (type) {
      case ADDRESS_TYPE.Departure: {
        await addressAdd({
          name: address.senderName,
          address: address.senderAddress,
          addressTwo: address.senderAddressTwo,
          email: address.senderEmail,
          mobile: address.senderMobile,
          province: address.senderCountryCode === 'SG' ? 'Singapore' : address.senderProvince,
          city: address.senderCountryCode === 'SG' ? 'Singapore' : address.senderCity,
          district: address.senderDistrict,
          town: address.senderTown,
          countryCode: address.senderCountryCode,
          zipCode: address.senderZipCode,
          addressType: ADDRESS_TYPE.Departure,
          isDefault: false,
        })

        break
      }

      case ADDRESS_TYPE.Arrival: {
        await addressAdd({
          name: address.consigneeName,
          address: address.consigneeAddress,
          addressTwo: address.consigneeAddressTwo,
          email: address.consigneeEmail,
          mobile: address.consigneeMobile,
          province: address.consigneeCountryCode === 'SG' ? 'Singapore' : address.consigneeProvince,
          city: address.consigneeCountryCode === 'SG' ? 'Singapore' : address.consigneeCity,
          district: address.consigneeDistrict,
          town: address.consigneeTown,
          countryCode: address.consigneeCountryCode,
          zipCode: address.consigneeZipCode,
          addressType: ADDRESS_TYPE.Arrival,
          isDefault: false,
        })

        break
      }

      case ADDRESS_TYPE.Reverse: {
        await addressAdd({
          name: address.consigneeName,
          address: address.consigneeAddress,
          addressTwo: address.consigneeAddressTwo,
          email: address.consigneeEmail,
          mobile: address.consigneeMobile,
          province: address.consigneeCountryCode === 'SG' ? 'Singapore' : address.consigneeProvince,
          city: address.consigneeCountryCode === 'SG' ? 'Singapore' : address.consigneeCity,
          district: address.consigneeDistrict,
          town: address.consigneeTown,
          countryCode: address.consigneeCountryCode,
          zipCode: address.consigneeZipCode,
          addressType: ADDRESS_TYPE.Reverse,
          isDefault: false,
        })

        break
      }
    }
  }, [])

  const [form] = Form.useForm(inputForm)

  const createAddresses = useCallback(
    async (address: IAddress) => {
      const { departure_addressNo, arrival_method, departure_isSave, arrival_isSave, arrival_store_companyCode } = form?.getFieldsValue() || {}

      // 创建收货地址
      if (departure_isSave && !departure_addressNo) {
        await createAddress(address, ADDRESS_TYPE.Departure)
      }

      // 收货地址保存到地址簿, 店配特殊逻辑711支持保存地址
      if (arrival_isSave && (arrival_method !== ARRIVAL_METHOD.STORE || (arrival_method === ARRIVAL_METHOD.STORE && is711CrossBorder(arrival_store_companyCode)))) {
        await createAddress(address, ADDRESS_TYPE.Arrival)
      }
    },
    [createAddress, form]
  )

  return {
    createAddress,
    createAddresses,
  }
}
