import type { FormInstance } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * 表单校验信息适配切换语言的场景
 * @param form
 */
export function useValidateError(form: FormInstance) {
  const { t } = useTranslation()
  useEffect(() => {
    const errorNames = form
      ?.getFieldsError()
      .filter((field) => field.errors.length > 0)
      .map((field) => field.name)

    if (errorNames?.length) {
      form.validateFields(errorNames)
    }
  }, [form, t])
}
