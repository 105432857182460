import { useMount, usePrevious, useRequest } from 'ahooks'
import { Button, Layout, Menu, Tooltip } from 'antd'
import c from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { MatchedRoute } from 'react-router-config'
import { Link, useHistory } from 'react-router-dom'
import { Arrival, ChevronRight, IconCheckCircle, Order, Warehouse } from '@yy/one-icon'
import MenuActive from '@/bizComponent/SvgIcons/MenuActive'
import AlertHeader from '@/containers/Subscribe/components/AlertHeader'
import { useStorePlanStatus } from '@/containers/Subscribe/components/AlertHeader/hook'
import { FreeActivityIcon, GiveawayModal } from '@/bizComponent/Giveaway'
import { useGuide } from '@/hooks'
import { useSubscriptionStatus } from '@/hooks/subscribe/useSubscriptionStatus'
import useLocalConfig from '@/hooks/useLocalConfig'
import { getNotifyList } from '@/services/apis'
import _logo from '@/static/images/logo.svg'
import { useSelector } from '@/store'
import { addressVerifyCreator } from '@/store/addressSlice'
import { imgURL } from '@/utils/countryVersion'
import { insertIntercom, shutdownIntercom } from '@/utils/intercom'
import type { MenuDataItem } from '@/utils/route'
import { isLinkRoute, travelRoutes } from '@/utils/route'
import { BreadcrumbContext } from '../../context'
import CompleteMissionModal from '../CompleteMissionModal'
import ErrorBoundary from '../ErrorBoundary'
import styles from './index.module.less'

const { Header, Content, Sider } = Layout
const { SubMenu, Item } = Menu
const logo = imgURL('./images/logo.svg') || _logo

const Feedback = React.lazy(() => import('@/components/Feedback'))
const RightContent = React.lazy(() => import('./RightContent'))

interface PageLayoutProps {
  children?: React.ReactNode
  routers?: MenuDataItem[]
  window?: () => Window
  matchedRoutes: MatchedRoute<string>[]
  hasSider?: boolean
  noModal?: boolean
}

const formatPath = (path: string | string[]) => {
  return typeof path === 'string' ? path.replace(/\//g, '') : path.join('').replace(/\//g, '')
}

const PageLayout: React.FC<PageLayoutProps> = (props) => {
  const { routers, children: mainContain, matchedRoutes, hasSider = true, noModal } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { getAddressVisible } = useLocalConfig()
  const [openKeys, setOpenKeys] = useState([])
  const { subscriptionStatusService, isTrial, isExpired, isFreeAndUnactive } = useSubscriptionStatus()
  const { getStorePlanStatus } = useStorePlanStatus()

  useInterCom()

  const [tagSettings, setTagSettings] = useState<Record<string, string>>()
  const { run: runQueryList } = useRequest(
    () => {
      const params = {
        notifyType: 'promote',
        pageNum: 1,
        pageSize: 999,
      }

      return getNotifyList(params)
    },
    {
      manual: true,
      onSuccess(res) {
        const { data } = res.data
        const tagSettings = data.reduce((result, item) => {
          const { contentType, relationModules } = item
          return relationModules.reduce((result, name) => {
            result[name] = contentType
            return result
          }, result)
        }, {} as Record<string, string>)

        setTagSettings(tagSettings)
      },
    }
  )

  const finalRouters = useMemo(() => {
    if (typeof tagSettings === 'object' && tagSettings !== null) {
      return travelRoutes(
        routers,
        (route) => {
          const { alias } = route
          const tag = tagSettings[alias]
          switch (tag) {
            case 'promo':
              route.tag = 'PROMO'
              break
            case 'new':
              route.tag = 'NEW'
              break
          }

          return route
        },
        'children'
      )
    }

    return routers
  }, [routers, tagSettings])

  const renderMenuItem = useCallback(
    (item: MenuDataItem, hasParent = false) => {
      const { tag, path, menuTitle, icon: Icon, component } = item || {}
      const active = window.location.pathname.match(path as string)
      const uniqueId = formatPath(item.path)

      return (
        <Item className={c(styles.singleItem, { [styles.hasParent]: hasParent, [styles.active]: !!active }, uniqueId)} key={path as string}>
          <MenuActive className={active ? styles.activeIcon : styles.hide} />
          <span className={c({ [styles.subItemText]: !!Icon, [styles.text]: !Icon })}>
            {Icon ? <Icon className={c(styles.icon, 'icon')} /> : null}
            {isLinkRoute(path) ? (
              <Link to={path}>{t(menuTitle)}</Link>
            ) : (
              <span onClick={component as any} className={styles.menuTitle}>
                {t(menuTitle)}
              </span>
            )}
            {tag ? <span className={styles.menuTag}>{tag}</span> : null}
          </span>
        </Item>
      )
    },
    [t]
  )

  const subMenuItemRender = useCallback(
    (item: MenuDataItem) => {
      const { tag, icon: SubIcon, menuTitle: subMenuTitle, path: subPath, children: subChildren } = item || {}
      const subActive = window.location.pathname.split('/')[1] === (subPath as string).split('/')[1]
      const uniqueId = formatPath(item.path)

      if (subChildren && subChildren.length > 0) {
        return (
          <SubMenu
            className={c(styles.subItem, uniqueId, { [styles.subActive]: !!subActive })}
            key={subPath as string}
            icon={<SubIcon className={c(styles.icon, 'icon')} />}
            title={
              <>
                {t(subMenuTitle)}
                {tag ? <span className={styles.menuTag}>{tag}</span> : null}
              </>
            }
          >
            {subChildren?.map((child) => renderMenuItem(child, true))}
          </SubMenu>
        )
      }
      return renderMenuItem(item)
    },
    [renderMenuItem, t]
  )

  const handleMenuChange = useCallback(
    (keys) => {
      const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
      // 打开第一个子菜单
      const target = finalRouters.find((it) => it.path === latestOpenKey)
      const jumpUrl = target?.children?.[0]?.path || target?.path
      jumpUrl && history.push(jumpUrl as string)
    },
    [history, openKeys, finalRouters]
  )

  const handleMenuOpen = useCallback(() => {
    window.requestAnimationFrame(() => {
      const defaultOpenKey = window.location.pathname.split('/')
      setOpenKeys([`/${defaultOpenKey[1]}`])
    })
  }, [])

  useMount(() => {
    history.listen(() => {
      // TODO 获取最新订阅状态
      // 采用websocket后可以去掉监听
      subscriptionStatusService()
      handleMenuOpen()
    })

    // 获取订阅状态
    subscriptionStatusService()

    dispatch(addressVerifyCreator())
    getAddressVisible()

    handleMenuOpen()
  })

  useMount(() => {
    runQueryList()
  })

  // 展示AlertHeader时，头部组件没有box-shadow
  const isAlertHeaderShow = useMemo(() => {
    const isShow = (isTrial && !getStorePlanStatus('trial')) || (isExpired && !getStorePlanStatus('expired')) || (isFreeAndUnactive && !getStorePlanStatus('freeAndUnactive'))
    return isShow
  }, [getStorePlanStatus, isExpired, isFreeAndUnactive, isTrial])

  // 新手指引相关
  const [click, setClick] = useState('')
  const [hover, setHover] = useState('')
  const [guideVisible, setGuideVisible] = useState(false)

  const { handleToAddress, handleToCreateOrder, handleToTrack } = useGuide(() => {
    setGuideVisible(false)
  })

  const guideInfo = useSelector((state) => state.common.guideInfo)
  const prevGuideInfo = usePrevious(guideInfo)

  useEffect(() => {
    // 任务进度有值并且跟当前存在浏览器的不想等时 说明刚完成一个新的任务 此时弹窗
    if (typeof guideInfo?.process === 'number' && typeof prevGuideInfo?.process === 'number') {
      if (guideInfo?.process !== prevGuideInfo?.process && guideInfo?.process !== guideInfo?.taskDetailResBos?.length && guideInfo?.process !== 0) {
        setGuideVisible(true)
      }
    }
  }, [guideInfo?.process, prevGuideInfo?.process, guideInfo?.taskDetailResBos?.length])
  const guideConfig = useMemo(() => {
    // 这里根据store里的内容，去判断展示什么icon
    const finishList = {
      address: guideInfo?.taskDetailResBos?.find((i) => i.guideTaskType === 'ADDRESS_GUIDE_TASK')?.completeStatus,
      order: guideInfo?.taskDetailResBos?.find((i) => i.guideTaskType === 'SALES_ORDER_GUIDE_TASK')?.completeStatus,
      track: guideInfo?.taskDetailResBos?.find((i) => i.guideTaskType === 'TRACK_SETTING_GUIDE_TASK')?.completeStatus,
    }
    return [
      {
        key: 'address',
        Icon: finishList['address'] ? <IconCheckCircle origin className={styles.tabIcon} /> : <Warehouse className={styles.tabIcon} />,
        title: t('dashbord.guide.addAddress'),
        content: t('dashbord.guide.addAddressTips'),
        handleClick: handleToAddress,
      },
      {
        key: 'order',
        Icon: finishList['order'] ? <IconCheckCircle origin className={styles.tabIcon} /> : <Order className={styles.tabIcon} />,
        title: t('dashbord.guide.createAnOrder'),
        content: t('dashbord.guide.firstOrder'),
        handleClick: handleToCreateOrder,
      },
      {
        key: 'track',
        Icon: finishList['track'] ? <IconCheckCircle origin className={styles.tabIcon} /> : <Arrival className={styles.tabIcon} />,
        title: t('dashbord.guide.trackPage'),
        content: t('dashbord.guide.email'),
        handleClick: handleToTrack,
      },
    ]
  }, [t, handleToAddress, handleToCreateOrder, handleToTrack, guideInfo?.taskDetailResBos])

  // 新手指引的具体内容
  const GuidePop = () => {
    return (
      <div className={styles.guideBody}>
        {guideConfig.map((item) => {
          return (
            <div
              className={styles.item}
              onClick={item.handleClick}
              style={hover === item.key ? (click === item.key ? { backgroundColor: '#EDF1F6' } : { backgroundColor: '#F6F8FC' }) : {}}
              onMouseOver={() => {
                setHover(item.key)
              }}
              onMouseLeave={() => {
                setHover('')
              }}
              onMouseDown={() => {
                setClick(item.key)
              }}
              onMouseUp={() => {
                setClick('')
              }}
            >
              <div className={styles.left}>{item.Icon}</div>
              <div className={styles.right}>
                <h6>{item.title}</h6>
                <p className={styles.contentText}>{item.content}</p>
              </div>
              {hover === item.key && (
                <div className={styles.icon}>
                  <ChevronRight style={{ fontSize: '18px', color: '#ABB9CC' }}></ChevronRight>
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <Layout hasSider={hasSider} className={styles.prolayout}>
      {hasSider && (
        <Sider className={styles.sider} width={240}>
          <Menu theme="light" onOpenChange={handleMenuChange} mode={'inline'} openKeys={openKeys} defaultOpenKeys={['/ship']}>
            {finalRouters.map((item) => subMenuItemRender(item))}
          </Menu>
        </Sider>
      )}
      <Layout className={styles.layout}>
        <Header className={c(styles.header, isAlertHeaderShow ? styles.alertShow : '')}>
          <div className={styles.headerContent}>
            <div className={styles.headerLeft}>
              <img alt="header" src={logo} className={styles.branding} />
              <FreeActivityIcon />
              {location.pathname !== '/dashboard' && guideInfo?.remindStatus && (
                <div className={styles.guideButton}>
                  <Tooltip
                    visible={guideVisible}
                    onVisibleChange={(vis) => {
                      setGuideVisible(vis)
                    }}
                    placement="topLeft"
                    overlayClassName={styles.tooltip}
                    title={GuidePop}
                    trigger="click"
                  >
                    <Button style={{ border: '1px solid #E6EDF5' }} type="text">
                      📌 {t('dashbord.guide.guideTitle')}：{guideInfo?.process}/{guideInfo?.taskDetailResBos?.length || 0}
                    </Button>
                  </Tooltip>
                </div>
              )}
            </div>
            <RightContent />
          </div>
        </Header>
        <Content
          className={styles.container}
          style={{
            marginLeft: hasSider ? '240px' : 0,
          }}
        >
          <ErrorBoundary>
            <BreadcrumbContext.Provider value={matchedRoutes}>
              <div id="scroll-content-target" className={styles.contentHeight}>
                <AlertHeader></AlertHeader>
                {mainContain}
              </div>
            </BreadcrumbContext.Provider>
          </ErrorBoundary>
        </Content>
      </Layout>
      {!noModal && (
        <>
          <CompleteMissionModal guideInfo={guideInfo}></CompleteMissionModal>
          {/* 活动优惠领取弹窗 */}
          <GiveawayModal />
        </>
      )}
      <Feedback />
    </Layout>
  )
}

function useInterCom() {
  useEffect(() => {
    insertIntercom()

    return () => {
      shutdownIntercom()
    }
  }, [])
}

PageLayout.displayName = 'PageLayout'
export default PageLayout
