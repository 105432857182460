import API from '@/services/http'

export interface FreightFeeItemDTO {
  /**
   * 费用类目 BASE_PRICE( "base_price","基础运费" ),
   * PROMOTION( "promotion","优惠" ),TAXES( "taxes","税费" ),
   * REGISTRATION( "registration","挂号费" ),
   * PREMIUM( "premium","保险费" ),
   * COD_HANDLE_FEE( "cod_handle_fee","COD手续费" ),
   * IMPORT_TAXES( "import_taxes","进口税" )
   */
  type?: string
  /**费用金额，标准币种 */
  amount?: number
  /**币种 */
  currencyCode?: string
  /**版本。可能为null
优惠会对应freightPromotionSeq
基础运费对应 基础价格seq.子版本号 */
  version?: string
  /**版本名。可能为null */
  versionName?: string
  /**费用金额是否为负数(减法) */
  amountNegative?: boolean
  /**保费明细扩展信息。其余明细类型该字段为null
保费模版设置价值区间最小值（开区间） （标准货币单位 * 100） */
  minInsuredAmount?: number
  /**保费明细扩展信息。其余明细类型该字段为null.
保费模版设置价值区间最大值（闭区间） */
  maxInsuredAmount?: number
  /**是否参与运费计算 */
  joinFreightCalc?: boolean
  /**是否参与优惠计算 */
  joinPromotionCalc?: boolean
}

/**命中的计价配置信息 */
export interface PriceSettingInfoDTO {
  /**是否所有关联服务 */
  allAssociatedService?: boolean
  /**基础价格关联的揽收方式 allAssociatedService=true时为空 PICK_UP( (byte) 1 ),DROP_OFF( (byte) 2 ) */
  pickupType?: number[]
  /**基础价格关联的配送方式 allAssociatedService=true时为空 HOME_STREET( (byte) 1,"宅配" ),STORE_STREET( (byte) 4,"店配" ) */
  deliveryType?: number[]
}

export interface OneshipTryCalculateResultVo {
  /**服务产品编码, 标准的 */
  expressTypeCode?: string
  /**服务产品名称, 标准的 */
  expressTypeName?: string
  /**渠道服务产品编码 */
  channelExpressType?: string
  /**渠道服务产品名称 */
  channelExpressTypeName?: string
  /**承运商编码 */
  companyCode?: string
  /**承运商名称 */
  companyName?: string
  /** cod */
  supportedCheckoutTypes?: number[]
  /**增值服务：
1=代收货款
2=保价服务
3=包装服务
4=送货上楼
5=保鲜服务
6=返单服务 */
  valueAddedServices?: number[]
  /**承诺送达时间/物流时效 */
  promisedTime?: string
  /**服务产品支持的揽收方式：1:PICK_UP;2:DROP_OFF */
  supportedPickupTypes?: number[]
  /**运费，标准货币单位*100 */
  freight?: number
  /**运费， 货币 */
  currencyCode?: string
  /**
   * 支持的配送类型
   */
  supportedAddressType: string[]
  /**
   * 包裹重量
   */
  weight: number
  discountFreight?: number
  originalFreight?: number
  insuranceSupport: number
  /** 高度/cm */
  height: number
  /** 宽度/cm */
  width: number
  /** 长度/cm */
  length: number
  /** 长宽高单位/cm */
  lengthUnit?: number
  /** 服务产品支持的签收服务类型,目前只有部分签收 SIGN_PART:部分签收 */
  supportSignServiceTypes?: string[]
  /**计费失败原因 */
  failReason?: string
  /**渠道自定义信息，透传给上游, json格式
arriveDaysRange:预估到达时间 eg: 1-4 
"{\"arriveDaysRange\":\"1-4\", \"sendle\":{\"planName\":\"路线\"}}"
*/
  channelCustomParam?: string
  /** 渠道特异性信息序列化而来 */
  channelCustomParamParse?: {
    arriveDaysRange?: string
    sendle?: {
      planName?: string
    }
  }
  /**命中的计价配置信息 */
  priceSettingInfo: PriceSettingInfoDTO
  /**费用明细 */
  feeItems?: FreightFeeItemDTO[]
}

export interface AddressNameVo {
  /**国家编码 */
  countryCode?: string
  /**一级行政区名称，比如省份/州名称 */
  provinceName?: string
  /**一级行政区编码 */
  provinceCode?: string
  /**二级行政区名称，比如城市名称 */
  cityName?: string
  /**二级行政区编码 */
  cityCode?: string
  /**三级行政区名称，比如区/县名称 */
  districtName?: string
  /**三级行政区编码 */
  districtCode?: string

  /**四级行政区名称，街道 */
  townName?: string
  /**四级行政区编码 */
  townCode?: string
  /**邮编 */
  zipCode?: string
}

export interface CalculateRes {
  /**寄件地址 */
  senderAddressName?: AddressNameVo
  /**收件地址 */
  consigneeAddressName?: AddressNameVo
  /**试算结果 */
  calculateResultVos?: OneshipTryCalculateResultVo[]
  /**是否有下一页 */
  hasNext?: boolean
  /**命中的计价配置信息 */
  priceSettingInfo: PriceSettingInfoDTO
  /**费用明细 */
  feeItems?: FreightFeeItemDTO[]
}

export interface CalFreightParamsTypes {
  expressTypeCode?: string
  senderAddressName: {
    countryCode: string
    provinceName: string
    cityName: string
    districtName: string
    townName: string
    zipCode: string
  }
  consigneeAddressName: {
    countryCode: string
    provinceName: string
    cityName: string
    districtName: string
    townName: string
    zipCode: string
  }
  weight: string
  weightUnit: 'kg' | 'g' | 'lb' | 'oz'
  currencyCode: string
  lastExpressTypeCode?: string
  pageSize?: number
  length?: number
  width?: number
  height?: number
  lengthUnit?: 'cm'
}

/**
 * 运费试算
 * @param params
 * @returns
 */
export const feeTryCalculate = async (params: CalFreightParamsTypes) => {
  const res = await API.admin.post<CalculateRes>('/api/fee/try/calculate', params)
  res?.data?.calculateResultVos?.forEach((item) => {
    try {
      item.channelCustomParamParse = JSON.parse(item.channelCustomParam)
    } catch (error) {}
  })
  return res
}
