import { createSlice } from '@reduxjs/toolkit'
import type { CountryConfigType, VerifyFieldType } from '@/store/addressSlice/types'
import type * as Types from './types'
import type { AppDispatch, AppGetState } from '..'
import { batchCreatePage, batchUpdateOrder } from '@/services/apis'
import { createSetReducers } from '../storeUtils'
import type { BatchCreateOrderVo } from '@/services/apis/sales/type'

/**
 * redux初始化默认值
 */
const initialState: Types.BatchCreateOrderState = {
  stepUploadIsLock: false,
  serviceUploadResult: null,
  fileId: '',
  taskId: null,
  pagination: {
    currentPage: 0,
    pageSize: 25,
    total: 0,
  },
}

/**
 * 切片
 * name：批量创建订单
 * initialState：初始化值
 * reducers：当前模块reducer的集合，触发action后，就触发reducers
 */
const batchCreateOrderSlice = createSlice({
  name: 'batchCreateOrder',
  initialState,
  reducers: {
    ...createSetReducers(initialState),
  },
})

/** 判断收件地址是否完整 */
const ADDRESS_CONFIG = {
  countryCode: 'departure_countryCode',
  provinceVisibility: 'departure_province',
  cityVisibility: 'departure_city',
  districtVisibility: 'departure_district',
}
/** 判断收件地址是否完整 */
const ARRIVAL_ADDRESS_CONFIG = {
  countryCode: 'arrival_countryCode',
  provinceVisibility: 'arrival_province',
  cityVisibility: 'arrival_city',
  districtVisibility: 'arrival_district',
}

// const OTHER_ADDRESS_INFO_CONFIG = { zipCode: 'departure_zipCode', address: 'departure_address', mobile: 'departure_mobile', name: 'departure_fullName' }

export const isSenderAddressComplete = (countryConfig: CountryConfigType, verifyconfig: VerifyFieldType, formValues) => {
  const showKeys = Object.keys(countryConfig).filter((key) => {
    const re = /(\S*)Visibility/
    const require = key === 'country' ? true : !!+verifyconfig?.[key.match(re)?.[1]]
    return countryConfig[key] && require // 展示且必填
  })

  const isAreaShow = showKeys.every((key) => Boolean(formValues[ADDRESS_CONFIG[key]]))

  return formValues.departure_mobile && formValues.departure_address && formValues.departure_fullName && isAreaShow
  // 根据必填配置
  // const isOtherAddressFieldFillComplete = Object.keys(OTHER_ADDRESS_INFO_CONFIG)
  //   .filter((key) => !!+verifyconfig?.[key])
  //   .every((item) => !!formValues[OTHER_ADDRESS_INFO_CONFIG[item]])

  // return isAreaShow && isOtherAddressFieldFillComplete
}

export const isArrivalAddressComplete = (countryConfig: CountryConfigType, verifyconfig: VerifyFieldType, formValues) => {
  const showKeys = Object.keys(countryConfig).filter((key) => {
    const re = /(\S*)Visibility/
    const require = key === 'country' ? true : !!+verifyconfig?.[key.match(re)?.[1]]
    return countryConfig[key] && require // 展示且必填
  })
  const isAreaShow = showKeys.every((key) => Boolean(formValues[ARRIVAL_ADDRESS_CONFIG[key]]))
  if (formValues.arrival_method !== 1) {
    return (
      formValues.arrival_mobile &&
      formValues.arrival_countryCode &&
      formValues.arrival_fullName &&
      (formValues.arrival_store_711station || formValues.arrival_store_station) &&
      formValues.arrival_store_companyCode
    )
  }
  return formValues.arrival_mobile && formValues.arrival_address && formValues.arrival_fullName && isAreaShow
}

const actions = batchCreateOrderSlice.actions

export const { setStepUploadIsLock, setFileId, setServiceUploadResult } = batchCreateOrderSlice.actions

export const initTask = (taskId: string) => async (dispatch: AppDispatch) => {
  dispatch(actions.setTaskId(taskId))
  dispatch(
    actions.setPagination({
      currentPage: 1,
      pageSize: 25,
      total: 0,
    })
  )
  dispatch(paginationChange())
}

export const paginationChange = (currentPage?: number, pageSize?: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { pagination, taskId } = getState().batchCreateOrder
  const pageNum = currentPage || pagination.currentPage
  const { data } = await batchCreatePage({ taskId, pageSize: pageSize || pagination.pageSize, pageNum })
  dispatch(
    actions.setPagination({
      pageSize: pageSize || pagination.pageSize,
      currentPage: pageNum,
      total: data.total,
    })
  )
  dispatch(setServiceUploadResult(data))
}

export const updateBatchOrder = (orders: BatchCreateOrderVo[]) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { taskId } = getState().batchCreateOrder
  await batchUpdateOrder({ taskId, orders })
  dispatch(paginationChange())
}

export default batchCreateOrderSlice.reducer
