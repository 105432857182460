import { useMount } from 'ahooks'
import type { ChangeEvent } from 'react'
import React, { useCallback, useMemo } from 'react'
import type { ControllerProps, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useDynamicFormContext } from '../../hooks/useDynamicFormContext'

function getValue(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): any
function getValue(event: any): any
function getValue(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
  if (typeof event === 'object' && event !== null && 'target' in event) {
    return event.target.value
  }

  return event
}

interface DynamicFormControllerExtraProps {
  /** 持久化数据 */
  isPersistence?: boolean
}

/**
 * 动态表单 Controller
 * @description
 * 建议配合 `useDynamicFormContext` 使用;
 *
 * 因为 react-hook-form 的 Controller 是 register 与 elementRef 一一对应,
 * 而通过节点是否存在来确定 register 与 unregister;
 * 因此, 在动态表单 setValue 时可能存在该节点不存在, 因为 render 是最后触发的;
 * 因此这里提出一种挟持方案:
 * 通过挟持 render 方法中的 value, onChange 来跳过 Controller 对表单元素的控制;
 * 这样在其他地方 setValue 之前就可以通过 register 来注册该表单项;
 *
 * 代码片段:
 * ```tsx
 * useEffect(() => {
 *  ;(async () => {
 *    await fetch('something')
 *
 *    // title 不存在表单内, 直接进行注册再赋值
 *    register('title')
 *    setValue('title', 'abc')
 *  })();
 * })
 *
 * // 必须获取到数据才显示; 这里会比 setValue 慢
 * return {show ? <DynamicFormController ... /> : null}
 * ```
 */
export const DynamicFormController = function <
  A extends React.ReactElement<any, string | React.JSXElementConstructor<any>> | 'input' | 'select' | 'textarea' | React.ComponentType<any>,
  T extends FieldValues = FieldValues
>(props: ControllerProps<A, T> & DynamicFormControllerExtraProps): JSX.Element {
  const { isPersistence = true, defaultValue: inputDefalutValue, name, render, ...restProps } = props
  const { control, setValue, getValues, watch, getPersistenceValue } = useDynamicFormContext()

  /**
   * 因为原生 useForm 中并不存在该函数, 因此这里需要进行一下判断
   */
  const persistenceValue = typeof getPersistenceValue === 'function' ? getPersistenceValue(name) : undefined
  const currValue = watch(name)

  /** 默认值 */
  const defaultValue = useMemo(() => {
    if (typeof inputDefalutValue !== 'undefined') {
      return inputDefalutValue
    }

    if (isPersistence === true) {
      if (typeof persistenceValue !== 'undefined') {
        return persistenceValue
      }
    }

    return ''
  }, [isPersistence, inputDefalutValue, persistenceValue])

  /** 当前值 */
  const value = useMemo(() => {
    if (typeof currValue === 'undefined' || currValue === null || (typeof currValue === 'number' && isNaN(currValue))) {
      return defaultValue
    }

    return currValue
  }, [currValue, defaultValue])

  /** 重写 render */
  const hijackRender = useCallback<typeof render>(
    (field, ...rest) => {
      const { onChange: onOriginChange } = field
      const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, ...restArgs: any[]) => {
        const nextValue = getValue(event)
        setValue(name, nextValue, { shouldDirty: true, shouldValidate: true })
        onOriginChange(event, ...restArgs)
      }

      return render({ ...field, value, onChange }, ...rest)
    },
    [name, value, render, setValue]
  )

  useMount(() => {
    const isRegistered = name in getValues()

    /**
     * 如果已经注册了, 则 defaultValue 不会生效需要手动设置;
     * 如果已经注册了, 代表值已经被设置过了,
     * 而 watch, getValues 需要等到下次渲染才能获取,
     * 因此这里无法获取上下文设置过的新值,
     * 所以注册过的值不进行设值处理;
     */
    !isRegistered && setValue(name, value, { shouldDirty: false })
  })

  // 这里的 defaultValue 主要用于 reset 的情况, 这里的 defaultValue 无法在 register 后生效
  return <Controller control={control} {...restProps} name={name} value={value} defaultValue={defaultValue} render={hijackRender} />
}

DynamicFormController.displayName = 'DynamicFormController'
