import type { SelectProps } from 'antd'
import React, { useCallback } from 'react'

export type ExpectedSelectProps<VT = any> = SelectProps<VT> & {
  /** 预计 */
  expect: (value: VT) => Promise<boolean>
}

/**
 * 期望选择器
 * @description
 * 主要用于确定选择是否有效, 可以用来前置 confirm 功能;
 * 挟持 onChange 来控制选择是否有效
 */
export const ExpectedSelect: React.FC<ExpectedSelectProps> = (props) => {
  const { children: Component, expect, onChange: antOnChange, ...restProps } = props

  const onChange = useCallback(
    async (value, option) => {
      const shouldChange = typeof expect === 'function' ? await expect(value) : true

      if (shouldChange) {
        antOnChange(value, option)
      }
    },
    [expect, antOnChange]
  )

  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, {
      ...restProps,
      onChange,
    } as any)
  }

  if (typeof Component === 'function') {
    return <Component {...restProps} onChange={onChange} />
  }
}

ExpectedSelect.displayName = 'ExpectedSelect'
