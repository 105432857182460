import type * as Types from '@/containers/Auth/Bind/types'
import API from '@/services/http'
import { bindUrl } from '@/services/url'
import type { AddressInfoType } from '@/store/addressSlice/types'
import type {
  DeliveryOptionUpdateRequest,
  DeliverySettingPageRes,
  DeliverySettingSaveRequest,
  OneshipBaseRequest,
  ProviderAndTypesRes,
  StoreDeliverySettingPageRequest,
} from './type'

export interface ICheckBindParams {
  appId: string
  shop?: string
  storeWebSite?: string
  storeId?: string
  extra?: {
    ticket?: string
    apikey?: string
  }
}
/**
 * 检查店铺绑定情况
 * @param params
 * @returns
 */
export const preCheckBindStore = (params: ICheckBindParams) => API.toast.post<Types.ResponseQueryBindType>(bindUrl.checkBind, params)

export interface IWooCommerceConfig {
  appName: string
  userId: string
  userHash: string
  scope: string
  returnUrl: string
  authCallBackUrl: string
}

/**
 * 查询 wooCommerceConfig
 * @returns
 */
export const getWooCommerceConfig = () => API.admin.post<IWooCommerceConfig>('/api/woocommerce/get_config')

export interface ExpressTypeSettingVo {
  /**服务偏好配置ID */
  id?: number
  /**店铺Seq（约束：userStoreSeq must less than 32!；userStoreSeq must not be blank） */
  userStoreSeq: string
  /**服务产品编码（约束：expressTypeCode must less than 64!；expressTypeCode must not be blank） */
  expressTypeCode: string
  /**配送方式
1：宅配；4：店配（约束：deliveryType must be greater than or equal to 1；deliveryType must be less than or equal to 4；deliveryType must not be null） */
  deliveryType: 1 | 2 | 3 | 4
  /**订单COD设置
example:["COD","NON_COD"]（约束：paymentTypes must not be empty） */
  paymentTypes: string[]
  /**收货地区代码
example:["MY","HK"]（约束：consigneeCountryCodes must not be empty） */
  consigneeCountryCodes: string[]
  /**服务商编码 */
  companyCode?: string
  /**服务商名称 */
  companyName?: string
  /**服务产品名称 */
  expressTypeName?: string
  /**服务产品配置扩展信息
比如pandago服务产品的默认门店信息 */
  settingExtraInfo?: ExpTypeSettingExtraInfo
}

interface AppCustomType {
  // 店铺更新同步设置
  syncDeliveryEnabled: boolean
  confirmed: {
    serialVersionUID: number
    orderStatus: string
    configSwitch: boolean
    emailSwitch: boolean
  }
  deliveryStatusConfig: {
    serialVersionUID: number
    deliveryStatus: string[]
    emailSwitch: boolean
  }
}
export interface StoreDetailRes {
  defaultAddressNo: string
  address: AddressInfoType
  storeAlias: string
  authStatus: string
  storeId: string
  pullStatus: number
  logoUrl: string
  paymentType: 0 | 1 | 2 | 3 | 4
  extMap: Record<string, any>
  storeWebsite: string
  appId: string
  userStoreSeq: string
  storeName: string
  defaultLanguageCode: string
  expressTypeSettings: ExpressTypeSettingVo[]
  email: string
  status: string
  /** 拉单状态列表 */
  pullOrderStatus: string[]
  /** 归档状态列表 */
  archiveOrderStatus: string[]
  carrierServiceStatus: number
  /** 订单同步旧字段 */
  callBackConfig?: Record<string, any>
  /** 订单同步新字段 */
  appCustom?: Record<string, AppCustomType>
}
export interface StoreDetailParams {
  getExpressTypeSettings: boolean
  getAddressDetail: boolean
  userStoreSeq: string
}

/** 获取全部店铺设置 */
export const getStoreDetail = (data: StoreDetailParams) => API.toast.post<StoreDetailRes>('/api/store/get', data)

export type SettingsParams = Pick<StoreDetailRes, 'userStoreSeq' | 'storeAlias' | 'defaultAddressNo' | 'paymentType' | 'expressTypeSettings'>

interface ExpTypeSettingExtraInfo {
  /**子账号编码 */
  subAccountSeq?: string
  /**pandago地址编码 */
  clientAddressNo?: string
  /**pandago地址是否有效*/
  valid?: boolean
}

interface CallBackConfigDetailVo {
  /**状态 */
  orderStatus?: string
  /**开关配置，true为开，false为关 */
  configSwitch?: boolean
  /**邮件通知配置，true为开，false为关（configSwitch=true时生效） */
  emailSwitch?: boolean
}
interface CallBackConfigVo {
  /**确认状态同步配置 */
  confirmed?: CallBackConfigDetailVo
  /**已完成状态同步配置 */
  completed?: CallBackConfigDetailVo
}

interface StoreSettingSaveRequest {
  /**应用ID */
  appId?: string
  /**店铺Seq（约束：userStoreSeq must less than 32!；userStoreSeq must not be blank） */
  userStoreSeq: string
  /**店铺别名（约束：storeAlias must less than 255!） */
  storeAlias?: string
  /**默认发货地址编码 */
  defaultAddressNo?: string
  /**0：默认配置；1：COD；2：NON_COD；3：WOOCOMMERCE；4：SHOPLINECROSSBORDER（约束：paymentType must be greater than or equal to 0；paymentType must be less than or equal to 4） */
  paymentType?: number
  /**是否对存量数据进行填数 */
  updateOrders?: boolean
  /**服务偏好设置 */
  expressTypeSettings?: ExpressTypeSettingVo[]
  /**拉单状态列表 */
  pullOrderStatus?: string[]
  /**归档状态列表 */
  archiveOrderStatus?: string[]
  /**店铺订单状态同步配置 */
  callBackConfig?: CallBackConfigVo
  appCustom?: Record<string, any>
}
/** 保存店铺设置 */
export const saveStoreSettings = (data: StoreSettingSaveRequest) =>
  API.toast.post<{
    bindState: string
    isSuccess: boolean
  }>('/api/store/settings/save', data)

interface OneshipCarrierServiceActiveRequest {
  /**应用ID */
  appId?: string
  /**时间戳 */
  timestamp?: string
  /**版本号 */
  version?: string
  /**扩展信息 */
  extInfo?: string
  /**操作时间 */
  createTime?: number
  /**操作人 */
  createBy?: string
  /**语言，按中台规范,例如
zh-hans-cn	中文简体(中国大陆)
zh-hant-tw	中文繁体(台湾)
zh-hant-hk  中文繁体(香港)
https://aurogon.yuque.com/staff-ec/okyuhq/rcrmma#grzvL */
  language?: string
  /**操作平台，请求设备都是取这个值，如果后续有必要的话，可以把请求设备的字段拆出来<br/>
操作平台-operatePlatform，请求设备-requestDevice
操作平台: ONE01, ONE01-H5
前端传Header["device"] */
  operatePlatform?: string
  /**店铺唯一编码 */
  userStoreSeq: string
  /**重量/g（约束：大于等于1） */
  weight: number
  /**Fallback Settings：是否配置尺寸：长、宽、高 */
  supportDimensions: boolean
  /**包裹长，cm（约束：大于等于1） */
  length?: number
  /**包裹宽，cm（约束：大于等于1） */
  width?: number
  /**包裹高，cm（约束：大于等于1） */
  height?: number
  /** 是否需要手机号码（回传给ec2.0） */
  phoneRequired?: boolean
}
interface CommonCarrierServiceReq {
  userStoreSeq: string
}

interface CarrierServiceGetRes {
  /**是否成功 */
  isSuccess?: boolean
  /**扩展属性 */
  extMap?: any
  /**店铺SEQ */
  userStoreSeq?: string
  /**是否启用 */
  activeStatus?: boolean
  /**CS ID */
  carrierServiceId?: string
  /**重量/g */
  weight?: number
  /**包裹长，cm */
  length?: number
  /**包裹宽，cm */
  width?: number
  /**包裹高，cm */
  height?: number
  /** 是否需要手机号码（回传给ec2.0） */
  phoneRequired?: boolean
}
/** 保存并启用CS服务 */
export const carrierServiceActive = (data: OneshipCarrierServiceActiveRequest) =>
  API.toast.post<{
    activeStatus: boolean
    isSuccess: boolean
  }>('/api/store/carrier-service/active', data)

/** 保存并启用CS服务 */
export const carrierServiceUpdate = (data: OneshipCarrierServiceActiveRequest) =>
  API.toast.post<{
    activeStatus: boolean
    isSuccess: boolean
  }>('/api/store/carrier-service/update', data)

// 禁用CS服务
export const carrierServiceDeactive = (data: CommonCarrierServiceReq) =>
  API.toast.post<{
    isSuccess: boolean
  }>('/api/store/carrier-service/deactivate', data)

// 加载CS服务
export const carrierServiceGet = (params: CommonCarrierServiceReq) => API.admin.post<CarrierServiceGetRes>('/api/store/carrier-service/get', params)

interface GetAuthUrlRequest {
  /**平台 shopee适用 */
  platform: 'SHP01'
  /**回调地址，不含参数的 */
  redirectUri: string
}

interface AuthUrlRes {
  /**是否成功 */
  isSuccess?: boolean
  /**扩展属性 */
  extMap?: any
  /**调整URL */
  url?: string
}

/**
 * **接口** [获取授权地址↗](https://api-doc-sl.inshopline.com/#/project/Java/74/feature%2F20231108_3128_ka/interface/5295975b3422fae8a7e9ad0751252405)
 * @请求头 `GET /api/store/auth-url`
 * @更新时间 `2023-11-22 17:39:23`
 */
export function getAuthUrl(params?: GetAuthUrlRequest) {
  return API.toast.post<AuthUrlRes>(`/api/store/auth-url`, params)
}

// https://api-doc-sl.inshopline.com/#/project/Java/74/feature%2F20240815_4172/interface/4ae3f52082698fc98947170fb9e38182
// 获取店铺地址同步设置
export const fetchPageDeliverySetting = (params: StoreDeliverySettingPageRequest) => {
  return API.admin.post<DeliverySettingPageRes>('/api/store/delivery-setting-page', params)
}

/**
 * **接口** [saveDeliverySetting↗](https://api-doc-sl.inshopline.com/#/project/Java/74/feature%2F20240815_4172/interface/93b2fb7fcb52e5fd55981788e6ebcae0)
 * 保存店铺地址同步设置
 * @请求头 `POST /api/store/delivery-setting-save`
 * @更新时间 `2024-08-08 14:07:46`
 */
export const postDeliverySettingSave = (params?: DeliverySettingSaveRequest) => {
  return API.admin.post(`/api/store/delivery-setting-save`, params)
}

/**
 * **接口** [deliveryOptionUpdate↗](https://api-doc-sl.inshopline.com/#/project/Java/74/feature%2F20240815_4172/interface/06797a5b5dc31c0f5fb2ba5e7433d6ae)
 * 更新店铺地址同步设置
 * @请求头 `POST /api/store/delivery-option-update`
 * @更新时间 `2024-08-08 14:07:46`
 */
export const postDeliveryOptionUpdate = (data?: DeliveryOptionUpdateRequest) => {
  return API.admin.post(`/api/store/delivery-option-update`, data)
}

/**
 * **接口** [列举有门店的服务商列表以及对应的类型↗](https://api-doc-sl.inshopline.com/#/project/Java/74/feature%2F20240815_4172/interface/e4a9587bee2fc13ea5c9ab98a3088032)
 * 列举有门店的服务商列表以及对应的类型
 * @请求头 `POST /api/station/provider-and-types`
 * @更新时间 `2024-08-08 14:07:46`
 */
export function postProviderAndTypes(data?: OneshipBaseRequest) {
  return API.admin.post<ProviderAndTypesRes>(`/api/station/provider-and-types`, data)
}
