// 运单状态
// 已取消
const CANCEL = 'cancel'
// 待发货
const UNDELIVERY = 'undelivery'
// 已发货
const DELIVERED = 'delivered'
// 已签收
const RECEIVED = 'received'
// 问题件
const PROBLEM = 'problem'
// 已退件
const RETURNED = 'returned'
// 退货在途
const ONRETURNWAY = 'onreturnway'
// 已揽收
const PICKED = 'picked'
// 在途中
const ONWAY = 'onway'
// 到达派件城市
const INCITY = 'incity'
// 派送中
const ONDELIVERY = 'ondelivery'
// 拒收
const REJECT = 'reject'

export const LOGISTICS_STATUS_KEYS = {
  CANCEL,
  UNDELIVERY,
  DELIVERED,
  RECEIVED,
  PROBLEM,
  RETURNED,
  ONRETURNWAY,
  PICKED,
  ONWAY,
  INCITY,
  ONDELIVERY,
  REJECT,
}
