import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Api from '@/services/http'
import { walletUrl } from '@/services/url'
import type { AppDispatch } from '@/store'
import { setProgress } from '@/store/commonSlice'
import type * as Types from './types'

const initialSearch: Types.SearchFormType = {
  search_text: '',
  search_type: '',
  time_range: null,
}

const initialState: Types.WalletState = {
  search: initialSearch,
  hasNext: false,
  prehasNext: false,
  flowList: [],
  preList: [],
  pagination: {
    pageSize: 20,
    pageNum: 1,
  },
  prepagination: {
    pageSize: 20,
    pageNum: 1,
  },
}

const walletSlice = createSlice({
  name: 'setp',
  initialState: initialState,
  reducers: {
    setWalletSlice(state, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const fetchWalletList = (params: Types.WalletListType & Types.PaginationType) => async (dispatch: AppDispatch) => {
  const { pageNum = 1 } = params
  const response = await Api.admin.post(walletUrl.waterFlow, {
    ...params,
  })
  const { data: list, hasNext } = response.data
  dispatch(
    walletSlice.actions.setWalletSlice({
      flowList: list,
      pagination: {
        pageNum,
        pageSize: 20,
        total: 100,
      },
      hasNext,
    })
  )
  dispatch(setProgress({ progress: false }))
}

export default walletSlice.reducer
