import { Dropdown, Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChevronDown, ChevronUp } from '@yy/one-icon'
import { OneRadio } from '@yy/one-ui'
import DateRange from '../DateRange'
import styles from './index.module.less'

const id = 'simple-popover'

interface ISearchProps {
  value: number
  onChange: (type: number) => void
  options: {
    label: string
    value: number
  }[]
}

const CustomSelect: React.FC<ISearchProps> = (props) => {
  const { options, onChange: handleChange, value } = props
  const { t } = useTranslation()

  const [currentValue, setCurrentValue] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const onVisibleChange = (visible) => {
    visible ? setAnchorEl(visible) : setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  return (
    <Dropdown
      onVisibleChange={onVisibleChange}
      overlay={
        <Menu>
          <OneRadio.Group
            aria-label="gender"
            value={currentValue}
            name="radio-buttons-group"
            onChange={(event) => {
              const value = event.target.value
              setAnchorEl(null)
              setCurrentValue(+value)
              handleChange(+value)
            }}
          >
            {options.map((option) => (
              <Menu.Item key={option.value}>
                <OneRadio key={option.value} value={option.value}>
                  {t(option.label, { num: option.value })}
                </OneRadio>
              </Menu.Item>
            ))}
          </OneRadio.Group>
        </Menu>
      }
    >
      <span aria-describedby={id} className={styles.select} onClick={handleClick}>
        <DateRange range={currentValue} />
        {!anchorEl ? <ChevronDown style={{ fontSize: 16 }}></ChevronDown> : <ChevronUp style={{ fontSize: 16 }}></ChevronUp>}
      </span>
    </Dropdown>
  )
}

export default React.memo(CustomSelect)
