// 类似节流等等高阶工具函数
import type { DebounceSettings } from 'lodash'
import { debounce } from 'lodash'

/**
 * 防抖调用，把期间所有的调用都等待，直至下次调用完成并返回
 * 因为lodash debounce永远返回的都是上次调用的结果，而这里则永远返回最后一次调用的结果，
 * @param func
 * @param wait
 * @param options
 */
export function debounceCall<T extends (...args: any) => Promise<any>>(func: T, wait?: number, options?: DebounceSettings) {
  let resArr: { res: (val: any) => void; ret: (val: any) => void }[] = []
  const _debounceFunc = debounce(
    async (...args) => {
      try {
        const res = await func(...args)
        // 回调
        resArr.forEach((i) => i.res(res))
      } catch (error) {
        // 抛错
        resArr.forEach((i) => i.ret(error))
      }
      resArr = []
    },
    wait,
    options
  )

  return function debounceFunc(...args: Parameters<T>) {
    const promise = new Promise((res, ret) => resArr.push({ res, ret }))
    _debounceFunc(...args)
    return promise
  } as unknown as T
}

type EatErrorFunc<F> = F extends (...args: infer A) => infer R ? (...args: A) => R | undefined : never

/**
 * 吃掉所有报错，用于封装调用方不关心内部错误的函数
 * 如果抛错，返回的是undefined，要注意会不会引起外部错误
 * @param func
 */
export function eatError<F extends (...args: any[]) => any>(func: F) {
  return function (...args) {
    try {
      const res = func(...args)
      // eslint-disable-next-line no-console
      if (res instanceof Promise) return res.catch((reason) => console.error(reason))
      return res
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  } as EatErrorFunc<F>
}
