export default {
  storeList: '/api/store/list',
  bindStore: '/api/store/bind',
  unbindStore: '/api/store/unbind',
  /** 同步订单 */
  SyncOrder: '/api/sales/order/sync',
  /** 更新店铺 */
  updateStore: '/api/store/update',
  /** 更新店铺COD配置 */
  updatePayment: '/api/store/update/payment',
}
