import React, { useEffect, useMemo, useState } from 'react'
import Context from './Context'

interface IDynamicFormItemGroupProps {
  hidden?: boolean
}

export { default as FormItem } from './FormItem'
const DynamicFormItemGroupContainer: React.FC<IDynamicFormItemGroupProps> = ({ children, hidden: inputHidden }) => {
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    setHidden(inputHidden)
  }, [inputHidden])

  const data = useMemo(() => {
    return {
      setHidden,
      hidden,
    }
  }, [hidden])

  return (
    <Context.Provider value={data}>
      <>{children}</>
    </Context.Provider>
  )
}

const DynamicFormItemGroup: React.FC<IDynamicFormItemGroupProps> = ({ children, ...args }) => {
  return <DynamicFormItemGroupContainer {...args}>{children}</DynamicFormItemGroupContainer>
}

export default DynamicFormItemGroup
