import { times } from 'number-precision'
import type { FormInstance } from 'antd'
import type { ParcelFormFields } from './types'

type IformData = Pick<ParcelFormFields, 'parcel_commodities'> & {
  parcel_currency_code: string
}

export const getParcelTotalPriceWithForm = (inputForm?: FormInstance<IformData>) => {
  const { valueOfGoods, valueOfGoodsCurrency } = getParcelTotalPrice(inputForm?.getFieldsValue(true) || {})

  return {
    amount: valueOfGoods,
    parcel_currency_code: valueOfGoodsCurrency,
  }
}

export const getParcelTotalPrice = (values: IformData) => {
  const { parcel_commodities, parcel_currency_code } = values || {}

  const parcelAmount =
    parcel_commodities?.reduce((acc, next) => {
      return (acc += times(next.parcel_goods_price || 0, next.parcel_goods_quantity || 1))
    }, 0) || ''

  return {
    valueOfGoods: typeof parcelAmount === 'number' ? times(parcelAmount, 100) : null,
    valueOfGoodsCurrency: parcel_currency_code as string,
  }
}
