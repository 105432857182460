import { isEmpty, omit } from 'lodash'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { intervalValueTransform } from '@/components/IntervalInput'
import { GET_DEFAULT_PAGE_SIZE, ONESHIP_SHIP_STATUS_KEYS } from '@/constant'
import Api from '@/services/http'
import { oneshipOrderUrl } from '@/services/url'
import type { AppDispatch } from '@/store'
import { setTableLoading } from '@/store/commonSlice'
import { isOrderArchive } from '@/utils'
import type * as Types from './type'

const initialState: Types.OneshipOrderState = {
  searchRecord: null,
  hasNext: false,
  list: [],
  pagination: {
    pageSize: GET_DEFAULT_PAGE_SIZE(),
    pageNum: 1,
    total: 0,
    orderByType: 1,
  },
  orderDetailInfo: null,
  listTabValue: 0,
  rowChecked: [],
  rowCheckedMap: {},
}

const formatRowCheckMap = (list: Types.RowCheckedType[]) => {
  return list.reduce((acc, next) => {
    acc[next.orderNo] = next
    return acc
  }, {} as Record<string, Types.RowCheckedType>)
}

const formatSelectRows = (rows: Types.RowCheckWithPreserveType): Types.RowCheckedType[] => {
  return rows.map((row) => {
    const { orderNo, userStoreSeq, associate, logisticsOrders, ind, sourceType, appId, address, tagIds, originOrderNo, presignedDeliveryProofUrl, tag } =
      row as Types.OneshipOrderItemType
    let canBePrint = false
    let canBeInvoicePrint = false
    const ship = associate
    const { senderCountryCode, consigneeCountryCode, consigneeStationCode } = address || {}
    const { shipStatus, waybillStatus, logisticsStatus, logisticsNo } = logisticsOrders?.[0] ?? {}
    if (associate && logisticsOrders) {
      canBePrint = ['unprint', 'printed'].includes(waybillStatus)
    }

    if (associate && logisticsOrders) {
      canBeInvoicePrint =
        [
          ONESHIP_SHIP_STATUS_KEYS.UNPRINTED,
          ONESHIP_SHIP_STATUS_KEYS.PRINTED,
          ONESHIP_SHIP_STATUS_KEYS.SHIPPED,
          ONESHIP_SHIP_STATUS_KEYS.COMPLETED,
          ONESHIP_SHIP_STATUS_KEYS.RETURNED,
        ].includes(shipStatus as any) && !tag?.includes('ARCHIVED')
    }
    return {
      orderNo,
      userStoreSeq,
      canBePrint,
      logisticsNo,
      stationCode: consigneeStationCode,
      ship,
      shipStatus,
      waybillStatus,
      ind,
      logisticsStatus,
      sourceType,
      appId,
      senderCountryCode,
      consigneeCountryCode,
      stationName: address?.consigneeStationName,
      tagIds,
      row,
      presignedDeliveryProofUrl,
      originOrderNo,
      canBeInvoicePrint,
    }
  })
}

const sortSelectRows = (rows: Types.RowCheckedType[]) => {
  return rows.sort((a, b) => {
    const [aPageNum, aInd] = a.ind?.split('_') || []
    const [bPageNum, bInd] = b.ind?.split('_') || []
    // 排序，按页码和下标比较
    if (aPageNum === bPageNum) {
      return Number(aInd) - Number(bInd)
    }
    return Number(aPageNum) - Number(bPageNum)
  })
}

/**
 * checkbox选项转成boolean筛选条件
 * @param options
 * @returns {boolean}
 */
const checkboxToBoolean = (options: Types.OptionsType[]) => {
  if (!options) return null
  if (options.length === 1) return !!options[0].value
  else return null
}

const oneshipOrderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrderSearchRecord(state, action: PayloadAction<Types.OneshipOrderState['searchRecord']>) {
      return {
        ...state,
        searchRecord: action.payload,
      }
    },
    setPagination(state, action: PayloadAction<Partial<Types.PaginationType>>) {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      }
    },
    setList(state, action: PayloadAction<any>) {
      return {
        ...state,
        list: action.payload,
      }
    },
    setHasNext(state, action: PayloadAction<Types.OneshipOrderState['hasNext']>) {
      return {
        ...state,
        hasNext: action.payload,
      }
    },
    setTabValue(state, action: PayloadAction<{ value: number }>) {
      return {
        ...state,
        listTabValue: action.payload.value,
      }
    },
    reset(state) {
      // 重置时排序方式不需要重置
      const pagination = {
        ...initialState.pagination,
        orderByType: state.pagination.orderByType,
      }
      return {
        ...state,
        ...omit(initialState, ['searchRecord', 'listTabValue']),
        pagination,
      }
    },
    setOrderSlice(state, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
      }
    },
    setRowChecked(state, action: PayloadAction<Types.RowCheckWithPreserveType>) {
      const rowChecked = sortSelectRows(formatSelectRows(action.payload))
      const rowCheckedMap = formatRowCheckMap(rowChecked)
      return {
        ...state,
        rowChecked,
        rowCheckedMap,
      }
    },
    setNewRowChecked(state, action: PayloadAction<Types.RowCheckWithPreserveType>) {
      const rowChecked = sortSelectRows(formatSelectRows(action.payload))
      const rowCheckedMap = formatRowCheckMap(rowChecked)

      return {
        ...state,
        rowChecked,
        rowCheckedMap,
      }
    },
    resetRowChecked(state) {
      return {
        ...state,
        rowChecked: [],
      }
    },
  },
})

/** 订单查找 */
export const transformData = (data: Types.SearchFormType, tabValue: number) => {
  if (isEmpty(data)) return null

  const archive = isOrderArchive(tabValue)
  const {
    search_text,
    search_type,
    time_range_type,
    time_range,
    filter_stores,
    filter_channel,
    filter_delivery_type,
    filter_receive_country,
    filter_provider,
    filter_productions,
    filter_remark,
    ship_status,
    order_status,
    pay_status,
    delivery_status,
    app_ids,
    filter_tag,
    setExpressType,
    setSenderAddress,
    order_good_number,
    order_good_types,
    pickGoodStatuses,
  } = data as Types.SearchFormType
  const searchArr = search_text ? search_text.trim().split(/\s+|,/) : []

  const timeRange = {}
  Object.assign(
    timeRange,
    time_range_type === 'CreateTime'
      ? {
          orderCreateStartTime: time_range?.[0] ? time_range[0] : null, //创建订单时间范围-开始时间
          orderCreateEndTime: time_range?.[1] ? time_range[1] : null, //创建订单时间范围-结束时间
        }
      : {
          requiredDeliveryDateStart: time_range?.[0] ? time_range[0] : null, //预约派送时间范围-开始时间
          requiredDeliveryDateEnd: time_range?.[1] ? time_range[1] : null, //预约派送时间范围-结束时间
        }
  )

  return {
    appIds: app_ids?.length ? app_ids.map((item) => item.value).reduce((acc, cur) => acc.concat(String(cur).split(',')), []) : null, // 来源平台
    queryWord: search_type === 'queryWord' ? search_text : '', // 关键字
    consigneeMobile: search_type === 'consigneeMobile' ? search_text : '', // 收件人手机号
    numberList: search_type === 'numberList' ? searchArr.filter(Boolean) : null, // 多单号混合搜索(orderNo、originOrderNo、expressNo)
    userStoreSeqs: filter_stores?.length ? filter_stores.map((item) => item.value) : null,
    channelType: filter_channel?.length ? filter_channel.map((item) => item.value) : null, //发货渠道
    deliveryTypes: filter_delivery_type?.length ? filter_delivery_type.map((item) => item.value) : null,
    consigneeCountries: filter_receive_country?.length ? filter_receive_country.map((item) => item.value) : null, //收货国家，地区
    companyCodes: filter_provider?.length ? filter_provider.map((item) => item.value) : null, //服务商
    expressTypeCodes: filter_productions?.length ? filter_productions.map((item) => item.value) : null, //服务产品
    existOriginOrderRemark: filter_remark?.map((item) => item.value).includes('0'), // 有无远订单备注
    existOrderRemark: filter_remark?.map((item) => item.value).includes('1'), // 有无oneship订单备注
    shipStatuses: ship_status?.length ? ship_status.map((item) => item.value) : null, //发货状态
    orderStatuses: order_status?.length ? order_status.map((item) => item.value) : null, // 订单状态
    paymentStatuses: pay_status?.length ? pay_status.map((item) => item.value) : null, // 支付状态
    deliveryStatuses: delivery_status?.length ? delivery_status.map((item) => item.value) : null, // 履约状态
    tag: archive ? ['ARCHIVED', 'AUTO_ARCHIVED'] : void 0, //归档的tab
    notTag: !archive ? ['ARCHIVED', 'AUTO_ARCHIVED'] : void 0, //除归档的tab
    tagIds: filter_tag?.length ? filter_tag.map((item) => item.value) : null, // 标签
    setExpressType: checkboxToBoolean(setExpressType), // 是否已设置服务产品
    setSenderAddress: checkboxToBoolean(setSenderAddress), // 是否已设置发货地址
    ...timeRange,
    ...intervalValueTransform('goodsQuantityTotal', order_good_number), // 订单商品数量
    ...intervalValueTransform('goodsTypeTotal', order_good_types), // 订单商品种类
    pickGoodStatuses,
  }
}

/** 获取订单列表 */
export const queryOrderList = (params: Types.SearchFormType & Types.SearchPaginationType) => async (dispatch: AppDispatch, getState) => {
  dispatch(setTableLoading({ loading: true }))
  const { oneshipOrder: orderState } = getState()
  const { pageNum: defaultPageNum, orderByType } = orderState.pagination

  const { pageNum = defaultPageNum, ...searchData } = params
  const trans = transformData(searchData, orderState.listTabValue)
  const newData = {
    pageSize: GET_DEFAULT_PAGE_SIZE(),
    pageNum,
    orderByType,
    updateOrderBy: true,
    ...trans,
  }

  dispatch(setOrderSearchRecord({ ...omit(params, 'pageNum'), orderByType }))

  try {
    const response = await Api.toast.takeLatest<Types.ResponseQueryType>('queryOrderList', 'post', oneshipOrderUrl.query, newData)
    const { data } = response

    const list = Array.isArray(data.data) ? data.data.map((item, ind) => ({ ...item, ind: `${data.pageNum}_${ind}` })) : []

    dispatch(
      oneshipOrderSlice.actions.setOrderSlice({
        list,
        hasNext: data.hasNext,
        pagination: {
          pageNum: data.pageNum,
          pageSize: data.pageSize,
          total: data.total,
          orderByType,
        },
      })
    )
    dispatch(setTableLoading({ loading: false }))
    return data.data
  } catch (error) {
    dispatch(setTableLoading({ loading: false }))
  }
}

/** 查询销售订单 */
export const queryOneShipOrder = async (orderNo: string) => {
  const { bizCode, data, bizMessage, message } = await Api.admin.post('/api/sales/order_query/get', {
    orderNo,
  })

  if (bizCode === 'SUCCESS') {
    return { succ: true, bizMessage, message, data }
  }

  return Promise.reject({ succ: false, bizMessage, message })
}

/** 查询其他平台的销售订单 */
export const queryOneShipOrderBySl = async (params: Types.PlatFormDetailParamsTypes) => {
  const { originOrderId, platform } = params
  const { bizCode, data, bizMessage, message } = await Api.admin.post('/api/sales/order_query/get', {
    originOrderId,
    platform,
  })

  if (bizCode === 'SUCCESS') {
    return { succ: true, bizMessage, message, data }
  }

  return Promise.reject({ succ: false, bizMessage, message })
}

/** 更新订单备注 */
export const updateRemark = async (params: Types.RemarkType) => {
  const { orderNo, orderRemark } = params
  const { bizCode, bizMessage, message } = await Api.admin.post(oneshipOrderUrl.updateRemark, {
    orderNo,
    orderRemark,
  })

  if (bizCode === 'SUCCESS') {
    return { succ: true }
  }

  return Promise.reject({ succ: false, message, bizMessage })
}

/** 查询销售订单-带汇率 */
export const queryRateOneShipOrder = async (param) => {
  const { bizCode, data, bizMessage, message } = await Api.admin.post('/api/sales/order_query/currency_converted_get', param)
  if (bizCode === 'SUCCESS') {
    return { succ: true, bizMessage, message, data }
  }

  return Promise.reject({ succ: false, bizMessage, message })
}

export const { setOrderSearchRecord, setPagination, setTabValue, reset, setHasNext, resetRowChecked, setRowChecked, setNewRowChecked } = oneshipOrderSlice.actions
export default oneshipOrderSlice.reducer
