import { useEffect, useState } from 'react'
import { useSelector } from '@/store'
import socket from '@/services/socket'

/** socket 版本更新 */
export const useVersionUpdate = () => {
  const [needUpdate, setNeedUpdate] = useState(false)
  const myuid = useSelector((state) => state.user.info?.uid)

  useEffect(() => {
    return socket.onVersionUpdate((payload) => {
      const { version, uid } = payload
      const users = typeof uid === 'string' && uid.length > 0 ? uid.split(',') : []

      // 如果用户为0 或者 UID 为空字符串均当做所有用户启用
      if (users.length > 0) {
        if (users.includes(myuid) && version !== process.env.GIT_COMMIT_HASH) {
          setNeedUpdate(true)
        }
      } else if (version !== process.env.GIT_COMMIT_HASH) {
        setNeedUpdate(true)
      }
    })
  }, [myuid])

  return needUpdate
}
