import type * as Types from '@/types'

/**
 * 重量单位
 * @description g-克，kg-千克，lb-磅，oz-盎司
 */
export type WeightUnit = 'kg' | 'g' | 'lb' | 'oz'

/** 重量单位字典 */
export const WEIGHT: Types.UnionDict<WeightUnit> = [
  {
    label: 'kg',
    value: 'kg',
  },
]
