import { useUnmount } from 'ahooks'
import React, { useCallback, useContext, useMemo, useRef } from 'react'

interface ContextProps {
  fetch: (name: string) => () => any
  register: (name: string, createApis: () => any) => void
  unregister: (name: string) => void
}

const Context = React.createContext<ContextProps>(null)
export const ScopeProvider: React.FC = React.memo((props) => {
  const { children } = props

  const scopes = useRef({})
  const fetch = useCallback((name: string) => {
    return scopes.current[name]
  }, [])

  const register = useCallback<ContextProps['register']>((name, createApis) => {
    scopes.current[name] = createApis
  }, [])

  const unregister = useCallback<ContextProps['unregister']>((name) => {
    delete scopes.current[name]
  }, [])

  const context = useMemo(() => ({ fetch, register, unregister }), [fetch, register, unregister])
  return <Context.Provider value={context}>{children}</Context.Provider>
})

ScopeProvider.displayName = 'ScopeProvider'

export function useImperativeScope<A extends Record<string, any>>(name: string, apis: () => A) {
  const { register, unregister } = useContext(Context)
  register(name, apis)

  useUnmount(() => {
    unregister(name)
  })
}

export function useScope<A extends Record<string, any>>(name: string): ProxyHandler<A> {
  const { fetch } = useContext(Context)
  return useMemo(() => {
    return new Proxy(
      {},
      {
        get(_target, prop) {
          const creator = fetch(name)
          if (typeof creator === 'function') {
            const apis = creator()
            return apis?.[prop]
          }
        },
      }
    )
  }, [name, fetch])
}
