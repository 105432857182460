import { GuideCard, HeaderCard, LogisticsCard, NotifyCard, OneClubPlusCard, OrderCard, TeachingCard, WalletCard } from './components'
import CloseGuideModal from './components/CloseGuideModal'
import { OpenIntercomSource, TimeRangeTypeEnum } from './constants'
import styles from './index.module.less'
import type { ILogisticsStats, IOrderStats } from './types'
import { useProgress } from '@/hooks'
import Api from '@/services/http'
import { dashboardUrl } from '@/services/url'
import { useSelector } from '@/store'
import { OneProgress } from '@yy/one-ui'
import { useMount, useRequest } from 'ahooks'
import cls from 'classnames'
import { startOfToday, subDays } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { GiveawayCard } from '@/bizComponent/Giveaway'
import { getUrlParamReturnUndefined } from '@/utils'
import { openIntercom } from '@/utils/intercom'

const defaultValue = 30

const Dashboard: React.FC = () => {
  const { t } = useTranslation()
  const { setProgress, progressState } = useProgress()

  const config = useSelector((state) => state.configSlice)
  const userUid = useSelector((state) => state.user?.info?.uid)
  const guideInfo = useSelector((state) => state?.common.guideInfo)

  /** 数据操作相关 */
  const [logisticsStats, setLogisticsStats] = useState<ILogisticsStats>({} as ILogisticsStats)
  const [orderStats, setOrderStats] = useState<IOrderStats>({} as IOrderStats)
  const { run: runStatsAll } = useRequest(
    ({ orderTime, logisticsTime }) => {
      const params = {
        startOrderCreateTime: orderTime,
        startLogisticsCreateTime: logisticsTime,
      }
      return Api.admin.post(dashboardUrl.getStatsAll, params)
    },
    {
      manual: true,
      throwOnError: true,
      onSuccess(res) {
        const { logisticsOrderStats, saleOrderStats } = res.data
        logisticsOrderStats && setLogisticsStats(logisticsOrderStats)
        saleOrderStats && setOrderStats(saleOrderStats)
      },
    }
  )

  /** 时间选择相关 */
  // const logisticsTime = useRef(0)
  // const orderTime = useRef(0)
  const TimeChange = useCallback(
    (value: number, type: number) => {
      const timestamp = subDays(startOfToday(), value - 1).getTime()
      switch (type) {
        case TimeRangeTypeEnum.LOGISTICS:
          runStatsAll({ logisticsTime: timestamp })
          break
        case TimeRangeTypeEnum.ORDER:
          runStatsAll({ orderTime: timestamp })
          break
        default:
          break
      }
    },
    [runStatsAll]
  )

  /** 打开itc */
  const initOpenIntercome = useCallback(() => {
    const itcResource = getUrlParamReturnUndefined('itcResource') as OpenIntercomSource
    if (!itcResource || OpenIntercomSource.oaSign !== itcResource) return
    const url = new URL(window.location.href)
    openIntercom()
    url.searchParams.delete('itcResource')
    window.history.replaceState(null, null, url)
  }, [])

  /** shopify新手教学相关 */
  const [needTeach, setNeedTeach] = useState<boolean>(false)

  useEffect(() => {
    const uids = config?.shopifyUid?.split(',')
    uids && setNeedTeach(uids.includes(userUid))
  }, [config?.shopifyUid, userUid])

  useMount(async () => {
    setProgress(true)
    try {
      const defaultTimestamp = subDays(startOfToday(), defaultValue - 1).getTime()
      await runStatsAll({ orderTime: defaultTimestamp, logisticsTime: defaultTimestamp })
      initOpenIntercome()
    } finally {
      setProgress(false)
    }
  })

  /** 新手指引相关 start */
  const [cancelVisible, setCancelVisible] = useState(false)

  const handleCancel = useCallback(() => {
    setCancelVisible(false)
  }, [])

  const handleShow = useCallback(() => {
    setCancelVisible(true)
  }, [])
  /** 新手指引相关 end */

  return (
    <div className={cls(styles.dashboardContainer, 'paper')}>
      <Helmet>
        <title>{t('dashboard.title')}</title>
      </Helmet>

      <OneProgress loading={progressState} />
      {needTeach && <TeachingCard />}
      <NotifyCard />
      <div className={styles.rowContainer}>
        <div className={styles.primaryContainer}>
          {/* 优惠活动卡片 */}
          <GiveawayCard />
          {guideInfo?.remindStatus && <GuideCard guideInfo={guideInfo} showCancel={handleShow} />}
          <HeaderCard />
          <LogisticsCard stats={logisticsStats} selectChange={TimeChange} />
          <OrderCard stats={orderStats} selectChange={TimeChange} />
          {guideInfo?.remindStatus && <CloseGuideModal show={cancelVisible} onCancel={handleCancel} />}
        </div>

        <div className={styles.secondaryContainer}>
          <OneClubPlusCard />
          <WalletCard />
        </div>
      </div>
    </div>
  )
}

Dashboard.displayName = 'Dashboard'
export default Dashboard
