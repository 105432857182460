export default {
  /* 查询新手任务列表 */
  taskListUrl: '/api/guide/task/list',
  /* 新手任务step提示，不再提示 */
  finishStepsUrl: '/api/guide/task/step_read',
  /* 全部任务完成后，不再弹窗提示 */
  finishTaskUrl: '/api/guide/task/notify_read',
  /** 不再提醒 */
  neverRemindUrl: '/api/guide/task/close_remind',
}
