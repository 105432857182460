import React from 'react'
import FormItem from '@/components/Form/Antd/DynamicFormItem'
import Context from './Context'

const DynamicFormItem: React.FC<React.ComponentProps<typeof FormItem>> = ({ children, hide: inputHidden, ...args }) => {
  return (
    <Context.Consumer>
      {({ hidden: globalHidden }) => {
        const hidden = typeof inputHidden === 'boolean' ? inputHidden : globalHidden

        return (
          <FormItem {...args} hide={hidden}>
            {children}
          </FormItem>
        )
      }}
    </Context.Consumer>
  )
}

export default DynamicFormItem
