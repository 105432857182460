import { useCounter } from 'ahooks'
import { defaults, defaultsDeep } from 'lodash'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import type { LoadableComponent } from '@loadable/component'
import type { TrackBasicParams, TrackEventParams } from '@/services/track'
import { track } from '@/services/track'
import { useSelector } from '@/store'

/**
 * 场景映射
 */
export const TRACK_STAGE = {
  addressCreate: '创建地址簿',
  addressEdit: '编辑地址簿',
  orderCreate: '创建订单',
  orderEdit: '编辑订单',
  orderShip: '订单发货',
  logisticsEdit: '编辑运单',
  buy: '买家下单',
}

const ADDRESS_TYPE = {
  departure: '发货地址',
  arrival: '收货地址',
}

type Meta = Partial<TrackEventParams['addressSplitAlgorithm'] & TrackEventParams['addressSplitAlgorithmOrderCreate']>

interface PageContext {
  /** 页面 */
  page?: keyof typeof TRACK_STAGE
  /** 统计 */
  count: number
  /** 执行统计 */
  incCount: () => void
  /** 订单唯一标识 */
  orderSign: string
  /** 更新 */
  update: (meta: Meta) => void
}

const pageContext = React.createContext<PageContext>(null)
export const withAddressSplitAlgorithmTrackPage = <P extends Record<string, any>>(Component: React.FC<P> | LoadableComponent<P>, page: keyof typeof TRACK_STAGE) => {
  const Provider = pageContext.Provider

  const AddressSplitAlgorithmTrackPage: React.FC<P> = (props) => {
    const [meta, update] = useState<Meta>(null)
    const [count, { inc: incCount }] = useCounter()
    const seller_id = useSelector((state) => state.user.info.sellerId)
    const orderSign = useMemo(() => seller_id + (Date.now() + Math.random() * 10e9).toString(32), [seller_id])

    return (
      <Provider value={{ ...meta, update, count, incCount, page, orderSign }}>
        <Component {...props} />
      </Provider>
    )
  }

  AddressSplitAlgorithmTrackPage.displayName = 'AddressSplitAlgorithmTrackPage'
  return AddressSplitAlgorithmTrackPage
}

interface ComponentContext extends Pick<PageContext, 'page' | 'count' | 'incCount' | 'update'> {
  /** 地址类型 */
  type: 'departure' | 'arrival'
  /** 国家 */
  countryCode?: string | (() => string)
}

const componentContext = React.createContext<ComponentContext>(null)

export interface AddressSplitAlgorithmTrackProviderProps extends Omit<ComponentContext, 'count' | 'incCount' | 'update'> {
  children: React.ReactElement
}

/** 地址拆分算法统计 */
export const AddressSplitAlgorithmTrackProvider: React.FC<AddressSplitAlgorithmTrackProviderProps> = (() => {
  const Provider = componentContext.Provider
  return (props) => {
    const { page, type, countryCode, children } = props
    const [meta, update] = useState<Meta>(null)
    const [count, { inc: incCount }] = useCounter()
    const context = useContext(pageContext)
    // 优先使用 pageContext
    if (context) {
      return <Provider value={{ ...context, page, type, countryCode }}>{children}</Provider>
    }

    return <Provider value={{ ...meta, update, count, incCount, page, type, countryCode }}>{children}</Provider>
  }
})()

export const useAddressSplitAlgorithmContext = () => {
  const page = useContext(pageContext)
  const comp = useContext(componentContext)
  const context = useMemo(() => defaults({}, comp, page), [comp, page])
  const metadata = useMemo<Record<string, any>>(() => context, [context])
  const { update, incCount } = useMemo(() => context, [context])
  return { context, metadata, update, incCount }
}

/** 地址拆分算法埋点 */
export const useAddressSplitAlgorithm = () => {
  const { metadata, incCount } = useAddressSplitAlgorithmContext()
  const { page, count, orderSign, countryCode, type, aa_orders, aa_logistics_orders, aa_origin_orders, aa_order_sign } = metadata || {}
  const seller_id = useSelector((state) => state.user.info.sellerId)
  const algorithmTrackData = useMemo(() => {
    return {
      /** 订单号 */
      aa_orders,
      /** 运单 */
      aa_logistics_orders,
      /** 原始订单号 */
      aa_origin_orders,
      /** 操作端类型 */
      aa_optype: 'PC',
      /** 调用来源 */
      aa_region: 'OS',
      /** 地址类型 */
      aa_address_type: ADDRESS_TYPE[type],
      /** 使用场景 */
      aa_stage: TRACK_STAGE[page],
      /** 第N次触发算法 */
      aa_times: count?.toString(),
      /** 国家/地区 */
      aa_country: typeof countryCode === 'string' ? countryCode : typeof countryCode === 'function' ? countryCode() : '',
      /** 商家 ID */
      seller_id,
      /** 唯一 ID */
      aa_uid: seller_id,
      /** 订单标识 */
      aa_order_sign: aa_order_sign || orderSign,
    } as const
  }, [aa_orders, aa_logistics_orders, aa_origin_orders, type, page, count, countryCode, seller_id, aa_order_sign, orderSign])

  type TrackAddressSplitAlgorithmParams = Omit<TrackEventParams['addressSplitAlgorithm'], 'aa_order_sign' | keyof typeof algorithmTrackData | keyof TrackBasicParams> &
    Partial<typeof algorithmTrackData> &
    Partial<TrackBasicParams>

  /** 地址拆分算法使用记录埋点 */
  const trackAddressSplitAlgorithm = useCallback(
    async (params: Partial<TrackAddressSplitAlgorithmParams>) => {
      const data: Partial<TrackEventParams['addressSplitAlgorithm']> = {
        ...algorithmTrackData,
        /** 国家/地区 */
        aa_country: typeof countryCode === 'function' ? countryCode() : typeof countryCode === 'string' ? countryCode : '',
        ...params,
      }

      const tracers: TrackEventParams['addressSplitAlgorithm'] = defaultsDeep({}, data, algorithmTrackData)
      await track('addressSplitAlgorithm', tracers)
      if (typeof incCount === 'function') {
        incCount()
      }
    },
    [algorithmTrackData, countryCode, incCount]
  )

  const orderCreateConnectAlgorithmTrackData = useMemo(() => {
    return {
      /** 订单号 */
      aa_orders,
      /** 运单 */
      aa_logistics_orders,
      /** 原始订单号 */
      aa_origin_orders,
      /** 操作端类型 */
      aa_optype: 'PC',
      /** 调用来源 */
      aa_region: 'OS',
      /** 第N次触发算法 */
      aa_times: count?.toString(),
      /** 使用场景 */
      aa_stage: TRACK_STAGE.orderCreate,
      /** 商家 ID */
      seller_id,
      /** 唯一 ID */
      aa_uid: seller_id,
      /** 订单标识 */
      aa_order_sign: orderSign,
    } as const
  }, [count, seller_id, orderSign, aa_orders, aa_logistics_orders, aa_origin_orders])

  type TrackOrderCreateConnectAddressSplitAlgorithmParams = Omit<
    TrackEventParams['addressSplitAlgorithmOrderCreate'],
    keyof typeof orderCreateConnectAlgorithmTrackData | keyof TrackBasicParams
  > &
    Partial<typeof orderCreateConnectAlgorithmTrackData> &
    Partial<TrackBasicParams>

  /** 拆分算法关联创单 */
  const trackOrderCreateConnectAddressSplitAlgorithm = useCallback(
    async (params: TrackOrderCreateConnectAddressSplitAlgorithmParams, force = false) => {
      const data: Partial<TrackEventParams['addressSplitAlgorithmOrderCreate']> = {
        ...orderCreateConnectAlgorithmTrackData,
        ...params,
      }

      if (parseInt(data.aa_times, 10) > 0 || force) {
        const tracers: TrackEventParams['addressSplitAlgorithmOrderCreate'] = defaultsDeep({}, data, orderCreateConnectAlgorithmTrackData)
        await track('addressSplitAlgorithmOrderCreate', tracers)
      }
    },
    [orderCreateConnectAlgorithmTrackData]
  )

  return { trackAddressSplitAlgorithm, trackOrderCreateConnectAddressSplitAlgorithm }
}
