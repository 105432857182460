import type { RouteConfig } from 'react-router-config'
import cloneDeep from 'lodash/cloneDeep'
import { map, trim, omit } from 'lodash'

export type MenuTagType = 'NEW' | 'PROMO'
export interface MenuDataItem extends RouteConfig {
  /**
   * 路由别名
   * 后续需要动态配置, 请根据该名字与后端数据对应
   */
  alias?: string
  /** 菜单项的标签 */
  tag?: MenuTagType
  group?: string
  menuTitle?: string
  children?: MenuDataItem[]
  /** 菜单icon */
  icon?: React.FC<any>
  /** 权限code，可以传入单个权限和多个权限编码，逗号分割 */
  permissionCode?: string
}

/**
 * 主要用于将相对地址改成绝对地址
 * @param routes 路由配置
 * @param parent 父节点路径
 */
export function absRoutes(routes: MenuDataItem[] = [], parent = ''): MenuDataItem[] {
  if (!Array.isArray(routes)) {
    return []
  }

  return routes.map((route) => {
    let absPath = route.path
    if (typeof absPath === 'string') {
      if (absPath.search(parent) === -1) {
        absPath = [parent, route.path].join('/')
        absPath = `/${trim(absPath, '/')}`
        absPath = absPath.replace(/\/+/g, '/')
      }

      if (Array.isArray(route.routes) && route.routes.length > 0) {
        const children = absRoutes(route.routes, absPath)

        return {
          ...route,
          path: absPath,
          routes: children,
        }
      }
    }

    return { ...route, path: absPath }
  })
}

/**
 * 将地址转成 ant layout 的配置
 * @param routes 路由配置
 */
export function antRoutes(routes: MenuDataItem[] = []): MenuDataItem[] {
  if (!Array.isArray(routes)) {
    return []
  }

  const validRoutes = routes.filter((route) => {
    return route.menuTitle
  })
  if (validRoutes.length === 0) {
    return []
  }

  return validRoutes.map((route) => {
    const antRoute = {
      ...omit(route, ['routes']),
      name: route.menuTitle,
      path: route.path,
    }

    if (Array.isArray(route.routes) && route.routes.length > 0) {
      return {
        ...antRoute,
        children: antRoutes(route.routes),
      }
    }

    return antRoute
  })
}

/**
 * 遍历路由配置
 * @param routes 路由
 * @param handle 每个路由配置的处理方法
 * @param childName 子路由名称
 */
export function travelRoutes<T extends RouteConfig = RouteConfig>(routes: T[], handle: (route: T) => T, childName: keyof T = 'routes'): T[] {
  if (typeof handle !== 'function') {
    return []
  }

  /**
   * ---------------------------
   * 不要再在这里写业务逻辑，谢谢老铁
   * ---------------------------
   * 不要再在这里写业务逻辑，谢谢老铁
   * ---------------------------
   * 不要再在这里写业务逻辑，谢谢老铁
   * ---------------------------
   * 具体做法请参考
   * @see /src/routers/index.tsx
   */

  return map(routes, (route) => {
    const clonedRoute: T = cloneDeep(route)
    if (clonedRoute[childName] && clonedRoute[childName].length > 0) {
      clonedRoute[childName] = travelRoutes(clonedRoute[childName], handle).filter(Boolean) as any
    }

    return handle(clonedRoute)
  })
}

/**
 * 只执行函数，不跳转页面的路由注册
 * @param menuDataItem
 * @returns
 */
export function functionBlankRoute(menuDataItem: MenuDataItem) {
  return {
    ...menuDataItem,
    path: `/_function_blank_/${Math.random()}`,
    component: menuDataItem.func,
  } as unknown as RouteConfig
}

/**
 * 判断是否纯函数路由
 * @param menuDataItem
 * @returns
 */
function isFunctionBlankRoute(path: string | string[]) {
  return path.includes('_function_blank_')
}

/**
 * 判断是否跳转路由
 * @param path
 * @returns
 */
export function isLinkRoute(path: string | string[]) {
  return path && !isFunctionBlankRoute(path)
}
