import { useCallback, useState } from 'react'

/**
 * 记录step by step 初始化完成的步骤
 * @returns
 */
export function useStepHasLoaded() {
  const [loadedStep, setLoadedStep] = useState(0)

  /** 已初始化完成步骤+1 */
  const updateLoadedStep = useCallback(() => {
    setLoadedStep((s) => s + 1)
  }, [])

  return {
    loadedStep,
    updateLoadedStep,
  }
}
