import { LANGUAGE } from '@/constant'

/**
 * 获取languageType
 * @returns
 */
export const getLocalLang = () => {
  const selfLanguageInfo = LANGUAGE.filter((item) => item.lang === window.localStorage.i18nextLng)
  const localLang = selfLanguageInfo[0] ? selfLanguageInfo[0].languageType : 'en'

  return localLang
}

/**
 * 获取lang
 * @returns
 */
export const getLocalLangLang = () => {
  const selfLanguageInfo = LANGUAGE.filter((item) => item.lang === window.localStorage.i18nextLng)
  const lang = selfLanguageInfo[0] ? selfLanguageInfo[0].lang : 'en'

  return lang
}
