import API from '@/services/http'

interface StrKeyPictureVo {
  /**多语言language code */
  key: string
  /**是否默认语言 */
  defaultLanguage: boolean
  /**PC图片地址 */
  pc: string
  /**H5图片地址 */
  mobile: string
}

interface StrKeyValueVo {
  /**多语言language code */
  key: string
  /**多语言内容 */
  value: string
  /**是否默认语言 */
  defaultLanguage: boolean
}

interface UserMessageNotifyVo {
  /**消息通知ID */
  messageNotifyId?: string
  /**消息标题 */
  messageTitle?: string
  /**消息通知标题多语言 */
  localeTitles?: StrKeyValueVo[]
  /**消息内容 */
  messageContent?: string
  /**通知内容多语言 */
  localeContent?: StrKeyValueVo[]
  /**消息上线时间 */
  onlineTime?: number
  /**通知类型 */
  notifyType?: string
  /**内容样式 */
  contentType?: string
  /**默认语言图片 */
  picture?: StrKeyPictureVo
  /**图片内容多语言 */
  localePicture?: StrKeyPictureVo[]
  /**跳转链接 */
  notifyLink?: string
  /**关联模块 */
  relationModules?: string[]
  /**产品线
ONE01：oneship */
  productLine?: string
  /**文本样式类型 */
  textStyleType?: 'all_text' | 'left_img_right_text'
  /**文本展示类型 */
  textDisplayType?: 'hover' | 'default'
}

export interface NotifyListRes {
  pageNum?: number
  pageSize?: number
  hasNext?: boolean
  data?: UserMessageNotifyVo[]
}

export interface MessageNotifyListReq {
  pageNum?: number
  pageSize?: number
  /**通知类型 */
  notifyType?: string
}

const matchCN = (url) => {
  if (!url) return url
  const m = url.match(/https?:\/\//g)
  if (m.length > 1) {
    return url.slice(url.lastIndexOf(m[m.length - 1]))
  } else return url
}

export const getNotifyList = async (params: MessageNotifyListReq) => {
  const res = await API.admin.post<NotifyListRes>('/api/user/message_notify/list', params)
  try {
    res?.data?.data?.forEach((item) => {
      if (item?.picture?.pc) item.picture.pc = matchCN(item?.picture?.pc)
      if (item?.picture?.mobile) item.picture.mobile = matchCN(item?.picture?.mobile)

      item?.localePicture?.forEach((p) => {
        if (p?.pc) p.pc = matchCN(p?.pc)
        if (p?.mobile) p.mobile = matchCN(p?.mobile)
      })
    })
  } catch (error) {
    // 临时处理
  }
  return res
}
