import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { fetchAreaList } from '@/services/apis/address'
import type { AppDispatch } from '@/store'
import type * as Types from './types'

const initialState: Types.InitialState = {
  departureCountryList: [
    { cn_name: '泰国', country_code: 'TH', en_name: 'Thailand' },
    { cn_name: '越南', country_code: 'VN', en_name: 'VN' },
    { cn_name: '新加坡', country_code: 'SG', en_name: 'SG' },
    { cn_name: '马来西亚', country_code: 'MY', en_name: 'Malaysia' },
    { cn_name: '中国', country_code: 'CN', en_name: '中国' },
  ],
  departureAreaList: [],
  arrivalCountryList: [
    { cn_name: '泰国', country_code: 'TH', en_name: 'Thailand' },
    { cn_name: '越南', country_code: 'VN', en_name: 'VN' },
    { cn_name: '新加坡', country_code: 'SG', en_name: 'SG' },
    { cn_name: '马来西亚', country_code: 'MY', en_name: 'Malaysia' },
  ],
  arrivalAreaList: [],
}

const { reducer, actions } = createSlice({
  name: 'order.country',
  initialState,
  reducers: {
    /** 设置发货地址 */
    setDepartureAreaList(state, action: PayloadAction<Types.Area[]>) {
      return {
        ...state,
        departureAreaList: action.payload,
      }
    },
    /** 设置发货地址 */
    setArrivalAreaList(state, action: PayloadAction<Types.Area[]>) {
      return {
        ...state,
        arrivalAreaList: action.payload,
      }
    },
  },
})

/** 获取发货地址区域列表 */
export const fetchDepartureAreaList = (params: Types.GetAreaParams) => async (dispatch: AppDispatch) => {
  const areas = await fetchAreaList(params)
  dispatch(actions.setDepartureAreaList(areas))
  return areas
}

/** 获取收货地址区域列表 */
export const fetchArrivalAreaList = (params: Types.GetAreaParams) => async (dispatch: AppDispatch) => {
  const areas = await fetchAreaList(params)
  dispatch(actions.setArrivalAreaList(areas))
  return areas
}

export const { setDepartureAreaList, setArrivalAreaList } = actions
export default reducer
