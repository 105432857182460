import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { HeaderType } from '@/bizComponent/EditTableHeaderBtn/constant'
import { formatKeys } from '@/bizComponent/EditTableHeaderBtn/headerKeys'
import { SETTING_CODE } from '@/constant'
import { fetchGuideInfoApi, getUserSetting, postUploadRestrictList } from '@/services/apis'
import { fetchDictionaryByKey } from '@/services/apis/dictionary'
import Api from '@/services/http'
import { settingUrl } from '@/services/url'
import { getPackageConfigList } from '@/services/apis/seller'
import type { AppDispatch, AppGetState } from '@/store'
import type { PackageInfoVo } from '@/services/apis/seller/type'
import { setPagination as setPaginationImmediateOrder } from '@/store/immediateOrderSlice'
import { setPagination as setPaginationLogistics } from '@/store/logisticsSlice'
import { setPagination as setPaginationOrder } from '@/store/oneshipOrderSlice'
import type * as Types from './types'
import { getTargetOmsConfig } from '@/utils'
import { postCountryAll } from '@/services/apis/common'

const initialState: Types.CommonState = {
  progress: false,
  setting: {
    CURRENCY_RATE: {
      dataValue: '0',
      dataType: null,
      settingCode: 'CURRENCY_RATE',
    },
    SELLER_FEE_SETTING_SWITCH: {
      dataValue: '0',
      dataType: null,
      settingCode: 'SELLER_FEE_SETTING_SWITCH',
    },
    [SETTING_CODE.SALES_SORT_FEATURE]: {
      settingCode: SETTING_CODE.SALES_SORT_FEATURE,
      dataType: null,
      dataValue: '',
    },
  },
  systemSetting: {
    TAG_MAX_COUNT: 15,
    TAB_MAX_COUNT: 20,
  },
  userSettingIsReady: false,
  tableLoading: false,
  columnSetting: {
    SALES_COLUMN_FEATURE: [],
    ORDER_COLUMN_FEATURE: [],
    REVERSE_COLUMN_FEATURE: [],
    ON_DEMAND_SALES_COLUMN_FEATURE: [],
    ON_DEMAND_ORDER_COLUMN_FEATURE: [],

    /**
     * 备注参考
     * @see /store/commonSlice/types.ts
     */

    // 排序
    SALES_COLUMN_FEATURE_SORT: [],
    ORDER_COLUMN_FEATURE_SORT: [],
    REVERSE_COLUMN_FEATURE_SORT: [],
    ON_DEMAND_SALES_COLUMN_FEATURE_SORT: [],
    ON_DEMAND_ORDER_COLUMN_FEATURE_SORT: [],
  },
  guideInfo: null,
  areaCodeMap: {},
  messageConfig: null,
  downloadConfig: null,
  countryAll: [],
  packageSizeList: [],
  uploadLimit: {} as Types.CommonState['uploadLimit'],
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setProgress(state, action: PayloadAction<{ progress: boolean }>): Types.CommonState {
      return {
        ...state,
        progress: action.payload.progress,
      }
    },
    setSetting(state, action: PayloadAction<Types.CommonState['setting']>): Types.CommonState {
      return {
        ...state,
        setting: {
          ...state.setting,
          ...action.payload,
        },
      }
    },
    setSystemSetting(state, action: PayloadAction<Types.CommonState['systemSetting']>): Types.CommonState {
      return {
        ...state,
        systemSetting: {
          ...state.systemSetting,
          ...action.payload,
        },
      }
    },
    setTableLoading(state, action: PayloadAction<{ loading: boolean }>) {
      return {
        ...state,
        tableLoading: action.payload.loading,
      }
    },
    setColumnSetting(state, action) {
      return {
        ...state,
        columnSetting: {
          ...state.columnSetting,
          ...action.payload,
        },
      }
    },
    setGuideInfo(state, action) {
      const data = action.payload
      // 过滤废弃的任务
      const taskDetailResBos = data?.taskDetailResBos?.filter((i) => !i.disable)
      const process = taskDetailResBos?.filter((i) => i.completeStatus).length
      return {
        ...state,
        guideInfo: {
          ...action.payload,
          process,
          taskDetailResBos,
        },
      }
    },
    setAreaCodeMap(state, action: PayloadAction<Record<string, string>>) {
      return {
        ...state,
        areaCodeMap: action.payload,
      }
    },
    updateMessageConfig(state, action: PayloadAction<Types.IMessageConfigRes>) {
      return {
        ...state,
        messageConfig: action.payload.message,
        downloadConfig: action.payload.download,
      }
    },
    updateCountryAll(state, action: PayloadAction<any>) {
      return {
        ...state,
        countryAll: action.payload,
      }
    },
    updatePackageSizeList(state, action: PayloadAction<PackageInfoVo[]>) {
      return {
        ...state,
        packageSizeList: action.payload,
      }
    },
    updateUserSettingIsReady(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        userSettingIsReady: action.payload,
      }
    },
    setUploadLimit(state, action) {
      return {
        ...state,
        uploadLimit: action.payload,
      }
    },
  },
})

/** 获取用户配置 */
export const settingCreator = (params?: Types.SettingListParams) => async (dispatch: AppDispatch) => {
  const response = await getUserSetting(params)
  const { settings } = response?.data || {}
  if (Array.isArray(settings)) {
    const settingMap = settings.reduce((sum, cur) => {
      const key = cur.settingCode
      sum[key] = cur
      return sum
    }, {})
    dispatch(setSetting(settingMap))
    dispatch(updateUserSettingIsReady(true))
    // 初始化订单排序方式
    dispatch(setPaginationOrder({ orderByType: settingMap[SETTING_CODE.SALES_SORT_FEATURE] && Number(settingMap[SETTING_CODE.SALES_SORT_FEATURE].dataValue) }))
    // 初始化即时物流订单排序方式
    dispatch(
      setPaginationImmediateOrder({
        orderByType: settingMap[SETTING_CODE.ON_DEMAND_SALES_SORT_FEATURE] && Number(settingMap[SETTING_CODE.ON_DEMAND_SALES_SORT_FEATURE].dataValue),
      })
    )
    // 初始化运单排序方式(包括即时物流)
    dispatch(
      setPaginationLogistics({
        orderByType: settingMap[SETTING_CODE.ORDER_SORT_FEATURE] && Number(settingMap[SETTING_CODE.ORDER_SORT_FEATURE].dataValue),
        immediateOrderByType: settingMap[SETTING_CODE.ON_DEMAND_ORDER_SORT_FEATURE] && Number(settingMap[SETTING_CODE.ON_DEMAND_ORDER_SORT_FEATURE].dataValue),
      })
    )

    const parseJson = (jsonSrouce: string) => {
      try {
        return JSON.parse(jsonSrouce)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Parse user settings failed. ${error.message}`)
      }
    }

    const parseConfig = (name: string) => {
      const jsonSrouce = settingMap?.[name]?.dataValue
      if (!(typeof jsonSrouce === 'string' && jsonSrouce.length)) {
        // eslint-disable-next-line no-console
        console.error(`User settings \`${name}\` is empty.`)
        return []
      }

      return parseJson(jsonSrouce)?.columnList || []
    }

    // 普通物流
    const SALES_COLUMNS = parseConfig('SALES_COLUMN_FEATURE')
    const ORDER_COLUMNS = parseConfig('ORDER_COLUMN_FEATURE')
    const REVERSE_COLUMNS = parseConfig('REVERSE_COLUMN_FEATURE')

    const salesConfig = formatKeys(SALES_COLUMNS, HeaderType.order)
    const orderConfig = formatKeys(ORDER_COLUMNS, HeaderType.logistic)
    const reverseConfig = formatKeys(REVERSE_COLUMNS, HeaderType.reverse)

    // 即时物流
    const ON_DEMAND_SALES_COLUMNS = parseConfig('ON_DEMAND_SALES_COLUMN_FEATURE')
    const ON_DEMAND_ORDER_COLUMNS = parseConfig('ON_DEMAND_ORDER_COLUMN_FEATURE')

    const onDemandSalesConfig = formatKeys(ON_DEMAND_SALES_COLUMNS, HeaderType.immediateOrder)
    const onDemandOrderConfig = formatKeys(ON_DEMAND_ORDER_COLUMNS, HeaderType.immediateLogistic)

    dispatch(
      setColumnSetting({
        ...salesConfig,
        ...orderConfig,
        ...reverseConfig,
        ...onDemandSalesConfig,
        ...onDemandOrderConfig,
      })
    )

    return settings
  }
}

/** 获取系统配置 */
export const systemSettingCreator = () => async (dispatch: AppDispatch) => {
  const response = await fetchDictionaryByKey('ONESHIP_COMMON_SETTING')
  const { data } = response
  const settingMap = data.dictionaryInfos.reduce((sum, cur) => {
    const key = cur.key
    sum[key] = cur.value
    return sum
  }, {}) as Types.CommonState['systemSetting']
  dispatch(setSystemSetting(settingMap))
  return data.dictionaryInfos
}

/** 新手指引信息 */
export const fetchGuideInfo = () => async (dispatch: AppDispatch) => {
  const response = await fetchGuideInfoApi()
  const { data } = response
  dispatch(setGuideInfo(data))
  return data
}
/** 更新用户配置 */
export const updateSetting = (params: Types.SettingUpdateParams[]) => async (dispatch: AppDispatch) => {
  const response = await Api.toast.post('/api/user/setting/update', {
    settings: params,
  })
  const { data } = response
  // 更新对应的store
  const settingMap = params.reduce((sum, cur) => {
    const key = cur.settingCode
    sum[key] = cur
    return sum
  }, {})
  dispatch(setSetting(settingMap))
  return data
}

/** 获取汇率 */
export const fetchRateList = async (params: Types.RateParam) => {
  const response = await Api.admin.post<Types.RateResItem>(settingUrl.rateList, params)
  const { data, bizCode, bizMessage, message } = response
  if (bizCode === 'SUCCESS') {
    return data
  }

  return Promise.reject({ bizCode, bizMessage, message })
}
/** 更新列配置 */
export const updateColumn = (params) => async (dispatch: AppDispatch) => {
  const response = await Api.toast.post('/api/user/setting/update', {
    settings: params,
  })
  const { data } = response
  const list = JSON.parse(params[0].dataValue).columnList || []

  // 更新对应的store
  dispatch(
    setColumnSetting({
      [params[0].settingCode]: list.filter((item) => !!item?.isSelect).map((item) => item.column),
      [`${params[0].settingCode}_SORT`]: list.map((item) => item.column),
    })
  )
  return data
}

/** 获取国家-手机区号映射 */
export const queryPhoneAreaCode = () => async (dispatch: AppDispatch) => {
  const { data } = await fetchDictionaryByKey('COUNTRY_PHONE_AREA_CODE')
  const areaCodeMap = data.dictionaryInfos.reduce((prev, cur) => {
    try {
      const value = cur.value ? JSON.parse(cur.value) : ''
      if (value?.countryCode) {
        prev[value.countryCode] = value.aresCode
      }
    } catch (error) {}
    return prev
  }, {})

  dispatch(setAreaCodeMap(areaCodeMap))
}

export const getMessageCenterConfig = () => async (dispatch: AppDispatch) => {
  const data = await getTargetOmsConfig('messageCenterConfig')
  dispatch(updateMessageConfig(data as unknown as Types.IMessageConfigRes))
}

export const getCountryAllWithContinent = () => async (dispatch: AppDispatch) => {
  const res = await postCountryAll()
  dispatch(updateCountryAll(res?.data?.data))
}

export const getPackageSizeList = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { user } = getState()
  const countryCode = user?.info?.countryCode
  const res = await getPackageConfigList({
    countryCode,
  })
  dispatch(updatePackageSizeList(res?.data?.packageInfoList))
}

/** 导入文件限制大小 */
export function getUploadLimit() {
  return async (dispatch: AppDispatch) => {
    const res = await postUploadRestrictList()
    const sceneMap = res?.data?.restricts?.reduce((sum, cur) => {
      sum[cur.sceneId] = cur.uploadSizeLimit || 10 /** 用10M兜底 */
      return sum
    }, {})
    dispatch(setUploadLimit(sceneMap))
  }
}

export const {
  setProgress,
  setSetting,
  setTableLoading,
  setSystemSetting,
  setColumnSetting,
  setAreaCodeMap,
  setGuideInfo,
  updateMessageConfig,
  updateCountryAll,
  updatePackageSizeList,
  updateUserSettingIsReady,
  setUploadLimit,
} = commonSlice.actions
export default commonSlice.reducer
