import type { ReactElement } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { ChevronLeft } from '@yy/one-icon'
import { useUrlSearch } from '@/hooks'
import { BreadcrumbContext } from '../../context'
import classes from './index.module.less'

/**
 * 没有返回按钮的路由
 * @todo
 * 这里应该直接读路由配置，不应该做枚举
 */
const noBack = [
  '/ship/order/list',
  '/ship/logistics/list',
  '/ship/reverse/list',
  '/setting/stores/list',
  '/setting/addressbook',
  '/setting/account',
  '/immediate/order',
  '/immediate/logistics',
  '/pickGoods/list',
]

const Breadcrumb: React.FC = () => {
  const history = useHistory()
  const { id, orderNo, logisticsNo } = useParams<{ id?: string; orderNo?: string; logisticsNo?: string }>()
  const { t } = useTranslation()
  const q = useUrlSearch()

  return (
    <BreadcrumbContext.Consumer>
      {(routes = []): ReactElement => {
        return (
          <div>
            {routes.map(({ route }, index) => {
              const title = location.pathname === route.path ? route.menuTitle || route.title : ''
              const desc = location.pathname === route.path ? route.pageDescription : ''

              if (route.path === '/ship/logistics/detail/:appId/:logisticsNo' || route.path === '/ship/logistics/edit/:appId/:logisticsNo') {
                return (
                  <div key={index} className={classes.root} style={{ marginBottom: 8, marginTop: 20 }}>
                    <ChevronLeft className={classes.backIcon}></ChevronLeft>

                    <span
                      className={classes.unselected}
                      onClick={() => {
                        history.push(routes[index - 1].route.path)
                      }}
                    >
                      {t(routes[index - 1].route?.menuTitle as string)}
                    </span>
                    <div key={index} className={classes.selected}>
                      #{logisticsNo || id || orderNo}
                    </div>
                  </div>
                )
              }

              if (title) {
                if (noBack.includes(route.path)) {
                  return (
                    <React.Fragment key={index}>
                      <span className={classes.selected}>{t(title)}</span>
                      {desc ? <p className={classes.desc}>{t(desc)}</p> : null}
                    </React.Fragment>
                  )
                }

                const backKey = index === 4 ? 2 : index - 1
                return (
                  <div key={index} className={classes.root} style={{ marginBottom: 8, marginTop: 20 }}>
                    <ChevronLeft className={classes.backIcon}></ChevronLeft>

                    <span
                      className={classes.unselected}
                      onClick={() => {
                        history.push(routes[backKey].route.path)
                      }}
                    >
                      {t(routes[backKey].route?.menuTitle as string)}
                    </span>
                    <div key={index} className={classes.selected}>
                      {routes[index - 1].route.path === '/ship/order/steps' && typeof q?.edit !== 'undefined' ? t('order.edit.titile') : t(title)}
                    </div>
                  </div>
                )
              } else {
                return ''
              }
            })}
          </div>
        )
      }}
    </BreadcrumbContext.Consumer>
  )
}

Breadcrumb.displayName = 'Breadcrumb'
export default Breadcrumb
