/* eslint-disable no-console */
import { ACT, APP_ID, EVENT_ID_NAME_LIST } from '../track/constants'
import { MAX_TTI } from './constant'
import { formatMoreInfo, formatTimeout, getFCPTime, getTTITime } from './util'

export interface IPerformanceIndicators {
  /** 首次渲染 */
  fcp: number
  /** 可交互时间 */
  tti: number
  /** dom解析完成的时间 */
  dom_complete: number
  /** 开始获取到dom的时间 */
  dom_interactive: number
  /** 请求超过限定时间的资源 */
  timeout_resource: string
  /** 当前页面，本来url已经有了，但数据分析过滤的字段是page */
  page: string
  /** 额外信息，保存序列化的对象 */
  more_info?: string
}

/** 上报sdk基础配置项 */
function getBaseOptions() {
  return {
    act: ACT,
    release: process.env.TRACE_RELEASE,
    environment: process.env.APP_ENV,
    project_id: APP_ID,
    event_id: EVENT_ID_NAME_LIST.Performance.event_id,
    event_name: EVENT_ID_NAME_LIST.Performance.event_name,
  }
}

class OneShipPerformance {
  private _longTaskEntries: PerformanceEntryList
  private _resourceEntries: PerformanceEntryList
  private _baseOptions: Record<string, any>

  constructor() {
    this._longTaskEntries = []
    this._resourceEntries = []
    this._baseOptions = getBaseOptions()
  }

  /**
   * 注册PerformanceObserver
   * @returns {number | null}
   */
  public observe() {
    try {
      const observer = new PerformanceObserver((list) => {
        const perfEntries = list.getEntries()

        for (let i = 0; i < perfEntries.length; i++) {
          if (perfEntries[i].entryType === 'resource') {
            this._resourceEntries.push(perfEntries[i])
          } else {
            this._longTaskEntries.push(perfEntries[i])
          }
        }
      })

      // register observer for resource notifications
      observer.observe({ entryTypes: ['resource', 'long-task'] })
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * 获取各项指标数据
   * @returns {IPerformanceIndicators}
   */
  public async run(): Promise<IPerformanceIndicators> {
    try {
      const [navTime = {}] = performance.getEntriesByType('navigation') as any
      const tti = await getTTITime()

      const data: IPerformanceIndicators = {
        dom_interactive: Math.round(navTime.responseStart) || null,
        fcp: getFCPTime(),
        dom_complete: Math.round(navTime.domContentLoadedEventStart) || null,
        tti,
        timeout_resource: formatTimeout(this._resourceEntries),
        page: location.pathname,
        more_info: formatMoreInfo({ ttiOverTime: tti >= MAX_TTI, resources: this._resourceEntries }),
      }
      this.reset()
      return Object.assign({}, data, this._baseOptions)
    } catch (error) {
      console.error(error)
    }
  }

  protected reset() {
    this._longTaskEntries = []
    this._resourceEntries = []
  }
}

const Performance = new OneShipPerformance()
// 代码执行必须先注册 PerformanceObserver监听才有后续数据
Performance.observe()

export default Performance
