/**
 * 引用
 * 主要用于 `useSelecter` 等返回值时不创建一个新对象
 */

/** 数组 */
export const REF_ARRAY: never[] = [] as never

/** 对象 */
export const REF_OBJECT: Record<string | number | symbol, never> = {}
