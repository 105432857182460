import API from '@/services/http'
import { guideUrl } from '@/services/url'
import type { ISubAccountInfo } from '@/store/serviceProviderSlice/types'

interface CancellationRequestType {
  /**邮箱 */
  email?: string
  /**来源系统 */
  applySource: 'ONESHIP_CROSS_BORDER_DASH' | 'ONESHIP_INTERNATIONAL_DASH' | 'ONESHIP_VN_DASH'
  /**图形验证码 */
  captchaToken: string
}

interface CancelConfirmType {
  ticket: string
  code: string
}

type IGetSubAccount = {
  expressTypeCode: string
  pickupType?: 1 | 2 //1:pickup；2:dropoff
}
/**
 * 获取子账号
 * @param params
 * @returns
 */
export const getSubAccount = (params: IGetSubAccount) => API.admin.post<ISubAccountInfo>('/api/user/sub_account/get', params)

export interface checkEmailTypeRes {
  sellerName: string
  /**
   * 1个人用户
   */
  userType: string
  token: string
}

export interface CountryCheckRes {
  countryCode: string
}

export interface IInfoProps {
  oneshipName: string
  notificationEmail?: string
}

export interface Premission {
  accessControl: 0 | 1
  resourceId: string
  system: string
  resourceCode: string
  resourceUrl: string
  children: Premission[]
  resourceName: string
  /** 是否禁用 */
  banned: 0 | 1
  /** 优先级（数字越大优先级越高） */
  priority: number
  /** ant插件key */
  key: string
  parentId: string
  /** 资源类型(1-菜单，2-功能) */
  resourceType: 1 | 2
}

/**
 * 更新用户信息
 * @returns
 */
export const updateUserInfo = (data: IInfoProps) => API.admin.post('/api/user/info/update', data)

/** 校验code */
export const verifyLoginCode = (data) => API.udb.get<{ code: string }>('/udb/lgn/login/verifyCode.do', { params: data })

/**
 * 商家注销-获取用户注册邮箱
 * @returns
 */
export const getCancellationEmail = () => API.toast.post<{ email: string }>('/api/user/info/getCancellationEmail')

/**
 * 商家注销申请
 * @returns
 */
export const doCancellation = (data: CancellationRequestType) => API.toast.post('/api/user/info/cancellation', data)

/**
 * 个人隐私申诉确认
 * @param data
 * @returns
 */
export const doAppealConfrim = (data: CancelConfirmType) => API.toast.post('/api/appeal/confirm', data)

/**
 * 商家注销确认
 * @param data
 * @returns
 */
export const doSellerCancelConfrim = (data: CancelConfirmType) => API.toast.post('/api/user/info/cancellationConfirm', data)
/** 校验ticket */
export const verifyLoginTicket = (data) => API.udb.get<{ code: string }>('/udb/lgn/cas/callback.do', { params: data })

/** 获取新手任务 */
export const fetchGuideInfoApi = () => API.admin.post(guideUrl.taskListUrl)

/** 不再提醒 */
export const neverRemindApi = () => API.admin.post(guideUrl.neverRemindUrl)

/** 全部完成 */
export const finishTask = () => API.admin.post(guideUrl.finishTaskUrl)

export const finishStepByStep = () => API.admin.post(guideUrl.finishStepsUrl, { guideTaskType: 'TRACK_SETTING_GUIDE_TASK' })

export const getUserPermissionTree = () => API.admin.post<{ extMap: any; resourceTree: Premission[] }>('/api/auth/resource/list')

export interface UserSettingListType {
  /** 来源国家地区 */
  sourceCountry?: string
  /** 配置编码列表 */
  settingCodes?: string[]
}

export interface UserSettingListResType {
  extMap?: Record<string, any>
  settings: {
    settingCode: string
    dataType?: any
    dataValue: string
  }[]
}

/**
 * 获取用户设置
 * @param params
 * @returns
 */
export const getUserSetting = (params?: UserSettingListType) =>
  API.admin.post<UserSettingListResType>('/api/user/setting/list', params, {
    params: {
      // 为了匹配预请求
      settingCodes: params?.settingCodes?.join(','),
    },
  })

export enum OsContractStatusEnum {
  /** 未签约 */
  UNSIGNED = 'unsigned',
  /** 已完成 */
  SIGNED = 'signed',
}
export interface ContractDetailRes {
  /**是否成功 */
  isSuccess?: boolean
  /**扩展属性 */
  extMap?: any
  /**商家结算方式（结算方式：monthly_statement月结,prepaid预付） */
  settlementMethod?: string
  /**
   * 商家的os签约状态,预付商家才需要判断签约状态, 签约状态如果是已完成，可以允许准入,签约状态如果是签署中，直接使用oaUrl跳转
   * APPROVE_TERMINATE( "APPROVE_TERMINATE","审批终止" ),DELETED( "DELETED","发起人拒签" ),
   * DECLINED( "DECLINED","已拒签" ),VOIDED( "VOIDED","签署终止" ),
   * COMPLETED( "COMPLETED","已完成" ),TIMEOUT( "TIMEOUT","已过期" ),SENT( "SENT","签署中" )
   * */
  osContractStatus?: string
  /**oa签约url（预付）如果此url为空，需要先跳转到填空写合同,如果此url非空，则直接跳转到此url继续签约 */
  oaContractUrls?: { oaContractUrl: string; phone: number; thirdSignStates: Array<string> }[]
}
/**
 * **接口** [获取商家签约状态（用于判断准入）↗](https://api-doc-sl.inshopline.com/#/project/Java/74/feature%2F20230626_1510_contract/interface/0207602c7e82b7a6c91785da204453a9)
 * @请求头 `POST /api/seller/contract/detail`
 * @更新时间 `2023-06-05 10:37:11`
 */
export function postContractDetail() {
  return API.admin.post<ContractDetailRes>(`/api/seller/contract/detail`, { urlType: 'CONTRACT_WEB_LIST' })
}

export const updateUserSetting = (settings: UserSettingListResType['settings']) => {
  return API.admin.post('/api/user/setting/update', {
    settings,
  })
}
