import type { RouteConfig } from 'react-router-config'
import { withAuthorized } from '@/components/Authorized'
import { withAuthRedirect } from '@/components/Authorized/hoc/withAuthRedirect'
import PrimaryLayout from '@/layouts/Primary'
import billManagement from './billManagement'
import dashboard from './dashboard'
import helps from './helps'
import immediate from './immediate'
import redirect from './redirect'
import setting from './setting'
import shipments from './shipments'
import subscribe from './subscribe'
import tools from './tools'
import trackSettings from './trackSettings'
import wallet from './wallet'
import survey from './survey'
import pickGoods from './pickGoods'
import { openProduct } from '@/utils/user'
import { SLIconProduct } from '@yy/one-icon'
import { functionBlankRoute } from '@/utils'

const routes: RouteConfig[] = [
  {
    path: '/',
    component: withAuthorized(withAuthRedirect(PrimaryLayout, { first: '/dashboard' })),
    routes: [
      ...dashboard,
      ...shipments,
      ...immediate,
      ...pickGoods,
      ...trackSettings,
      ...billManagement,
      functionBlankRoute({
        menuTitle: 'selling.product',
        icon: SLIconProduct,
        permissionCode: 'product',
        func: openProduct,
      }),
      ...subscribe,
      ...wallet,
      ...setting,
      ...tools,
      ...helps,
      ...redirect,
      ...survey,
    ],
  },
]

export default routes
