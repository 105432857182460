import React from 'react'
import FreeIcon from '@/static/images/freeIcon.png'
import { OneTooltip } from '@yy/one-ui'
import { useTranslation } from 'react-i18next'
import { useGetGiveawayDetail, useGiveawayTracker } from '../hook'
import { useHistory, useLocation } from 'react-router-dom'
import styles from './index.module.less'
import { GiveawayActivityStatus } from '@/services/apis/giveaway/type'

interface FreeActivityIconProps {
  className?: string
}

export const FreeActivityIcon: React.FC<FreeActivityIconProps> = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { trackGiveawayClick } = useGiveawayTracker()
  const location = useLocation()

  const { status, price, seq } = useGetGiveawayDetail()
  const inQuestionnairePage = location.pathname.includes('/questionnaire/')
  const toQuestionnairePage = () => {
    trackGiveawayClick()
    history.push(`/questionnaire/${seq}`)
  }

  return status === GiveawayActivityStatus.NotParticipate && !inQuestionnairePage ? (
    <OneTooltip title={t('promotion.free.tooltips', { num: price })} placement="bottom">
      <img className={styles.icon} width={50} src={FreeIcon} onClick={toQuestionnairePage} />
    </OneTooltip>
  ) : null
}
