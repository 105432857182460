import http from '@/services/http'
import type {
  PickGoodOrderBatchPrintRequest,
  PickGoodOrderGetRes,
  PickGoodOrderQueryRequest,
  PickGoodOrderQueryRes,
  PickGoodOrderRecheckConfirmRequest,
  QuickDeliveryRes,
} from './types'

/**
 * **接口** [拣货单列表查询↗](https://api-doc-sl.inshopline.com/#/project/Java/74/feature%2F20231108_3128_ka/interface/d002679e17163e1974e5edd6577e015b)
 * @请求头 `POST /api/pick-good-order/query`
 * @更新时间 `2023-11-08 12:18:14`
 */
export function postPickGoodOrderQuery(data?: PickGoodOrderQueryRequest) {
  return http.admin.post<PickGoodOrderQueryRes>(`/api/pick-good-order/query`, data)
}

interface BatchPrintRes {
  /**异步任务ID */
  taskId?: string
}

/**
 * **接口** [批量打印拣货单↗](https://api-doc-sl.inshopline.com/#/project/Java/74/feature%2F20231108_3128_ka/interface/3cba3c98189d14d5510699716a7d67db)
 * @请求头 `POST /api/pick-good-order/batch-print`
 * @更新时间 `2023-11-09 18:33:38`
 */
export function postBatchPrint(data?: PickGoodOrderBatchPrintRequest) {
  return http.toast.post<BatchPrintRes>(`/api/pick-good-order/batch-print`, data)
}

/**
 * **接口** [取消拣货单↗](https://api-doc-sl.inshopline.com/#/project/Java/74/feature%2F20231108_3128_ka/interface/f4bc4c8b5e239543ac3a37d9873e9296)
 * @请求头 `POST /api/pick-good-order/cancel`
 * @更新时间 `2023-11-09 18:33:38`
 */
export function postPickGoodOrderCancel(data?: PickGoodOrderBatchPrintRequest) {
  return http.admin.post<BatchPrintRes>(`/api/pick-good-order/cancel`, data)
}

/**
 * **接口** [拣货单详情↗](https://api-doc-sl.inshopline.com/#/project/Java/74/feature%2F20231108_3128_ka/interface/8fd8477d1d2b080e3f1d19df0151d7bf)
 * @请求头 `POST /api/pick-good-order/get`
 * @更新时间 `2023-11-09 18:33:38`
 */
export function postPickGoodOrderGet(pickGoodOrderNo: string) {
  return http.admin.post<PickGoodOrderGetRes>(`/api/pick-good-order/get`, {
    pickGoodOrderNo,
  })
}

/**
 * **接口** [拣货单复核-确认↗](https://api-doc-sl.inshopline.com/#/project/Java/74/feature%2F20231108_3128_ka/interface/e08d95ef1bfa92f369442e6f5832af81)
 * @请求头 `POST /api/pick-good-order/recheck-confirm`
 * @更新时间 `2023-11-09 18:33:38`
 */
export function postRecheckConfirm(data?: PickGoodOrderRecheckConfirmRequest) {
  return http.admin.post<null>(`/api/pick-good-order/recheck-confirm`, data)
}

/**
 * **接口** [订单快速发货↗](https://api-doc-sl.inshopline.com/#/project/Java/74/feature%2F20231108_3128_ka/interface/95e59f13847252b93f71301689aa703b)
 * @请求头 `POST /api/sales/order/quick-delivery`
 * @更新时间 `2023-11-20 16:13:17`
 */
export function postQuickDelivery(data?: { orderNo: string }) {
  return http.toast.post<QuickDeliveryRes>(`/api/sales/order/quick-delivery`, data)
}
