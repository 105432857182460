import Api from '@/services/http'
import { subscribeUrl } from '@/services/url'
import billApiUrl from '@/services/url/subscribe'
import type * as Types from './type'

export const pollingPayStatus = (billId: number) => {
  return Api.admin.post<Types.IBillList>(billApiUrl.pollingPayStatus, { billId })
}
export const getBillList = (parmas: Types.IBillListReq) => {
  return Api.admin.post<Types.IBillListRes>(billApiUrl.billList, parmas)
}

export const exportBill = (parmas: Types.IBillListReq) => {
  return Api.admin.post<Types.IBillListRes>(billApiUrl.exportBill, parmas)
}

export const getFlowList = (parmas: Types.IFlowsListReq) => {
  return Api.admin.post<Types.IRightsListRes>(billApiUrl.flowingList, parmas)
}

export const cancelBill = (billId: number) => {
  return Api.admin.post(billApiUrl.cancelBill, { billId })
}

// 获取商家订阅信息
export const getSubscriptionInfo = () => {
  return Api.admin.post<Types.IGetSubscriptionInfoRes>(subscribeUrl.getSubscriptionInfo)
}

// 设置是否自动续费
export const setAutoRecurring = (data: Types.ISetAutoRecurringReq) => {
  return Api.admin.post(subscribeUrl.autoRecurring, data)
}

// 查询绑卡结果、信息(轮询时调用)
export const getCardInfo = (data: { bindUniqueId: string }) => {
  return Api.admin.post<Types.ICardInfoDTO>(subscribeUrl.cardInfo, data)
}

// 已绑定卡信息
export const getCardBounds = () => {
  return Api.admin.post<Types.IGetCardBounds>(subscribeUrl.cardBounds)
}

// sdk初始化信息
export const getBillSdkInfo = () => {
  return Api.admin.post<Types.IGetBillSdkInfoRes>(subscribeUrl.billSdkMeta)
}

export const getSubscriptionProductGroup = () => {
  return Api.admin.post<Types.IGetSubscriptionProductGroupRes>(subscribeUrl.subscriptionProductGroup)
}

// 获取优惠信息
export const getSubscriptionProductPromotion = (param: any) => {
  return Api.admin.post<any>(subscribeUrl.subscriptionProductPromotion, {
    ...param,
    pageNum: 1,
    pageSize: 1000,
  })
}

export const getUnpaidSubBill = () => {
  return Api.admin.post<Types.IGetUnpaidSubBill>(subscribeUrl.getUnpaidSubBill)
}

export const getSubProducts = (data: Types.IGetSubProductsPrams) => {
  return Api.admin.post<Types.IGetSubProductsRes>(subscribeUrl.getSubProducts, data)
}

// 绑定卡
export const bindCard = (data) => {
  return Api.admin.post<{
    /** 错误码 */
    errCode: string
    /** 支付中台响应对象 */
    nextAction: Record<string, unknown>
    /** 绑定状态 */
    bindStatus: number
  }>(subscribeUrl.bindCard, data)
}

// 支付，默认绑卡
export const subBillPay = (data) => {
  return Api.admin.post(subscribeUrl.subBillPay, data)
}

export const subUpgrade = (data) => {
  return Api.admin.post(subscribeUrl.subUpgrade, data)
}

export const subCreate = (data) => {
  return Api.admin.post(subscribeUrl.subCreate, data)
}

/**
 * 获取商家当前套餐状态
 */
export const subscriptionStatus = () => Api.admin.post<Types.IPackageStatusRes>('/api/crm/admin/subscription/status')
export const billCardList = (data: Types.BillCardListReq) => Api.admin.post<Types.BillCardListRes>(subscribeUrl.billCardList, data)
/**
 * 获取商家订阅当前账号类控制上限使用情况
 */
export const accountLimit = () => Api.toast.post<Types.AccountLimitStatusRespDTO[]>(subscribeUrl.accountLimit)

export const balancePay = (data: Types.BalancePayReq) => Api.toast.post<Types.BalancePayResp>('/api/crm/admin/wallet/pay', data)

export interface TokenGetRes {
  accessToken: string
  expireTime: number
}

/** 获取Settlement sdk token */
export const getSettleMentSdkToken = () => Api.toast.post<TokenGetRes>(`/api/crm/admin/subscription/auth/token/get`)
