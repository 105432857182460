import type { SelectProps } from 'antd'
import { uniqBy } from 'lodash'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { OneInput, OneSelect } from '@yy/one-ui'
import { filterOptionIncludes } from '@/utils'

const { Option } = OneSelect

interface ISelect2InputProps extends Record<string, any> {
  list: Array<{ name: string }>
  onValueChange?: SelectProps['onChange']
  // onChange?: SelectProps['onChange']
}

const Select2Input: React.FC<ISelect2InputProps> = (props) => {
  const { list = [], onValueChange, loading, ...restProps } = props
  const { t } = useTranslation()

  const onChange = useCallback(
    (...props: Parameters<typeof onValueChange>) => {
      restProps?.onChange?.apply(null, props)
      onValueChange?.(...props)
    },
    [restProps?.onChange, onValueChange]
  )

  return !list?.length ? (
    <OneInput placeholder={t('common.input.placeholder')} {...restProps} />
  ) : (
    <OneSelect placeholder={t('common.select.placeholder')} showSearch filterOption={filterOptionIncludes} loading={loading} {...restProps} onChange={onChange}>
      {uniqBy(list, 'name')?.map((item) => (
        <Option key={item.name} value={item.name}>
          {item.name}
        </Option>
      ))}
    </OneSelect>
  )
}

Select2Input.displayName = 'Select2Input'
export default Select2Input
