import type { RouteConfig } from 'react-router-config'
import { Calculation } from '@yy/one-icon'
import { withAsync } from '@/components/Async'
import { withAuthRedirect } from '@/components/Authorized/hoc/withAuthRedirect'
import Blank from '@/layouts/Blank'

const routes: RouteConfig[] = [
  // 工具
  {
    alias: 'tools',
    path: '/tools',
    component: withAuthRedirect(Blank, { first: '/tools/rate' }),
    menuTitle: 'menu.title.tools',
    icon: Calculation,
    permissionCode: 'tools:tools:rate',
    routes: [
      // 汇率计算器
      {
        alias: 'currency_converter',
        path: '/rate',
        permissionCode: 'tools:tools:rate',
        component: withAsync(() => import(/* webpackChunkName: "Dashboard" */ '@/containers/RateCalculate')),
        menuTitle: 'menu.title.rate',
      },
    ],
  },
]

export default routes
