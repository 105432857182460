import { createContext } from 'react'

type Routes = {
  route: {
    path?: any
    menuTitle?: string
    pageDescription?: string
    title?: string
  }
}[]

const routes: Routes = []

export const BreadcrumbContext = createContext(routes)
