import type * as Types from '@/types'

export enum ElectrizationEnum {
  YES = 1,
  NO = 2,
  NO_LIMIT = 0,
}
export enum SupportedCargoEnum {
  GENERAL_GOODS = 0,
  BUILT_IN_BATTERY = 1,
  SHARP_WEAPON = 2,
  LIQUID = 3,
  POWDER = 4,
  PASTE = 5,
  PURE_BATTERY = 6,
  ARMS = 7,
  SEXY_PRODUCTS = 8,
  TEXTILE = 9,
  DANGEROUS_GOODS = 10,
}

/** 是否带电 */
export const ELECTRIZATION_OPTIONS: Types.Dict<{ value: ElectrizationEnum }> = [
  {
    value: ElectrizationEnum.YES,
    label: 'common.charged',
  },
  {
    value: ElectrizationEnum.NO,
    label: 'common.uncharged',
  },
  {
    value: ElectrizationEnum.NO_LIMIT,
    label: 'common.noLimit',
  },
]

/**体积单位 */
export const VOLUME_UNIT = {
  M: 'm³',
  CM: 'cm³',
}

/** 货物属性 */
export const SUPPORTED_CARGO_TYPES: Types.Dict<{ value: SupportedCargoEnum }> = [
  {
    value: SupportedCargoEnum.GENERAL_GOODS,
    label: 'common.cargoProperties.generalGoods',
  },
  {
    value: SupportedCargoEnum.BUILT_IN_BATTERY,
    label: 'common.cargoProperties.builtInBattery',
  },
  {
    value: SupportedCargoEnum.SHARP_WEAPON,
    label: 'common.cargoProperties.sharpWeapon',
  },
  {
    value: SupportedCargoEnum.LIQUID,
    label: 'common.cargoProperties.liquid',
  },
  {
    value: SupportedCargoEnum.POWDER,
    label: 'common.cargoProperties.powder',
  },
  {
    value: SupportedCargoEnum.PASTE,
    label: 'common.cargoProperties.paste',
  },
  {
    value: SupportedCargoEnum.PURE_BATTERY,
    label: 'common.cargoProperties.pureBattery',
  },
  {
    value: SupportedCargoEnum.ARMS,
    label: 'common.cargoProperties.arms',
  },
  {
    value: SupportedCargoEnum.SEXY_PRODUCTS,
    label: 'common.cargoProperties.sexyProducts',
  },
  {
    value: SupportedCargoEnum.TEXTILE,
    label: 'common.cargoProperties.textile',
  },
  {
    value: SupportedCargoEnum.DANGEROUS_GOODS,
    label: 'common.cargoProperties.dangerousGoods',
  },
]
