import { useEffect, useRef } from 'react'

/**
 * 我们的版本没有，自己实现一个
 * https://ahooks.gitee.io/zh-CN/hooks/use-memoized-fn
 * @param fn
 * @returns
 */
export const useMemoizedFn = <F extends (...args: any[]) => any>(fn: F) => {
  const ref0 = useRef<F>()
  const ref = useRef(fn)
  ref.current = fn
  ref0.current =
    ref0.current ??
    (function (this: any, ...args) {
      return ref.current.call(this, ...args)
    } as F)
  return ref0.current
}

/**
 * 利用useEffect实现监听某些变量变化，用于解决只想监听一个变量变化，但是监听函数里又使用了其他变量的场景
 * 会回传旧值给到回调函数
 * @param deps
 * @param fn
 */
export const useWatch = <T extends any[]>(deps: T, fn: (...args: T) => void) => {
  const watchFn = useMemoizedFn(fn)
  const oldRef = useRef([] as T)
  useEffect(() => {
    watchFn(...oldRef.current)
    oldRef.current = [...deps] as T
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, watchFn])
}
