import type { Dispatch, SetStateAction } from 'react'
import { createContext } from 'react'

type IDefaultValue = {
  hidden: boolean
  setHidden: Dispatch<SetStateAction<boolean>>
}

const defaultValue: IDefaultValue = {
  hidden: false,
  setHidden: (value) => {
    value
  },
}

const Context = createContext<IDefaultValue>(defaultValue)

export default Context
