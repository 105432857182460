import type { ModalFuncProps } from 'antd'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { awaitConfirm } from '@/components/ModalConfirmWithPromise'
import { useCannotUpgrade } from '../useCannotUpgrade'
import { useSubscriptionStatus } from '../useSubscriptionStatus'
import styles from './index.module.less'
import { IconCloseCircle } from '@yy/one-icon'

export const useToUpgradePackage = () => {
  const { t } = useTranslation()
  const { toPlanPage } = useSubscriptionStatus()
  const { isCannotUpgrade, noAuthorizedTips, okText, cancelText } = useCannotUpgrade()

  // 能升级，则引导升级，不能升级，则引导联系客服
  const toUpgradePackage = useCallback(
    (props?: ModalFuncProps) => {
      return awaitConfirm({
        title: t('subscribe.trackingSetting.notAuthorizedTitile'),
        content: noAuthorizedTips,
        centered: true,
        maskClosable: true,
        okText,
        icon: <IconCloseCircle className={styles.modalErrorIcon} />,
        cancelText,
        width: 500,
        ...props,
        onOk: () => {
          if (isCannotUpgrade) {
            window.Intercom('show')
          } else {
            toPlanPage()
          }
        },
      })
    },
    [t, noAuthorizedTips, okText, cancelText, isCannotUpgrade, toPlanPage]
  )

  return {
    toUpgradePackage,
  }
}
