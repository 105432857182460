import type * as Types from '@/types'

/**
 * 增值服务类型
 * 1: 代收货款
 * 2: 无增值服务
 */
type ValueAddedService = 1 | 2

/** 增值服务类型字典 */
export const VALUE_ADDED_SERVICES: Types.Dict<{ value: ValueAddedService }> = [
  {
    label: 'newOrder.COD',
    value: 1,
  },
  {
    label: 'newOrder.otherMethod',
    value: 2,
  },
]
