import type * as Types from '@/types'

/** 面单状态 */
export type WayBillStatus = 'ungenerated' | 'pending' | 'unprint' | 'printed'

/** 面单类型字典 */
export type WayBillStatusDict = Types.Dict<{ value: WayBillStatus }>

/** 订单面单状态 Label 类型 */
export const ORDER_WAYBILL_STATUS_LABEL: Types.DictLabel<WayBillStatus, { color: string }> = {
  ungenerated: {
    label: 'order.list.labelStatusNotGenerated',
    color: 'processing',
  },
  pending: {
    label: 'order.list.labelStatusProcessing',
    color: 'warning',
  },
  unprint: {
    label: 'order.list.labelStatusNotPrint',
    color: 'warning',
  },
  printed: {
    label: 'order.list.labelStatusPrinted',
    color: 'success',
  },
}

/** 面单类型字典 */
export const WAYBILL_STATUS: WayBillStatusDict = [
  {
    label: 'order.list.labelStatusNotGenerated',
    value: 'ungenerated',
  },
  {
    label: 'order.list.labelStatusProcessing',
    value: 'pending',
  },
  {
    label: 'order.list.labelStatusNotPrint',
    value: 'unprint',
  },
  {
    label: 'order.list.labelStatusPrinted',
    value: 'printed',
  },
]

// 面单枚举
export enum WayBillStatusEnum {
  /** 未生成 */
  ungenerated = 'ungenerated',
  /** 生成中 */
  pending = 'pending',
  /** 未打印 */
  unprint = 'unprint',
  /** 已打印 */
  printed = 'printed',
}
/** 运单-支持面单打印的面单状态 */
export const printStatus = [WayBillStatusEnum.unprint, WayBillStatusEnum.printed] as string[]
