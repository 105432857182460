import type { RouteConfig } from 'react-router-config'
import { withAsync } from '@/components/Async'
import Blank from '@/layouts/Blank'

const routes: RouteConfig[] = [
  {
    path: '/auth',
    component: Blank,
    routes: [
      {
        path: '/bind',
        component: withAsync(() => import(/* webpackChunkName: "Auth/Bind" */ '@/containers/Auth/Bind')),
        exact: true,
      },
      {
        // ec2.0 route
        path: '/shopline',
        component: withAsync(() => import(/* webpackChunkName: "Auth/Bind" */ '@/containers/Auth/Bind')),
        exact: true,
      },
      {
        // wix route
        path: '/wix',
        component: withAsync(() => import(/* webpackChunkName: "Auth/Bind" */ '@/containers/Auth/Bind')),
        exact: true,
      },
    ],
  },
]

export default routes
