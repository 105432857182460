import type { IntervalValue } from '@/components/IntervalInput'
import type { DeliveryTypeDict, ShipmentStatusDict, ShipWayDict, WayBillStatusDict } from '@/constant'
import type { FilterProviderType, ProviderType } from '@/constant/serviceProvider'
import type { LogisticsSortType } from '@/constant/sortType'
import type { OneshipLogisticsFeeResVo } from '@/services/apis/batchMergeShip/types'
import type { ExpressListData } from '@/services/apis/express/type'
import type { LogisticsAppid } from '@/services/apis/logistics/type'
import type { OrderAddressInfoVo, TagTypes } from '@/services/apis/order/type'
import type { ChannelCustomInfo } from '@/services/apis/type'
import type { DepartureStatusType } from '@/store/addressSlice/types'
import type { TagOption } from '@/store/customTagSlice/types'
import type { IProviders, WAYBILL_RESSTATUS } from '../oneshipOrderSlice/type'
import type { WaybillType } from '@/constant/oneshipShipStatus'
import type { FeeItem } from '../serviceProviderSlice/types'
import type { IAppId } from '@/components/PlatformAvatar'
export type OrderTypes = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 21 | 22 | 23 | 24 | 25 | 1000 | 1001

// 筛选类型
export interface FilterValuesType {
  shipment_status: ShipmentStatusDict
  reverse_status: ShipmentStatusDict
  ship_way: ShipWayDict
  delivery_type_name: DeliveryTypeDict
  departure: DepartureStatusType[]
  service_provider: FilterProviderType[]
  waybill_status: WayBillStatusDict
  filter_tag: TagOption[]
  has_insurance: {
    label: string
    value: boolean
  }[]
  logistics_good_number?: IntervalValue
  logistics_good_types?: IntervalValue
}

export type FilterKeysType = keyof FilterValuesType

export interface SearchNormalType {
  search_text: string
  search_type: string
  time_range: (number | null)[]
  time_type: number
}

export type SearchData = SearchNormalType & {
  filter: FilterValuesType
}

export type SearchFormType = SearchNormalType & FilterValuesType

export interface SearchType {
  check: boolean
  data?: SearchData
}

export interface PaginationType {
  pageNum: number
  pageSize: number
  total: number
  /**
   * 创建时间排序
   */
  orderByType: LogisticsSortType
  immediateOrderByType: LogisticsSortType
  reverseOrderByType: LogisticsSortType
}
export type RowCheckedType = OrderItemType & {
  /**取值于extInfo.shipDate */
  shipDate?: any
}
// 搜索查询
export interface OrderState {
  /* 用户搜索选项保存 */
  searchRecord: (SearchFormType & SearchPaginationType) | null
  immediateSearchRecord: (SearchFormType & SearchPaginationType) | null
  reverseSearchRecord: (SearchFormType & SearchPaginationType) | null
  list: OrderItemType[]
  pagination: PaginationType
  orderDetailInfo: ResOrderDetailType | null
  listTabValue: number
  listImmediateTabValue: number
  listReverseTabValue: number
  rowChecked: RowCheckedType[]
}

// 批量发货
export interface ShipType {
  /** 物流订单号列表 */
  oneshipNoList?: string[]
  freightVos?: any[]
  /** 预约提货日期 */
  requiredPickupDates?: string[]
  /** 预约提货时间 */
  requiredPickupTimeslots?: string[]
  /** 时区 */
  timezone?: string
  /** 月结账号 */
  monthlyAccount?: string
  /** 是否发货 */
  asyncSend?: boolean
  /** 揽收方式 1 pickup 2 dropoff */
  pickupType: 1 | 2
  /** 支付方式 */
  payType?: number
  // appids
  appIds: AppId[]
}
export interface AppId {
  appId: string
  /** @deprecated 使用 logisticsNo 代替 */
  oneshipNo?: string
  /** lms 单号 */
  logisticsNo: string
}
// 取消订单
export interface CancelType {
  /** @deprecated 使用 appIds[].logisticsNo 代替 */
  oneshipNoList?: string[] // 物流订单号列表
  appIds: AppId[]
}

export interface TraceType {
  logisticsNo: string
  /** @deprecated 使用 logisticsNo 代替 */
  oneshipNo?: string
  appId: string
}

// 按照查询条件导出

export interface TransformSearchData {
  status: number[] | null // 0-已取消，1-待发货，2-已发货，3-已签收，4-问题件，5-已退件，6-退货在途，21-已揽收，22-在途中，23-到达派件城市， 24-派送中， 25-拒收
  // payType: number; // 1现金支付 2到付 3月结 4第三方
  paymentType: number | null // 1cod 2非cod  todo 待定
  allCodes: string[] | null // all
  bulkNos: string[] | null // 批次号 // todo 待定
  orderNoList: string[] | null // 客户订单号
  /** lms 单号 */
  logisticsNoList: string[] | null
  companyCodeList: string[] | null // 服务商编码
  expressNoList: string[] | null //  运单号
  waybillStatus: string[] // 运单号
  startTime: number | null // 开始时间
  endTime: number | null // 结束时间
  locationName: string[] | null // 发货地址别名搜索 todo 待定
}

export interface SearchPaginationType {
  pageNum?: number
  pageSize?: number
  orderByType?: number // 1降序 2升序
}

export type QueryLogisticsType = SearchNormalType & FilterValuesType & SearchPaginationType

export type ExportType = TransformSearchData &
  SearchPaginationType & {
    exportLanguage: string
    exportSearchType: number
    notTag?: string[]
    tag?: string[]
    orderByType: number
    appIds?: LogisticsAppid[]
    logisticsType?: string
  } & {
    /**
     *  the columns what the users selected need to be export
     */
    excelColumns?: string[]
  }

// 按照查询条件导出 返回值
export interface ResponseExportType {
  taskId: string
}

// 查询订单
export type QueryType = ExportType

export interface oneshipAppIdType {
  appId: string
  /** @deprecated 使用 logisticsNo 代替 */
  oneshipNo?: string
  /** lms 单号 */
  logisticsNo: string
}

export interface PrintType {
  logisticsNos: string[]
  /** @deprecated 使用 logisticsNos 代替 */
  oneShipNos?: string[]
  callBackOSUrl?: string
}

export interface OrderItemType {
  logisticsNo: string // lms运单号
  /**
   * 物流订单编号
   * @deprecated 使用 logisticsNo 代替
   */
  oneshipNo?: string
  businessType: number // 1 线下发货 2线上发货
  expressTypeCode: string // 服务产品编码
  orderNo: string // 	销售订单号
  sellerId: string // 	卖家uid
  sellerName: string // 商家名字
  buyerId: string | null // 买家uid
  warehouseId: string | null // 仓库ID
  deliveryType: number // 交货方式：1送货上门,2上楼,3自提
  payType: number // 	支付方式:0-未定义, 1-现付，2-到付，3-月结， 4-第三方支付
  paymentType: number // 电商订单支付方式  1:COD支付， 2：非COD支付
  status: OrderTypes // 状态(0-已取消，1-待发货，2-已发货，3-已签收，4-问题件，5-已退件，6-退货在途，21-已揽收，22-在途中，23-到达派件城市， 24-派送中， 7-拒收)
  statusTime: number // 状态更新时间
  orderTime: number // 	创单时间
  sendTime: number // 	发货时间
  quantity: number // 包裹数量
  weight: number // 重量/kg
  length: number | null // 长度/cm
  width: number | null // 宽度/cm
  height: number | null //  高度/cm
  volumn: number // 体积/cm3
  locationName: string // location
  companyCode: string | null // 承运商编码
  companyName: string // 承运商名称
  expressNo: string // 运单号
  expressTypeName: string // 服务产品名称
  channelCode: string // 渠道编码
  isPrint: boolean | null // 是否打印
  billUrl: string | null // 	面单地址
  syncStatus: number // 同步状态
  createTime: number // 系统创建时间
  updateTime: number // 更新时间
  traceSyncTime: number | null // 	轨迹同步时间
  granularStatus: number // 物流子状态 见OrderGranularStatus枚举
  codAmount: number // 	cod金额
  codCurrencyCode?: string // cod币种
  noPrefix: string | null // 	订单前缀
  createBy: string // 	创建人
  updateBy: string | null // 更新人
  valid: boolean | null // 是否删除
  currencyCode: string // 	币种
  receiveTime: number // 签收时间
  extInfo: string // 扩展信息,包含了 原始预约揽收时间
  platform: string //销售平台编码
  consigneeCountryCode: string // 收件国家
  consigneeProvince: string // 收件省份
  consigneeCity: string // 收件城市
  consigneeName: string // 收件人
  waybillStatus: string // 面单状态
  senderCountry: string // 寄件国家
  senderProvince: string // 寄件省份
  senderCity: string // 寄件城市
  senderDistrict: string // 寄件区县
  senderTown: string // 寄件街镇
  senderName: string // 寄件人
  consigneeStationCode: string //原始门店编码
  consigneeStationSeq: string //OMS门店编码
  freight: number // 运费
  correctFreight: number //纠正运费
  freightCurrencyCode: string
  pickupType: 1 | 2 // 揽收方式 1 上门揽收 2 商家自寄
  requiredPickupDates: string[] // 预计揽收日期
  requiredPickupTimeslots: string[] // 预计揽收时间
  consigneeStationName: string
  consigneeStationAddress: string
  appId: string
  tagIds: string[]
  senderRemark: string
  pickupTime: number
  /** 最新轨迹 */
  latestTraceAcceptStation?: string
  /** 最新轨迹时间 */
  latestTraceAcceptTime?: number
  /** 最新轨迹类型（新增）18-门店关转事件, 19-门店修改成功事件, 20-门店修改失败事件 */
  latestTraceEventType?: string
  /** 出货日期 */
  shipDate?: string
  requiredDeliveryDates: string[]
  requiredDeliveryTimeslots: string[]
  /** 是否有托运单证明文件 */
  presignedDeliveryProofUrl?: string
  channelCustomParam?: string
  /** 支持多地址才会有（例如即时物流lalamove） */
  consigneeAddresses?: OrderAddressInfoVo[]
  /** 仅当为true时展示 取消关联 按钮 */
  resendOnlyViaUnbind?: boolean
  /** 退货原订单号 */
  reverseOrderNo?: string[]
  ind?: string
  waybillType: WaybillType
  /**1.平台物流,2:LMS物流 */
  expressLogisticsType?: 1 | 2
}

// 查询订单列表返回
export interface ResponseQueryType {
  pageNum: number
  hasNext: boolean
  pageSize: number
  total: number
  data: OrderItemType[]
  orderCounts: { [propsName: string]: number }
}

// 查询轨迹
export interface QueryTrace {
  logisticsNo: string
  /**
   * 物流订单号
   * @deprecated 使用 logisticsNo 代替
   */
  oneshipNo?: string
  appId: string
  /** 是否查询保费区间, 默认不查询 */
  queryInsuranceSupport?: boolean
}

export interface TranceItemType {
  acceptTime: number
  acceptStation: string
  remark: string
  eventType: string
  data?: {
    reason: string
    rawSubReason: string
    raw_reason: string
    eventEchoTag: string
    granular_status: number
  }
}

// 轨迹返回
export interface ResponseQueryTraceType {
  oneshipNo: string // 	物流订单号
  companyCode: string // 	快递公司编码
  expressNo: string // 	快递单号
  status: number // 物流订单状态
  historyStatusList: string // 历史状态变更历史
  statusName: string // 	物流订单状态名称
  estimatedDeliveryTime: string // 	预计送达时间
  companyName: string // 	快递公司名称
  companyPhone: string // 	快递公司电话
  traces: TranceItemType[] // 物流轨迹
  historyStatusMap: string // 历史状态变更历史时间
}

interface OrderAddressType {
  locationName: string // 发货地址别名
  senderIdNo: string // 	寄件人证件号
  senderIdType: number // 证件类型：1身份证,2护照,3港澳通行证,4回乡证,5台胞证,6赴台通行证,7其它
  senderCountryCode: string // 寄件国家
  senderProvince: string // 	寄件省份
  senderCity: string // 寄件城市
  senderDistrict: string // 	寄件区县
  senderTown: string // 寄件街镇
  senderName: string // 寄件人
  senderPhone: string // 	寄件人电话
  senderMobile: string // 寄件人手机
  senderAddress: string // 寄件详细地址
  senderAddressTwo: string // 寄件详细地址2
  senderZipCode: string // 寄件邮编
  senderRemark: string | null // 寄件备注
  senderEmail: string | null // 发件人邮箱
  consigneeIdNo: string | null // 收件人证件号
  consigneeIdType: number | null // 证件类型：1身份证,2护照,3港澳通行证,4回乡证,5台胞证,6赴台通行证,7其它 圆通只能用1和2
  consigneeCountryCode: string // 收件国家
  consigneeProvince: string // 收件省份
  consigneeCity: string // 收件城市
  consigneeDistrict: string // 收件区县
  consigneeTown: string // 收件街镇
  consigneeAddress: string // 收件详细地址
  consigneeAddressTwo: string // 收件详细地址2
  consigneeName: string // 	收件人
  consigneePhone: string // 收件人电话
  consigneeMobile: string // 收件人手机
  consigneeZipCode: string // 收件邮编
  consigneeRemark: string | null // 收件备注
  consigneeStationSeq: string | null // 收件门店编码
  consigneeStationCode: string | null // 收件原始门店编码,原始门店编码
  consigneeStationType: number // 收件门店类型
  consigneeEmail: string // 收件人邮箱
  consigneeStationAddress: string // 门店地址
  consigneeStationName: string //
  senderAddressLanguages?: string[]
  consigneeAddressLanguages?: string[]
}
type FreeInfoType = {
  freight: number | null // 	运费(单位/分)
  discountFreight: number
  premium: number // 保险费(单位/分)
  insuredAmount: number | null // 保险金额/保额(单位/分)
  /** 是否购买保险 1-是 2-否 */
  insuranceType: 1 | 2
  packAmount: number // 包装费(单位/分)
  payType: number // 支付方式:1-现付，2-到付，3-月结， 4-第三方支付
  totalAmount: number | null // 总费用(单位/分)
  codAmount: number // 	代收货款
  codCurrencyCode: string // 代收货款币种
  orderCommodityAmount: number // 订单实付金额中商品金额
  orderAmount: number // 订单实付金额
  extInfo: string | null // 扩展信息
  currencyCode: string // 币种
  paymentType: number // 电商订单支付方式:1COD支付, 2非COD其它支付方式
  monthlyAccount: string //月结账号
  freightCurrencyCode: string
  correctFreight: string //纠正运费
  currencyConverted: number
  originCurrencyCode: string
  originalFreight: number // 原始运费
  originalCorrectFreight: number // 原始运费
  currencyRate?: number // 汇率
  fetchTime?: number // 汇率时间
  /** 部分签收金额 对应的币种为partSignAmountCurrencyCode字段 */
  partSignAmount?: number
  /** 部分签收金额币种 */
  partSignAmountCurrencyCode?: string
  feeItems?: FeeItem[]
  /** 退货代收金额 */
  reverseFreight: number
  /** 退货代收金额币种 */
  reverseFreightCurrency?: string
} & Pick<
  OneshipLogisticsFeeResVo,
  'sellerCodAmount' | 'codCurrencyCode' | 'deductedFreight' | 'deductedFreightCurrencyCode' | 'sellerCustomFreight' | 'originSellerCodAmount' | 'originSellerCodCurrency'
>

export interface OrderDetailExtInfoType {
  airEmbargo: boolean // 航空禁运品
  fragile: boolean // 易碎
  fresh: boolean // 生鲜
  storageCdt: string | null // 储存条件
  remark: string // 备注
  returnBill: boolean // 是否返单
  packingTypedeliveryType: number // 交货方式：1送货上门,2上楼,3自提
  packingType: string // 包装方式：0默认，1纸箱，2气泡袋，3泡沫箱，4木架
  sellerId: string // 卖家ID
  storeId: string | null // 商铺ID
  buyerId: string | null // 买家ID
  warehouseId: string | null // 仓库ID
  customIdNo: string
  taxNumber: string
  pickupType: number // 揽收方式 2到店寄件 1预约揽收
  waybillStatus: string | null // 面单状态
  /** 税号 */
  taxes?: {
    taxNo: string
    taxType: string
  }[]
  /** 标签 - 归档 */
  tag: TagTypes
  deliveryType: number
  requiredDeliveryDates: string[]
  requiredDeliveryTimeslots: string[]
  packageDesc?: string
  /**渠道特性字段 */
  channelCustomParamV2?: ChannelCustomInfo
  /**是否需要交付证明 */
  needDeliveryProofUrl?: boolean
  /**短信通知关注的物流轨迹状态列表 */
  messageTraceNotifyStatus?: string[]
  /** 逆向物流原订单号 */
  reverseOrderNo?: string[]
}
export interface CommoditiesType {
  goodsName: string // 商品名称
  goodsCode: string // 商品编码
  goodsSize: string | null // 商品规格描述
  goodsQuantity: number // 数量
  goodsPrice: number // 价格
  goodsWeight: number | null // 重量/kg
  goodsVolume: number | null // 体积/cm3
  goodsDesc: string | null // 商品描述
  goodsPic: string | null // 图片
  goodsLength: number | null // 长cm
  goodsWidth: number | null // 宽cm
  goodsHeight: number | null // 高cm
  extInfo: string | null // 扩展信息
  currencyCode: string | null //货币
  goodsCname: string | null
  goodsEname: string | null // 商品英文名称
  goodsHscode: string | null
  goodsMaterial?: string // 商品材质
  goodsPurpose?: string // 商品用途
}

export interface OrderDetailType {
  oneshipNo: string // 物流订单号
}

export type OneshipExpressTypeSimpleVo = Partial<ExpressListData>

export interface ResOrderDetailType {
  appId: IAppId
  currencyCode: any
  /** 物流订单号 */
  logisticsNo: string
  /**
   * 物流订单号
   * @deprecated 使用 logisticsNo 代替
   */
  oneshipNo: string
  orderNo: string // 客户订单号/销售订单号
  billUrl: string //面单url
  status: number // 状态 0-已取消，1-待发货，2-已发货，3-已签收，4-问题件，5-已退件，6-退货在途，21-已揽收，22-在途中，23-到达派件城市， 24-派送中， 25-拒收
  expressTypeCode: string // 服务产品类型code
  orderTime: number // 	下单时间
  sendTime: number // 发货时间
  quantity: number // 	数量
  weight: number // 重量/kg
  length: number // 长度/cm
  width: number // 宽度/cm
  height: number // 	高度/cm
  volume: number | null // 体积/cm3
  companyCode: ProviderType // 快递公司编码
  expressNo: string // 快递单号
  channelCode: string // 对接渠道编码
  isPrint: boolean // 已打印面单标识
  syncStatus: number // 同步状态:-1同步失败,0未同步,1已同步
  noPrefix: string | null // 租户订单号定制化前缀
  address: OrderAddressType // 收寄地址信息
  feeInfo: FreeInfoType // 费用信息
  extraInfo: OrderDetailExtInfoType // 附加信息
  commodities: CommoditiesType[] // 托寄物品列表
  granularStatus: number // 在status下面对应的子状态 见OrderGranularStatus枚举
  requiredPickupDates: string[] // 预约提货日期
  requiredPickupTimeslots: string[] // 预约提货时间
  timezone: string // 时区
  oneshipExpressTypeSimpleVo: OneshipExpressTypeSimpleVo //服务产品
  orderStatus: string // 订单状态 pending待处理 delivery已发货 finished已完成 canceled已取消 closed已关闭
  associate: boolean // 是否关联
  /** zeek服务编码 */
  channelSellerId: string
  /** 实际重量 */
  realWeight?: number
  /** 计费重 */
  chargeableWeight?: number
  store: {
    userStoreSeq: string // 店铺编码
    appId: string // 三方系统id
    storeId: string // 店铺id
    storeName: string // 店铺名称
    storeAlias: string // 店铺别名
    storeWebsite: string // 网址
    email: string // 邮箱
    authStatus: number // 店铺授权状态 1 -已授权. 0 - 未授权
    pullStatus: string // 店铺初始化拉单状态
    defaultAddressNo: string // 店铺默认地址id
    defaultLanguageCode: string // 店铺默认语言编码
    logoUrl: string // 店铺图标地址
  }
  freightCurrencyCode?: string
  // 接入方式 0:接口创建 1人工创建
  sourceType: number
  tagIds: string[]
  presignedDeliveryProofUrl?: string

  /**收货地址 */
  consigneeAddresses?: OrderAddressInfoVo[]

  /**是否即时物流 */
  onDemand: boolean
  /** 仅当为true时展示 取消关联 按钮 */
  resendOnlyViaUnbind?: boolean
  /** 面单类型 0：3PL面单类型 1：OMS面单类型 2：无须面单 3：3PL规范绘制(手工申请) CARRIER_TYPE( 0,"3PL面单类型" ),OMS_TYPE( 1,"OMS面单类型" ),NOTHING_TYPE( 2,"无须面单" ),CARRIER_TYPE_APPLY( 3,"3PL规范绘制(手工申请)" ) */
  waybillType: number
}
interface BillList {
  appid: string
  logisticsNo: string
  orderNo: string
  companyCode: ProviderType
  expressNo: string
  billUrl: string
  noPrefix: string
  presignedBillUrl: string
}
export interface ResBillType {
  waybillInfoList: BillList[]
  providers: IProviders[]
  waybillResStatus: WAYBILL_RESSTATUS
}

export enum LogisticsType {
  express = 'express',
  ondemand = 'ondemand',
  reverse = 'reverse',
}
