export const MESSAGE_RESOURCE = {
  /** 新建用户 */
  createUser: 'CREATE_USER',
  /** 批量新建 */
  batchCreate: 'BATCH_CREATE',
  /** 批量新建并发货 */
  batchCreateDeliver: 'BATCH_CREATE_DELIVER',
  /** 批量合单发货 */
  batchMergeDeliver: 'BATCH_AGG_DELIVERY',
  /** 批量更新并发货 */
  batchUpdateDeliver: 'BATCH_UPDATE_DELIVER',
  /** 批量取消 */
  batchCancel: 'BATCH_CANCEL',
  /** 单个更新并发货 */
  updateDeliver: 'UPDATE_DELIVER',
  /** 单个创建并发货 */
  createDeliver: 'CREATE_DELIVER',
  /** 接触 SL 店铺绑定 */
  storeUnbind: 'STORE_UNBIND',
  /** 绑定店铺 */
  storeBind: 'STORE_BIND',
  /** 同步拉单消息 */
  storepullOrder: 'STORE_PULL_ORDER',
  /** 手工拉单消息 */
  pullOrder: 'PULL_ORDER',
  /** 取消订单 */
  cancelDeliver: 'CANCEL_DELIVER',
  /** 批量订单备注更新 */
  updateRemark: 'UPDATE_REMARK',
  // 充值到账提醒
  walletDeposit: 'WALLET_DEPOSIT',
  // 余额到期提醒
  walletBalanceExpire: 'WALLET_BALANCE_EXPIRE',
  // 订阅套餐成功
  subSuccess: 'SUB_SUCCESS',
  // 加购权益成功
  subAddonSuccess: 'SUB_ADDON_SUCCESS',
  // 账单生成但未支付，引导用户重新支付
  subBillPending: 'SUB_BILL_PENDING',
  // 套餐即将到期并且关闭了自动续费：续费提醒
  subRecurringNotify: 'SUB_RECURRING_NOTIFY',
  // 自动续费成功通知
  subRecurringSuccess: 'SUB_RECURRING_SUCCESS',
  // 自动续费失败通知
  subRecurringFail: 'SUB_RECURRING_FAIL',
  // 轨迹查询-权益消耗到阈值提醒
  subTrackThreshold: 'SUB_TRACK_THRESHOLD',
  // 短信权益消耗到阈值提醒
  subSmsThreshold: 'SUB_SMS_THRESHOLD',
  // 套餐已过期但在宽限期内提醒购买套餐
  subExpiredNotify: 'SUB_EXPIRED_NOTIFY',
  // 批量创建追踪单
  batchCreateWaybillChecking: 'BATCH_CREATE_ORDER_TRACKING',
  // 运费纠正提醒
  walletFreightCorrect: 'WALLET_CORRECT',
  // 设置服务产品结果通知
  orderExpressTypePreferenceFilling: 'EXPRESS_TYPE_PREFERENCE_FILLING',
  // 保存店铺设置服务产品与默认地址设置结果通知
  allPreferenceFilling: 'ALL_PREFERENCE_FILLING',
  /** 门店关转通知 */
  orderStationTurnOff: 'ORDER_STATION_TURN_OFF',

  bindWooCommerceFailed: 'STORE_BIND_TOKEN_INVALID',
  /** COD账单结账通知 */
  codBillCheckOut: 'COD_BILL_CHECK_OUT',

  // 批量预合单发货
  batchPrepareMergeShip: 'BATCH_COMBINED_DELIVERY_PRE_PROCESS',

  // 批量合单发货结果
  batchMergeShipDelivery: 'BATCH_COMBINED_DELIVERY',
  /** 邮件模板审核通过 */
  EMAIL_TEMPLATE_AUDIT_SUCCESS: 'EMAIL_TEMPLATE_AUDIT_SUCCESS',
  /** 邮件模板审核失败 */
  EMAIL_TEMPLATE_AUDIT_FAIL: 'EMAIL_TEMPLATE_AUDIT_FAIL',
  /** 短信模板审核通过 */
  SMS_TEMPLATE_AUDIT_SUCCESS: 'SMS_TEMPLATE_AUDIT_SUCCESS',
  /** 短信模板审核失败 */
  SMS_TEMPLATE_AUDIT_FAIL: 'SMS_TEMPLATE_AUDIT_FAIL',
  /** 追踪页模板审核通过 */
  TRACK_TEMPLATE_AUDIT_SUCCESS: 'TRACK_TEMPLATE_AUDIT_SUCCESS',
  /** 追踪页模板审核失败 */
  TRACK_TEMPLATE_AUDIT_FAIL: 'TRACK_TEMPLATE_AUDIT_FAIL',
  /** 邮件模板被禁用 */
  EMAIL_TEMPLATE_BAN: 'EMAIL_TEMPLATE_BAN',
  /** 邮件模板解除禁用 */
  EMAIL_TEMPLATE_UNBAN: 'EMAIL_TEMPLATE_UNBAN',
  /** 短信模板被禁用 */
  SMS_TEMPLATE_BAN: 'SMS_TEMPLATE_BAN',
  /** 短信模板解除禁用 */
  SMS_TEMPLATE_UNBAN: 'SMS_TEMPLATE_UNBAN',
  /** 追踪页模板被禁用 */
  TRACK_TEMPLATE_BAN: 'TRACK_TEMPLATE_BAN',
  /** 追踪页模板解除禁用 */
  TRACK_TEMPLATE_UNBAN: 'TRACK_TEMPLATE_UNBAN',
  // 呼叫揽收结果
  callPickup: 'CALL_PICKUP',

  // 取消呼叫揽收结果
  cancelPickup: 'CANCEL_PICKUP',

  FREIGHT_BILL_PAY_NOTIFY: 'FREIGHT_BILL_PAY',
  // saas
  SUBSCRIBE_SAAS: 'SUB_SAAS_THRESHOLD',

  // cash on delivery count
  SUBSCRIBE_FREIGHT_COUNT: 'SUB_FREIGHT_THRESHOLD',
  // 审核通知
  SELLER_KYC_APPROVAL_COMPLETE: 'SELLER_KYC_APPROVAL_COMPLETE',

  // 更新揽收
  updatePickup: 'UPDATE_PICKUP',

  /** 逾期转宅配 */
  ORDER_EXPIRE_TO_HOME: 'ORDER_EXPIRE_TO_HOME',
  /** 添加优先费失败 */
  ADD_PRIORITY_FEE_FAIL: 'ADD_PRIORITY_FEE_FAIL',
  /** 已分配司机 */
  COURIER_ASSIGNMENT: 'COURIER_ASSIGNMENT',
  /** 即时物流优先费补扣 */
  PRIORITY_FEE_DEDUCTION: 'PRIORITY_FEE_DEDUCTION',
  /** 即时物流优先费返还 */
  PRIORITY_FEE_RETURN: 'PRIORITY_FEE_RETURN',
  /** 运费返还 */
  FREIGHT_CORRECT_RETURN: 'FREIGHT_CORRECT_RETURN',
  // 销售成单协议审核通知，已下线，但为了展示历史数据而保留
  privacy: 'SALES_CONFIG_RISK_REVIEW_RESULT',
  /** 用户领取优惠成功 */
  OS_GIVEAWAY_SUCCEED: 'OS_GIVEAWAY_SUCCEED',
  /** 用户未参与活动提醒 */
  OS_GIVEAWAY_NOT_JOIN: 'OS_GIVEAWAY_NOT_JOIN',
  /** 用户活动参与成功，未消费 */
  OS_GIVEAWAY_NOT_PAY: 'OS_GIVEAWAY_NOT_PAY',
  /** 用户活动参与失败 */
  OS_GIVEAWAY_FAILED: 'OS_GIVEAWAY_FAILED',
  /** 合同签约成功 */
  OS_CONTRACT_SIGNED_STATUS: 'OS_CONTRACT_SIGNED_STATUS',
  /** 申请面单 */
  APPLY_WAYBILL_RESULT: 'APPLY_WAYBILL_RESULT',
  /** 调账 */
  ADJUST_ACCOUNT: 'ADJUST_ACCOUNT',
}

export const EMAIL_TEMPLATE_SOURCE_LIST = [
  MESSAGE_RESOURCE.EMAIL_TEMPLATE_AUDIT_SUCCESS,
  MESSAGE_RESOURCE.EMAIL_TEMPLATE_AUDIT_FAIL,
  MESSAGE_RESOURCE.EMAIL_TEMPLATE_BAN,
  MESSAGE_RESOURCE.EMAIL_TEMPLATE_UNBAN,
]

export const SMS_TEMPLATE_SOURCE_LIST = [
  MESSAGE_RESOURCE.SMS_TEMPLATE_AUDIT_SUCCESS,
  MESSAGE_RESOURCE.SMS_TEMPLATE_AUDIT_FAIL,
  MESSAGE_RESOURCE.SMS_TEMPLATE_BAN,
  MESSAGE_RESOURCE.SMS_TEMPLATE_UNBAN,
]

export const TRACK_TEMPLATE_SOURCE_LIST = [
  MESSAGE_RESOURCE.TRACK_TEMPLATE_AUDIT_SUCCESS,
  MESSAGE_RESOURCE.TRACK_TEMPLATE_AUDIT_FAIL,
  MESSAGE_RESOURCE.TRACK_TEMPLATE_BAN,
  MESSAGE_RESOURCE.TRACK_TEMPLATE_UNBAN,
]

export const OPERATE_STATUS = {
  /** 成功 */
  success: 'SUCCESS',
  /** 失败 */
  fail: 'FAIL',
}
