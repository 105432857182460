import API from '@/services/http'
import type * as Types from './type'

/**
 * 分页查询商家渠道产品配置列表
 * @param data
 * @returns
 */
export const sellerConfigPage = (data: Types.ConfigPageReq) => API.admin.post<Types.ConfigPageRes>(`/oneclub-api/api/seller/express/type/config/page`, data)

/**
 * 新增商家渠道产品配置
 * @param data
 * @returns
 */
export const sellerConfigCreate = (data?: Types.ConfigCreateReq) => API.toast.post<Types.ConfigCreateRes>(`/api/seller/express/type/config/create`, data)

/**
 * 更新商家渠道产品配置
 * @param data
 * @returns
 */
export const sellerConfigUpdate = (data?: Types.ConfigCreateReq) => API.toast.post<Types.ConfigCreateRes>(`/api/seller/express/type/config/update`, data)

/**
 * 新增saas服务产品配置时，发送短信获取 OTP
 * @param data
 * @returns
 */
export const sellerConfigSendMessage = (data?: Types.ConfigSendMessageReq) => API.toast.post<Types.ConfigSendMessageRes>(`/api/seller/express/type/config/send_message`, data)

/**
 * **接口** [新增商家自定义包裹尺寸↗](http://api-doc-sl.inshopline.com/#/project/74/feature%2F20221201_1750_au_station/interface/47bf816c953e711ee5ba85d7938115dd)
 * @请求头 `POST /api/seller/package/config/create`
 * @更新时间 `2022-11-04 10:06:19`
 */
export function createPackageConfig(data?: Types.SellerPackageConfigCreateRequest) {
  return API.toast.post(`/api/seller/package/config/create`, data)
}

/**
 * **接口** [获取商家自定义包裹尺寸↗](http://api-doc-sl.inshopline.com/#/project/74/feature%2F20221201_1750_au_station/interface/c14eb68fff6a132cc40aa81be2132ee8)
 * @请求头 `POST /api/seller/package/config/get`
 * @更新时间 `2022-11-04 10:06:19`
 */
export function getPackageConfigList(data?: Types.SellerPackageConfigGetRequest) {
  return API.toast.post<Types.ConfigGetRes>(`/api/seller/package/config/get`, data)
}

/** delete the package size config */
export const deletePackageConfigById = (id: string) => {
  return API.toast.post('/api/seller/package/config/delete', {
    id,
  })
}

/**
 * 商家是否至少有一个商家授权的服务产品
 * @returns
 */
export const hasSellerAuthExpressType = () => {
  return API.toast.post<{ hasSellerAuthExpressType: boolean; hasAppApply: boolean }>('/api/seller/express/type/auth/valid')
}

/** 删除sass账号 */
export const deleteSassAccount = (configSeqs: string | string[]) => {
  return API.toast.post('/oneclub-api/api/seller/express/type/config/unbind', {
    configSeqList: Array.isArray(configSeqs) ? configSeqs : [configSeqs],
  })
}
