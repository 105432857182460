import type { OptionsType } from '@/store/oneshipOrderSlice/type'

export const PLATFORM_KEYS = {
  shopline: 'SL101',
  shopify: 'SPF01',
  oneship: 'ONE01',
  woocommerce: 'WOC01',
  /**
   * shopline 跨境
   */
  shopline2: 'SL201',
  easystore: 'EZS01',
  wix: 'WIX01',
  sqp: 'SQP01',
  sales: 'SALES01',
  shopee: 'SHP01',
} as const

export const PLATFORM_APP_NAME = {
  [PLATFORM_KEYS.shopline]: 'SHOPLINE',
  [PLATFORM_KEYS.shopify]: 'Shopify',
  [PLATFORM_KEYS.woocommerce]: 'WooCommerce',
  [PLATFORM_KEYS.shopline2]: 'SHOPLINE',
  [PLATFORM_KEYS.oneship]: 'ONESHIP',
  [PLATFORM_KEYS.easystore]: 'Easystore',
  [PLATFORM_KEYS.wix]: 'Wix',
  [PLATFORM_KEYS.sqp]: 'SquareSpace',
  [PLATFORM_KEYS.shopee]: 'Shopee',
} as const

export const PLATFORM_OPTIONS: OptionsType[] = [
  {
    label: 'SHOPLINE',
    value: `${PLATFORM_KEYS.shopline},${PLATFORM_KEYS.shopline2}`,
  },
  {
    label: 'Shopify',
    value: PLATFORM_KEYS.shopify,
  },
  {
    label: 'WooCommerce',
    value: PLATFORM_KEYS.woocommerce,
  },
]
