export enum ENUM_FILTER_TYPE {
  /**
   * 货到付款
   */
  COD = '1',
  /**
   * 保险
   */
  INSURANCE = '2',
}

/** 服务产品支持的签收服务类型 */
export enum ENUM_SIGN_SERVICE_TYPE {
  /** 部分签收 */
  SIGN_PART = 'SIGN_PART',
  /** 支持Authority to leave */
  AUTHORITY_TO_LEAVE = 'AUTHORITY_TO_LEAVE',
  SIGN_ON_DELIVERY = 'SIGN_ON_DELIVERY',
  SAFE_PLACE = 'SAFE_PLACE',
}

/** 自定义运费 */
export enum CUSTOM_FREIGHT_REASON_ENUM {
  /** oneship 计算方式错误  */
  // 提示文本：费率模板失效，请联系客户成功团队配置模板
  ONESHIP_ERROR = 'LST-123672-B1142',
  /** 未配置运费规则/或者未开启 */
  IGNORE = 'LST-123672-B1140',
  /** 命中 */
  // 提示文本：计费失败，请检查您的自定义运费设置
  NO_IN_RANGE = 'LST-123672-B1141',
  // 提示文本：请检查代收货款与运费币种是否一致
  CAN_NOT_USE = 'FREIGHT-123672-B1141',
}
