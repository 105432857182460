import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OneModal } from '@yy/one-ui'
import { getStationListByOrderNo } from '@/services/apis/station'
import { useSelector } from '@/store'
import type { StationListItem } from '@/store/addressSlice/types'
import styles from './index.module.less'

interface IGetStationParams {
  stationCode: string
  orderNo?: string
  stationName?: string
  stationSeq?: string
}

interface IStationConfig {
  /**
   * 是否显示无匹配时的取消按钮
   */
  cancelButton?: boolean
}

export function useGetStation(config?: IStationConfig) {
  const { cancelButton = true } = config ?? {}
  const { t } = useTranslation()
  const stationTypes = useSelector((state) => state?.address?.stationTypes || [])

  const stationMap = useMemo(() => {
    return new Map(stationTypes.map((item) => [item.key.toString(), item]))
  }, [stationTypes])

  const handleGetStation = (params: IGetStationParams): Promise<null | StationListItem> => {
    const { stationCode, stationName, stationSeq } = params
    return new Promise(async (resolve, reject) => {
      const promise = getStationListByOrderNo(params)
      promise.then((res) => {
        const list = res.data.data
        const matchType = res.data?.matchType
        const text = `${stationCode}-${stationName || ''}`
        let modal
        // 根据门店编码精准查询才能直接回填
        if (list?.length && list.length === 1 && matchType === 'exact_match') {
          resolve(list[0])
        } else if (list.length) {
          // 如果找到了跟传入 stationCode 一致的门店
          const matchStation = list.find((item) => item.stationSeq === stationSeq)

          if (matchStation) {
            return resolve(matchStation)
          }

          const handleResolve = (data) => {
            resolve(data)
            modal.destroy()
          }

          const renderContent = (list?: StationListItem[]) => (
            <div>
              <div className={styles.title}>{t('order.list.shipConfirm.stations', { text })}</div>

              <div className={styles.list}>
                {Array.isArray(list)
                  ? list.map((item, index) => {
                      const stationType = stationMap.get(item.stationType.toString())

                      const key = [item.stationType, item.stationName, item.stationCode, index].join(',')
                      const onClick = () => handleResolve(item)
                      return (
                        <div className={styles.listItem} key={key} onClick={onClick}>
                          {item?.companyName}-{stationType?.value}-{item.stationName}-{item.stationCode}
                        </div>
                      )
                    })
                  : null}
              </div>
            </div>
          )

          modal = OneModal.confirm({
            className: styles['address-choose-modal'],
            centered: true,
            icon: null,
            okButtonProps: {
              style: {
                display: 'none',
              },
            },
            width: 432,
            content: renderContent(list),
            cancelText: t('common.cancelBtn'),
            onCancel: () => reject(null),
          })
        } else {
          OneModal.confirm({
            // className: styles['confirm-modal'],
            centered: true,
            icon: null,
            title: t('orderDetail.disassociateTit'),
            content: t('order.list.shipConfirm.station.null', { text }),
            cancelButtonProps: {
              style: {
                display: cancelButton ? 'inline-block' : 'none',
              },
            },
            okText: t('common.confirmBtn'),
            cancelText: t('common.cancelBtn'),
            onCancel: () => reject(null),
            onOk: () => resolve(null),
          })
        }
      })
    })
  }

  return {
    handleGetStation,
  }
}
