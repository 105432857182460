import styles from './index.module.less'
import { LANGUAGE } from '@/constant'
import { getNotifyList } from '@/services/apis'
import { useDispatch, useSelector } from '@/store'
import { notificationActions } from '@/store/notificationSlice'
import { windowOpen } from '@/utils/browser'
import { getUrlParam } from '@/utils'
import { useBoolean, useRequest } from 'ahooks'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

type INotifyType = Record<
  string,
  {
    contentType: string | null
    picture: string | null
    notifyLink: string | null
    messageNotifyId?: string
    messageContent: string
    messageTitle: string
    /**文本样式类型 */
    textStyleType?: 'all_text' | 'left_img_right_text'
    /**文本展示类型 */
    textDisplayType?: 'hover' | 'default'
  }
>

const langs = LANGUAGE.map((item) => item.languageType)

const NotifyCard: React.FC = () => {
  const hasNotifyCount = useSelector((state) => state.notification.hasNotifyCount)
  const language = useSelector((state) => state.language.languageType)
  const interval = useSelector((state) => Number(state.configSlice.swipeInterval))
  const dispatch = useDispatch()

  const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

  const [isHover, hoverActions] = useBoolean()

  /** 数据操作相关 */
  const [notifyList, setNotifyList] = useState<INotifyType[]>([])
  const { run: runQueryList } = useRequest(
    () => {
      const params = {
        notifyType: 'overview',
        pageNum: 1,
        pageSize: 999,
      }
      return getNotifyList(params)
    },
    {
      manual: true,
      onSuccess(res) {
        const { data } = res.data
        const transData = data.map((item) => {
          const { contentType, notifyLink, messageContent, messageTitle, picture, localeContent, localeTitles, localePicture, textDisplayType, textStyleType } = item
          const contentMap = {}
          const titleMap = {}
          const pictureMap = {}

          if (Array.isArray(localeContent)) {
            localeContent.forEach(({ key, value }) => {
              contentMap[key] = value
            })
          }

          if (Array.isArray(localeTitles)) {
            localeTitles.forEach(({ key, value }) => {
              titleMap[key] = value
            })
          }

          if (Array.isArray(localePicture)) {
            localePicture.forEach(({ key, pc }) => {
              pictureMap[key] = pc
            })
          }

          // 默认语言通知内容
          const tmp = {
            default: {
              messageTitle,
              messageContent: messageContent,
              contentType,
              picture: picture?.pc,
              notifyLink,
              textDisplayType,
              textStyleType,
            },
          }

          langs.forEach((lang) => {
            if (contentMap[lang] || titleMap[lang] || pictureMap[lang]) {
              tmp[lang] = {
                contentType,
                notifyLink,
                textDisplayType,
                textStyleType,
                messageTitle: titleMap[lang],
                messageContent: contentMap[lang],
                picture: pictureMap[lang],
              }
            }
          })

          return tmp
        })
        setNotifyList(transData)
      },
    }
  )

  const filterList = useMemo(() => {
    return notifyList.map((item) => {
      return item[language] || item['default']
    })
  }, [language, notifyList])

  const [swipeInterval, setSwipeInterval] = useState(0)
  const [activeStep, setActiveStep] = useState(0)
  const handleStepChange = (step: number) => {
    setActiveStep(step)
  }

  useEffect(() => {
    if (interval) {
      setSwipeInterval(interval * 1000)
      runQueryList()
    }
  }, [interval, runQueryList])

  // 收到socket通知
  useEffect(() => {
    if (!hasNotifyCount) return
    runQueryList()
    dispatch(notificationActions.setHasNotifyCount(false))
  }, [hasNotifyCount, runQueryList, dispatch])

  if (filterList.length === 0) {
    return null
  }

  // 是否自动轮播（性能跑分场景下关掉自动轮播）
  const isAutoPlay = getUrlParam('notAutoPlay') ? false : !isHover

  return (
    <div className={styles.notifyCard}>
      <div className={styles.wrapper} onMouseLeave={hoverActions.setFalse} onMouseEnter={hoverActions.setTrue}>
        <AutoPlaySwipeableViews autoplay={isAutoPlay} index={activeStep} onChangeIndex={handleStepChange} interval={swipeInterval} enableMouseEvents>
          {filterList.map((step, index) => {
            const content = (
              <>
                <div className={styles.title}>{step.messageTitle}</div>
                <div className={classNames(styles.html, styles.textContent)} dangerouslySetInnerHTML={{ __html: step.messageContent }}></div>
              </>
            )

            return (
              <div className={classNames(styles.swipeItem)} key={index}>
                {step.textStyleType === 'left_img_right_text' || step.contentType === 'picture' ? (
                  <div className={classNames(styles.swipeItemWrap, styles.swipeItemImageWrap)}>
                    <span className={styles.image} onClick={() => step.notifyLink && windowOpen(step.notifyLink, '_blank')}>
                      {step.picture && <img src={step.picture} />}
                      {(step.textDisplayType === 'hover' ? isHover : true) &&
                        step.contentType !== 'picture' &&
                        step.textStyleType === 'left_img_right_text' &&
                        (step.messageContent || step.messageTitle) && (
                          <div className={styles.messageBox} onClick={(e) => e.stopPropagation()}>
                            {content}
                          </div>
                        )}
                    </span>
                  </div>
                ) : (
                  <div className={styles.content}>{content}</div>
                )}
              </div>
            )
          })}
        </AutoPlaySwipeableViews>
        {notifyList.length > 1 ? (
          <div className={styles.stepper}>
            {notifyList.map((item, index) => (
              <div className={styles.stepperBox} key={index} onClick={() => setActiveStep(index)}>
                <span
                  className={classNames({
                    [styles.stepItem]: true,
                    [styles.isActive]: activeStep === index,
                  })}
                />
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

NotifyCard.displayName = 'NotifyCard'
export default React.memo(NotifyCard)
