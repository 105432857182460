import Cookies from 'js-cookie'
import { APP_CODE } from '@/containers/User/constants'

const app_id = 'Oneship'

export const slTraceInit = () => {
  if (process.env.NODE_ENV === 'development') return
  if (process.env.APP_ENV !== 'product') return

  const df = window.DeviceFingerprint({ env: 'pro', appCode: APP_CODE })
  const uid = Cookies.get('osudb_uid')

  window?.slq('init', {
    options: {
      debugMode: false,
      beforeSend: (reportParams: any) =>
        // B端接入风控的SDK
        df?.getDeviceInfo().then((res: any) => ({
          ...reportParams,
          device_token: res.token,
          ua_info: res.deviceInfo,
        })),
    },
    params: {
      app_id, // 应用id
      user_id: uid, // 用户id
      // 数据概览分析需要带上sltrace定义的env标示
      env: 'PRO',
    },
  })
}
