import type { SVGProps } from 'react'
import React from 'react'
import withIcon from './hoc/withIcon'

const MenuActive: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width="4" height="20" viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 20V20C2.20914 20 4 18.2091 4 16L4 4C4 1.79086 2.20914 -5.96046e-07 0 -5.96046e-07V-5.96046e-07L0 20Z" fill="#02C8C6" />
    </svg>
  )
}

MenuActive.displayName = 'MenuActive'

export default withIcon(MenuActive)
