import React from 'react'
import Icon from '@ant-design/icons'

type IconComType = React.ComponentProps<typeof Icon>

function withIcon(Component: React.FC): React.FC<IconComType> {
  if (typeof Component === 'string') return () => <img src={Component} height={24} />
  return (props) => {
    return <Icon component={Component} {...props} />
  }
}

export default withIcon
