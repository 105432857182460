// 手动上报错误
import * as Sentry from '@sentry/react'

export const sentryReport = (messageOrError: Error | string) => {
  const error = messageOrError instanceof Error ? messageOrError : new Error(messageOrError)
  Sentry.captureException(error, (scope) => {
    scope.setTag('errorType', 'scriptReport')
    scope.addBreadcrumb({
      type: 'error',
      level: Sentry.Severity.Error,
      category: 'stack',
      message: error.stack.slice(0, 300),
    })
    return scope
  })
}
