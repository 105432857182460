import { isPromise } from './isPromise'

type HandlePromiseError = {
  message: string
  bizCode?: string
} | null
/**
 * @deprecated
 * @param promise
 */
async function handlePromise(promise: any): Promise<[HandlePromiseError, any]>
async function handlePromise<P>(promise: Promise<P>): Promise<[HandlePromiseError, P?]>
async function handlePromise<P>(promise: Promise<P>): Promise<[HandlePromiseError, P?]> {
  if (!isPromise(promise)) {
    throw new Error('Param is invalid promise')
  }

  try {
    const data = await promise
    if (typeof data === 'undefined') {
      return [{ message: 'Failed to obtain data' }]
    }

    return [null, data]
  } catch (error) {
    const { bizMessage, message } = error
    return [{ ...error, message: bizMessage || message }]
  }
}

export default handlePromise
export { handlePromise }
