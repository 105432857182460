import { useRequest } from 'ahooks'
import { useTranslation } from 'react-i18next'
import { OneMessage } from '@yy/one-ui'
import { preCheckBindStore } from '@/services/apis'
import type { ICheckBindParams } from '@/services/apis'
import { useValidateStoreAccountLimit } from './subscribe/useValidateAccountLimit'

export type { IAddressMatchResVo, IAddressMatchVo, IHandleConfirmMatchAddress } from '@/services/apis'

interface CheckOptions {
  /** 是否由调用方处理某些状态（通过捕获错误），而不toast */
  handleBindState?: string[]
}
const defaultCheckOptions: CheckOptions = {
  handleBindState: [],
}

export function usePreCheckBind() {
  const { t } = useTranslation()
  const { validateStoreAccount } = useValidateStoreAccountLimit()

  const { run: runCheckBind } = useRequest(
    (params: ICheckBindParams, { shop }: { shop: string }, options?: CheckOptions) => {
      const { handleBindState } = options || defaultCheckOptions
      if (params.storeWebSite) {
        params.storeWebSite = params.storeWebSite?.replace(/^(?:https?:\/\/)?(.*)/, 'https://$1').replace(/\/*?$/, '')
      }
      return preCheckBindStore(params).then((res) => {
        const { data, bizMessage, message } = res
        const { bindState } = data

        if (bindState === 'NONE') return Promise.resolve()

        switch (bindState) {
          case 'NONE':
            // 可以跳转授权
            return Promise.resolve()
          case 'REPEATED':
            // 重复绑定当前账号
            if (!handleBindState.includes(bindState)) OneMessage.error(t('auth.checkShopfiyStoreBinded', { handle: shop }))
            return Promise.reject(bindState)
          case 'OCCUPIED':
            // 店铺已被另外一个账号绑定
            if (!handleBindState.includes(bindState)) OneMessage.error(t('auth.checkShopifyStoreRepeat', { handle: shop }))
            return Promise.reject(bindState)
          case 'OVER':
            // 超过上限
            if (!handleBindState.includes(bindState)) validateStoreAccount()
            return Promise.reject(bindState)
          case 'WEBSITE_INVALID':
            // 商店链接不正确
            if (!handleBindState.includes(bindState)) OneMessage.error(t('shopify.url.error'))
            return Promise.reject(bindState)
          default:
            OneMessage.error(bizMessage || message)
            return Promise.reject()
        }
      })
    },
    {
      manual: true,
    }
  )

  return {
    runCheckBind,
  }
}
