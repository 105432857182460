import React from 'react'
import styles from './index.module.less'

export const LinearProgress: React.FC = () => {
  return (
    <div className={styles.root}>
      <div className={styles.bar}></div>
    </div>
  )
}

LinearProgress.displayName = 'LinearProgress'
