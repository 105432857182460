class EventBus {
  private listeners: Record<string, FnType[]> = {}

  listen(type: string, cb: FnType) {
    this.listeners[type] = (this.listeners[type] || []).concat(cb)
    window.addEventListener(type, cb, false)
  }

  unListen(type: string, cb: FnType) {
    const cbList = this.listeners[type] || []

    if (!cbList.length) return false

    const index = cbList.findIndex((item) => item === cb)

    if (index > -1) {
      cbList.splice(index, 1)
      window.removeEventListener(type, cb)
    }

    this.listeners[type] = cbList

    return true
  }

  clear() {
    Object.entries(this.listeners).forEach(([key, cbs]) => {
      cbs.forEach((cb) => {
        window.removeEventListener(key, cb)
      })
    })
  }

  send(type: string, data: any) {
    window.dispatchEvent(
      new CustomEvent(type, {
        detail: data,
      })
    )
  }

  sendWithOpener(type: string, data: any) {
    window.opener?.dispatchEvent(
      new CustomEvent(type, {
        detail: data,
      })
    )
  }
}

export default new EventBus()

export const MESSAGE_TYPE = 'station711MapDataReceive'
