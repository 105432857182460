import { ONECLUB_URL } from '@/constant/url'
import { getLocalLangLang, getUrlParam, stringifyQuery } from '@/utils'

import { windowOpen } from './browser'
import { countryConfig } from './countryVersion'
import Cookies from 'js-cookie'
import store from '@/store'

export const OneClubPathMap = {
  login: '/user/login',
  resetPwd: '/user/changePassword',
}

export const paramKey = ['ticket', 'loginCode']

/**
 * 优先从链接上取adminTicket，否则从cookie取
 * @returns
 */
export function getAdminTicket() {
  return new URL(location.href).searchParams.get('adminTicket') || Cookies.get('authorization')
}

export function getOneClubUrl(path: string, scene?: string, extraQuery?: Record<string, any>) {
  const lang = getLocalLangLang()
  const domain = process.env.ONECLUB_URL || 'https://accounts.oneclub.vip'

  let service = window.location.href
  if (['logout', 'error', 'user'].includes(scene)) {
    service = `${window.location.origin}/dashboard`
  }
  if (scene === 'bind') {
    const newUrl = new URL(window.location.href)
    paramKey.forEach((item) => newUrl.searchParams.delete(item))
    service = newUrl.href
  }
  const authorization = getAdminTicket()
  if (authorization) {
    const newUrl = new URL(service)
    newUrl.searchParams.append('adminTicket', authorization)
    service = newUrl.href
  }
  const utm_source = getUrlParam('utm_source')
  const url = stringifyQuery(domain + path, {
    ...(utm_source ? { utm_source } : {}),
    ...extraQuery,
    app: countryConfig?.account_appId || 'os',
    lang,
    service: encodeURIComponent(service),
    ...(['logout', 'error', 'bind'].includes(scene) && {
      scene: 'logout',
    }),
  })
  return url
}

/**
 * 跳转到oneClub登录
 * @param args
 */
export function hrefToOneClubUrl(...args: Parameters<typeof getOneClubUrl>) {
  const url = getOneClubUrl(...args)
  Cookies.remove('authorization')
  window.location.href = url
}
/**
 * 跳转到oneClub登录(使用replace)
 * @param args
 */
export function replaceToOneClubUrl(...args: Parameters<typeof getOneClubUrl>) {
  const url = getOneClubUrl(...args)
  Cookies.remove('authorization')
  window.location.replace(url)
}

/**
 * 超管登录商家
 * 对于前端来讲就相对于是商家自己登录，除了cookie里面的uid是超管的
 */
export function adminLogin() {
  const url = new URL(window.location.href)
  const adminTicket = url.searchParams.get('adminTicket')
  if (!adminTicket) return
  url.searchParams.delete('adminTicket')
  window.history.replaceState(null, null, url)
  Cookies.set('authorization', adminTicket)
}

/**
 * 跳转到oneclub，携带adminTicket和lang
 * @param key
 * @param replace
 */
export function jumpToOneclub(key: keyof typeof ONECLUB_URL, replace?: boolean) {
  let link = ONECLUB_URL[key]
  const url = new URL(link)

  const authorization = getAdminTicket()
  if (authorization) url.searchParams.append('adminTicket', authorization)

  const lang = getLocalLangLang()
  url.searchParams.append('lang', lang)

  link = url.href
  return replace ? window.location.replace(link) : windowOpen(link)
}

/**
 * 打开onegoods
 * @returns
 */
export function openProduct() {
  const language = store.getState().language.lang
  let url = `${process.env.DOMAIN_PRODUCT}/?language=${language}`
  const adminTicket = getAdminTicket()
  if (adminTicket) url = `${url}&adminTicket=${adminTicket}`
  windowOpen(url)
}
