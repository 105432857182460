import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DURATION_TYPES, PRODUCT_LINE, REF_OBJECT } from '@/constant'
import type { Subscription } from '@/services/apis/subscribe/type'
import { useSelector } from '@/store'

/**
 * 判断套餐是否可以升级
 * 定制套餐、最高套餐年付两种情况不能升级
 * @param subscriptionData
 * @returns
 */
export const cannotUpgrade = (subscriptionData: Subscription) =>
  subscriptionData?.customized || (subscriptionData?.productLine === PRODUCT_LINE.os_dy_pro && subscriptionData?.durationType === DURATION_TYPES.year)

export const useCannotUpgrade = () => {
  const { t } = useTranslation()
  const subStatus = useSelector((state) => state.subscribeSlice?.subStatus)
  const subscription = subStatus?.subscription || (REF_OBJECT as unknown as Subscription)
  // 定制套餐、最高套餐年付两种情况不能升级
  const isCannotUpgrade = useMemo(() => {
    return cannotUpgrade(subscription)
  }, [subscription])

  // 引导升级弹窗-提示文本
  const noAuthorizedTips = isCannotUpgrade ? t('subscribe.trackingSetting.contactCustomerService') : t('subscribe.trackingSetting.notAuthorizedTips')
  // 引导升级弹窗-确认按钮文本
  const okText = isCannotUpgrade ? t('common.contactService') : t('frame.upgrade')
  // 引导升级弹窗-取消按钮文本
  const cancelText = isCannotUpgrade ? t('frame.cancel') : t('subscribe.notUpgrade')
  return {
    isCannotUpgrade,
    noAuthorizedTips,
    okText,
    cancelText,
  }
}
