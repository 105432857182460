import { UseRequestProvider } from 'ahooks'
import { ConfigProvider } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { changeLanguage } from '@yy/one-ui'
import GlobalUpdateModal from '@/bizComponent/GlobalUpdateModal'
import WatchRouter from '@/bizComponent/WatchRouter/index'
import { ScopeProvider, SentryConfiguration } from '@/components'
import SourceLoadError from '@/components/SourceLoadError'
import GlobalToast from '@/components/Toast'
import { useLangPolyfill } from '@/hooks'
import Router from '@/routers'
import { useSelector } from '@/store'

const App: React.FC = () => {
  const languageStore = useSelector((state) => state.language)
  const [antdLocale, setantdLocale] = useState<any>({})

  useLangPolyfill()

  const changeLanguageCallback = useCallback((lang) => {
    if (localStorage.getItem('os-show-me-language') && lang === 'no_NO') {
      return
    }

    // antd组件加载locale文件
    Promise.all([import(`antd/lib/locale/${lang}.js`)])
      .then((res: any[]) => {
        const [localeRes] = res || []
        const locale = localeRes?.default || {}
        if (Object.keys(locale).length) {
          setantdLocale(locale)
        } else {
          return Promise.reject(`[antd] 语言包{${lang}}加载失败`)
        }
      })
      .catch((e) => {
        /* eslint-disable no-console  */
        console.error(e)
      })
  }, [])

  useEffect(() => {
    changeLanguageCallback(languageStore.antd)
    changeLanguage(languageStore.languageType as any)
  }, [changeLanguageCallback, languageStore])

  return (
    <SentryConfiguration>
      <ConfigProvider autoInsertSpaceInButton={false} locale={antdLocale}>
        <ScopeProvider>
          <BrowserRouter>
            {/* useRequest 全配置 */}
            <UseRequestProvider
              value={{
                manual: true,
                throwOnError: true,
              }}
            >
              <GlobalToast />
              <SourceLoadError />
              <GlobalUpdateModal />
              <WatchRouter />
              <Switch>
                <Router />
              </Switch>
            </UseRequestProvider>
          </BrowserRouter>
        </ScopeProvider>
      </ConfigProvider>
    </SentryConfiguration>
  )
}

App.displayName = 'App'
export default Sentry.withProfiler(App)
