import type * as TYPES from './type'
import API from '@/services/http'
import type { FeePayload, FeeState } from '@/store/serviceProviderSlice/types'

/**
 * 运费试算
 * @param payload
 * @returns
 */
export const getFeeList = async (payload: FeePayload, token = 'getFeeList') => {
  const res = await (token ? API.toast.takeLatest<FeeState>(token, 'post', '/api/fee/trial/calculate', payload) : API.toast.post<FeeState>('/api/fee/trial/calculate', payload))

  try {
    res.data?.shippingFeeResults?.forEach((item) => {
      item?.shippingResults?.forEach((result) => {
        try {
          result.channelCustomParamParse = JSON.parse(result.channelCustomParam)
        } catch (error) {}
      })
    })
  } catch (error) {}
  return res
}

/** 新增商家运费配置 */
export const feeSettingAdd = (payload: TYPES.OneshipFreightSettingVO) => {
  return API.toast.post('/api/fee/setting/add', {
    data: payload,
  })
}

/** 删除商家运费配置 */
export const feeSettingDelete = (settingSeq: string) => {
  return API.toast.post('/api/fee/setting/delete', { settingSeq })
}

/** 更新商家运费配置 */
export const feeSettingUpdate = (payload: TYPES.OneshipFreightSettingVO) => {
  return API.toast.post('/api/fee/setting/update', {
    data: payload,
  })
}

/** 分页查询商家运费配置 */
export const feeSettingList = () => {
  return API.toast.post<TYPES.SettingPageRes>('/api/fee/setting/page', {
    pageNum: 1,
    pageSize: 999,
  })
}

/** 商家自定义配置，服务产品列表 */
export const feeSettingExpressList = () => {
  return API.toast.post<TYPES.FreightSettingExpressRes>('/api/fee/setting/express/list')
}
