export const DATE_RANGE_OPTIONS = [
  {
    /** 最近30天 */
    label: 'dashboard.select.recently',
    value: 30,
  },
  {
    /** 最近60天 */
    label: 'dashboard.select.recently',
    value: 60,
  },
  {
    /** 最近90天 */
    label: 'dashboard.select.recently',
    value: 90,
  },
]

export const LOGISTICS_STATS_OPTIONS = [
  {
    /** 待发货 */
    label: 'dashboard.status.pending',
    valueKey: 'pendingDispatchCount',
  },
  {
    /** 未揽收 */
    label: 'dashboard.status.unPickup',
    valueKey: 'unPickupCount',
  },
  {
    /** 运输中 */
    label: 'dashboard.status.inTransit',
    valueKey: 'inTransitCount',
  },
  {
    /** 已签收 */
    label: 'dashboard.status.delivered',
    valueKey: 'deliveredCount',
  },
  {
    /** 退件 */
    label: 'dashboard.status.return',
    valueKey: 'returnCount',
  },
]

export const ORDER_STATS_OPTIONS = [
  {
    /** 待发货 */
    label: 'dashboard.status.pending',
    valueKey: 'pendingDispatchCount',
  },
  {
    /** 已发货 */
    label: 'dashboard.status.shipped',
    valueKey: 'orderReceivedCount',
  },
  {
    /** 已签收 */
    label: 'dashboard.status.delivered',
    valueKey: 'deliveredCount',
  },
  {
    /** 已退货 */
    label: 'dashboard.status.returned',
    valueKey: 'returnedCount',
  },
]

export enum TimeRangeTypeEnum {
  LOGISTICS = 1,
  ORDER = 2,
}

export enum OpenIntercomSource {
  /** 合同签约 */
  oaSign = 'oaSign',
}
