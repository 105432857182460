import { useDebounceFn } from 'ahooks'
import { useCallback } from 'react'
import { useDispatch, useSelector } from '@/store'
import { setProgress as setProgressAction } from '@/store/commonSlice'

interface ProgressProps {
  setProgress: (state: boolean) => void
  progressState: boolean
}

export function useProgress(): ProgressProps {
  const dispatch = useDispatch()
  const { run } = useDebounceFn(
    () => {
      return dispatch(setProgressAction({ progress: false }))
    },
    { wait: 3000 }
  )

  const setProgress = useCallback(
    (state: boolean) => {
      dispatch(setProgressAction({ progress: state }))
      state && run()
    },
    [dispatch, run]
  )

  return {
    setProgress,
    progressState: useSelector((state) => state.common.progress),
  }
}
