import Cookies from 'js-cookie'
import { useCallback } from 'react'
import { shallowEqual } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { OneMessage } from '@yy/one-ui'
import { useUrlSearch } from '@/hooks'
import { useLanguage } from '@/hooks/useLanguage'
import { verifyLoginCode, verifyLoginTicket } from '@/services/apis'
import { i18nInitResourcePromise } from '@/services/i18n'
import { useDispatch, useSelector } from '@/store'
import { getConfig } from '@/store/configSlice'
import { checkLogin, getUserInfo, loginout, setUserInfo, updateAcceptMarketing } from '@/store/userSlice'
import { countryIs, countryVersion, exceptCountries } from '@/utils/countryVersion'
import { adminLogin, hrefToOneClubUrl, OneClubPathMap, paramKey, replaceToOneClubUrl } from '@/utils/user'
import { COUNTRY_DOMAIN_MAP } from './constants'
import { deleteUrlQuery } from '@/utils'

export function logoutClears(scene?: string, extraQuery?: Record<string, any>) {
  Cookies.remove('AQM_VERSION')
  // 清除本次用户所有的临时数据
  sessionStorage.clear()
  hrefToOneClubUrl(OneClubPathMap.login, scene || 'logout', extraQuery)
}

export function useUserLogin() {
  const history = useHistory()
  const dispatch = useDispatch()
  const q = useUrlSearch()
  const { info } = useSelector((state) => state.user, shallowEqual)
  const { handleFixedLocalLanguage } = useLanguage()

  /**
   * 校验商家域名匹配
   */
  const matchCountry = useCallback(async () => {
    try {
      // need to load config first, otherwise the language will show error
      const [, res] = await Promise.all([dispatch(getConfig), dispatch(getUserInfo(true))])

      // 本地就别跳转了
      if (process.env.NODE_ENV === 'development') {
        handleFixedLocalLanguage(res.countryCode)
        return { isMatch: true }
      }
      if (countryIs.GLOBAL && (exceptCountries as string[]).includes(res.countryCode)) {
        const origin = COUNTRY_DOMAIN_MAP[res.countryCode]

        const url = location.href.replace(location.origin, origin).concat(`${!!location.search ? '&' : '?'}loginCode=${res?.code}`)
        dispatch(setUserInfo(null))
        window.location.replace(url)
        return Promise.reject(false)
      } else {
        if (res.countryCode !== countryVersion && !countryIs.GLOBAL) {
          // 如果也不属于当前版本，跳去无权限
          dispatch(setUserInfo(null))
          const search = q?.lang ? `?lang=${q?.lang}` : ''
          history.push(`/authority${search}`)
          return Promise.reject(false)
        }
      }

      handleFixedLocalLanguage(res.countryCode)

      return { isMatch: true }
    } catch (error) {
      // 兜底，拦截器cookie过期刷新页面，偶发会走到toast，要过滤掉此提示
      if (error?.message !== 'USER_NOT_VALID' && error?.message !== 'COOKIE_EXPIRED') OneMessage.error(error.message)
      return Promise.reject(false)
    }
  }, [dispatch, handleFixedLocalLanguage, history, q?.lang])
  /**
   * 登录成功
   */
  const loginSuccessCb = useCallback(async () => {
    // 更新登录状态
    await dispatch(checkLogin())

    // 登录更新用户信息
    if (q?.acceptMarketing) {
      const acceptMarketing = q?.acceptMarketing === '1'
      await updateAcceptMarketing(acceptMarketing)
    }

    // 国家版本
    await matchCountry()
  }, [q?.acceptMarketing, dispatch, matchCountry])

  /** code登陆 */
  const verifyCode = useCallback(
    async (code) => {
      try {
        const res = await verifyLoginCode({ code, appid: process.env.APPID, subappid: process.env.SUB_APPID })
        if (res.rescode === '0') {
          await loginSuccessCb()
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
      }
    },
    [loginSuccessCb]
  )
  /** ticket登陆 */
  const verifyTicket = useCallback(
    async (ticket) => {
      await i18nInitResourcePromise.then(async () => {
        try {
          const res = await verifyLoginTicket({ ticket, appid: process.env.APPID, subappid: process.env.SUB_APPID })
          if (res.rescode === '0') {
            await loginSuccessCb()
          }
        } catch (error) {
          if (error.rescode === '1028') {
            hrefToOneClubUrl(OneClubPathMap.login, 'bind')
          }
          throw error
        }
      })
    },
    [loginSuccessCb]
  )
  /** 登录流程 */
  const userLogin = useCallback(async () => {
    adminLogin()
    const { isLogined } = await dispatch(checkLogin())
    // 登陆
    if (q?.loginCode || q?.ticket) {
      paramKey.forEach((item) => deleteUrlQuery(item, { useQs: true }))
      if (q?.lang) deleteUrlQuery('lang', { useQs: true })
      if (q.loginCode) {
        await verifyCode(q.loginCode)
        return
      }
      if (q?.ticket) {
        await verifyTicket(q.ticket)
        return
      }
    }

    if (!isLogined) {
      replaceToOneClubUrl(OneClubPathMap.login)
      return Promise.reject()
    } else if (isLogined && !info?.uid) {
      // 重新校验国家
      await matchCountry()
    }
  }, [dispatch, info?.uid, matchCountry, q?.lang, q.loginCode, q.ticket, verifyCode, verifyTicket])

  /** 登出并跳转到oneclub */
  const logoutToOnclub = useCallback(
    async (scene?: string, extraQuery?: Record<string, any>) => {
      await dispatch(loginout())
      logoutClears(scene, extraQuery)
    },
    [dispatch]
  )

  return {
    loginSuccessCb,
    matchCountry,
    verifyCode,
    userLogin,
    logoutToOnclub,
  }
}
