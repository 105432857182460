import type { ValuesType } from 'utility-types'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { IAddressMatchResVo } from '@/bizComponent/AntdAddressMatch'
import Api from '@/services/http'
import { salesUrl } from '@/services/url'
import type { AppDispatch } from '@/store'
import type * as Types from './types'

const initialState: Types.InitialState = {
  customLogisticPickupTimeState: null,
  customLogisticPaymentTypeState: null,
  isUnified: false,
  logisticsOrderVos: [],
  batchDeliveryValidation: null,
}

const { reducer, actions } = createSlice({
  name: 'order.batchCreate',
  initialState,
  reducers: {
    /** 设置发货地址选项 */
    setDepartureType(state, action: PayloadAction<Types.DepartureType>) {
      return {
        ...state,
        departureType: action.payload,
      }
    },
    /** 设置选中的发货地址 */
    setDepartureSelected(state, action: PayloadAction<Types.DepartureSelected>) {
      return {
        ...state,
        departureSelected: action.payload,
      }
    },
    /** 清除设置选中的发货地址 */
    clearDepartureSelected(state) {
      return {
        ...state,
        departureSelected: undefined,
      }
    },
    /** 设置服务商选择器 */
    setExpressList(state, action: PayloadAction<Types.ExpressList>) {
      return {
        ...state,
        expressList: action.payload,
      }
    },
    /** 设置已选服务商选择器 */
    setExpressSelected(state, action: PayloadAction<Types.Express>) {
      return {
        ...state,
        expressSelected: action.payload,
      }
    },
    /** 设置服务列表返回接口 */
    setBatchDeliveryLogistics(state, action: PayloadAction<Types.BatchDeliveryLogisticsState | undefined>) {
      return {
        ...state,
        batchDeliveryLogistics: action.payload,
      }
    },
    /** 设置验证结果 */
    setBatchDeliveryValidation(state, action: PayloadAction<Types.BatchDeliveryValidateState | null>) {
      return {
        ...state,
        batchDeliveryValidation: action.payload,
      }
    },
    /** 保存自定义揽件时间 */
    setCustomLogisticPickupTime(state, action: PayloadAction<ValuesType<Types.CustomLogisticPickupTimeState> & { orderNo: string }>) {
      const { customLogisticPickupTimeState } = state
      const { orderNo, requiredPickupDates, requiredPickupTimeslots, monthlyAccount, taxNumber, customIdNo, channelSellerId } = action.payload

      return {
        ...state,
        customLogisticPickupTimeState: {
          ...customLogisticPickupTimeState,
          [orderNo]: {
            requiredPickupDates,
            requiredPickupTimeslots,
            monthlyAccount,
            taxNumber,
            customIdNo,
            channelSellerId,
          },
        },
      }
    },
    /** 保存自定义支付方式 */
    setCustomLogisticPaymentType(state, action: PayloadAction<ValuesType<Types.CustomLogisticPaymentTypeState> & { orderNo: string }>) {
      const { customLogisticPaymentTypeState } = state
      const { orderNo, shipmentPaymentType, shipmentInsuranceType } = action.payload
      const orderItem = customLogisticPaymentTypeState?.[orderNo]
      return {
        ...state,
        customLogisticPaymentTypeState: {
          ...customLogisticPaymentTypeState,
          [orderNo]: {
            ...orderItem,
            shipmentPaymentType,
            shipmentInsuranceType,
          },
        },
      }
    },
    /** 清除自定义揽件时间信息 */
    clearCustomLogisticPickupTime(state) {
      return {
        ...state,
        customLogisticPickupTimeState: {},
      }
    },
    /** 设置是否为统一服务产品 */
    setIsUnified(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isUnified: !!action.payload,
      }
    },
    setLogisticsOrderVos(state, action: PayloadAction<Types.InitialState['logisticsOrderVos']>) {
      return {
        ...state,
        logisticsOrderVos: action.payload.map((item) => ({
          ...item,
          match: true,
        })),
      }
    },
    updateLogisticsOrderVo(state, action: PayloadAction<IAddressMatchResVo & { orderNo: string }>) {
      const { orderNo, ...data } = action.payload
      const index = state.logisticsOrderVos.findIndex((item) => item.orderNo === orderNo)
      const newList = state.logisticsOrderVos.slice()

      if (index > -1 && state.logisticsOrderVos?.[index]) {
        const oldItem = state.logisticsOrderVos[index]
        const updateItem: Types.LogisticsOrderItemType = {
          ...oldItem,
          consigneeCity: data.city,
          consigneeCountry: data.countryCode,
          consigneeProvince: data.province,
        }

        newList.splice(index, 1, updateItem)
      }

      return {
        ...state,
        logisticsOrderVos: newList,
      }
    },
  },
})

/** 获取初始化数据 */
export const getBatchDeliveryLogistics = (params: Types.GetBatchDeliveryLogisticsParams) => async (dispatch: AppDispatch) => {
  try {
    const response = await Api.admin.post<Types.BatchDeliveryLogisticsState>(salesUrl.batchDeliveryLogistics, { ...params })
    const { data, bizCode, bizMessage, message } = response
    if (bizCode === 'SUCCESS') {
      dispatch(actions.setBatchDeliveryLogistics(data))
      return data
    }
    return Promise.reject({ bizCode, bizMessage, message })
  } catch (e) {
    const { bizCode, bizMessage, message } = e
    return Promise.reject({ bizCode, bizMessage, message })
  }
}

/** 批量验证 */
export const batchDeliveryValidate = (params: Types.BatchDeliveryValidateParams) => async (dispatch: AppDispatch) => {
  const response = await Api.admin.post<Types.BatchDeliveryValidateState>(salesUrl.batchDeliveryValidate, params)
  const { data, bizCode, bizMessage, message } = response
  if (bizCode === 'SUCCESS') {
    const { validCount, total, logisticsOrderVos } = data
    dispatch(actions.setBatchDeliveryValidation({ validCount, total }))
    dispatch(actions.setLogisticsOrderVos(logisticsOrderVos))
    return data
  }

  return Promise.reject({ bizCode, bizMessage, message })
}

/** 自定义揽货时间 */
export const modifyCustomLogisticPickupTime = (orderNo: string, params: ValuesType<Types.CustomLogisticPickupTimeState>) => (dispatch: AppDispatch) => {
  dispatch(actions.setCustomLogisticPickupTime({ orderNo, ...params }))
}

/** 自定义支付方式 和 保险服务 */
export const modifyCustomLogisticPaymentType = (orderNo: string, params: ValuesType<Types.CustomLogisticPaymentTypeState>) => (dispatch: AppDispatch) => {
  dispatch(actions.setCustomLogisticPaymentType({ orderNo, ...params }))
}

/** 批量发货 */
export const batchDelivery = (params: Types.BatchDeliveryParams) => async () => {
  const response = await Api.admin.post<Types.BatchDeliveryLogisticsState>(salesUrl.batchDelivery, params, { timeout: 120e3 })
  const { data, bizCode, bizMessage, message } = response
  if (bizCode === 'SUCCESS') {
    return data
  }

  return Promise.reject({ bizCode, bizMessage, message })
}

export const {
  setDepartureType,
  setDepartureSelected,
  clearDepartureSelected,
  setExpressList,
  setExpressSelected,
  setBatchDeliveryLogistics,
  clearCustomLogisticPickupTime,
  setIsUnified,
  setLogisticsOrderVos,
  setBatchDeliveryValidation,
  updateLogisticsOrderVo,
} = actions

export default reducer
