import { Card } from 'antd'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DATE_RANGE_OPTIONS, ORDER_STATS_OPTIONS, TimeRangeTypeEnum } from '../../constants'
import styles from '../../index.module.less'
import type { IOrderStats } from '../../types'
import CustomSelect from '../CustomSelect'
import Stats from '../Stats'

interface IOrderCardProps {
  stats: IOrderStats
  selectChange: FnType
}

const OrderCard: React.FC<IOrderCardProps> = (props) => {
  const { stats, selectChange } = props
  const { t } = useTranslation()

  const [dateRange, setDateRange] = useState(30)

  const handleChange = useCallback(
    (value: number) => {
      setDateRange(value)
      selectChange(value, TimeRangeTypeEnum.ORDER)
    },
    [selectChange]
  )

  return (
    <Card
      className={styles.orderCard}
      title={t('dashboard.order.label', { total: stats.total || 0 })}
      extra={<CustomSelect value={dateRange} options={DATE_RANGE_OPTIONS} onChange={handleChange} />}
    >
      <div className={styles.container}>
        {ORDER_STATS_OPTIONS.map((item) => (
          <Stats key={item.valueKey} label={t(item.label)} value={stats[item.valueKey]} />
        ))}
      </div>
    </Card>
  )
}

OrderCard.displayName = 'OrderCard'
export default React.memo(OrderCard)
