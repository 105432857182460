import Api from '@/services/http'
import type * as Types from './types'

/** 获取汇率 */
export const fetchRateList = async (params: Types.RateParam) => Api.admin.post<Types.RateResItem>('/api/currency/convert', params)

export const enum EnumFileTypes {
  invoicePath = 'invoicePath',
}

export type IOSSItem = {
  fileType: `${EnumFileTypes}`
  ossUri: string
}

/**
 *
 * @param ossUriRes
 * @param fileType
 * @returns
 */
export const getOssAccessLinks = (data: IOSSItem[]) => {
  return Api.toast.post<{
    res: Array<{
      ossUri: string
      result: string
    }>
  }>('/api/presigned/oss', {
    uris: data,
  })
}

/**
 * @desc 加载所有国家，带洲信息
 */
export function postCountryAll(data?: Types.OneshipBaseRequest) {
  return Api.toast.post<Types.CountryAllRes>(`/api/country/all`, data)
}

/**
 * **接口** [按业务模块查询上传场景及对应限制↗](https://api-doc-sl.inshopline.com/#/project/Java/293/feature%2F20230731_3192_upload/interface/ab41fcc86c60e567f7c9a00b8e6dc722)
 * @请求头 `POST /api/resource-svc/config/upload-restrict/list`
 * @更新时间 `2023-07-31 11:43:16`
 */
export function postUploadRestrictList() {
  return Api.toast.post<{ restricts: Array<{ sceneId: string; uploadSizeLimit: number }> }>(`/api/v2/resource-svc/config/upload-restrict/list`, { bizModule: 'ONESHIP' })
}
