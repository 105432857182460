import type { IntervalValue } from '@/components/IntervalInput'
import type { IAppId } from '@/components/PlatformAvatar'
import type { OneshipStatusDict } from '@/constant/oneshipShipStatus'
import type { ProviderType } from '@/constant/serviceProvider'
import type { OrderSortType } from '@/constant/sortType'
import type { OrderAddressInfoVo } from '@/services/apis/order/type'
import type { ChannelCustomInfo } from '@/services/apis/type'
import type { TagOption } from '@/store/customTagSlice/types'
import type { LogisticsType } from '@/store/logisticsSlice/type'

export interface OptionsType {
  label: string
  value: string | number | boolean
}

export interface FilterStore {
  label: string
  value: string
}

export interface FilterChannel {
  label: string
  value: string
}

export interface FilterProduction {
  label: string
  value: string
}

export interface FilterProvider {
  label: string
  value: string
}

export interface FilterCountry {
  label: string
  value: string
}

export interface FilterRemark {
  label: string
  value: string
}
export interface FilterDeliveryType {
  label: string
  value: number
}

export interface OneshipLogisticsAddressResVo {
  /**发货地址别名 */
  locationName?: string
  /**寄件人证件号 */
  senderIdNo?: string
  /**证件类型：1身份证,2护照,3港澳通行证,4回乡证,5台胞证,6赴台通行证,7其它 */
  senderIdType?: number
  /**寄件国家 */
  senderCountryCode?: string
  /** 发货地址语言 */
  senderAddressLanguages?: string[]
  /**寄件省份 */
  senderProvince?: string
  /**寄件城市 */
  senderCity?: string
  /**寄件区县 */
  senderDistrict?: string
  /**寄件街镇 */
  senderTown?: string
  /**寄件人 */
  senderName?: string
  /**寄件人电话 */
  senderPhone?: string
  /**寄件人手机 */
  senderMobile?: string
  /**寄件详细地址 */
  senderAddress?: string
  /**寄件详细地址2 */
  senderAddressTwo?: string
  /**寄件邮编 */
  senderZipCode?: string
  /**寄件备注 */
  senderRemark?: string
  /**发件人邮箱 */
  senderEmail?: string
  /**收件人证件号 */
  consigneeIdNo?: string
  /**证件类型：1身份证,2护照,3港澳通行证,4回乡证,5台胞证,6赴台通行证,7其它
圆通只能用1和2 */
  consigneeIdType?: number
  /**收件国家 */
  consigneeCountryCode: string
  /**收件省份 */
  consigneeProvince?: string
  /**收件城市 */
  consigneeCity?: string
  /**收件区县 */
  consigneeDistrict?: string
  /**收件街镇 */
  consigneeTown?: string
  /**收件详细地址 */
  consigneeAddress?: string
  /**收件详细地址2 */
  consigneeAddressTwo?: string
  /**收件人 */
  consigneeName?: string
  /**收件人电话 */
  consigneePhone?: string
  /**收件人手机 */
  consigneeMobile?: string
  /**收件邮编 */
  consigneeZipCode?: string
  /**收件备注 */
  consigneeRemark?: string
  /**收件门店编码 */
  consigneeStationSeq?: string
  /**收件原始门店编码,原始门店编码+consigneeStationType+companyCode = consigneeStationSeq */
  consigneeStationCode?: string
  /**收件门店类型 */
  consigneeStationType?: number
  /**收件人邮箱 */
  consigneeEmail?: string
  /**门店名称 */
  consigneeStationName?: string
  /**门店地址 */
  consigneeStationAddress?: string
}

export interface OneshipOrderRelationType {
  oneshipNo: string // 物流单号
  logisticsNo: string // 运单号
  companyCode: string // 运单服务商编码
  companyName: string // 运单服务商名称
  expressTypeCode: string // 运单服务产品编码
  expressTypeName: string // 运单服务产品名称
  expressNo: string // 运单号
  waybillStatus: string // 面单状态
  logisticsStatus: string // 运单状态
  /* 订单号 */
  orderNo: string
  shipStatus: string // 发货状态
}

export interface OneshipOrderItemType {
  payType: number
  logisticsAppId: string // 运单appid
  appId: IAppId // 接入系统
  orderNo: string // oneship订单号
  userStoreSeq: string // oneship店铺编码
  sellerId: string // 商家ID
  sellerName: string // 商家名称
  originOrderNo: string // 原订单号
  codAmount: number
  channelCustomInfoV2?: ChannelCustomInfo
  paymentType: number // 1: cod支付 2：非cod支付
  currencyCode: string
  expressTypeCode: string // 运单服务产品编码
  expressTypeName: string // 运单服务产品名称
  companyCode: ProviderType // 运单服务商编码
  companyName: ProviderType // 运单服务商名称
  orderStatus: string // 订单状态
  paymentStatus: string // 支付状态
  deliveryStatus: string // 履约状态
  associate: boolean // 是否关联运单
  existOrderRemark: boolean // 有无oneship订单备注
  existOriginOrderRemark: boolean // 有无原订单备注
  orderCreateTime: number // 原订单创建时间
  orderUpdateTime: number // 原订单更新时间
  originOrderStatus: string
  deliveryTypeName: string // 送货方式
  /* 合单发货的订单号 */
  aggShipmentOrderNos: string[]
  logisticsOrders?: OneshipOrderRelationType[]
  store: {
    userStoreSeq: string // 店铺编码
    appId: string // 三方系统id
    storeId: string // 店铺id
    storeName: string // 店铺名称
    storeAlias: string // 店铺别名
    storeWebsite: string // 店铺网址
    email: string // 店铺email
    authStatus: number // 店铺授权状态
    pullStatus: number // 店铺初始化拉单状态
    defaultAddressNo: string // 店铺默认地址id
    defaultLanguageCode: string // 店铺默认语言编码
    logoUrl: string // 店铺图标地址
  }
  deliveryType: string //配送方式
  /**接入方式 0:接口创建 1人工创建 */
  sourceType?: 0 | 1
  /**揽收方式方式：1:上门揽收 2:商家自寄 */
  pickupType?: 1 | 2
  /**预约提货日期 */
  requiredPickupDates?: string[]
  /**预约提货时间 */
  requiredPickupTimeslots?: string[]
  /**收寄件地址信息 */
  address: OneshipLogisticsAddressResVo
  /* 前端添加的排序下标 */
  ind: string
  tagIds: string[]
  expectedDeliveryTime?: string
  requiredDeliveryDates: string[]
  requiredDeliveryTimeslots: string[]
  paymentMethod: 'COD' | 'ELSE'[]
  /** 是否有托运单证明文件 */
  presignedDeliveryProofUrl?: string
  tag: string[] //归档的tab
  // 渠道编码
  channelCode: string
  /** 支持多地址才会有（例如即时物流lalamove） */
  consigneeAddresses?: OrderAddressInfoVo[]
  /** 服务产品物流类型 1.平台物流,2:LMS物流 */
  expressLogisticsType?: 1 | 2
}

export interface PaginationType {
  pageNum: number
  pageSize: number
  total: number
  orderByType: OrderSortType
}

export interface RowCheckedType {
  /** 前端添加的排序下标 */
  ind: string
  /** 是否能发货 */
  ship: boolean
  /** 能否打印 */
  canBePrint: boolean
  /** invoice print */
  canBeInvoicePrint: boolean
  /** 运单状态 */
  logisticsStatus: string
  /** 发货状态 */
  shipStatus: string
  /** 面单状态 */
  waybillStatus: string
  /** LMS 单号 */
  logisticsNo: string
  /** 收货国家 */
  consigneeCountryCode: string
  /** 列表项全部数据 */
  row: Partial<OneshipOrderItemType>

  orderNo: string
  userStoreSeq: string
  stationCode: string
  sourceType: 0 | 1
  appId: IAppId
  senderCountryCode: string
  tagIds: string[]
  presignedDeliveryProofUrl: string
  originOrderNo: string
  stationName: string
}

export interface PreserveRowCheckType {
  orderNo: string
}

export interface SearchFormType {
  app_ids: OptionsType[]
  search_text: string
  search_type: string
  time_range: (number | null)[] | undefined
  filter_stores: OptionsType[]
  ship_status: OneshipStatusDict
  filter_channel: OptionsType[]
  filter_receive_country: OptionsType[]
  filter_provider: OptionsType[]
  filter_productions: OptionsType[]
  filter_remark: OptionsType[]
  filter_delivery_type: OptionsType[]
  time_range_type?: string
  order_status: OptionsType[]
  pay_status: OptionsType[]
  delivery_status: OptionsType[]
  filter_tag: TagOption[]
  setExpressType: OptionsType[]
  setSenderAddress: OptionsType[]
  //TODO 对接接口
  order_good_number: IntervalValue
  order_good_types: IntervalValue
  pickGoodStatuses: string[]
}

export interface SearchQueryType {
  appIds: string[] // 来源平台
  queryWord: string // 关键字
  consigneeMobile: string // 收件人手机号
  numberList: string[] // 多单号混合搜索(orderNo、originOrderNo、expressNo)
  userStoreSeqs: string[]
  orderCreateStartTime: number //创建订单时间范围-开始时间
  orderCreateEndTime: number //创建订单时间范围-结束时间
  channelType: string[] //发货渠道
  deliveryTypes: string[]
  consigneeCountries: string[] //收货国家，地区
  companyCodes: string[] //服务商
  expressTypeCodes: string[] //服务产品
  existOriginOrderRemark: boolean // 有无远订单备注
  existOrderRemark: boolean // 有无oneship订单备注
  shipStatuses: string[] //发货状态
  orderStatuses: string[] // 订单状态
  paymentStatuses: string[] // 支付状态
  deliveryStatuses: string[] // 履约状态
  tag: string[] //归档的tab
  notTag: string[] //除归档的tab
  tagIds: string[] // 标签
}

export interface SearchPaginationType {
  pageNum?: number
  pageSize?: number
  orderByType?: OrderSortType
}

// 按照查询条件导出

export interface TransformSearchData {
  orderNos: string[] | null // oneship订单号
  originOrderNos: string[] | null //  原平台订单号
  expressNos: string[] | null // 3PL运单号
  userStoreSeqs: string[] | null //oneship店铺编码
  orderCreateStartTime: number | null //创建订单时间范围-开始时间
  orderCreateEndTime: number | null //创建订单时间范围-结束时间
  orderStatuses: string[] | null //订单状态
  channelType: string[] | null //发货渠道
  consigneeCountries: string[] | null //收货国家，地区
  companyCodes: ProviderType[] | null //服务商
  expressTypeCodes: string[] | null //服务产品
  existOriginOrderRemark: boolean | null // 有无原订单备注
  existOrderRemark: boolean | null // 有无oneship订单备注
}

// 搜索查询
export interface OneshipOrderState {
  /* 暂存用户搜索记录 */
  searchRecord: (SearchFormType & SearchPaginationType) | null
  hasNext: boolean
  list: OneshipOrderItemType[]
  pagination: PaginationType
  orderDetailInfo: null
  listTabValue: number
  rowChecked: RowCheckedType[]
  rowCheckedMap: Record<string, RowCheckedType>
}

export interface ResponseQueryType {
  pageNum: number
  hasNext: boolean
  pageSize: number
  total: number
  data: OneshipOrderItemType[]
  orderCounts: { [propsName: string]: number }
}

export interface OrderNoAppIdsType {
  appId: string
  orderNo: string
}

export interface PrintType {
  orderNos: string[]
  callBackOSUrl?: string
}

export interface BatchShipType {
  orderNos: string[]
  logisticsType: LogisticsType
}

export interface PrintResponse {
  taskId: string
  /** 711C2C 回调地址 */
  callBackUrl?: string
  /** 711C2C 参数 */
  bodyParam?: any
  /** 当前面单任务返回的结果， 1-默认（正常返回任务id）， 2-当前任务中存在711C2C+其他渠道的单（需返回其他渠道的单）， 3-当前任务当中只存在711C2C的单（返回对应回调地址及参数）4-代表存在需要人工申请的面单  */
  waybillResStatus: WAYBILL_RESSTATUS
  /** 过滤后的订单号（不包含711C2C） */
  filterNos?: string[]
  providers?: Array<IProviders>
}

export interface IProviders {
  providerCode: string
  providerRemark: string
  logisticsNos: string[]
}

export enum WAYBILL_RESSTATUS {
  /** 普通面单，无711 人工 */
  normal = '1',
  /** 有711c2c面单和其他 */
  other_and_711c2c = '2',
  /** 仅只有711c2c面单 */
  _711C2C = '3',
  /** 人工面单 */
  artificial = '4',
}

export interface BatchShipResponse {
  taskId: string
}

export interface RemarkType {
  orderNo: string
  orderRemark: string
  appId: string
}

export interface BatchRemarkType {
  orderNos: string[]
  orderRemark: string
  appIds: Array<OrderNoAppIdsType>
}

export interface ModalCountries {
  sender: string
  consignee?: string
}

// 国家校验弹窗类型
export enum ModalType {
  SHIP = 'ship', // 发货
  ORDER = 'order', // 取消发货 取消关联 前往查看运单
}

export interface PlatFormDetailParamsTypes {
  originOrderId: string
  platform: string
}

export type RowCheckWithPreserveType = (OneshipOrderItemType | PreserveRowCheckType)[]
