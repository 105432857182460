// 校验商家订阅当前账号类控制上限使用情况，如果校验不通过会弹窗提示
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { accountLimit, subscriptionStatus } from '@/services/apis/subscribe'
import type { AccountLimitStatusRespDTO } from '@/services/apis/subscribe/type'
import { cannotUpgrade } from './useCannotUpgrade'
import { useToUpgradePackage } from './useToUpgradePackage'

/**
 * 校验指定账号是否已满，返回
 * @param key
 */
const validateAccountLimit = async (key: AccountLimitStatusRespDTO['key']) => {
  const [accountLimitRes, subscriptionStatusRes] = await Promise.all([accountLimit(), subscriptionStatus()])
  const accountLimitData = accountLimitRes.data
  const subscription = subscriptionStatusRes?.data?.subscription
  const limitData = accountLimitData.find((item) => item.key === key)
  return {
    limitData,
    isTopLevel: cannotUpgrade(subscription),
    success: limitData.capacity - limitData.used > 0,
    subscription,
  }
}

/**
 * 校验商家发货账号数量上限使用情况
 * 如果校验不通过会弹窗提示
 * @returns
 */
export const useValidateSAASAccountLimit = () => {
  const { t } = useTranslation()
  const { toUpgradePackage } = useToUpgradePackage()

  const validateSAASAccount = useCallback(async () => {
    const res = await validateAccountLimit('os_saas_order_account')
    const { limitData, isTopLevel, success } = res
    if (!success) {
      toUpgradePackage({
        title: t(isTopLevel ? 'subscribe.SAASAccountLimit.topLevel' : 'subscribe.SAASAccountLimit', { num: limitData.capacity }),
        content: '',
        okText: t(isTopLevel ? 'common.contactService' : 'frame.upgrade'),
        cancelText: t('common.cancelBtn'),
      })
    }
    return res
  }, [t, toUpgradePackage])
  return {
    validateSAASAccount,
  }
}

/**
 * 校验商家绑定店铺上限使用情况
 * 如果校验不通过会弹窗提示
 * @returns
 */
export const useValidateStoreAccountLimit = () => {
  const { t } = useTranslation()
  const { toUpgradePackage } = useToUpgradePackage()

  const validateStoreAccount = useCallback(async () => {
    const res = await validateAccountLimit('os_sale_store_account')
    const { limitData, isTopLevel, success } = res
    if (!success) {
      toUpgradePackage({
        content: '',
        title: t(isTopLevel ? 'subscribe.StoreAccountLimit.topLevel' : 'subscribe.StoreAccountLimit', { num: limitData.capacity }),
        okText: t(isTopLevel ? 'common.contactService' : 'frame.upgrade'),
        cancelText: t('common.cancelBtn'),
      })
    }
    return res
  }, [t, toUpgradePackage])
  return {
    validateStoreAccount,
  }
}
