import { useMount } from 'ahooks'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { shallowEqual } from 'react-redux'
import Loading from '@/components/Loading'
import { useUserLogin } from '@/containers/User/hook'
import { markTTI } from '@/services/performance'
import { useDispatch, useSelector } from '@/store'
import { fetchStationTypes, splitSupportCountryMapCreator } from '@/store/addressSlice'
import { fetchGuideInfo, getMessageCenterConfig, getUploadLimit, queryPhoneAreaCode, settingCreator, systemSettingCreator } from '@/store/commonSlice'
import {
  featchDictionaryBasicCurrencyList,
  featchDictionaryCountryList,
  featchDictionaryCustomTemplateImageLimit,
  featchDictionaryTaxTypeList,
  featchOrderStatusList,
  fetchFilterCountry,
  queryFullProviderConfigList,
  queryImmediateProviderConfigList,
  queryProviderConfigList,
} from '@/store/dictionarySlice'
import { getUserSettingList, initUserPermissionTree } from '@/store/userSlice'
import { slTraceInit } from '@/utils/slTrace'
import type { AuthorizedProps } from '../Authorized'
import { Authorized } from '../Authorized'
import { getActivityAction } from '@/store/giveawaySlice'
import { ABTEST_VERSION_KEY, ABTEST_VERSION_STABLE_VALUE, useAppVersionDetecter } from '@/components/ABTest'

export type LoginAuthorizedProps = Omit<AuthorizedProps, 'isReady' | 'isPermit'>

export const LoginAuthorized: React.FC<LoginAuthorizedProps> = (props) => {
  const dispatch = useDispatch()
  const [isReady, setIsReady] = useState(false)
  const { osudb_uid: uid } = useSelector((state) => state.user, shallowEqual)
  const { userLogin } = useUserLogin()
  const detectAppVersion = useAppVersionDetecter()

  useMount(async () => {
    setIsReady(false)
    try {
      // 先预设用户ABtest版本为稳定版
      if (!Cookies.get(ABTEST_VERSION_KEY)) {
        Cookies.set(ABTEST_VERSION_KEY, ABTEST_VERSION_STABLE_VALUE, { path: '/', expires: 10 })
      }
      // 校验登陆状态及国家版本
      await userLogin()
      // 确认用户真正的ABtest版本，若版本不是稳定版，则刷新页面
      detectAppVersion()

      dispatch(queryProviderConfigList())
      // 加载ONESHIP发货地区支持的全部物流服务商
      dispatch(queryFullProviderConfigList()) // 订单运单筛选才用到，可以移到这两个页面
      dispatch(queryImmediateProviderConfigList()) // 即时物流订单运单筛选才用到
      dispatch(fetchStationTypes())

      /**
       * 获取用户的配置信息
       * @todo
       * 这里面单个与统一请求是一样的，
       * 之所以没有去除是因为此处 dispatch 或 set 值的位置不一致
       * 需要改成一致才能去除
       */
      dispatch(getUserSettingList({ settingCodes: ['SAAS_CONFIG'] })) // 包含在了settingCreator里面，可以省略

      // 获取系统公共配置信息
      dispatch(systemSettingCreator())

      dispatch(getMessageCenterConfig())

      dispatch(splitSupportCountryMapCreator())

      // 获取导入限制
      dispatch(getUploadLimit())

      // 需要同步，或者报错需要中断整个初始化流程的操作
      await Promise.all([
        (async () => {
          // 获取新手指引信息
          const guideData = await dispatch(fetchGuideInfo())
          if (guideData === null) {
            /**
             * 这里用setTimeout是兼容如下场景：
             * 刚注册完跳转到首页，这时发出请求列表，还没后端初始化完，只会返回空的数据。
             * 初始化完后，后端会发一个推送，但此时socket又没初始化完，就会错过那次推送，导致的结果就是这两次数据都是空的
             */
            setTimeout(() => {
              dispatch(fetchGuideInfo())
            }, 2000)
          }
        })(),
        dispatch(featchDictionaryCountryList()),
        dispatch(settingCreator()),
        dispatch(featchDictionaryCustomTemplateImageLimit()),
        dispatch(featchDictionaryBasicCurrencyList()),
        /** 获取权限树 */
        dispatch(initUserPermissionTree()),
        /** 发货/收件国家过滤 */
        dispatch(fetchFilterCountry()),
        dispatch(featchDictionaryTaxTypeList()),
        dispatch(getActivityAction()),
      ])

      // 获取国家手机区号
      dispatch(queryPhoneAreaCode())

      // 获取店铺拉单设置
      dispatch(featchOrderStatusList())

      setIsReady(true)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      if (navigator.onLine && process.env.APP_ENV === 'product') {
        // 网络状况没问题，就3秒后刷新重试
        setTimeout(() => {
          location.reload()
        }, 3000)
      } else {
        // 网络有问题，当重新在线时刷新页面
        window.addEventListener('online', () => {
          location.reload()
        })
      }
    }
  })
  useLayoutEffect(() => {
    // 初始化完成后标记tti时间
    if (isReady) markTTI()
  }, [isReady])
  useEffect(() => {
    if (!uid) return
    slTraceInit()
  }, [uid])

  return <Authorized {...props} isReady={isReady} isPermit={isReady} progress={Loading} />
}

LoginAuthorized.displayName = 'LoginAuthorized'
