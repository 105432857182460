// 所有新人指引
import type * as Types from './types'

const storageKey = 'guideQueueMap'

interface GuideItem {
  key: Types.GuideKeys
  showed: boolean
}

export const initGuideQueue = () =>
  [
    { key: 'language', showed: false },
    { key: 'storeSetting', showed: false },
    { key: 'serviceApply', showed: false },
  ] as GuideItem[]

export function getGuideQueue(): Record<string, Types.UserInitState['guideQueue']>
export function getGuideQueue(uid?: string): Types.UserInitState['guideQueue']
export function getGuideQueue(uid?: string) {
  const json = window.localStorage.getItem(storageKey)
  let guideQueueMap = {} as Record<string, Types.UserInitState['guideQueue']>
  if (json) {
    try {
      guideQueueMap = JSON.parse(json)
    } catch (error) {
      //console.error(error)
    }
  }
  let userGuideQueue: GuideItem[] = null
  if (uid) {
    const initGuide = initGuideQueue()
    userGuideQueue = guideQueueMap[uid] ?? initGuide
    // 如果initGuideQueue有新增的，则补充在用户本地的数据里
    // 如果本地存储里存在在initGuideQueue里面没有的项，则移除
    userGuideQueue = initGuide.map((item) => {
      const userItem = userGuideQueue.find((i) => i.key === item.key)
      return userItem ?? item
    })
  }
  return uid ? userGuideQueue : guideQueueMap
}

export const saveGuideQueue = (queue: { key: Types.GuideKeys; showed: boolean }[], uid: string) => {
  const allQueue = getGuideQueue()
  allQueue[uid] = queue
  window.localStorage.setItem(storageKey, JSON.stringify(allQueue))
}
