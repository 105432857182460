import { Button, Card, Progress } from 'antd'
import React, { useLayoutEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { SERVICE_KEYS } from '@/constant'
import { useSubscriptionInfo } from '@/containers/Subscribe/Config/hook'
import { useSelector } from '@/store'
import styles from './index.module.less'
import reduce from 'lodash/reduce'

const OneClubPlusCard: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { subscriptionInfoService, subscriptionInfo, subscriptionAccountLimit } = useSubscriptionInfo()
  const languageStore = useSelector((state) => state.language)
  const { capacity: saleCapacity = 0, used: saleUsed = 0 } = subscriptionAccountLimit?.os_sale_store_account || {}
  const { capacity: sassCapacity = 0, used: sassUsed = 0 } = subscriptionAccountLimit?.os_saas_order_account || {}
  const { capacity: adminCapacity = 0, used: adminUsed = 0 } = subscriptionAccountLimit?.os_muti_admin || {}

  const rights = reduce(
    subscriptionInfo?.rights,
    (result, item) => {
      const { serviceKey, totalQty, availableQty } = item
      return Object.assign(result, {
        [serviceKey]: { totalQty, availableQty },
      })
    },
    {}
  )

  const trackingWaybillInfo = rights[SERVICE_KEYS.os_tracking_waybill] || {}
  const { totalQty: trackingTotal = 0, availableQty: trackingAvailable = 0 } = trackingWaybillInfo

  const freightCollectInfo = rights[SERVICE_KEYS.os_freight_collect] || {}
  const { totalQty: freightTotal = 0, availableQty: freightAvailable = 0 } = freightCollectInfo

  const packageName = useMemo(() => {
    let name = ''
    try {
      const subscribePkgConfig = JSON.parse(subscriptionInfo?.subscription?.productName)
      name = subscribePkgConfig?.[languageStore.languageType] || subscribePkgConfig?.['en']
    } catch (error) {}

    return name
  }, [subscriptionInfo?.subscription, languageStore?.languageType])

  useLayoutEffect(() => {
    subscriptionInfoService()
  }, [subscriptionInfoService])

  const jump = () => {
    history.push('/account/subscribe')
  }

  return (
    <div className={styles.oneclubPlusCard}>
      <Card className={styles.wrapper}>
        <div className={styles.head}>
          <h3>{packageName}</h3>
          <Button onClick={jump}>{t('dashboard.oneclubplus.compare')}</Button>
        </div>
        <div className={styles.body}>
          <div className={styles.limit}>
            <h5 className={styles.title}>
              {t('dashboard.oneclubplus.bindStore')}
              {saleCapacity ? <span>（{`${saleUsed}/${saleCapacity}`}）</span> : null}
            </h5>
            {saleCapacity ? (
              <Progress className={styles.process} width={280} percent={(saleUsed / saleCapacity) * 100} showInfo={false} />
            ) : (
              <span className={styles.nan}>{t('dashboard.oneclubplus.nan')}</span>
            )}
          </div>
          <div className={styles.limit}>
            <h5 className={styles.title}>
              {t('dashboard.oneclubplus.sassAccount')}
              {sassCapacity ? <span>（{`${sassUsed}/${sassCapacity}`}）</span> : null}
            </h5>
            {sassCapacity ? (
              <Progress className={styles.process} width={280} percent={(sassUsed / sassCapacity) * 100} showInfo={false} />
            ) : (
              <span className={styles.nan}>{t('dashboard.oneclubplus.nan')}</span>
            )}
          </div>
          <div className={styles.limit}>
            <h5 className={styles.title}>
              {t('dashboard.oneclubplus.tracking')}
              {trackingTotal ? <span>（{`${trackingTotal - trackingAvailable}/${trackingTotal}`}）</span> : null}
            </h5>
            {trackingTotal ? (
              <Progress className={styles.process} width={280} percent={((trackingTotal - trackingAvailable) / trackingTotal) * 100} showInfo={false} />
            ) : (
              <span className={styles.nan}>{t('dashboard.oneclubplus.nan')}</span>
            )}
          </div>

          <div className={styles.limit}>
            <h5 className={styles.title}>
              {t('pay.deduct.product.name')}
              {freightTotal ? <span>（{`${freightTotal - freightAvailable}/${freightTotal}`}）</span> : null}
            </h5>
            {freightTotal ? (
              <Progress className={styles.process} width={280} percent={((freightTotal - freightAvailable) / freightTotal) * 100} showInfo={false} />
            ) : (
              <span className={styles.nan}>{t('dashboard.oneclubplus.nan')}</span>
            )}
          </div>

          <div className={styles.limit}>
            <h5 className={styles.title}>
              {t('dashboard.oneclubplus.accountLimitAdmin')}
              {adminCapacity ? <span>（{`${adminUsed}/${adminCapacity}`}）</span> : null}
            </h5>
            {adminCapacity ? (
              <Progress className={styles.process} width={280} percent={(adminUsed / adminCapacity) * 100} showInfo={false} />
            ) : (
              <span className={styles.nan}>{t('dashboard.oneclubplus.nan')}</span>
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}

OneClubPlusCard.displayName = 'OneClubPlusCard'

export default OneClubPlusCard
