import { useRequest } from 'ahooks'
import { useState } from 'react'
import { OneMessage } from '@yy/one-ui'
import { BIND_STATUS } from '@/constant'
import * as subscribeService from '@/services/apis/subscribe'
import type { ICardInfoDTO } from '@/services/apis/subscribe/type'

interface UseCardInfoProps {
  pollingCardInfoSuccess?: () => void
  pollingCardInfoError?: () => void
  boundInfoSuccess?: () => void
  boundInfoError?: () => void
}
export function useCardInfo(option?: UseCardInfoProps) {
  const { pollingCardInfoSuccess, pollingCardInfoError, boundInfoSuccess, boundInfoError } = option || {}

  const [cardInfo, setCardInfo] = useState<Partial<ICardInfoDTO>>({})
  const [bindCardInfo, setBindCardInfo] = useState<Partial<ICardInfoDTO>>({})
  const [canShowCredit, setCanShowCredit] = useState(false)

  const {
    run: pollingCardInfoService,
    cancel: cancelPollingCardInfoService,
    loading: isPollingCardInfoLoading,
  } = useRequest(
    ({ bindUniqueId }) =>
      subscribeService.getCardInfo({
        bindUniqueId: bindUniqueId,
      }),
    {
      pollingInterval: 2000,
      pollingWhenHidden: false,
      onSuccess(res) {
        setBindCardInfo(res.data)
        if (res.code === 'SUCCESS') {
          if (res.data?.bindStatus === BIND_STATUS.SUCCEEDED) {
            setCardInfo(res.data)
            setCanShowCredit(true)
          }
        } else {
          setCanShowCredit(false)
        }
        pollingCardInfoSuccess && pollingCardInfoSuccess()
      },
      onError(e) {
        pollingCardInfoError && pollingCardInfoError()

        e?.message && OneMessage.error(e.message)
      },
    }
  )

  const { run: boundInfoService, loading: boundInfoServiceLoading } = useRequest(() => subscribeService.getCardBounds(), {
    onSuccess(res) {
      if (res.code === 'SUCCESS') {
        setCardInfo(res?.data.bound)
        setCanShowCredit(true)
      } else {
        setCanShowCredit(false)
      }

      boundInfoSuccess && boundInfoSuccess()
    },
    onError(e) {
      boundInfoError && boundInfoError()
      setCanShowCredit(false)

      e?.message && OneMessage.error(e.message)
    },
  })

  return {
    /**当前绑定卡信息 */
    creditCardInfo: cardInfo || {},
    canShowCredit,
    bindCardInfo,

    isPollingCardInfoLoading,
    boundInfoServiceLoading,

    pollingCardInfoService,
    cancelPollingCardInfoService,
    boundInfoService,
  }
}
