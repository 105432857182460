import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { combineReducers } from '@reduxjs/toolkit'
import addressMatch from './addressMatchSlice'
import address from './addressSlice'
import batchCreateOrder from './batchCreateOrderSlice'
import batchMerge from './batchMergeSlice'
import common from './commonSlice'
import configSlice from './configSlice'
import country from './countrySlice'
import courier from './courierSlice'
import createOrder from './createOrderSlice'
import customTag from './customTagSlice'
import dictionary from './dictionarySlice'
import I17trackProvider from './I17trackProvider'
import immediateBatchCreate from './immediateBatchCreateSlice'
import immediateBatchShip from './immediateBatchShipSlice'
import immediateOrder from './immediateOrderSlice'
import immediateSteps from './immediateStepsSlice'
import language from './languageSlice'
import order from './logisticsSlice'
import newBatchShipSlice from './newBatchShipSlice'
import newServiceProvider from './newServiceProviderSlice'
import notification from './notificationSlice'
import oneshipOrder from './oneshipOrderSlice'
import orderBatchCreate from './order/batchCreateSlice'
import orderCreate from './order/createSlice'
import parcelSlice from './parcelSlice'
import recipientAddressForm from './recipientAddressSlice'
import senderAddressForm from './senderAddressSlice'
import serviceProvider from './serviceProviderSlice'
import store from './storeSlice'
import subscribeSlice from './subscribeSlice'
import trackingSetting from './trackingSettingSlice'
import user from './userSlice'
import wallet from './walletSlice'
import waybillTrackingSlice from './waybillTrackingSlice'
import newBatchMergeShipSlice from './newBatchMergeShipSlice'
import giveaway from './giveawaySlice'
import preSelectedProvider from './preSelectedProvider'
import pickGoods from './pickGoods'

const history = createBrowserHistory()
const reducers = {
  // 发货地址
  senderAddressForm,
  recipientAddressForm,
  // 旧的
  orderCreate,
  orderBatchCreate,
  user,
  country,
  courier,
  batchCreateOrder,
  address,
  common,
  order,
  language,
  notification,
  createOrder,
  store,
  oneshipOrder,
  dictionary,
  serviceProvider,
  wallet,
  configSlice,
  newServiceProvider,
  parcelSlice,
  addressMatch,
  trackingSetting,
  waybillTrackingSlice,
  subscribeSlice,
  I17trackProvider,
  immediateBatchShip,
  immediateBatchCreate,
  customTag,
  batchMerge,
  immediateOrder,
  immediateSteps,
  newBatchShipSlice,
  newBatchMergeShipSlice,
  giveaway,
  preSelectedProvider,
  pickGoods,
}

const rootReducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
