import type { RouteConfig } from 'react-router-config'
import { withAsync } from '@/components/Async'
import { withAuthRedirect } from '@/components/Authorized/hoc/withAuthRedirect'
import { withAddressSplitAlgorithmTrackPage } from '@/bizComponent/AddressAutoSplit/AddressSplitAlgorithmTrack'
import { countryIs } from '@/utils/countryVersion'
import Blank from '@/layouts/Blank'

const routes: RouteConfig[] = countryIs.CN
  ? []
  : [
      {
        // 退货
        path: '/reverse',
        alias: 'reverse_management',
        component: withAuthRedirect(Blank, { first: '/ship/reverse/list' }),
        menuTitle: 'frame.reverse',
        group: 'shipments',
        permissionCode: 'ship:return',
        routes: [
          // 单个退货
          {
            path: '/create',
            component: withAddressSplitAlgorithmTrackPage(
              withAsync(() => import(/* webpackChunkName: "Reverse/CreateOrder" */ '@/containers/Reverse/CreateOrder')),
              'orderCreate'
            ),
            exact: true,
          },
          // 批量退货
          {
            path: '/batchCreateNew',
            component: withAddressSplitAlgorithmTrackPage(
              withAsync(() => import(/* webpackChunkName: "Reverse/BatchCreate" */ '@/containers/Reverse/BatchImportOrders')),
              'orderCreate'
            ),
            exact: true,
          },
          // 编辑退货单
          {
            path: '/edit/:appId/:logisticsNo',
            menuTitle: 'reverse.edit.titile',
            component: withAddressSplitAlgorithmTrackPage(
              withAsync(() => import(/* webpackChunkName: "Reverse/EditOrder" */ '@/containers/Reverse/EditOrder')),
              'orderEdit'
            ),
          },
          // 退货列表
          {
            path: '/list',
            component: withAsync(() => import(/* webpackChunkName: "Reverse/List" */ '@/containers/Reverse/List')),
            menuTitle: 'frame.reverse',
            pageDescription: 'frame.reverse.desc',
            exact: true,
          },
          // 详情
          {
            path: '/detail/:appId/:logisticsNo',
            menuTitle: 'detail',
            component: withAsync(() => import(/* webpackChunkName: "Reverse/Detail" */ '@/containers/Reverse/Detail')),
            exact: true,
          },
        ],
      },
    ]

export default routes
