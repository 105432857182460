import { useMemo } from 'react'
import { useLocation } from 'react-router'

export function useUrlSearch<T extends Record<string, any>>() {
  const location = useLocation()
  return useMemo<T>(() => {
    if (!location.search) return {} as T
    return location.search
      .replace(/\?/g, '')
      .split('&')
      .reduce((result, item) => {
        const [name, value] = item.split('=')
        return Object.assign(result, { [name]: value })
      }, {} as T)
  }, [location.search])
}
