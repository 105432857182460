import { windowOpen } from '@/utils/browser'
import { Button } from 'antd'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ExternalLink } from '@yy/one-icon'
import styles from './index.module.less'

export interface PaneContentProps {
  imgSrc
  btnText: string
  title: string
  content: string
  faqUrl?: string
  handleClick: () => void
}

const PaneContent: React.FC<PaneContentProps> = (props) => {
  const { t } = useTranslation()
  const { imgSrc, btnText, title, content, faqUrl, handleClick } = props
  const jumpHandler = useCallback((link) => {
    windowOpen(link, '_blank')
  }, [])
  return (
    <div className={styles.content}>
      <div className={styles.upper}>
        <div className={styles.left}>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.tips}>{content}</p>
          <Button type="primary" onClick={handleClick}>
            {btnText}
          </Button>

          {faqUrl && (
            <div className={styles.faqUrl} onClick={() => jumpHandler(faqUrl)}>
              {t('dashbord.guide.detail')}
              <ExternalLink className={styles.shareIcon} />
            </div>
          )}
        </div>
        <img src={imgSrc} />
      </div>
    </div>
  )
}
PaneContent.displayName = 'PaneContent'
export default PaneContent
