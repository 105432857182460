// 文档 https://developers.intercom.com/installing-intercom/docs/intercom-javascript
import { useCallback } from 'react'
import { LANGUAGE } from '@/constant'
import storageUtil from '@/utils/storageUtil'
import * as apis from './api'
import debounce from 'lodash/debounce'
import snakeCase from 'lodash/snakeCase'

// retry define
const RETRY_TIMES = 3
let current_retry_time = 0

// object convert to snakeCase
const object2snakeCase = (o: Record<string, any>) => {
  return Object.keys(o).reduce((acc, key) => {
    acc[snakeCase(key)] = o[key]
    return acc
  }, {} as Record<string, any>)
}

const getConfig = async () => {
  const { data: intercomConfig } = await apis.getIntercomConfig()

  storageUtil.set('interComConfig', intercomConfig as IInterComConfig)

  return intercomConfig
}

export const insertIntercom = async () => {
  await getConfig()

  // 初始化
  getIntercom().then(() => {
    updateIntercom({
      current_url: location.href,
    })
  })
}

export const getIntercomConfig = () => {
  const interComConfig = storageUtil.get('interComConfig') ?? {}
  const selfLanguageInfo = LANGUAGE.filter((item) => item.lang === window.localStorage.i18nextLng)
  const localLang = selfLanguageInfo[0] ? selfLanguageInfo[0].languageType : 'en'
  return Object.assign({ default_language_code: localLang, alignment: 'left', horizontal_padding: 20, vertical_padding: 20 }, object2snakeCase(interComConfig)) as IIntercomSettings
}

export function getIntercom(): Promise<any> {
  return new Promise((resolve, reject) => {
    const w = window
    const ic = w.Intercom

    const interComConfig = getIntercomConfig()

    // 没有 appid 就不初始化，防止切换语言或者其他误调用
    if (!interComConfig.app_id) return reject(new Error('appid not found'))

    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings || interComConfig)
      resolve(ic)
    } else {
      window.intercomSettings = interComConfig

      const script = document.createElement('script')
      script.async = true
      script.src = `https://${interComConfig.app_id}.intercom-chat.com/shim.latest.js`
      script.onload = () => resolve(w.Intercom)

      /**
       * error retry
       */
      script.onerror = () => {
        if (current_retry_time >= RETRY_TIMES) {
          reject()
        } else {
          current_retry_time++
          getIntercom()
        }
      }
      const x = document.getElementsByTagName('script')[0]
      x.parentNode?.insertBefore(script, x)
    }
  })
}

export function updateIntercom(data?: Record<string, any>) {
  const w = window

  return getIntercom().then((ic) => {
    w.intercomSettings = {
      ...getIntercomConfig(),
      ...(data ?? {}),
    }
    ic('update', { ...w.intercomSettings })
  })
}

export function updateIntercomLanguage() {
  updateIntercom()
}

export function shutdownIntercom() {
  const w = window
  const ic = w.Intercom
  ic?.('shutdown')
  w.intercomSettings = undefined
}

/**
 * 打开客服弹窗
 */
export function openIntercom() {
  getIntercom().then((ic) => ic('show'))
}

export function useIntercomWatchRouter() {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateRouterForInterCom = useCallback(
    debounce(() => {
      updateIntercom({
        current_url: location.href,
      })
      // here has the limit, whtin 30 seconds it's only allowed to do no more than 20 times.
      // so limit the users that if they click so fast, so debounce 1.5 seconds to update the data is the key to solve the problem.
      // https://developers.intercom.com/installing-intercom/docs/intercom-javascript
    }, 1500),
    []
  )

  return {
    updateRouterForInterCom,
  }
}
