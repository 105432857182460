import { DELIVER_VALUE_MAP } from '@/services/apis/sales/type'
import { calculateFreightAndFeeItem, convertAddress, formatFreightItem } from '../utils'
import { getCodAmountDatas } from '@/utils'
import { handlePayTypeOptions, update } from '..'
import type { FormInstance } from 'antd'
import type { AppDispatch, AppGetState } from '@/store'
import { getParcelTotalPriceWithForm } from '@/store/parcelSlice/utils'
import { PaymentTypeEnum } from '@/constant'
import type { IServiceProviderVO } from '../types'
import { isPlatformLogistics } from '@/utils/format/utils'

/**
 * 计算费用，包含
 * 1. 运费
 * 2. codAmount
 * 3. 进口关税
 * @param inputForm
 * @returns
 */
export const handleCalculateFreightAndFeeItems = (inputForm?: FormInstance) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { newServiceProvider } = getState()

  const { props, type, provider, list } = newServiceProvider

  //   没有服务产品的情况下不进行计算
  if (!provider || (['batch', 'reverse-batch'] as Array<IServiceProviderVO['type']>).includes(type)) {
    return
  }

  const { addressVO: inputAddressVO, supportedAddressTypes, parcel_weight, parcel_weight_unit, parcel_height, parcel_length, parcel_width } = props

  const { expressTypeCode, freightCurrencyCode, expressLogisticsType } = provider
  // 销售平台物流产品不计算运费
  if (isPlatformLogistics(expressLogisticsType)) return

  const isHome = supportedAddressTypes?.[0] === 'HOME_STREET'

  const addressVO = convertAddress({
    addressVO: inputAddressVO,
    type,
    isHome,
  })

  const params = [
    {
      expressTypeCode,
      metadatas: [
        {
          weightUnit: parcel_weight_unit || 'kg',
          weight: Number(parcel_weight),
          length: Number(parcel_length),
          width: Number(parcel_width),
          height: Number(parcel_height),
          lengthUnit: 'cm',
          calculateFreight: true,
          currencyCode: freightCurrencyCode, // 保费币种跟着运费币种
          metadataId: Math.floor(Math.random() * Math.pow(10, 20)) + '',
          senderAddress: addressVO?.senderAddressName,
          consigneeAddress: addressVO?.consigneeAddressName,
          partitionInfo: await dispatch(getPartitionInfo(inputForm)),
        },
      ],
    },
  ]

  dispatch(
    update({
      freightLoading: true,
    })
  )

  try {
    const feeRes = await calculateFreightAndFeeItem(params)

    const newProvider = {
      ...provider,
      /**
       * update freight and feeItems
       */
      ...formatFreightItem(feeRes),
    }

    const index = list?.findIndex((item) => item?.expressTypeCode === provider?.expressTypeCode)

    let newList = list?.slice()

    if (list?.length && index !== -1) {
      newList.splice(index, 1, newProvider)
      newList = newList.slice()
    }

    await dispatch(
      update({
        provider: newProvider,
        list: newList,
      })
    )

    await dispatch(handlePayTypeOptions())
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  } finally {
    dispatch(
      update({
        freightLoading: false,
      })
    )
  }
}

export const getPartitionInfo = (inputForm?: FormInstance) => async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance) => {
  const currentForm = inputForm || form
  const values = currentForm?.getFieldsValue(true)
  const { newServiceProvider } = getState()

  const { props, value: codValue } = newServiceProvider

  const { supportedAddressTypes } = props

  const { provider_payment_type } = values

  const isCod = provider_payment_type === PaymentTypeEnum.cod

  const amountData = isCod
    ? getCodAmountDatas({
        ...values,
        codCurrencyConverted: codValue?.codCurrencyConverted,
        provider_cod_amount: currentForm?.getFieldValue('provider_cod_amount') || values?.provider_cod_amount,
      })
    : null

  const provider_tax_no = currentForm?.getFieldValue('provider_tax_no')
  const provider_tax_type = currentForm?.getFieldValue('provider_tax_type')

  const isHome = supportedAddressTypes?.[0] === 'HOME_STREET'

  return {
    /**
     * for cod handling fee
     */
    codAmount: amountData?.codAmount,
    codCurrencyCode: amountData?.codCurrencyCode,

    /**
     * for freight
     */
    pickupType: currentForm?.getFieldValue('provider_pickup_type'),
    deliveryType: isHome ? DELIVER_VALUE_MAP.HOME : DELIVER_VALUE_MAP.STORE,

    /**
     *  for import taxes
     */

    taxes: provider_tax_no && provider_tax_type ? [{ taxNo: provider_tax_no, taxType: provider_tax_type }] : null,
    ...getParcelInfo(currentForm),
  }
}

export const getParcelInfo = (inputForm?: FormInstance) => {
  const data = getParcelTotalPriceWithForm(inputForm)

  return {
    valueOfGoods: data?.amount,
    valueOfGoodsCurrency: data?.parcel_currency_code as string,
  }
}
