import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from '@/store'
import type { IAddressVO } from '../../type'
import { concatAddress } from '../../util'
import OrderNosPopover from '../OrderNosPopover'
import styles from './index.module.less'

interface IAddressItemProps {
  address: Partial<IAddressVO>

  onClick?: (address: Partial<IAddressVO>) => void

  placeholder?: boolean
  focus?: boolean
  selected?: boolean

  orderNos?: string[]
  /**
   * 是否显示 店配/宅配 标签
   */
  showLabel?: boolean

  type?: string
}

const AddressItem: React.FC<IAddressItemProps> = ({ onClick, address, orderNos, children, placeholder, focus, selected, showLabel = false, type }) => {
  const countryDictMap = useSelector((state) => state.dictionary.countryMapDics)
  const { t } = useTranslation()

  const addressStr = useMemo(() => {
    const countryVO = countryDictMap[address?.countryCode]

    return concatAddress({
      ...address,
      country: countryVO?.countryName,
    })
  }, [address, countryDictMap])

  return (
    <div
      className={classNames(styles.wrap, {
        [styles.placeholder]: placeholder,
        [styles.focus]: focus,
        [styles.selected]: selected,
      })}
      onClick={() => onClick?.(address)}
    >
      <div className={styles.infoWrap}>
        <div className={styles.info}>
          {orderNos?.length > 0 && <OrderNosPopover type={type} orderNos={orderNos} />}
          {showLabel && <span className={styles.label}>{address?.stationCode ? t('order.list.storeDelivery') : t('order.list.houseDelivery')}</span>}
          <span className={styles.name}>{address?.name}</span>
          <span className={styles.phone}>{address?.mobile || address?.phone}</span>
        </div>
        <div className={styles.address}>{addressStr}</div>
      </div>
      {children && <div className={styles.operator}>{children}</div>}
    </div>
  )
}

AddressItem.displayName = 'AddressItem'
export default AddressItem
