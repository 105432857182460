import API from '@/services/http'
import { stationUrl } from '@/services/url'
import { isCNWebsiteFn } from '@/utils/address'
import { getExpressList } from '../express'
import type * as Types from './types'
import type { ExpressListPayload } from '../express/type'

const handleAddress = (addressInfo: Types.VerifyType): Types.VerifyType => {
  return {
    ...addressInfo,
    province: addressInfo.countryCode === 'SG' ? 'Singapore' : addressInfo.province,
    city: addressInfo.countryCode === 'SG' ? 'Singapore' : addressInfo.city,
  }
}

/**
 * 地址校验
 * @param payload
 * @returns
 */
export const addressVerify = (payload: Types.VerifyType) => {
  return API.toast.post<Types.AddressVerifyRes>('/api/address/verify', handleAddress(payload))
}

/** 地址新增 */
export const addressAdd = async (params: Types.AddressAddParams) => {
  return API.admin.post('/api/user/address/add', params)
}

/** 获取服务产品列表 */
export const fetchExpressList = async (params: ExpressListPayload) => {
  const response = await getExpressList({
    ...params,
    pageSize: 999, // 临时
    needStationType: true,
  })
  const { data } = response
  const expresses = data?.data || []
  return expresses
}

/** 获取收货地址区域列表 */
export const fetchAreaList = async (params: Types.FetchRecipientAddressAreaParams) => {
  const response = await API.admin.post<{ nodes: Types.Area[] }>('/api/address/list', {
    ...params,
    level: 4,
  })
  const { data } = response
  const { nodes: areas } = data
  return areas
}

/** 获取门店地址列表 */
export const fetchStoreAreaList = async (params: Types.FetchStationAreaListParams) => {
  const response = await API.admin.post<{ areaNodes: Types.StoreAreaList }>('/api/station/list_child', params)
  const { data } = response
  return data.areaNodes || []
}

/** 获取门店列表 | 收货地址 - 店配 */
export const fetchStationList = async (payload: Types.FetchStationListParams) => {
  const params = Object.assign({ pageSize: 20, pageNum: 1 }, payload || {})
  const response = await API.admin.post<Types.FetchStationListResp>(stationUrl.stationList, params)
  return response?.data
}

/** 获取过滤无效门店后的门店列表 - 店配 */
export const fetchStationListFilter = async (params: Types.FetchStationListParams) => {
  const response = await API.admin.post<Types.FetchStationListResp>(stationUrl.stationListNew, params)
  return response?.data
}

/** 获取支持的方式 */
export const fetchSupportMethods = async (params: Types.FetchSupportMethodsParams) => {
  const { departureCountryCode, arrivalCountryCode, logisticsType } = params
  const fetchAreaListParams: Record<string, any> = {
    needStationType: true,
  }

  if (departureCountryCode) {
    fetchAreaListParams.senderAddressName = {
      countryCode: departureCountryCode,
    }
  }

  if (arrivalCountryCode) {
    fetchAreaListParams.consigneeAddressName = {
      countryCode: arrivalCountryCode,
    }
  }

  const resp = await fetchExpressList({
    ...fetchAreaListParams,
    logisticsType,
  })
  const stringifySupportedMethods = resp?.map((item) => item.supportedAddressType)?.join(',') || ''
  const methodsMap: Record<string, Types.AddressTypeValues> = { HOME_STREET: 'home', STORE_STREET: 'store' }
  const supportedMethods: Types.AddressTypeValues[] = []
  Object.keys(methodsMap).forEach((name) => {
    if (-1 !== stringifySupportedMethods.indexOf(name)) {
      supportedMethods.push(methodsMap[name])
    }
  })

  return supportedMethods
}

/** 获取地图 url */
export const fetchMapUrl = (params: Types.FetchMapUrlParams) => {
  return API.admin.post<{ url: string }>('/api/emap/get_url', params)
}

/** 地址列表,根据国家和发件或收件查询 */
export const getAddressList = (params: { pageSize?: number; pageNum?: number; countryCode: string; addressType: number }) => {
  return API.admin.post<Types.AddressListState>(
    '/api/user/address/list',
    Object.assign(
      {
        pageNum: 1,
        pageSize: 10,
      },
      params || {}
    )
  )
}

/** 批量校验 */
export const postBatchVerify = (params: Types.OneshipBatchAddressVerifyRequest) => {
  return API.toast.post<Types.BatchVerifyRes>('/api/address/batch_verify', params)
}

/** 搜索省 */
export const getProvinceList = (params: Types.ISearchProvinceVO) => {
  return API.toast.post<{ results: Types.IProvinceResVO[] }>('/api/address-service/lib/province/listMultiLanguage', {
    ...params,
    // 中国无需
    languageOrder: isCNWebsiteFn() ? undefined : params?.languageOrder,
    /**
     * 使用多语言
     * @description
     * 可能 H5 会用到，后面确认所有端都不会用到非多语言可以去除
     */
    useMultiLanguage: true,
  })
}

/**
 * 获取地址子级
 * @param params
 * @returns
 */
export const getAddressListChildren = (params: Types.ISearchAddressListVO) => {
  return API.toast.post<{ nodes: Types.IAdddressNode[] }>('/api/address-service/lib/listChildren', params)
}

/**
 * 筛选器地址获取（即时物流地址）
 * @param params
 * @returns
 */
export const getFilterAddress = (params: Types.AddressFilterGetReqDTO) => {
  delete params.preferredLanguages
  return API.toast.post<{ nodeList: Types.AddressNodeDTO[]; language: string }>('/api/address-service/lib/filter/get', {
    ...params,
    // 中国无需
    // preferredLanguages: isCNWebsiteFn() ? undefined : params?.preferredLanguages,
  })
}

/** 地址智能识别 */
export const getAddressAutoSplitResult = async (params: Types.AutoSplitAddressReq[], sceneId?: string) => {
  const { data } = await API.toast.post<{ output: Types.AutoSplitAddressType[] }>('/api/address-service/split/smart-split', { input: params, sceneId })
  return data?.output || []
  // const mockData = {
  //   countryCode: 'HK',
  //   name: 'Ceci',
  //   areaCode: '0086',
  //   phoneNum: null,
  //   province: 'New Territories',
  //   city: 'North',
  //   district: 'Sheung Shui',
  //   town: '',
  //   address: '香港南区利乐街533号',
  //   zipCode: '999077',
  //   email: null,
  //   code: 200,
  //   msg: 'success',
  // }
  // return Promise.resolve(
  //   params.map((i, ind) => ({
  //     ...mockData,
  //     metadataId: String(ind),
  //   }))
  // )
}

/** 获取支持拆分算法的国家 */
export const getSupportAutoSplitCountryList = async () => {
  const { data } = await API.admin.post<{ items: Types.SplitSupportCountryType[] }>('/api/address-service/split/support?_sw_cache=15')
  return data?.items
}
