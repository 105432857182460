import type { TrackCoreParams, TrackEventType } from './types'

export const APP_ID = 'Oneship'

/** 聚数平台申请的项目act */
export const ACT = 'weboneshipperformance'

const toMoreInfo = (data: any) => ({
  seller_id: data?.seller_id,
  moreinfo: data,
})

/**
 * 埋点事件配置
 * @see https://jdp.eclytics.com/et/event (代码埋点 Tab)
 */
export const EVENT_ID_NAME_LIST: Record<TrackEventType, TrackCoreParams> = {
  Performance: {
    event_id: '2453',
    event_name: 'Performance',
  },
  eco: {
    event_id: '2976',
    event_name: 'eco',
  },
  apiPerformance: {
    event_id: '4291',
    event_name: 'apiPerformance',
  },
  businessTrack: {
    event_id: '6032',
    event_name: 'businessTrack',
  },
  /** 地址拆分算法使用记录 */
  addressSplitAlgorithm: {
    event_id: '6059',
    event_name: 'addressSplitAlgorithm',
    intercept: toMoreInfo,
  },
  /** 地址拆分算法创单记录 */
  addressSplitAlgorithmOrderCreate: {
    event_id: '6060',
    event_name: 'addressSplitAlgorithmOrderCreate',
    intercept: toMoreInfo,
  },
  /** 优惠活动弹窗事件 */
  giveawayModalOpen: {
    event_id: '6563',
    event_name: 'giveawayModalOpen',
    intercept: toMoreInfo,
  },
  /** 优惠活动参与事件 */
  giveawayClick: {
    event_id: '6564',
    event_name: 'giveawayClick',
    intercept: toMoreInfo,
  },
  /** 优惠活动问卷页面离开记录 */
  giveawayQuestionnaireLeave: {
    event_id: '6570',
    event_name: 'giveawayQuestionnaireLeave',
    intercept: toMoreInfo,
  },
}
