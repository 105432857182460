import React from 'react'
import { OneInput } from '@yy/one-ui'
import styles from './index.module.less'
import type { InputProps } from 'antd/lib/input'

interface Iprops {
  value?: string
  onChange?: (val: string) => void
  placeholder?: string
  'data-id'?: string
}
const InputBase: React.FC<InputProps & Iprops> = ({ value, onChange, placeholder, ...reset }) => {
  return <OneInput placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} className={styles.inputBase} {...reset} />
}

InputBase.displayName = 'InputBase'
export default InputBase
