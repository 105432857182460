import type { AxiosResponse } from 'axios'
import * as Sentry from '@sentry/react'
import { FILTER_CODES } from '@/constant/udbBizCode'
import { ERROR_TYPE_ENUM } from '@/services/sentry/constants'
import { newCustomError, throwError } from '@/services/sentry/errorWrapper'
import { logger } from '@/utils/logger'

/** admin 域 sentry 上报 */
export function adminSentryReport(rejection: Error | Record<string, any>, response: AxiosResponse<any>) {
  // 全局拦截器里面的createRejectedReason把rejection信息放到了 origin 字段
  const { config, data, status, headers } = response || (rejection as any).origin.response || {}
  const { url, method } = config || {}
  const { code, message, bizMessage, bizCode } = data || {}
  // 如果本身是error
  const isErrorObject = rejection instanceof Error
  const error = isErrorObject ? rejection : newCustomError(rejection.bizMessage || rejection.message, { stack: bizCode || code })

  /**
   * 因为部分接口是通过预请求获取的，而 traceid 正真的 traceid 无法获取到，
   * 必须通过后端接口返回，因此这里需要使用返回 traceId
   */
  const traceId = headers?.['x-traceid'] || headers?.['x-trace-id']
  Sentry.captureException(error, (scope) => {
    scope.setTag('type', ERROR_TYPE_ENUM[isErrorObject ? 'HTTP' : 'INTERACTION'])
    scope.setTag('traceId', traceId)
    scope.setExtras({ traceId, bizMessage, bizCode, code, message, rejection: JSON.stringify(rejection) })
    scope.addBreadcrumb({
      type: 'error',
      category: 'xhr',
      level: Sentry.Severity.Error,
      message: `${method?.toUpperCase()} ${url} [${status}]`,
      data: {
        traceId,
      },
    })

    return scope
  })
  logger.track({ type: 'action', event_type: 'error', event_target: 'api', data: data, url, trace_id: traceId })
}

/** udb 域 sentry 上报 */
export function udbSentryReport(rejection: Error | string, response: AxiosResponse<any>) {
  const { config, status, data } = response || (rejection as any).origin.response || {}

  // 过滤UDB业务码
  const { rescode: code } = data
  if (FILTER_CODES.includes(code)) return

  const { url, method } = config || {}
  // 如果本身是error
  const isErrorObject = rejection instanceof Error
  const error = isErrorObject ? rejection : newCustomError(rejection, { stack: code })

  Sentry.captureException(error, (scope) => {
    scope.setTag('type', ERROR_TYPE_ENUM[isErrorObject ? 'HTTP' : 'INTERACTION'])
    scope.addBreadcrumb({
      type: 'error',
      category: 'xhr',
      level: Sentry.Severity.Error,
      message: `${method?.toUpperCase()} ${url} [${status}]`,
    })

    return scope
  })
}

function getAddressHasError(address) {
  const { districtName, cityName, provinceName } = address || {}
  if (districtName && !cityName && !provinceName) {
    return 'missing cityName and provinceName'
  }
  if (districtName && !cityName) {
    return 'missing cityName'
  }
  if (districtName && !provinceName) {
    return 'missing provinceName'
  }
  if (cityName && !provinceName) {
    return 'missing provinceName'
  }
  return ''
}
/**
 * 地址错误参数上报(没上一级地址)
 * @param senderAddress
 * @param consigneeAddress
 */
export function sentryReportAddressError(senderAddress, consigneeAddress) {
  const senderAddressError = getAddressHasError(senderAddress)
  const consigneeAddressError = getAddressHasError(consigneeAddress)
  if (senderAddressError) {
    throwError(senderAddressError)
  }
  if (consigneeAddressError) {
    throwError(consigneeAddressError)
  }
}
