import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type * as Types from './types'

const initialState: Types.IInitialState = {
  selectedRow: [],
  countries: [],
}

const waybillTrackingSlice = createSlice({
  name: 'waybillTracking',
  initialState,
  reducers: {
    setSelectedRow(state, action: PayloadAction<any>) {
      return {
        ...state,
        selectedRow: action.payload,
      }
    },

    setCountries(state, action: PayloadAction<any>) {
      return {
        ...state,
        countries: action.payload,
      }
    },
  },
})

export const { setSelectedRow, setCountries } = waybillTrackingSlice.actions
export default waybillTrackingSlice.reducer
