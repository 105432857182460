import type * as Types from '@/types'

/** 待处理 */
const PENDING = 'pending' as const
/** 处理中 */
const PROCESSING = 'processing' as const
/** 未打印 */
const UNPRINTED = 'unprinted' as const
/** 已打印 */
const PRINTED = 'printed' as const
/** 已发货 */
const SHIPPED = 'shipped' as const
/** 已完成 */
const COMPLETED = 'completed' as const
/** 已退货 */
const RETURNED = 'returned' as const
/** 下单失败 */
const FAILED = 'failed' as const

/* 归档 */
export const ARCHIVE = 'archive'

/** 运单状态 */
export type TypeOneshipShipStatus = 'pending' | 'processing' | 'unprinted' | 'printed' | 'shipped' | 'completed' | 'returned' | 'failed'

export type UpperCaseShipStatus = Uppercase<TypeOneshipShipStatus>

/** 运单字典 */
export type OneshipStatusDict = Types.Dict<{ value: TypeOneshipShipStatus }>

export const labelMap = {
  [PENDING]: 'order.list.shipStatus.pending',
  [PROCESSING]: 'order.list.shipStatus.processing',
  [UNPRINTED]: 'order.list.shipStatus.unprinted',
  [PRINTED]: 'order.list.shipStatus.printed',
  [SHIPPED]: 'order.list.shipStatus.shipped',
  [COMPLETED]: 'order.list.shipStatus.completed',
  [RETURNED]: 'order.list.shipStatus.returned',
  [FAILED]: 'order.list.shipStatus.failed',
} as Record<TypeOneshipShipStatus, string>

export const ONESHIP_SHIP_TAB: Types.Dict<{ value: TypeOneshipShipStatus[] }> = [
  {
    value: [],
    label: 'order.list.tabAll',
  },
  {
    value: [PENDING, FAILED],
    label: labelMap.pending,
  },
  {
    value: [UNPRINTED],
    label: labelMap.unprinted,
  },
  {
    value: [PRINTED],
    label: labelMap.printed,
  },
  {
    value: [SHIPPED],
    label: labelMap.shipped,
  },
  {
    value: [COMPLETED],
    label: labelMap.completed,
  },
]

export const OTHER_TAB = [
  {
    label: 'order.list.archive',
    value: ARCHIVE,
  },
]

export const ONESHIP_SHIP_STATUS: OneshipStatusDict = [
  {
    value: PENDING,
    label: labelMap.pending,
  },
  {
    value: FAILED,
    label: labelMap.failed,
  },
  {
    value: PROCESSING,
    label: labelMap.processing,
  },
  {
    value: UNPRINTED,
    label: labelMap.unprinted,
  },
  {
    value: PRINTED,
    label: labelMap.printed,
  },
  {
    value: SHIPPED,
    label: labelMap.shipped,
  },
  {
    value: COMPLETED,
    label: labelMap.completed,
  },
  {
    value: RETURNED,
    label: labelMap.returned,
  },
]

export const ONESHIP_SHIP_STATUS_LABEL: Types.DictLabel<TypeOneshipShipStatus, { color: string }> = {
  [PENDING]: {
    label: labelMap.pending,
    color: 'processing',
  },
  [PROCESSING]: {
    label: labelMap.processing,
    color: 'processing',
  },
  [UNPRINTED]: {
    label: labelMap.unprinted,
    color: 'warning',
  },
  [PRINTED]: {
    label: labelMap.printed,
    color: 'warning',
  },
  [SHIPPED]: {
    label: labelMap.shipped,
    color: 'warning',
  },
  [COMPLETED]: {
    label: labelMap.completed,
    color: 'success',
  },
  [RETURNED]: {
    label: labelMap.returned,
    color: 'default',
  },
  [FAILED]: {
    label: labelMap.failed,
    color: 'error',
  },
}

/** 运单状态Keys */
export const ONESHIP_SHIP_STATUS_KEYS = {
  /** 待处理 */
  PENDING,
  /** 处理中 */
  PROCESSING,
  /** 未打印 */
  UNPRINTED,
  /** 已打印 */
  PRINTED,
  /** 已发货 */
  SHIPPED,
  /** 已完成 */
  COMPLETED,
  /** 已退货 */
  RETURNED,
  /** 下单失败 */
  FAILED,
}
export enum WaybillType {
  // 3PL面单类型
  _3pl = 0,
  // OMS面单类型
  OMS = 1,
  // 无须面单
  noNeed = 2,
  // 3PL规范绘制(手工申请)
  _artificial = 3,
}
/** 运单能申请打印面单类型 */
export const LOGISTIC_CAN_PRINT_TYPE = [WaybillType._artificial]
