import type * as Types from '@/types'
import options2Map from '@/utils/options2Map'

/** 交易类型 */
type DealType =
  | 'deposit'
  | 'present'
  | 'pay'
  | 'correct'
  | 'refund'
  | 'compensate'
  | 'adjust_account'
  | 'froze'
  | 'unfroze'
  | 'unfreezepay'
  | 'credit_update'
  | 'priority_fee'
  | 'premium'
  | 'invalid'

export const DEAL_TYPE: Types.Dict<{ value: DealType }> = [
  {
    value: 'deposit',
    label: 'wallet.searchType.charge',
  },
  {
    value: 'present',
    label: 'wallet.searchType.present',
  },
  {
    value: 'correct',
    label: 'wallet.searchType.freightCorrect',
  },
  {
    value: 'refund',
    label: 'wallet.searchType.refund',
  },
  {
    value: 'compensate',
    label: 'wallet.searchType.compensate',
  },
  {
    value: 'adjust_account',
    label: 'wallet.searchType.adjust',
  },
  {
    value: 'unfreezepay',
    label: 'wallet.searchType.unfrozepay',
  },
  {
    value: 'credit_update',
    label: 'wallet.preCredit',
  },
  {
    value: 'invalid',
    label: 'wallet.invalid',
  },
  // {
  //   value: 'priority_fee',
  //   label: 'wallet.priorityFee',
  // },
  // {
  //   value: 'premium',
  //   label: 'insurance.amount.name',
  // },
]

const optionsMap = options2Map(DEAL_TYPE, 'value', 'label') // 选项的map

export const DEAL_LIST_TYPE = {
  ...optionsMap,
  auto_correct: 'wallet.searchType.freightCorrect',
  priority_fee: 'wallet.priorityFee',
  premium: 'insurance.amount.name',
}
