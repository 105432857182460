import type { RouteConfig } from 'react-router-config'
import { Help } from '@yy/one-icon'
import { withAsync } from '@/components/Async'
import Blank from '@/layouts/Blank'

const routes: RouteConfig[] = [
  {
    /** 帮助中心 */
    alias: 'help_center',
    path: '/helps',
    component: Blank,
    menuTitle: 'menu.helpCenter',
    icon: Help,
    permissionCode: 'helps:guide',
    routes: [
      /** 新手上路 */
      {
        alias: 'user_manual',
        path: '/guide',
        component: withAsync(() => import(/* webpackChunkName: "Helps" */ '@/containers/Helps')),
        exact: true,
        menuTitle: 'help.novice',
      },
      /** 物流服务 */
      // {
      //   alias: 'logistics_service',
      //   path: '/couriers',
      //   component: withAsync(() => import(/* webpackChunkName: "Couriers" */ '@/containers/Couriers')),
      //   exact: true,
      //   menuTitle: 'courier.service',
      //   hideCountryCodes: ['CN'],
      // },
      /** 生态服务 */
      {
        alias: 'oneclub_services',
        path: '/eco',
        component: withAsync(() => import(/* webpackChunkName: "Eco" */ '@/containers/Eco')),
        exact: true,
        menuTitle: 'help.eco',
      },
    ],
  },
]

export default routes
