// except IPad to redirect

const MOBILE_ORIGIN = 'madmin.oneship.io'

const SITE_MAP = {
  'admin-dev.oneship.io': 'madmin-dev.oneship.io',
  'admin-test.oneship.io': 'madmin-test.oneship.io',
  'admin-preview.oneship.io': 'madmin-pre.oneship.io',
  'admin.oneship.io': 'madmin.oneship.io',
  'admin-dev.oneclub.vn': 'madmin-dev.oneclub.vn',
  'admin-test.oneclub.vn': 'madmin-test.oneclub.vn',
  'admin-preview.oneclub.vn': 'madmin-preview.oneclub.vn',
  'admin.oneclub.vn': 'madmin.oneclub.vn',
}

/**
 * 不跳转移动端的白名单列表
 */
const mobileWhitePathsList = ['/subscribe']

export class MobileAgentService {
  /**
   * 检查是否移动端访问
   */
  isMobile() {
    const ua = window.navigator.userAgent

    return /Android|webOS|iPhone|iPod|BlackBerry|Windows Phone/i.test(ua)
  }

  /**
   * 是否在不跳转的白名单内
   * @returns
   */
  whiteListMatch() {
    const path = window.location.pathname
    const isMatched = mobileWhitePathsList.some((whitePath) => path.includes(whitePath))

    return isMatched
  }

  /**
   * 是否需要跳转移动端
   */
  shouldChangeMobileOrigin() {
    return this.isMobile() && !this.whiteListMatch()
  }

  /**
   * 重定向
   * @returns
   */
  change2MobileOrigin() {
    const { host, href } = window.location

    const mobileOrigin = SITE_MAP[host]

    // 组装域名, backup
    const mobileSite = href.replace(host, mobileOrigin ?? MOBILE_ORIGIN)

    location.href = mobileSite
  }

  /**
   * 检查ua，确认是否跳转移动端
   * @returns
   */
  checkRedirect() {
    const res = this.shouldChangeMobileOrigin()
    if (res) this.change2MobileOrigin()
    return res
  }
}
