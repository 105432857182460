import { Row } from 'antd'
import React from 'react'
import './index.less'

interface CountryWithCodeProps {
  countryName: string
  countryCode: string
}

const CountryWithCode: React.FC<{ item: CountryWithCodeProps }> = ({ item }) => {
  const { countryCode, countryName } = item
  return (
    <Row justify="space-between" className="country-option">
      <span className="country-name">{countryName}</span>
      <span className="country-code">{countryCode}</span>
    </Row>
  )
}

CountryWithCode.displayName = 'CountryWithCode'
export default CountryWithCode
