import { useModal } from '.'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { ConfirmChangeCountryProps } from '@/components/ConfirmChangeCountry'
import { ConfirmChangeCountry } from '@/components/ConfirmChangeCountry'
import { useSelector } from '@/store'
import type { ModalCountries } from '@/store/oneshipOrderSlice/type'
import { ModalType } from '@/store/oneshipOrderSlice/type'

export function useCountryModal() {
  const { t } = useTranslation()
  const { isOpen, open, close } = useModal()
  const [comfirmModalType, setComfirmModalType] = useState('')
  const countryDicsMap = useSelector((state) => state.dictionary.countryMapDics)
  const selfArea = useSelector((state) => state.user.info?.selfArea)
  const [modalCountry, setModalCountry] = useState<ModalCountries>({ sender: '', consignee: '' })

  const countryConfirmContent = useMemo(() => {
    const [sender, consignee] = [countryDicsMap?.[modalCountry?.sender || selfArea]?.countryName, countryDicsMap?.[modalCountry?.consignee]?.countryName]
    const okText = t('auth.area.noAuth.okText')
    const contentText =
      comfirmModalType === ModalType.ORDER
        ? t('order.area.noAuth', { to: sender })
        : t('country.rejectChangeCountry', {
            to: consignee,
            from: sender,
          })
    return { okText, contentText }
  }, [comfirmModalType, countryDicsMap, modalCountry?.consignee, modalCountry?.sender, t, selfArea])

  const handleComfirmShow = useCallback(
    (param: ModalCountries, type?: string) => {
      type && setComfirmModalType(type)
      open()
      setModalCountry(param)
    },
    [open]
  )

  const renderCountryModal = useCallback(
    (props?: ConfirmChangeCountryProps) => {
      return <ConfirmChangeCountry {...countryConfirmContent} {...props} onOk={close} onCancle={close} open={isOpen} />
    },
    [close, countryConfirmContent, isOpen]
  )

  return useMemo(() => ({ isOpen, open, close, renderCountryModal, handleComfirmShow }), [isOpen, open, close, renderCountryModal, handleComfirmShow])
}
