import type { RouteConfig } from 'react-router-config'
import { withAsync } from '@/components/Async'
import { withAuthRedirect } from '@/components/Authorized/hoc/withAuthRedirect'
import Blank from '@/layouts/Blank'

const routes: RouteConfig[] = [
  // 店铺管理
  {
    alias: 'store_management',
    path: '/stores',
    component: withAuthRedirect(Blank, { first: '/setting/stores/list' }),
    menuTitle: 'frame.stores',
    group: 'setting',
    permissionCode: 'settings:store',
    routes: [
      {
        path: '/list',
        component: withAsync(() => import(/* webpackChunkName: "Stores/List" */ '@/containers/Stores/List')),
        menuTitle: 'frame.stores',
        exact: true,
      },
      {
        path: '/setting/:storeSeq',
        component: withAsync(() => import(/* webpackChunkName: "Stores/List" */ '@/containers/Stores/Setting')),
        menuTitle: 'frame.stores',
        exact: true,
      },
      {
        path: '/syncSetting/:storeSeq',
        component: withAsync(() => import(/* webpackChunkName: "Stores/List" */ '@/containers/Stores/Setting/SyncSettingPage')),
        menuTitle: 'frame.stores',
        exact: true,
      },
      {
        path: '/checkoutSetting/:storeSeq',
        component: withAsync(() => import(/* webpackChunkName: "Stores/List" */ '@/containers/Stores/Setting/ShippingAtCheckout')),
        menuTitle: 'frame.stores',
        exact: true,
      },
      // 门店地址更新设置
      {
        path: '/updateStoreAddress/:storeSeq',
        menuTitle: 'frame.stores',
        component: withAsync(() => import(/* webpackChunkName: "Stores/List" */ '@/containers/Stores/Setting/UpdateStoreAddress')),
        exact: true,
      },
    ],
  },
]

export default routes
