import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { IState } from './types'

const initialState: IState = {
  expressTypeCode: undefined,
}

const preSelectedProviderSlice = createSlice({
  name: 'preSelectedProvider',
  initialState,
  reducers: {
    update(state, action: PayloadAction<IState>) {
      const updateState = action.payload

      return {
        ...state,
        ...updateState,
      }
    },
  },
})

export const { update } = preSelectedProviderSlice.actions

export default preSelectedProviderSlice.reducer
