import { Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { OneModal } from '@yy/one-ui'

export interface ConfirmChangeCountryProps {
  open?: boolean
  onOk?: () => void
  onCancle?: () => void
  contentText?: string
  okText?: string
}

export const ConfirmChangeCountry: React.FC<ConfirmChangeCountryProps> = (props) => {
  const { open = false, onOk, onCancle, contentText, okText } = props
  const { t } = useTranslation()
  return (
    <OneModal
      closable={false}
      width={400}
      visible={open}
      onCancel={onCancle}
      onOk={onOk}
      okText={okText || t('auth.area.noAuth.okText')}
      cancelText={t('order.create.draftCancel')}
    >
      <Row style={{ marginTop: 30 }} justify="center">
        {contentText}
      </Row>
    </OneModal>
  )
}

ConfirmChangeCountry.displayName = 'ConfirmChangeCountry'
