import type { RouteConfig } from 'react-router-config'
import { withAsync } from '@/components/Async'

const routes: RouteConfig[] = [
  /** 物流服务 */
  {
    alias: 'cross_border_logistics_service',
    path: '/couriers',
    component: withAsync(() => import(/* webpackChunkName: "CrossBorderCouriers" */ '@/containers/CrossBorderCouriers')),
    exact: true,
    menuTitle: 'courier.service',
    permissionCode: 'ship:couriers',
    showCountryCodes: ['CN'],
  },
]

export default routes
