import API from '@/services/http'
import i18n from '@/services/i18n'
import { httpWithAsyncTask } from '@/utils/httpWithAsyncTask'
import type {
  BatchCreateState,
  BatchCreateValidateRes,
  IOrder2LogisticsPayload,
  IOrder2LogisticsRes,
  OrderBatchCreateRequest,
  BatchCreateValidateRequest,
  S3ResponseType,
  BatchCreateUpdateRequest,
  ILogistics2ReversePayload,
} from './type'
import type { IExtraTaskOptions } from '@/hooks'

/**
 * 获取订单转运单数据
 * @param params
 * @returns
 */
export const fetchOrder2Logistics = (params: IOrder2LogisticsPayload) => API.admin.post<IOrder2LogisticsRes>('/api/sales/order/convert_to_logistics_order', params)

/**
 * 获取订单转退货单数据
 * @param params
 * @returns
 */
export const fetchOrder2ReverseLogistics = (params: IOrder2LogisticsPayload) => API.admin.post<IOrder2LogisticsRes>('/api/reverse/sales_order/convert', params)

/**
 * 获取订单转退货单数据
 * @param params
 * @returns
 */
export const fetchLogistics2ReverseLogistics = (params: ILogistics2ReversePayload) => API.admin.post<IOrder2LogisticsRes>('/api/reverse/logistics_order/convert', params)

/**
 * 订单Excel批量发货预校验
 * @param payload
 * @returns
 */
export const batchCreateValidate = (payload: BatchCreateValidateRequest & IExtraTaskOptions) =>
  API.toast.post<{ taskId: string }>('/api/sales/order/batch-create/pre-validate', payload)

/**
 * 批量导入发货/批量创建 - 查询订单分页
 * @param payload
 * @returns
 */
export const batchCreatePage = (payload: { taskId: string; pageSize: number; pageNum: number }) =>
  API.toast.post<BatchCreateValidateRes>('/api/sales/order/batch-create/page', payload)

/**
 * 批量导入发货/批量创建 - 全量更新订单数据
 * @param payload
 * @returns
 */
export const batchFullUpdateOrder = async (payload: OrderBatchCreateRequest) => {
  return httpWithAsyncTask('/api/sales/order/batch-create/full/update', payload, { timeout: 30, taskType: 'BATCH_CREATE_FULL_UPDATE' })
}

/**
 * 订单Excel批量发货
 * @param payload
 * @returns
 */
export const batchCreateOrder = async (payload: { taskId: string; asyncSend: boolean }) => API.toast.post<BatchCreateState>('/api/sales/order/batch-create/submit', payload)

/**
 * 订单Excel批量更新并且发货
 * @param payload
 * @returns
 */
export const batchUpdateAndCreateOrder = async (payload: OrderBatchCreateRequest) => {
  // 全量更新数据，等websocket通知成功后，批量创建订单
  await batchFullUpdateOrder(payload)
  const { data } = await batchCreateOrder({ taskId: payload.taskId, asyncSend: payload.asyncSend })
  return data
}

/**
 * 批量导入发货/批量创建 - 更新局部订单数据
 * @param payload
 * @returns
 */
export const batchUpdateOrder = async (payload: BatchCreateUpdateRequest) =>
  API.admin.post<BatchCreateState>('/api/sales/order/batch-create/update', payload, { timeout: 3 * 30e3 })

/** 生成预授权上传URL */
export const getAmazonS3Api = async (uploadSceneId) => {
  const response = await API.admin.post<S3ResponseType>('/api/media/s3/get_upload_pre_Url', { uploadSceneId })
  const { data } = response
  const parseUploadInfo = (infoArray: Record<string, any>[]) => {
    if (!infoArray) {
      return {}
    }
    const data = {}
    infoArray.forEach((info) => {
      data[info.key] = info.value
    })
    return data
  }

  const { actionUrl, fields, headers, fileId } = data
  const uploadFormData = new FormData()

  // 组装formdata 数据
  fields.forEach((item: any) => {
    uploadFormData.append(item.key, item.value)
  })

  return {
    actionUrl,
    data: uploadFormData,
    headers: { ...parseUploadInfo(headers || []), 'content-type': 'multipart/form-data' },
    fileId,
  }
}

/**
 * 上传到亚马逊
 * @param params
 * @returns
 */
export const uploadAmazon = async (params: { url: string; formData: FormData; headers: Record<string, string> }) => {
  const response = await API.post(params.url, params.formData, { headers: { ...params.headers }, timeout: 60e3, withCredentials: false })
  if (response.status === 204) {
    return response
  }

  throw new Error(i18n.t('batchOrder.uploadError'))
}
