import type * as Types from '@/types'

/** 原订单状态 */
export type OriginOrderStatus = 'pending' | 'removed' | 'confirmed' | 'completed' | 'cancelled'

// server string

/** 原订单状态 */
export const ORIGIN_ORDER_STATUS_LABEL: Types.DictLabel<OriginOrderStatus, { color: string }> = {
  pending: {
    label: 'order.list.orderStatusProcessing',
    color: 'turquoise',
  },
  removed: {
    label: 'order.list.orderStatusDeleted',
    color: 'yellow',
  },
  confirmed: {
    label: 'order.list.orderStatusConfirmed',
    color: 'green',
  },
  completed: {
    label: 'order.list.orderStatusCompleted',
    color: 'blackA',
  },
  cancelled: {
    label: 'order.list.orderStatusCancelled',
    color: 'blackA',
  },
}

/** 上游支付状态 */
type OriginPaymentStatus = 'pending' | 'failed' | 'expired' | 'completed' | 'refunding' | 'refunded'

/** 上游支付状态 */
export const ORIGIN_PAYMENT_STATUS_LABEL: Types.DictLabel<OriginPaymentStatus, { color: string }> = {
  pending: {
    label: 'order.list.paymentStatusUnpaid',
    color: 'turquoise',
  },
  failed: {
    label: 'order.list.paymentStatusUnpaid',
    color: 'turquoise',
  },
  expired: {
    label: 'order.list.paymentStatusUnpaid',
    color: 'turquoise',
  },
  completed: {
    label: 'order.list.paymentStatusPaid',
    color: 'yellow',
  },
  refunding: {
    label: 'order.list.paymentStatusProcessingRefund',
    color: 'green',
  },
  refunded: {
    label: 'order.list.paymentStatusRefunded',
    color: 'blackA',
  },
}

/** 上游履约状态 */
type OriginDeliveryStatus = 'pending' | 'shipping' | 'shipped' | 'arrived' | 'collected' | 'returned' | 'returning'

/** 上游履约状态 */
export const ORIGIN_DELIVERY_STATUS_LABEL: Types.DictLabel<OriginDeliveryStatus, { color: string }> = {
  pending: {
    label: 'order.list.deliveryStatusPreparing',
    color: 'turquoise',
  },
  shipping: {
    label: 'order.list.deliveryStatusShipping',
    color: 'yellow',
  },
  shipped: {
    label: 'order.list.deliveryStatusShipped',
    color: 'green',
  },
  arrived: {
    label: 'order.list.deliveryStatusArrived',
    color: 'blackA',
  },
  collected: {
    label: 'order.list.deliveryStatusPickedUp',
    color: 'blackA',
  },
  returned: {
    label: 'order.list.deliveryStatusReturned',
    color: 'blackA',
  },
  returning: {
    label: 'order.list.deliveryStatusReturning',
    color: 'blackA',
  },
}
