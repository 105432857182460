import type { AppDispatch, AppGetState, AppThunk } from '..'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { OneMessage, OneModal } from '@yy/one-ui'
import { GET_DEFAULT_PAGE_SIZE } from '@/constant'
import { awaitAsyncTask, createWsMsgId } from '@/hooks'
import { postBatchDeliveryPage, postFullUpdate, postPreprocess } from '@/services/apis/batchship'
import type { BatchDeliveryFullUpdateRequest } from '@/services/apis/batchship/type'
import i18n from '@/services/i18n'
import { getUrlParam } from '@/utils/getUrlParam'
import { EnumTabs } from './enum'
import type * as Types from './types'
import { genTraceParent } from '@/utils'

export * from './enum'

export * from './enum'

export const getKeyInfo = (tab: string) => {
  const isInvalid = tab === EnumTabs.INVALID
  const isValid = tab === EnumTabs.VALID
  return {
    isInvalid,
    isValid,
  }
}

const initialState: Types.IInitialState = {
  tab: EnumTabs.VALID,
  // in case of users refresh the page
  taskId: '',
  invalidCount: 0,
  validCount: 0,
  currencyConverted: 0,
  list: [],
  validList: [],
  invalidList: [],
  pagination: {
    pageSize: GET_DEFAULT_PAGE_SIZE(),
    pageNum: 1,
    total: 0,
  },
  loading: false,
  totalFreight: 0,
  settingList: [],
  rightsResults: [],
  summaryFees: [],
  invalidCorrectAddressCount: 0,
  validCorrectAddressCount: 0,
}

/** 批量发货 */
const batchShipSlice = createSlice({
  name: 'newBatchShipSlice',
  initialState: {
    ...initialState,
    taskId: getUrlParam('taskId'),
  },
  reducers: {
    update(state, action: PayloadAction<Partial<Types.IInitialState>>) {
      const pagination = {
        ...(state.pagination || {}),
        ...(action.payload.pagination || {}),
      }

      return {
        ...state,
        ...action.payload,
        pagination,
      }
    },
  },
})

export const { update } = batchShipSlice.actions

export const clearBatchShipData = (): AppThunk => async (dispatch) => {
  dispatch(
    update({
      ...initialState,
    })
  )
}

export default batchShipSlice.reducer

export const handleMessage = (res) => {
  const key = `system.error.${res?.response?.code}`
  const parametes = {
    ...res?.extMap,
    privacyAppealOrderNos: res?.extMap?.privacyAppealOrderNos?.join(',') || '',
  }
  const message = key === i18n.t(key, parametes) ? res?.message : i18n.t(key, parametes)

  OneModal.error({
    content: message || i18n.t('system.remoteError.SYSTEM_ERROR'),
    centered: true,
    okText: i18n.t('common.Ikonw'),
  })
}

export const startListenForBatchship =
  (orderNos: string[], gotoMethod?: (taskId: string) => void): AppThunk =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState()
    const { batchshipTimeout } = state.configSlice

    await dispatch(update({ loading: true }))

    try {
      const extraTaskOptions = createWsMsgId()

      const prepostRes = await postPreprocess({
        orderNos,
        ...extraTaskOptions,
      })
      const { taskId } = prepostRes.data
      await dispatch(update({ taskId }))

      if (gotoMethod) {
        gotoMethod(taskId)
      }

      const res = await awaitAsyncTask(taskId, {
        timeout: Number(batchshipTimeout) * 1000,
        ...extraTaskOptions,
      })

      if (!res) {
        return OneMessage.error(i18n.t('system.error.2'))
      }
      // get list
      if (res?.response?.success) {
        dispatch(getList())
        // timeout or others error
      } else {
        handleMessage(res.response)
      }

      dispatch(update({ loading: false }))
    } finally {
      dispatch(update({ loading: false }))
    }
  }

export const updateParamters =
  (props: Partial<Pick<Types.IInitialState, 'pagination' | 'taskId' | 'tab'>>, updateList = true): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(update(props))

    if (updateList) {
      dispatch(getList())
    }
  }

export const getList = (): AppThunk => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { newBatchShipSlice } = getState()
  const { taskId, tab, pagination } = newBatchShipSlice

  if (!taskId) return

  dispatch(update({ loading: true }))
  try {
    const res = await postBatchDeliveryPage({
      logisticsType: 'express',
      taskId,
      validGroup: Number(tab),
      ...pagination,
    })

    const { data, summaryFees, rightsResults, invalidCount, validCount, currencyConverted, invalidCorrectAddressCount, validCorrectAddressCount } = res?.data || {}

    dispatch(
      update({
        list: data,
        summaryFees,
        rightsResults,
        invalidCount,
        validCount,
        currencyConverted,
        validCorrectAddressCount,
        invalidCorrectAddressCount,
      })
    )
  } finally {
    dispatch(update({ loading: false }))
  }
}

export const fullBatchUpdate =
  (data: BatchDeliveryFullUpdateRequest['data']): AppThunk =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState()
    const { batchshipTimeout } = state.configSlice
    const { tab, taskId } = state.newBatchShipSlice

    const nonce = genTraceParent()
    const extraTaskOptions = createWsMsgId()

    try {
      await postFullUpdate({
        taskId,
        valid: tab === EnumTabs.VALID,
        data,
        nonce,
        ...extraTaskOptions,
      })

      const _getList = () => {
        setTimeout(() => {
          dispatch(getList())
        }, 500)
      }

      const result = await awaitAsyncTask(nonce, { idType: 'nonce', timeout: Number(batchshipTimeout) * 1000, ...extraTaskOptions })

      if (!result) {
        return OneMessage.error(i18n.t('system.error.2'))
      }
      // get list
      if (result?.response?.success) {
        _getList()
        // timeout or others error
      } else {
        handleMessage(result.response)
      }
    } finally {
      dispatch(update({ loading: false }))
    }
  }
