import { omit, pick, values } from 'lodash'
import React, { useContext, useMemo } from 'react'
import type { FieldValues, FormProviderProps, UseFormMethods } from 'react-hook-form'
import { FormProvider } from 'react-hook-form'
import type { UseDynamicFormMethods } from '../../hooks/useDynamicForm'

type DynamicFormMethodNames = Exclude<keyof UseDynamicFormMethods, keyof UseFormMethods>
type DynamicFormMethodContext<T extends FieldValues = FieldValues> = Pick<DynamicFormProviderProps<T>, DynamicFormMethodNames>

const context = React.createContext<DynamicFormMethodContext<any>>(null)
const { Provider: DynamicFormMethodsProvider } = context

export function useDynamicFormMethodsContext<T extends FieldValues = FieldValues>(): DynamicFormMethodContext<T> {
  return useContext(context)
}

const ExtraMethods: Array<DynamicFormMethodNames> = [
  'setCache',
  'getCache',
  'setCaches',
  'getCaches',
  'clearCaches',
  'getPersistenceValue',
  'setPersistenceValues',
  'getPersistenceValues',
  'setPersistenceValue',
  'clearPersistenceValues',
  'setValues',
]

export interface DynamicFormProviderProps<T extends FieldValues = FieldValues> extends Omit<FormProviderProps<T>, DynamicFormMethodNames | 'setValue'>, UseDynamicFormMethods<T> {}
export const DynamicFormProvider: React.FC<DynamicFormProviderProps> = (props) => {
  const restProps = omit(props, ExtraMethods)
  const extraMethods = pick(props, ExtraMethods)
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const methods = useMemo(() => extraMethods, values(extraMethods))

  return (
    <DynamicFormMethodsProvider value={methods}>
      <FormProvider {...restProps} />
    </DynamicFormMethodsProvider>
  )
}

DynamicFormProvider.displayName = 'DynamicFormProvider'
