import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

export interface Iprops {
  to: string
  from?: string
}

const RedirectOrder: React.FC<Iprops> = (props) => {
  const { to, from } = props
  const location = useLocation()
  const q = location.search
  const path = !!from ? location.pathname.replace(from, to) + q : to + q
  return <Redirect to={path} />
}
RedirectOrder.displayName = 'RedirectOrder'
export default RedirectOrder
