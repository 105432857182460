import qs from 'qs'

/**
 * 获取url中指定参数的值
 * @param name 需要获取的参数名
 * @param url 需要被处理的url，默认为当前url
 * @return 对应的参数值
 */
export function getUrlParam(name: string, url?: string): string {
  const paramReg = new RegExp(`[\\?&#]${name}=([^&#]+)`, 'gi')
  const paramMatch = (url || window.location.href).match(paramReg)
  let paramResult = []
  if (paramMatch && paramMatch.length > 0) {
    paramResult = paramMatch[paramMatch.length - 1].split('=')
    if (paramResult && paramResult.length > 1) {
      return paramResult[1]
    }
    return ''
  }
  return ''
}

export function getUrlParamReturnUndefined(name: string, url?: string) {
  const result = getUrlParam(name, url)
  return result ? result : undefined
}

export function getQuery<T extends Record<string, any>>(url?: string): T {
  const curUrl = url ?? location.href
  if (!/\?/.test(curUrl)) return {} as T
  const queryStr = curUrl.replace(/^.*\?/, '')
  return queryStr
    .split('&')
    .map((str) => str.split('='))
    .reduce((acc, [k, v]) => {
      acc[k] = decodeURIComponent(v)
      return acc
    }, {} as any) as T
}

/**
 * 删除url上的query参数
 * searchParams会把空格编码转成了+，可以使用useQs规避
 * @param param 需要删除的key
 */
export function deleteUrlQuery(param: string, options?: { useQs?: boolean }) {
  const useQs = options?.useQs
  try {
    // searchParams会把空格编码转成了+
    // https://www.cnblogs.com/skychx/p/http-space-to-what.html
    if (useQs) {
      const queryObj = qs.parse(window.location.search, { ignoreQueryPrefix: true })
      delete queryObj[param]
      const newSearch = qs.stringify(queryObj, { addQueryPrefix: true })
      const url = `${window.location.origin}${window.location.pathname}${newSearch}${window.location.hash}`
      history.replaceState(null, null, url)
    } else {
      const url = new URL(window.location.href)
      url.searchParams.delete(param)
      history.replaceState(null, null, url)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export function stringifyQuery(url: string, query: Record<string, any>) {
  return (
    url +
    '?' +
    Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')
  )
}

export const formmatQueryObjectToString = (data?: Record<string, any>) => {
  if (!data) return ''
  const pairStr = Object.keys(data).reduce((acc, key) => {
    return acc.concat(`${key}=${data[key]}`)
  }, [] as string[])

  return pairStr.length ? `?${pairStr.join('&')}` : ''
}
