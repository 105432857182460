// 下载发货证明
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OneMessage } from '@yy/one-ui'
import { downLoadCertifier } from '@/services/apis'
import { useMemoizedFn } from '../utilsHooks'

export const useCertifierDownLoad = ({ onSuccess, rowChecked }: { onSuccess?: () => void; rowChecked: { logisticsNo: string; presignedDeliveryProofUrl?: string }[] }) => {
  const { t } = useTranslation()
  // 是否选择的订单全部都有托运单
  const allHasCertifier = useMemo(() => rowChecked.length < 500 && rowChecked.every((item) => !!item.presignedDeliveryProofUrl), [rowChecked])
  const certifierDownLoad = useMemoizedFn(async () => {
    await downLoadCertifier({
      logisticsNos: rowChecked.map((i) => i.logisticsNo),
    })
    OneMessage.success(t('logistics.list.orderListExportSuccess'))
    onSuccess?.()
  })

  return { allHasCertifier, certifierDownLoad }
}
