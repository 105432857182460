import Performance from './performance'

/** 动态引入SDK，执行代码上报 */
const init = async () => {
  const { tracker } = await import('@yy/jdp-tracker')
  const data = await Performance.run()
  tracker.report(data as any)
}

window.onload = () => {
  if (process.env.APP_ENV !== 'product') return
  // 尽量不影响页面主线程
  if (window.requestIdleCallback) {
    window.requestIdleCallback(init)
  } else {
    setTimeout(init)
  }
}

/** 标记tti时间 */
export const markTTI = () => {
  try {
    const arr = performance.getEntriesByName('tti')
    if (arr.length) return
    performance.mark('tti')
  } catch (error) {}
}
