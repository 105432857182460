import React from 'react'

/**
 * 不是必填，需要显示选填文案
 */

export interface AreaLabelProps {
  label: string
  required?: boolean
}

export const AreaLabel: React.FC<AreaLabelProps> = ({ label }) => {
  return (
    <>
      {label}
      {/* {!required && <span className={styles.optional}>{t('common.optional')}</span>} */}
    </>
  )
}

AreaLabel.displayName = 'AreaLabel'
