import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { fetchLogistics2ReverseLogistics, fetchOrder2Logistics, fetchOrder2ReverseLogistics } from '@/services/apis'
import Api from '@/services/http'
import { userUrl } from '@/services/url'
import type { AppDispatch } from '@/store'
import type * as Types from './types'

const initialState: Types.InitialState = {
  hasBookService: false,
  departureRadioType: 'departureAddr',
  arrivalRadioType: 'arrivalAddr',
  serviceList: null,
  isEdit: false,
  isShip: false,
  currentExpressTypeCode: '',
  isMergeShip: false,
  hasMergeAddressShow: false,
}

const { reducer, actions } = createSlice({
  name: 'order.create',
  initialState,
  reducers: {
    setHasBookService(state, action: PayloadAction<boolean>): Types.InitialState {
      return {
        ...state,
        hasBookService: action.payload,
      }
    },
    setDepartureRadioType(state, action: PayloadAction<Types.TDepartureRadioType>): Types.InitialState {
      return {
        ...state,
        departureRadioType: action.payload,
      }
    },
    setServiceList(state, action: PayloadAction<Types.TServiceList>): Types.InitialState {
      return {
        ...state,
        serviceList: action.payload,
      }
    },
    setIsEdit(state, action: PayloadAction<boolean>): Types.InitialState {
      return {
        ...state,
        isEdit: action.payload,
      }
    },
    setIsShip(state, action: PayloadAction<boolean>): Types.InitialState {
      return {
        ...state,
        isShip: action.payload,
      }
    },
    setCurrentExpressTypeCode(state, action: PayloadAction<string>): Types.InitialState {
      return {
        ...state,
        currentExpressTypeCode: action.payload,
      }
    },
    setAddressOverview(state, action: PayloadAction<Types.AddressOverviewState>): Types.InitialState {
      return {
        ...state,
        addressOverview: action.payload,
      }
    },
    setLogisticsOrder(state, action: PayloadAction<Types.LogisticsOrderInfo>): Types.InitialState {
      return {
        ...state,
        logisticsOrderInfo: action.payload,
      }
    },
    setIsMergeShip(state, action: PayloadAction<boolean>): Types.InitialState {
      return {
        ...state,
        isMergeShip: action.payload,
      }
    },
    setHasMergeAddressShow(state, action: PayloadAction<boolean>): Types.InitialState {
      return {
        ...state,
        hasMergeAddressShow: action.payload,
      }
    },
  },
})

interface GetAddressOverviewParams {
  userStoreSeqs?: string[]
}

/**
 * 用户地址信息总览
 * @description
 * 包含:
 * - 发件-用户默认地址
 * - 发件-店铺默认地址
 */
export const getAddressOverview = (params?: GetAddressOverviewParams) => async (dispatch: AppDispatch) => {
  const userStoreSeqs = params?.userStoreSeqs
  const response = await Api.admin.post<{ sender: Types.AddressOverviewState }>(userUrl.addressOverview, { userStoreSeqs })
  const { bizCode, bizMessage, message, data } = response
  if (bizCode === 'SUCCESS') {
    const { userDefault, storeDefault } = data.sender
    const addressOverview = { userDefault, storeDefault }
    dispatch(actions.setAddressOverview(addressOverview))
    return addressOverview
  }

  return Promise.reject({ bizCode, bizMessage, message })
}

/** 订单转运单 */
export const convertOrderToLogisticsOrder =
  (params: { orderNos: string[]; convertCurrencyType?: number; type?: 'order2Logistics' | 'order2Reverse' | 'Logistice2Reverse' }) => async (dispatch: AppDispatch) => {
    const { orderNos, convertCurrencyType = 1, type = 'order2Logistics' } = params
    let fetchFn = fetchOrder2Logistics
    switch (type) {
      case 'order2Reverse':
        fetchFn = fetchOrder2ReverseLogistics
        break
    }
    const response = await fetchFn({ orderNos, convertCurrencyType })
    const { bizCode, bizMessage, message, data } = response
    if (bizCode === 'SUCCESS') {
      dispatch(actions.setLogisticsOrder(data))
      return data
    }

    return Promise.reject({ bizCode, bizMessage, message })
  }

/** 订单转运单 */
export const convertLogisticsToReverse = (params: { logisticsNos: string[] }) => async (dispatch: AppDispatch) => {
  const { logisticsNos } = params
  const response = await fetchLogistics2ReverseLogistics({ logisticsNos })
  const { bizCode, bizMessage, message, data } = response
  if (bizCode === 'SUCCESS') {
    dispatch(actions.setLogisticsOrder(data))
    return data
  }

  return Promise.reject({ bizCode, bizMessage, message })
}

export const {
  setLogisticsOrder,
  setHasBookService,
  setDepartureRadioType,
  setServiceList,
  setIsEdit,
  setIsShip,
  setCurrentExpressTypeCode,
  setIsMergeShip,
  setHasMergeAddressShow,
} = actions
export default reducer
