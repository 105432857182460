/**
 * options转map
 * @param option
 * @param keyField
 * @param valueField
 * @returns
 */
const option2Map: <T = string>(option: Array<any>, keyField?: string, valueField?: string) => Record<string, T> = (option, keyField = 'label', valueField = 'value') =>
  Array.isArray(option)
    ? option.reduce((sum, cur) => {
        const value = cur[valueField]
        const key = cur[keyField]
        sum[key] = value
        return sum
      }, {})
    : []

export default option2Map
