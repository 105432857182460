import classNames from 'classnames'
import React, { useCallback } from 'react'
import { OneInputNumber } from '@yy/one-ui'
import styles from './index.module.less'

type InputProps = React.ComponentProps<typeof OneInputNumber>

const CustomNumberInput: React.FC<InputProps> = (args) => {
  const currencyParser = useCallback((value) => {
    return value?.toString().replace(/\$\s?|(,*)/g, '')
  }, [])

  const formatter: InputProps['formatter'] = useCallback((value) => {
    return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }, [])

  return <OneInputNumber {...args} formatter={formatter} parser={currencyParser} className={classNames(styles.input, args.className)}></OneInputNumber>
}

export default CustomNumberInput
