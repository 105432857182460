import { useRequest } from 'ahooks'
import { useCallback, useMemo } from 'react'
import { OneMessage } from '@yy/one-ui'
import { REF_OBJECT } from '@/constant'
import * as subscribeService from '@/services/apis/subscribe'
import type { AccountLimitStatusRespDTO, IGetSubscriptionInfoRes } from '@/services/apis/subscribe/type'

interface UseSubscriptionInfoOptions {
  fetchTypes?: Array<'fetchSubscriptionInfo' | 'fetchAccountLimit'>
}

export function useSubscriptionInfo(options?: UseSubscriptionInfoOptions) {
  const { fetchTypes = ['fetchSubscriptionInfo', 'fetchAccountLimit'] } = options || {}
  const {
    run: fetchSubscriptionInfo,
    data,
    loading: isSubInfoLoading,
  } = useRequest(() => subscribeService.getSubscriptionInfo(), {
    formatResult(data) {
      return data.data
    },
    onError(e) {
      e?.message && OneMessage.error(e.message)
    },
  })

  const { run: fetchAccountLimit, data: accountLimitRes } = useRequest(() => subscribeService.accountLimit())

  const subscriptionInfoService = useCallback(
    () => {
      fetchTypes.indexOf('fetchSubscriptionInfo') !== -1 && fetchSubscriptionInfo()
      fetchTypes.indexOf('fetchAccountLimit') !== -1 && fetchAccountLimit()
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [fetchTypes.join(','), fetchSubscriptionInfo, fetchAccountLimit]
  )

  const subscriptionAccountLimit = useMemo(() => {
    if (!accountLimitRes?.data) return REF_OBJECT
    return accountLimitRes.data.reduce((p, item) => {
      p[item.key] = item
      return p
    }, {}) as Record<string, AccountLimitStatusRespDTO>
  }, [accountLimitRes])

  return {
    isSubInfoLoading,
    subscriptionInfoService,
    subscriptionInfo: (data || {}) as IGetSubscriptionInfoRes,
    subscriptionAccountLimit: subscriptionAccountLimit,
  }
}
