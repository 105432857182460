import { is711CrossBorder, isCatService } from '@/bizComponent/RecipientAddressForm/utils'
import { VALUE_ADDED_SERVICES } from '@/constant'
import { useGetStation, useShip } from '@/hooks'
import type { StationListItem } from '@/services/apis/address/types'
import type { LogisticsType } from '@/services/apis/sales/type'
import type { ChannelCustomInfo } from '@/services/apis/type'
import { useDispatch, useSelector } from '@/store'
import { getExpressList } from '@/store/createOrderSlice'
import { sortProviderList } from '@/store/newServiceProviderSlice/utils'
import type { LogisticsOrderInfo } from '@/store/order/createSlice/types'
import type { ExpressListData } from '@/store/serviceProviderSlice/types'
import {
  formatParcelFormValues,
  formatRecipientAddressFormValues,
  formatSenderAddressFormValues,
  formatServiceProviderData,
  formatServiceProviderFormValues,
  formatServiceProviderProps,
} from '@/utils/format'
import { OneMessage } from '@yy/one-ui'
import { pick } from 'lodash'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

export interface ToPopParams {
  /* 门店编码 */
  stationCode?: string

  stationName?: string
  /* 订单转运单的数据 */
  orderInfo: LogisticsOrderInfo
  /* 是否是合单发货场景 */
  isMergeShip?: boolean
  /** 是否是合单退货场景 */
  isMergeReverse?: boolean
  /** 原单号 */
  reverseOrderNo?: string[]
  /**是否为平台物流服务产品的订单 */
  isPlatformLogisticsType: boolean
}

interface UseStepsFunctionsOptions {
  logisticsType?: LogisticsType
}

export function useStepsFunctions(options?: UseStepsFunctionsOptions) {
  const { logisticsType } = options || {}
  const { setShipInfo } = useShip()
  const history = useHistory()
  const dispatch = useDispatch()
  const currencyCode = useSelector((state) => state.user.info.currencyCode)
  /** format 成表单需要的数据 */
  const routeToComplete = useCallback(
    (
      url: string,
      options?: {
        address?: LogisticsOrderInfo['address']
        isMergeShip?: boolean
        isMergeReverse?: boolean
        service?: ExpressListData
        orderInfo: LogisticsOrderInfo & { channelCustomInfo?: ChannelCustomInfo }
        reverseOrderNo?: string[]
        extraExt?: {
          /** 合单发货单号 */
          aggShipmentOrderNos?: string
          [key: string]: any
        }
      }
    ) => {
      const { address: addressInfo, service, orderInfo, isMergeShip, extraExt, reverseOrderNo } = options || {}
      /** 表单值 */
      const formValues = {
        ...formatSenderAddressFormValues({ addressInfo }),
        ...formatRecipientAddressFormValues({ addressInfo, orderInfo }),
        ...formatParcelFormValues({ orderInfo }),
        ...formatServiceProviderFormValues({ addressInfo, orderInfo }),
        reverseOrderNo,
      }

      /** 请求 provider 列表的参数 */
      const providerProps = {
        ...formatServiceProviderProps({ formValues, addressInfo, orderInfo, isMergeShip }),
      }

      /** 元数据 */
      const ext = {
        orderInfo,
        ...(extraExt || {}),
      }

      const shipInfo = {
        formValues,
        ext,
        providerProps,
        // add this as the preSelected provider
        expressTypeCode: orderInfo.expressTypeCode === service?.expressTypeCode ? orderInfo.expressTypeCode : undefined,
      }

      /** 有服务产品才初始化 */
      if (service) {
        const provider = {
          ...formatServiceProviderData({ provider: service, orderInfo }),
        }

        Object.assign(shipInfo, {
          provider,
        })
      }
      const { mapTokenToUrl } = setShipInfo(shipInfo)
      history.push(mapTokenToUrl(url))
    },
    [setShipInfo, history]
  )

  // 多个门店选择
  const { handleGetStation } = useGetStation()

  /** 补充门店信息 */
  const getStation = useCallback(
    async (data: { stationCode: string; orderNo: string; stationName: string; stationSeq?: string }) => {
      const { stationCode, stationName, orderNo, stationSeq } = data
      if (!stationCode) {
        return
      }

      const station = await handleGetStation({ stationCode, orderNo, stationName, stationSeq } as any)

      return station
    },
    [handleGetStation]
  )

  interface GetExpressOptions {
    orderInfo?: Pick<LogisticsOrderInfo, 'address' | 'feeInfo' | 'expressTypeCode' | 'expressLogisticsType'>
    stationCode?: string
    station?: Partial<Pick<StationListItem, 'stationCode' | 'stationType' | 'stationSeq' | 'companyCode'>>
    companyCode?: string
    orderCodCurrencyCode?: string
    /**是否平台服务产品订单，是的话，不传收货地址筛选 */
    isPlatformLogisticsType: boolean
  }

  /** 补充服务产品信息 */
  const getExpress = useCallback(
    async (data: GetExpressOptions) => {
      const { station, stationCode, orderInfo, companyCode, orderCodCurrencyCode, isPlatformLogisticsType } = data
      const { address, feeInfo, expressTypeCode } = orderInfo || {}
      try {
        const expressList = await dispatch(
          getExpressList(
            isPlatformLogisticsType
              ? { expTypeCode: expressTypeCode }
              : {
                  senderAddressName: {
                    countryCode: address.senderCountryCode,
                    provinceName: address.senderProvince,
                    cityName: address.senderCity,
                    districtName: address.senderDistrict,
                    townName: address.senderTown,
                  },
                  consigneeAddressName: {
                    countryCode: address.consigneeCountryCode,
                    provinceName: address.consigneeProvince,
                    cityName: address.consigneeCity,
                    districtName: address.consigneeDistrict,
                    townName: address.consigneeTown,
                  },
                  cod: feeInfo?.paymentType === VALUE_ADDED_SERVICES[0].value ? true : undefined, // 只能传true
                  supportedAddressTypes: stationCode ? ['STORE_STREET'] : undefined,
                  companyCode: companyCode || station?.companyCode || undefined,
                  queryInsuranceSupport: true,
                  currencyCode,
                  queryDeliveryDate: true,
                  stationSeq: station?.stationSeq,
                  stationCode,
                  stationType: stationCode ? station?.stationType?.toString() : undefined,
                  curExpTypeCode: expressTypeCode,
                  logisticsType: logisticsType,
                  orderCodCurrencyCode,
                }
          )
        )

        const filterExpessList = sortProviderList(expressList, {
          filter: true,
        })

        if (filterExpessList && filterExpessList.length) {
          let express = filterExpessList[0]

          if (expressTypeCode) {
            const findIdx = filterExpessList.findIndex((item: any) => item.expressTypeCode === expressTypeCode)
            if (findIdx !== -1) {
              express = filterExpessList[findIdx]
            }
          }

          const service = {
            ...express,
          }

          return service
        }
      } catch (error) {
        OneMessage.error(error.message)
      }
    },
    [logisticsType, currencyCode, dispatch]
  )

  /** 补充地址信息 */
  const formatAddress = useCallback(
    (data: {
      station: StationListItem
      address: LogisticsOrderInfo['address']
      service?: ExpressListData
      consigneeAddressVos?: LogisticsOrderInfo['consigneeAddressVos']
      isMergeShip?: boolean
    }) => {
      const { station, address, service, consigneeAddressVos, isMergeShip } = data
      const addressResult = {} as LogisticsOrderInfo['address']
      if (station) {
        Object.assign(addressResult, address, {
          consigneeStationCode: station.stationCode,
          consigneeStationSeq: station.stationSeq,
          consigneeStationType: station.stationType,
          consigneeStationName: station.stationName,
          companyCode: service?.companyCode,
          countryCode: station.countryCode,
          /** 711跨境不需要门店地址覆盖 */
          ...(!is711CrossBorder(service?.companyCode) && {
            consigneeAddress: station.address,
            consigneeProvince: station.province,
            consigneeCity: station.city,
            consigneeDistrict: station.district,
          }),
        })
      } else if (isMergeShip && !consigneeAddressVos?.length) {
        // 合单发货且没有多个地址的情况下，直接使用后端提供的数据
        Object.assign(addressResult, address, {
          companyCode: service?.companyCode,
        })
      } else {
        // 因为黑猫店配无法匹配到我们数据库中的门店，所以需要写进去判断
        // 默认也同步订单的 companyCode 进去，为了 黑猫🐈‍⬛
        Object.assign(addressResult, address, {
          companyCode: service?.companyCode,
          expressTypeCode: service?.expressTypeCode,
          // 发货，没有匹配到订单的对应门店，清空门店信息，不带入订单原门店详情
          consigneeStationSeq: null,
        })
      }

      // 合单发货如果有多个收货地址，需要清空原来的地址数据
      if (isMergeShip && consigneeAddressVos?.length > 0) {
        const pickAddress = Object.keys(address).filter((key) => !key.startsWith('consignee'))
        Object.assign(addressResult, pick(addressResult, pickAddress), {
          consigneeCountryCode: address.consigneeCountryCode,
        })
      }

      return addressResult
    },
    []
  )

  /**
   * 补充订单信息
   */
  const makeupOrderInfo = useCallback(
    async (params: Omit<ToPopParams, 'isEdit'>) => {
      /* 合单发货场景列表页没有stationCode，需要step1选择具体店配地址才有 */
      const { stationCode, stationName, orderInfo, isMergeShip, isMergeReverse, isPlatformLogisticsType } = params

      const { address, consigneeAddressVos, expressTypeCode } = orderInfo

      const isBlackCat = isCatService(orderInfo.companyCode)
      /** 如果没有stationCode，会返回空，但是这里黑猫是不能走这个逻辑，因为黑猫会返回了711的信息 */
      const station =
        isBlackCat || isPlatformLogisticsType // 平台物流无法获取到门店所以跳过
          ? null
          : await getStation({
              stationName,
              stationCode,
              // 兼容批量合单发货场景
              orderNo: orderInfo.aggShipmentOrderNos?.length > 0 ? orderInfo.aggShipmentOrderNos[0] : orderInfo?.orderNo,
              stationSeq: address.consigneeStationSeq,
            })

      const companyCode = station?.companyCode || orderInfo.companyCode

      // 先初始化
      let service

      // 没有收/发货国家不调用获取服务产品接口
      let shouldFetchProvider = !!(address.consigneeCountryCode && address.senderCountryCode)

      if (isMergeReverse || (isMergeShip && (orderInfo?.senderAddressVos?.length || orderInfo?.consigneeAddressVos?.length))) {
        shouldFetchProvider = false
      }
      // 店配推荐服务产品
      const storeShouldFetchProvider = shouldFetchProvider && stationCode && station
      // 宅配推荐服务产品
      const homeShouldFetchProvider = shouldFetchProvider && !stationCode
      // 黑猫推荐服务产品
      const catShouldFetchProvider = shouldFetchProvider && isBlackCat

      if (storeShouldFetchProvider || homeShouldFetchProvider || catShouldFetchProvider || isPlatformLogisticsType) {
        /** 进行用户服务产品的初始化 */
        const returnService = await getExpress({
          orderInfo,
          stationCode,
          station,
          companyCode,
          isPlatformLogisticsType,
        })

        if (isMergeShip && returnService?.expressTypeCode === expressTypeCode) service = returnService
        else if (returnService && !isMergeShip) {
          service = returnService
        } else {
          service = null
        }
      }

      /**
       * format 地址
       * 特殊说明： 这里会把service的companyCode以及expressType注入到address里面，目的是为了黑猫做特殊匹配
       *
       */

      const formatedAddress = formatAddress({
        consigneeAddressVos,
        station,
        address,
        service: {
          expressTypeCode,
          companyCode,
          ...(service || {}),
        },
        isMergeShip,
      })
      return {
        address: formatedAddress,
        service,
      }
    },
    [formatAddress, getExpress, getStation]
  )

  return {
    routeToComplete,
    getStation,
    getExpress,
    formatAddress,
    makeupOrderInfo,
  }
}

export const object2Query = (data?: Record<string, any>) => {
  if (!data) return ''
  const pairStr = Object.keys(data).reduce((acc, key) => {
    return acc.concat(`${key}=${data[key]}`)
  }, [] as string[])

  return pairStr.length ? `?${pairStr.join('&')}` : ''
}
