import { useEffect, useRef } from 'react'

const defaultEvents = ['mousedown', 'touchstart']

export const useClickAway = <E extends Event = Event>(selector: string, onClickAway: (event: E) => void, events: string[] = defaultEvents) => {
  const savedCallback = useRef(onClickAway)

  useEffect(() => {
    savedCallback.current = onClickAway
  }, [onClickAway])

  useEffect(() => {
    const handler = (event) => {
      const flag = Array.from(document.querySelectorAll(selector)).some((el) => {
        return el.contains(event.target)
      })

      !flag && savedCallback.current(event)
    }
    for (const eventName of events) {
      window.document.addEventListener(eventName, handler)
    }
    return () => {
      for (const eventName of events) {
        window.document.removeEventListener(eventName, handler)
      }
    }
  }, [events, selector])
}

export default useClickAway
