import type { RouteConfig } from 'react-router-config'
import { Carrier } from '@yy/one-icon'
import { withAuthRedirect } from '@/components/Authorized/hoc/withAuthRedirect'
import Blank from '@/layouts/Blank'
import freight from './freight'
import logistics from './logistics'
import orders from './orders'
import reverse from './reverse'
import crossBorderCouriers from './crossBorderCouriers'

const routes: RouteConfig[] = [
  {
    alias: 'shipments',
    path: '/ship',
    component: withAuthRedirect(Blank, { first: '/ship/order/list' }),
    menuTitle: 'menu.shipments',
    group: 'shipments',
    icon: Carrier,
    permissionCode: 'ship:freight,ship:order,ship:logistics',
    routes: [...crossBorderCouriers, ...freight, ...orders, ...logistics, ...reverse],
  },
]

export default routes
