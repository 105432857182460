import { useEffect } from 'react'
import { useSelector } from '@/store'
import { updateProps as updateParcelSliceProps } from '@/store/parcelSlice'

export function useUserCountryCode() {
  const userCountryCode = useSelector((state) => state.user.info.countryCode)

  // 设置 parcel 收货国家
  // 只用于初始化
  useEffect(() => {
    if (userCountryCode) {
      updateParcelSliceProps({
        senderCountry: userCountryCode,
      })
    }
  }, [userCountryCode])

  return {
    userCountryCode,
  }
}
