import { omit } from 'lodash'
import type { LangType } from '@/constant'
import { LANGUAGE } from '@/constant'
import { isUrlFormatValid } from '@/utils/common'
import { countryConfig } from '@/utils/countryVersion'

export { noticeSettingList } from '@/constant/noticeSettingList'

/** 有此FK的用户，只能使用已揽收的邮件节点，其他节点都需要在点击后都提醒用户升级权益*/
export const OS_DY_SINGLEMAIL = 'os_dy_singlemail'
/** 有此FK的用户，可以打开多节点的邮件通知*/
export const OS_DY_MULTIEMAIL = 'os_dy_multiemail'
/** 没有此FK的用户，在设置「关闭Oneship品牌」标识的时候会引导用户升级套餐*/
export const OS_DY_TRACKINGSET = 'os_dy_trackingset'
/** 邮件设置*/
export const OS_DY_EMAIL_NOLOGO = 'os_dy_email_nologo'
/** 邮件自定义设置*/
export const EMAIL_CUSTOM_KEY = 'os_dy_emailset'
/** SMS信息的流量*/
export const OS_SMS_MEG = 'os_sms_meg'
/** 去除oneship标识*/
export const REMOVE_OS_BRAND = 'REMOVE_OS_BRAND'
/** 轨迹查询*/
export const OS_TRACKING_WAYBILL = 'os_tracking_waybill'
/** 域名状态 */
export const DOMAIN_STATUS = {
  invalid: 'invalid', // 已重置
  deleted: 'deleted', // 无自定义域名FK，域名已失效
  active: 'active', // 生效中
  moved: 'moved', // 权益降级，已无自定义域名FK，但还没解绑域名，此时前端当成“已失效”处理
}
/** 自定义域名权限key */
export const CUSTOM_DOMAIN_KEY = 'os_custom_domain'

/** 短信自定义模板权限key */
export const SMS_CUSTOM_KEY = 'os_dy_smsset'
/** 短信追踪页模板权限key */
export const TRACKING_PAGE_CUSTOM_KEY = 'os_dy_trackingset'

export const SCENE = 'OS_CUSTOM_TEMPLATE_IMAGE_UPLOAD'
export const SCENE_OSS_PRIVATE = 'OS_PRIVATE_DEFAULT_UPLOAD'
export const DOMAIN_IMAGE_CDN = 'https://cdn.myoneship.cn/'
export const DEFAULT_FONT_COLOR = '#415066'

/** 变量名 (前端维护) */
export const ReceiverName = 'Receiver Name'
export const TrackingNo = 'Tracking No'
export const CourierName = 'Courier Name'
export const TrackingDetail = 'Tracking Detail'
export const ShipmentStatus = 'Shipment Status'
export const OrderNo = 'Order No'

export const emailValueToMap = {
  ['metafields.consignee_name']: ReceiverName,
  ['metafields.custom_no']: TrackingNo,
  ['metafields.provider_name']: CourierName,
  ['metafields.url']: TrackingDetail,
  ['metafields.status_desc']: ShipmentStatus,
  ['metafields.reference_no']: OrderNo,
}

export const emailClientToServiceMap: Record<string, string> = {
  ...Object.fromEntries(Object.entries(omit(emailValueToMap, ['metafields.status_desc'])).map(([key, value]) => [value, key])),
  ...LANGUAGE.reduce((pre, cur) => {
    const key = `${ShipmentStatus} ${cur.languageType}`
    pre[key] = 'metafields.status_desc'
    return pre
  }, {}),
}

export const ShipmentStatusLanageMap = LANGUAGE.reduce((pre, cur) => {
  const key = `${ShipmentStatus} ${cur.languageType}`
  pre[key] = 'data.status_desc'
  return pre
}, {})

/** 短信 用于展示前转换变量 后端=>前端 */
export const smsServiceToClientMap = {
  ['data.consignee_name']: ReceiverName,
  ['data.custom_no']: TrackingNo,
  ['data.provider_name']: CourierName,
  ['data.url']: TrackingDetail,
  ['data.status_desc']: ShipmentStatus,
}

/** 短信 用于传参前转换变量 前端=>后端 */
export const smsClientToServiceMap: Record<string, string> = {
  ...Object.fromEntries(Object.entries(omit(smsServiceToClientMap, ['data.status_desc'])).map(([key, value]) => [value, key])),
  ...ShipmentStatusLanageMap,
}

const ShipmentStatusCount = LANGUAGE.reduce((pre, cur) => {
  const key = `${ShipmentStatus} ${cur.languageType}`
  pre[key] = cur.languageType === 'en' ? 15 : 6
  return pre
}, {}) as Record<`${typeof ShipmentStatus} ${LangType}`, number>

/** sms 变量对应的短信条数 */
export const VAR_COUNT = {
  [TrackingNo]: 22,
  [CourierName]: 15,
  [TrackingDetail]: 27,
  ...ShipmentStatusCount,
}

/** 生成短信模板变量 */
export const getSmsVar = (v: string) => `{{ ${v} }}`

export const getSmsVarReg = (val: string) => new RegExp(`\{\{\\s*${val}\\s*\}\}`, 'g')

/** 变量名 list */
export const VAR_NAME = Object.keys(VAR_COUNT).map(getSmsVar)

/** 提取文本{{变量}} */
export const VAR_REG = new RegExp(
  Object.keys(VAR_COUNT)
    .map((val) => `\{\{\\s*${val}\\s*\}\}`)
    .join('|'),
  'g'
)

/** 短信水印 */
export const SMS_WATER_MARK = ` -Power by ${countryConfig.brand}`

export const RULES = {
  imageLink: [
    {
      validator: async (_, value) => {
        if (value?.filter((item) => !isUrlFormatValid(item.link)).length > 0) {
          throw Error()
        }
        return true
      },
    },
  ],
}
