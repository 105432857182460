import { useRequest } from 'ahooks'
import API from '@/services/http'
import type * as Types from './type'
import type { IExtraTaskOptions } from '@/hooks'
import { OneNotification } from '@yy/one-ui'
import { downloadFile } from '@/utils/browser'
import { pick } from 'lodash'

/* 批量更新订单打标接口 */
export const updateOrderTag = (params: Types.OrderTagParams) => API.admin.post(`/api/sales/order/tag`, params)

/* 取消发货 */
export const cancelDelivery = (params: Types.SalesOrderCancelDeliveryRequest) => API.toast.post('/api/sales/order/cancel_delivery', params)

/* 更新运单并发货 */
export const updateOrder = (params: Types.OrderUpdateRequest) => API.admin.post('/api/order/update', params)

/* 创单接口  */
export const createOrder = (stepsInfo: Types.CreateType) => API.toast.post('/api/sales/order/create', stepsInfo)

/* 提交和发货 */
export const editOrder = (params: Types.OrderEditRequest) => API.toast.post('/api/sales/order/update', params)

/* 查询销售订单 */
export const queryOneShipOrder = (params: Types.OrderDetailReq) => API.admin.post<Types.ResDetailType>('/api/sales/order_query/get', params)

/* 取消关联 */
export const disassociate = (params: { orderNo?: string; logisticsNo?: string }) => API.admin.post('/api/sales/order/unbind', params)

/* 单个更新订单备注 */
export const updateRemark = (params: Types.RemarkType) => API.admin.post('/api/sales/order/update_remark', params)

/* 单个更新订单备注 */
export const updateBatchRemark = (params: Types.BatchRemarkType) => API.admin.post('/api/sales/order/batch_update_remark', params)

/* 订单管理-导出订单 */
export const orderExportAll = async (params) => {
  const moment = await import('moment-timezone').then((module) => module.default)
  const timezone = moment.tz.guess()
  return API.admin.post('/api/sales/order_export/export', Object.assign(params, { timezone }))
}

/* 获取来源平台 */
export const getListPlatForm = () => API.admin.post('/api/sales/order/list_platform')

/* 添加标签 */
const addTag = (params: Types.UpdateTagReq) => API.admin.post('/api/tag/relation/create', params)

/* 删除标签 */
const deleteTag = (params: Types.UpdateTagReq) => API.admin.post('/api/tag/relation/del', params)

/* 校验地址 */
export const verifyAddress = (params) => API.admin.post('/api/address/verify', params)
/* 重置列表 */
export const resetColumn = (params) => API.admin.post('/api/user/setting/recover', params)

/* 批量打印 */
export const orderPrint = async (params: Types.PrintType & IExtraTaskOptions) => {
  const { data } = await API.admin.post<Types.PrintResponse>('/api/sales/order/print_waybill', params)
  return data
}

/* 批量打印 */
export const invoicePrint = async (params: Types.PrintType & IExtraTaskOptions) => {
  const { data } = await API.admin.post<Types.PrintResponse>('/api/sales/order/print_receipt', params)
  return data
}

const commonOptions = { manual: true, throwOnError: true }
export function useOrderRequest() {
  const updateOrderTagRequest = useRequest(updateOrderTag, commonOptions)

  const cancelDeliveryRequest = useRequest(cancelDelivery, commonOptions)

  const orderExportAllRequest = useRequest(orderExportAll, commonOptions)

  const disassociateRequest = useRequest(disassociate, commonOptions)

  const queryOneShipOrderRequest = useRequest(queryOneShipOrder, commonOptions)

  const updateRemarkRequest = useRequest(updateRemark, commonOptions)

  const updateBatchRemarkRequest = useRequest(updateBatchRemark, commonOptions)

  const addTagRequest = useRequest(addTag, commonOptions)

  const deleteTagRequest = useRequest(deleteTag, commonOptions)

  return {
    updateOrderTagRequest,
    cancelDeliveryRequest,
    orderExportAllRequest,
    disassociateRequest,
    queryOneShipOrderRequest,
    updateRemarkRequest,
    updateBatchRemarkRequest,
    addTagRequest,
    deleteTagRequest,
  }
}

/* 寄件地址更新校验 */
export const senderAddressUpdateValidate = (params: Types.SenderAddressUpdateValidateParams) =>
  API.toast.post<Types.SenderAddressUpdateValidateRes>('/api/sales/order/sender_address/update_validate', params)

/* 批量设置服务产品订单校验 */
export const orderExpressTypeValidate = (params: Types.OrderExpressTypeValidateParams) =>
  API.toast.post<Types.OrderExpressTypeValidateRes>('/api/sales/order/express_type/update_validate', params)

/* 批量修改发货地址 */
export const updateOrderSenderAddress = (params: Types.UpdateOrderSenderAddressParams) => API.toast.post('/api/sales/order/sender_address/update', params)

/* 批量设置服务产品 */
export const updateOrderExpress = (params: Types.UpdateOrderExpressParams) => API.toast.post('/api/sales/order/express_type/update', params)

/* 增加 use Request 默认参数 */
export const useManualRequest = <T extends (...args: any[]) => Promise<any>>(cb: T) => {
  return useRequest(cb, commonOptions)
}

/* 批量发货 */
export const batchDeliveryV2 = (data: Types.BatchDeliveryRequest) => {
  return API.toast.post<Types.BatchDeliveryV2Res>('/api/sales/order/batch_delivery/v2', data)
}
/* 批量发货 */
export const queryExpressList = (data: Types.QueryExpressListReq) => {
  return API.toast.post<Types.QueryExpressListRes>('/api/express/list_by_codes', data)
}
/* SAAS账号配置子账号 */
export const createSassSubAccount = (data: Types.SassSubAccount) => {
  return API.toast.post('/api/seller/express/type/config/cover_update', data)
}

/**
 * 下载托运单证明文件，异步，下载中心通知
 * @param data
 * @returns
 */
export const downLoadCertifier = (data: { logisticsNos: string[] }) => API.toast.post('/api/bill/print_delivery_proof', data)
/** 复制拆分订单 */
export const createCopyOrder = (data: Types.CopyOrderReq) => API.toast.post<Types.CopyOrderResponse>('/api/sales/order/clone', data)

/** 取消呼叫揽收 */
export const cancleCallPickup = (data) => API.toast.post('/api/pickup/cancel', data)
/** 呼叫揽收 */
export const callPickup = (data: Types.CallPickupRequest) => API.toast.post('/api/pickup/call', data)
/** 呼叫揽收日期时间 */
export const callPickupDateTime = (data: { companyCode: string; countryCode: string }) =>
  API.toast.post<{ selectablePickTimes: Array<{ date: string; timeIntervals: string[] }> }>('/api/express/pick_up_time', data)

/** 用于即时物流选择车辆类型、附加服务等 */
export const getExpressServiceList = (data: Types.ExpressTypeServiceListRequest) => API.toast.post<{ services: Types.ExpressTypeServiceVo[] }>('/api/express/service/list', data)

/**
 * 第三方平台系统计算运费
 * 目前支持lalamove预估费用
 *
 * @param request
 * @return
 */
export const feeExpressCalculate = (data: Types.LalamoveCalculateRequest) => API.toast.post<Types.CalculateRes>('/api/fee/express/calculate', data)

/* 即时物流批量发货-订单列表 */
export const getImmediateBatchBaseOrderInfos = (data: Types.ImmediateBatchDeliveryPageRequest) => {
  return API.toast.post<Types.ImmediateBatchDeliveryPageRes>('/api/sales/order/batch-delivery/page', data, {
    // 超时5分钟
    timeout: 5 * 60 * 1000,
  })
}

/* 即时物流批量发货-任务提交 */
export const immediateBatchShip = async (params: Types.BatchShipType & IExtraTaskOptions) => {
  const { data } = await API.admin.post<Types.BatchShipResponse>('/api/sales/order/batch-delivery/preprocess', params)
  return data
}

/* 即时物流-提交批量发货 */
export const immediateBatchShipCreate = (data: Types.ImmediateBatchDeliveryRequest) => {
  return API.toast.post<Types.BatchDeliveryV2Res>('/api/sales/order/batch-delivery/submit', data)
}

/**
 * 查询物流订单简要信息
 * @param payload
 * @returns
 */
export const queryOrderSimple = (payload: { logisticsNo: string }) => API.admin.post<Types.OrderQuerySimpleRes>('/api/order_query/simple', payload)

/**
 * **接口** [预约揽收详情↗](http://api-doc-sl.inshopline.com/#/project/74/feature%2F20230420_2066_couriersplease/interface/8b09f3365882f83510ded7e976a7c523)
 * @请求头 `POST /api/pickup/detail`
 * @更新时间 `2023-04-10 21:38:52`
 */
export function postPickupDetail(data: { id: string }) {
  return API.admin.post<Types.PickupDetailRes>('/api/pickup/detail', data)
}

/**
 * **接口** [申请面单↗](https://api-doc-sl.inshopline.com/#/project/Java/60/feature%2F20230703_2881_auspost_mypost/interface/8892d9047813e3d4d645652975fa492a)
 * @请求头 `POST /api/order/apply_waybill`
 * @更新时间 `2023-06-30 15:40:01`
 */
export function postApplyWaybill(data: Types.OrderWaybillApplyRequest) {
  return API.toast.post(`/api/order/apply_waybill`, data)
}

/**
 * 下载批量导入模板
 * @param data
 * @returns
 */
export async function downloadTemplate(data: { senderCountryCode: string; logisticsType: 'express' | 'ondemand' | 'reverse'; name?: string }) {
  const resonse = await API.admin.post('/api/sales/order/batch-create/template/get', pick(data, 'logisticsType', 'senderCountryCode'))

  const countryCode = data.senderCountryCode

  const { templateUrl } = resonse?.data || {}
  if (!(typeof templateUrl === 'string' && templateUrl)) {
    OneNotification.error({ message: 'template url is not found.' })
    return
  }

  downloadFile(templateUrl, `${data.name}_${countryCode.toLowerCase()}.xlsx`)
}
