import { useMount } from 'ahooks'
import { pick } from 'lodash'
import React, { useEffect } from 'react'
import { shallowEqual } from 'react-redux'
import * as Sentry from '@sentry/react'
import { useSelector } from '@/store'

const SSID = 'ssid'

let ssid: string = (() => {
  try {
    return window.sessionStorage.getItem(SSID)
  } catch (error) {
    // nothing todo...
  }
})()

function genSSID() {
  if (ssid) {
    return ssid
  }

  ssid = (Math.floor(Math.random() * 10e6) + Date.now()).toString(32)
  window.sessionStorage.setItem('ssid', ssid)
  return ssid
}

export const SentryConfiguration: React.FC = React.memo((props) => {
  const { children } = props
  const role = useSelector((state) => state?.user?.role)
  const info = useSelector((state) => state?.user?.info, shallowEqual)
  const language = useSelector((state) => state.language)

  // 设置当前访问的 Session ID
  // 用于追踪用户本次访问的错误
  useMount(() => {
    const ssid = genSSID()
    Sentry.setTag('ssid', ssid)
  })

  // 设置用户信息
  useEffect(() => {
    const { uid, nickname, email, countryCode, address, contactName, phone } = info || {}
    const userInfo = { uid, nickname, email, countryCode, address, contactName, phone }
    const validProps = Object.keys(userInfo).filter((name) => userInfo[name])
    const finalUserInfo = pick(userInfo, validProps)

    if (Object.keys(finalUserInfo).length > 0) {
      // 源码是覆盖, 所以这里必须每次更新用户信息都需要完整的字段
      Sentry.setUser(finalUserInfo)
      Sentry.setTag('uid', uid)
    }
  }, [role, info])

  // 设置语言
  useEffect(() => {
    if (language) {
      Sentry.setExtra('currentLanguage', language)
    }
  }, [language])

  // 设置当前国家信息
  useEffect(() => {
    if (info?.selfArea) {
      Sentry.setExtra('currentCountry', info.selfArea)
      Sentry.setTag('currentCountry', info.selfArea)
    }
  }, [info?.selfArea])

  return <>{children}</>
})

SentryConfiguration.displayName = 'SentryConfiguration'
