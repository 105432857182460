// 第三方常量地址

export const ONECLUB_URL = {
  // 账号管理
  account: `${process.env.ONECLUB_URL}/admin/account`,
  // 管理员设置
  manager: `${process.env.ONECLUB_URL}/admin/manager`,
  // 服务申请
  serviceApply: `${process.env.ONECLUB_URL}/admin/serviceApply`,
  // 申请服务
  createApply: `${process.env.ONECLUB_URL}/admin/serviceApply/apply`,
  // 申请子账号
  createApplySubAccount: `${process.env.ONECLUB_URL}/admin/serviceApply/apply?appApplyType=express_account`,
  // 钱包
  wallet: `${process.env.ONECLUB_URL}/admin/wallet`,
}

export enum ApplyTypeEnum {
  /** 申请服务产品子账号 */
  express_account = 'express_account',
  seller_kyc = 'seller_kyc',
}
