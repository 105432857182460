import type React from 'react'
import type { RouteConfigComponentProps } from 'react-router-config'
import { renderRoutes } from 'react-router-config'
import { useHistory } from 'react-router-dom'
import { useSelector } from '@/store'

const Blank: React.FC<RouteConfigComponentProps<any>> = (props) => {
  const { route } = props
  const displayWallet = useSelector((state) => state.user?.info?.displayWallet)
  const history = useHistory()
  if (route) {
    if (displayWallet) {
      return renderRoutes(route.routes)
    }

    // 没有流水不能访问钱包
    history.push('/')
  }

  return null
}

export default Blank
