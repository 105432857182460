import { useRequest } from 'ahooks'
import API from '@/services/http'
import type * as LogisticsTypes from '@/store/logisticsSlice/type'
import type * as Types from './type'
import { disassociate } from '../order'
import type { IExtraTaskOptions } from '@/hooks'

/** 获取面单地址 */
export const queryBillUrl = (params: Types.WayBillType) => API.admin.post<Types.ResBillType>('/api/order_query/list_way_bill', params)

/** 更新订单打印状态  */
export const updateWaybillStatus = (logisticsNo: string, appId: string) => API.admin.post('/api/order/update_waybill_status', { logisticsNo, appId })

/** 订单取消 */
export const orderCancel = (data: Types.CancelType) => API.admin.post('/api/order/cancel', data)

/* 轨迹查询 */
export const queryTrace = (params: Types.TraceType) => API.toast.post<Types.ResponseQueryTraceType>('/api/order_query/trace', params)

/* 批量更新运单打标接口 */
export const updateLogisticsOrderTag = (params: Types.OrderTagParams) => API.admin.post('/api/order/tag', params)

/**
 * 导出运单
 * @description
 * 搜索 `logisticsExportAllRequest`
 * @see /services/apis/logistics/index.tsx
 */
export const logisticsExportAll = async (params: Partial<Types.ExportType>) => {
  const moment = await import('moment-timezone').then((module) => module.default)
  const timezone = moment.tz.guess()
  return API.admin.post('/api/order/export/export', Object.assign(params, { timezone }))
}

/* 添加标签 */
const addTag = (params: Types.UpdateTagReq) => API.admin.post('/api/tag/relation/create', params)

/* 删除标签 */
const deleteTag = (params: Types.UpdateTagReq) => API.admin.post('/api/tag/relation/del', params)

/* 门店修改接口 */
export const updateStation = (params: Types.UpdateStationReq) => API.admin.post('/api/station/update', params)

/* 批量打印 */
export const batchPrint = async (params: Types.PrintType & IExtraTaskOptions) => {
  const response = await API.admin.post<Types.PrintResponse>('/api/order_query/print_waybill', params)
  const { data } = response
  return data
}

const commonOptions = { manual: true, throwOnError: true }
export function useLogisticsRequest() {
  const updateLogisticsOrderTagRequest = useRequest(updateLogisticsOrderTag, commonOptions)

  const logisticsExportAllRequest = useRequest(logisticsExportAll, commonOptions)

  const orderCancelRequest = useRequest(orderCancel, commonOptions)
  const logisticCancelAssociateRequest = useRequest(disassociate, commonOptions)

  const addTagRequest = useRequest(addTag, commonOptions)
  const deleteTagRequest = useRequest(deleteTag, commonOptions)

  return {
    updateLogisticsOrderTagRequest,
    logisticsExportAllRequest,
    orderCancelRequest,
    addTagRequest,
    deleteTagRequest,
    logisticCancelAssociateRequest,
  }
}

/**
 * 查询轨迹和地址
 * @param params
 * @returns
 */
export const queryTraceAddress = (params: Types.OneshipLogisticsTraceRequest) => API.toast.post<Types.TraceAddressRes>('/api/order_query/trace_address', params)

/**
 * 逾期店配转宅配
 * @param params
 * @returns
 */
export const updateStoreToHome = (params: Types.OrderExpireToHomeUpdateRequest) => API.toast.post<Types.UpdateHomeAddressRes>('/api/order/update_home_address', params)

/** 订单详情 */
export const queryOrderDetail = (data: LogisticsTypes.QueryTrace) => {
  return API.toast.post<LogisticsTypes.ResOrderDetailType>('/api/order_query/get', data)
}

/** 更新优先运费 */
export const updatePriorityFee = (data: { logisticsNo: string; priorityFee: number }) => API.toast.post('/api/order/priority-fee/update', data)
/** 更新司机 */
export const updateDriverReason = (data: { logisticsNo: string; reason: string }) => API.toast.post('/api/order/driver/update', data)
