import { trim } from 'lodash'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { BizType } from '@/constant'
import { ACTION_TYPE, BIZ_TYPE } from '@/constant'
import Api from '@/services/http'
import { customTagUrl } from '@/services/url'
import type { AppDispatch } from '@/store'
import { uuid } from '@/utils'
import type * as Types from './types'

/**
 * @description
 * 这里普通物流与及时物流是一样的
 */
export const StateTagListMap = {
  [BIZ_TYPE.ORDER]: 'tagListOrder',
  [BIZ_TYPE.LOGISTICS]: 'tagListLogistics',
  [BIZ_TYPE.IMMEDIATE_ORDER]: 'tagListOrder',
  [BIZ_TYPE.IMMEDIATE_LOGISTICS]: 'tagListLogistics',
  [BIZ_TYPE.REVERSE]: 'tagListLogistics',
} as const

const initialState: Types.CustomTagState = {
  customTags: [],
  defaultTags: [],
  tagListOrder: [],
  tagListLogistics: [],
  /**
   * 现状 "即时物流" 有 "自定义标签管理"，但是没有该筛选功能
   * 即时物流使用的是跟普通物流相同的 "自定义标签管理"
   * @todo
   * 这里需要将 `tagListImmediateOrder` 与 `tagListImmediateLogistics` 删除掉
   * 这里永远不会更新，因为 `StateTagListMap` 没有针对`即时物流`的数据
   * 其中 `tagListImmediateOrder` 在`即时物流-订单列表`用到，可以删除并删除相应的功能
   * 或更新到正确的读取方式（统一读取 `tagListOrder`）
   */
  tagListImmediateOrder: [],
  tagListImmediateLogistics: [],
}

const { reducer, actions } = createSlice({
  name: 'customTag',
  initialState,
  reducers: {
    setCustomTags(state, action: PayloadAction<Types.TagItem[]>): Types.CustomTagState {
      return {
        ...state,
        customTags: action.payload,
      }
    },
    setDefaultTags(state, action: PayloadAction<Types.TagItem[]>): Types.CustomTagState {
      return {
        ...state,
        customTags: action.payload,
      }
    },
    setTagList(state, action: PayloadAction<{ bizType: BizType; data: Types.TagListData[] }>): Types.CustomTagState {
      return {
        ...state,
        [StateTagListMap[action.payload.bizType]]: action.payload.data,
      }
    },
  },
})

export const getTagList = (params: Types.TagListPayload) => async (dispatch: AppDispatch) => {
  const response = await Api.admin.post<Types.TagListState>(customTagUrl.tagList, { ...params })
  const { bizCode, bizMessage, message, data } = response
  if (bizCode === 'SUCCESS') {
    dispatch(
      actions.setTagList({
        bizType: params.bizType,
        data: data?.resultList || [],
      })
    )
    return data
  }
  return Promise.reject({ bizCode, bizMessage, message })
}

export const saveTagList = (tagList: Types.TagListData[], bizType: number) => async () => {
  const response = await Api.toast.post<Types.SaveTagListApiState>(customTagUrl.createOrUpdate, {
    reqList: tagList,
    bizType,
  })
  const { bizCode, bizMessage, message, data } = response
  if (bizCode === 'SUCCESS') {
    return data?.isSuccess
  }

  return Promise.reject({ bizCode, bizMessage, message })
}

export const formatTagList = (tagList: Types.TagListData[]): { defaultTags: Types.TagItem[]; customTags: Types.TagItem[] } => {
  const defaultTags = []
  const customTags = []
  Array.isArray(tagList) &&
    tagList.forEach((item) => {
      const [color, shape] = item.tagCode.split('_')
      switch (item.tagType) {
        case 1: {
          defaultTags.push({
            ...item,
            tagShape: shape,
            tagColor: color,
            actionType: ACTION_TYPE.UPDATE,
            validateStatus: '',
            errorMsg: '',
          })

          break
        }
        case 2: {
          customTags.push({
            ...item,
            tagShape: shape,
            tagColor: color,
            actionType: ACTION_TYPE.UPDATE,
            validateStatus: '',
            errorMsg: '',
          })
          break
        }
      }
    })

  return { defaultTags, customTags }
}

export const parseToSaveData: (tagList: Types.TagItem[]) => Types.TagListData[] = (tagList) => {
  return tagList.map((item) => ({
    tagCode: item.tagCode,
    tagId: item.tagId,
    tagName: trim(item.tagName),
    tagType: item.tagType,
    actionType: item.actionType,
  }))
}

export const genTagItem: (bizType: number) => Types.TagItem = (bizType) => {
  return {
    shape: '',
    tagId: '',
    tagShape: '',
    tagColor: '',
    tagName: '',
    validateStatus: '',
    errorMsg: '',
    tagCode: undefined,
    bizType,
    actionType: ACTION_TYPE.CREATE,
    tagType: 2,
    tagIdFront: uuid(),
  }
}

export const { setCustomTags, setDefaultTags } = actions

export default reducer
