export default {
  /** 用户权限 */
  authority: '/api/Authority',
  /** 用户地址信息总览 */
  addressOverview: '/api/user/address/overview',
  /** 用户信息 */
  info: '/api/user/info/get',
  /** 更新用户信息 */
  updateInfo: '/api/user/info/update',

  subAccount: '/api/user/sub_account/get',

  /** 设置用户国家地区 */
  setCountry: '/api/user/info/country/change',

  /** 登出 */
  logout: '/udb/lgn/login/logout.do',

  // 自定义域名
  domainInfo: '/api/user/domain/getDomainMapping', // 获取域名信息
  insertDomain: '/api/user/domain/insert', // 新增域名
  updateDomain: '/api/user/domain/update', // 修改域名
  deleteDomain: '/api/user/domain/delete', // 删除域名
}
