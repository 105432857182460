import type { RouteConfig } from 'react-router-config'
import { withAsync } from '@/components/Async'
import Blank from '@/layouts/Blank'

const routes: RouteConfig[] = [
  // 地址簿
  {
    alias: 'address_book',
    path: '/addressbook',
    component: Blank,
    menuTitle: 'frame.addressBooks',
    pageDescription: 'frame.addressBooks.desc',
    permissionCode: 'settings:addressbook',
    routes: [
      {
        path: '/',
        component: withAsync(() => import(/* webpackChunkName: "AddressBook" */ '@/containers/AddressBook')),
        exact: true,
      },
    ],
  },
]

export default routes
