import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { ADDRESS_TYPE } from '@/constant'
import { getSupportAutoSplitCountryList } from '@/services/apis'
import { fetchDictionaryByKey } from '@/services/apis/dictionary'
import Api from '@/services/http'
import { addressUrl, stationUrl } from '@/services/url'
import type { AppDispatch } from '@/store'
import { createSetReducers } from '../storeUtils'
import type * as Types from './types'

const AddressInputHandler = {
  get: function (target, name) {
    return target.hasOwnProperty(name)
      ? target[name]
      : {
          provinceVisibility: true,
          cityVisibility: true,
          districtVisibility: true,
        }
  },
}

const initialState: Types.AddressState = {
  departureDefault: null,
  departureList: [],
  initedDepartureList: false,
  arrivalList: [],
  departureSearchList: [],
  arrivalSearchList: [],
  departureSelect: null,
  batchDepartureSelect: null,
  searchKeywords: '',
  addressType: 1,
  countrySelectIndex: 0,
  countrySelectTempIndex: 0,
  departureListHasNext: false,
  arrivalListHasNext: false,
  addressFormReady: false,
  verifyFieldMap: {},
  consigneeVerifyFieldMap: {},
  addressConfig: new Proxy({}, AddressInputHandler),
  initialAddressConfig: false,
  splitSupportCountryMap: {},
  initialVerifyFieldMap: false,
}

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    ...createSetReducers(initialState),
    setDepartureDefaultAddress(state, action: PayloadAction<Types.AddressState['departureDefault']>) {
      return {
        ...state,
        departureDefault: action.payload,
      }
    },
    setDepartureList(state, action: PayloadAction<Types.AddressState['departureList']>) {
      return {
        ...state,
        departureList: action.payload,
        initedDepartureList: true,
      }
    },
    setArrivalList(state, action: PayloadAction<Types.AddressState['arrivalList']>) {
      return {
        ...state,
        arrivalList: action.payload,
      }
    },
    setArrivalSearchList(state, action: PayloadAction<Types.AddressState['arrivalSearchList']>) {
      return {
        ...state,
        arrivalSearchList: action.payload,
      }
    },
    setDepartureListHasNext(state, action: PayloadAction<Types.AddressState['departureListHasNext']>) {
      return {
        ...state,
        departureListHasNext: action.payload,
      }
    },
    setArrivalListHasNext(state, action: PayloadAction<Types.AddressState['arrivalListHasNext']>) {
      return {
        ...state,
        arrivalListHasNext: action.payload,
      }
    },
    updateStationList(state, action: PayloadAction<Types.FetchStationListResp>) {
      const { data, hasNext, pageNum } = action.payload
      const { stationList = [] } = state

      return {
        ...state,
        stationList: [...stationList, ...data],
        stationListHasNext: hasNext,
        stationListPageNum: pageNum,
      }
    },
    setStationList(state, action: PayloadAction<Types.FetchStationListResp>) {
      const { data: stationList, hasNext: stationListHasNext, pageNum: stationListPageNum } = action.payload
      return { ...state, stationList, stationListHasNext, stationListPageNum }
    },
    setStationTypes(state, action: PayloadAction<Types.StationTypes>) {
      const stationTypes = action.payload
      return { ...state, stationTypes }
    },
    clearStationList(state) {
      return {
        ...state,
        stationList: [],
        stationListHasNext: true,
        stationListPageNum: 0,
      }
    },
    setStationAreaList(state, action: PayloadAction<{ name: string; data: Types.StationAreaList }>) {
      const { name, data } = action.payload
      return {
        ...state,
        stationAreaList: {
          ...(state?.stationAreaList || {}),
          [name]: data,
        },
      }
    },
    setAddressVerifyMap(state, action: PayloadAction<Record<string, Types.VerifyFieldType>>) {
      return {
        ...state,
        verifyFieldMap: action.payload,
      }
    },
    setConsigneeVerifyFieldMap(state, action: PayloadAction<Record<string, Types.VerifyFieldType>>) {
      return {
        ...state,
        consigneeVerifyFieldMap: action.payload,
      }
    },
    setAddressConfig(state, action: PayloadAction<Record<string, Types.CountryConfigType>>) {
      return {
        ...state,
        addressConfig: action.payload,
      }
    },
    setSplitSupportCountryMap(state, action: PayloadAction<Types.AddressState['splitSupportCountryMap']>) {
      return {
        ...state,
        splitSupportCountryMap: action.payload,
      }
    },
  },
})

export const {
  setDepartureDefaultAddress,
  setArrivalList,
  setDepartureList,
  setArrivalSearchList,
  setDepartureListHasNext,
  setArrivalListHasNext,
  updateStationList,
  setStationList,
  setStationTypes,
  clearStationList,
  setStationAreaList,
  setAddressVerifyMap,
  setAddressConfig,
  setInitialAddressConfig,
  setSplitSupportCountryMap,
  setInitialVerifyFieldMap,
  setConsigneeVerifyFieldMap,
} = addressSlice.actions

export const fetchStationDetail = (payload: Types.FetchStationDetailPayload) => async () => {
  const response = await Api.admin.post<Types.FetchStationDetailResp>(stationUrl.stationDetail, payload)
  const { bizCode, data, bizMessage, message } = response
  if (bizCode === 'SUCCESS') {
    return data?.stationInfo
  }

  return Promise.reject({ bizCode, bizMessage, message })
}

/** 获取门店列表 | 收货地址 - 店配 */
export const fetchStationList = (payload: Types.FetchStationListPayload) => async (dispatch: AppDispatch) => {
  const params = Object.assign({ pageSize: 20, pageNum: 1 }, payload || {})
  const response = await Api.admin.post<Types.FetchStationListResp>(stationUrl.stationList, params)
  const { bizCode, data, bizMessage, message } = response
  if (bizCode === 'SUCCESS') {
    data && dispatch(setStationList(data))
    return data
  }

  return Promise.reject({ bizCode, bizMessage, message })
}

/** 获取过滤无效门店后的门店列表 - 店配 */
export const fetchStationListFilter = (params: Types.FetchStationListPayload) => async (dispatch: AppDispatch) => {
  const response = await Api.admin.post<Types.FetchStationListResp>(stationUrl.stationListNew, params)
  const { bizCode, data, bizMessage, message } = response
  if (bizCode === 'SUCCESS') {
    data && dispatch(setStationList(data))
    return data
  }

  return Promise.reject({ bizCode, bizMessage, message })
}

/** 获取门店列表分页 | 收货地址 - 店配 */
export const fetchStationListMore = (payload: Types.FetchStationListPayload) => async (dispatch: AppDispatch) => {
  const params = Object.assign({ pageSize: 20, pageNum: 1 }, payload || {})
  const response = await Api.admin.post<Types.FetchStationListResp>(stationUrl.stationList, params)
  const { bizCode, data, bizMessage, message } = response
  if (bizCode === 'SUCCESS') {
    data && dispatch(params.pageNum === 1 ? setStationList(data) : updateStationList(data))
    return data
  }

  return Promise.reject({ bizCode, bizMessage, message })
}

/** 获取门店地址列表 */
export const fetchStationAreaList = (name: string, payload: Types.FetchStationAreaListPayload) => async (dispatch: AppDispatch) => {
  const response = await Api.admin.post<{ areaNodes: Types.StationAreaList }>(stationUrl.stationAreaList, payload)
  const { bizCode, data, bizMessage, message } = response
  if (bizCode === 'SUCCESS') {
    data && dispatch(setStationAreaList({ name, data: data.areaNodes }))
    return data.areaNodes
  }

  return Promise.reject({ bizCode, bizMessage, message })
}

/** 获取门店类型 */
export const fetchStationTypes = () => async (dispatch: AppDispatch) => {
  const response = await fetchDictionaryByKey('STATION_TYPE_DICT')
  const stationTypes = response?.data?.dictionaryInfos || []
  dispatch(setStationTypes(stationTypes))
  return response?.data
}

/**
 * 根据编号或默认地址类型获取地址详细
 * @param addressNo {number} 编号
 * @param isDefault {boolean} 是否默认地址
 */
export const getDepartureDefaultAddress = (props: Types.GetAddressPayload) => async (dispatch: AppDispatch) => {
  const { addressNo, isDefault } = props
  const response = await Api.admin.post<Types.AddressState['departureDefault']>(addressUrl.getAddress, {
    addressNo,
    isDefault,
  })

  const { data, bizCode, bizMessage, message } = response
  if (bizCode === 'SUCCESS') {
    dispatch(setDepartureDefaultAddress(data))
    return data || [null]
  }

  return Promise.reject({ bizCode, bizMessage, message })
}

/**
 * 根据国家或地址类型获取地址列表
 * @param countryCode {string} 地区编码
 * @param addressType {number} 数字类型
 */
export const getAddressList = (payload: Types.GetAddressListPayload) => async (dispatch: AppDispatch, state) => {
  const { countryCode, addressType: ParamAddressType, pageNum, pageSize } = payload
  const response = await Api.toast.post<Types.AddressListState>(addressUrl.getAddressList, {
    countryCode,
    addressType: ParamAddressType,
    pageSize: pageSize || 999,
    pageNum: pageNum || 1,
  })

  const { data } = response
  const pageNo = pageNum || 1
  if (data) {
    let resData = data?.data || []
    if (ParamAddressType === ADDRESS_TYPE.Arrival) {
      const originalData = state().address.arrivalList || []
      resData = pageNo > 1 ? originalData.concat(resData) : resData
      dispatch(setArrivalList(resData))
      dispatch(setArrivalListHasNext(data?.hasNext))
    } else if (ParamAddressType === ADDRESS_TYPE.Departure) {
      const originalData = state().address.departureList || []
      resData = pageNo > 1 ? originalData.concat(resData) : data?.data || []
      dispatch(setDepartureList(resData))
      dispatch(setDepartureListHasNext(data?.hasNext))
    }

    return {
      list: resData,
      hasNext: data?.hasNext,
    }
  }
}

/**
 * 根据国家或地址类型获取地址列表
 * @param addressType {number} 数字类型
 */
export const fetchAddressList = async (params: Types.GetAddressListPayload) => {
  const { countryCode, addressType: ParamAddressType, pageNum, pageSize } = params
  const response = await Api.admin.post<Types.AddressListState>(addressUrl.getAddressList, {
    countryCode,
    addressType: ParamAddressType,
    pageSize: pageSize || 999,
    pageNum: pageNum || 1,
  })
  const { bizCode, bizMessage, message, data } = response
  if (bizCode === 'SUCCESS' && data) {
    return data?.data || []
  }
  throw new Error(bizMessage || message)
}

/**
 * @todo 这里写法最好统一
 */

export const addAddress = async (payload: Types.SubmitAddress) => {
  const response = await Api.admin.post(addressUrl.addAddress, payload)
  const { bizMessage: message, bizCode: code, data } = response
  if (code === 'SUCCESS') {
    return { message, code, data }
  }

  return Promise.reject({ message, code, data: null })
}

export const updateAddress = async (payload: Types.AddressInfoType) => {
  const response = await Api.admin.post(addressUrl.updateAddress, payload)
  const { bizMessage: message, bizCode: code } = response
  if (code === 'SUCCESS') {
    return { message, code }
  }

  return Promise.reject({ message, code })
}

export const deleteAddress = async (addressNo: string) => {
  const response = await Api.admin.post(addressUrl.deleteAdress, { addressNo })
  const { bizCode, bizMessage: message } = response
  if (bizCode === 'SUCCESS') {
    return { bizCode, message }
  }

  return Promise.reject({ bizCode, message })
}

export const setDefaultAddress = async (addressNo: string) => {
  const { bizCode, bizMessage: message } = await Api.admin.post(addressUrl.setDefaultAdress, { addressNo })
  if (bizCode === 'SUCCESS') {
    return { bizCode, message }
  }

  return Promise.reject({ bizCode, message })
}

// 根据多个地址主键获取地址
export const setMultiAddress = async (addressNos: string[]) => {
  const response = await Api.toast.post(addressUrl.getMultiAddress, {
    addressNos,
  })

  const { bizCode, bizMessage: message, data } = response

  return { data, bizCode, message }
}

export const addressVerifyCreator = () => async (dispatch: AppDispatch) => {
  /** 地址必填信息 */
  const body = {
    pageSize: 999,
    pageNum: 1,
  }
  const response = await Api.admin.post<Types.ResVerifyField>('/api/address/verifyFields', body, { params: { ...body, _sw_cache: 15 } })

  const { bizCode, data } = response

  if (bizCode === 'SUCCESS') {
    // 配置没有该国家，一二级地址，姓名，手机必填
    const handler = {
      get: function (target, name) {
        return target.hasOwnProperty(name) ? target[name] : { province: '1', city: '1', name: '1', mobile: '1' }
      },
    }
    const { data: verifyFieldCountries } = data

    const vertifyMap = new Proxy({}, handler)
    const consigneeVertifyMap = new Proxy({}, handler)

    verifyFieldCountries.forEach(({ countryCode, data, consigneeData }) => {
      let map = {}
      let consigneeMap = {}
      try {
        map = JSON.parse(data)
        consigneeMap = JSON.parse(consigneeData)
      } catch (error) {}

      vertifyMap[countryCode] = map
      consigneeVertifyMap[countryCode] = consigneeMap
    })
    dispatch(setAddressVerifyMap(vertifyMap))
    dispatch(setConsigneeVerifyFieldMap(consigneeVertifyMap))
    dispatch(setInitialVerifyFieldMap(true))
  }
}

export const addressConfigCreator = () => async (dispatch: AppDispatch) => {
  /** 一二三级地址显示隐藏配置 */
  const response = await Api.admin.post<Types.AddressConfigType>('/api/address/config?_sw_cache=15')

  const { bizCode, data } = response

  if (bizCode === 'SUCCESS') {
    // 配置没有该国家，省市区默认展示
    if (Array.isArray(data.configs)) {
      const addressConfig = data.configs.reduce((sum, next) => {
        sum[next.countryCode] = next
        return sum
      }, new Proxy({}, AddressInputHandler))

      dispatch(setAddressConfig(addressConfig))
      dispatch(setInitialAddressConfig(true))
      return
    }
  }
}

export const splitSupportCountryMapCreator = () => async (dispatch: AppDispatch) => {
  const data = await getSupportAutoSplitCountryList()

  const map = data
    ? data.reduce((prev, current) => {
        const { enabled, countryCode } = current
        prev[countryCode] = enabled
        return prev
      }, {})
    : {}

  dispatch(setSplitSupportCountryMap(map))
}

export default addressSlice.reducer
