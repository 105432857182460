export default {
  /* 商家订阅信息 */
  getSubscriptionInfo: '/api/crm/admin/subscription/info',
  billList: '/api/crm/admin/subscription/bill/list',
  cancelBill: '/api/crm/admin/subscription/bill/cancel',
  flowingList: '/api/crm/admin/subscription/rights/list',
  exportBill: '/api/subscription/export/bill',
  exportFlow: '/api/subscription/export/rights',
  pollingPayStatus: '/api/crm/admin/subscription/bill/get',
  // 设置是否自动续费
  autoRecurring: '/api/crm/admin/subscription/set-auto-recurring',
  // 查询绑卡结果、信息
  cardInfo: '/api/crm/admin/subscription/bill/card/info',
  // 已绑定卡信息
  cardBounds: '/api/crm/admin/subscription/bill/card/bounds',
  // 支付sdk初始化信息
  billSdkMeta: '/api/crm/admin/subscription/bill/sdk/meta',
  // 获取可购买订阅套餐组
  subscriptionProductGroup: '/api/crm/admin/subscription/product/group',
  // 获取优惠信息
  subscriptionProductPromotion: '/api/crm/admin/promotion/list-sub-promotion',
  // 获取未支付的订阅类账单
  getUnpaidSubBill: '/api/crm/admin/subscription/bill/get-unpaid-sub-bill',
  // 获取可购买商品
  getSubProducts: '/api/crm/admin/subscription/product/products',
  // 获取付费权限
  getFeatureKeys: '/api/crm/admin/subscription/rights/feature-keys',
  // 支付、默认绑卡
  subBillPay: '/api/crm/admin/subscription/bill/pay',
  // 绑定卡
  bindCard: '/api/crm/admin/subscription/bill/card/bind',
  // 升级订阅（更换周期，升级套餐）
  subUpgrade: '/api/crm/admin/subscription/upgrade',
  // 购买订阅（如加购包,试用转付费）
  subCreate: '/api/crm/admin/subscription/create',
  // 获取短信流量
  getServiceKeys: '/api/crm/admin/subscription/rights/service-keys',
  // 分页查询绑卡信息列表
  billCardList: '/api/crm/admin/subscription/bill/card/list',
  // 获取商家订阅当前账号类控制上限使用情况
  accountLimit: '/api/crm/admin/subscription/account/limit_info',
}
