import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { antRoutes, forEachTree } from '@/utils'

interface AuthRedirectProps {
  /** 优先跳转路径 */
  first?: string
}

/**
 * 如果完全匹配当前路由路径，跳转到first，如果first无权限，则跳转到第一个有权限的子菜单
 * 需要在获取权限树后处理
 */
export const withAuthRedirect = (Component: React.FC<any>, authRedirectProps: AuthRedirectProps) => {
  const AuthRedirect: React.FC<any> = (props) => {
    const { route } = props
    const { first } = authRedirectProps
    const { pathname } = useLocation()

    const authRoute = antRoutes(route.routes)
    let firstPath = forEachTree(authRoute, (item) => item.path === first) ? first : (authRoute?.[0]?.path as string)
    // 如果first不在菜单配置里面，则取路由配置里面第一个
    if (!firstPath) firstPath = forEachTree(route.routes, (item) => item.path === first, 'routes') ? first : (route.routes?.[0]?.path as string)

    return pathname === route.path && firstPath ? <Redirect to={firstPath} /> : <Component {...props} />
  }

  AuthRedirect.displayName = 'AuthRedirect'
  return AuthRedirect
}
