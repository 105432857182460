import addressBook from './addressbook'
import stores from './stores'
import { withAsync } from '@/components/Async'
import { withAuthRedirect } from '@/components/Authorized/hoc/withAuthRedirect'
import Blank from '@/layouts/Blank'
import { Setting } from '@yy/one-icon'
import type { RouteConfig } from 'react-router-config'

const routes: RouteConfig[] = [
  // 设置
  {
    alias: 'settings',
    path: '/setting',
    component: withAuthRedirect(Blank, { first: '/setting/addressbook/' }),
    menuTitle: 'menu.setting',
    icon: Setting,
    permissionCode: 'settings:addressbook,settings:store,settings:account,settings:other',
    routes: [
      ...addressBook,
      ...stores,
      // 发货账号设置
      {
        alias: 'shipping_account_setting',
        path: '/account',
        permissionCode: 'settings:account',
        component: withAsync(() => import(/* webpackChunkName: "Account" */ '@/containers/Account')),
        exact: true,
        menuTitle: 'setting.shipsetting.title.new',
        pageDescription: 'setting.shipsetting.desc.new',
      },
      // 运输规则
      {
        alias: 'shipping_rules',
        path: '/shippingRules',
        permissionCode: 'settings:shippingRules',
        component: withAsync(() => import(/* webpackChunkName: "Setting" */ '@/containers/Setting/ShippingRules')),
        exact: true,
        menuTitle: 'menu.setting.shippingRules',
      },
      // 其他设置
      {
        alias: 'other_settings',
        path: '/other',
        permissionCode: 'settings:other',
        component: withAsync(() => import(/* webpackChunkName: "Setting" */ '@/containers/Setting')),
        exact: true,
        menuTitle: 'menu.setting.other',
      },
      {
        path: '/freight',
        component: withAsync(() => import('@/containers/Setting/FreightSetting')),
        exact: true,
      },
    ],
  },
]

export default routes
