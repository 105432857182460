// 运单状态列表

export const noticeSettingList = [
  {
    key: 'DELIVERED', // 轨迹追踪设置时使用的key
    smsNoticeKey: 'SMS_DELIVERED', // 创单时短信通知节点字段使用的key
    i18Key: 'enums.logisticStatus.2',
    statusDetailsI18Key: 'subscribe.trackingSetting.email.toPickUp',
  },
  {
    key: 'PICKED',
    smsNoticeKey: 'SMS_PICKED', // 创单时短信通知节点字段使用的key
    i18Key: 'enums.logisticStatus.21',
    statusDetailsI18Key: 'subscribe.trackingSetting.email.pickedup',
  },
  {
    key: 'PROBLEM',
    smsNoticeKey: 'SMS_PROBLEM', // 创单时短信通知节点字段使用的key
    i18Key: 'enums.logisticStatus.4',
    statusDetailsI18Key: 'subscribe.trackingSetting.email.error',
  },
  {
    key: 'ON_WAY',
    smsNoticeKey: 'SMS_ON_WAY', // 创单时短信通知节点字段使用的key
    i18Key: 'enums.logisticStatus.22',
    statusDetailsI18Key: 'subscribe.trackingSetting.email.inTransit',
  },
  {
    key: 'ON_DELIVERY',
    smsNoticeKey: 'SMS_ON_DELIVERY', // 创单时短信通知节点字段使用的key
    i18Key: 'enums.logisticStatus.24',
    statusDetailsI18Key: 'subscribe.trackingSetting.email.delivering',
  },
  {
    key: 'STATION_ARRIVED',
    smsNoticeKey: 'SMS_STATION_ARRIVED', // 创单时短信通知节点字段使用的key
    i18Key: 'enums.logisticStatus.24002',
    statusDetailsI18Key: 'subscribe.trackingSetting.email.deliveryStatusArrived',
  },
  {
    key: 'RECEIVED',
    smsNoticeKey: 'SMS_RECEIVED', // 创单时短信通知节点字段使用的key
    i18Key: 'enums.logisticStatus.3',
    statusDetailsI18Key: 'subscribe.trackingSetting.email.received',
  },
]

export const traceNotifyOptions = noticeSettingList.map((i) => ({ value: i.smsNoticeKey, label: i.i18Key }))
