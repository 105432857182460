import React, { useEffect, useMemo } from 'react'
import { Redirect } from 'react-router'
import type { RouteConfig } from 'react-router-config'
import { renderRoutes } from 'react-router-config'
import { useHistory } from 'react-router-dom'
import { withAsync } from '@/components/Async'
import { useSelector } from '@/store'
import type { MenuDataItem } from '@/utils/route'
import { absRoutes, travelRoutes } from '@/utils/route'
import admin from './admin'
import auth from './admin/auth'
import { usePermission } from './interceptors/permission'
import middleware from './middleware'
import redirect from './redirect'
import user from './user'
import { logger } from '@/utils/logger'

/**
 * 增加Not Match 404 页面路由
 * @param routes 路由
 */
export function addNotMatchRoute(routes: RouteConfig[]): RouteConfig[] {
  const newRoutes = routes.map((item) => {
    if (item.routes && item.routes.length > 0) {
      item.routes.push({
        path: '/*',
        component: () => <Redirect to="/error" />,
        exact: true,
      })
      return { ...item, routes: addNotMatchRoute(item.routes) }
    } else {
      return item
    }
  })
  return newRoutes
}

export const routes = addNotMatchRoute(
  absRoutes([
    ...redirect,
    ...user,
    ...auth,
    ...middleware,
    {
      path: '/error',
      component: withAsync(() => import(/* webpackChunkName: "Error/NotFoundPage" */ '@/containers/Error/NotFoundPage')),
      exact: true,
    },
    {
      path: '/authority',
      component: withAsync(() => import(/* webpackChunkName: "Error/NotFoundPage" */ '@/components/Authorized/components/Authority')),
      exact: true,
    },
    {
      path: '/order/print',
      component: withAsync(() => import(/* webpackChunkName: "print" */ '@/containers/Order/Print')),
      exact: true,
    },
    {
      path: '/unsubscribe/balanceMonitor',
      component: withAsync(() => import(/* webpackChunkName: "print" */ '@/containers/Wallet/UnSubscribe')),
      exact: true,
    },
    ...admin,
  ])
)

let oldHref = window.location.href

const Router: React.FC = () => {
  const permissionInterceptor = usePermission()
  const countryCode = useSelector((state) => state.user.info?.countryCode)
  const routers = useMemo(() => {
    const interceptedRoutes = travelRoutes(routes, (route: MenuDataItem) => {
      let interceptorRoute = route
      if (typeof route.interceptor === 'function') {
        interceptorRoute = route.interceptor(route, {})
      }
      // 国家展示过滤
      if (route.showCountryCodes && !route.showCountryCodes?.includes(countryCode)) {
        return null
      }
      // 国家展示过滤
      if (route.hideCountryCodes && route.hideCountryCodes?.includes(countryCode)) {
        return null
      }
      // 权限拦截
      interceptorRoute = permissionInterceptor(route)

      return interceptorRoute
    })

    return absRoutes(interceptedRoutes)
  }, [countryCode, permissionInterceptor])

  const history = useHistory()
  useEffect(() => {
    history.listen(() => {
      logger.track({ type: 'navigation', data: oldHref })
      oldHref = window.location.href
    })
  }, [history])

  return renderRoutes(routers)
}

Router.displayName = 'Router'
export default Router
