import { useMount } from 'ahooks'
import type { FieldValues } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'
import { useDynamicFormMethodsContext } from '../components/DynamicFormProvider'
import type { UseDynamicFormMethods } from './useDynamicForm'

export type UseDynamicFormContext<T extends FieldValues = FieldValues> = UseDynamicFormMethods<T>

/**
 * 动态表单上下文
 * @description
 * 必须配合 `DynamicFormController` 使用;
 *
 * 因为 setValue 在没有注册的表单内容中是无法进行赋值的;
 * 因此, 这里挟持了 setValue 并对没有注册的字段进行先注册;
 * 主要用于动态表单 Controller 没有被渲染的情况;
 * 而使用 Controller 也无法在当前渲染中绑定到表单节点; 只是 useForm 的上下文
 * 字段值变更; 并没有同步到节点上, 因此这里必须配合 `DynamicFormController`
 * 进行表单节点渲染
 *
 * 代码片段:
 * ```tsx
 * const { setValue } = useDynamicFormContext()
 * useEffect(() => {
 *  ;(async () => {
 *    await fetch('something')
 *    setValue('title', 'abc')
 *  })();
 * })
 *
 * return {show ? <DynamicFormController ... /> : null}
 * ```
 */
export function useDynamicFormContext<TFieldValues extends FieldValues = FieldValues>(): UseDynamicFormContext<TFieldValues> {
  const methods = useFormContext<TFieldValues>()
  const extraMethods = useDynamicFormMethodsContext<TFieldValues>()

  /** 注销的时候会导致这里多次渲染 */
  useMount(() => {
    if (!methods) {
      // eslint-disable-next-line no-console
      console.error('检测不到表单的方法，如果在 FormProvider 页面使用，请使用 use入DynamicForm')
    }
  })

  return { ...methods, ...extraMethods }
}
