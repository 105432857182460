import type { PaginationProps } from 'antd'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { OneMessage } from '@yy/one-ui'
import { DEFAULT_PAGE_SIZE_OPTIONS } from '@/constant'

/**
 * 表格查询hook
 * @param
 * @returns
 */

interface OptionsType {
  dataKey?: string
  /** 是否有下一页的Key */
  hasNextKey?: string
  /** 总数key */
  totalKey?: string
  pageIndexKey?: string
  pageSizeKey?: string
  defaultSearchParams?: Record<string, unknown>
  defaultPageSize?: number
}

export interface ReducerState<T> {
  loading: boolean
  pageIndex: number
  pageSize: number
  hasNext: boolean
  total: number
  searchParams: Record<string, unknown>
  list: Array<T>
}

export type Action =
  | { type: 'setLoading'; playLoad: boolean }
  | { type: 'setPageIndex'; playLoad: number }
  | { type: 'setPageSize'; playLoad: number }
  | { type: 'setHasNext'; playLoad: boolean }
  | { type: 'setTotal'; playLoad: number }
  | { type: 'setList'; playLoad: any[] }
  | { type: 'setParams'; playLoad: Record<string, unknown> }

export function useTableSearch(fetchApi: (params: any) => Promise<any>, options?: OptionsType) {
  const {
    dataKey = 'data', // 返回数据列表的Key
    hasNextKey = 'hasNext', // 是否有下一页的Key
    totalKey = 'total', // 总数key
    pageIndexKey = 'pageNum', // 请求参数key
    pageSizeKey = 'pageSize', // 请求参数key
    defaultSearchParams, // 默认搜索参数
    defaultPageSize = 25, // 默认页码
  } = options || {}

  const [reloadFlag, setReloadFlag] = useState(false)

  const [tableState, dispatch] = useReducer(
    (state, action) => {
      const { type, payLoad } = action
      switch (type) {
        case 'setLoading':
          return { ...state, loading: payLoad }
        case 'setPageIndex':
          return { ...state, pageIndex: payLoad }
        case 'setPageSize':
          return { ...state, pageSize: payLoad }
        case 'setHasNext':
          return { ...state, hasNext: payLoad }
        case 'setTotal':
          return { ...state, total: payLoad }
        case 'setList':
          return { ...state, list: payLoad }
        case 'setParams':
          return { ...state, searchParams: payLoad }
        default:
          return { ...state }
      }
    },
    {
      loading: false,
      pageIndex: 1,
      pageSize: defaultPageSize,
      hasNext: true,
      total: 0,
      searchParams: defaultSearchParams || {},
      list: [],
    }
  )

  const pagination = useMemo<PaginationProps>(
    () => ({
      showSizeChanger: true,
      total: tableState.total,
      current: tableState.pageIndex,
      pageSize: tableState.pageSize,
      pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
      onChange: (pageNum, pageSize) => {
        dispatch({
          type: 'setPageIndex',
          payLoad: pageNum,
        })
        dispatch({
          type: 'setPageSize',
          payLoad: pageSize,
        })
      },
    }),
    [tableState.pageIndex, tableState.pageSize, tableState.total]
  )

  const { pageIndex, pageSize, searchParams } = tableState

  // 请求数据
  const queryTable = useCallback(
    async (params) => {
      dispatch({ type: 'setLoading', payLoad: true })
      try {
        const res = await fetchApi(params)
        const { data } = res || {}
        const list = data?.[dataKey]
        if (data) {
          dispatch({ type: 'setList', payLoad: list || [] })
          dispatch({ type: 'setHasNext', payLoad: data[hasNextKey] })
          dispatch({ type: 'setTotal', payLoad: data[totalKey] })
        }
      } catch (error) {
        OneMessage.error(error.message)
      }

      dispatch({ type: 'setLoading', payLoad: false })
    },
    [dataKey, hasNextKey, fetchApi, totalKey]
  )

  useEffect(() => {
    queryTable({
      [pageIndexKey]: pageIndex,
      [pageSizeKey]: pageSize,
      ...searchParams,
    })
  }, [pageIndex, pageSize, searchParams, pageIndexKey, pageSizeKey, queryTable, reloadFlag])

  // 触发列表查询
  const reload = useCallback(() => {
    setReloadFlag(!reloadFlag)
  }, [reloadFlag])

  return { tableState, dispatch, pagination, reload, list: tableState.list, loading: tableState.loading }
}
