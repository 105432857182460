import { withAsync } from '@/components/Async'
import type { RouteConfig } from 'react-router-config'

const routes: RouteConfig[] = [
  {
    alias: 'survey',
    hideMenu: true,
    exact: true,
    noSide: true,
    noModal: true,
    path: '/questionnaire/:seq',
    component: withAsync(() => import(/* webpackChunkName: "Survey" */ '@/containers/Survey')),
  },
]

export default routes
