import type { Form } from 'antd'
import { trim } from 'lodash'
import { ACTION_TYPE } from '@/constant'
import type { SearchTabItem, SettingItemType, TabFeature } from '@/store/commonSlice/types'
import type { TagListData } from '@/store/customTagSlice/types'

type ValidateStatus = Parameters<typeof Form.Item>[0]['validateStatus']

// 自定义选项卡名称校验
export const validateTabName = (
  tabList: SearchTabItem[],
  value: string,
  customTabIndex: number,
  isEdit: boolean,
  defaultTabNames: string[]
): {
  validateStatus: ValidateStatus
  errorMsg: string
} => {
  value = trim(value)
  if (!value) {
    return {
      validateStatus: 'error',
      errorMsg: 'common.search.errorMsg.emptyName',
    }
  }
  if (value.length > 64) {
    return {
      validateStatus: 'error',
      errorMsg: 'customTag.errorMsg.longestStringLength',
    }
  }
  const len = tabList.filter((item, index) => {
    if (isEdit && index === customTabIndex) {
      return false
    } else {
      return item.tabName === value
    }
  }).length
  if (len > 0 || defaultTabNames.includes(value)) {
    return {
      validateStatus: 'error',
      errorMsg: 'common.search.errorMsg.duplicateName',
    }
  }
  return {
    validateStatus: '',
    errorMsg: '',
  }
}

// 选项卡列表
export const extractTabList = (settingItem: SettingItemType) => {
  const dataValue = settingItem?.dataValue
  if (!dataValue) return []
  let tabList: SearchTabItem[] = []
  try {
    if (dataValue.length > 0) {
      tabList = JSON.parse(dataValue)?.tabList || []
    }
  } catch (error) {
    // eslint-disable-next-line
    console.log(error)
  }
  return tabList
}

// 删除标签后需要更新选项卡中的标签筛选条件
export const updateFilterTagInTabFeature = (tabList: SearchTabItem[], tagList: TagListData[]): TabFeature => {
  const newTabList = [...tabList]
  const tagIds = tagList.filter((item) => item.actionType !== ACTION_TYPE.DELETE).map((item) => item.tagId)
  for (const tabItem of newTabList) {
    if (tabItem?.extendInfo?.filter_tag?.length > 0) {
      tabItem.extendInfo.filter_tag = tabItem.extendInfo.filter_tag.filter((item) => tagIds.includes(item?.value))
      tabItem.condition.tagIds = tabItem.condition.tagIds.filter((item) => tagIds.includes(item))
    }
  }
  return {
    tabList: newTabList,
  }
}
