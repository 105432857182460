export enum SHIPMENT_PICKUP_TYPE {
  /**上门取货 */
  pickUp = 1,
  /**自邮 */
  dropOff = 2,
}

/**收件人证件类型 */
export enum CONSIGNEE_CERTTIFICATE_TYPE {
  // 身份证
  idCard = 1,
  // 护照
  passport = 2,
  // 港澳通行证
  ghPass = 3,
  // 回乡证
  hxPass = 4,
  // 台胞证
  tbPass = 5,
  // 赴台同学证
  twPass = 6,
  // 其他
  other = 7,
}

/**使用场景：订单/运单 */
export enum SCENE_TYPE {
  order = 'order',
  logistic = 'logistic',
}

/**
 * COD TAB
 */
export enum COD_TAB {
  ALL,
  COD,
  NO_COD,
}
