import type { Any, Options } from 'currency.js'
import currency from 'currency.js'
import { divide } from 'number-precision'
import { CurrencyConverted } from '@/store/order/batchCreateSlice/types'
import { ORDER_TYPE } from '@/store/parcelSlice/types'
import type { CodCurrencyType } from '@/services/apis/express/type'

/**
 * format currency without symbol
 */
export const formatCurrency = (value: Any, options?: Options) => {
  const precision = value?.toString().includes('.') ? 2 : 0
  const precisionNum = typeof options?.precision === 'number' ? options?.precision : precision

  return currency(value, {
    symbol: '',
    ...options,
    precision: precisionNum,
  }).format()
}

/**
 * return the currency format
 */
export const unFormatCurrency = (value: Any) => {
  return currency(value).value
}

/**
 *
 * @param amount
 * @param precision 小数点
 * @returns
 */
export function translateCurrency(amount: number, precision?: number) {
  return formatCurrency(divide(amount || 0, 100), { precision })
}

// 判断是否可以币种转换
export const canConvertedCurrency = (options: { currencyRate: string; orderType: number; isApiOder: boolean; originCurrencyCode: string; arrivalCurrencyCode: string }) => {
  const { currencyRate, orderType, isApiOder, originCurrencyCode, arrivalCurrencyCode } = options
  return !!Number(currencyRate) && orderType === ORDER_TYPE.ORDER_EDIT && isApiOder && !!originCurrencyCode && originCurrencyCode !== arrivalCurrencyCode
}

// 判断货币是否已转换：通过后端返回的金额是否已转换（feeInfo.currencyConverted）
export const hasCurrencyConverted = (converted: number) => converted === CurrencyConverted.CONVERTED

// 指定COD币种的服务产品COD币种需要使用配置的特定币种
export const isCodCurrencyCodeSpecified = (codCurrencyType: CodCurrencyType) => codCurrencyType === 2
