import type { OneshipLogisticsFeeResVo } from '../batchMergeShip/types'
import type { LalamoveCalculateAddressVo } from '../order/type'
import type { RightItemType } from '@/bizComponent/FeeTotal'
import type { IConsigneeAddressItem, IConsigneeAddressVO, ISenderAddressItem, ISenderAddressVO } from '@/components/AddressChooseModal/type'
import type { IAppId } from '@/components/PlatformAvatar'
import type { CountryCode } from '@/constant'
import type { ArrivalAddressType } from '@/store/stepSlice/types'
import type { ChannelCustomInfo } from '../type'
import type { FeeItem } from '@/store/serviceProviderSlice/types'

/** 物流类型（不传默认快递） - express: 快递 - ondemand: 即时 - reverse: 逆向物流 */
export type LogisticsType = 'express' | 'ondemand' | 'reverse'

/** 订单转运单payload */
export interface IOrder2LogisticsPayload {
  orderNos: string[]
  convertCurrencyType?: number
  /** 物流类型（不传默认快递） - express: 快递 - ondemand: 即时 */
  logisticsType?: LogisticsType
}

export interface ILogistics2ReversePayload {
  logisticsNos: string[]
}
/** 订单发货物流信息 */
export interface IOrder2LogisticsRes {
  validateCodes?: Array<{
    bizCode: string
    bizMessage: string
    fieldNames: string[]
  }>
  /** 服务商 */
  companyCode: string
  /** 服务产品编码 */
  expressTypeCode: string
  /** 是否绑定 oneship 销售订单 */
  bindOneshipSalesOrder: boolean
  /** 客户订单号/销售订单号(不填则由系统自动生成) */
  orderNo: string
  /** 原始订单号 前端用于展示参考号 */
  originOrderNo: string
  /** 数量 */
  quantity: number
  /** 重量/kg */
  weight: number
  /** 重量单位（g-克，kg-千克，lb-磅，oz-盎司） */
  weightUnit: string
  /** 长度/cm */
  length: number
  /** 宽度/cm */
  width: number
  /** 高度/cm */
  height: number
  /** 长宽高单位/cm */
  lengthUnit: string
  /** 收寄件地址信息 */
  address: {
    /** 发货地址别名 */
    locationName?: string
    /** 发件地址编号，地址簿地址编号 */
    senderReferenceNo?: string
    /** 寄件人证件号 */
    senderIdNo?: string
    /** 证件类型：1身份证,2护照,3港澳通行证,4回乡证,5台胞证,6赴台通行证,7其它 */
    senderIdType?: number
    /** 寄件国家 */
    senderCountryCode: CountryCode | string
    /** 寄件省份 */
    senderProvince: string
    /** 寄件城市 */
    senderCity: string
    /** 寄件区县 */
    senderDistrict: string
    /** 寄件街镇 */
    senderTown: string
    /** 寄件人 */
    senderName: string
    /** 寄件人电话 */
    senderPhone: string
    /** 寄件人手机 */
    senderMobile: string
    /** 寄件详细地址 */
    senderAddress: string
    /** 寄件详细地址2 */
    senderAddressTwo: string
    /** 寄件邮编 */
    senderZipCode: string
    /** 寄件备注 */
    senderRemark: string
    /** 发件人邮箱 */
    senderEmail: string
    /** 收件人证件号 */
    consigneeIdNo: string
    /** 证件类型：1身份证,2护照,3港澳通行证,4回乡证,5台胞证,6赴台通行证,7其它, 圆通只能用1和2 */
    consigneeIdType: number
    /** 收件国家 */
    consigneeCountryCode: string
    /** 收件省份 */
    consigneeProvince: string
    /** 收件城市 */
    consigneeCity: string
    /** 收件区县 */
    consigneeDistrict: string
    /** 收件街镇 */
    consigneeTown: string
    /** 收件详细地址 */
    consigneeAddress: string
    /** 收件详细地址2 */
    consigneeAddressTwo: string
    /** 收件人 */
    consigneeName: string
    /** 收件人电话 */
    consigneePhone: string
    /** 收件人手机 */
    consigneeMobile: string
    /** 收件邮编 */
    consigneeZipCode: string
    /** 收件备注 */
    consigneeRemark: string
    /** 收件门店编码 */
    consigneeStationSeq: string
    /** 收件人邮箱 */
    consigneeEmail: string

    /**
     * 店配地址
     */
    consigneeStationCode?: string
    /** 门店名称 */
    consigneeStationName: string

    senderAddressLanguages?: string[]

    consigneeAddressLanguages?: string[]

    consigneeStationType?: number
  }
  /** 收件人邮箱 */
  feeInfo: {
    /** 是否购买保险 1 是 2 否  */
    insuranceType: number
    /** 运费(单位/分) */
    freight: number
    /** 折扣(单位/分) */
    discountFreight: number
    /** 原始运费(单位/分) */
    originalFreight: number
    /** 保险费(单位/分) */
    premium: number
    /** 保险金额/保额(单位/分) */
    insuredAmount: number
    /** 包装费(单位/分) */
    packAmount: number
    /** 支付方式:1-现付，2-到付，3-月结， 4-第三方支付 */
    payType: number
    /** 总费用(单位/分) */
    totalAmount: number
    /** 代收货款 */
    codAmount: number
    /** 订单实付金额中商品金额 */
    orderCommodityAmount: number
    /** 订单实付金额 */
    orderAmount: number
    /** 扩展信息 */
    extInfo: string
    /** 币种 */
    currencyCode: string
    /** 电商订单支付方式:1COD支付, 2非COD其它支付方式 */
    paymentType: number
    /** 电商订单支付方式:1COD支付, 2非COD其它支付方式 */
    monthlyAccount: string
    /** 商品是否转换了货币 1=转换过，-1=没转换 没资格转 */
    currencyConverted: number
    /** cod是否转换了货币 1=转换过，-1=没转换 没资格转 */
    codCurrencyConverted?: number
    /** 订单原币种  */
    originCurrencyCode: string
    /**汇率转换 币种 */
    currencyRate?: number
    /**汇率对应的时间版本 */
    fetchTime?: number
    codCurrencyCode?: string
    codCurrencyRate?: number
    codFetchTime?: number
    /** 原始订单实付金额 */
    originOrderAmount?: number
    freightCurrencyCode: string

    /** 商家自定义运费 */
    sellerCustomFreight?: number
    sellerCustomFreightCurrencyCode?: string
    /** 商家输入的值 */
    sellerCodAmount?: number
    /** 原订单运费 */
    deductedFreight?: number
    deductedFreightCurrencyCode?: string
    originSellerCodAmount?: number
    originSellerCodCurrency?: string
    /** 退货代收金额 */
    reverseFreight?: number
    /** 退货代收金额货币 */
    reverseFreightCurrency?: string
  }
  /** 附加信息 */
  extraInfo: {
    /** 注释号码 */
    customIdNo?: string
    /** 航空禁运品 */
    airEmbargo: boolean
    /** 易碎 */
    fragile: boolean
    /** 生鲜 */
    fresh: boolean
    /** 储存条件 */
    storageCdt: string
    /** 备注 */
    remark: string
    /** 是否返单 */
    returnBill: boolean
    /** 交货方式：1送货上门,2上楼,3自提 */
    deliveryType: number
    /** 包装方式：0默认，1纸箱，2气泡袋，3泡沫箱，4木架 */
    packingType: string
    /** 商铺ID */
    storeId: string
    /** 买家Id */
    buyerId: string
    /** 仓库ID */
    warehouseId: string
    /** 揽收方式 */
    pickupType: number
    /** 税号信息 */
    taxes?: {
      /** 税号类型 */
      taxType: string
      /** 税号 */
      taxNo: string
    }[]
    /** 税号 */
    taxNumber: string
    /** 派送日期 */
    requiredDeliveryDates: string[]
    /** 派送时间 */
    requiredDeliveryTimeslots: string[]

    packageDesc?: string
    /** 短信通知关注的物流轨迹状态列表  */
    messageTraceNotifyStatus?: string[]
    /** 退货原订单号 */
    reverseOrderNo?: string[]
  }
  /** 托寄物品列表 */
  commodities: Array<{
    /** 商品名称 */
    goodsName: string
    /** 商品英文名称 */
    goodsEname: string
    /** 商品编码 */
    goodsCode: string
    /** 商品规格描述 */
    goodsSize: string
    /** 数量 */
    goodsQuantity: number
    /** 价格 */
    goodsPrice: number
    /** 重量/kg */
    goodsWeight: number
    /** 体积/cm3 */
    goodsVolume: number
    /** 商品描述 */
    goodsDesc: string
    /** 图片 */
    goodsPic: string
    /** 长cm */
    goodsLength: number
    /** 宽cm */
    goodsWidth: number
    /** 高cm */
    goodsHeight: number
    /** ext_info扩展信息,json格式 */
    extInfo: string
    /** 货币 */
    currencyCode: string
    /** 商品中文品名 */
    goodsCname: string
    /** 出口国商品海关编码 */
    goodsHscode: string
    /** 原价格 */
    originGoodsPrice?: number
    /** 原币种 */
    orginCurrencyCode?: string
    /** 商品材质 */
    goodsMaterial?: string
    /** 商品用途 */
    goodsPurpose?: string

    /** 原产国 */
    originCountryCode?: string
    // the url of the product for example the Taobao's commodity
    goodsUrl?: string
  }>
  /** 预约提货日期 */
  requiredPickupDates: string[]
  /** 预约提货时间 */
  requiredPickupTimeslots: string[]
  /** 时区 */
  timezone: string
  /* 合单收货地址 */
  shipmentAddressVos: Array<{
    /* 订单号 */
    orderNos: string[]
    /* 收货地址 */
    address: ArrivalAddressType
  }>
  /**
   * 合单发货收货地址
   */
  consigneeAddressVos?: IConsigneeAddressVO[]
  /**
   * 合单发货发货地址
   */
  senderAddressVos?: ISenderAddressVO[]
  /* 应用ID */
  appId: IAppId
  /** 服务编码/子账号 */
  channelSellerId?: string
  /**default:默认值；autoMatch:自动匹配 */
  expressTypeMatchType: 'default' | 'autoMatch'
  /**渠道特性字段 */
  channelCustomInfo?: ChannelCustomInfo

  isECommerce?: boolean
  /** 订单运费 */
  salesOrderFreight?: number
  /** 订单 cod amount */
  salesOrderAmount?: number
  /**收货地址 */
  consigneeAddresses?: LalamoveCalculateAddressVo[]

  /** 1 手工单 0:API单 */
  sourceType?: 1 | 0

  /** 服务产品物流类型 1.平台物流,2:LMS物流 */
  expressLogisticsType?: 1 | 2
}

export interface BatchCreateState {
  taskId: string
}

export const DELIVER_VALUE_MAP = {
  HOME: 1,
  STORE: 4,
}

export interface OrderBatchCreateRequest {
  /**预约提货日期 */
  requiredPickupDates?: string[]
  /**预约提货时间 */
  requiredPickupTimeslots?: string[]
  /**时区 */
  timezone?: string
  /**月结账号，提交发货时必填 */
  monthlyAccount?: string
  /**发件地址 */
  senderAddress?: OrderAddressInfoVo
  /**渠道特性字段 */
  channelCustomInfo?: ChannelCustomInfo
  /**需要根据揽收方式传值，oneship新增zeek的服务编码录入框 */
  channelSellerId?: string
  /**付款方式, 寄付、月结 */
  payType?: number
  /**海关登记号-对于4px，就是欧盟的eori号（约束：customIdNo length must less than 32!） */
  customIdNo?: string
  /**配送地址类型，4:店配; 1:宅配 */
  deliveryType?: 1 | 4
  /**揽收方式 1 pickUp 2 dropOff */
  pickupType?: 1 | 2
  /**任务ID */
  taskId: string
  /**是否需要调用3pl执行发货 */
  asyncSend: boolean
  /**
   * 运单类型
   * @description
   * 暂时只需要在逆向物流使用到
   */
  logisticsType?: LogisticsType
}

export interface BatchCreateUpdateRequest {
  /**任务ID */
  taskId: string
  orders: BatchCreateOrderVo[]
}

export interface BatchCreateValidateRequest {
  /**订单文件key（约束：fileId must not be blank!） */
  fileId: string
  /**物流类型（不传默认快递）
- express: 快递
- ondemand: 即时 */
  logisticsType?: 'express' | 'ondemand' | 'reverse'
  /**快递服务产品编码（约束：expressTypeCode must not be blank!） */
  expressTypeCode: string
  /**预约提货日期 */
  requiredPickupDates?: string[]
  /**预约提货时间 */
  requiredPickupTimeslots?: string[]
  /**寄件地址信息 */
  senderAddress?: OrderAddressInfoVo
  /** 收件地址信息 */
  consigneeAddress?: OrderAddressInfoVo
  /**渠道特性字段 */
  channelCustomInfo?: ChannelCustomInfo
  /**时区 */
  timezone?: string
  /**月结账号，提交发货时必填 */
  monthlyAccount?: string
  /**需要根据揽收方式传值，oneship新增zeek的服务编码录入框 */
  channelSellerId?: string
  /**付款方式 */
  payType?: number
  /**海关登记号-对于4px，就是欧盟的eori号（约束：customIdNo length must less than 32!） */
  customIdNo?: string
  /**配送地址类型，
4:店配
1:宅配 */
  deliveryType?: 1 | 4
  /**揽收方式 1 pickUp 2 dropOff */
  pickupType?: 1 | 2
}

export interface BatchCreateOrderVo {
  /**参考号、原订单号（约束：originOrderNo must not be blank） */
  originOrderNo: string
  /**收件地址（约束：consigneeAddresses must not be empty） */
  consigneeAddresses?: OrderAddressInfoVo[]
  /** 发货地址 */
  senderAddresses?: OrderAddressInfoVo[]
  /**渠道特性字段 */
  channelCustomInfo?: ChannelCustomInfo
}

export interface OrderAddressInfoVo {
  /**国家（约束：countryCode length must be 2！；countryCode must not be blank!） */
  countryCode?: string
  /**省份（约束：province length must be less than or equal to ） */
  province?: string
  /**城市（约束：city length must be less than or equal to ） */
  city?: string
  /**区县（约束：district length must be less than or equal to ） */
  district?: string
  /**街镇（约束：town length must be less than or equal to ） */
  town?: string
  /**详细地址（约束：address must less than 128） */
  address?: string
  /**详细地址2（约束：addressTwo must less than 128） */
  addressTwo?: string
  /**人（约束：senderName must less than 64!；name must not be blank!） */
  name?: string
  /**人电话 */
  phone?: string
  /**人手机（约束：mobile must less than 20!） */
  mobile?: string
  /**邮编（约束：zipCode length must be less than or equal to ） */
  zipCode?: string
  /**备注 */
  remark?: string
  /**人邮箱 */
  email?: string
  /**地址语言 */
  addressLanguages?: string[]
  /**门店编码 */
  stationSeq?: string
  /**原始门店编码 */
  stationCode?: string
  /**门店类型 */
  stationType?: string
  /**
   * 智能拆分地址信息
   */
  consigneeSummary?: string
}

export interface S3ResponseType {
  actionUrl: string
  fields: { [propName: string]: any }[]
  headers: { [propName: string]: any }[] | null
  fileId: string
}

export interface ValidateItemType
  extends Pick<
    OneshipLogisticsFeeResVo,
    'sellerCodAmount' | 'codCurrencyCode' | 'deductedFreight' | 'deductedFreightCurrencyCode' | 'sellerCustomFreight' | 'originSellerCodAmount' | 'originSellerCodCurrency'
  > {
  originOrderNo?: string
  orderNo: string
  freightCurrencyCode?: string
  freight?: number
  originalFreight?: number
  discountFreight?: number
  premium?: number
  insuranceType?: number
  codAmount?: number
  currencyCode?: string
  address: IConsigneeAddressItem & ISenderAddressItem & { smartSplit: 0 | 1 }
  validateCodes?: {
    serialVersionUID: number
    SELLER_CUSTOM_FREIGHT_TYPE: string
    fieldNames: string[]
    bizCode: string
    bizMessage: string
    bizType: string
  }[]
  consigneeAddresses?: (OrderAddressInfoVo & { smartSplit: 0 | 1; addressNo: string })[]
}

export interface FeeSummaryVo {
  /**运费，标准货币单位*100 */
  freight?: number
  /**订单币种 */
  currencyCode?: string
  /**优惠运费（差值），标准货币单位*100 */
  discountFreight?: number
  /**保费，标准货币单位*100 */
  premium?: number
  /**合计，标准货币单位*100 */
  totalFee?: number

  feeItemsSummary?: FeeItem[]
}

export interface BatchCreateValidateRes {
  pageNum: number
  pageSize: number
  hasNext: boolean
  data: ValidateItemType[]
  /**任务id */
  taskId: string
  /**有效数量 */
  validCount: number
  /**无效数量 */
  invalidCount?: number
  /**总数 */
  total: number
  /**费用合计 */
  summaryFees: FeeSummaryVo[]
  /**权益结果 */
  rightsResults?: RightItemType[]
  /** 是否使用了地址拆分 */
  smartSplit?: 0 | 1
}
