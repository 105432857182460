import { is711CrossBorder, isCatService, isMapService } from '@/bizComponent/RecipientAddressForm/utils'
import type { IAddress } from '@/components/AddressInfo/type'
import { INSURANCE_TYPE, SETVICE_TYPE, SHIPMENT_PICKUP_TYPE } from '@/constant'
import { ARRIVAL_METHOD } from '@/constant/arrivalMethod'
import type { AutoSplitAddressType } from '@/services/apis/address/types'
import type { AddressInfoType } from '@/store/addressSlice/types'
import type { OneshipLogisticsFeeResVo } from '@/services/apis/batchMergeShip/types'
import type { CUSTOM_FREIGHT_REASON_ENUM } from '@/store/newServiceProviderSlice/enum'
import type { IProviderFormValues, ISetProviderProps } from '@/store/newServiceProviderSlice/types'
import { CurrencyConverted } from '@/store/order/batchCreateSlice/types'
import type { LogisticsOrderInfo } from '@/store/order/createSlice/types'
import type { IParcelFormValues } from '@/store/parcelSlice/types'
import type { IRecipientAddressFormValues } from '@/store/recipientAddressSlice/types'
import type { ISenderAddressFormValues } from '@/store/senderAddressSlice/types'
import type { ExpressListData, FeeItem } from '@/store/serviceProviderSlice/types'
import { decodeLanguageAndProvince, encodeLanguageAndProvince } from '../address'
import type * as Types from './types'
import { isUndefined } from 'lodash'
import moment from 'moment'
import { divide, plus, times } from 'number-precision'
import type { ChannelCustomInfo } from '@/services/apis/type'

export const format = (data: Record<string, any>) => {
  const { search_text, search_type, time_range, ...filter } = data
  return {
    search_text,
    search_type,
    time_range,
    filter,
  }
}

/**
 * 地址格式化：国家与一级地址与二级的分隔符用'-'
 * 展示三级级地址
 */
export const formatAddress = (country, province, city, district?: string) => {
  // 业务规则：展示两级地址
  if (country && (province || city)) {
    // country-province|city
    return `${country}${province ? '-' : ''}${province || ''}${city ? '-' : ''}${city || ''}${district ? `-${district}` : ''}`
  }

  // 降级
  return `${country || ''} ${province || ''} ${city || ''} ${district || ''}`
}

/**
 * 店配地址格式化
 */
export const formatStoreAddress = (stationAddress, stationName, stationCode) => {
  // 如果门店名称和编码都有就显示全部
  return `${stationAddress || ''}${stationAddress ? ',' : ''}${stationName || ''}${stationCode ? '(' : ''}${stationCode || ''}${stationCode ? ')' : ''}`
}

/**
 * 税号 EORI号格式化
 */
export const formatTaxEori = (taxText, tax, EORIText, EORI) => {
  if (tax && EORI) {
    return `${taxText} - ${tax}; ${EORIText} - ${EORI}`
  } else if (tax) {
    return `${taxText} - ${tax}`
  } else if (EORI) return `${EORIText} - ${EORI}`
  return ``
}

/** format成订单专用的数据 */

export interface IAllFields extends IRecipientAddressFormValues, ISenderAddressFormValues, IParcelFormValues, IProviderFormValues {
  /** 轨迹订阅短信通知状态列表 */
  provider_message_trace_notify_status?: string[]
}

type IStore = {
  senderReferenceNo?: string
  /** 上游费用 */
  freight?: number
  feeInfo?: any
  discountFreight?: number
  originalFreight?: number
  /** 运费试算的值 */
  estimatedCost?: number
  /** 汇率版本 */
  parcel_rate_fetch_time?: number
  /** 运费币种 */
  freightCurrencyCode?: string
  /** 汇率 */
  parcel_currency_rate?: number
  /** 是否发货 true 保存并发货  false仅保存 */
  asyncSend: boolean
  /** 服务产品编码 */
  expressTypeCode: string
  /** 时区 */
  timeZone: string
  /** 是否绑定销售订单 */
  bindOneshipSalesOrder?: boolean // 此字段暂时意义不明，需要后续确认
  cod_currency_rate?: number
  cod_fetchTime?: number
  provider_cod_amount_target?: number
  provider_cod_currency_target?: string
  companyCode?: string
  supportedCurrencies?: string[]
  codCurrencyConverted?: boolean
  codCurrencyCode?: string
} & Pick<OneshipLogisticsFeeResVo, 'deductedFreight' | 'deductedFreightCurrencyCode'> & {
    feeItems?: FeeItem[]
  }

export interface IClientToService {
  fieldsValue: IAllFields
  ext: IStore
}

type IFormaSenderAddress = ISenderAddressFormValues & Pick<IStore, 'senderReferenceNo'> & Pick<IParcelFormValues, 'parcel_sender_remark'>

/* 转接发货地址 */
export const formatSenderAddressToService = (params: IFormaSenderAddress) => {
  const departure_province = decodeLanguageAndProvince(params?.departure_province)?.province

  return {
    senderReferenceNo: params?.senderReferenceNo,
    senderCountryCode: params.departure_countryCode,
    senderProvince: departure_province || '',
    senderCity: params?.departure_city || '',
    senderDistrict: params?.departure_district || '',
    senderTown: params?.departure_town || '',
    senderName: params.departure_fullName,
    senderMobile: params?.departure_mobile,
    senderAddress: params?.departure_address,
    senderAddressTwo: params?.departure_addressTwo,
    senderZipCode: params?.departure_zipCode,
    senderEmail: params?.departure_email,
    senderRemark: params.parcel_sender_remark,
  }
}

type IFormatRecipientAddress = IRecipientAddressFormValues & Pick<IProviderFormValues, 'provider_consignee_id_num' | 'provider_consignee_id_type'>
/* 转接收地址 */
export const formatRecipientAddressToService = (params: IFormatRecipientAddress) => {
  const { arrival_method, arrival_store_companyCode } = params

  // 相同的信息
  const sameInfo = {
    consigneeName: params?.arrival_fullName,
    consigneeMobile: params?.arrival_mobile,
    consigneeEmail: params?.arrival_email,
    consigneeIdNo: params?.provider_consignee_id_num,
    consigneeIdType: params?.provider_consignee_id_type,
  }

  const arrival_province = decodeLanguageAndProvince(params?.arrival_province)?.province

  // 宅配 || 店配特殊逻辑，711跨境也需要地址
  if (arrival_method === ARRIVAL_METHOD.home) {
    return {
      consigneeCountryCode: params.arrival_countryCode,
      consigneeProvince: arrival_province || '',
      consigneeCity: params?.arrival_city || '',
      consigneeDistrict: params?.arrival_district || '',
      consigneeTown: params?.arrival_town || '',
      consigneeAddress: params?.arrival_address,
      consigneeAddressTwo: params?.arrival_addressTwo,
      consigneeZipCode: params?.arrival_zipCode,
      ...sameInfo,
    }
  }

  const store711AddressLength = params.arrival_store_711station?.address?.length
  // 地址1最大长度
  const maxAddressLength = 256

  /** 黑猫特殊处理 */
  const station711Data = isMapService(arrival_store_companyCode)
    ? {
        consigneeStationName: params.arrival_store_711station?.name,
        consigneeStationCode: params.arrival_store_711station?.storeId,
        // 711跨境不需要覆盖地址
        ...(is711CrossBorder(arrival_store_companyCode)
          ? {
              consigneeCountryCode: params.arrival_countryCode,
              consigneeProvince: arrival_province || '',
              consigneeCity: params?.arrival_city || '',
              consigneeDistrict: params?.arrival_district || '',
              consigneeTown: params?.arrival_town || '',
              consigneeAddress: params?.arrival_address || '',
              consigneeAddressTwo: params?.arrival_addressTwo || '',
              consigneeZipCode: params?.arrival_zipCode || '',
            }
          : {
              consigneeProvince: params.arrival_store_711station?.province,
              consigneeCity: params.arrival_store_711station?.city,
              consigneeDistrict: params.arrival_store_711station?.district,
              consigneeAddress: params.arrival_store_711station?.address?.slice(0, maxAddressLength),
              consigneeAddressTwo: params.arrival_store_711station?.address?.slice(maxAddressLength, store711AddressLength),
            }),
      }
    : {}

  return {
    consigneeCountryCode: params.arrival_countryCode,
    consigneeProvince: params?.arrival_store_province,
    consigneeCity: params?.arrival_store_city,
    consigneeDistrict: params?.arrival_store_district,
    consigneeStationSeq: params.arrival_store_station,
    consigneeStationType: params?.arrival_station_type,
    consigneeZipCode: undefined,
    ...sameInfo,
    ...station711Data,
  }
}

/** 转换包裹信息 */
export const formatParcelToService = (params: IParcelFormValues) => {
  const { parcel_check_currency, parcel_converted_currency_code, parcel_commodities, parcel_currency_code, parcel_show_keys = [] } = params
  return (
    parcel_commodities?.map((item) => {
      return {
        goodsName: item.parcel_goods_name,
        goodsCode: item.parcel_goods_code,
        goodsQuantity: item.parcel_goods_quantity ? Number(item.parcel_goods_quantity) : undefined,
        goodsPrice: times(Number(parcel_check_currency ? item.parcel_goods_converted_price : item.parcel_goods_price), 100),
        currencyCode: parcel_check_currency ? parcel_converted_currency_code : parcel_currency_code,
        goodsCname: item.parcel_goods_cname || '',
        goodsEname: item.parcel_goods_ename || '',
        goodsHscode: item.parcel_goods_hscode || '',
        goodsSize: item.parcel_goods_size || '',
        goodsUrl: item.parcel_goods_url || '',
        originCountryCode: parcel_show_keys?.includes('originCountryCode') ? item.originCountryCode : undefined,
        // 转换后加上原单价parcel_goods_price
        ...(parcel_check_currency && {
          originGoodsPrice: times(Number(item.parcel_goods_price), 100),
        }),
        goodsMaterial: item.parcel_goods_material || '',
        goodsPurpose: item.parcel_goods_purpose || '',
        goodsWeight: parcel_show_keys?.includes('parcel_goods_weight') && item.parcel_goods_weight ? Number(item.parcel_goods_weight) : undefined,
      }
    }) || []
  )
}

export const formatPremiumToService = (params: Pick<IFormatFee, 'provider_premium' | 'provider_insurance_type'>) => {
  const { provider_premium, provider_insurance_type } = params
  // 购买了保险，没有保费，传0给后端
  if (provider_insurance_type === INSURANCE_TYPE.YES && !provider_premium) {
    return 0
  }
  return provider_premium ? times(Number(provider_premium), 100) : undefined
}

type IFormatFee = IStore & IParcelFormValues & IProviderFormValues
/** 转换费用信息 */
export const formatFeeToService = (
  params: IProviderFormValues &
    Pick<IParcelFormValues, 'parcel_check_currency' | 'parcel_currency_code' | 'parcel_converted_currency_code'> &
    Partial<
      Pick<
        IStore,
        | 'companyCode'
        | 'deductedFreight'
        | 'freightCurrencyCode'
        | 'deductedFreightCurrencyCode'
        | 'freight'
        | 'discountFreight'
        | 'estimatedCost'
        | 'originalFreight'
        | 'feeInfo'
        | 'provider_cod_amount_target'
        | 'provider_cod_currency_target'
        | 'cod_currency_rate'
        | 'cod_fetchTime'
        | 'parcel_currency_rate'
        | 'parcel_rate_fetch_time'
        | 'codCurrencyConverted'
        | 'expressTypeCode'
        | 'feeItems'
      >
    >
) => {
  const { parcel_check_currency, parcel_converted_currency_code, parcel_currency_code, provider_payment_type, provider_custom_freight_currency_code, feeItems } = params

  const codAmountData = getCodAmountDatas(params)
  const { sellerCustomFreight } = codAmountData

  return {
    deductedFreightCurrencyCode: sellerCustomFreight && params?.deductedFreightCurrencyCode,
    deductedFreight: sellerCustomFreight && params?.deductedFreight,
    sellerCustomFreightCurrencyCode: sellerCustomFreight && provider_custom_freight_currency_code,

    freight: isUndefined(params.freight) ? params.estimatedCost : params.freight,
    discountFreight: isUndefined(params.discountFreight) ? params?.feeInfo?.discountFreight : params.discountFreight,
    originalFreight: isUndefined(params.originalFreight) ? params?.feeInfo?.originalFreight : params.originalFreight,
    payType: params.provider_pay_type,
    codAmount: codAmountData?.codAmount,
    sellerCodAmount: codAmountData?.sellerCodAmount,
    sellerCustomFreight: codAmountData?.sellerCustomFreight,
    codCurrencyCode: codAmountData?.codCurrencyCode,
    currencyCode: parcel_currency_code,
    currencyConverted: undefined,
    paymentType: Number(provider_payment_type),
    monthlyAccount: params.provider_monthly_account,
    freightCurrencyCode: params.freightCurrencyCode,
    insuranceType: params.provider_insurance_type, // 是否购买保险
    insuredAmount: params.provider_insured_amount ? times(Number(params.provider_insured_amount), 100) : undefined, // 包裹价值
    premium: formatPremiumToService(params), // 保费
    feeItems,
    reverseFreight: Number(params.provider_reverse_freight_setting) === 1 && params.provider_reverse_freight ? times(Number(params.provider_reverse_freight), 100) : null,
    reverseFreightCurrency: params.provider_reverse_freight_currency,
    /** 商品逻辑 勾选了自动转换： */
    ...(parcel_check_currency && {
      currencyCode: parcel_converted_currency_code,
      currencyConverted: 1,
      currencyRate: params.parcel_currency_rate,
      fetchTime: params.parcel_rate_fetch_time,
      originCurrencyCode: parcel_currency_code,
    }),
    ...codAmountData,
  }
}

type IFormatExtarInfo = IProviderFormValues & Pick<IRecipientAddressFormValues, 'arrival_store_storage_cdt' | 'arrival_method'>
/** 转换扩展信息 */
export const formatExtraInfo = (params: IFormatExtarInfo) => {
  return {
    /** 逆向物流原订单号 */
    reverseOrderNo: Array.isArray(params?.reverseOrderNo) ? params?.reverseOrderNo : undefined,
    storageCdt: params?.arrival_store_storage_cdt,
    pickupType: params.provider_pickup_type,
    customIdNo: params.provider_custom_id_no || '',
    deliveryType: params.arrival_method === 1 ? 1 : 4,
    packageDesc: params?.provider_package_desc?.trim(),
    taxes: params.provider_tax_type
      ? [
          {
            taxType: params.provider_tax_type,
            taxNo: params.provider_tax_no || '',
          },
        ]
      : undefined,
    requiredDeliveryDates: params.provider_delivery_date ? [params.provider_delivery_date] : [],
    requiredDeliveryTimeslots: params.provider_delivery_time ? [params.provider_delivery_time] : [],
    messageTraceNotifyStatus: params.provider_message_trace_notify_status,
  }
}

type IFormatOthers = IStore & IAllFields
/** 转换其他乱七八糟的东西 */
export const formatOthers = (params: IFormatOthers) => {
  return {
    expressTypeCode: params.expressTypeCode,
    weight: params?.parcel_weight || undefined,
    weightUnit: params.parcel_weight_unit || 'kg',
    length: params.parcel_length || null,
    width: params.parcel_width || null,
    height: params.parcel_height || null,
    lengthUnit: params.parcel_length_unit,
    requiredPickupDates: params.provider_pickup_type === SHIPMENT_PICKUP_TYPE.pickUp ? [params.provider_pickup_date] : [],
    requiredPickupTimeslots: params.provider_pickup_type === SHIPMENT_PICKUP_TYPE.pickUp ? [params.provider_pickup_time_slots] : [],
    timezone: params.timeZone,
    channelSellerId: params.provider_channel_seller_id,
    bindOneshipSalesOrder: params.bindOneshipSalesOrder,
    orderNo: params?.parcel_order_no,
    quantity: params?.parcel_quantity ? Number(params?.parcel_quantity) : 1,
    asyncSend: params.asyncSend,
  }
}

type IFormatChannelCustomInfo = IStore & IAllFields
/** 转换渠道特性字段 */
export const formatChannelCustomInfo = (params: IFormatChannelCustomInfo) => {
  const { provider_taxation, provider_taxationCurrencyCode, provider_pickup_station_code } = params
  const jne =
    provider_taxation || provider_taxationCurrencyCode
      ? {
          taxation: params.provider_taxation ? times(Number(params.provider_taxation), 100) : undefined,
          taxationCurrencyCode: provider_taxationCurrencyCode,
        }
      : undefined
  const jdwl = provider_pickup_station_code
    ? {
        pickUpStationCode: provider_pickup_station_code,
      }
    : undefined
  return {
    invoiceNo: params?.provider_invoice_no?.trim(),
    invoiceDate: params?.provider_invoice_date ? moment(params?.provider_invoice_date).format('YYYY-MM-DD') : undefined,
    invoicePath: params?.provider_invoice_path,
    serviceTypes: params?.provider_service_types,
    jne,
    hazmatTypeCode: params?.provider_hazmat_type_code,
    jdwl,
  }
}

/** oneship客户端转服务端数据 */
export const clientToService = (params: IClientToService) => {
  const { fieldsValue, ext } = params

  const address = {
    ...formatSenderAddressToService({ ...fieldsValue, ...ext }),
    ...formatRecipientAddressToService({ ...fieldsValue, ...ext }),
  }

  const commodities = formatParcelToService({ ...fieldsValue, ...ext })

  const feeInfo = formatFeeToService({ ...fieldsValue, ...ext })

  const extraInfo = formatExtraInfo({ ...fieldsValue })

  const others = formatOthers({ ...fieldsValue, ...ext })

  const channelCustomInfo = formatChannelCustomInfo({ ...fieldsValue, ...ext })

  return {
    address,
    commodities,
    feeInfo,
    extraInfo,
    ...others,
    channelCustomInfo: {
      ...channelCustomInfo,
    },
  }
}

/**
 * **********************************************************
 * 格式化成发货需要的数据
 * 以下函数如果入参不一样不要做任何 if else 处理, 请额外写一个
 * **********************************************************
 */

/** 格式化发货地址表单值 */
export const formatSenderAddressFormValues = (params: { addressInfo: IAddress }): ISenderAddressFormValues => {
  const { addressInfo } = params
  const { senderAddressLanguages } = addressInfo || {}

  const senderProvince = encodeLanguageAndProvince({
    languages: senderAddressLanguages,
    province: addressInfo?.senderProvince,
  })

  return {
    departure_countryCode: addressInfo?.senderCountryCode,
    departure_fullName: addressInfo?.senderName || undefined,
    departure_address: addressInfo?.senderAddress || undefined,
    departure_addressTwo: addressInfo?.senderAddressTwo || undefined,
    departure_email: addressInfo?.senderEmail || undefined,
    departure_mobile: addressInfo?.senderMobile || addressInfo?.senderPhone || undefined,
    departure_province: senderProvince || undefined,
    departure_city: addressInfo?.senderCity || undefined,
    departure_district: addressInfo?.senderDistrict || undefined,
    departure_town: addressInfo?.senderTown || undefined,
    departure_zipCode: addressInfo?.senderZipCode || undefined,
  }
}

/** 格式化地址簿表单值 */
export const formatAddressToAddressBookFormValuesByAutoSplit = (addressInfo: AutoSplitAddressType): AddressInfoType => {
  return {
    countryCode: addressInfo?.countryCode,
    name: addressInfo?.name,
    address: addressInfo?.address,
    addressTwo: '',
    email: addressInfo?.email,
    mobile: (addressInfo?.areaCode || '') + (addressInfo?.phoneNum || ''),
    province: addressInfo?.province,
    city: addressInfo?.city,
    district: addressInfo?.district,
    town: addressInfo?.town,
    zipCode: addressInfo?.zipCode,
  }
}

/** 格式化发货地址表单值 */
export const formatSenderAddressToFormValuesByAutoSplit = (addressInfo: AutoSplitAddressType): ISenderAddressFormValues => {
  return {
    departure_countryCode: addressInfo?.countryCode,
    departure_fullName: addressInfo?.name,
    departure_address: addressInfo?.address,
    departure_addressTwo: '',
    departure_email: addressInfo?.email,
    departure_mobile: (addressInfo?.areaCode || '') + (addressInfo?.phoneNum || ''),
    departure_province: addressInfo?.province,
    departure_city: addressInfo?.city,
    departure_district: addressInfo?.district,
    departure_town: addressInfo?.town,
    departure_zipCode: addressInfo?.zipCode,
  }
}

/** 格式化收货地址表单值 */
export const formatRecipientAddressToFormValuesByAutoSplit = (addressInfo: AutoSplitAddressType): IRecipientAddressFormValues => {
  return {
    arrival_countryCode: addressInfo?.countryCode,
    arrival_fullName: addressInfo?.name,
    arrival_address: addressInfo?.address,
    arrival_addressTwo: '',
    arrival_email: addressInfo?.email,
    arrival_mobile: (addressInfo?.areaCode || '') + (addressInfo?.phoneNum || ''),
    arrival_province: addressInfo?.province,
    arrival_city: addressInfo?.city,
    arrival_district: addressInfo?.district,
    arrival_town: addressInfo?.town,
    arrival_zipCode: addressInfo?.zipCode,
  }
}

/** 格式化收货地址表单值 */
export const formatRecipientAddressFormValues = (params: {
  addressInfo: IAddress & { companyCode?: string; expressTypeCode?: string }
  orderInfo: Pick<LogisticsOrderInfo, 'extraInfo' | 'expressTypeCode' | 'companyCode'>
}): IRecipientAddressFormValues => {
  // 在address里面已经注入了service的companyCode以及服务产品编码
  const { addressInfo, orderInfo } = params

  const { consigneeAddressLanguages } = addressInfo || {}

  const consigneeProvince = encodeLanguageAndProvince({
    languages: consigneeAddressLanguages,
    province: addressInfo?.consigneeProvince,
  })

  const { extraInfo } = orderInfo
  const isStoreCatService = isCatService(addressInfo?.companyCode) && addressInfo?.expressTypeCode?.includes('CAT_STORE')
  // TODO 这里需要兼容拉单过来，没有consigneeStationCode的情况，增加判断条件为店配订单
  const isMap = isMapService(addressInfo?.companyCode)
  // 非宅配不进来
  if (addressInfo?.consigneeStationCode || isStoreCatService) {
    // 兼容同步过来的订单，黑猫无法匹配自己数据库的门店
    if (isMap || !addressInfo?.consigneeStationCode) {
      return {
        arrival_store_companyCode: addressInfo?.companyCode,
        arrival_countryCode: addressInfo?.consigneeCountryCode || undefined,
        arrival_method: 2,
        arrival_mobile: addressInfo?.consigneeMobile || addressInfo?.consigneePhone || undefined,
        arrival_fullName: addressInfo?.consigneeName || undefined,
        arrival_email: addressInfo?.consigneeEmail || undefined,
        // 711跨境需要回填地址信息
        ...(is711CrossBorder(addressInfo?.companyCode) && {
          arrival_province: consigneeProvince || undefined,
          arrival_city: addressInfo?.consigneeCity || undefined,
          arrival_district: addressInfo?.consigneeDistrict || undefined,
          arrival_town: addressInfo?.consigneeTown || undefined,
          arrival_address: addressInfo?.consigneeAddress || undefined,
          arrival_addressTwo: addressInfo?.consigneeAddressTwo || undefined,
          arrival_zipCode: addressInfo?.consigneeZipCode || undefined,
        }),
        ...(() => {
          if (isMap) {
            return {
              /** 温层 */
              arrival_store_storage_cdt: extraInfo?.storageCdt,
              /** 电子地图数据初始化 */
              arrival_store_711station: {
                storeId: addressInfo?.consigneeStationCode,
                name: addressInfo?.consigneeStationName ?? '',
                /** 711跨境电子地图门店初始化不需要展示地址 */
                address: is711CrossBorder(addressInfo?.companyCode) ? '' : addressInfo?.consigneeAddress ?? '',
                province: addressInfo.consigneeProvince ?? '',
                city: addressInfo?.consigneeCity ?? '',
                district: addressInfo?.consigneeDistrict ?? '',
              },
            }
          }

          return {}
        })(),
      }
    }

    return {
      arrival_countryCode: addressInfo?.consigneeCountryCode || undefined,
      arrival_method: 2,
      arrival_store_companyCode: addressInfo?.companyCode,
      // 后端问题，遗留问题，后端改不动类型，原本就是 number
      arrival_station_type: addressInfo?.consigneeStationType || addressInfo?.consigneeStationType === 0 ? Number(addressInfo?.consigneeStationType) : undefined,
      arrival_store_station: addressInfo?.consigneeStationSeq,
      arrival_store_province: addressInfo?.consigneeProvince || undefined,
      arrival_store_city: addressInfo?.consigneeCity || undefined,
      arrival_store_district: addressInfo?.consigneeDistrict || undefined,
      arrival_zipCode: addressInfo?.consigneeZipCode || undefined,
      // TODO: 这里需要 mobile 和 phone 统一处理成一个字段，mobile 没有的情况下，取出数据库中的 phone 字段
      arrival_mobile: addressInfo?.consigneeMobile || addressInfo?.consigneePhone || undefined,
      arrival_fullName: addressInfo?.consigneeName || undefined,
      arrival_email: addressInfo?.consigneeEmail || undefined,
    }
  }

  return {
    arrival_countryCode: addressInfo?.consigneeCountryCode || undefined,
    arrival_method: 1,
    arrival_fullName: addressInfo?.consigneeName || undefined,
    arrival_province: consigneeProvince || undefined,
    arrival_city: addressInfo?.consigneeCity || undefined,
    arrival_district: addressInfo?.consigneeDistrict || undefined,
    arrival_town: addressInfo?.consigneeTown || undefined,
    arrival_address: addressInfo?.consigneeAddress || undefined,
    arrival_addressTwo: addressInfo?.consigneeAddressTwo || undefined,
    arrival_email: addressInfo?.consigneeEmail || undefined,
    arrival_mobile: addressInfo?.consigneeMobile || addressInfo?.consigneePhone || undefined,
    arrival_zipCode: addressInfo?.consigneeZipCode || undefined,
  }
}

/** 格式化包裹表单值 */
export const formatParcelFormValues = (params: {
  orderInfo: Pick<
    LogisticsOrderInfo,
    'extraInfo' | 'length' | 'lengthUnit' | 'width' | 'height' | 'weight' | 'weightUnit' | 'originOrderNo' | 'orderNo' | 'feeInfo' | 'commodities' | 'address' | 'quantity'
  >
  type?: 'order' | 'logistics'
}): IParcelFormValues => {
  const { orderInfo, type = 'order' } = params
  if (!orderInfo) {
    return undefined
  }

  const { extraInfo, length, lengthUnit, width, height, weight, weightUnit, originOrderNo, orderNo, feeInfo, commodities, address, quantity } = orderInfo
  const { reverseOrderNo } = extraInfo || {}
  const isConverted = feeInfo?.currencyConverted === CurrencyConverted.CONVERTED && type !== 'logistics'
  const commoditiesFormData = Array.isArray(commodities)
    ? commodities.map((item) => {
        const {
          goodsName,
          goodsCode,
          goodsQuantity,
          goodsPrice,
          goodsCname,
          goodsEname,
          goodsHscode,
          goodsMaterial,
          goodsPurpose,
          goodsSize,
          originGoodsPrice,
          goodsWeight,
          originCountryCode,
          goodsUrl,
        } = item

        return {
          parcel_goods_name: goodsName,
          parcel_goods_code: goodsCode,
          parcel_goods_quantity: goodsQuantity,
          // 如果有原价格（即转换过的）就回填原价格, 否则currency googPrice
          parcel_goods_price: divide(Number(isConverted ? originGoodsPrice : goodsPrice), 100),
          parcel_goods_cname: goodsCname || undefined,
          parcel_goods_ename: goodsEname || undefined,
          parcel_goods_hscode: goodsHscode || undefined,
          parcel_goods_size: goodsSize || undefined,
          originCountryCode: originCountryCode || undefined,
          ...(isConverted && {
            parcel_goods_converted_price: divide(Number(goodsPrice), 100),
          }),
          parcel_goods_material: goodsMaterial || undefined,
          parcel_goods_purpose: goodsPurpose || undefined,
          parcel_goods_weight: goodsWeight || undefined,
          parcel_goods_url: goodsUrl || undefined,
        }
      })
    : undefined

  return {
    parcel_length: length ? length + '' : null,
    parcel_width: width ? width + '' : null,
    parcel_height: height ? height + '' : null,
    parcel_length_unit: lengthUnit || 'cm',
    parcel_weight: !!weight ? weight : '',
    parcel_weight_unit: weightUnit || 'kg',
    /**
     * 订单发货取originOrderNo，运单编辑取orderNo
     */
    parcel_order_no: originOrderNo || orderNo,
    parcel_sender_remark: address?.senderRemark,
    parcel_currency_code: feeInfo?.currencyCode,
    parcel_commodities: commoditiesFormData,
    parcel_quantity: quantity,
    // 初始化已转换
    ...(isConverted
      ? {
          parcel_currency_rate: feeInfo?.currencyRate,
          parcel_rate_fetch_time: feeInfo?.fetchTime,
          parcel_converted_currency_code: feeInfo.currencyCode,
          parcel_currency_code: feeInfo?.originCurrencyCode,
        }
      : {}),
    // 退货单原订单号
    ...(reverseOrderNo ? { reverseOrderNo } : {}),
  }
}

/** 格式化服务产品表单值 */
export const formatServiceProviderFormValues = (params: {
  addressInfo: IAddress
  orderInfo: Partial<
    Pick<LogisticsOrderInfo, 'companyCode' | 'expressTypeCode' | 'feeInfo' | 'extraInfo' | 'channelSellerId' | 'requiredPickupDates' | 'requiredPickupTimeslots'>
  > & { channelCustomInfo?: ChannelCustomInfo }
  type?: 'order' | 'logistics'
}): IProviderFormValues => {
  const { addressInfo, orderInfo, type = 'order' } = params
  const { feeInfo, extraInfo, channelSellerId, requiredPickupDates, requiredPickupTimeslots, companyCode, channelCustomInfo } = orderInfo || {}
  const {
    monthlyAccount,
    codAmount,
    currencyConverted,
    originCurrencyCode,
    currencyCode,
    codCurrencyCode,
    codCurrencyConverted,
    originSellerCodAmount,
    originSellerCodCurrency,
    sellerCodAmount,
    reverseFreight,
    reverseFreightCurrency,
  } = feeInfo || {}
  const { taxes, customIdNo, pickupType, packageDesc } = extraInfo || {}
  const [tax] = taxes || []
  const [requiredPickupDate] = requiredPickupDates || []
  const [requiredPickupTimeslot] = requiredPickupTimeslots || []
  const [requiredDeliveryDate] = extraInfo?.requiredDeliveryDates || []
  const [requiredDeliveryTimeslot] = extraInfo?.requiredDeliveryTimeslots || []
  const { hazmatTypeCode } = channelCustomInfo || {}

  // 订单中cod币种可能没有值，需要用订单币种兜底
  const isCurrencyConverted = currencyConverted === CurrencyConverted.CONVERTED
  const curCurrencyCode = isCurrencyConverted ? originCurrencyCode || currencyCode : currencyCode

  let curShipmentCodFee = sellerCodAmount
  let curShipmentCodCurrencyCode = feeInfo.codCurrencyCode || curCurrencyCode

  /** 运单永远取这个值, 不支持币种转换 */
  if (type === 'logistics') {
    curShipmentCodFee = codAmount
  } else {
    // 如果币种已转换，cod金额要取转换前的COD金额-originCodAmount（如果没有，则取最终cod金额-codAmount）
    const isCodCurrencyConverted = codCurrencyConverted === CurrencyConverted.CONVERTED
    curShipmentCodFee = isCodCurrencyConverted ? originSellerCodAmount || sellerCodAmount : sellerCodAmount
    curShipmentCodCurrencyCode = (isCodCurrencyConverted ? originSellerCodCurrency || codCurrencyCode : codCurrencyCode) || curCurrencyCode
  }

  return {
    provider_custom_id_no: customIdNo || '',
    provider_cod_amount: !isNaN(Number(curShipmentCodFee)) && typeof Number(curShipmentCodFee) === 'number' ? divide(Number(curShipmentCodFee), 100) : '',
    provider_cod_currency_code: curShipmentCodCurrencyCode,
    provider_channel_seller_id: channelSellerId || '',
    provider_consignee_name: addressInfo?.consigneeName || '',
    provider_consignee_id_type: addressInfo?.consigneeIdType || undefined,
    provider_consignee_id_num: addressInfo?.consigneeIdNo || '',
    provider_payment_type: feeInfo?.paymentType || 2,
    provider_pickup_date: requiredPickupDate,
    provider_pickup_time_slots: requiredPickupTimeslot,
    provider_pickup_type: pickupType,
    provider_pickup_station_code: channelCustomInfo?.jdwl?.pickUpStationCode,
    provider_pay_type: Number(feeInfo?.payType),
    provider_monthly_account: monthlyAccount,
    provider_insurance_type: companyCode === 'GHTK' ? INSURANCE_TYPE.YES : feeInfo?.insuranceType || INSURANCE_TYPE.NO, // 是否购买保险, 默认否
    provider_insured_amount: feeInfo?.insuredAmount ? divide(Number(feeInfo.insuredAmount), 100) : undefined, // 包裹价值
    provider_premium: typeof feeInfo?.premium === 'number' ? divide(Number(feeInfo.premium), 100) : undefined, // 保费
    provider_tax_type: tax?.taxType || undefined,
    provider_tax_no: tax?.taxNo || undefined,
    provider_delivery_date: requiredDeliveryDate,
    provider_delivery_time: requiredDeliveryTimeslot,
    provider_package_desc: packageDesc,
    provider_hazmat_type_code: hazmatTypeCode,
    provider_invoice_no: channelCustomInfo?.invoiceNo,
    provider_invoice_date: channelCustomInfo?.invoiceDate,
    provider_invoice_path: channelCustomInfo?.invoicePath,
    provider_message_trace_notify_status: extraInfo.messageTraceNotifyStatus,
    provider_taxationCurrencyCode: channelCustomInfo?.jne?.taxationCurrencyCode,
    provider_taxation: !isNaN(Number(channelCustomInfo?.jne?.taxation)) ? divide(Number(channelCustomInfo?.jne?.taxation), 100) : undefined,
    /** logisitcs don't supported the custom freight function */
    ...(type !== 'logistics'
      ? {
          provider_custom_freight: !isNaN(Number(feeInfo.sellerCustomFreight)) ? divide(Number(feeInfo.sellerCustomFreight), 100) : undefined,

          /** always same with the cod currencyCode, if no freight, no need to initial */
          provider_custom_freight_currency_code: feeInfo.sellerCustomFreight ? feeInfo.sellerCustomFreightCurrencyCode : undefined,
        }
      : {}),
    provider_service_types: channelCustomInfo?.serviceTypes,
    provider_reverse_freight: reverseFreight ? divide(Number(reverseFreight), 100) : null,
    provider_reverse_freight_currency: reverseFreightCurrency,
    provider_reverse_freight_setting: reverseFreight ? 1 : 0,
  }
}

/** 格式化服务产品信息 */
export const formatServiceProviderData = (params: {
  provider: ExpressListData
  orderInfo: Pick<LogisticsOrderInfo, 'expressTypeCode' | 'companyCode' | 'feeInfo'>
}): Partial<ExpressListData> => {
  const { provider, orderInfo } = params
  const { feeInfo } = orderInfo
  return {
    ...provider,
    insuranceSupport: provider?.insuranceSupport,
    insuranceSetting: provider?.insuranceSetting,
    needSubAccount: provider?.needSubAccount,
    expressTypeCode: provider?.expressTypeCode || '',
    expressTypeName: provider?.expressTypeName || '',
    channelExpressType: provider?.channelExpressType || '',
    channelExpressTypeName: provider?.channelExpressTypeName || '',
    companyName: provider?.companyName || '',
    companyCode: provider?.companyCode || '',
    promisedTime: provider?.promisedTime || '',
    settlementModes: provider?.settlementModes || [],
    supportedCheckoutTypes: provider?.supportedCheckoutTypes || [],
    supportedPickupTypes: provider?.supportedPickupTypes || [],
    estimatedCost: feeInfo?.freight,
    freightCurrencyCode: provider?.freightCurrencyCode,
    channelRelationType: provider?.channelRelationType,
    selectableDeliveryList: provider?.selectableDeliveryList,
    saasFlag: provider?.saasFlag,
  }
}

/** get custom freight erorr */
export const getFreightErrorCode = (validateCodes: LogisticsOrderInfo['validateCodes']) => {
  return validateCodes?.filter((item) => item.bizType === 'sellerCodFreight')?.[0]?.bizCode as `${CUSTOM_FREIGHT_REASON_ENUM}`
}

/** 格式化成请求 provider 列表的参数 */
export const formatServiceProviderProps = (params: {
  formValues: Types.IShipFormValues
  addressInfo: IAddress
  orderInfo: Pick<
    LogisticsOrderInfo,
    | 'feeInfo'
    | 'extraInfo'
    | 'companyCode'
    | 'expressTypeMatchType'
    | 'address'
    | 'isECommerce'
    | 'salesOrderAmount'
    | 'salesOrderFreight'
    | 'validateCodes'
    | 'salesOrderCurrencyCode'
    | 'appId'
    | 'expressLogisticsType'
  >
  isMergeShip?: boolean
  type?: 'order' | 'logistics'
}): ISetProviderProps => {
  const { formValues, addressInfo, orderInfo, isMergeShip, type = 'order' } = params
  const { feeInfo, extraInfo, expressTypeMatchType, address, isECommerce, salesOrderFreight, salesOrderAmount, validateCodes, salesOrderCurrencyCode, expressLogisticsType } =
    orderInfo
  const isConverted = feeInfo?.currencyConverted === CurrencyConverted.CONVERTED && type !== 'logistics'
  const currencyCode = !isConverted ? feeInfo.currencyCode : feeInfo?.originCurrencyCode || feeInfo.currencyCode

  const realFormValues = {
    ...formValues,
    arrival_station_code: addressInfo.consigneeStationCode || address.consigneeStationCode,
    // 优先使用匹配出来的 station type --> 兼容同一个 station code 会匹配出来多个店铺情况
    arrival_station_type: addressInfo.consigneeStationType || address.consigneeStationType,
  }

  const customFreightErrorCode = getFreightErrorCode(validateCodes)
  const codCurrencyConverted = feeInfo?.currencyConverted === CurrencyConverted.CONVERTED
  // cod金额初始化取值逻辑
  const handleFinalCodAmount = () => {
    if (type === 'logistics') {
      // 运单编辑cod金额直接取codAmount
      return feeInfo?.codAmount
    }
    return !codCurrencyConverted ? feeInfo?.sellerCodAmount || feeInfo?.codAmount : feeInfo?.originSellerCodAmount || feeInfo?.sellerCodAmount || feeInfo?.codAmount
  }
  // cod币种初始化取值逻辑
  const handleFinalCodCurrencyCode = () => {
    if (type === 'logistics') {
      // 运单编辑cod金额直接取codCurrencyCode
      return feeInfo?.codCurrencyCode
    }
    // 如果没有cod币种，则取currencyCode
    return (!codCurrencyConverted ? feeInfo?.codCurrencyCode : feeInfo?.originSellerCodCurrency) || currencyCode
  }

  const codAmount = handleFinalCodAmount()
  const codCurrencyCode = handleFinalCodCurrencyCode()

  return {
    appId: orderInfo.appId,
    addressVO: realFormValues,
    arrival_method: formValues.arrival_method,
    supportStorageCdts: extraInfo.storageCdt ? [extraInfo.storageCdt] : undefined,
    companyCode: formValues.arrival_method === ARRIVAL_METHOD.store ? formValues.arrival_store_companyCode : undefined,
    // 显示 cod 原始币种, 币种转换后取 originCurrencyCode，未转换 currencyCode
    currencyCode,
    stationSeq: addressInfo?.consigneeStationSeq || undefined,
    parcel_weight: formValues.parcel_weight,
    parcel_weight_unit: formValues.parcel_weight_unit,
    parcel_height: formValues.parcel_height,
    parcel_length: formValues.parcel_length,
    parcel_width: formValues.parcel_width,
    orderInfo: {
      paymentType: feeInfo.paymentType,
      codAmount,
      orderAmount: !isConverted ? feeInfo?.orderAmount : feeInfo?.originOrderAmount || feeInfo?.orderAmount,
      orderAmountCurrencyCode: !isConverted ? feeInfo.currencyCode : feeInfo?.originCurrencyCode || feeInfo?.currencyCode,
      isECommerce,
      ...(() => {
        if (!isECommerce) return {}
        // 记录原始订单的运费与 cod amount
        return {
          salesOrderFreight,
          /** 订单 cod amount */
          salesOrderAmount,
          salesOrderCurrencyCode,
        }
      })(),
    },
    needStationType: true,
    type: isMergeShip ? 'merge' : 'normal',
    isAutoMatch: expressTypeMatchType === 'autoMatch',
    apiCodCurrencyCode: codCurrencyCode,
    apiCodAmount: codAmount,
    previousShipmentServiceType: feeInfo.paymentType,
    // cod币种，初始值先取订单中的cod币种，如果没有，则取订单币种
    codCurrencyCode: codCurrencyCode,
    osFreightErrorCode: customFreightErrorCode,
    previousCodFee: !isNaN(Number(codAmount)) ? divide(Number(codAmount), 100) : undefined,

    // 扣减
    ...(() => {
      if (!isECommerce || !salesOrderFreight) return {}

      return {
        deductedFreight: feeInfo?.deductedFreight || salesOrderFreight,
        deductedFreightCurrencyCode: feeInfo?.deductedFreightCurrencyCode || feeInfo.currencyCode,
      }
    })(),
    expressLogisticsType,
  }
}

/** 格式化成请求 provider formValue */
export const formatProviderFormValues = (params: {
  provider?: Partial<Pick<ExpressListData, 'supportedCheckoutTypes' | 'insuranceSupport' | 'companyCode'>>
  formValues: Types.IShipFormValues
}) => {
  const { provider, formValues } = params
  if (!provider) {
    return formValues
  }

  if (provider?.supportedCheckoutTypes?.findIndex((value) => value === formValues.provider_payment_type) === -1) {
    delete formValues.provider_payment_type
  }

  // 如果服务产品中的服务商为 GHTK 并且支持保险则默认选中
  if (provider.insuranceSupport === 1 && provider.companyCode === 'GHTK') {
    formValues.provider_insurance_type = 1
  }

  return formValues
}

/** 格式化智能识别地址到form表单 */
export const formatImmediateAddressByAutoSplit = (address: AutoSplitAddressType) => {
  return {
    area: {
      province: address.province,
      city: address.city,
      district: address.district,
      town: address.town,
    },
    address: address.address,
    fullName: address.name,
    mobile: (address.areaCode || '') + (address.phoneNum || ''),
    addressLanguage: '',
    addressItem: address,
  }
}
/**
 * 格式化区号，去除+号和国家编码，转换成00形式
 * @param areaCode
 * @returns
 */
export const formatAreaCode = (areaCode: string) => {
  if (areaCode.includes('_')) return areaCode.split('_')[1]
  if (areaCode.includes('+')) return areaCode.replace('+', '00')
}

type AmountParams = Partial<
  Pick<IStore, 'provider_cod_amount_target' | 'provider_cod_currency_target' | 'codCurrencyConverted' | 'cod_fetchTime' | 'cod_currency_rate'> &
    Pick<IProviderFormValues, 'provider_payment_type' | 'provider_cod_currency_code' | 'provider_custom_freight_currency_code' | 'provider_cod_amount' | 'provider_custom_freight'>
>

export const getCodAmountDatas = (params: AmountParams) => {
  const {
    provider_payment_type,
    provider_cod_currency_code,
    codCurrencyConverted,
    provider_cod_amount_target,
    provider_cod_currency_target,
    provider_custom_freight_currency_code,
    provider_cod_amount,
    provider_custom_freight,
    cod_fetchTime,
    cod_currency_rate,
  } = params

  const usedCod = Number(provider_payment_type) === SETVICE_TYPE.cod

  // 指定COD币种的服务产品，COD币种为特定币种，COD不需要币种转换
  const finalCodCurrencyCode = usedCod ? provider_cod_currency_code : null

  /**
   * sellerCodAmount: it's no include the customFreight
   * codAmount: it's include the customFreight and the codAmount from user's input
   */
  let sellerCodAmount = usedCod ? (provider_cod_amount ? times(Number(provider_cod_amount), 100) : undefined) : undefined
  let codAmount = sellerCodAmount

  let sellerCustomFreight = undefined

  // if custom currency code same with the
  if (usedCod && provider_custom_freight_currency_code === finalCodCurrencyCode && provider_custom_freight) {
    sellerCustomFreight = times(Number(provider_custom_freight), 100)
    codAmount = plus(sellerCustomFreight, codAmount)
  }

  if (!codCurrencyConverted || !usedCod) {
    return {
      codAmount,
      sellerCodAmount,
      sellerCustomFreight,
      codCurrencyCode: finalCodCurrencyCode,
    }
  }

  /**
   * sellerCodAmount: it's no including the customFreight
   * codAmount: it includes the customFreight and the codAmount from user's input
   */
  sellerCodAmount = provider_cod_amount_target ? times(Number(provider_cod_amount_target), 100) : 0
  codAmount = sellerCodAmount
  sellerCustomFreight = undefined

  // if custom currency code same with the
  if (provider_custom_freight_currency_code === provider_cod_currency_target && provider_custom_freight) {
    sellerCustomFreight = times(Number(provider_custom_freight), 100)
    codAmount = plus(sellerCustomFreight, codAmount)
  }

  return {
    /** 转后后值 */
    codAmount,
    codCurrencyCode: provider_cod_currency_target,
    sellerCodAmount,
    sellerCustomFreight,

    /** 原币种值 */
    originSellerCodAmount: provider_cod_amount ? times(Number(provider_cod_amount), 100) : undefined,
    originSellerCodCurrency: provider_cod_currency_code,

    /**
     * 币种转行信息
     */
    codCurrencyRate: cod_currency_rate,
    codFetchTime: cod_fetchTime,
    codCurrencyConverted: 1,
  }
}
