import React, { useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import { usePermissionControl } from '@/bizComponent/PermissionControl'
import type { MenuDataItem } from '@/utils'

const PermissionRedirect = () => <Redirect to="/authority" />

/** 权限拦截器 */
export function usePermission() {
  const hasPermission = usePermissionControl()
  return useCallback(
    (route: MenuDataItem) => {
      const { permissionCode } = route
      if (!permissionCode) return route
      return hasPermission(permissionCode)
        ? route
        : {
            path: route.path,
            component: PermissionRedirect,
          }
    },
    [hasPermission]
  )
}
