export default {
  /** 获取用户地址 */
  getAddress: '/api/user/address/get',
  /** 地址列表,根据国家和发件或收件查询 */
  getAddressList: '/api/user/address/list',
  /** 获取省市区的地址 */
  getAreaAddress: '/api/address/list',
  /** 新增物流地址 */
  addAddress: '/api/user/address/add',
  /** 删除地址 */
  deleteAdress: '/api/user/address/delete',
  /** 设置默认地址 */
  setDefaultAdress: '/api/user/address/default',
  /** 更新地址， */
  updateAddress: '/api/user/address/update',
  /** 批量查询地址 */
  getMultiAddress: '/api/user/address/get_multi',
}
