import type { DefaultOptionType, FilterFunc } from 'rc-select/es/Select'

/** 过滤选项 */
export const filterOption: FilterFunc<DefaultOptionType> = (input, option) => {
  const context = option.title.toString().toLowerCase()
  return context.indexOf(input.toLowerCase()) !== -1
}

/** 从首字母开始过滤选项 */
export const filterOptionStartsWith: FilterFunc<DefaultOptionType> = (input, option) => {
  const lowerInput = input.toLowerCase()

  const context = (option?.name || option?.title || option?.children).toString()?.toLowerCase()
  if (!lowerInput || !context) return true

  return context.startsWith(lowerInput)
}

/** 过滤国家选项 */
export const filterCountryOption: FilterFunc<DefaultOptionType> = (input, option) => {
  const lowerInput = input.toLowerCase()
  const code = option.key.toString().toLowerCase()

  return filterOptionStartsWith(input, option) || lowerInput === code
}

/** includes 过滤 */
export const filterOptionIncludes: FilterFunc<DefaultOptionType> = (input, option) => {
  const lowerInput = input?.toLowerCase()?.trim()

  // 语言的 option 不用来过滤
  if (typeof option?.language === 'boolean') return false

  const context = (option?.name || option?.title || option?.children).toString()?.toLowerCase()
  if (!lowerInput || !context) return true

  return context?.includes(lowerInput)
}
