/**
 * 地址 后端字段转前端字段
 */

// 发货地址
export const DEPARTURE_AMP = {
  senderCountryCode: 'departure_countryCode',
  senderProvince: 'departure_province',
  senderCity: 'departure_city',
  senderDistrict: 'departure_district',
  senderTown: 'departure_town',
  senderName: 'departure_fullName',
  senderMobile: 'departure_mobile',
  senderAddress: 'departure_address',
  senderAddressTwo: 'departure_addressTwo',
  senderZipCode: 'departure_zipCode',
  senderEmail: 'departure_email',
}

// 收发地址
export const ADDRESSMAP = {
  ...DEPARTURE_AMP,
  // 收货地址字段
  consigneeCountryCode: 'arrival_countryCode',
  consigneeProvince: 'arrival_province',
  consigneeCity: 'arrival_city',
  consigneeDistrict: 'arrival_district',
  consigneeTown: 'arrival_town',
  consigneeZipCode: 'arrival_zipCode',
  consigneeAddress: 'arrival_address',
  consigneeAddressTwo: 'arrival_addressTwo',
  consigneeName: 'arrival_fullName',
  consigneeMobile: 'arrival_mobile',
  consigneeEmail: 'arrival_email',
}
// 店配 收发地址
export const STORE_ADDRESSMAP = {
  ...DEPARTURE_AMP,
  // 收货地址字段
  consigneeCountryCode: 'arrival_countryCode',
  consigneeProvince: 'arrival_store_province',
  consigneeCity: 'arrival_store_city',
  consigneeDistrict: 'arrival_store_district',
  consigneeZipCode: 'arrival_store_zipCode',
  consigneeName: 'arrival_store_fullName',
  consigneeMobile: 'arrival_store_mobile',
  consigneeEmail: 'arrival_store_email',
}

export const I18N_PREFIX = 'system.error.'

export const VERVIFY_FIELDS = ['name', 'mobile', 'countryCode', 'province', 'city', 'district', 'town', 'address', 'addressTwo', 'zipCode', 'email', 'addressType']
