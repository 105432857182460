import { sleep } from '@/utils'
import { BASE_TIME, MAX_TTI, TIMEOUT } from './constant'

interface IPerformanceEntry extends PerformanceEntry {
  [index: string]: any
}

/**
 * 资源请求加载时长
 */
export const getLoadTime = ({ startTime, responseEnd }: IPerformanceEntry) => Math.round(responseEnd - startTime)

/**
 * FCP，全称 First Contentful Paint，它代表浏览器第一次向屏幕绘内容。
 * @returns {number | null}
 */
export function getFCPTime() {
  const timings = performance.getEntriesByType('paint') || []
  const timeObj = timings.length > 1 ? timings[1] : timings[0]
  return timeObj ? Math.round(timeObj.startTime) : null
}

/**
 * TTI，全称 Time To Interactive，可交互时间，指的是应用在视觉上都已渲染出了，完全可以响应用户的输入了
 * @returns {number}
 */
export async function getTTITime() {
  try {
    const startTime = Date.now()
    let now = startTime
    while (now - startTime <= MAX_TTI) {
      const arr = performance.getEntriesByName('tti')
      if (arr.length) return Math.round(arr[0].startTime)
      await sleep(BASE_TIME)
      now = Date.now()
    }
    return Math.round(performance.mark('tti').startTime)
  } catch (error) {}
}

/**
 * 格式化请求超时资源
 * @returns {string}
 */
export function formatTimeout(resources: PerformanceEntryList) {
  const timeouts = []
  resources.forEach((item) => {
    const loadTime = getLoadTime(item)
    if (loadTime > TIMEOUT) {
      timeouts.push({
        url: item.name,
        loadTime,
      })
    }
  })
  try {
    return JSON.stringify(timeouts)
  } catch {
    return ''
  }
}

interface IOptions {
  /* tti是否超出最大值，是的话上传所有请求 */
  ttiOverTime: boolean
  /* 监听到的所有请求 */
  resources: PerformanceEntryList
}

/**
 * 统一处理附加信息
 * @returns {number | null}
 */
export function formatMoreInfo(options: IOptions) {
  const { ttiOverTime, resources } = options

  const finalObject = {
    resources: ttiOverTime ? resources : void 0,
  }

  try {
    return JSON.stringify(finalObject)
  } catch {
    return ''
  }
}
