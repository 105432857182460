import React from 'react'
import loadable from '@loadable/component'
import { LinearProgress } from '@/components/LinearProgress'
import { Fallback } from './components/Fallback'

/**
 * 此处不返回任何值, 因为无需列举 Loadable
 * 所有的返回值
 */

function withAsync(component: () => Promise<React.FC | { default: React.FC }>) {
  const fn = () =>
    component().catch((error) => () => {
      Promise.reject(error)
      return <Fallback />
    })

  return loadable(fn, { fallback: <LinearProgress /> })
}

export default withAsync
