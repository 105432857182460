/** 是否是🐈‍⬛黑猫服务商 */
export const isCatService = (code: string) => code === 'TCAT'

/** 是否711跨境 */
export const is711CrossBorder = (code: string) => code === '711'

export const isFMTB2C = (code: string) => code === 'FMTB2C'

export const is711C2C = (code: string) => code === 'SEVEN_ELEVEN_C2C'

export const is711B2C = (code: string) => code === 'SEVEN_ELEVEN_B2C'

/** 服务产品对应的mayType */
export const mapService = {
  /** 黑猫 */
  TCAT: 'TCAT_1',
  /** 全家b2c */
  FMTB2C: 'FMT_1',
  /** 711跨境 */
  '711': 'SE_3',
  /** 711-B2C  PC版 */
  SEVEN_ELEVEN_B2C: 'SE_1',
  /** 711-C2C  PC版 */
  SEVEN_ELEVEN_C2C: 'SEC2C_1',
}

export const isMapService = (code: string, exceptKeys?: string[]) => {
  return Object.keys(mapService)
    .filter((key) => !exceptKeys?.includes(key))
    .includes(code)
}
