import { decodeLanguageAndProvince } from '../../utils/address'
import { SHIP_TYPE_ENUM } from '@/store/enum'
import type { ICoverAddressTypes, IExpressProps, IProviderAddressVO, IServiceProviderVO, ProviderInfoItem } from './types'
import { SHIPMENT_PICKUP_TYPE } from '@/bizComponent/ServiceProvider/enum'
import type { IAddressItem, IExpressData } from '@/bizComponent/ServiceProvider/type'
import { DEFAULT_PAYMENT_OPTIONS, DELIVERY_SETTLE, INSURANCE_SUPPORT_TYPE, INSURANCE_TYPE, PAY_TYPE, PaymentTypeEnum, SAAS_FLAG } from '@/constant'
import { getExpressList, getFeeList, getSubAccount, queryExpressConfig } from '@/services/apis'
import { fetchRateList } from '@/services/apis/common'
import type { ExpressListPayload, ExpressTypeConfigListRequest } from '@/services/apis/express/type'
import { DELIVER_VALUE_MAP } from '@/services/apis/sales/type'
import type { ParcelFormFields } from '@/store/parcelSlice/types'
import type { ExpressListData, FeeObject, FeePayloadMetadatasItem, PriceSettingInfo } from '@/store/serviceProviderSlice/types'
import { isZEEKFn } from '@/utils'
import { omit } from 'lodash'
import { divide, times } from 'number-precision'
import { LogisticsType } from '../logisticsSlice/type'
import { isPlatformLogistics } from '@/utils/format/utils'

export const getPickupType = (options: { supportedPickupTypes: SHIPMENT_PICKUP_TYPE[]; provider_pickup_type?: number }) => {
  const { supportedPickupTypes, provider_pickup_type } = options
  // the priority is the pickup
  const defaultPickupType = supportedPickupTypes?.includes(SHIPMENT_PICKUP_TYPE.pickUp) ? SHIPMENT_PICKUP_TYPE.pickUp : supportedPickupTypes?.[0]
  const pickupType = supportedPickupTypes?.includes(provider_pickup_type as SHIPMENT_PICKUP_TYPE) ? provider_pickup_type : defaultPickupType

  return pickupType
}

export const logger = (msg: string, type: 'warn' | 'log' | 'error' | 'info' = 'warn') => {
  // eslint-disable-next-line no-console
  console[type]('[service provider]: ', msg)
}

/**
 * 获取 cod 选项
 * https://shopline.yuque.com/mlu41d/gy9ldi/ezg64m#Xd86c 详细介绍
 * @param data
 * @returns
 */
export const getShipmentServiceType = (
  data: {
    supportedCheckoutTypes: number[]
    filterArr?: string[]
    previousShipmentServiceType?: number
    initialShipmentServiceType?: number
  } & Pick<IServiceProviderVO, 'type'>
) => {
  const { supportedCheckoutTypes, filterArr, previousShipmentServiceType } = data

  // 有筛选项，选择了货到付款
  if (filterArr?.length && filterArr.includes(String(PaymentTypeEnum.cod))) {
    return PaymentTypeEnum.cod
  }

  // 如果只支持一项
  if (supportedCheckoutTypes.length === 1) {
    return supportedCheckoutTypes[0]
  } else if (!previousShipmentServiceType) {
    return PaymentTypeEnum.notCod
    // 有上一次的选项为 cod，且产品支持 cod
  } else if (previousShipmentServiceType === PaymentTypeEnum.cod && supportedCheckoutTypes.includes(PaymentTypeEnum.cod)) {
    return PaymentTypeEnum.cod
  }

  return PaymentTypeEnum.notCod
}

/**
 * 处理 cod 选项
 * @param data
 * @returns
 */
export const getCodFee = (
  data: {
    provider_payment_type: number
    /**
     * 订单金额
     */
    orderCodAmount?: number
    previousCodFee?: number | string
  } & Pick<IServiceProviderVO, 'type'>
) => {
  const { provider_payment_type, orderCodAmount, previousCodFee } = data

  if (provider_payment_type === PaymentTypeEnum.notCod) {
    return ''
  } else if (orderCodAmount) {
    // 拥有修改的值就读修改后的值否则读订单原本
    if (!isNaN(Number(previousCodFee))) {
      return previousCodFee
    } else {
      const codFee = divide(Number(orderCodAmount || 0), 100)
      return codFee
    }
  }
  // 如果为创建运单或者编辑运单，则直接读取上一次的编辑的值

  return previousCodFee
}

/**
 * 获取 payment
 * @param data
 * @returns
 */
export const handlePayment = (data: {
  companyCode: string
  settlementModes: number[]
  estimatedCost: number
  isPrepaid: boolean
  type: IServiceProviderVO['type']
  saasFlag: number
  /** 用户钱包的币种没有该服务产品的运费币种 */
  freightCurrencyCodeNoInUserCurrencyCodeList: boolean
}) => {
  const { companyCode, settlementModes, estimatedCost, isPrepaid, saasFlag, type, freightCurrencyCodeNoInUserCurrencyCodeList } = data
  const freight = estimatedCost || 0

  // 到付现结 根据接口（后端根据 Onship服务产品授权）
  // 改动 PRD https://shopline.yuque.com/mlu41d/gy9ldi/evvics
  // 数据直接读取后端
  let payTypeOptions = settlementModes
    ?.map((value) => ({
      value,
      label: PAY_TYPE[value],
      disabled: DEFAULT_PAYMENT_OPTIONS[value],
    }))
    .sort((a, b) => a.value - b.value)

  // 到付现结的option位置
  const cashOnDeliveryItemInd = payTypeOptions.findIndex((item) => item.value === 2)

  // zeek 特殊处理， 排除saas服务产品
  if (type !== 'batch' && type !== 'reverse-batch' && ['ZEEK', 'HKZEEK'].indexOf(companyCode) !== -1 && saasFlag !== SAAS_FLAG.YES) {
    // 预估运费没问题
    if (freight !== 0) {
      // 再根据是否支持加入 到付现结
      if (-1 !== settlementModes.indexOf(2)) {
        payTypeOptions[cashOnDeliveryItemInd].disabled = false
      }
    }
  } else {
    // 根据是否支持加入 到付现结
    if (-1 !== settlementModes.indexOf(2)) {
      payTypeOptions[cashOnDeliveryItemInd].disabled = false
    }
  }

  let defaultValue = DELIVERY_SETTLE

  // 用户币种钱包没有此服务产品运费币种，只支持【到付现结】2(因为到付现结是线下给运费的，不扣钱包钱)
  // 算不出运费，也只支持【到付现结】
  // prd: https://shopline.yuque.com/mlu41d/gy9ldi/bbntsl#NGkpY
  if (saasFlag !== SAAS_FLAG.YES && isPrepaid && type !== 'batch' && type !== 'reverse-batch') {
    // 注意step by step订单编辑场景，此方法在初始化执行的时候，会出现服务产品没有币种的情况；此时会在step2 到 step3过程中额外处理options
    if (freightCurrencyCodeNoInUserCurrencyCodeList || freight === 0) {
      payTypeOptions = payTypeOptions.map((item) => ({
        ...item,
        disabled: item.value !== 2,
      }))
      defaultValue = undefined // 支付方式默认值留空
    }
  }

  const enabledOptions = payTypeOptions.filter((item) => !item.disabled) // 可选的options
  const enabledValues = enabledOptions.map((item) => item.value) // 可选的options的值
  defaultValue = defaultValue === undefined || enabledValues.includes(defaultValue) ? defaultValue : enabledValues[0]

  return {
    options: enabledOptions,
    defaultValue,
    list: enabledValues,
  }
}

export interface GetPickupTimeOptions {
  supportedPickupTypes: number[]
  formValues?: {
    provider_pickup_type?: number
    provider_pickup_date?: string
    provider_pickup_time_slots?: string
  }
}

/**
 * 服务产品配置(获取揽收时间和税号配置)
 */
export const getExpressConfig = async ({
  supportedPickupTypes,
  formValues,
  expressTypeCode,
  address,
  priceSettingInfo,
  logisticsType,
  type,
}: GetPickupTimeOptions &
  ExpressTypeConfigListRequest & {
    priceSettingInfo?: PriceSettingInfo
  } & {
    type: IServiceProviderVO['type']
  }) => {
  const { data } = await queryExpressConfig({
    expressTypeCode,
    address,
    logisticsType,
  })

  const canPickUp = supportedPickupTypes.includes(SHIPMENT_PICKUP_TYPE.pickUp)

  let { provider_pickup_type, provider_pickup_date, provider_pickup_time_slots } = formValues || {}
  // https://shopline.yuque.com/mlu41d/gy9ldi/nqvrv8xu75x4g9m2#oTw3M 默认逻辑修改
  const defaultPickup = -1 !== supportedPickupTypes?.indexOf(SHIPMENT_PICKUP_TYPE.pickUp) ? SHIPMENT_PICKUP_TYPE.pickUp : SHIPMENT_PICKUP_TYPE.dropOff
  let list: ProviderInfoItem[] = []
  let shipmentPickupTimeRangOptions: string[] = []
  // 能不能 pickup 都不影响 formValues 的值
  // formValues 中的值永远不能被修改(*最高优先级*)
  // 只要服务产品支持 pickup 就直接请求一次, 后续不会请求, 除非切换服务产品
  if (canPickUp) {
    list = data?.selectablePickTimes || []

    if (list.length) {
      // 此处主要用于编辑场景
      // 默认有值则需要查找对应的项赋值给 shipmentPickupTimeRangOptions
      // 其他值不能进行赋值操作, 否则会改变原表单信息
      if (formValues && !['create', 'batch'].includes(type)) {
        const selected = list.find(({ date }) => date === provider_pickup_date)
        selected && (shipmentPickupTimeRangOptions = selected?.timeIntervals)
      } else {
        // 初始化默认逻辑
        // 如果表单没有值, 则需要帮忙设置默认值
        const [selected] = list
        shipmentPickupTimeRangOptions = selected.timeIntervals
        provider_pickup_date = selected.date
        provider_pickup_time_slots = shipmentPickupTimeRangOptions?.[0]
      }
    }
  }

  // 计价匹配
  // 优先使用交集
  // 没有交集走原本选择
  if (priceSettingInfo && !priceSettingInfo?.allAssociatedService && priceSettingInfo?.pickupType?.length) {
    provider_pickup_type = getPickupType({
      supportedPickupTypes: priceSettingInfo?.pickupType,
      provider_pickup_type,
    })
  }

  provider_pickup_type = provider_pickup_type || defaultPickup

  return {
    dateData: {
      shipmentPickupDateOptions: list,
      shipmentPickupTimeRangOptions,
      provider_pickup_time_slots,
      provider_pickup_date,
      provider_pickup_type: provider_pickup_type,
    },
    extraConfig: data?.configs,
    supportStationPickUp: data?.supportStationPickUp,
  }
}

// 获取 options 上面已经做过了
export const initialPickupTimeWithChange = (
  provider_pickup_type: number,
  options: {
    providerDateOptions: ProviderInfoItem[]
  }
) => {
  const { providerDateOptions } = options

  // 如果不是预约揽收或者无数据
  if (provider_pickup_type !== SHIPMENT_PICKUP_TYPE.pickUp || !providerDateOptions?.length) {
    return {
      provider_pickup_time_slots: '',
      provider_pickup_date: '',
    }
  }

  const defaultData = providerDateOptions[0]
  const provider_pickup_date = defaultData.date
  const providerTimeOptions = defaultData?.timeIntervals
  const provider_pickup_time_slots = providerTimeOptions?.[0]

  return {
    providerTimeOptions,
    provider_pickup_date,
    provider_pickup_time_slots,
  }
}

export const handleSubAccount = async ({ expressTypeCode, pickupType }: { expressTypeCode: string; pickupType: number }) => {
  const data = await getSubAccount({
    expressTypeCode,
    pickupType: pickupType as 1 | 2,
  })
  const list = data.data?.subAccountDetails || []

  return {
    list,
    value: list?.[0]?.accountNo,
  }
}

type IGetServiceProvider = {
  currencyCode?: string
  isPrepaid?: boolean
  props?: IExpressProps & {
    curExpTypeCode?: string
  }
  extraGetListQuery?: Record<string, any>
  orderCodCurrencyCode?: string

  partitionInfo?: Partial<FeePayloadMetadatasItem['partitionInfo']>
  provider_pickup_type?: number
} & Pick<IServiceProviderVO, 'type'>

/**
 * format 地址
 * @param payload
 * @returns
 */
export function convertAddress(payload: Pick<IExpressProps, 'addressVO'> & Pick<IServiceProviderVO, 'type'> & { isHome?: boolean }) {
  const { addressVO, type, isHome } = payload
  interface IAddress {
    senderAddressName?: IAddressItem
    consigneeAddressName?: IAddressItem
  }
  const address: IAddress = {}

  if (type !== SHIP_TYPE_ENUM.REVERSE_BATCH) {
    address['senderAddressName'] = {
      countryCode: addressVO?.departure_countryCode,
      provinceName: decodeLanguageAndProvince(addressVO?.departure_province)?.province,
      cityName: addressVO?.departure_city,
      districtName: addressVO?.departure_district,
      zipCode: addressVO?.departure_zipCode,
      address: addressVO?.departure_address,
      addressTwo: addressVO?.departure_addressTwo,
      townName: addressVO?.departure_town,
      name: addressVO?.departure_fullName,
      mobile: addressVO?.departure_mobile,
    }
  }

  if (type !== 'batch') {
    address['consigneeAddressName'] = {
      countryCode: addressVO?.arrival_countryCode,
      provinceName: isHome ? decodeLanguageAndProvince(addressVO?.arrival_province)?.province : addressVO?.arrival_store_province,
      cityName: isHome ? addressVO?.arrival_city : addressVO?.arrival_store_city,
      districtName: isHome ? addressVO?.arrival_district : addressVO?.arrival_store_district,
      zipCode: addressVO?.arrival_zipCode,
      address: addressVO?.arrival_address,
      addressTwo: addressVO?.arrival_addressTwo,
      townName: addressVO?.arrival_town,
      name: addressVO?.arrival_fullName,
      mobile: addressVO?.arrival_mobile,
    }
  }

  return address
}

interface ServiceProviderListItem extends ExpressListData {
  /** 某些渠道才有的路线名 */
  planName?: string
}

export const getServiceProvider = async (
  payload: IGetServiceProvider
): Promise<{
  /** the filtered list */
  list: ServiceProviderListItem[]
  /** the original list */
  expressList: ServiceProviderListItem[]
}> => {
  const { props, currencyCode, isPrepaid, type, orderCodCurrencyCode, provider_pickup_type, partitionInfo } = payload

  const { parcel_weight, parcel_weight_unit, parcel_height, parcel_length, parcel_width, supportedAddressTypes, addressVO: inputAddressVO, ...args } = props ?? {}
  /**
   * 是宅配
   */
  const isHome = supportedAddressTypes?.[0] === 'HOME_STREET'
  const addressVO = convertAddress({
    addressVO: inputAddressVO,
    type,
    isHome,
  })

  /** 是否为退货 */
  const isReverse = [SHIP_TYPE_ENUM.REVERSE, SHIP_TYPE_ENUM.REVERSE_BATCH].includes(type as any)

  /** 是否查询服务产品保费区间 */
  const insuranceParams =
    type !== 'batch' && type !== 'reverse-batch'
      ? {
          queryInsuranceSupport: true,
        }
      : {}

  const extraStationProps = isHome
    ? {}
    : {
        stationCode: inputAddressVO.arrival_station_code,
        stationType: inputAddressVO.arrival_station_type?.toString(),
      }
  const companyCode = isHome && !isReverse ? undefined : args.companyCode || undefined
  const lengthUnit = 'cm'
  const params: ExpressListPayload = isPlatformLogistics(props.expressLogisticsType)
    ? { expressLogisticsType: props.expressLogisticsType, expTypeCode: props.curExpTypeCode }
    : {
        ...omit(args, ['appId', 'arrival_method', 'orderInfo', 'companyCode', 'currencyCode']),
        needStationType: true,
        supportedAddressTypes,
        pageSize: 999,
        pageNum: 1,
        companyCode,
        weightUnit: (parcel_weight_unit || 'kg') as 'kg',
        weight: Number(parcel_weight),
        length: Number(parcel_length),
        width: Number(parcel_width),
        height: Number(parcel_height),
        lengthUnit: lengthUnit as 'cm',
        orderCodCurrencyCode,
        ...addressVO,
        ...insuranceParams,
        ...extraStationProps,
      }
  if (args.appId) params.supportedEcPlatforms = [args.appId]

  try {
    const data = await getExpressList({
      ...params,
      logisticsType: isReverse ? LogisticsType.reverse : undefined,
    })

    const expressList = data.data.data
    if (expressList && expressList.length) {
      const tmpParams = expressList.slice().map((item) => {
        // the priority is the pickup
        const pickupType = getPickupType({
          supportedPickupTypes: item.supportedPickupTypes,
          provider_pickup_type,
        })
        return {
          expressTypeCode: item.expressTypeCode,
          metadatas: [
            {
              metadataId: Math.floor(Math.random() * Math.pow(10, 20)) + '',
              weightUnit: parcel_weight_unit || 'kg',
              weight: Number(parcel_weight),
              length: Number(parcel_length),
              width: Number(parcel_width),
              height: Number(parcel_height),
              lengthUnit: 'cm',
              currencyCode,
              senderAddress: addressVO?.senderAddressName,
              consigneeAddress: addressVO?.consigneeAddressName,
              partitionInfo: {
                ...partitionInfo,
                pickupType,
                deliveryType: isHome ? DELIVER_VALUE_MAP.HOME : DELIVER_VALUE_MAP.STORE,
              },
            },
          ],
        }
      })

      let newExpressList = expressList

      // 非批量需要计算运费
      if (type !== SHIP_TYPE_ENUM.BATCH && type !== SHIP_TYPE_ENUM.REVERSE_BATCH) {
        const feeRes = await getFeeList({
          feeMetadatas: tmpParams,
        })

        const currentFeeArr = feeRes?.data?.shippingFeeResults ?? []

        const feeMap = new Map(currentFeeArr.map((item) => [item.expressTypeCode, item]))

        newExpressList = expressList
          ?.slice()
          ?.map((item) => {
            const { expressTypeCode } = item

            const currentData = feeMap.get(expressTypeCode)

            const currentFeeData = currentData?.shippingResults?.[0]

            let promisedTime = item.promisedTime
            let planName = ''
            if (currentFeeData?.channelCustomParamParse) {
              const customData = currentFeeData.channelCustomParamParse
              promisedTime = customData.arriveDaysRange || promisedTime
              planName = customData[item.companyCode.toLocaleLowerCase()]?.planName
            }

            return {
              ...item,
              ...formatFreightItem(currentData),
              priceSettingInfo: currentFeeData?.priceSettingInfo,
              promisedTime,
              planName,
            }
          })
          .filter((item) => {
            // saas服务产品需要展示，平台物流需要展示
            if (item.saasFlag === SAAS_FLAG.YES || isPlatformLogistics(item.expressLogisticsType)) {
              return true
            }

            // 当用户是个人用户时  如果运费试算不能计算出运费，或者运费为负数或者0，不显示这个服务产品给用户看到
            // https://shopline.yuque.com/slpm/ta6d4y/nmwb7s#j8733
            if (isPrepaid) {
              //支持到付的服务产品可展示，排除zeek
              if (item.settlementModes.includes(2)) {
                if (!isZEEKFn(item.companyCode)) {
                  return true
                }
              }

              return !!item.estimatedCost
            }
            return true
          })
      }

      if (newExpressList && newExpressList.length) {
        return {
          list: sortProviderList(newExpressList),
          expressList,
        }
      } else {
        return {
          list: [],
          expressList: [],
        }
      }
    }
  } catch (error) {
    throw error
  }

  return {
    list: [],
    expressList: [],
  }
}

export const convertAddress2Props = (address: ICoverAddressTypes): IProviderAddressVO => {
  return {
    departure_countryCode: address.senderCountryCode,
    departure_city: address.senderCity,
    departure_province: decodeLanguageAndProvince(address.senderProvince).province,
    departure_district: address.senderDistrict,
    departure_zipCode: address.senderZipCode,
    departure_address: address.senderAddress,
    departure_addressTwo: address.senderAddressTwo,
    departure_fullName: address.senderName,
    departure_mobile: address.senderMobile,
    arrival_countryCode: address.consigneeCountryCode,
    arrival_city: address.consigneeCity,
    arrival_district: address.consigneeDistrict,
    arrival_province: decodeLanguageAndProvince(address.consigneeProvince).province,
    arrival_zipCode: address.consigneeZipCode,
    arrival_address: address.consigneeAddress,
    arrival_addressTwo: address.consigneeAddressTwo,
    arrival_fullName: address.consigneeName,
    arrival_mobile: address.consigneeMobile,
  }
}

interface calculateTotalPricePrams {
  /** 包裹信息 */
  parcel_commodities: ParcelFormFields['parcel_commodities']
  /** 服务产品运费币种 */
  freightCurrencyCode: string
  /** 订单币种 */
  currencyCode: string
  /** 最大投保额 */
  maxInsuredAmount?: number | undefined
  /**
   * 是否开启了币种自动转换
   */
  hasCurrencyRate: boolean
}

/**
 * 计算前端商品总额
 * @returns
 */
export const calculateTotalPrice: (params: calculateTotalPricePrams) => Promise<number | undefined> = async (params) => {
  const { parcel_commodities, freightCurrencyCode, currencyCode, maxInsuredAmount, hasCurrencyRate } = params
  if (parcel_commodities?.length) {
    let totalPrice = parcel_commodities.reduce((pre, next) => {
      const price = times(next.parcel_goods_price, next.parcel_goods_quantity)
      return price + pre
    }, 0)
    totalPrice = times(Number(totalPrice), 100)

    // 币种不一致，需要汇率换算
    if (freightCurrencyCode !== currencyCode) {
      // 没开启币种转换，金额留空
      if (!hasCurrencyRate) {
        return undefined
      }
      const { data } = await fetchRateList({
        sourceAmountList: [totalPrice],
        sourceCurrency: currencyCode,
        targetCurrency: freightCurrencyCode,
      })
      totalPrice = data?.targetAmountList[0]
    }

    if (maxInsuredAmount && totalPrice > maxInsuredAmount) {
      // 币种都是一样的
      return divide(maxInsuredAmount, 100)
    }

    totalPrice = typeof totalPrice === 'number' ? divide(totalPrice, 100) : undefined
    return totalPrice
  }
}

/**
 * 计算保费
 */
export const getPremium = async (feeMetadatas) => {
  const { data } = await getFeeList({
    feeMetadatas,
  })
  const premium = data?.shippingFeeResults?.[0]?.shippingResults?.[0]?.premium
  const currencyCode = data?.shippingFeeResults?.[0]?.shippingResults?.[0]?.currencyCode
  return { premium, currencyCode }
}

/** 获取派送时间 */
export const getDeliveryTime = ({
  provider,
  formValues,
}: {
  provider: Partial<ExpressListData>
  formValues: {
    provider_delivery_time?: string
    provider_delivery_date?: string
  }
}) => {
  const selectableDeliveryTimes = provider?.selectableDeliveryList?.selectableDeliveryTimes
  const { provider_delivery_time, provider_delivery_date } = formValues
  const targetDate = selectableDeliveryTimes?.find((item) => item.date === provider_delivery_date)
  const res = {
    providerDeliveryDateOptions: selectableDeliveryTimes,
    providerDeliveryTimeOptions: [],
    provider_delivery_date: provider_delivery_date || '',
    provider_delivery_time: provider_delivery_time || '',
  }
  if (targetDate) {
    res.providerDeliveryTimeOptions = selectableDeliveryTimes?.find((item) => item.date === provider_delivery_date)?.timeIntervals || []
  }
  return res
}

/** 是否清空包裹价值 */
export const shouldClearInsuranceAmountFn = ({
  preInsuranceType,
  provider,
  previousProvider,
  defaultCurrencyCode,
}: {
  preInsuranceType: number
  provider: IExpressData
  previousProvider: IExpressData
  defaultCurrencyCode: string
  type: `${SHIP_TYPE_ENUM}`
}) => {
  // 包裹价值币种对比，不一致清空
  const preCurrencyCode = previousProvider?.freightCurrencyCode || defaultCurrencyCode
  const currencyCode = provider?.freightCurrencyCode || defaultCurrencyCode
  if (preCurrencyCode !== currencyCode) {
    return true
  }

  const supportIns = provider.insuranceSupport === INSURANCE_SUPPORT_TYPE.YES // 当前的服务产品是否支持保险

  return preInsuranceType === INSURANCE_TYPE.YES && !supportIns
}

export const formatProvinceUtil = (data: IProviderAddressVO) => {
  const arrival_province = decodeLanguageAndProvince(data.arrival_province)?.province
  const departure_province = decodeLanguageAndProvince(data.departure_province)?.province

  return {
    ...data,
    arrival_province,
    departure_province,
  }
}

interface ISortProviderListOptions {
  /** this option will filter the ones who available is false */
  filter?: boolean
}
export function sortProviderList(list?: ExpressListData[], options?: ISortProviderListOptions) {
  if (!list?.length) return []

  const { filter = false } = options || ({} as ISortProviderListOptions)
  const newList = Array.from(list).sort((a, b) => {
    if (a.available && !b.available) return -1
    if (!a.available && b.available) return 1
    return 0
  })

  if (filter) {
    return newList.filter((item) => item.available)
  }

  return newList
}

/**
 *
 */
export const calculateFreightAndFeeItem = async (feeMetadatas) => {
  const { data } = await getFeeList(
    {
      feeMetadatas,
    },
    'freight'
  )

  const feeRes = data?.shippingFeeResults?.[0]

  return feeRes
}

export const formatFreightItem = (currentData: FeeObject) => {
  const currentFeeData = currentData?.shippingResults?.[0]
  const rightsResults = currentData?.rightsResults

  return {
    estimatedCost: currentFeeData?.freight,
    freight: currentFeeData?.freight,
    freightCurrencyCode: currentFeeData?.currencyCode,
    originalFreight: currentFeeData?.originalFreight,
    discountFreight: currentFeeData?.discountFreight,
    rightsResults,
    failReason: currentFeeData?.failReason,
    feeItems: currentFeeData?.feeItems,
  }
}
