/* eslint-disable no-console */
import { cloneDeep } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import Api from '@/services/http'
import { trackingSettingUrl } from '@/services/url'
import type { AppDispatch } from '@/store'
import { countryConfig, countryVersion, localCountries } from '@/utils/countryVersion'
import { DOMAIN_MAP } from './constant'
import type * as Types from './types'

const initialState: Types.CommonState = {
  trackingMate: {},
  publicTrackingMate: {},
  trackingLink: '',
}

const trackingSettingSlice = createSlice({
  name: 'trackingSetting',
  initialState,
  reducers: {
    setTrackingMate(state, action) {
      const newTrackingMate = {
        ...state.trackingMate,
        ...action.payload,
      }
      const oneshipHost = localCountries.includes(countryVersion) ? countryConfig.tracking : 'oneship.io'
      const trackingLink = `https://${newTrackingMate.handleCode}.${DOMAIN_MAP[process.env.APP_ENV]}${oneshipHost}`
      return {
        ...state,
        trackingMate: newTrackingMate,
        trackingLink,
      }
    },
    setPublicTrackingMate(state, action) {
      return {
        ...state,
        publicTrackingMate: {
          ...state.publicTrackingMate,
          ...action.payload,
        },
      }
    },
  },
})

/** 获取运单轨迹页面配置 */
export const getTrackingMeta = () => async (dispatch: AppDispatch) => {
  const response = await Api.admin.post(trackingSettingUrl.trackingMeta)
  const { data, bizCode, bizMessage, message } = response
  if (bizCode === 'SUCCESS') {
    const trackingMate = {
      handleCode: data.handleCode,
      ...data.trackingSetting,
    }
    dispatch(setTrackingMate(trackingMate))
    return trackingMate
  }

  return Promise.reject({ bizCode, bizMessage, message })
}

// 运单轨迹页面配置更新
export const updateTrackSettings = (params?: Types.TrackSettingsParams) => async (dispatch: AppDispatch) => {
  const response = await Api.admin.post(trackingSettingUrl.trackSettingsUpdate, params)
  const { bizCode, bizMessage, message } = response
  if (bizCode === 'SUCCESS') {
    const trackingMate = cloneDeep(params)
    dispatch(setTrackingMate(trackingMate))
    return trackingMate
  }

  return Promise.reject({ bizCode, bizMessage, message })
}

const parseUploadInfo = (infoArray: Record<string, any>[]) => {
  if (!infoArray) {
    return {}
  }

  const data = {}
  infoArray.forEach((info) => {
    data[info.key] = info.value
  })

  return data
}

/** 生成预授权上传URL */
export const getAmazonS3Api = async (params?: { fileName: string }) => {
  const response = await Api.admin.post<Types.S3ResponseType>(trackingSettingUrl.getS3Url, {
    scene: 'TRACK_PAGE_BRAND_URL',
    fileName: params?.fileName,
  })
  const { bizCode, data } = response
  if (bizCode === 'SUCCESS') {
    const { actionUrl, fields, headers, fileId } = data
    const uploadFormData = new FormData()
    let imgUrl = ''
    // 组装formdata 数据
    fields.forEach((item: any) => {
      item.key === 'key' && (imgUrl = `https://cdn.myoneship.cn/${item.value}`)
      uploadFormData.append(item.key, item.value)
    })

    return {
      actionUrl,
      data: uploadFormData,
      headers: { ...parseUploadInfo(headers || []), 'content-type': 'multipart/form-data' },
      fileId,
      imgUrl,
    }
  }

  return Promise.reject(response)
}

/** 上传到亚马逊 */
export const uploadAmazon = async (params: { url: string; formData: any; headers: any }) => {
  const response = await Api.post(params.url, params.formData, { headers: { ...params.headers }, timeout: 60e3, withCredentials: false })
  const { status } = response
  if (status === 204) {
    return {
      bizCode: 'SUCCESS',
      data: response,
    }
  }

  return Promise.reject({
    bizCode: 'UPLOAD_FAIL',
    bizMessage: 'batchOrder.uploadError',
    message: 'batchOrder.uploadError',
  })
}

export const { setTrackingMate, setPublicTrackingMate } = trackingSettingSlice.actions
export default trackingSettingSlice.reducer
