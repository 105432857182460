import API from '@/services/http'
import type {
  RecommendListRes,
  RecommendListReq,
  ExpressDetailReq,
  ExpressDetailRes,
  AddressReq,
  AddressRes,
  PricingPlanReq,
  PricingPlanRes,
  PriceSettingPageReq,
  SettingPageRes,
  ICountry,
} from './type'

// 服务产品推荐列表
export const fetchRecommendList = async (params: RecommendListReq) => API.admin.post<RecommendListRes>('/api/express/recommend/list', params)

// 服务产品详情
export const fetchExpressDetail = async (params: ExpressDetailReq) => API.admin.post<ExpressDetailRes>('/api/express/get', params)

// 服务产品报价发件地址查询
export const fetchSenderAddress = async (params: AddressReq) => API.admin.post<AddressRes>('/api/express/pricing-plan/sender-address/page', params)

// 服务产品报价收件地址查询
export const fetchConsigneeAddress = async (params: AddressReq) => API.admin.post<AddressRes>('/api/express/pricing-plan/consignee-address/page', params)

// 报价列表
export const fetchPricingPlan = async (params: PricingPlanReq) => API.admin.post<PricingPlanRes>('/api/express/pricing-plan/page', params)

// 查询充值套餐信息
export const fetchPriceSettring = async (params: PriceSettingPageReq) => API.admin.post<SettingPageRes>('/api/product/price/setting/page', params)

export const fetchDepartureCountryList = async () =>
  API.admin.post<{
    supportSearchCountryVos: ICountry[]
  }>('/api/express/support/search/country')
