/**
 * 地址用的utils
 */

import { flatten } from 'lodash'
/**
 * 承运商是4PX
 * @param {string} companyCode 服务商
 * @return {boolean}
 */
export const isFourPxFn = (companyCode: string) => companyCode === '4PX'

/**
 * 服务商是否zeek
 * @param {string} companyCode 服务商
 * @returns boolean
 */
export const isZEEKFn = (companyCode: string) => companyCode === 'HKZEEK'

interface Country {
  senderCountryCode: string
  consigneeCountryCode: string
}

/**
 * 是否是跨境
 * 条件：发件国家和收件国家不同
 * @param {string} senderCountryCode 发件国家地区
 * @param {string} consigneeCountryCode 收件国家地区
 * @return {boolean} 是否跨境
 */
export const isCrossBorderFn: (params?: Country) => boolean = (params) => {
  if (!params) return false
  const { senderCountryCode = '', consigneeCountryCode = '' } = params
  return senderCountryCode !== consigneeCountryCode
}

/**
 * 是否是中国跨境
 * 条件：发件国家是中国大陆，收件国家非中国大陆
 * @param {string} senderCountryCode 发件国家地区
 * @param {string} consigneeCountryCode 收件国家地区
 * @return {boolean}
 *
 */
export const isChinaCrossBorderFn: (params?: Country) => boolean = (params) => {
  if (!params) return false
  const { senderCountryCode = '', consigneeCountryCode = '' } = params
  return senderCountryCode === 'CN' && 'CN' !== consigneeCountryCode
}

/**
 * 跨境收件地区是台湾
 * 条件：收件地区是台湾，发件地区是非台湾
 * @param {string} senderCountryCode 发件国家地区
 * @param {string} consigneeCountryCode 收件国家地区
 * @return {boolean}
 */
export const isTWCrossBorderFn: (params: Country) => boolean = (params) => {
  if (!params) return false
  const { senderCountryCode = '', consigneeCountryCode = '' } = params
  return senderCountryCode !== 'TW' && consigneeCountryCode === 'TW'
}

/**
 * HK kerry 香港-台湾
 * 条件：
 * 1. 寄件地址countryCode === 'HK'
 * 2. 收件地址countryCode === 'TW'
 * 3. 承运商是companyCode === 'HK_KERRY'
 * @param {string} companyCode 服务商
 * @param {string} senderCountryCode 发件国家地区
 * @param {string} consigneeCountryCode 收件国家地区
 */
export const isKerryHk2TWFn: (params?: Country & { companyCode: string }) => boolean = (params) => {
  if (!params) return false
  const { companyCode = '', senderCountryCode = '', consigneeCountryCode = '' } = params
  return companyCode === 'HK_KERRY' && senderCountryCode === 'HK' && consigneeCountryCode === 'TW'
}

/**
 * 是否中国到美国
 * @param {string} senderCountryCode 发件国家地区
 * @param {string} consigneeCountryCode 收件国家地区
 * @returns
 */
export const isCN2USFn: (params: Country) => boolean = (params) => {
  if (!params) return false
  const { senderCountryCode = '', consigneeCountryCode = '' } = params
  return senderCountryCode === 'CN' && consigneeCountryCode === 'US'
}

/**
 * 是否香港跨境
 * @param {string} senderCountryCode 发件国家地区
 * @param {string} consigneeCountryCode 收件国家地区
 * @returns
 */
export const isHKCrossBorderFn: (params: Country) => boolean = (params) => {
  if (!params) return false
  const { senderCountryCode = '', consigneeCountryCode = '' } = params
  return senderCountryCode === 'HK' && consigneeCountryCode !== 'HK'
}

export const isIndonesiaFn: (params?: Country) => boolean = (params) => {
  if (!params) return false
  const { senderCountryCode = '', consigneeCountryCode = '' } = params
  const IDCountryCode = 'ID'
  return senderCountryCode === IDCountryCode && IDCountryCode === consigneeCountryCode
}

const stepFieldMap = [['departure_', 'arrival_'], ['parcel_'], ['provider_']]
export const getErrorFieldsStep = (errorFields) => {
  const errorFieldsKeys = flatten(errorFields.map((item) => item.name)) as string[]

  const step = stepFieldMap.findIndex((prefixKeys) =>
    errorFieldsKeys.find((errorName) => prefixKeys.find((prefix) => (typeof errorName === 'string' ? errorName.includes(prefix) : false)))
  )
  return step
}
