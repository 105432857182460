export default {
  /** 门店列表分页查询接口 */
  stationList: '/api/station/list',
  /** 门店列表分页查询接口 */
  stationListNew: '/api/sales/order_query/list_station',
  /** 获取门店所在行政区域信息列表 */
  stationAreaList: '/api/station/list_child',
  /** 门店详情 */
  stationDetail: '/api/station/get',
}
