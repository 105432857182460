import type { AxiosInstance, Method as AxiosMethod, CancelTokenSource } from 'axios'
import Axios from 'axios'
import type { FunctionKeys } from 'utility-types'
import type { ChainRecord, DeepMerge } from '../types/index'

const cancelTokens: Record<string, CancelTokenSource> = {}
const CancelToken = Axios.CancelToken

/**
 * 只请求最后的结果, 其他都直接打断或丢弃
 * @example
 * http.admin.takeLatest('token', 'get', 'url')
 */
export async function takeLatest<R = any, M extends Lowercase<AxiosMethod> = Lowercase<AxiosMethod>>(
  this: AxiosInstance,
  token: string,
  method: M,
  ...args: M extends FunctionKeys<AxiosInstance> ? Parameters<AxiosInstance[M]> : never
) {
  if (typeof cancelTokens?.[token] !== 'undefined') {
    cancelTokens[token].cancel('TakeLatest Cancel.')
    cancelTokens[token] = undefined
  }

  const methods = ['post', 'put', 'patch']
  const finalArgs = (() => {
    if (1 !== methods.indexOf(method)) {
      return [...args]
    }

    const params = [...args]
    params.splice(1, 0, undefined)
    return params
  })()

  const cancelToken = (cancelTokens[token] = CancelToken.source())
  const [url, inData, config] = finalArgs
  const data = typeof inData !== undefined ? inData : config?.data
  return this.request<R>({ ...config, url, method, data, cancelToken: cancelToken.token })
}

/**
 * 扩展 takeLatest 方法
 * @param Instance Axios/Http 实例, 通过 create 生成的实例
 * @param Body 不同域的返回格式(返回值 body)
 * @param Data 不同域的动态返回值所在位置(返回值 data 所在 body 的键值链式数组)
 * @example
 * type Http = TakeLatest<AxiosInstance, { data: { a: 1 } }, ['data']>
 * const http: Http = null
 * http.takeLatest<{ abc: 123 }>('token', 'get', 'url').then((response) => {
 *  console.log(response.data.a) // 1
 *  console.log(response.data.abc) // 123
 * })
 */
// prettier-ignore
export type TakeLatest<
  Body extends Record<string, any>,
  Data extends string[]
> = {
  /**
   * 只请求最后的结果, 其他都直接打断或丢弃
   * @description
   * 因为 Parameters 不能分离 overload, 因此会导致获取的参数为 overload 最后的参数
   * 因此这里需要重写 takeLatest 声明
   */
  takeLatest<R = any, M extends Lowercase<AxiosMethod> = Lowercase<AxiosMethod>>(
    token: string,
    method: M,
    ...args: M extends FunctionKeys<AxiosInstance> ? Parameters<AxiosInstance[M]> : never
  ): Promise<DeepMerge<Body, ChainRecord<Data, R>>>
}

/**
 * 扩展 MapTakeLatest 方法
 * @description takeLatest 的复数
 */
// prettier-ignore
export type MapTakeLatest<
  Body extends Record<string, any>,
  Data extends Record<keyof Body, string[]>
> = {
  [K in keyof Body]: TakeLatest<Body[K], Data[K]>
}
