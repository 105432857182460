import type { SettingKeyType } from '@/store/commonSlice/types'

export type HandleType = 'add' | 'delete'

type HandleTypeMap = Record<'ADD' | 'DELETE', HandleType>

export const HANDLE_TYPE: HandleTypeMap = {
  ADD: 'add',
  DELETE: 'delete',
}

export type SettingCode = Partial<SettingKeyType>
type SettingCodeMap = Record<SettingCode, SettingCode>

export const SETTING_CODE: Partial<SettingCodeMap> = {
  ORDER_TAB_FEATURE: 'ORDER_TAB_FEATURE', // 运单TAB页配置
  SALES_TAB_FEATURE: 'SALES_TAB_FEATURE', // 订单TAB页配置
  REVERSE_TAB_FEATURE: 'REVERSE_TAB_FEATURE', // 退货单TAB页配置
  ON_DEMAND_ORDER_TAB_FEATURE: 'ON_DEMAND_ORDER_TAB_FEATURE', // 即时物流运单TAB页配置
  ON_DEMAND_SALES_TAB_FEATURE: 'ON_DEMAND_SALES_TAB_FEATURE', // 即时物流订单TAB页配置
  ORDER_SORT_FEATURE: 'ORDER_SORT_FEATURE', // 运单排序
  SALES_SORT_FEATURE: 'SALES_SORT_FEATURE', //  订单排序
  ON_DEMAND_ORDER_SORT_FEATURE: 'ON_DEMAND_ORDER_SORT_FEATURE', // 运单排序
  ON_DEMAND_SALES_SORT_FEATURE: 'ON_DEMAND_SALES_SORT_FEATURE', //  订单排序
}
export type BizType = 1 | 2 | 4
export type BizTypeMap = Record<'ORDER' | 'LOGISTICS' | 'IMMEDIATE_ORDER' | 'IMMEDIATE_LOGISTICS' | 'REVERSE', BizType>
export const BIZ_TYPE: BizTypeMap = {
  ORDER: 1,
  LOGISTICS: 2,
  IMMEDIATE_ORDER: 1,
  IMMEDIATE_LOGISTICS: 2,
  REVERSE: 4,
}

// 用户添加包裹尺寸个数限制
export const PackageSizeNumberLimit = 3

// shippingRules相关链接
export const ShippingRulesExampleLink =
  'https://support.oneship.io/hc/articles/7195119463695--Coming-soon%E4%B8%A8Get-OneShip-shipping-rates-on-the-checkout-page-of-your-sales-platform'
export const ShippingRulesIntroductionLink =
  'https://support.oneship.io/hc/articles/7195119463695--Coming-soon%E4%B8%A8Get-OneShip-shipping-rates-on-the-checkout-page-of-your-sales-platform'
