import { Modal } from 'antd'
import React, { useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { OneInput, OneModal } from '@yy/one-ui'
import { BizCodeEnum } from '@/constant'
import { createSassSubAccount } from '@/services/apis/order'
import i18n from '@/services/i18n'
import { useSelector } from '@/store'

interface IAccountRef {
  expressTypeCode: string
  account: string
}

let hasMergeModalShow = false

export function useModalConfirm() {
  const history = useHistory()
  const { provider } = useSelector((state) => state.newServiceProvider)
  const AccountRef = useRef<IAccountRef>({} as IAccountRef)
  const ContentRef = useRef(null)

  AccountRef.current.expressTypeCode = provider?.expressTypeCode

  /**
   * saas服务产品权益不足提示
   */
  const SellerToBuySaasConfirm = useCallback(() => {
    OneModal.confirm({
      title: i18n.t('frame.hint'),
      content: i18n.t('setting.shipsetting.shipFail.buy'),
      okText: i18n.t('common.buy'),
      cancelText: i18n.t('frame.cancel'),
      onOk: () => {
        history.push('/account/subscribe/add-benefit/os_saas_order')
      },
    })
  }, [history])

  /**
   * 配置发货账户
   */
  const SellerToCreateSellerAccountConfirm = useCallback(() => {
    OneModal.confirm({
      title: i18n.t('frame.hint'),
      content: i18n.t('setting.shipsetting.shipFail.addAccount'),
      okText: i18n.t('common.config.btn'),
      cancelText: i18n.t('frame.cancel'),
      onOk: () => {
        history.push('/setting/account')
      },
    })
  }, [history])

  const content = useCallback((isPickup) => {
    return (
      <>
        <p style={{ margin: '20px 0 10px' }}>{i18n.t(isPickup ? 'common.account.pickupSub' : 'common.account.dropoffSub')}</p>
        <OneInput onChange={(e) => (AccountRef.current.account = e.target.value)} />
      </>
    )
  }, [])
  ContentRef.current = content

  /**
   * sass服务商发货缺失子账号
   */
  const CreateSassSubAccountConfirm = useCallback((bizCode) => {
    const isPickup = bizCode === BizCodeEnum.CREATE_SASS_SUB_ACCOUNT_PICK_UP
    OneModal.confirm({
      title: i18n.t('setting.shipsetting.shipFail.addAccount'),
      content: ContentRef.current(isPickup),
      okText: i18n.t('frame.create'),
      cancelText: i18n.t('frame.cancel'),
      wrapClassName: 'account-input-modal',
      onOk: async () => {
        const params = {
          expressTypeCode: AccountRef.current.expressTypeCode,
          extraInfo: {
            [isPickup ? 'pickupMerchantId' : 'dropOffMerchantId']: AccountRef.current.account,
          },
        }
        await createSassSubAccount(params)
      },
    })
  }, [])
  /**
   * 合单发货快照过期
   */
  const MergeShipSnapshotExpireConfirm = useCallback(() => {
    if (hasMergeModalShow) return
    hasMergeModalShow = true
    OneModal.confirm({
      title: i18n.t('frame.hint'),
      centered: true,
      content: i18n.t('order.merge.expire'),
      okText: i18n.t('common.confirmBtn'),
      cancelText: i18n.t('order.create.cancel'),
      onOk: () => {
        history.push('/ship/order/list')
        hasMergeModalShow = false
        Modal.destroyAll()
      },
      onCancel: () => {
        hasMergeModalShow = false
      },
    })
  }, [history])

  /**
   * cash on delivery 权益不足提示
   */
  const SellerToBuyCashOnDeliveryCountConfirm = useCallback(() => {
    OneModal.confirm({
      title: i18n.t('frame.hint'),
      content: i18n.t('setting.deduct.comfirm'),
      okText: i18n.t('pay.deduct.buy'),
      cancelText: i18n.t('frame.cancel'),
      onOk: () => {
        history.push('/account/subscribe/add-benefit/os_freight_collect')
      },
    })
  }, [history])

  return {
    SellerToBuySaasConfirm,
    SellerToCreateSellerAccountConfirm,
    CreateSassSubAccountConfirm,
    MergeShipSnapshotExpireConfirm,
    SellerToBuyCashOnDeliveryCountConfirm,
  }
}
