export type CurrencyName = 'USD' | 'THB' | 'CNY' | 'HKD' | 'MYR' | 'VND' | 'TWD' | 'SGD' | 'IDR' | 'AUD' | 'JPY'

export type CurrencyType = {
  [K in keyof Record<CurrencyName, never>]: {
    key: K
    value: number
  }
}

/** 不能小数的币种 */
export const noDecimalsCurrency = ['TWD', 'VND', 'IDR', 'JPY']
