import type { ValuesType } from 'utility-types'
import type * as Types from '@/types'
import {
  DELIVERING,
  ERROR,
  LOGISTICS_STATUS_TRACKING_OPTION,
  ORDER_STATUS,
  ORDER_STATUS_LABEL,
  PROCESS,
  REACHED,
  RECEIVED,
  REJECTED,
  RETURNED,
  RETURNING,
  SHIPPING,
  TO_BE_DELIVERED,
  TO_PICK_UP,
} from './shipment'

const ALL = -1
// 运单状态
const TRACKING_WAYBILL_STATUS = ORDER_STATUS
const TRACKING_WAYBILL_STATUS_LABEL = ORDER_STATUS_LABEL
export type TWaybillStatus = ValuesType<typeof TRACKING_WAYBILL_STATUS>
const getWaybillTabs = (t): Types.Dict<{ value: TWaybillStatus[] }> => [
  {
    value: [],
    label: t(`enums.logisticStatus.${ALL}`),
  },
  {
    value: [TO_BE_DELIVERED],
    label: t(`enums.logisticStatus.${TO_BE_DELIVERED}`),
  },
  {
    value: [TO_PICK_UP],
    label: t(`enums.logisticStatus.${TO_PICK_UP}`),
  },
  {
    value: [PROCESS, SHIPPING, REACHED, DELIVERING],
    label: t(`enums.logisticStatus.${SHIPPING}`),
  },
  {
    value: [RECEIVED],
    label: t(`enums.logisticStatus.${RECEIVED}`),
  },
  {
    value: [ERROR, REJECTED, RETURNED, RETURNING],
    label: t(`enums.logisticStatus.${ERROR}`),
  },
]

const WAYBILL_STATUS_OPTION = LOGISTICS_STATUS_TRACKING_OPTION
const WAYBILL_STATUS_LABEL = ORDER_STATUS_LABEL

// 追踪状态
export const TRACKING_STATUS_VAlUE = {
  PLACED: 'PLACED',
  TRACK_FAILED: 'TRACK_FAILED',
  TRACK_SUCCESSED: 'TRACK_SUCCESSED',
  DEDUCT_FAILED: 'DEDUCT_FAILED',
  TRACK_ENDED: 'TRACK_ENDED',
} as const
export type TTRACKING_STATUS = ValuesType<typeof TRACKING_STATUS_VAlUE>

const TRACKING_STATUS = [
  {
    value: 'PLACED',
    label: 'enums.OStracingStatus.PLACED',
    color: 'warning',
  },
  {
    value: 'DEDUCT_FAILED',
    label: 'enums.OStracingStatus.DEDUCT_FAILED',
  },
  {
    value: 'TRACK_FAILED',
    label: 'enums.OStracingStatus.TRACK_FAILED',
    color: 'error',
  },
  {
    value: 'TRACK_SUCCESSED',
    label: 'enums.OStracingStatus.TRACK_SUCCESSED',
    color: 'succcess',
  },
  {
    value: 'TRACK_ENDED',
    label: 'enums.OStracingStatus.TRACK_ENDED',
    color: 'default',
  },
]

const transformKeyMap = (data: any[]) => {
  return data.reduce((result, item) => {
    return Object.assign(result, {
      [item.value]: item,
    })
  }, {})
}
const TRACKING_STATUS_LABEL = transformKeyMap(TRACKING_STATUS)

export { getWaybillTabs, TRACKING_STATUS, TRACKING_STATUS_LABEL, TRACKING_WAYBILL_STATUS, TRACKING_WAYBILL_STATUS_LABEL, WAYBILL_STATUS_LABEL, WAYBILL_STATUS_OPTION }
