// 优惠方案: 满赠
export enum PROMOTION_TYPE_ENUM {
  GIVE = 'premium_zon',
}

// 订阅sdk参数
export const SETTLE_MENT_SDK = {
  BIZTYPE: 'ONECLUB',
  SUB_BIZTYPE: 'ONESHIP',
}

export const SETTLE_MENT_SDK_ENV_MAP: Record<string, 'dev' | 'test' | 'prev' | 'prod'> = {
  develop: 'dev',
  test: 'test',
  preview: 'prev',
  product: 'prod',
}

export type PayMethodType = {
  payMethod: 'Wechat' | 'CreditCard'
  channelId: 'wechat' | 'stripe'
}

export const PAY_CHANNEL_LIST: PayMethodType[] = [
  { payMethod: 'Wechat', channelId: 'wechat' },
  { payMethod: 'CreditCard', channelId: 'stripe' },
]

/**
 * 币种符号
 * 补充
 */
export const COUNTRY_SYMBOL_MAP = {
  MYR: 'RM',
  THD: '฿',
}

export const WALLET_FLOW_MAX_DAYS = 180
