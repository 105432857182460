import { Modal } from 'antd'
import Cookies from 'js-cookie'
import { OneModal } from '@yy/one-ui'
import { logoutClears } from '@/containers/User/hook'
import i18n from '@/services/i18n'
import type { AppThunkDispatch } from '@/store'
import store from '@/store'
import { loginout } from '@/store/userSlice'

/** 检查用户是否被切换 */
function handleUserWarning() {
  const uid = store.getState().user?.info?.uid
  const oneshipName = store.getState().user?.info?.oneshipName
  const cookieUid = Cookies.get('osudb_uid')
  // need to warning user
  if (uid && uid !== cookieUid) {
    // avoid the user back and forth to triggle the visible of the web
    Modal.destroyAll()
    OneModal.confirm({
      centered: true,
      maskClosable: false,
      closable: false,
      content: i18n.t('login.warn.tips', {
        username: oneshipName,
      }),
      okText: i18n.t('login.relogin'),
      cancelText: i18n.t('common.confirmBtn'),
      cancelButtonProps: {
        style: {
          display: cookieUid ? 'inline-block' : 'none',
        },
      },
      onCancel: () => window.location.reload(),
      onOk: async () => {
        // login the other account state
        if (cookieUid) {
          await (store.dispatch as AppThunkDispatch)(loginout())
        }

        logoutClears()
      },
    })
    return true
  }
  return false
}

/**
 * 超管登录，检查商家是否被切换
 */
function handleAdminWarning() {
  const localToken = store.getState().user?.authorization
  const storageToken = Cookies.get('authorization')
  if (localToken !== storageToken) {
    Modal.warning({
      title: i18n.t('admin.login.warn.tips'),
      okText: i18n.t('admin.switchSeller'),
      onOk: async () => {
        logoutClears()
        window.location.reload()
      },
    })
  }
}

export function handleWindowVisiblity() {
  window.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      const localToken = store.getState().user?.authorization
      if (localToken) handleAdminWarning()
      else handleUserWarning()
    }
  })
}

handleWindowVisiblity()
