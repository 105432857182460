interface TagIconItem {
  shape: string
  color: string
  value: string
}
// 标签图标列表
export const TagIconList: TagIconItem[] = [
  {
    shape: 'triangle',
    color: 'red',
    value: 'red_triangle',
  },
  {
    shape: 'triangle',
    color: 'blue',
    value: 'blue_triangle',
  },
  {
    shape: 'triangle',
    color: 'yellow',
    value: 'yellow_triangle',
  },
  {
    shape: 'triangle',
    color: 'green',
    value: 'green_triangle',
  },
  {
    shape: 'triangle',
    color: 'purple',
    value: 'purple_triangle',
  },
  {
    shape: 'diamond',
    color: 'red',
    value: 'red_diamond',
  },
  {
    shape: 'diamond',
    color: 'blue',
    value: 'blue_diamond',
  },
  {
    shape: 'diamond',
    color: 'yellow',
    value: 'yellow_diamond',
  },
  {
    shape: 'diamond',
    color: 'green',
    value: 'green_diamond',
  },
  {
    shape: 'diamond',
    color: 'purple',
    value: 'purple_diamond',
  },
  {
    shape: 'pentagon',
    color: 'red',
    value: 'red_pentagon',
  },
  {
    shape: 'pentagon',
    color: 'blue',
    value: 'blue_pentagon',
  },
  {
    shape: 'pentagon',
    color: 'yellow',
    value: 'yellow_pentagon',
  },
  {
    shape: 'pentagon',
    color: 'green',
    value: 'green_pentagon',
  },
  {
    shape: 'pentagon',
    color: 'purple',
    value: 'purple_pentagon',
  },
]

export const ShapeColorLabelMap = {
  red: 'common.color.red',
  blue: 'common.color.blue',
  green: 'common.color.green',
  yellow: 'common.color.yellow',
  purple: 'common.color.purple',
  triangle: 'common.shape.triangle',
  diamond: 'common.shape.diamond',
  pentagon: 'common.shape.star',
}

// 标签操作类型
export const ACTION_TYPE = {
  // 新增标签
  CREATE: 'create',
  // 更新标签
  UPDATE: 'update',
  // 删除标签
  DELETE: 'delete',
}
