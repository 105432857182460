// 可await的命令式弹窗
import React from 'react'
import { OneModal } from '@yy/one-ui'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import I18n from '@/services/i18n'
import store from '@/store'

let modal: ReturnType<typeof OneModal.confirm> = null

/**
 * 可await的命令式弹窗，点击ok返回true，点击cancel返回false
 * 可以使用awaitConfirm.getModal() 获取当前弹窗实例
 * @param props
 * @returns
 */
export const awaitConfirm = ({
  throwError,
  ...props
}: Parameters<typeof OneModal.confirm>[0] & {
  throwError?: boolean
}) =>
  new Promise<boolean>((res, reject) => {
    let content = props.content
    if (content !== null && content !== undefined) {
      content = (
        <I18nextProvider i18n={I18n}>
          <Provider store={store}>{content}</Provider>
        </I18nextProvider>
      )
    }
    modal = OneModal.confirm({
      centered: true,
      ...props,
      content,
      onOk: async () => {
        modal = null
        const temp = await props.onOk?.()
        res(true)
        return temp
      },
      onCancel: async () => {
        modal = null
        const temp = await props.onCancel?.()
        if (throwError) {
          reject(false)
        } else {
          res(false)
        }
        return temp
      },
    })
  })

/**
 * 获取当前弹窗实例
 * @returns
 */
awaitConfirm.getModal = () => modal
