import store from '@/store'

/**
 * 是否是跨境
 * @param url
 * @todo
 * 可以统一成一种形式 `store.getState()`，
 * 否则开发跟正式不一致，容易造成混乱
 */
export const isCNWebsiteFn = (url?: string) =>
  process.env.NODE_ENV === 'production' ? /oneship\.cn$/.test(url || location.origin) : store.getState()?.user?.info?.countryCode === 'CN'

/**
 *
 * @param value
 * @returns
 */
export const decodeLanguageAndProvince = (value?: string) => {
  if (!value) return { province: undefined, language: undefined }

  if (!value.includes('_')) {
    return {
      province: value,
      language: undefined,
    }
  }

  const [language, province] = value?.split('_') || []

  return {
    language,
    province,
  }
}

/**
 *
 * @param value
 * @returns
 */
export const encodeLanguageAndProvince = (data: { province: string; languages?: string[] }) => {
  if (!data?.languages?.length || !data?.province) return data?.province || undefined

  const [language] = data?.languages || []

  return `${language}_${data.province}`
}
