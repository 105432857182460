import type { RouteConfig } from 'react-router-config'
import { User } from '@yy/one-icon'
import { withAsync } from '@/components/Async'
import Blank from '@/layouts/Blank'

const routes: RouteConfig[] = [
  // 订阅
  {
    alias: 'subscription',
    menuTitle: 'subscribe.config.title',
    icon: User,
    path: '/account/subscribe',
    component: Blank,
    permissionCode: 'subscribe',
    routes: [
      // 订阅页
      {
        path: '/',
        component: withAsync(() => import(/* webpackChunkName: "Subscribe" */ '@/containers/Subscribe/Config')),
        exact: true,
      },
      // 套餐列表页
      {
        path: '/package',
        hideMenu: true,
        component: withAsync(() => import(/* webpackChunkName: "Subscribe" */ '@/containers/Subscribe/Package')),
        exact: true,
      },
      // 账单
      {
        path: '/bills',
        component: withAsync(() => import(/* webpackChunkName: "Subscribe" */ '@/containers/Subscribe/Bills')),
        exact: true,
      },
      // 加购权益流量
      {
        path: '/add-benefit/:serviceKey',
        component: withAsync(() => import(/* webpackChunkName: "Subscribe" */ '@/containers/Subscribe/AddBenefit')),
        exact: true,
      },
      // 加购权益流量-账单支付
      {
        path: '/add-benefit-from-bills/:billId',
        component: withAsync(() => import(/* webpackChunkName: "Subscribe" */ '@/containers/Subscribe/AddBenefitFromBill')),
        exact: true,
      },
      // 升级套餐
      {
        path: '/buy-package/upgrade',
        component: withAsync(() => import(/* webpackChunkName: "Subscribe" */ '@/containers/Subscribe/BuyPackage')),
        exact: true,
      },
    ],
  },
]

export default routes
