// store通用工具函数
import store from '.'
import { upperFirst } from 'lodash'
import type { ValuesType } from 'utility-types'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { IsNever } from '@/types'
import type { RootState } from './rootReducer'

type KeysType<Keys> = IsNever<Keys> extends true ? string | number | bigint : Exclude<Keys, symbol>

type SetReducers<S, Keys extends keyof S> = Omit<
  {
    [K in keyof S as K extends KeysType<Keys> ? `set${Capitalize<`${K}`>}` : '']: (state: S, action: PayloadAction<S[K]>) => S
  },
  ''
>

/**
 * 生成通用的reducers setter
 * @param state
 * @param keys
 */
export const createSetReducers = <S, K extends (keyof S)[]>(state: S, ...keys: K) => {
  if (!keys.length) keys = Object.keys(state) as K
  const reducers = {} as SetReducers<S, ValuesType<K>>
  keys.forEach((key) => {
    reducers[`set${upperFirst(key as string)}`] = (state, action) => ({
      ...state,
      [key]: action.payload,
    })
  })
  return reducers
}

/**
 * thunk节流，使用相同参数调用，且上一次未执行完，则不会执行第二次
 * @param func
 */
export const thunkThrottle = () => {
  // TODO
}

/**
 * thunk函数防抖,本质上可以用来做缓存，例如设置1分钟，则1分钟时间内不会刷新数据
 * @param func
 */
export const thunkDebounce = <T extends (...args: any[]) => any>(func: T, wait = 2000): T => {
  let lastTime = 0
  return ((...args) => {
    const res = func(...args)
    // 如果时间没够，就return一个空action给dispatch
    if (Date.now() - lastTime <= wait) return {}
    lastTime = Date.now()
    return res
  }) as unknown as T
}

/**
 * 返回一个promise，等待store的值变更到符合条件，才resolve
 */
export function waitStore(predicate: (state: RootState) => boolean) {
  return new Promise<void>((resolve) => {
    if (predicate(store.getState())) resolve()
    else {
      const off = store.subscribe(() => {
        if (predicate(store.getState())) {
          off()
          resolve()
        }
      })
    }
  })
}
