import { debounce } from 'lodash'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Api from '@/services/http'
import type { AppDispatch } from '@/store'
import type * as Types from './types'

const initialState: Types.IInitialState = {
  providers: [],
}

// i17trackf服务商列表
const i17trackProviderSlice = createSlice({
  name: 'I17trackProvider',
  initialState,
  reducers: {
    setProviders(state: Types.IInitialState, action: PayloadAction<Types.TraceCarrierInfo[]>) {
      return {
        ...state,
        providers: action.payload,
      }
    },
  },
})

/** 获取服务商列表 */
export const fetchCarrierList = () =>
  debounce(async (dispatch: AppDispatch) => {
    const response = await Api.admin.get('/api/onetrace/carrier/list')
    dispatch(i17trackProviderSlice.actions.setProviders(response?.data?.carrierList || []))
    return response.data
  }, 100)

export default i17trackProviderSlice.reducer
