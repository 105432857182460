import type { FormInstance } from 'antd'
import { Modal } from 'antd'
import { uniqBy } from 'lodash'
import React, { useCallback, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { REF_ARRAY } from '@/constant'
import type { AddressArrivalMethodKeys } from '@/constant/arrivalMethod'
import { ARRIVAL_METHOD } from '@/constant/arrivalMethod'
import { fetchSupportMethods } from '@/services/apis'
import { useDispatch, useSelector } from '@/store'
import { setProps as setProviderProps, updateAddressVO, clearSelectedProvider } from '@/store/newServiceProviderSlice'
import {
  actions,
  fetchRecipientAddressExpress,
  fetchRecipientAddressStoreArea,
  getReceiveProvinceList,
  selectRecipientAddressCity,
  selectRecipientAddressCountry,
  selectRecipientAddressDistrict,
  selectRecipientAddressProvince,
  selectRecipientAddressStoreCity,
  selectRecipientAddressStoreCompany,
  selectRecipientAddressStoreProvince,
  selectRecipientAddressStoreStationType,
} from '@/store/recipientAddressSlice'
import type { FetchSupportMethodsParams, IRecipientAddressFormValues } from '@/store/recipientAddressSlice/types'
import type { ISenderAddressFormValues } from '@/store/senderAddressSlice/types'
import { isMapService } from './utils'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import type { LogisticsType } from '@/services/apis/sales/type'

export interface UseRecipientAddressFormOptions {
  logisticsType?: LogisticsType
}

export const useRecipientAddressForm = (form: FormInstance<ISenderAddressFormValues & IRecipientAddressFormValues>, options?: UseRecipientAddressFormOptions) => {
  const { logisticsType = 'express' } = options || {}
  const dispatch = useDispatch(form)
  const { t } = useTranslation()
  const [, forceRender] = useReducer((s) => s + 1, 0)
  const isLoading = useSelector((state) => state.recipientAddressForm.isLoading)
  const languageType = useSelector((state) => state?.language?.languageType)
  const filterArrivalCountries = useSelector((state) => state.dictionary?.filterArrivalCountries)

  /** 门店列表 */
  const stations = useSelector(
    (state) => {
      if (Array.isArray(state.recipientAddressForm.stationList)) {
        return uniqBy(state.recipientAddressForm.stationList, 'stationSeq')
      }

      return REF_ARRAY
    },
    (prev, curr) => {
      if (prev?.length !== curr?.length) {
        return false
      }

      const prevSeqs = prev.map((item) => item.stationSeq).join(',')
      const currSeqs = curr.map((item) => item.stationSeq).join(',')
      return prevSeqs === currSeqs
    }
  )

  /** 支持转成禁止 */
  const convertSupportedMethodsToDisabledMethods = useCallback((supportedMethods?: AddressArrivalMethodKeys[]): AddressArrivalMethodKeys[] => {
    const methodsMap: AddressArrivalMethodKeys[] = ['home', 'store']
    return methodsMap.filter((name) => -1 === supportedMethods.indexOf(name))
  }, [])

  // 重置数据
  const reset = useCallback(async () => {
    const { getFieldValue, getFieldsValue } = form
    const { arrival_countryCode, departure_countryCode } = getFieldsValue()
    const supportedMethods = await fetchSupportMethods({
      logisticsType: logisticsType,
      arrivalCountryCode: arrival_countryCode,
      departureCountryCode: departure_countryCode,
    })

    const disabledMethods = convertSupportedMethodsToDisabledMethods(supportedMethods)
    dispatch(actions.update({ supportedMethods, disabledMethods }))

    const countryCode = getFieldValue('arrival_countryCode')
    dispatch(getReceiveProvinceList({ countryCode }))
  }, [logisticsType, convertSupportedMethodsToDisabledMethods, form, dispatch])

  /** 更新数据 */
  const update = useCallback(
    async (params: Partial<FetchSupportMethodsParams>) => {
      const { setFields, getFieldsValue } = form
      const { arrival_method, departure_countryCode, arrival_countryCode } = getFieldsValue()
      const { departureCountryCode = departure_countryCode, arrivalCountryCode = arrival_countryCode } = params
      const supportedMethods = await fetchSupportMethods({
        departureCountryCode,
        arrivalCountryCode,
      })

      if (supportedMethods.length === 0) {
        const disabledMethods = convertSupportedMethodsToDisabledMethods([])
        dispatch(actions.update({ disabledMethods }))

        return true
      }

      /**
       * ****************************************
       * (注意) 下面有 confirm 判断
       * 请不要在上面 set value
       * ****************************************
       */

      const setValues = (values: Record<string, any>) => setFields(Object.keys(values).map((name) => ({ name, value: values[name] })))

      // 选择的是店配
      if (arrival_method === 2) {
        // 当前如果是店配，切换到了不支持店配的需要提示
        if (!supportedMethods.includes('store')) {
          try {
            await new Promise<void>((resolve, reject) => {
              Modal.confirm({
                title: t('address.form.confirmUnsupportedCountryCode'),
                icon: <ExclamationCircleOutlined />,
                content: t('address.form.confirmUnsupportedCountryCode'),
                okText: t('address.form.confirmUnsupportedCountryCodeConfirm'),
                cancelText: t('address.form.confirmUnsupportedCountryCodeCancel'),
                onOk: () => resolve(),
                onCancel: () => reject(),
                centered: true,
              })
            })

            const arrival_method = supportedMethods.length === 0 ? 0 : 1

            setValues({
              // 设置为0主要为了两个都不选
              arrival_method,
              // 切换成宅配也要清除地址数据
              arrival_store_companyCode: undefined,
              arrival_store_province: undefined,
              arrival_store_city: undefined,
              arrival_store_district: undefined,
              arrival_store_station: undefined,
              arrival_province: undefined,
              arrival_city: undefined,
              arrival_district: undefined,
            })

            dispatch(setProviderProps({ arrival_method }))
          } catch (error) {
            return false
          }
        } else {
          setValues({
            // 只有店配需要清除地址数据
            arrival_store_companyCode: undefined,
            arrival_store_province: undefined,
            arrival_store_city: undefined,
            arrival_store_district: undefined,
            arrival_store_station: null,

            arrival_province: undefined,
            arrival_city: undefined,
            arrival_district: undefined,
          })
        }
      } else {
        // 当前选择的是宅配 或者没有选
        if (supportedMethods.length === 2) {
          setValues({ arrival_method: 1 })
          dispatch(setProviderProps({ arrival_method: 1 }))
        } else if (supportedMethods.length === 1) {
          if (!supportedMethods.includes('home')) {
            setValues({ arrival_method: 2 })
            dispatch(setProviderProps({ arrival_method: 2 }))
          } else {
            setValues({ arrival_method: 1 })
            dispatch(setProviderProps({ arrival_method: 1 }))
          }
        } else {
          setValues({ arrival_method: 1 })
          dispatch(setProviderProps({ arrival_method: 1 }))
        }
      }

      // 设置不可选
      const disabledMethods = convertSupportedMethodsToDisabledMethods(supportedMethods)
      dispatch(actions.update({ disabledMethods }))

      return true
    },
    [t, form, convertSupportedMethodsToDisabledMethods, dispatch]
  )

  /** 数据修改回调 */
  const onRecipientAddressFormValuesChange = useCallback(
    async (changedValues: IRecipientAddressFormValues) => {
      const { setFieldsValue, getFieldValue, getFieldsValue } = form
      const changedKeys = Object.keys(changedValues)

      // 修改国家
      if (changedKeys.includes('arrival_countryCode')) {
        const { arrival_countryCode } = changedValues
        dispatch(clearSelectedProvider({ inputForm: form, clearForm: true }))
        await dispatch(selectRecipientAddressCountry({ arrival_countryCode }))
        forceRender()
      }

      // 修改省份
      if (changedKeys.includes('arrival_province')) {
        const { arrival_province } = changedValues
        dispatch(selectRecipientAddressProvince({ arrival_province }))
      }

      // 修改城市
      if (changedKeys.includes('arrival_city')) {
        const { arrival_city } = changedValues
        dispatch(selectRecipientAddressCity({ arrival_city }))
      }

      // 修改区
      if (changedKeys.includes('arrival_district')) {
        const { arrival_district } = changedValues
        dispatch(selectRecipientAddressDistrict({ arrival_district }))
      }

      // 修改详细地址或邮编
      const addressKey = ['arrival_address', 'arrival_addressTwo', 'arrival_zipCode', 'arrival_town', 'arrival_fullName', 'arrival_mobile'].find((key) => changedKeys.includes(key))
      if (addressKey) {
        dispatch(updateAddressVO({ [addressKey]: changedValues[addressKey] }))
      }

      // 修改服务商
      if (changedKeys.includes('arrival_store_companyCode')) {
        const { arrival_store_companyCode } = changedValues
        const { arrival_countryCode } = getFieldsValue()
        await dispatch(selectRecipientAddressStoreCompany({ companyCode: arrival_store_companyCode, countryCode: arrival_countryCode }))
        // 切换服务商，清空电子地图门店数据、黑猫温层数据
        dispatch(
          setProviderProps({
            companyCode: arrival_store_companyCode,
            supportStorageCdts: undefined,
            addressVO: {
              arrival_station_code: undefined,
              arrival_station_type: undefined,
            },
          })
        )
        setFieldsValue({
          arrival_store_711station: undefined,
          arrival_store_storage_cdt: undefined,
        })
      }

      // 修改门店类型
      if (changedKeys.includes('arrival_station_type')) {
        dispatch(selectRecipientAddressStoreStationType())
      }

      // 修改店配省
      if (changedKeys.includes('arrival_store_province')) {
        const { arrival_store_province } = changedValues
        const { arrival_store_companyCode } = getFieldsValue()

        await dispatch(selectRecipientAddressStoreProvince({ arrival_store_province, companyCode: arrival_store_companyCode }))

        dispatch(
          updateAddressVO({
            arrival_store_province,
            arrival_store_city: undefined,
          })
        )
      }

      // 修改店配市
      if (changedKeys.includes('arrival_store_city')) {
        const { arrival_store_city } = changedValues
        const { arrival_store_companyCode } = getFieldsValue()
        await dispatch(selectRecipientAddressStoreCity({ arrival_store_city, companyCode: arrival_store_companyCode }))

        dispatch(
          updateAddressVO({
            arrival_store_city,
            arrival_district: undefined,
          })
        )
      }

      // 修改店配区
      if (changedKeys.includes('arrival_store_district')) {
        setFieldsValue({
          arrival_store_station: undefined,
        })
      }

      // 修改门店
      if (changedKeys.includes('arrival_store_station')) {
        const arrival_store_station = getFieldValue('arrival_store_station')
        const station = stations.find((station) => station.stationSeq === arrival_store_station)
        const { stationType, province, city, district, stationCode } = station
        setFieldsValue({
          arrival_station_type: stationType,
          arrival_store_province: province,
          arrival_store_city: city,
          arrival_store_district: district,
        })

        dispatch(
          updateAddressVO({
            arrival_store_province: province,
            arrival_store_city: city,
            arrival_store_district: district,
            arrival_station_type: stationType,
            arrival_station_code: stationCode,
          })
        )
      }

      if (changedKeys.includes('arrival_store_companyCode') || changedKeys.includes('arrival_method')) {
        const { arrival_method, arrival_countryCode, arrival_store_companyCode } = getFieldsValue()

        if (arrival_countryCode && arrival_method == ARRIVAL_METHOD.store && !isMapService(arrival_store_companyCode) && arrival_store_companyCode) {
          // 电子地图不请求门店地址库
          await dispatch(fetchRecipientAddressStoreArea('province', { countryCode: arrival_countryCode, companyCode: arrival_store_companyCode })).catch(() => {
            // 加个catch 避免接口报错阻塞后面逻辑
          })
        }
      }

      // 获取服务商列表
      if (changedKeys.includes('arrival_countryCode') || changedKeys.includes('departure_countryCode') || changedKeys.includes('arrival_method')) {
        const { arrival_method, departure_countryCode, arrival_countryCode } = getFieldsValue()
        // 退货不会有店配，只有到店寄件，移除退货相关判断
        if (arrival_method === ARRIVAL_METHOD.store && departure_countryCode && arrival_countryCode) {
          dispatch(
            fetchRecipientAddressExpress({
              senderAddressName: {
                countryCode: departure_countryCode,
              },
              consigneeAddressName: {
                countryCode: arrival_countryCode,
              },
              needStationType: true,
            })
          )
        }
      }

      // 重置数据
      if (changedKeys.includes('arrival_method')) {
        const { arrival_method, arrival_store_companyCode } = getFieldsValue()
        reset()
        // 店配切宅配清理companyCode和温层，宅配切店配同步companyCode
        if (arrival_method !== ARRIVAL_METHOD.store) {
          dispatch(setProviderProps({ companyCode: undefined, supportStorageCdts: undefined }))
          setFieldsValue({
            arrival_store_storage_cdt: undefined,
          })
        } else {
          dispatch(setProviderProps({ companyCode: arrival_store_companyCode }))
        }
      }
    },
    [stations, reset, form, dispatch]
  )

  /** 这里只做刷新列表，请不要做任何其他更新表单清除列表等操作避免造成流程不可控 */
  useEffect(() => {
    ;(async () => {
      const countryCode = form.getFieldValue('arrival_countryCode')
      const countryLanguage = filterArrivalCountries.find((country) => country.countryCode === countryCode)?.language

      if (countryCode && languageType && countryLanguage) {
        await dispatch(
          getReceiveProvinceList({
            countryCode: countryCode,
            languageOrder: [languageType, 'en', countryLanguage].filter(Boolean),
          })
        )
      }
    })()
  }, [dispatch, form, filterArrivalCountries, languageType])

  return {
    isLoading,
    reset,
    update,
    onRecipientAddressFormValuesChange,
  }
}
