import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import Cookies from 'js-cookie'
import { cloneDeep, defaultsDeep } from 'lodash'
import type { Assign } from 'utility-types'
import { getLocalLang, getUrlParam } from '@/utils'
import { OneClubPathMap, replaceToOneClubUrl } from '@/utils/user'
import { compose } from './compose'
import { isCancelOrAbsorted } from './create'
import type { MapTakeLatest } from './extensions'
import { takeLatest } from './extensions'
import { adminSentryReport, hiidoReport, setTraceInfo, udbSentryReport } from './reports'
import { logger } from '@/utils/logger'

export * from './reports'
export * from './types'

/**
 * 因为拦截器将返回参数修正
 * 因此这里需重写部分域的调用函数,
 * 因为TS是声明式, 所以不建议通过JS来实现方法重写
 * 所以有以下重写方式
 */

/** 网关返回 data 数据格式 */
type GatwayData = {
  isSuccess: boolean
  code?: string
  message?: string
  extMap?: any
}

/** 网关返回 body 数据格式 */
type GatwayBody<T extends Record<string, any> = any> = {
  data: Assign<GatwayData, T>
  code: string
  message: string
  bizCode?: string
  bizMessage?: string
  timestamp?: string
  sign?: string
  signType?: string
  channel?: string
  api?: string
}

/** UDB返回 body 数据格式 */
type UdbBody<T extends Record<string, any> = any> = {
  data?: T
  rescode: string
  resmsg: string
  stoken?: string
}

/**
 * 日志中间件
 * @param interceptor
 */
const interceptorLoggerHandler = (interceptor: AxiosInstance['interceptors']) => {
  // interceptor.request.use(async (requestOptions: AxiosRequestConfig) => {
  //   logger.track({
  //     type: 'request',
  //     url: requestOptions.url,
  //     trace_id: requestOptions.headers?.['traceparent']?.split('-')?.[1],
  //     header: Object.keys(requestOptions.headers).reduce((p, key) => {
  //       if (typeof requestOptions.headers[key] !== 'object') p[key] = requestOptions.headers[key]
  //       return p
  //     }, {}),
  //     data: requestOptions.data,
  //   })
  //   return requestOptions
  // })
  interceptor.response.use((response: AxiosResponse) => {
    const { headers, config = {} } = response || {}
    logger.track({
      type: 'response',
      url: config.url,
      trace_id: headers?.['x-traceid'] || headers?.['x-trace-id'],
      tti: Date.now() - (config as any).traceInfo?.traceStart || 0,
      data: '',
    })
    return response
  })
}

export const handleInterceptor = (interceptor: AxiosInstance['interceptors']) => {
  interceptorLoggerHandler(interceptor)

  interceptor.request.use(async (requestOptions: AxiosRequestConfig) => {
    const finalOptions = cloneDeep(requestOptions)
    // 接入trace
    setTraceInfo(finalOptions)
    finalOptions.headers.device = 'ONE01'
    const authorization = Cookies.get('authorization')
    if (authorization) finalOptions.headers.authorization = authorization
    // 在外面获取会导致获取出来的是undefined，从而使得locallanguage 是en
    // 因为用户可能改设置，所以这里改成实时读
    const localLang = getLocalLang()
    finalOptions.data = defaultsDeep(finalOptions.data, { language: localLang, sourceSystem: 'ONESHIP' })
    // const behind = Math.random().toString(16).substr(2).padStart(19, '0')
    // finalOptions.headers['x-traceid'] = Date.now() + behind.substr(0, 19)
    return finalOptions
  })

  interceptor.response.use(
    (response: AxiosResponse) => {
      // trace 海度上报
      hiidoReport(response)

      /**
       * 以为 inteceptor.response.use 中 onRejection 无法
       * 在 then 抛出异常后立即执行, 因此会导致出现
       *
       * ```ts
       * ajax.then(...).catch(...)
       * // inteceptor.onRequest -> inteceptor.onInteceptor -> then -> Promise.reject -> catch
       * // 而非
       * // inteceptor.onRequest -> inteceptor.onInteceptor -> then -> Promise.reject -> inteceptor onRejection -> catch
       * // 因而导致忽略了 inteceptor onRejection
       * ```
       * @see https://github.com/axios/axios/blob/1025d1231a7747503188459dd5a6d1effdcea928/lib/adapters/xhr.js#L66
       * @see https://github.com/axios/axios/blob/1025d1231a7747503188459dd5a6d1effdcea928/lib/core/settle.js#L12
       */
      const result = (() => {
        const { data } = response
        if (typeof data === 'undefined') {
          return Promise.reject(new Error('Failed to obtain data'))
        }

        const { bizCode, config, bizMessage, message, code } = data
        if (bizCode === 'SUCCESS' || code === 'SUCCESS') {
          return data
        }

        if (bizCode === 'USER_NOT_VALID' || bizCode === 'COOKIE_EXPIRED' || code === 'LST-133242-B9001') {
          Cookies.remove('osudb_uid')
          Cookies.remove('osudb_appid')
          Cookies.remove('osudb_subappid')
          Cookies.remove('AQM_VERSION')
          Cookies.remove('authorization')
          replaceToOneClubUrl(OneClubPathMap.login, 'logout')
          return Promise.reject(new Error(bizCode || code))
        }
        // 没有权限
        if (bizCode === 'LST-123677-B0002') {
          const lang = getUrlParam('lang')
          // 无权限页多语言需要设置onclub的语言
          const search = lang ? `?lang=${lang}` : ''
          window.location.replace(`/authority${search}`)
          return Promise.reject(data)
        }
        // 过滤亚马逊上传文件的请求
        if (config && config?.url.indexOf('amazonaws') !== -1) {
          return data
        }

        if (bizCode === 'DATA_NOT_FOUND') {
          return {
            ...data,
            bizCode: 'SUCCESS',
            data: null,
          }
        }

        if (bizCode === 'COUNTRY_UNAUTHORIZED') {
          return Promise.reject(data)
        }

        return Promise.reject({
          ...data,
          message: bizMessage || message,
        })
      })()

      if (typeof result?.catch === 'function') {
        return result.catch((rejection: Error | Record<string, any>) => {
          adminSentryReport(rejection, response)
          return Promise.reject(rejection)
        })
      }

      return result
    },
    (rejection) => {
      if (isCancelOrAbsorted(rejection)) {
        return Promise.reject(rejection)
      }

      adminSentryReport(rejection, rejection?.response)
      return Promise.reject(rejection)
    }
  )
}

/** 最终 http 生成器 */
const generate = compose({
  admin: {
    interceptors(interceptor: AxiosInstance['interceptors']) {
      handleInterceptor(interceptor)
    },
    extensions: {
      takeLatest: takeLatest,
    },
  },
  udb: {
    interceptors(inteceptor: AxiosInstance['interceptors']) {
      interceptorLoggerHandler(inteceptor)
      inteceptor.response.use(
        (response: AxiosResponse) => {
          const { data } = response || {}
          const { rescode, resmsg } = data || {}

          // 成功
          if (rescode === '0') {
            return data
          }

          udbSentryReport(resmsg, response)
          return Promise.reject(data)
        },
        (rejection) => {
          if (isCancelOrAbsorted(rejection)) {
            return Promise.reject(rejection)
          }

          udbSentryReport(rejection, rejection?.response)
          return Promise.reject(rejection)
        }
      )
    },
  },
  toast: {
    interceptors(interceptor: AxiosInstance['interceptors']) {
      handleInterceptor(interceptor)
    },
    extensions: {
      takeLatest: takeLatest,
    },
  },
  /** 注意: 半天需求临时解决只有一个地方用到, 这个项目千万不要再给 TOAST 逻辑 谢谢 */
  noToast: {
    interceptors(interceptor: AxiosInstance['interceptors']) {
      handleInterceptor(interceptor)
    },
    extensions: {
      takeLatest: takeLatest,
    },
  },
})

type GenerateBody = {
  admin: GatwayBody<unknown>
  udb: UdbBody<unknown>
  toast: GatwayBody<unknown>
  noToast: GatwayBody<unknown>
}

type GenerateChains = {
  admin: ['data']
  udb: ['data']
  toast: ['data']
  noToast: ['data']
}

type GenerateExtends = MapTakeLatest<GenerateBody, GenerateChains>

/** 最终 http 实例 */
// prettier-ignore
const http = generate<GenerateBody, GenerateChains, GenerateExtends>()
export default http
