import React from 'react'
import { LoginAuthorized } from '../components/LoginAuthorized'

export const withAuthorized = <P extends Record<string, any>>(Component: React.FC<P>) => {
  const Authorized: React.FC<P> = (props) => {
    return (
      <LoginAuthorized>
        <Component {...props} />
      </LoginAuthorized>
    )
  }

  Authorized.displayName = 'Authorized'
  return Authorized
}
