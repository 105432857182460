import type { RouteConfig } from 'react-router-config'
import { withAddressSplitAlgorithmTrackPage } from '@/bizComponent/AddressAutoSplit/AddressSplitAlgorithmTrack'
import { withAsync } from '@/components/Async'
import { withAuthRedirect } from '@/components/Authorized/hoc/withAuthRedirect'
import Blank from '@/layouts/Blank'

const routes: RouteConfig[] = [
  {
    // 订单管理
    path: '/order',
    alias: 'order_management',
    component: withAuthRedirect(Blank, { first: '/ship/order/list' }),
    menuTitle: 'frame.order',
    group: 'shipments',
    permissionCode: 'ship:order',
    routes: [
      {
        path: '/create',
        menuTitle: 'order.create.title',
        component: withAddressSplitAlgorithmTrackPage(
          withAsync(() => import(/* webpackChunkName: "Order/Create" */ '@/containers/Order/CreateOrder')),
          'orderCreate'
        ),
        exact: true,
      },
      {
        path: '/ship',
        menuTitle: 'order.list.orderDetailShip',
        component: withAddressSplitAlgorithmTrackPage(
          withAsync(() => import(/* webpackChunkName: "Order/Ship" */ '@/containers/Order/ShipOrder')),
          'orderShip'
        ),
        exact: true,
      },
      {
        path: '/list',
        component: withAsync(() => import(/* webpackChunkName: "Order/List" */ '@/containers/Order/List')),
        menuTitle: 'frame.order',
        pageDescription: 'frame.order.desc',
        exact: true,
      },
      {
        path: '/detail/:id',
        menuTitle: 'detail',
        component: withAsync(() => import(/* webpackChunkName: "Logistics/Detail" */ '@/containers/Order/Detail')),
        exact: true,
      },
      {
        path: '/detail/:id/:appId',
        menuTitle: 'detail',
        component: withAsync(() => import(/* webpackChunkName: "Logistics/Detail" */ '@/containers/Order/Detail')),
        exact: true,
      },
      {
        path: '/quickship/',
        menuTitle: 'order.create.quickShip',
        component: withAsync(() => import(/* webpackChunkName: "Order/QuickShip" */ '@/containers/Order/QuickShip')),
        exact: true,
      },
      {
        path: '/batchCreateNew',
        menuTitle: 'order.create.newShipment',
        component: withAddressSplitAlgorithmTrackPage(
          withAsync(() => import(/* webpackChunkName: "Order/BatchCreate" */ '@/containers/Order/BatchImportOrders')),
          'orderCreate'
        ),
        exact: true,
      },
      // 编辑订单
      {
        path: '/edit',
        menuTitle: 'order.edit.titile',
        component: withAddressSplitAlgorithmTrackPage(
          withAsync(() => import(/* webpackChunkName: "Order/EditOrder" */ '@/containers/Order/EditOrder')),
          'orderEdit'
        ),
      },
      {
        path: '/batch',
        menuTitle: 'order.batch.list.title',
        component: withAsync(() => import(/* webpackChunkName: "Order/BatchShipList" */ '@/containers/Order/NewBatchShip/List')),
        exact: true,
      },
      {
        path: '/batch/edit',
        menuTitle: 'order.edit.titile',
        component: withAddressSplitAlgorithmTrackPage(
          withAsync(() => import(/* webpackChunkName: "Order/BatchShipList" */ '@/containers/Order/NewBatchShip/EditOrder/index')),
          'orderShip'
        ),
        exact: true,
      },
      {
        path: '/new-batch-merge',
        menuTitle: 'batch.merge.title',
        component: withAsync(() => import(/* webpackChunkName: "Order/BatchShipList" */ '@/containers/Order/NewBatchMergeShip/List/index')),
        exact: true,
      },
      {
        path: '/new-batch-merge/detail',
        menuTitle: 'batch.merge.title',
        component: withAddressSplitAlgorithmTrackPage(
          withAsync(() => import(/* webpackChunkName: "Order/BatchShipList" */ '@/containers/Order/NewBatchMergeShip/EditOrder/index')),
          'orderShip'
        ),
        exact: true,
      },
    ],
  },
]

export default routes
