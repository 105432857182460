import { useTranslation } from 'react-i18next'
import { LANGUAGE } from '@/constant'
import { useDispatch } from '@/store'
import { changeLanguage } from '@/store/languageSlice'

/**
 * 兼容已有访问用户 简中场景
 * SDK加载简中语言失败的问题
 * 将i18nextLng的“zh”改为“zh-CN”
 */
export function useLangPolyfill() {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const currentLang = window.localStorage?.getItem('i18nextLng')
  if (currentLang === 'zh') {
    const tartgetLang = LANGUAGE.find((l) => l.lang === 'zh-CN')
    dispatch(changeLanguage(tartgetLang))
    i18n.changeLanguage(tartgetLang.lang)
  }
}
