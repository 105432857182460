import classes from './index.module.less'
import { FormItem } from '@/bizComponent/AntdForm/DynamicFormItemGroup'
import NumberFormatInput from '@/bizComponent/NumberFormatInput'
import { OneInput, OneSelect } from '@yy/one-ui'
import type { Rule } from 'antd/lib/form'
import type { InputNumberProps } from 'antd/lib/input-number'
import c from 'classnames'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const { Option } = OneSelect

type AntInputSelectProps = InputNumberProps & {
  onChange?: () => void
  onCurrencyChange?: (val?: string) => void
  units: Array<string>
  unitPlaceholder?: string
  disableSelect?: boolean
  unitLan?: boolean
  disableInput?: boolean
  names: Array<string | number | (string | number)[]>
  rules?: Rule[]
}

const AntInputSelect: React.FC<AntInputSelectProps> = (props) => {
  const {
    units,
    unitPlaceholder,
    onChange,
    onCurrencyChange,
    disableSelect,
    disabled,
    size,
    unitLan = false, // 是否展示币种文案
    disableInput = false,
    style,
    names,
    value,
    rules,
    ...otherProps
  } = props

  const { t } = useTranslation()
  /** 选择货币 */
  const handleChange = useCallback(() => {
    onChange && onChange()
  }, [onChange])

  return (
    <OneInput.Group className={c(classes.inputWrapper, { [classes.disableInput]: disableInput })} style={style} compact>
      <FormItem name={names[0]} noStyle rules={rules} dependencies={[names[1]]}>
        <NumberFormatInput onChange={handleChange} className={classes.input} disabled={disabled || disableInput} size={size} {...otherProps} />
      </FormItem>
      {/* 只显示 */}

      {value ? (
        <OneSelect onSelect={onCurrencyChange} className={classes.unit} disabled={disabled || disableSelect} size={size} placeholder={unitPlaceholder} value={value}>
          {units?.map((item) => (
            <Option value={item} key={`currency_${item}`}>
              {item}
            </Option>
          ))}
        </OneSelect>
      ) : (
        <FormItem name={names[1]} noStyle>
          <OneSelect onSelect={onCurrencyChange} className={classes.unit} disabled={disabled || disableSelect} size={size} placeholder={unitPlaceholder}>
            {units?.map((item) => (
              <Option value={item} key={`currency_${item}`}>
                {unitLan ? t(`setting.currency.${item}`) + ' ' + item : item}
              </Option>
            ))}
          </OneSelect>
        </FormItem>
      )}
    </OneInput.Group>
  )
}

AntInputSelect.displayName = 'AntInputSelect'

export default AntInputSelect
