/* 返回码文档 https://shopline.yuque.com/sczv0i/zbwfmf/lg4txw */
/** 通用返回码 */
export const COMMON_FILTER_CODES = ['-1', '-2', '-3', '-4', '-5', '-6', '-13']

/** 登录流程返回码 */
export const LOGIN_FILTER_CODES = [
  '1001',
  '1002',
  '1003',
  '1004',
  '1005',
  '1006',
  '1007',
  '1008',
  '1009',
  '1011',
  '1013',
  '1014',
  '1015',
  '1016',
  '1017',
  '1018',
  '1019',
  '1020',
  '1021',
  '1022',
  '1023',
  '1024',
]

/** 注册流程返回码 */
export const REGISTER_FILTER_CODES = ['2001', '2002', '2003', '2004', '2005', '2006', '2009', '2010', '2011', '2014', '2015', '2016', '2019']

/** 安全中心流程返回码 */
export const SAFE_FILTER_CODES = ['3001', '3002', '3003', '3004', '3005', '3006', '3007', '3008', '3009', '3010', '3011', '3012', '3013', '3014', '3015', '3017', '3018', '3019']

export const FILTER_CODES = [...COMMON_FILTER_CODES, ...LOGIN_FILTER_CODES, ...REGISTER_FILTER_CODES, ...SAFE_FILTER_CODES]
