import { Card } from 'antd'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DATE_RANGE_OPTIONS, LOGISTICS_STATS_OPTIONS, TimeRangeTypeEnum } from '../../constants'
import styles from '../../index.module.less'
import type { ILogisticsStats } from '../../types'
import CustomSelect from '../CustomSelect'
import Stats from '../Stats'

interface ILogisticsCardProps {
  stats: ILogisticsStats
  selectChange: FnType
}

const LogisticsCard: React.FC<ILogisticsCardProps> = (props) => {
  const { stats, selectChange } = props
  const { t } = useTranslation()

  const [dateRange, setDateRange] = useState(30)

  const handleChange = useCallback(
    (value: number) => {
      setDateRange(value)
      selectChange(value, TimeRangeTypeEnum.LOGISTICS)
    },
    [selectChange]
  )

  return (
    <Card
      className={styles.logisticsCard}
      title={t('dashboard.logistics.label', { total: stats.total || 0 })}
      extra={<CustomSelect value={dateRange} options={DATE_RANGE_OPTIONS} onChange={handleChange} />}
    >
      <div className={styles.container}>
        {LOGISTICS_STATS_OPTIONS.map((item) => (
          <Stats key={item.valueKey} label={t(item.label)} value={stats[item.valueKey]} />
        ))}
      </div>
    </Card>
  )
}

LogisticsCard.displayName = 'LogisticsCard'
export default React.memo(LogisticsCard)
