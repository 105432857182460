import type React from 'react'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useUrlSearch } from '@/hooks'

export const Redirect: React.FC = () => {
  const history = useHistory()
  const query = useUrlSearch<{ href?: string; url?: string }>()
  const { href, url } = query || {}

  useEffect(() => {
    if (typeof href === 'string') {
      const target = decodeURIComponent(href)
      if (/^https?:\/\//.test(target)) {
        window.location.href = target
      }
    }
  }, [href])

  useEffect(() => {
    if (typeof url === 'string') {
      const target = decodeURIComponent(url)
      history.replace(target)
    }
  }, [url, history])

  return null
}

Redirect.displayName = 'Redirect'
