import Api from '@/services/http'
import type { PaginationType, WalletListType } from '@/store/walletSlice/types'
import { convertCurrency } from '@yy/one-currency'
import type * as Types from './type'

/** 获取预占流水列表 */
export const fetchPreemptList = async (params: PaginationType) => {
  const res = await Api.admin.post('/api/wallet/admin/order-detail/page-freeze', params)
  return res.data
}

/** 获取钱包流水列表 */
export const fetchWalletList = async (params: WalletListType & PaginationType) => {
  const res = await Api.admin.post<Types.FlowListRes>('/api/wallet/admin/order-detail/page', params)
  return res.data
}

/** 查询充值套餐信息 */
export const postRechargeInfo = (param: Types.RechargeInfoParams) => Api.admin.post<Types.RechargeInfoRes>('/api/crm/admin/package/pay/recharge_info', param)

/**
 * 查询套餐优惠
 * @description
 * 入参出参都为**元**
 */
export const fetchPackageFee = async (params: Types.FetchPackageFeeParams) => {
  const finalParams = convertCurrency(params).centify(['customPackageAmount'])
  const resp = await Api.admin.post<Types.FetchPackageFeeResp>('/api/crm/admin/package/pay/calculatePackageFee', finalParams)
  return convertCurrency(resp?.data).yuanify(['totalPayAmount', 'taxAmount', 'amount', 'couponAmount', 'amountNext', 'couponAmountNext'])
}

/** 查询内部支付状态 */
export const postPayStatus = (params: Types.GetPayStatusReq) => Api.admin.post<Types.GetPayStatusRes>('/api/crm/admin/subscription/pay/get-pay-status', params)

/**
 * 创建支付
 * @description
 * 入参为**元**
 */
export const postCreateWalletPay = (params: Types.PostCreateWalletPayParams) => {
  const finalParams = convertCurrency(params).centify(['customPackageAmount'])
  return Api.admin.post<Types.PayCreateRes>('/api/crm/admin/subscription/pay/create', finalParams)
}

/** 获取支付方式和对应的支付平台 */
export const getPayChannelList = (params: { countryCode: string; currencyCode: string }) => Api.admin.post('/api/crm/admin/pay/pay-channel-list', params)

/** 获取账单信息 */
export const getSettlement = (params: Types.GetSettlementParams) => Api.admin.post('/api/crm/admin/package/pay/settlement', params)

/** 创建订单 */
export const createPayBill = (params: Types.CreatePayBillParams) => Api.admin.post('/api/crm/admin/pay/create', params)

/** 导出钱包流水 */
export const postWalletFlowExport = (params: WalletListType & PaginationType) => Api.toast.post('/api/wallet/flow/export', params)

/** 退订/重新订阅 */
export const walletSubscribeSetting = (params: Types.SubscribeType) => Api.toast.post('/api/wallet/admin/setting/subscribe/setting', params)

/** 保存钱包余额监控设置 */
export const walletMonitorSettingSave = (params: Types.BalanceThresholdParams) => Api.toast.post('/api/wallet/admin/setting/monitor-save', params)

/** 查询钱包余额监控设置 */
export const walletMonitorSettingGet = () => Api.toast.post<{ walletMonitorSettingBos: Types.BalanceThresholdParams[] }>('/api/wallet/admin/setting/monitor-list')
