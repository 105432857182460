import { useBoolean } from 'ahooks'
import { differenceInDays } from 'date-fns'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { OnePrimaryButton, OneSecondaryButton } from '@yy/one-ui'
import { useSubscriptionStatus } from '@/hooks/subscribe/useSubscriptionStatus'
import { useSelector } from '@/store'
import { useStorePlanStatus } from './hook'
import styles from './index.module.less'
import type { IUniqStoreKey } from './type'

const getUniqPlanStatusKey = (uid: string) => `${uid}_plan_status`

// 没有订阅提示的页面
const WHITE_ROUTES = [
  '/account/subscribe/package',
  '/account/subscribe/add-benefit-from-bills',
  '/account/subscribe/buy-package/upgrade',
  '/account/subscribe/add-benefit/os_saas_order',
  '/account/subscribe/add-benefit/os_tracking_waybill',
  '/account/subscribe/add-benefit/os_sms_meg',
]
const AlertHeader: React.FC = () => {
  const { t } = useTranslation()
  const [isShow, { setFalse: onHideTip, setTrue: onShowTip }] = useBoolean(true)
  const location = useLocation()
  const uid = useSelector((state) => state.user?.info?.uid)
  const { statusData, toPlanPage, isTrial, isExpired, isFreeAndUnactive } = useSubscriptionStatus()

  const { getStorePlanStatus } = useStorePlanStatus()

  const contentText = useMemo(() => {
    const noTipRoute = WHITE_ROUTES.includes(location.pathname)
    if (noTipRoute) {
      onHideTip()
      return null
    }

    if (isTrial && !getStorePlanStatus('trial')) {
      onShowTip()
      const diffDays = differenceInDays(statusData.subscription?.endAt, new Date())
      return t('subscribe.alertHeader.probationPeriod', { day: diffDays })
    }
    if (isExpired && !getStorePlanStatus('expired')) {
      onShowTip()
      return t('subscribe.alertHeader.gracePeriod')
    }
    if (isFreeAndUnactive && !getStorePlanStatus('freeAndUnactive')) {
      onShowTip()
      return t('subscribe.alertHeader.expired')
    }
    onHideTip()
  }, [getStorePlanStatus, isExpired, isFreeAndUnactive, isTrial, location.pathname, onHideTip, onShowTip, statusData.subscription?.endAt, t])

  const toBookPackage = () => {
    toPlanPage()
  }

  const onNotRemind = () => {
    const uniqPlanStatusKey = getUniqPlanStatusKey(uid)
    let storePlanData: IUniqStoreKey = null
    try {
      storePlanData = JSON.parse(localStorage.getItem(uniqPlanStatusKey)) || {}
    } catch {
      storePlanData = {}
    }

    if (isTrial) {
      storePlanData.trial = true
    } else if (isExpired) {
      storePlanData.expired = true
    } else if (isFreeAndUnactive) {
      storePlanData.freeAndUnactive = true
    }
    localStorage.setItem(uniqPlanStatusKey, JSON.stringify(storePlanData))
    onHideTip()
  }

  if (!isShow) {
    return null
  }
  return (
    <div className={styles.headerAlert}>
      <span className={styles.text}>{contentText}</span>
      <OnePrimaryButton onClick={toBookPackage} className={styles.confirmBtn}>
        {t('subscribe.alertHeader.buyPackage')}
      </OnePrimaryButton>
      <OneSecondaryButton className={styles.cancelBtn} onClick={onNotRemind}>
        {t('subscribe.alertHeader.notRemind')}
      </OneSecondaryButton>
    </div>
  )
}

export default AlertHeader
