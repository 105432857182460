import type * as Types from '@/types'
import { getRecentDay } from '@/utils/getRecentDay'
import options2Map from '@/utils/options2Map'

export type DateSelect = 'all' | 1 | 3 | 7 | 30

export const DATE_SELECT: Types.Dict<{ value: DateSelect; timeRange?: [number, number] }> = [
  {
    label: 'order.list.allTime',
    value: 'all',
  },
  {
    label: 'order.list.typeRangeLast1Day',
    value: 1,
    timeRange: getRecentDay(1),
  },
  {
    label: 'order.list.typeRangeLast3Day',
    value: 3,
    timeRange: getRecentDay(3),
  },
  {
    label: 'order.list.typeRangeLast7Day',
    value: 7,
    timeRange: getRecentDay(7),
  },
  {
    label: 'order.list.typeRangeLast30Day',
    value: 30,
    timeRange: getRecentDay(30),
  },
]

export const SELECT_DATE_TYPE: Types.Dict<{ value: number }> = [
  {
    label: 'logistics.list.orderListCreated',
    value: 1,
  },
  {
    label: 'logistics.list.pickupTime',
    value: 4,
  },
  {
    label: 'logistics.list.actualPickupTime',
    value: 3,
  },
  {
    label: 'logistics.list.signForTime',
    value: 2,
  },
]

export const SELECT_DATE_TYPE_MAP = options2Map(SELECT_DATE_TYPE, 'value', 'label')

/**
 * 天数对应的时间戳范围
 */
export const DATE_SELECT_TIME_RANGE_MAP = options2Map<[number, number]>(
  DATE_SELECT.filter((item) => item.timeRange),
  'value',
  'timeRange'
)
