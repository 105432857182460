import type * as Types from '@/types'
import options2Map from '@/utils/options2Map'

export const QUERY_WORD_TYPE = 'queryWord'
export const NUMBER_LIST_TYPE = 'numberList'
export const QUERY_FiELD_TYPE = 'queryField'
export const CONSIGNEE_MOBILE_TYPE = 'consigneeMobile'

export const ORDER_LIST_SEARCH_TYPES = {
  /** 关键词 */
  QUERY_WORD_TYPE: QUERY_WORD_TYPE,
  /** 多单号 */
  NUMBER_LIST_TYPE: NUMBER_LIST_TYPE,
  QUERY_FiELD_TYPE: QUERY_WORD_TYPE,
  /** 手机号码 */
  CONSIGNEE_MOBILE_TYPE: CONSIGNEE_MOBILE_TYPE,
}

export const orderSearchType: Types.Dict = [
  {
    label: 'logistics.list.queryWord',
    value: QUERY_WORD_TYPE,
  },
  {
    label: 'logistics.list.numberList',
    value: NUMBER_LIST_TYPE,
  },
  {
    label: 'logistics.list.queryField',
    value: QUERY_FiELD_TYPE,
  },
  {
    label: 'logistics.list.consigneeMobile',
    value: CONSIGNEE_MOBILE_TYPE,
  },
]

export const orderSearchTypeMap = options2Map(orderSearchType, 'value', 'label')

export const oneshipOrderSearchType: Types.Dict = [
  {
    label: 'order.list.queryWord',
    value: QUERY_WORD_TYPE,
  },
  {
    label: 'order.list.numberList',
    value: NUMBER_LIST_TYPE,
  },
  {
    label: 'order.list.consigneeMobile',
    value: CONSIGNEE_MOBILE_TYPE,
  },
]

export const oneshipOrderSearchTypeMap = options2Map(oneshipOrderSearchType, 'value', 'label')
