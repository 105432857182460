import { Card } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { OneButton } from '@yy/one-ui'
import PermissionControl from '@/bizComponent/PermissionControl'
import { useCNSign } from '@/hooks/useCNSign'
import styles from '../../index.module.less'
import classes from './index.module.less'

const HeaderCard: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const checkServiceApply = useCNSign()

  const handleClick = async (batch?: boolean) => {
    const pass = await checkServiceApply()
    if (!pass) return
    history.push(batch ? '/ship/order/batchCreateNew' : '/ship/order/create')
  }

  return (
    <Card className={styles.headerCard}>
      <div className={styles.left}>
        <div className={classes.titleWrap}>
          <div className={classes.title}>{t('dashboard.createOrder')}</div>
          <div className={classes.subTitle}>{t('dashboard.create.description')}</div>
        </div>
        <PermissionControl permissionCode="ship:order:create">
          <div>
            <OneButton size="middle" onClick={() => handleClick()}>
              {t('order.create.order')}
            </OneButton>
            <OneButton className={classes.rightButton} size="middle" onClick={() => handleClick(true)}>
              {t('order.create.batchOrder')}
            </OneButton>
          </div>
        </PermissionControl>
      </div>
    </Card>
  )
}

HeaderCard.displayName = 'HeaderCard'
export default React.memo(HeaderCard)
