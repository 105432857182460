/** 套餐类型 */
export const SUB_TYPES = {
  /**试用 */
  trial: 'trial',
  /**付费 */
  paid: 'paid',
}

/**套餐状态*/
export const SUB_STATUS = {
  /**未支付 */
  pending: 'pending',
  /**生效中 */
  active: 'active',
  /**过期 */
  expired: 'expired',
  /**失效 */
  unactive: 'unactive',
  /**取消 */
  cancelled: 'cancelled',
}

/** 绑卡状态 */
export const BIND_STATUS = {
  /**INIT */
  INIT: 0,
  /**PROCESSING */
  PROCESSING: 100,
  /**SUCCEEDED */
  SUCCEEDED: 200,
  /**FAILED */
  FAILED: 300,
  /**EXPIRED */
  EXPIRED: 400,
  /**UN_BIND */
  UN_BIND: 500,
  /**UN_KNOW */
  UN_KNOW: 600,
}

/** 期限类型 */
export const DURATION_TYPES = {
  /**月付 */
  monthly: 'monthly',
  /**年付 */
  year: 'year',
}

/** serviceKey类型 */
export const SERVICE_KEYS = {
  os_sms_meg: 'os_sms_meg',
  os_tracking_waybill: 'os_tracking_waybill',
  os_saas_order: 'os_saas_order',
  os_freight_collect: 'os_freight_collect',
  os_return: 'os_return',
}

export const PRODUCT_LINE = {
  /**serviceKey: productLine */
  os_dy_free: 'os_dy_free',
  os_dy_plus: 'os_dy_plus',
  os_dy_pro: 'os_dy_pro',

  os_sms_meg: 'os_sms_meg',
  os_tracking_waybill: 'os_tracking_waybill',
  os_saas_order: 'os_saas_order',
  os_freight_collect: 'os_freight_collect',
  os_return: 'os_return',
}

// 套餐等级
export const PRODUCT_LEVEL = {
  os_dy_free: 1,
  os_dy_plus: 2,
  os_dy_pro: 3,
}

export const SERVICE_TYPES = {
  /**订阅类 */
  SUB: 1,
  /**服务类 */
  SERVICE: 2,
}
