import type { FormInstance } from 'antd'
import { useCallback, useState } from 'react'
import { decodeLanguageAndProvince } from '@/utils/address'
import { getShipInfoFromCache } from '../useShip'
import type { IValitorProviderProps } from '@/services/apis/express/type'
import { validatorExpressFields } from '@/services/apis'
import i18n from '@/services/i18n'
import STORAGE_LIST from '@/constant/temperature'
import { ARRIVAL_METHOD_LIST_STRING_VERSION } from '@/constant/arrivalMethod'
import { awaitConfirm } from '@/components/ModalConfirmWithPromise'
import { debounce } from 'lodash'

/**
 * 校验收发地址
 * @param form
 * @returns
 */
export function useVerifyProviderFn(
  form: FormInstance,
  options?: {
    updateLoading?: (state: boolean) => void
  }
) {
  const [expressTypeCode, setExpressTypeCode] = useState(getShipInfoFromCache()?.expressTypeCode)
  const { updateLoading } = options || {}

  const verify = useCallback(async () => {
    if (!expressTypeCode) {
      return Promise.resolve([])
    }

    try {
      const value = form.getFieldsValue()

      const { departure_countryCode, arrival_countryCode } = value

      const payload: IValitorProviderProps = {
        expressTypeCode,
        address: {
          senderCountry: departure_countryCode || undefined,
          senderProvince: decodeLanguageAndProvince(value.departure_province)?.province || undefined,
          senderCity: value.departure_city || undefined,
          senderDistrict: value.departure_district || undefined,
          senderTown: value.departure_town || undefined,

          consigneeStationCode: value?.arrival_store_711station?.storeId,
          consigneeCountry: arrival_countryCode || undefined,
          consigneeProvince: decodeLanguageAndProvince(value.arrival_province)?.province || undefined,
          consigneeCity: value.arrival_city || undefined,
          consigneeDistrict: value.arrival_district || undefined,
          consigneeTown: value.arrival_town || undefined,

          consigneeStationCompanyCode: value.arrival_store_companyCode || undefined,
          consigneeStationSeq: value?.arrival_store_station || undefined,
        },
        extraInfo: {
          storageCdt: value?.arrival_store_storage_cdt || undefined,
          deliveryType: Number(value.arrival_method) === 1 ? 1 : 4,
        },
      }

      updateLoading?.(true)

      const res = await validatorExpressFields(payload)

      const validResults = [].concat(res.data.validResults || [])

      if (!validResults?.length) {
        return Promise.resolve([])
      }

      const fields = validResults.map((result) => {
        const key = result.fieldNames[0]
        const item = KEY_HELPER_MAP[key]

        const name = item.key

        const targetValue = form.getFieldValue(name)

        const error =
          i18n.t(item?.message) ||
          item?.messageCreator?.({
            value: targetValue,
            messageValues: result.messageValues,
          }) ||
          ''

        const errors = [error]

        return {
          name,
          value: targetValue,
          errors,
        }
      })

      return Promise.resolve(fields)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      return Promise.resolve([])
    } finally {
      updateLoading?.(false)
    }
  }, [expressTypeCode, form, updateLoading])

  /* 校验地址表单数据 */
  const verifyProviderFileds = useCallback(async () => {
    /** if it don't have the expressTypeCode here, return true directly */
    const fields = await verify()

    if (!fields?.length) {
      return Promise.resolve(true)
    }

    const result = await awaitConfirm({
      content: i18n.t('provider.performance.confirm.title'),
      cancelText: i18n.t('provider.performance.confirm.cancel'),
      okText: i18n.t('dashbord.guide.continue'),
    })

    if (result) {
      /** clear the expressTypeCode */
      setExpressTypeCode('')
      return Promise.resolve(true)
    } else {
      // if user want to correct the files of the form, set the error fields to form
      form.setFields(fields)
      return Promise.reject(false)
    }
  }, [verify, form])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceVerifyProviderFileds = useCallback(
    debounce(async () => {
      const fields = await verify()

      form.setFields(fields)
    }, 500),
    [verify, form]
  )

  const clearExpressTypeCode = useCallback(() => {
    setExpressTypeCode('')
  }, [])

  return { verifyProviderFileds, clearExpressTypeCode, debounceVerifyProviderFileds }
}

type IMessageCreator = (data: {
  /** form value */
  value: any
  /** iligal values */
  messageValues: []
}) => string

const normalCreatetor: IMessageCreator = (data) => {
  const { value } = data
  return i18n.t('provider.performance.unsupported', { key: value })
}

const provincelCreatetor: IMessageCreator = (data) => {
  const { value } = data
  const { province } = decodeLanguageAndProvince(value)
  return i18n.t('provider.performance.unsupported', { key: province })
}

const convertListToMap = (list: Array<{ value: any; label: string }>) => {
  return list.reduce((acc, next) => {
    acc[next.value] = next.label
    return acc
  }, {} as Record<string, string>)
}

const normalOnlaySupportedCreatetor = (list?: Array<{ value: any; label: string }>) => (data) => {
  const map = convertListToMap(list)

  const { messageValues } = data
  const messages = messageValues.map((value) => {
    return i18n.t(map[value])
  })

  return i18n.t('provider.performance.supported', { key: messages.join(',') })
}

export const KEY_HELPER_MAP = {
  senderCountry: {
    key: 'departure_countryCode',
    messageCreator: normalCreatetor,
  },
  senderProvince: {
    key: 'departure_province',
    messageCreator: provincelCreatetor,
  },
  senderCity: {
    key: 'departure_city',
    messageCreator: normalCreatetor,
  },
  senderDistrict: {
    key: 'departure_district',
    messageCreator: normalCreatetor,
  },
  senderTown: {
    key: 'departure_town',
    messageCreator: normalCreatetor,
  },
  consigneeCountry: {
    key: 'arrival_countryCode',
    messageCreator: normalCreatetor,
  },
  consigneeProvince: {
    key: 'arrival_province',
    messageCreator: provincelCreatetor,
  },
  consigneeCity: {
    key: 'arrival_city',
    messageCreator: normalCreatetor,
  },
  consigneeDistrict: {
    key: 'arrival_district',
    messageCreator: normalCreatetor,
  },
  consigneeTown: {
    key: 'arrival_town',
    messageCreator: normalCreatetor,
  },
  consigneeStationCompanyCode: {
    key: 'arrival_store_companyCode',
    message: 'provider.performance.company.nomatch',
  },
  consigneeStationSeq: {
    key: 'arrival_method',
    messageCreator: normalOnlaySupportedCreatetor(ARRIVAL_METHOD_LIST_STRING_VERSION),
  },
  deliveryType: {
    key: 'arrival_method',
    messageCreator: normalOnlaySupportedCreatetor(ARRIVAL_METHOD_LIST_STRING_VERSION),
  },
  storageCdt: {
    key: 'arrival_store_storage_cdt',
    messageCreator: normalOnlaySupportedCreatetor(STORAGE_LIST),
  },
} as Record<
  string,
  {
    key: string
    messageCreator?: IMessageCreator
    message?: string
  }
>
