import { Row } from 'antd'
import c from 'classnames'
import React from 'react'
import Breadcrumb from '../Breadcrumb'
import styles from './index.module.less'

interface AppBarProps {
  BreadCrumb?: React.ReactElement
  className?: string
  hasBread?: boolean
}

const AppBar: React.FC<AppBarProps> = (props) => {
  const { children, BreadCrumb, className, hasBread = true } = props

  return (
    <>
      {hasBread ? (
        <Row align="middle" justify="space-between" className={c(styles.appBar, className)}>
          {BreadCrumb ? React.cloneElement(BreadCrumb) : <Breadcrumb />}
          {children}
        </Row>
      ) : null}
    </>
  )
}

AppBar.displayName = 'AppBar'
export default AppBar
