import { eatError } from '@/utils/function'
import { configure } from './configure'
import type { EVENT_ID_NAME_LIST } from './constants'
import type { TrackBasicParams, TrackEventParams } from './types'

export type { TrackBasicParams, TrackEventParams }

let jdpTracker = undefined
export const getTracker = async () => {
  if (!jdpTracker) {
    const { tracker } = await import('@yy/jdp-tracker')
    tracker.setOptions({ useHttps: process.env.NODE_ENV === 'production' })
    tracker.initInstance()
    jdpTracker = tracker
  }

  return jdpTracker
}

export const track = eatError(async <T extends keyof typeof EVENT_ID_NAME_LIST>(type: T, data: TrackEventParams[T]) => {
  const tracker = await getTracker()
  const withPayload = configure(type)
  const payload = withPayload(data)
  // report 所需参数跟我们上报的格式不一致
  tracker.report(payload as any)
})
