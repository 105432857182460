/**
 * 是否购买保险
 */
export const INSURANCE_TYPE = {
  YES: 1,
  NO: 2,
}

/**
 * 服务产品是否支持保险
 */
export const INSURANCE_SUPPORT_TYPE = {
  YES: 1,
  NO: 0,
}

export const INSURANCE_TYPE_OPTIONS = [
  {
    label: 'component.serviceProvider.codYes',
    value: INSURANCE_TYPE.YES,
  },
  {
    label: 'component.serviceProvider.codNo',
    value: INSURANCE_TYPE.NO,
  },
]

export const INSURANCE_TYPE_SEARCH_OPTIONS = [
  {
    label: 'insurance.buy.yes',
    value: true,
  },
  {
    label: 'insurance.buy.no',
    value: false,
  },
]
