import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next, setI18n } from 'react-i18next'
import { LANGUAGE } from '@/constant'
import store from '@/store'
import { setLanguage } from '@/store/languageSlice'
import { changedBrand, getUrlParam } from '@/utils'

const I18N_URL = process.env.I18N_URL
const COMMON_LANG_URL = process.env.COMMON_LANG_URL
let initState: 'pending' | 'inited' = 'pending'

const assignFetch = async (params: { url: string; nameSpace: string }[]) => {
  const datas = await Promise.all(params.map(({ url }) => fetch(url).then((res) => res.json())))
  return datas
}
/** 加载完初始化语言 */
const resolveObj = { res: null, ret: null }
export const i18nInitResourcePromise = new Promise((res, ret) => {
  resolveObj.res = res
  resolveObj.ret = ret
})
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: function (lngs: string[]) {
        const lng = lngs[0]
        const search = `?lng=${lng}&hash=${process.env.GIT_COMMIT_HASH}`
        if (lng === 'zh' || lng === 'zh-CN') {
          return `${I18N_URL}/zh-hans-cn.json${search}`
        }
        if (lng === 'zh-TW') {
          return `${I18N_URL}/zh-hant-tw.json${search}`
        }
        if (lng === 'zh-HK') {
          return `${I18N_URL}/zh-hant-hk.json${search}`
        }
        return `${I18N_URL}/{{lng}}.json${search}`
      },
      request: async (options, url: string, payload, callback) => {
        const commonUrl = url.replace(I18N_URL, COMMON_LANG_URL)
        const urls = [
          { url, nameSpace: 'translation' },
          { url: commonUrl, nameSpace: 'common' },
        ]
        if (initState === 'inited') {
          // 如果i18n已初始化，则直接请求
          const datas = await assignFetch(urls)
          urls.forEach((config, index) => {
            i18n.addResources(getUrlParam('lng', url), config.nameSpace, changedBrand(datas[index]))
          })
        } else {
          const lng = getUrlParam('lng', url)
          // 如果i18n未初始化，则先返回空数据，请求到之后再加上
          assignFetch(urls)
            .then((datas) => {
              if (lng === store.getState().language.lang) {
                initState = 'inited'
                resolveObj.res()
              }
              urls.forEach((config, index) => {
                i18n.addResourceBundle(lng, config.nameSpace, changedBrand(datas[index]))
              })
            })
            .catch((err) => resolveObj.ret(err))
        }
        callback(null, { status: 200 })
      },
    },
    // 优先使用上一次加载过的，没有才 en
    fallbackLng: window.localStorage.i18nextLng || 'en',
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    supportedLngs: ['en', 'zh', 'zh-TW', 'zh-HK', 'zh-CN', , 'ja'],
    keySeparator: '',
    react: {
      bindI18nStore: 'added',
    },
    load: 'currentOnly',
    initImmediate: false,
  })
  .then(() => {
    const localLang = LANGUAGE.filter((item) => item.lang === window.localStorage.i18nextLng)
    setI18n(i18n) // 保证react-i18next内i18n实例一致
    store.dispatch(
      setLanguage(
        localLang[0] || {
          label: 'English',
          lang: 'en',
          languageType: 'en',
          antd: 'en_US',
        }
      )
    )
  })

export const noLang = {
  label: '显示多语言key',
  lang: 'nokey',
  languageType: 'nokey' as 'en',
  antd: 'nokey_NO',
}

export const canShowI18nKey = process.env.APP_ENV !== 'product'
if (canShowI18nKey) {
  const t = i18n.t
  i18n.t = (...args) => (i18n.language === noLang.lang ? args[0] : t.call(i18n, ...args))
}

export const LanguageChangedEvents: Set<(language: string) => void> = new Set()

export const onLanguageChanged = (fn: (language: string) => void) => {
  LanguageChangedEvents.add(fn)
  return () => {
    LanguageChangedEvents.delete(fn)
  }
}

export default i18n
