import { PaymentTypeEnum } from '@/constant'
import { windowOpen } from './browser'
import { format } from 'date-fns'

export const delay = (ms: number): Promise<any> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const randomSvgId = () => {
  const id = 'svg' + (Math.random() + '').replace('.', '')
  return {
    id,
    urlId: `url(#${id})`,
  }
}

// 订单默认搜索tab数量
export const orderDefaultTabNum = 7
// 运单默认搜索tab数量
export const logisticsDefaultTabNum = 8
// 即时物流-订单默认搜索tab数量
export const immediateOrderDefaultTabNum = 4
// 即时物流-运单默认搜索tab数量
export const immediateLogisticsDefaultTabNum = 7
// 逆向物流-运单默认搜索tab数量
export const reverseLogisticsDefaultTabNum = 8

/** 是否订单归档Tab */
export const isOrderArchive = (tabValue: number) => tabValue === 6

/** 根据当前订单tabValue返回当前处于自定义选项卡配置中的index */
export const orderCustomTabIndex = (tabValue: number) => tabValue - orderDefaultTabNum
/** 根据当前运单tabValue返回当前处于自定义选项卡配置中的index */
export const logisticsCustomTabIndex = (tabValue: number) => tabValue - logisticsDefaultTabNum
/** 根据当前订单tabValue返回当前处于自定义选项卡配置中的index */
export const immediateOrderCustomTabIndex = (tabValue: number) => tabValue - immediateOrderDefaultTabNum
/** 根据当前运单tabValue返回当前处于自定义选项卡配置中的index */
export const immediateLogisticsCustomTabIndex = (tabValue: number) => tabValue - immediateLogisticsDefaultTabNum
/** 根据当前退货单tabValue返回当前处于自定义选项卡配置中的index */
export const reverseLogisticsCustomIndex = (tabValue: number) => tabValue - reverseLogisticsDefaultTabNum
/** 是否运单归档Tab */
export const isLogisticsArchive = (tabValue: number) => tabValue === 7

export const getUnitCode = () => {
  return Date.now().toString(16)
}

/**
 * @param time 毫秒 默认2000
 * @returns
 */
export const sleep = (time = 2000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null)
    }, time)
  })
}

/**
 * @param prefix 唯一ID前缀
 * @returns
 */
export const uuid = (prefix = '') => prefix + Math.random().toString(36).slice(-8)

/**
 * @desc 去除对象中值为空的属性
 * @param obj 对象值
 * @returns
 */
export const objectShaking = (obj) => {
  const ret: {
    [key: string]: any
  } = {}
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'boolean' || Boolean(value)) {
      ret[key] = value
    }
  })
  return ret
}

/**
 * @desc 新开标签页的形式跳转新链接
 * @param url 要跳转的链接
 * @returns
 */
export const jumpHandler = (url: string) => {
  url && windowOpen(url, '_blank')
}

/**
 * @desc 将域名转换成可访问的链接
 * @param domain 域名
 * @returns  url-域名对应的链接
 */
export const domainToUrl = (domain: string) => {
  return `https://${domain}`
}

/**
 * @desc 是否代收货款
 */
export const isCod = (provider_payment_type: number | string) => Number(provider_payment_type) === PaymentTypeEnum.cod

export type Tree<T, CKey extends string> = {
  [K in CKey]?: T[]
}
/**
 * 遍历一棵树，可通过callbackfn返回true来中断遍历并返回当前节点
 * @param tree
 */
export function forEachTree<T extends Tree<T, CKey>, CKey extends string = 'children'>(tree: T | T[], callbackfn: (value: T) => boolean | void, key?: CKey) {
  const k = key ?? ('children' as CKey)
  // 当前节点
  let res = null
  let list = null
  if (Array.isArray(tree)) list = tree
  else if (callbackfn(tree)) return tree
  else list = tree[k]

  if (list)
    list.find((t) => {
      res = forEachTree(t, callbackfn, k)
      return !!res
    })
  return res
}

/**
 * 遍历一棵树，返回一棵格式化之后的新树
 * @param tree
 */
export function mapTree<F, T extends Tree<T, CKey>, CKey extends string = 'children'>(tree: T[], callbackfn: (value: T, children: F[]) => F, key?: CKey): F[] {
  const k = key ?? ('children' as CKey)
  // 当前节点
  let resRree = null
  if (tree) resRree = tree.map((t) => callbackfn(t, mapTree(t[k], callbackfn, k)))
  return resRree
}

/** 判断URL格式是否错误 */
export const isUrlFormatValid = (url: string) => {
  if (!url) return true
  return /^((http:|https:)+(\/\/)[^\s]*?(([A-Za-z0-9]+-[A-Za-z0-9]+|[A-Za-z0-9]+)\.))+([a-z]+)[/?:]?.*$/.test(url)
}

/** 格式化时间 */
export const formatTime = (timeStamp: Date | number | undefined, formatString = 'yyyy-MM-dd', defaultString = '') => {
  if (!timeStamp) {
    return defaultString
  }

  try {
    return format(timeStamp, formatString)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('parse timeStamp error，source timeStamp is ', timeStamp)
  }
  return ''
}

export function formatNullData(data) {
  return !data && data !== 0 ? '-' : data
}

export const convertMtoByte = (n: number) => n * 1024 * 1024
