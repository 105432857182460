// 导入antd样式，覆盖antd默认主题变量
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import '@yy/one-icon/src/style/index.css'
import '@yy/one-ui/es/style/antd/index.less'
import '@yy/one-ui/es/style/index.less'
import * as serviceWorker from '@/serviceWorkerRegistration'
import store from '@/store'
import '@/utils/login'
import App from './App'
import Loading from './components/Loading'
import I18n, { i18nInitResourcePromise } from './services/i18n'
import './services/performance'
import './services/sentry'
import './styles/index.less'
import 'normalize.css'
import { OneConfigProvider } from '@yy/one-config'
import { oneConfig } from '@/services/one-config'
import { MobileAgentService } from './services/mobileAgentService'

new MobileAgentService().checkRedirect()

const history = createBrowserHistory()

const oneConfigValues = {
  oneConfig,
  immediate: true,
}

function render() {
  // 加载完语言之后再展示root
  i18nInitResourcePromise.then(() => (document.getElementById('oneshipLoading').style.cssText = 'display: none'))
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <React.Suspense fallback={<Loading />}>
          <I18nextProvider i18n={I18n}>
            <OneConfigProvider value={oneConfigValues}>
              <App />
            </OneConfigProvider>
          </I18nextProvider>
        </React.Suspense>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render)
}
if (process.env.NODE_ENV === 'development') {
  serviceWorker.unregister()
} else {
  serviceWorker.register()
}
