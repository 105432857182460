import type * as Types from '@/types'

/**
 * 支持的结算方式
 * 2: 到付现结
 * 3: 寄付
 * 5: 逆向现结
 */
type SettlementMode = 1 | 2 | 3 | 5

export const CASH_ON_DELIVERY = 2
export const DELIVERY_SETTLE = 3
export const RETURN_PAY_NOW = 5

export const PAY_TYPE: Types.DictLabel<SettlementMode> = {
  1: 'newOder.payNow',
  2: 'newOder.cashOnDelivery',
  3: 'constant.deliverySettle',
  5: 'return.payNow',
}

/** 付款方式枚举 */
export const PayTypeEnum = {
  /** 到付现结 */
  cashOnDelivery: 2,
  /** 寄付 */
  deliverySettle: 3,
} as const

/** 是否是saas服务产品 */
export const SaasStatusEnum = {
  /** 是 */
  YES: 1,
  /** 不是 */
  NO: 0,
} as const

export const DEFAULT_PAYMENT_OPTIONS = {
  2: true,
  3: false,
}
