import { useCallback, useMemo } from 'react'
import { useUrlSearch } from '@/hooks'
import type * as Types from './types'
import { getUrlParam } from '@/utils'

export const getShipInfoFromCache = (queryKey = 't'): Types.IUseShipInfo => {
  const token = getUrlParam(queryKey)
  const { localStorage, sessionStorage } = window
  const source = localStorage.getItem(token) || sessionStorage.getItem(token)
  if (source) {
    try {
      const orderInfo = JSON.parse(source)
      localStorage.removeItem(token)

      sessionStorage.setItem(token, source)
      return orderInfo
    } catch (error) {
      return null
    }
  }

  return null
}

/**
 * 发货钩子
 * @param queryKey 参数在路由 search 部分中的名称, 默认为: `t`
 * @description
 * 主要用于跳转到发货流程, 包括发货/编辑/合单发货等所有的发货页面;
 * 该钩子主要用于携带信息跳转页面;
 * 路径由调用发确定, 钩子只会在路由后面添加 search 参数
 */
export const useShip = <T = any>(queryKey = 't') => {
  const params = useUrlSearch()
  const token = useMemo(() => params[queryKey] || '', [params, queryKey])

  /** 获取发货信息 */
  const getShipInfo = useCallback((): Types.IUseShipInfo<T> => {
    const { localStorage, sessionStorage } = window
    let source = localStorage.getItem(token) || sessionStorage.getItem(token)

    // 外域跳过来
    if (!source && params[token]) source = decodeURIComponent(params[token])

    if (source) {
      try {
        const orderInfo = JSON.parse(source)
        localStorage.removeItem(token)

        sessionStorage.setItem(token, source)
        return orderInfo
      } catch (error) {
        return null
      }
    }

    return null
  }, [token, params])

  /** 设置发货信息 */
  const setShipInfo = useCallback(
    (orderInfo: Types.IUseShipInfo<T>) => {
      const token = (Date.now() + Math.floor(Math.random() * 1e9)).toString(32)
      const source = JSON.stringify(orderInfo)
      localStorage.setItem(token, source)
      const mapTokenToUrl = (url: string) => {
        const hIndex = url.indexOf('#')
        const hash = hIndex !== -1 ? url.substring(hIndex) : ''
        const head = hash ? url.replace(hash, '') : url
        const tail = `${queryKey}=${token}${hash}`
        return `${head}${-1 !== url.indexOf('?') ? '&' : '?'}${tail}`
      }

      return { token, mapTokenToUrl }
    },
    [queryKey]
  )

  return { setShipInfo, getShipInfo }
}

export * from './useSession'
