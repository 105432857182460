import API from '@/services/http'
import type { AddressInfoType, IAddress } from '@/store/addressSlice/types'

export interface IAddressMatchVo {
  /**
   * 国家编码
   */
  countryCode: string
  /**
   * 来源国家
   */
  sourceCountry?: string

  city?: string
  /**
   * 请求标识ID
   */
  metadataId?: string
  /**
   * 省
   */
  province?: string
  /**
   * 区
   */
  district?: string
  /**
   * 街镇
   */
  town?: string
  /**
   * 邮编
   */
  zipCode?: string

  /**
   * 地址
   */
  address?: string

  addressTwo?: string

  // 应用id
  appId?: string

  addressLanguages?: string[]

  match?: boolean
}

export type IAddressMatchResVo = IAddressMatchVo
export type IHandleConfirmMatchAddress = (value: IAddressMatchResVo) => void
export type ICounvertAddressInfo = Pick<
  IAddress,
  | 'consigneeCountryCode'
  | 'consigneeProvince'
  | 'consigneeCity'
  | 'consigneeDistrict'
  | 'consigneeDistrict'
  | 'consigneeTown'
  | 'consigneeAddress'
  | 'consigneeAddressTwo'
  | 'consigneeZipCode'
>
export const postAddressMatch = (addressSmartMatchVos: IAddressMatchVo[]) =>
  API.admin.post<{ addressSmartMatchVos: IAddressMatchResVo[] }>('/api/address/match', { addressSmartMatchVos })

interface IGetAddressVO {
  isDefault: boolean
  addressType?: number
}

/**
 * 获取地址详情
 * @param data
 * @returns
 */
export const getAddress = (data?: IGetAddressVO) => API.admin.post<AddressInfoType>('/api/user/address/get', data)
