import type { RouteConfig } from 'react-router-config'
import { withAsync } from '@/components/Async'
import { withAuthRedirect } from '@/components/Authorized/hoc/withAuthRedirect'
import Blank from '@/layouts/Blank'
import { withAddressSplitAlgorithmTrackPage } from '@/bizComponent/AddressAutoSplit/AddressSplitAlgorithmTrack'

const routes: RouteConfig[] = [
  {
    alias: 'logistics_management',
    path: '/logistics',
    component: withAuthRedirect(Blank, { first: '/ship/logistics/list' }),
    menuTitle: 'frame.logisticOrders',
    permissionCode: 'ship:logistics',
    routes: [
      // 运单管理
      {
        path: '/list',
        component: withAsync(() => import(/* webpackChunkName: "Logistics/List" */ '@/containers/Logistics/List')),
        menuTitle: 'frame.logisticOrders',
        pageDescription: 'frame.logisticOrders.desc',
        exact: true,
      },
      {
        path: '/detail/:appId/:logisticsNo',
        menuTitle: 'detail',
        component: withAsync(() => import(/* webpackChunkName: "Logistics/Detail" */ '@/containers/Logistics/Detail')),
        exact: true,
      },
      {
        path: '/edit/:appId/:logisticsNo',
        menuTitle: 'newOrder.editShipment',
        component: withAddressSplitAlgorithmTrackPage(
          withAsync(() => import(/* webpackChunkName: "Logistics/Create" */ '@/containers/Logistics/LogisticsEdit')),
          'logisticsEdit'
        ),
        exact: true,
      },
    ],
  },
]

export default routes
