import Api from '@/services/http'
import { notificationUrl } from '@/services/url'
import store from '@/store'
import { setGuideInfo } from '@/store/commonSlice'
import type { IGuideInfo } from '@/store/commonSlice/types'
import { notificationActions } from '@/store/notificationSlice'
import type { ITaskResultProps, ResponseUnReadType } from '@/store/notificationSlice/type'
import { updateWalletListInfo } from '@/store/userSlice'
import type { IWallInfo } from '@/store/userSlice/types'
import { genTraceParent } from '@/utils'

/**
 * create the ws msg id for retry for timeout fetch some websocket task
 */
export const createWsMsgId = () => {
  const wsMsgId = genTraceParent()

  return {
    wsMsgId,
  }
}

/** 更新异步消息, 通知到对应业务组件 */
export const updateAsyncTask = (task: ITaskResultProps) => {
  const { taskId } = task
  store.dispatch(notificationActions.addTaskResultsItem(task))
  store.dispatch(notificationActions.deleteTaskId({ taskId }))

  // 触发通知到页面后就自动把对应的task移除
  setTimeout(() => {
    store.dispatch(notificationActions.deleteTaskResultsItem({ taskId }))
  }, 60)
}

/** 未读通知消息 */
export const queryUnRead = async () => {
  const states = store.getState()
  const params = {
    appId: process.env.WEBSOCKET_APP_ID,
    taskIds: states.notification.taskIdCaches,
  }

  const response = await Api.admin.post<ResponseUnReadType>(notificationUrl.queryUnRead, params)
  if (response) {
    const { data, bizCode, bizMessage } = response
    if (bizCode === 'SUCCESS') {
      const { messageCount, exportCount, taskResults } = data
      store.dispatch(notificationActions.setUnReadMessageCount({ messageCount }))
      store.dispatch(notificationActions.setUnReadDownloadCount({ exportCount }))

      taskResults?.forEach((task) => {
        updateAsyncTask(task)
      })

      return data
    }

    return Promise.reject(bizMessage)
  }
}

export const updateBalance = (data: IWallInfo) => {
  store.dispatch(updateWalletListInfo(data))
}

export const updateGuideInfo = (data: IGuideInfo) => {
  store.dispatch(setGuideInfo(data))
}
