import React from 'react'

export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M0.833344 10.0002C0.833344 4.93803 4.93788 0.833496 10 0.833496C15.0621 0.833496 19.1667 4.93803 19.1667 10.0002C19.1667 15.0623 15.0621 19.1668 10 19.1668C4.93788 19.1668 0.833344 15.0623 0.833344 10.0002ZM9.30541 14.2958C9.12123 14.1116 9.01775 13.8618 9.01775 13.6014C9.01775 13.3409 9.12123 13.0911 9.30541 12.9069C9.4896 12.7227 9.73941 12.6192 9.99989 12.6192C10.2604 12.6192 10.5102 12.7227 10.6944 12.9069C10.8786 13.0911 10.982 13.3409 10.982 13.6014C10.982 13.8618 10.8786 14.1116 10.6944 14.2958C10.5102 14.48 10.2604 14.5835 9.99989 14.5835C9.73941 14.5835 9.4896 14.48 9.30541 14.2958ZM10.491 11.3097H9.50882C9.41879 11.3097 9.34513 11.236 9.34513 11.146V5.58052C9.34513 5.49049 9.41879 5.41683 9.50882 5.41683H10.491C10.581 5.41683 10.6547 5.49049 10.6547 5.58052V11.146C10.6547 11.236 10.581 11.3097 10.491 11.3097Z"
        fill="#F86140"
      />
    </svg>
  )
}
