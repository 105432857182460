import type { FormInstance } from 'antd'
import { Col, Form, Input, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProvinceSelect } from '@/bizComponent/AntdForm/AreaForm'
import DynamicFormItemGroup, { FormItem } from '@/bizComponent/AntdForm/DynamicFormItemGroup'
import RULES, { EMPTY_RULES } from '@/bizComponent/AntdForm/rules'
import Select2Input from '@/bizComponent/AntdForm/Select2Input'
import { useAddressLocalLanage } from '@/hooks'
import { useSelector } from '@/store'
import type { IRecipientAddressFormValues } from '@/store/recipientAddressSlice/types'

export interface HomeProps {
  /** 表单实例 */
  form: FormInstance
  /** 是否隐藏 */
  hidden: boolean
  /** 禁止项 */
  disables: Array<keyof IRecipientAddressFormValues> | 'all'
}

/** 宅配地址 */
export const Home: React.FC<HomeProps> = (props) => {
  const { hidden, disables = [] } = props

  const { t } = useTranslation()
  /** 省列表 */
  const provinces = useSelector((state) => state.recipientAddressForm.provinces)
  /** 市列表 */
  const cities = useSelector((state) => state.recipientAddressForm.cities)
  /** 区列表 */
  const districts = useSelector((state) => state.recipientAddressForm.districts)
  /** town列表 */
  const towns = useSelector((state) => state.recipientAddressForm.towns)
  /** 地址配置 */
  const addressConfig = useSelector((state) => state.address?.addressConfig)
  /** 必填的字段 */
  const verifyFieldMap = useSelector((state) => state.address.consigneeVerifyFieldMap)

  const { _t } = useAddressLocalLanage()

  return (
    <DynamicFormItemGroup hidden={hidden}>
      <div style={{ display: hidden ? 'none' : undefined }}>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => {
            const fields = ['arrival_countryCode']
            return -1 !== fields.findIndex((name) => prev[name] !== next[name])
          }}
        >
          {(form) => {
            const { arrival_countryCode } = form.getFieldsValue()
            const visibilityConfig = addressConfig?.[arrival_countryCode]

            return (
              <>
                <Row gutter={20}>
                  {/* 省 */}
                  <Col span={6} style={{ display: visibilityConfig.provinceVisibility ? 'block' : 'none' }}>
                    <FormItem
                      rules={EMPTY_RULES}
                      required={!!+verifyFieldMap[arrival_countryCode]?.province}
                      name="arrival_province"
                      label={_t(arrival_countryCode, 'stateProvince') || ' '}
                    >
                      <ProvinceSelect list={provinces} data-id="shipment_reciptInfo_homeProvince" disabled={disables === 'all' || disables.includes('arrival_province')} />
                    </FormItem>
                  </Col>
                  {/* 市 */}
                  <Col span={6} style={{ display: visibilityConfig.cityVisibility ? 'block' : 'none' }}>
                    <FormItem rules={EMPTY_RULES} required={!!+verifyFieldMap[arrival_countryCode]?.city} name="arrival_city" label={_t(arrival_countryCode, 'city') || ' '}>
                      <Select2Input list={cities} data-id="shipment_reciptInfo_homeCity" disabled={disables === 'all' || disables.includes('arrival_city')} />
                    </FormItem>
                  </Col>
                  {/* 区 */}
                  <Col span={6} style={{ display: visibilityConfig.districtVisibility ? 'block' : 'none' }}>
                    <FormItem
                      rules={EMPTY_RULES}
                      required={!!+verifyFieldMap[arrival_countryCode]?.district}
                      name="arrival_district"
                      label={_t(arrival_countryCode, 'district') || ' '}
                    >
                      <Select2Input list={districts} data-id="shipment_reciptInfo_homeDistrict" disabled={disables === 'all' || disables.includes('arrival_district')} />
                    </FormItem>
                  </Col>

                  {/* TOWN */}
                  <Col span={6} style={{ display: visibilityConfig.townVisibility ? 'block' : 'none' }}>
                    <FormItem rules={EMPTY_RULES} required={!!+verifyFieldMap[arrival_countryCode]?.town} name="arrival_town" label={_t(arrival_countryCode, 'town') || ' '}>
                      <Select2Input list={towns} data-id="shipment_reciptInfo_homeTown" disabled={disables === 'all' || disables.includes('arrival_town')} />
                    </FormItem>
                  </Col>

                  {/* 邮编 */}
                  <Col span={6}>
                    <FormItem rules={EMPTY_RULES} required={!!+verifyFieldMap[arrival_countryCode]?.zipCode} name="arrival_zipCode" label={t('order.create.zipCode')}>
                      <Input
                        disabled={disables === 'all' || disables.includes('arrival_zipCode')}
                        data-id="shipment_reciptInfo_homeZipcode"
                        placeholder={t('common.input.placeholder')}
                      />
                    </FormItem>
                  </Col>
                  {/* 地址1 */}
                  <Col span={6}>
                    <FormItem rules={[RULES.address()]} required={!!+verifyFieldMap[arrival_countryCode]?.address} name="arrival_address" label={t('order.create.address1')}>
                      <Input
                        disabled={disables === 'all' || disables.includes('arrival_address')}
                        data-id="shipment_reciptInfo_homeAddress1"
                        placeholder={t('common.input.placeholder')}
                      />
                    </FormItem>
                  </Col>
                  {/* 地址2 */}
                  <Col span={6}>
                    <FormItem
                      rules={[RULES.addressTwo()]}
                      required={!!+verifyFieldMap[arrival_countryCode]?.addressTwo}
                      name="arrival_addressTwo"
                      label={t('order.create.address2')}
                    >
                      <Input
                        disabled={disables === 'all' || disables.includes('arrival_addressTwo')}
                        data-id="shipment_reciptInfo_homeAddress2"
                        placeholder={t('common.input.placeholder')}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </>
            )
          }}
        </Form.Item>
      </div>
    </DynamicFormItemGroup>
  )
}

Home.displayName = 'Home'
