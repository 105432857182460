import { Button, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { NoData as NoDataIcon } from '@/components/Icons'
import logisticEmptyPic from '@/static/images/logistic-empty.jpg'
import orderEmptyPic from '@/static/images/order-empty.jpg'
import { LogisticsType } from '@/store/logisticsSlice/type'
import classes from './index.module.less'

export * from './BatchListEmpty'

/**
 * 订单管理完全没数据
 */
export const OrderListTotalEmpty: React.FC<{ type?: LogisticsType }> = ({ type }) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className={classes.wrapper}>
      <img className={classes.img} width="360" src={orderEmptyPic} alt="" />
      <Typography className={classes.text}>{type === LogisticsType.ondemand ? t('immediateOrder.list.emptyText') : t('order.list.emptyText')}</Typography>
      {type === LogisticsType.ondemand ? null : (
        <Button
          className={classes.btn}
          type="primary"
          data-id="order.list.auth"
          onClick={() => {
            history.push('/stores/list')
          }}
        >
          {t('order.list.auth')}
        </Button>
      )}
    </div>
  )
}

/**
 * 运单列表完全没数据
 */
export const LogisticsTotalEmpty: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className={classes.wrapper}>
      <img className={classes.img} width="360" src={logisticEmptyPic} alt="" />
      <Typography className={classes.text}>{t('logistics.list.emptyText')}</Typography>
    </div>
  )
}

/**
 * 搜索条件没数据
 */
export const Empty: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.tips}>{t('logistics.list.orderListAddYourFitst')}</Typography>
    </div>
  )
}

export const WaybillTrackingListEmpty: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.tips}>{t('subscribe.waybillTracking.list.empty')}</Typography>
    </div>
  )
}

Empty.displayName = 'Empty'
OrderListTotalEmpty.displayName = 'OrderListTotalEmpty'
LogisticsTotalEmpty.displayName = 'LogisticsTotalEmpty'

interface NoDataEmptyProps {
  text: string
}

export const NoDataEmpty: React.FC<NoDataEmptyProps> = ({ text }) => {
  return (
    <div className={classes.noDataEmpty}>
      <NoDataIcon />
      <p className={classes.text}>{text}</p>
    </div>
  )
}

NoDataEmpty.displayName = 'NoDataEmpty'
