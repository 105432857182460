import type * as Types from '@/types'

/**
 * 订单排序方式
 * 1创单时间（最新到最旧）|2创单时间（最旧到最新）|3原订单号（降序）|4原订单号（升序）|5收件人姓名（Z-A）|6收件人姓名（A-Z）
 * 7预约派送时间（最晚到最早）|8预约派送时间（最早到最晚）
 */
export type OrderSortType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

export const ORDER_SORT_TYPE: Types.Dict<{ value: OrderSortType }> = [
  {
    value: 1,
    label: 'common.search.sort.createTimeDesc',
  },
  {
    value: 2,
    label: 'common.search.sort.createTimeAsc',
  },
  {
    value: 3,
    label: 'common.search.sort.orderNumDesc',
  },
  {
    value: 4,
    label: 'common.search.sort.orderNumAsc',
  },
  {
    value: 5,
    label: 'common.search.sort.RecipientDesc',
  },
  {
    value: 6,
    label: 'common.search.sort.RecipientAsc',
  },
  {
    value: 7,
    label: 'common.search.sort.deliveryDateDesc',
  },
  {
    value: 8,
    label: 'common.search.sort.deliveryDateAsc',
  },
]

/**
 * 运单排序方式
 * 1创单时间（最新到最旧）|2创单时间（最旧到最新）|3收件人姓名（Z-A）|4收件人姓名（A-Z）
 * 5预约派送时间（最晚到最早）|6预约派送时间（最早到最晚）
 */
export type LogisticsSortType = 1 | 2 | 3 | 4 | 5 | 6

export const LOGISTICS_SORT_TYPE: Types.Dict<{ value: LogisticsSortType }> = [
  {
    value: 1,
    label: 'common.search.sort.createTimeDesc',
  },
  {
    value: 2,
    label: 'common.search.sort.createTimeAsc',
  },
  {
    value: 3,
    label: 'common.search.sort.RecipientDesc',
  },
  {
    value: 4,
    label: 'common.search.sort.RecipientAsc',
  },
  {
    value: 5,
    label: 'common.search.sort.deliveryDateDesc',
  },
  {
    value: 6,
    label: 'common.search.sort.deliveryDateAsc',
  },
]
