import { Card, Progress } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Card as CardIcon } from '@yy/one-icon'
import { OneButton } from '@yy/one-ui'
import { REF_OBJECT } from '@/constant'
import { postRechargeInfo } from '@/services/apis'
import { useSelector } from '@/store'
import { translateCurrency } from '@/utils'
import styles from './index.module.less'

export const WalletCard: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [rechargePackageInfo, setRechargePackageInfo] = useState(null)

  const isPrepaid = useSelector((state) => state.user.info.isPrepaid)
  const defaultWallet = useSelector((state) => state.user.walletList.find((wallet) => wallet.isDefault) || REF_OBJECT)
  const { currencyCode, totalBalance, preApprovalStatus, creditAvailableAmount, creditActiveAmount } = defaultWallet

  useEffect(() => {
    if (isPrepaid && currencyCode) {
      postRechargeInfo({
        currencyCode,
      }).then((res) => {
        const data = res?.data
        if (data?.rechargePackageInfoList) {
          setRechargePackageInfo(data?.rechargePackageInfoList.find((item) => item.recommend))
        }
      })
    }
  }, [currencyCode, isPrepaid])

  //固定
  const creditActiveAmountTemp = (creditActiveAmount || 0) as any
  //可用
  const creditAvailableAmountTemp = (creditAvailableAmount || 0) as any
  //已经用
  const alreadyUse = translateCurrency(creditActiveAmountTemp - creditAvailableAmountTemp)

  //百分比计算   (固定 - 可用 )/ 固定
  const percentCredit = creditActiveAmountTemp ? (creditActiveAmountTemp - creditAvailableAmountTemp) / creditActiveAmountTemp : 0

  return (
    <>
      {isPrepaid && (
        <Card className={styles.walletCard}>
          <div className={styles.head}>
            <h3>{t('dashboard.account.balance')}</h3>
            {rechargePackageInfo && (
              <span>
                {t('wallet.recharge.Discount', { amount: translateCurrency(rechargePackageInfo.amount), couponAmount: translateCurrency(rechargePackageInfo.couponAmount) })}
              </span>
            )}
          </div>
          <div className={styles.body}>
            <div className={styles.balance}>
              <h2 className={styles.title}>{t('wallet.remaing')}</h2>
              <div className={styles.account}>
                {currencyCode} {translateCurrency(totalBalance)}
              </div>
            </div>
            {!preApprovalStatus ? null : (
              <div className={styles.limit}>
                <h5 className={styles.title}>
                  {t('wallet.preCredit')}
                  &nbsp;{currencyCode}
                  <span>({`${alreadyUse}/${translateCurrency(creditActiveAmountTemp)}`})</span>
                </h5>
                <Progress className={styles.process} width={280} percent={percentCredit * 100} showInfo={false} />
              </div>
            )}
            <OneButton
              type="primary"
              icon={<CardIcon />}
              onClick={() => {
                history.push('/wallet')
              }}
              className={styles.recharge}
              size="middle"
            >
              {t('dashboard.account.recharge')}
            </OneButton>
          </div>
        </Card>
      )}
    </>
  )
}

export default WalletCard
