import type { AppThunk } from '..'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type TypeDelivery = {
  countryCode: string
  provider_delivery_date: string
  provider_delivery_time: string
}

export type TypePickupData = {
  provider_pickup_type: number
  provider_pickup_date?: string
  provider_pickup_time_slots?: string
}
export type TypeInsuranceData = {
  provider_insurance_type: 1 | 2
  provider_insured_amount?: number
}

export type TyepSetting = {
  pickup?: TypePickupData
  delivery?: TypeDelivery[]
  insurance?: TypeInsuranceData
  additionalService?: { provider_service_types?: string[] }
}

export type TypeInitialState = {
  list: TyepSetting[]
}

const initialState = {
  list: [],
} as TypeInitialState

const batchMergeSlice = createSlice({
  name: 'batchMerge',
  initialState: initialState,
  reducers: {
    update(state, action: PayloadAction<TypeInitialState>): TypeInitialState {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const { update } = batchMergeSlice.actions

export const clearBatchMergeSlice = (): AppThunk => (dispatch) => {
  dispatch(
    update({
      ...initialState,
    })
  )
}

export default batchMergeSlice.reducer
