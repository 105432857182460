import { Col, Form } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OneSelect } from '@yy/one-ui'
import type { AreaFormAPIs, AreaFormProps } from '@/bizComponent/AntdForm'
import { AreaForm, useFormContext } from '@/bizComponent/AntdForm'
import { useScope } from '@/components'
import { useDispatch, useSelector } from '@/store'
import { filterCountryOption, shouldUpdateFn } from '@/utils'
import CountryWithCode from '@/bizComponent/CountryWithCode'
import type { Assign } from 'utility-types'

/**
 *  发货地址 国家省市区
 */

const { Option } = OneSelect

export type DepartureAreaFormProps = Partial<
  Assign<
    AreaFormProps,
    {
      countryDisabled?: boolean
      useReceiveDictionary?: boolean
      countryLabel?: string
    }
  >
>

export const DepartureAreaForm: React.FC<DepartureAreaFormProps> = ({ countryDisabled = false, useReceiveDictionary, countryLabel, ...props }) => {
  const { setFieldsValue } = useFormContext()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  /** 当前国家 */
  const curCountry = useSelector((state) => state.user.info?.selfArea)
  /** 国家列表 */
  const filterSenderCountries = useSelector((state) => state?.dictionary?.filterSenderCountries)
  const filterArrivalCountries = useSelector((state) => state.dictionary.filterArrivalCountries)

  const list = useMemo(() => {
    return useReceiveDictionary ? filterArrivalCountries : filterSenderCountries
  }, [filterArrivalCountries, filterSenderCountries, useReceiveDictionary])

  const DepartureAreaFormAPIs = useScope<AreaFormAPIs>('antd_DepartureAreaFormAPIs')

  useEffect(() => {
    async function initDepartureCountry() {
      if (curCountry) {
        setFieldsValue({
          departure_countryCode: curCountry,
        })
      }
    }
    initDepartureCountry()
  }, [DepartureAreaFormAPIs, curCountry, dispatch, setFieldsValue])

  return (
    <>
      {/* 国家 */}
      <Col {...props?.colProps}>
        <Form.Item label={countryLabel || t('order.create.countryRegion')} name="departure_countryCode">
          <OneSelect
            dropdownMatchSelectWidth={200}
            disabled={countryDisabled || list.length === 0}
            placeholder={t('order.create.countryRegion')}
            showSearch
            filterOption={filterCountryOption}
            className="country-select"
          >
            {list?.map((item) => (
              <Option key={item.countryCode} value={item.countryCode} title={item.countryName}>
                <CountryWithCode item={item} />
              </Option>
            ))}
          </OneSelect>
        </Form.Item>
      </Col>
      <Form.Item noStyle shouldUpdate={shouldUpdateFn(['departure_countryCode'])}>
        {({ getFieldValue }) => {
          const departure_countryCode = getFieldValue('departure_countryCode')
          return <AreaForm countryCode={departure_countryCode} areaType="departure" showRequired={false} {...props} />
        }}
      </Form.Item>
    </>
  )
}

DepartureAreaForm.displayName = 'DepartureAreaForm'
