import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { getGiveawayActDetail } from '@/services/apis'
import type { AppDispatch } from '@/store'
import type * as Types from './types'
import { yuanify } from '@yy/one-currency'

const initialState: Types.InitialState = {
  /** 活动信息 */
  activity: null,
}

const { reducer, actions } = createSlice({
  name: 'giveaway',
  initialState,
  reducers: {
    /** 设置活动信息 */
    setActivity(state, action: PayloadAction<Types.InitialState['activity']>) {
      return {
        ...state,
        activity: action.payload,
      }
    },
  },
})

/** 获取优惠活动信息 */
export const getActivityAction = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await getGiveawayActDetail()

    yuanify(data, ['discountDetails.[].topup.qtyList.[].qty'])
    dispatch(actions.setActivity(data))
    return data
  } catch (e) {}
}

export const { setActivity } = actions
export default reducer
