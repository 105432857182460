import { useDebounceFn } from 'ahooks'
import type { FormInstance } from 'antd'
import { Col, Form, Row, Select } from 'antd'
import { uniqBy } from 'lodash'
import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import DynamicFormItemGroup, { FormItem } from '@/bizComponent/AntdForm/DynamicFormItemGroup'
import RULES from '@/bizComponent/AntdForm/rules'
import { REF_ARRAY, REF_OBJECT } from '@/constant'
import STORAGE_LIST from '@/constant/temperature'
import { useDispatch, useSelector } from '@/store'
import { actions, queryRecipientAddressStoreStation, queryRecipientAddressStoreStationPagination } from '@/store/recipientAddressSlice'
import type { IRecipientAddressFormValues } from '@/store/recipientAddressSlice/types'
import { filterOption, filterOptionStartsWith } from '@/utils'
import type { BlackCatSelectAPI } from '../BlackCatSelect'
import { BlackCatSelect } from '../BlackCatSelect'
import { SelectLoading } from '../SelectLoading'
import { isCatService, isMapService } from '../utils'
import styles from './style.module.less'

export interface StoreProps {
  /** 表单实例 */
  form: FormInstance
  /** 是否隐藏 */
  hidden: boolean
  /** 禁止项 */
  disables: Array<keyof IRecipientAddressFormValues> | 'all'
}

/** 店配地址 */
export const Store: React.FC<StoreProps> = (props) => {
  const { form, hidden, disables = [] } = props
  const { t } = useTranslation()
  const isLoading = useSelector((state) => state.recipientAddressForm.isLoading)
  const dispatch = useDispatch(form)
  const blackCatRef = useRef<BlackCatSelectAPI>(null)
  const { providerDic } = useSelector((state) => state.dictionary)

  /** 服务商列表 */
  const expresses = useSelector(
    (state) => {
      const { expresses } = state.recipientAddressForm
      if (Array.isArray(expresses) && expresses.length > 0) {
        const supportedStoreExpresses = expresses.filter((item) => item.supportedAddressType.includes('STORE_STREET'))
        return uniqBy(supportedStoreExpresses, 'companyCode').filter((express) => express.companyName)
      }

      return REF_ARRAY
    },
    (prev, curr) => {
      if (prev?.length !== curr?.length) {
        return false
      }

      const prevSeqs = prev.map((item) => item.companyCode).join(',')
      const currSeqs = curr.map((item) => item.companyCode).join(',')
      return prevSeqs === currSeqs
    }
  )

  const { province: provinces = [], city: cities = [], district: districts = [] } = useSelector((state) => state.recipientAddressForm.storeAreaList || REF_OBJECT)

  /** 门店类型(登录之后就会请求) */
  const allStationTypes = useSelector((state) => state?.address?.stationTypes || REF_ARRAY)

  /** 门店列表 */
  const stations = useSelector(
    (state) => {
      if (Array.isArray(state.recipientAddressForm.stationList)) {
        return uniqBy(state.recipientAddressForm.stationList, 'stationSeq')
      }

      return REF_ARRAY
    },
    (prev, curr) => {
      if (prev?.length !== curr?.length) {
        return false
      }

      const prevSeqs = prev.map((item) => item.stationSeq).join(',')
      const currSeqs = curr.map((item) => item.stationSeq).join(',')
      return prevSeqs === currSeqs
    }
  )

  /** 搜索门店 */
  const { run: queryStation } = useDebounceFn(
    (keywords?: string) => {
      dispatch(actions.resetStationState())
      dispatch(queryRecipientAddressStoreStation({ queryKey: keywords }))
    },
    { wait: 500 }
  )

  /** 滚动加载接口 */
  const { run: debounceQueryStoreStationPagination } = useDebounceFn(
    () => {
      dispatch(queryRecipientAddressStoreStationPagination())
    },
    { wait: 500 }
  )

  const stationListHasNext = useSelector((state) => state.recipientAddressForm.stationListHasNext)

  /** 滚动加载处理 */
  const handlePopupScroll = useCallback(
    (e) => {
      const { target } = e
      if (stationListHasNext && target.scrollTop + target.offsetHeight === target.scrollHeight) {
        debounceQueryStoreStationPagination()
      }
    },
    [debounceQueryStoreStationPagination, stationListHasNext]
  )

  return (
    <DynamicFormItemGroup hidden={hidden}>
      <Form.Item
        style={{ display: hidden ? 'none' : undefined }}
        noStyle
        shouldUpdate={(prev, next) => {
          const fields = ['arrival_store_companyCode', 'arrival_store_province', 'arrival_store_city', 'arrival_store_storage_cdt']
          return -1 !== fields.findIndex((name) => prev[name] !== next[name])
        }}
      >
        {({ getFieldsValue }) => {
          const { arrival_store_companyCode, arrival_store_province, arrival_store_city, arrival_store_storage_cdt, arrival_countryCode } = getFieldsValue()
          const stationTypeMap = new Map(expresses.map((item) => [item.companyCode as string, item.stationTypes ?? []]))
          const stationTypes = stationTypeMap?.get(arrival_store_companyCode) || []
          const disabledProvince = expresses.length === 0 || !arrival_store_companyCode
          const disabledCity = expresses.length === 0 || !arrival_store_companyCode || !arrival_store_province
          const disabledDistrict = expresses.length === 0 || !arrival_store_companyCode || !arrival_store_province || !arrival_store_city
          /* 接入电子地图的服务商都不需要选择 1-3级地址，黑猫不需要门店类型选择 */
          const isMap = isMapService(arrival_store_companyCode)
          const isCat = isCatService(arrival_store_companyCode)

          if (isMap === false) {
            blackCatRef.current?.close()
          }

          /** 黑猫显示样式 */
          const blackCatVisibleStyle = { display: isCat ? undefined : 'none' }
          /** 一二三级地址样式, 非电子地图，才展示 */
          const normalVisibleStyle = { display: isMap ? 'none' : undefined }
          /** 电子地图选择展示样式 */
          const mapVisibleStyle = { display: isMap ? undefined : 'none' }
          if (hidden) {
            normalVisibleStyle.display = 'none'
            blackCatVisibleStyle.display = 'none'
            mapVisibleStyle.display = 'none'
          }

          return (
            <>
              <Row gutter={20}>
                {/* 服务商 */}
                <Col span={6}>
                  <FormItem rules={[RULES.required()]} required name="arrival_store_companyCode" label={t('address.form.company')}>
                    <SelectLoading
                      disabled={disables === 'all' || disables.includes('arrival_store_companyCode')}
                      loading={isLoading}
                      showSearch
                      data-id="shipment_reciptInfo_storeProvider"
                      filterOption={filterOption}
                      placeholder={t('common.select.placeholder')}
                      dropdownMatchSelectWidth={false}
                    >
                      {expresses.map((express, index) => (
                        <Select.Option
                          title={express.companyCode}
                          key={express.companyCode}
                          value={express.companyCode}
                          data-id={'shipment_reciptInfo_storeProviderItem' + `${index + 1}`}
                        >
                          <Row align="middle">
                            {providerDic[express.companyCode]?.url ? (
                              <img
                                src={providerDic[express.companyCode].url}
                                style={{
                                  width: '28px',
                                  height: '28px',
                                  marginRight: '4px',
                                  borderRadius: '4px',
                                }}
                              />
                            ) : null}
                            <p>{express.companyName}</p>
                          </Row>
                        </Select.Option>
                      ))}
                    </SelectLoading>
                  </FormItem>
                </Col>

                {/* 门店类型 */}
                <Col span={6} style={{ display: stationTypes && stationTypes.length > 0 && !isMap ? undefined : 'none' }}>
                  <FormItem name="arrival_station_type" label={t('address.form.stationType')}>
                    <SelectLoading
                      disabled={disables === 'all' || disables.includes('arrival_station_type')}
                      loading={isLoading}
                      showSearch
                      allowClear
                      data-id="shipment_reciptInfo_station_type"
                      filterOption={filterOption}
                      placeholder={t('common.select.placeholder')}
                    >
                      {uniqBy(stationTypes, 'stationType').map((item, index) => (
                        <Select.Option title={item.stationTypeName} value={item.stationType} key={item.stationType} data-id={'shipment_reciptInfo_station_type' + `${index + 1}`}>
                          {item.stationTypeName}
                        </Select.Option>
                      ))}
                    </SelectLoading>
                  </FormItem>
                </Col>

                {/* 一级地址 */}
                <Col span={6} style={normalVisibleStyle}>
                  <FormItem name="arrival_store_province" label={t('address.form.addressOne')}>
                    <SelectLoading
                      disabled={disables === 'all' || disables.includes('arrival_store_province') || disabledProvince}
                      loading={isLoading}
                      showSearch
                      data-id="shipment_reciptInfo_storeProvince"
                      filterOption={filterOptionStartsWith}
                      placeholder={t('common.select.placeholder')}
                    >
                      {provinces.map((express, index) => (
                        <Select.Option title={express.name} value={express.name} key={express.name} data-id={`shipment_reciptInfo_storeProvinceItem${index + 1}`}>
                          {express.name}
                        </Select.Option>
                      ))}
                    </SelectLoading>
                  </FormItem>
                </Col>

                {/* 二级地址 */}
                <Col span={6} style={normalVisibleStyle}>
                  <FormItem name="arrival_store_city" label={t('address.form.addressTwo')}>
                    <SelectLoading
                      disabled={disables === 'all' || disables.includes('arrival_store_city') || disabledCity}
                      loading={isLoading}
                      showSearch
                      data-id="shipment_reciptInfo_storeCity"
                      filterOption={filterOptionStartsWith}
                      placeholder={t('common.select.placeholder')}
                    >
                      {cities.map((city, index) => (
                        <Select.Option title={city.name} value={city.name} key={city.name} data-id={`shipment_reciptInfo_storeCityItem${index + 1}`}>
                          {city.name}
                        </Select.Option>
                      ))}
                    </SelectLoading>
                  </FormItem>
                </Col>

                {/* 三级地址 */}
                <Col span={6} style={normalVisibleStyle}>
                  <FormItem name="arrival_store_district" label={t('address.form.addressThree')}>
                    <SelectLoading
                      disabled={disables === 'all' || disables.includes('arrival_store_district') || disabledDistrict}
                      loading={isLoading}
                      showSearch
                      data-id="shipment_reciptInfo_storeDistrict"
                      filterOption={filterOptionStartsWith}
                      placeholder={t('common.select.placeholder')}
                    >
                      {districts.map((district, index) => (
                        <Select.Option title={district.name} value={district.name} key={district.name} data-id={`shipment_reciptInfo_storeDistrictItem${index + 1}`}>
                          {district.name}
                        </Select.Option>
                      ))}
                    </SelectLoading>
                  </FormItem>
                </Col>

                {/* 温层 - 黑猫 */}
                <Col span={6} style={blackCatVisibleStyle}>
                  <FormItem hide={hidden || !isCat} rules={[RULES.required]} required name="arrival_store_storage_cdt" label={t('constant.temperature.label')}>
                    <SelectLoading
                      disabled={disables === 'all' || disables.includes('arrival_store_storage_cdt')}
                      loading={isLoading}
                      showSearch
                      data-id="shipment_reciptInfo_storeStorage"
                      filterOption={(input, option) => {
                        const context = option.value.toString().toLowerCase()
                        return context.indexOf(input.toLowerCase()) !== -1
                      }}
                      placeholder={t('common.select.placeholder')}
                    >
                      {STORAGE_LIST.map((item) => (
                        <Select.Option value={item.value} key={item.label} data-id={`shipment_reciptInfo_station_storage${item.label}`}>
                          {t(item.label)}
                        </Select.Option>
                      ))}
                    </SelectLoading>
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={20}>
                {/* 门店 */}
                <Col span={12} style={normalVisibleStyle}>
                  <FormItem hide={hidden || isMap} rules={[RULES.required()]} required name="arrival_store_station" label={t('address.form.store')}>
                    <SelectLoading
                      disabled={disables === 'all' || disables.includes('arrival_store_station') || !arrival_store_companyCode}
                      loading={isLoading}
                      showSearch
                      filterOption={false}
                      onFocus={() => {
                        // 清空门店数据
                        dispatch(actions.resetStationState())
                        dispatch(queryRecipientAddressStoreStation({ pageNum: 1 }))
                      }}
                      onSearch={queryStation}
                      onPopupScroll={handlePopupScroll}
                      data-id="shipment_reciptInfo_storeSearch"
                      placeholder={t('common.select.placeholder')}
                    >
                      {uniqBy(stations, 'stationSeq').map((station) => {
                        let title = ''
                        if (typeof station?.stationType !== 'undefined') {
                          title += allStationTypes?.find((item) => Number(item.key) === Number(station?.stationType))?.value
                        }

                        title += `-${station?.stationName}-${station?.stationCode}, ${station?.stationAddress}`

                        return (
                          <Select.Option title={station?.stationName} value={station?.stationSeq} key={station.stationSeq}>
                            <div className={styles.stationOptionSelected}>{title}</div>
                            <div className={styles.stationOption}>
                              <div className={styles.stationTitle}>
                                {/* 为了不让0的时候丢失 */}
                                {Boolean(station?.stationType !== null && station?.stationType !== undefined) ? (
                                  <div className={styles.stationName}>{allStationTypes?.find((item) => Number(item.key) === Number(station?.stationType))?.value}</div>
                                ) : null}
                                <div className={styles.stationName}>{station?.stationName}</div>
                                <div className={styles.stationId}>({station?.stationCode})</div>
                              </div>
                              <div className={styles.stationAddress}>
                                {station?.stationAddress}
                                {station?.zipCode ? ` (${station?.zipCode})` : null}
                              </div>
                            </div>
                          </Select.Option>
                        )
                      })}
                    </SelectLoading>
                  </FormItem>
                </Col>

                {/* 门店 - 电子地图 */}
                <Col span={12} style={mapVisibleStyle}>
                  <FormItem
                    hide={hidden || !isMap}
                    rules={[
                      {
                        validator: async (_, value) => {
                          if (!value) {
                            throw Error(t('common.formValidRequired'))
                          }
                        },
                      },
                    ]}
                    required
                    name="arrival_store_711station"
                    label={t('serviceProvider.map.address.label')}
                  >
                    <BlackCatSelect
                      disabled={disables === 'all' || disables.includes('arrival_store_711station')}
                      ref={blackCatRef}
                      data-id="shipment_reciptInfo_storeDisplayName"
                      storageCdt={arrival_store_storage_cdt}
                      placeholder={t('common.select.placeholder')}
                      companyCode={arrival_store_companyCode}
                      countryCode={arrival_countryCode}
                    />
                  </FormItem>
                </Col>
              </Row>
            </>
          )
        }}
      </Form.Item>
    </DynamicFormItemGroup>
  )
}

Store.displayName = 'Store'
