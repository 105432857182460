import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OneButton, OnePopover } from '@yy/one-ui'
import type { ISelectItem } from '../../type'
import AddressItem from '../AddressItem'
import { useClickAway } from './hooks'
import styles from './index.module.less'

interface AddressSelectProps {
  options?: ISelectItem[]
  value?: any
  type?: string
  onChange?: (index: number, value: ISelectItem) => void
}

const AddressSelect: React.FC<AddressSelectProps> = ({ options, value, onChange, type }) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState<boolean>(false)

  useClickAway('.ant-popover-inner', () => {
    setVisible(false)
  })

  const handleClick = (index: number, value: ISelectItem) => {
    onChange(index, value)
    setVisible(false)
  }

  return (
    <OnePopover
      placement="bottom"
      overlayClassName={styles.popover}
      content={
        <div className={styles.selectOptions}>
          {options.map((item, index) => (
            <AddressItem key={index} type={type} showLabel selected={index === value} onClick={() => handleClick(index, item)} orderNos={item.orderNos} address={item.address} />
          ))}
        </div>
      }
      visible={visible}
    >
      <AddressItem placeholder focus={visible} address={options[value]?.address}>
        {!visible && <OneButton onClick={() => setVisible(true)}>{t('order.batchCreate.reselect')}</OneButton>}
      </AddressItem>
    </OnePopover>
  )
}

AddressSelect.displayName = 'AddressSelect'
export default AddressSelect
