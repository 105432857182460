import API from '@/services/http'
import type * as Types from './type'

/** 优惠活动弹窗记录接口 */
export const postAddOptEvent = (event: 'os_giveaway') =>
  API.admin.post('/api/price/admin/opt/add-opt-event', {
    event,
  })

/** 是否弹出优惠活动弹窗 */
export const getIsGiveawayPopUp = () => API.admin.post<boolean>('/api/price/admin/opt/is-giveaway-pop-up')

/** 领取金额 */
export const getGiveaway = (data: Types.GetGiveawayReq) => API.noToast.post('/api/price/admin/activity/submit-giveaway', data)

/** 获取优惠活动明细 */
export const getGiveawayActDetail = () => API.admin.post<Types.GiveawayActivityRes>('/api/price/admin/activity/giveaway-activity')
