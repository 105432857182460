export const ADDRESS_TYPE = {
  Departure: 1,
  Arrival: 2,
  Reverse: 3,
} as const

export type AddressType = typeof ADDRESS_TYPE

type AddressRadioType = Record<'SELECT' | 'CUSTOM', 'select' | 'custom'>

export const ADDRESS_RADI_TYPE: AddressRadioType = {
  SELECT: 'select',
  CUSTOM: 'custom',
}
