import API from '@/services/http'
import type { DictionaryInfo } from '@/store/addressSlice/types'

interface providerLogoRespVos {
  /**服务商编码 */
  providerCode?: string
  /**服务商名称 */
  providerName?: string
  /**服务商logo地址 */
  logoUrl?: string
}
/**
 * 服务商字典配置查询
 * @returns
 */
export const queryDictionary = () => API.toast.post<{ providerLogoRespVos: providerLogoRespVos[] }>('/api/provider/logo/list?_sw_cache=15')

/**
 * oneship优化0728需求，订单列表筛选条件-服务商，运单列表筛选条件-承运商，需展示ONESHIP发货地区支持的全部物流服务商
 * 这两个地方的数据新开了一个接口加载
 */
export const queryEfficientProvider = (data?: { logisticsType: string }) =>
  API.toast.post<{ providerLogoRespVos: providerLogoRespVos[] }>('/api/provider/oneship/list', data, { params: { ...data, _sw_cache: 15 } })

export type DictinaryKeyMap =
  | 'COUNTRY_PHONE_AREA_CODE'
  | 'STATION_TYPE_DICT'
  | 'COUNTRY_LIST'
  | 'ONESHIP_COMMON_SETTING'
  | 'COUNTRY_PHONE_AREA_CODE'
  | 'STORE_ORDER_STATUS_LIST'
  | 'ONESHIP_PICK_UP_CALL'
  | 'CUSTOM_TEMPLATE_IMAGE_LIMIT'
  | 'BASIC_CURRENCY_LIST'
  | 'TAX_NO_TYPE_LIST'
  | 'LMS_CARGO_TYPES'
  | 'LMS_SUPPORT_BATTERY'
  | 'LOGISTICS_HAZMAT_TYPE'
/**
 * 整合所有字典配置查询
 * @returns
 */
export const fetchDictionaryByKey = (key: DictinaryKeyMap) =>
  API.toast.post<{ dictionaryInfos: DictionaryInfo[] }>(`/api/dictionary_query/list/v2`, { group: key }, { params: { group: key } }) // params是为了匹配预请求
