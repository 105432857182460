import type { FormInstance } from 'antd'
import React, { createContext, useContext, useMemo } from 'react'

export const FormContext = createContext<FormInstance>(null)
FormContext.displayName = 'FormContext'

export const FormContextProvider: React.FC<FormInstance> = ({ children, ...formMethods }) => {
  const value = useMemo<FormInstance>(() => formMethods, [formMethods])
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>
}

export const useFormContext = () => useContext(FormContext)
