import type { FormInstance } from 'antd'
import { flatten, pick, pickBy } from 'lodash'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { is711CrossBorder, isMapService } from '@/bizComponent/RecipientAddressForm/utils'
import { ARRIVAL_METHOD } from '@/constant/arrivalMethod'
import { getAddressListChildren, getProvinceList } from '@/services/apis'
import { fetchExpressList, fetchStationList, fetchStoreAreaList, fetchSupportMethods } from '@/services/apis/address'
import type { AddressTypeValues } from '@/services/apis/address/types'
import type { AppDispatch, AppGetState } from '@/store'
import { setProps as setProviderProps, updateAddressVO } from '@/store/newServiceProviderSlice'
import type { ISenderAddressFormValues } from '@/store/senderAddressSlice/types'
import { decodeLanguageAndProvince } from '../../utils/address'
import type * as Types from './types'
import type { LogisticsType } from '@/services/apis/sales/type'

export const initialState: Types.InitialState = {
  /** 省列表 */
  provinces: [],
  /** 市列表 */
  cities: [],
  /** 区列表 */
  districts: [],

  towns: [],
  /** 快递方式 */
  supportedMethods: ['home', 'store'],
  /** 禁止的快递方式 */
  disabledMethods: [],
  /** 服务商列表 */
  expresses: [],
  /** 门店地址列表省/市/区 */
  storeAreaList: {},
  /** 搜索门店列表关键字 */
  stationQueryKey: undefined,
  /** 门店列表 */
  stationList: [],
  /** 门店列表是否有下一页 */
  stationListHasNext: true,
  /** 门店列表分页当前页数 */
  stationListPageNum: 1,
  /** 请求中 */
  isLoading: false,
  /** 地址语种 */
  language: '',
}

export const recipientAddressSlice = createSlice({
  name: 'recipientAddressForm',
  initialState,
  reducers: {
    update(state, action: PayloadAction<Partial<Types.InitialState>>) {
      const { payload } = action
      return { ...state, ...payload }
    },
    resetStationState(state) {
      const stationOriginState = pick(initialState, ['stationQueryKey', 'stationList', 'stationListHasNext', 'stationListPageNum'])
      return { ...state, ...stationOriginState }
    },
    clearRecipientAddress(state) {
      return {
        ...state,
        ...pick(initialState, ['provinces', 'cities', 'districts', 'towns', 'language']),
      }
    },
  },
})

export const { actions, reducer } = recipientAddressSlice
export default reducer
export const { clearRecipientAddress } = actions
type AddressFormValues = ISenderAddressFormValues &
  Types.IRecipientAddressFormValues & {
    provider_consignee_name?: string
  }

export interface IInitRecipientAddressFormParams {
  logisticsType?: LogisticsType
  formValues: AddressFormValues
  /** 店配信息 */
  stationRequiredProps?: {
    orderNo: string
  }
  /**是否平台物流，平台物流收货地址可能是异常的，跳过所有校验 */
  isPlatformLogisticsType?: boolean
}

const wrapPromises: Promise<any>[] = []
const wrapLoading = <T extends (...args: any[]) => IPromiseActionThunk<any>>(handle: T): ((...args: Parameters<T>) => IPromiseActionThunk<ReturnType<ReturnType<T>>>) => {
  return (...args: Parameters<T>) =>
    async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance<AddressFormValues>) => {
      let promise = null
      const reset = () => {
        const index = wrapPromises.indexOf(promise)
        index !== -1 && wrapPromises.splice(index, 1)
        wrapPromises.length === 0 && dispatch(actions.update({ isLoading: false }))
      }

      try {
        promise = handle(...args)(dispatch, getState, form)
        wrapPromises.push(promise)

        dispatch(actions.update({ isLoading: true }))
        const result = await promise

        reset()
        return result
      } catch (error) {
        throw error
      } finally {
        reset()
      }
    }
}

/**
 * 初始化收货地址
 * 只会设置name为 arrival_ 开头的表单值
 */
export const initRecipientAddressForm = wrapLoading(
  (params: IInitRecipientAddressFormParams) => async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance<AddressFormValues>) => {
    const { formValues, stationRequiredProps, logisticsType, isPlatformLogisticsType } = params
    const departure_countryCode = formValues?.departure_countryCode || form.getFieldValue('departure_countryCode')

    const {
      arrival_countryCode,
      arrival_province,
      arrival_city,
      arrival_district,
      arrival_store_companyCode,
      arrival_store_province,
      arrival_store_city,
      arrival_method,
      arrival_zipCode,
      arrival_address,
      arrival_addressTwo,
      arrival_store_station,
      arrival_fullName,
      arrival_mobile,
    } = formValues

    const { dictionary } = getState()
    const isInvalidCountry = !dictionary.filterArrivalCountries?.find((item) => item.countryCode === arrival_countryCode)

    if (!isPlatformLogisticsType && (!arrival_countryCode || isInvalidCountry)) {
      return
    }

    /** 店配宅配禁止 */
    const supportedMethods = await fetchSupportMethods({
      departureCountryCode: departure_countryCode,
      arrivalCountryCode: isPlatformLogisticsType ? null : arrival_countryCode,
      logisticsType: logisticsType,
    })
    if (supportedMethods.length === 0) return

    const disabledMethods = (['home', 'store'] as AddressTypeValues[]).filter((name) => -1 === supportedMethods.indexOf(name))
    dispatch(actions.update({ disabledMethods }))
    await dispatch(selectRecipientAddressCountry({ arrival_countryCode, arrival_method, arrival_store_companyCode }))
    arrival_province && (await dispatch(selectRecipientAddressProvince({ arrival_province, arrival_countryCode })))
    arrival_province && arrival_city && (await dispatch(selectRecipientAddressCity({ arrival_city, arrival_countryCode })))
    arrival_province && arrival_city && arrival_district && (await dispatch(selectRecipientAddressDistrict({ arrival_district })))

    arrival_store_companyCode && (await dispatch(selectRecipientAddressStoreCompany({ companyCode: arrival_store_companyCode, countryCode: arrival_countryCode })))
    arrival_store_companyCode && (await dispatch(initStoreProvince({ companyCode: arrival_store_companyCode, arrival_countryCode })))
    arrival_store_province && (await dispatch(selectRecipientAddressStoreProvince({ arrival_countryCode, arrival_store_province, companyCode: arrival_store_companyCode })))
    arrival_store_province &&
      arrival_store_city &&
      (await dispatch(selectRecipientAddressStoreCity({ arrival_countryCode, arrival_store_province, arrival_store_city, companyCode: arrival_store_companyCode })))

    if (departure_countryCode && arrival_method === ARRIVAL_METHOD.store) {
      dispatch(
        fetchRecipientAddressExpress({
          senderAddressName: {
            countryCode: departure_countryCode,
          },
          consigneeAddressName: {
            countryCode: arrival_countryCode,
          },
          needStationType: true,
        })
      )
    }

    if (arrival_store_station && arrival_store_companyCode && stationRequiredProps) {
      dispatch(
        fetchRecipientAddressStoreStation({
          stationSeq: arrival_store_station,
          orderNo: stationRequiredProps.orderNo,
        })
      )
    }

    const updateFormValues = pick(
      formValues,
      Object.keys(formValues).filter((key) => key.startsWith('arrival_'))
    )

    form.setFieldsValue(updateFormValues)

    await dispatch(
      setProviderProps({
        arrival_method,
        addressVO: {
          arrival_countryCode,
          arrival_province,
          arrival_city,
          arrival_district: formValues.arrival_district,
          arrival_town: formValues.arrival_town,
          arrival_store_city,
          arrival_store_province,
          arrival_zipCode,
          arrival_address,
          arrival_addressTwo,
          arrival_fullName,
          arrival_mobile,
        },
      })
    )
  }
)

/** 获取收货地址可视配置 */
export const getRecipientAddressVisibility = (arrival_countryCode?: string) => (dispatch: AppDispatch, getState: AppGetState, form: FormInstance<AddressFormValues>) => {
  const { address } = getState()
  const countryCode = arrival_countryCode || form.getFieldValue('arrival_countryCode')
  const addressConfig = address?.addressConfig?.[countryCode]

  /* 只有省份隐藏 */
  const isProvinceHidden = !addressConfig.provinceVisibility && addressConfig.cityVisibility && addressConfig.districtVisibility

  /* 只有城市隐藏 */
  const isCityHidden = !addressConfig.cityVisibility && addressConfig.provinceVisibility && addressConfig.districtVisibility

  /* 只有区显示 */
  const isOnlyDistrictShow = addressConfig.districtVisibility && !addressConfig.provinceVisibility && !addressConfig.cityVisibility

  return { isProvinceHidden, isCityHidden, isOnlyDistrictShow }
}

export interface ISelectRecipientAddressCountryParams {
  arrival_countryCode: string
  arrival_method?: number
  arrival_store_companyCode?: string
}

/** 改变收货国家 */
export const selectRecipientAddressCountry = wrapLoading(
  (params: ISelectRecipientAddressCountryParams) => async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance<AddressFormValues>) => {
    const { setFields, getFieldsValue } = form
    const formValues = getFieldsValue()
    const { arrival_countryCode, arrival_method = formValues.arrival_method, arrival_store_companyCode } = params
    const setFieldsValue = (data) => {
      setFields(
        Object.keys(data).map((k) => ({
          name: k,
          value: data[k],
          errors: [],
        }))
      )
    }

    dispatch(
      actions.update({
        provinces: [],
        cities: [],
        districts: [],
        towns: [],
        storeAreaList: {},
      })
    )
    setFieldsValue({
      arrival_province: undefined,
      arrival_city: undefined,
      arrival_district: undefined,
      arrival_town: undefined,
      arrival_address: undefined,
      arrival_addressTwo: undefined,
      arrival_zipCode: undefined,

      arrival_store_companyCode: undefined,
      arrival_store_province: undefined,
      arrival_store_city: undefined,
      arrival_store_district: undefined,
      arrival_store_station: null,
    })

    //  店配711跨境特殊逻辑需要宅配地址
    if (arrival_method === ARRIVAL_METHOD.home || (arrival_method === ARRIVAL_METHOD.store && is711CrossBorder(arrival_store_companyCode))) {
      dispatch(
        getReceiveProvinceList({
          countryCode: arrival_countryCode,
        })
      )

      dispatch(updateAddressVO({ arrival_countryCode: arrival_countryCode }))
    } else {
      // 电子地图不考虑请求门店地址库
      if (!isMapService(arrival_store_companyCode) && arrival_method === ARRIVAL_METHOD.store) {
        setFieldsValue({
          arrival_station_type: undefined,
          arrival_store_province: undefined,
          arrival_store_city: undefined,
          arrival_store_district: undefined,
          arrival_store_station: undefined,
        })
      }
    }
  }
)

/** get provicne list by params */
export const getReceiveProvinceList = (params: Parameters<typeof getProvinceList>[0]) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { language, dictionary } = getState()
  const arrivalCountry = dictionary?.filterArrivalCountries?.find((item) => {
    return item.countryCode === params.countryCode
  })

  const provinceResponse = await getProvinceList({
    countryCode: params.countryCode,
    languageOrder: [language.languageType, 'en', arrivalCountry?.language].filter(Boolean),
  })

  const provinces = provinceResponse?.data?.results || []
  dispatch(actions.update({ provinces }))
}

export interface ISelectRecipientAddressProvinceParams {
  arrival_province: string
  arrival_countryCode?: string
}

/** 获取收货地址省 */
export const selectRecipientAddressProvince =
  (params: ISelectRecipientAddressProvinceParams) => async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance<AddressFormValues>) => {
    const { isCityHidden } = dispatch(getRecipientAddressVisibility(params?.arrival_countryCode))
    const { arrival_province: provinceName } = params
    const arrival_countryCode = params.arrival_countryCode || form.getFieldsValue().arrival_countryCode

    const { province: arrival_province, language } = decodeLanguageAndProvince(provinceName)
    dispatch(
      actions.update({
        cities: [],
        districts: [],
        towns: [],
        language,
      })
    )
    const cities = await (async () => {
      // 没有语言，请求也会报错
      if (!language) return []
      const citiesResponse = await getAddressListChildren({
        countryCode: arrival_countryCode,
        parentAddressLevel: 1,
        parentAddressLanguage: language,
        parentAddressName: arrival_province,
        addressChildrenLevelNumber: 3,
      })

      const cities = citiesResponse?.data?.nodes || []

      return cities
    })()

    if (isCityHidden) {
      /** 市隐藏的情况 省变化 -> 更新区 */
      let districts = [].concat(...cities.map((item) => item.child).filter(Boolean))
      // 地址扁平化后端无法排序，地址如果是英文就需要排序
      if (language === 'en') {
        districts = districts.sort((a, b) => (a.name > b.name ? 1 : -1))
      }

      form.setFieldsValue({ arrival_district: undefined, arrival_town: undefined })
      dispatch(actions.update({ districts: districts, cities }))
      return
    }

    form.setFieldsValue({ arrival_city: undefined, arrival_district: undefined, arrival_town: undefined })
    dispatch(updateAddressVO({ arrival_province, arrival_city: undefined, arrival_district: undefined, arrival_town: undefined }))

    dispatch(actions.update({ cities }))
    dispatch(actions.update({ districts: [] }))
  }

export interface ISelectRecipientAddressCityParams {
  arrival_city: string
  arrival_countryCode?: string
}

/**
 * 选择收货地址市
 * 初始化也应用这种
 */
export const selectRecipientAddressCity = (params: ISelectRecipientAddressCityParams) => (dispatch: AppDispatch, getState: AppGetState, form: FormInstance<AddressFormValues>) => {
  const { recipientAddressForm: state } = getState()
  const { cities } = state
  const { isCityHidden } = dispatch(getRecipientAddressVisibility(params?.arrival_countryCode))
  const { arrival_city } = params

  // 初始化的情况兼容
  const { language } = state

  const districts = (() => {
    if (cities?.length) {
      /* 市隐藏的情况 */
      if (isCityHidden) {
        // 初始化的时候会覆盖province初始化的district值, 因此需要重新根据语言进行重新设置
        let dis = flatten(cities.map((item) => item?.child)).filter(Boolean)

        if (language === 'en') {
          dis = dis.sort((a, b) => (a.name > b.name ? 1 : -1))
        }
        return dis
      }

      const city = cities.find((item) => item?.name === arrival_city)
      return city?.child || []
    }

    return []
  })()
  // clear district and town
  form.setFieldsValue({ arrival_district: undefined, arrival_town: undefined })

  dispatch(actions.update({ districts }))
  dispatch(actions.update({ towns: [] }))
}

export interface ISelectRecipientAddressDistrictParams {
  arrival_district: string
  arrival_countryCode?: string
}

/** 选择收货地址区 */
export const selectRecipientAddressDistrict =
  (params: ISelectRecipientAddressDistrictParams) => async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance<AddressFormValues>) => {
    const { recipientAddressForm: state } = getState()
    const { isCityHidden } = await dispatch(getRecipientAddressVisibility())

    const { districts } = state
    const { arrival_district } = params
    const district = districts.find((item) => item.name === arrival_district)

    dispatch(updateAddressVO({ arrival_district, arrival_town: undefined }))
    // clear town
    form.setFieldsValue({ arrival_town: undefined })

    dispatch(
      actions.update({
        towns: district?.child || [],
      })
    )

    // 马来西亚旧数据如果有市，切换区后会带着错误的区过去
    if (isCityHidden) {
      form.setFieldsValue({ arrival_city: undefined })
    }
  }

/** 获取店配服务商列表 */
export const fetchRecipientAddressExpress = wrapLoading((params: Types.FetchExpressListParams) => async (dispatch: AppDispatch) => {
  const expresses = await fetchExpressList({ ...params, expressLogisticsType: 2 })
  dispatch(actions.update({ expresses }))
})

/** 获取店配地址省/市/区 */
export const fetchRecipientAddressStoreArea = wrapLoading((name: string, params: Types.FetchStationAreaListParams) => async (dispatch: AppDispatch, getState: AppGetState) => {
  try {
    const { recipientAddressForm: state } = getState()
    const { storeAreaList: originStoreAreaList } = state
    const areas = await fetchStoreAreaList(params)

    const storeAreaList = {
      ...originStoreAreaList,
      [name]: areas,
    }

    dispatch(actions.update({ storeAreaList }))
  } catch (error) {}
})

/** 获取门店列表 | 收货地址 - 店配 */
export const fetchRecipientAddressStoreStation = wrapLoading((params: Types.FetchStationListParams) => async (dispatch: AppDispatch) => {
  const { data: stationList, hasNext: stationListHasNext, pageNum: stationListPageNum } = await fetchStationList(params)
  dispatch(actions.update({ stationList, stationListHasNext, stationListPageNum }))
})

/** 获取门店列表分页 | 收货地址 - 店配 */
export const fetchRecipientAddressStoreStationPagination = wrapLoading((params: Types.FetchStationListParams) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { recipientAddressForm: state } = getState()
  const { data: stationList, hasNext: stationListHasNext, pageNum: stationListPageNum } = await fetchStationList(params)

  if ((params as any).pageNum === 1) {
    dispatch(
      actions.update({
        stationList,
        stationListHasNext,
        stationListPageNum,
      })
    )

    return
  }

  const { stationList: originStationList } = state
  dispatch(
    actions.update({
      stationList: [].concat(originStationList, stationList),
      stationListHasNext,
      stationListPageNum,
    })
  )
})

/** 选中服务商 */
export const selectRecipientAddressStoreCompany = wrapLoading(
  (params?: { companyCode?: string; countryCode: string }) => async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance<AddressFormValues>) => {
    const { setFieldsValue } = form

    // 711跨境切换服务商不需要清空地址列表
    if (is711CrossBorder(params?.companyCode)) {
      // 获取地址列表
      dispatch(getReceiveProvinceList({ countryCode: params.countryCode }))
    } else {
      dispatch(actions.update({ provinces: [], stationList: [] }))
    }

    setFieldsValue({
      arrival_station_type: undefined,
      arrival_store_province: undefined,
      arrival_store_city: undefined,
      arrival_store_district: undefined,
      arrival_store_station: undefined,
    })
  }
)

/** 选中门店类型 */
export const selectRecipientAddressStoreStationType = () => (dispatch: AppDispatch, getState: AppGetState, form: FormInstance<AddressFormValues>) => {
  form.setFieldsValue({
    arrival_store_province: undefined,
    arrival_store_city: undefined,
    arrival_store_district: undefined,
    arrival_store_station: undefined,
  })
}

export interface ISelectRecipientAddressStoreProvinceParams {
  arrival_countryCode?: string
  arrival_store_province: string
  companyCode?: string
}

export const initStoreProvince = wrapLoading(
  (params: Omit<ISelectRecipientAddressStoreProvinceParams, 'arrival_store_province'>) =>
    async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance<AddressFormValues>) => {
      const { getFieldsValue, setFieldsValue } = form
      const formValues = getFieldsValue()
      const { arrival_countryCode = formValues.arrival_countryCode, companyCode } = params

      await dispatch(fetchRecipientAddressStoreArea('province', { countryCode: arrival_countryCode, companyCode }))

      setFieldsValue({
        arrival_store_city: undefined,
        arrival_store_district: undefined,
        arrival_store_station: undefined,
      })
    }
)

/** 选中店配省 */
export const selectRecipientAddressStoreProvince = wrapLoading(
  (params: ISelectRecipientAddressStoreProvinceParams) => async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance<AddressFormValues>) => {
    const { getFieldsValue, setFieldsValue } = form
    const formValues = getFieldsValue()
    const { arrival_countryCode = formValues.arrival_countryCode, arrival_store_province, companyCode } = params

    await dispatch(
      fetchRecipientAddressStoreArea('city', {
        countryCode: arrival_countryCode,
        province: arrival_store_province ?? undefined,
        companyCode,
      })
    )

    setFieldsValue({
      arrival_store_city: undefined,
      arrival_store_district: undefined,
      arrival_store_station: undefined,
    })
  }
)

export interface ISelectRecipientAddressStoreCityParams {
  arrival_countryCode?: string
  arrival_store_province?: string
  arrival_store_city: string
  companyCode?: string
}

/** 选中店配市 */
export const selectRecipientAddressStoreCity = wrapLoading(
  (params: ISelectRecipientAddressStoreCityParams) => async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance<AddressFormValues>) => {
    const { getFieldsValue, setFieldsValue } = form
    const formValues = getFieldsValue()
    const { arrival_store_city, arrival_countryCode = formValues.arrival_countryCode, arrival_store_province = formValues.arrival_store_province, companyCode } = params

    await dispatch(
      fetchRecipientAddressStoreArea('district', {
        countryCode: arrival_countryCode,
        province: arrival_store_province ?? undefined,
        city: arrival_store_city ?? undefined,
        companyCode,
      })
    )

    setFieldsValue({
      arrival_store_district: undefined,
      arrival_store_station: undefined,
    })
  }
)

export interface IQueryRecipientAddressStoreStationKeywordParams {
  queryKey: string
  countryCode?: string
  companyCode?: string
  stationType?: number
  pageNum?: number
}

export interface IQueryRecipientAddressStoreStationAddressParams {
  countryCode?: string
  companyCode?: string
  stationType?: number
  pageNum?: number
  province?: string
  city?: string
  district?: string
}

export interface IPromiseActionThunk<T = void> {
  (dispatch: AppDispatch, getState: AppGetState, form: FormInstance<AddressFormValues>): Promise<T>
}

/** 搜索门店列表 | 收货地址 - 店配 */
interface IQueryRecipientAddressStoreStation {
  (params: IQueryRecipientAddressStoreStationKeywordParams): IPromiseActionThunk
  (params: IQueryRecipientAddressStoreStationAddressParams): IPromiseActionThunk
}

export const queryRecipientAddressStoreStation: IQueryRecipientAddressStoreStation = wrapLoading(
  (params: IQueryRecipientAddressStoreStationKeywordParams | IQueryRecipientAddressStoreStationAddressParams) =>
    async (dispatch: AppDispatch, getState: AppGetState, form: FormInstance<AddressFormValues>) => {
      const { getFieldsValue } = form
      const formValues = getFieldsValue()
      const { recipientAddressForm } = getState()

      const { expresses = [] } = recipientAddressForm

      const { pageNum = 1, countryCode = formValues.arrival_countryCode, companyCode = formValues.arrival_store_companyCode } = params

      // if there is not the stationType, when search not bring it up to the backend point.
      const stationTypeMap = new Map(
        expresses.filter((item) => item.supportedAddressType.includes('STORE_STREET')).map((item) => [item.companyCode as string, item.stationTypes ?? []])
      )
      const stationTypes = stationTypeMap?.get(formValues?.arrival_store_companyCode) || []
      const stationType = stationTypes?.length ? formValues?.arrival_station_type : undefined

      if ('queryKey' in params) {
        const keywords = params.queryKey
        dispatch(actions.update({ stationQueryKey: keywords }))
        await dispatch(fetchRecipientAddressStoreStationPagination({ countryCode, companyCode, stationType, keywords, pageNum }))
      } else {
        const optionalPayload = {
          province: params.province || formValues.arrival_store_province,
          city: params.city || formValues.arrival_store_city,
          district: params.district || formValues.arrival_store_district,
        }

        const paylad = pickBy<typeof optionalPayload>(optionalPayload, Boolean)
        await dispatch(fetchRecipientAddressStoreStationPagination({ countryCode, companyCode, stationType, pageNum, ...paylad }))
      }
    }
) as any

/** 搜索门店列表分页 | 收货地址 - 店配 */
export const queryRecipientAddressStoreStationPagination = wrapLoading(() => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { recipientAddressForm: state } = getState()
  const { stationQueryKey, stationListPageNum } = state

  const params: Partial<IQueryRecipientAddressStoreStationKeywordParams> = {
    pageNum: stationListPageNum + 1,
  }

  if (stationQueryKey) {
    params.queryKey = stationQueryKey
  }

  await dispatch(queryRecipientAddressStoreStation(params))
})
