import React from 'react'
import { Fallback } from '@/components/Async/components/Fallback'
import { useVersionUpdate } from '@/hooks'

const GlobalUpdateModal: React.FC = () => {
  const hasBeenUpdate = useVersionUpdate()
  return hasBeenUpdate ? <Fallback></Fallback> : null
}

GlobalUpdateModal.displayName = 'GlobalUpdateModal'

export default GlobalUpdateModal
