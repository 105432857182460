import type { ValuesType } from 'utility-types'
import type { IGuideInfo } from '@/store/commonSlice/types'
import type { IWallInfo } from '@/store/userSlice/types'
import type { SocketListener } from './socket'
import { Socket } from './socket'

/** 消息类型 */
export const TOPICS = {
  USER_MESSAGE: 'broadcast://user_message',
  EXPORT_LIST: 'broadcast://export_list_message',
  ASYNC_RESULT: 'broadcast://async_result_event',
  VERSION_UPDATE: 'broadcast://version_update',
  /** 钱包余额更新 */
  BALANCE_UPDATE: 'broadcast://oneship/wallet_deposit_message',
  /** 新手指引更新 */
  GUIDE_TASK_CHANGE_EVENT: 'broadcast://oneship/guide_task_change_event',
} as const

/** 消息类型 */
export type Topic = ValuesType<typeof TOPICS>

export interface SocketVersionUpdatePayload {
  version: string
  uid: string
}

export class OneShipSocket extends Socket<Topic> {
  static readonly TOPICS = TOPICS

  public connect() {
    const topics = Object.keys(TOPICS).map((name) => TOPICS[name])
    this.registerTopics(topics)
  }

  public onUserMessage<P = any>(handle: SocketListener<P>) {
    return this.on(TOPICS.USER_MESSAGE, handle)
  }

  public onExportListMessage<P = any>(handle: SocketListener<P>) {
    return this.on(TOPICS.EXPORT_LIST, handle)
  }

  public onAsyncResult<P = any>(handle: SocketListener<P>) {
    return this.on(TOPICS.ASYNC_RESULT, handle)
  }

  public onVersionUpdate(handle: SocketListener<SocketVersionUpdatePayload>) {
    return this.on(TOPICS.VERSION_UPDATE, handle)
  }

  public onBalanceUpdate(handle: SocketListener<IWallInfo>) {
    return this.on(TOPICS.BALANCE_UPDATE, handle)
  }

  public onGuideInfoUpdate(handle: SocketListener<IGuideInfo>) {
    return this.on(TOPICS.GUIDE_TASK_CHANGE_EVENT, handle)
  }
}
