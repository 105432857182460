import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { LanguageType } from '@/constant'
import type { AppDispatch, AppGetState } from '@/store'
import { updateIntercomLanguage } from '@/utils/intercom'

const initialState: LanguageType = {
  label: 'English',
  lang: 'en',
  languageType: 'en',
  antd: 'en_US',
}

const languageSlice = createSlice({
  name: 'language',
  initialState: initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<LanguageType>): LanguageType {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

/* eslint-disable no-console  */
export const changeLanguage = (lang: LanguageType) => (dispatch: AppDispatch, getState: AppGetState) => {
  dispatch(languageSlice.actions.setLanguage(lang))
  window.localStorage.setItem('os-lang', JSON.stringify(lang))

  // update intercom language
  const { user } = getState()
  if (user.isLogined) {
    updateIntercomLanguage()
  }
}

export const { setLanguage } = languageSlice.actions
export default languageSlice.reducer
