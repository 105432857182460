import type { ValuesType } from 'utility-types'

/**
 * @todo 统一 ARRIVAL_METHOD 和 ARRIVAL_METHOD_BACKEND_VERSION 为 1, 4 一个版本
 */
export const ARRIVAL_METHOD = {
  home: 1,
  store: 2,
}

export const ARRIVAL_METHOD_BACKEND_VERSION = {
  home: 1,
  store: 4,
}

export const ARRIVAL_METHOD_LIST = [
  {
    value: ARRIVAL_METHOD.home,
    label: 'order.list.houseDelivery',
  },
  {
    value: ARRIVAL_METHOD.store,
    label: 'order.list.storeDelivery',
  },
]

export const ARRIVAL_METHOD_LIST_STRING_VERSION = [
  {
    value: ARRIVAL_METHOD_BACKEND_VERSION.home,
    label: 'order.list.houseDelivery',
  },
  {
    value: ARRIVAL_METHOD_BACKEND_VERSION.store,
    label: 'order.list.storeDelivery',
  },
]

export type AddressArrivalMethod = typeof ARRIVAL_METHOD
export type AddressArrivalMethodKeys = keyof AddressArrivalMethod
export type AddressArrivalMethodValues = ValuesType<AddressArrivalMethod>
