import options2Map from '@/utils/options2Map'

export const CHARGE_MODES = {
  /** 重量区间 */
  FIX_WEIGHT: 'fix_weight_interval',
  /** 首续重 */
  FIRST_WEIGHT: 'inc_weight_interval',
  /** 固定运费 */
  FIX_FREIGHT: 'fix',
  /** oneship运费 */
  ONESHIP_FREIGHT: 'os_freight',
} as const

/** 计算方式 */
export const PRICE_METHOD_OPTIONS = [
  {
    label: 'freight.weight.block', // 重量区间
    value: CHARGE_MODES.FIX_WEIGHT,
  },
  {
    label: 'freight.weight.firstContinue', // 首续重
    value: CHARGE_MODES.FIRST_WEIGHT,
  },
  {
    label: 'freight.setting.fix', // 固定运费
    value: CHARGE_MODES.FIX_FREIGHT,
  },
  {
    label: 'freight.oneship', // oneship运费
    value: CHARGE_MODES.ONESHIP_FREIGHT,
  },
]

export const PRICE_METHOD_OPTIONS_MAPS = options2Map(PRICE_METHOD_OPTIONS, 'value', 'label')
