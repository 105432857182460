import { flatten } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import type { AreaFormNameTypes } from '@/bizComponent/AntdForm'
import { useFormContext } from '@/bizComponent/AntdForm'
import { useAddressLocalLanage } from '@/hooks'
import { getAddressListChildren, getProvinceList } from '@/services/apis/address'
import type { IAdddressNode, IProvinceResVO } from '@/services/apis/address/types'
import { useSelector } from '@/store'
import { useDebounceFn, usePrevious } from 'ahooks'

export type IDefaultValue = {
  province?: string
  city?: string
  district?: string
  town?: string
  language?: string
}

export function useVertifyConfig(config: { countryCode: string; showRequired?: boolean; areaType: 'departure' | 'arrival' }) {
  const { showRequired = true, countryCode, areaType } = config

  /* 必填的字段 */
  const verifyFieldMap = useSelector((state) => (areaType === 'departure' ? state.address.verifyFieldMap : state.address.consigneeVerifyFieldMap))

  /* 地址显隐 */
  const addressConfig = useSelector((state) => state.address?.addressConfig?.[countryCode])

  /* 只有城市隐藏 */
  const isCityHidden = !addressConfig.cityVisibility && addressConfig.provinceVisibility && addressConfig.districtVisibility

  /* 只有省份隐藏 */
  const isProvinceHidden = !addressConfig.provinceVisibility && addressConfig.cityVisibility && addressConfig.districtVisibility

  /* 只有区显示 */
  const isOnlyDistrictShow = addressConfig.districtVisibility && !addressConfig.provinceVisibility && !addressConfig.cityVisibility

  // 显示才再判断是否需要校验
  const provinceRequired = !!addressConfig.provinceVisibility && showRequired && !!+verifyFieldMap[countryCode]?.province
  const cityRequired = !!addressConfig.cityVisibility && showRequired && !!+verifyFieldMap[countryCode]?.city
  const districtRequired = !!addressConfig.districtVisibility && showRequired && !!+verifyFieldMap[countryCode]?.district
  const townRequired = !!addressConfig.townVisibility && showRequired && !!+verifyFieldMap[countryCode]?.town

  return {
    config: {
      provinceRequired,
      cityRequired,
      districtRequired,
      townRequired,
      isCityHidden,
      isProvinceHidden,
      isOnlyDistrictShow,
      addressConfig,
    },
  }
}

export interface UseHelpersParams {
  countryCode: string
  showRequired?: boolean
  areaType: 'departure' | 'arrival'
  useFormPrefix?: boolean
  /**
   * 使用副作用
   * @todo 去除所有副作用
   * @see /bizComponent/AddressEditModal/CountryAreaForm.tsx
   */
  sideEffect?: boolean
}

export function useHelpers(params: UseHelpersParams) {
  const { areaType, countryCode, useFormPrefix = true, sideEffect = true } = params
  const { config } = useVertifyConfig(params)
  const { _t } = useAddressLocalLanage()

  const [provinceLoading, setProvinceLoading] = useState(false)
  const [childrenLoading, setChildrenLoading] = useState(false)

  const [provinces, setProvinces] = useState<IProvinceResVO[]>([]) // 城市列表
  const [cities, setCities] = useState<IAdddressNode[]>([]) // 城市列表
  const [districts, setDistricts] = useState<IAdddressNode[]>([]) // 区列表
  const [towns, setTowns] = useState<IAdddressNode[]>([]) // town列表

  const languageStore = useSelector((state) => state.language)

  const filterSenderCountries = useSelector((state) => state.dictionary.filterSenderCountries)
  const filterArrivalCountries = useSelector((state) => state.dictionary.filterArrivalCountries)
  const selectedCountry = useMemo(() => {
    if (areaType === 'departure') {
      return filterSenderCountries.find((item) => item.countryCode === countryCode)
    }

    return filterArrivalCountries.find((item) => item.countryCode === countryCode)
  }, [areaType, filterSenderCountries, filterArrivalCountries, countryCode])

  /* 表单name */
  const formName = useCallback((name: AreaFormNameTypes) => (useFormPrefix ? `${areaType}_${name}` : name), [areaType, useFormPrefix])
  const [provinceName, cityName, districtName, townName, language] = [formName('province'), formName('city'), formName('district'), formName('town'), formName('language')]

  const { setFieldsValue, getFieldValue } = useFormContext()

  const getListChildren = useCallback(
    async ({ language, name }) => {
      const countryCode = getFieldValue(formName('countryCode'))
      setChildrenLoading(true)

      return getAddressListChildren({
        countryCode,
        parentAddressLevel: 1,
        parentAddressLanguage: language,
        parentAddressName: name,
        addressChildrenLevelNumber: 3,
      })
        .then((res) => {
          return res?.data?.nodes || []
        })
        .finally(() => {
          setChildrenLoading(false)
        })
    },
    [formName, getFieldValue]
  )

  /** 区变化 -> 重置town */
  const resetTown = useCallback(
    (townList?: IAdddressNode[], value?: IDefaultValue) => {
      setTowns(townList || [])
      setFieldsValue({
        [townName]: value?.town || undefined,
      })
    },
    [setFieldsValue, townName]
  )

  /** 市变化 -> 重置区 */
  const resetDistrict = useCallback(
    (districtList: IAdddressNode[], value?: IDefaultValue) => {
      setDistricts(districtList || [])

      if (value?.district) {
        const curDistrict = districtList.find((district) => district.name === value.district)

        const towns = curDistrict?.child || []
        resetTown(towns || [], value)
      } else {
        resetTown([], value)
      }

      setFieldsValue({
        [districtName]: value?.district || undefined,
      })

      // 马来西亚旧数据如果有市，切换区后会带着错误的区过去
      if (config?.isCityHidden) {
        setFieldsValue({
          [cityName]: undefined,
        })
      }
    },
    [cityName, config?.isCityHidden, districtName, resetTown, setFieldsValue]
  )

  /** 省变化 -> 重置市 */
  const resetCity = useCallback(
    (cityList: IAdddressNode[], value?: IDefaultValue) => {
      setCities(cityList || [])
      /** 市隐藏的情况 省变化 -> 重置区 */

      if (config?.isCityHidden) {
        const languge = getFieldValue(formName('language'))
        let districts = flatten(cityList.map((item) => item.child))
        // 区地址扁平化导致，不会显示城市，因此区是英文的话就需要排序
        if (languge === 'en') {
          districts = districts.sort((a, b) => (a.name > b.name ? 1 : -1))
        }

        resetDistrict(districts, value)
        setFieldsValue({
          [cityName]: value?.city || undefined,
        })
        return
      }

      if (value?.city) {
        const curCity = cityList.find((city) => city.name === value.city)
        const districts = curCity?.child || []
        resetDistrict(districts || [], value)
      } else {
        resetDistrict([], value)
      }

      setFieldsValue({
        [cityName]: value?.city || undefined,
      })
    },
    [config?.isCityHidden, setFieldsValue, cityName, getFieldValue, formName, resetDistrict]
  )

  /** 国家变化 -> 重置省 */
  const resetProvinceWithoutDebounce = useCallback(
    async (province?: IDefaultValue) => {
      if (province) {
        const prefix = province.language ? `${province.language}_` : ''
        const provinceNameValue = province.province ? `${prefix}${province.province}` : undefined

        setFieldsValue({
          [provinceName]: provinceNameValue,
        })
      } else {
        setFieldsValue({
          [provinceName]: undefined,
        })
      }

      setFieldsValue({
        [language]: province?.language || undefined,
      })

      if (province?.language && province?.province) {
        const cities = await getListChildren({
          language: province.language,
          name: province.province,
        })

        resetCity(cities, province)
      } else {
        resetCity([], province)
      }
    },
    [getListChildren, language, provinceName, resetCity, setFieldsValue]
  )

  const { run: resetProvince } = useDebounceFn(resetProvinceWithoutDebounce, {
    wait: 500,
  })

  /* 省份改变 */
  const provinceChange = useCallback(
    async (_, data) => {
      if (typeof data !== 'object') {
        return
      }

      const { language, name } = data
      resetProvince({
        language,
        province: name,
      })
    },
    [resetProvince]
  )

  /* 城市改变 */
  const cityChange = useCallback(
    (cityName) => {
      const districts = (() => {
        if (cities?.length) {
          const city = cities.find((item) => {
            return item?.name === cityName
          })

          return city?.child || []
        }
        return []
      })()

      resetDistrict(districts)
    },
    [cities, resetDistrict]
  )

  /** 区改变 */
  const districtChange = useCallback(
    (districtName: string) => {
      const district = districts.find((item) => item.name === districtName)

      resetTown(district?.child || [])

      setFieldsValue({
        [townName]: undefined,
      })
    },
    [districts, resetTown, setFieldsValue, townName]
  )

  const fetchProvince = useCallback(
    async (keyword?: string) => {
      setProvinceLoading(true)
      setProvinces([])

      return getProvinceList({
        countryCode,
        provinceSearchName: keyword,
        languageOrder: [languageStore?.languageType, 'en', selectedCountry?.language].filter(Boolean),
      })
        .then((res) => {
          const list = res.data?.results?.map((item) => {
            return {
              ...item,
              nodes: item.nodes.map((item) => ({
                ...item,
                key: `${item.language}_${item.name}`,
              })),
            }
          })
          setProvinces(list)
          return list
        })
        .finally(() => {
          setProvinceLoading(false)
        })
    },
    [selectedCountry, countryCode, setProvinces, languageStore?.languageType]
  )

  const getSingleAreaCode = useCallback(
    (type: AreaFormNameTypes, addressName: string) => {
      if (type === 'province') {
        const list = flatten(provinces.map((item) => item.nodes))

        const item = list.find((item) => `${item.language}_${item.name}` === addressName)

        if (!item) return ''

        return item.code
      }
      const areaList = (() => {
        if (type === 'city') {
          return cities
        }
        if (type === 'district') {
          return districts
        }
        if (type === 'town') {
          return towns
        }
        return []
      })()

      const item = areaList.find((item) => item.name === addressName)
      return item?.code || ''
    },
    [cities, districts, provinces, towns]
  )

  /** 获取地址code字段 */
  const getAreaCode = useCallback(() => {
    return {
      provinceCode: getSingleAreaCode('province', getFieldValue(provinceName)),
      cityCode: getSingleAreaCode('city', getFieldValue(cityName)),
      districtCode: getSingleAreaCode('district', getFieldValue(districtName)),
      townCode: getSingleAreaCode('town', getFieldValue(townName)),
    }
  }, [cityName, districtName, getFieldValue, getSingleAreaCode, provinceName, townName])

  const prevCountryCode = usePrevious(countryCode)

  // 国家重新选择后，需要清空
  useEffect(() => {
    if (countryCode) {
      fetchProvince()

      /**
       * 因为 province list 输入内部
       * 因此无法通过外部修改，必须使用 store 或 context
       * 所以 fetchProvince 不能受 sideEffect === false 影响
       * @todo
       * 统一使用 context 或 store 存储
       */
      if (sideEffect !== true) {
        return
      }

      /**
       * 这里缺少初始化有值与编辑的情况
       * @todo useEffect 会导致外部发生改变，后续应该让外面调用
       * 确保外部逻辑不受影响后去除
       */

      // 只有国家变更才重置省份
      if (prevCountryCode !== countryCode) {
        resetProvinceWithoutDebounce()
      }
    }
  }, [sideEffect, prevCountryCode, countryCode, resetProvinceWithoutDebounce, fetchProvince])

  return {
    resetProvince,
    provinceChange,
    cityChange,
    districtChange,

    provinces,
    cities,
    districts,
    towns,
    config,
    setProvinces,

    _t,

    getAreaCode,

    childrenLoading,
    provinceLoading,

    fetchProvince,

    resetProvinceWithoutDebounce,

    names: {
      provinceName,
      cityName,
      districtName,
      townName,
      language,
    },
  }
}
