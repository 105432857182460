import type { RouteConfig } from 'react-router-config'
import { RealTimeLogistics } from '@yy/one-icon'
import { withAsync } from '@/components/Async'
import { withAuthRedirect } from '@/components/Authorized/hoc/withAuthRedirect'
import Blank from '@/layouts/Blank'
import { countryIs } from '@/utils/countryVersion'
import { withAddressSplitAlgorithmTrackPage } from '@/bizComponent/AddressAutoSplit/AddressSplitAlgorithmTrack'

/** 即时物流显示的国家 */
export const showCountryStation = ['HK', 'TW', 'SG', 'MY', 'TH', 'VN', 'ID']

const routes: RouteConfig[] = countryIs.CN
  ? []
  : [
      {
        alias: 'immediate',
        path: '/immediate',
        component: withAuthRedirect(Blank, { first: '/immediate/order/list' }),
        menuTitle: 'menu.immediate',
        group: 'immediate',
        showCountryCodes: showCountryStation,
        icon: RealTimeLogistics,
        permissionCode: 'immediate:order,immediate:logistics',
        routes: [
          {
            path: '/order',
            component: withAsync(() => import(/* webpackChunkName: "Immediate/Order/List" */ '@/containers/Immediate/Order/List')),
            menuTitle: 'frame.order',
            pageDescription: 'immediate.order.desc',
            permissionCode: 'immediate:order',
            exact: true,
          },
          {
            path: '/order/batchShip',
            component: withAddressSplitAlgorithmTrackPage(
              withAsync(() => import(/* webpackChunkName: "Immediate/Order/BatchShip/List" */ '@/containers/Immediate/Order/BatchShip/List')),
              'orderCreate'
            ),
            exact: true,
          },
          {
            path: '/order/batchShip/edit/:orderNo',
            component: withAddressSplitAlgorithmTrackPage(
              withAsync(() => import(/* webpackChunkName: "Immediate/Order/BatchShip/EditOrder" */ '@/containers/Immediate/Order/BatchShip/EditOrder')),
              'orderShip'
            ),
            exact: true,
          },
          // 运单管理
          {
            path: '/logistics',
            component: withAsync(() => import(/* webpackChunkName: "Immediate/Logistics/List" */ '@/containers/Immediate/Logistics/List')),
            menuTitle: 'frame.logisticOrders',
            pageDescription: 'frame.logisticOrders.desc',
            permissionCode: 'immediate:logistics',
            exact: true,
          },
          {
            path: '/order/create',
            component: withAddressSplitAlgorithmTrackPage(
              withAsync(() => import(/* webpackChunkName: "Immediate/Order/Create" */ '@/containers/Immediate/Order/Create')),
              'orderCreate'
            ),
            exact: true,
          },
          {
            path: '/order/batchCreateNew',
            component: withAddressSplitAlgorithmTrackPage(
              withAsync(() => import(/* webpackChunkName: "Immediate/Order/BatchCreate" */ '@/containers/Immediate/Order/batchCreateNew')),
              'orderCreate'
            ),
            exact: true,
          },
          {
            path: '/order/detail/:id/:appId',
            component: withAsync(() => import(/* webpackChunkName: "Immediate/Order/Detail" */ '@/containers/Immediate/Order/Detail')),
            exact: true,
          },
          {
            path: '/order/edit/:orderNo',
            component: withAddressSplitAlgorithmTrackPage(
              withAsync(() => import(/* webpackChunkName: "Immediate/Order/Edit" */ '@/containers/Immediate/Order/Edit')),
              'orderShip'
            ),
            exact: true,
          },
          {
            path: '/logistics/detail/:appId/:logisticsNo',
            component: withAsync(() => import(/* webpackChunkName: "Immediate/Logistics/Detail" */ '@/containers/Immediate/Logistics/Detail')),
            exact: true,
          },
          {
            path: '/logistics/edit/:logisticsNo',
            component: withAddressSplitAlgorithmTrackPage(
              withAsync(() => import(/* webpackChunkName: "Immediate/Logistics/Create" */ '@/containers/Immediate/Logistics/Edit')),
              'logisticsEdit'
            ),
            exact: true,
          },
        ],
      },
    ]

export default routes
