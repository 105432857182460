import { useEffect, useRef } from 'react'

import Cookies from 'js-cookie'
import socket from '@/services/socket'
import store, { useSelector } from '@/store'
import { notificationActions } from '@/store/notificationSlice'
import { logger } from '@/utils/logger'
import { queryUnRead, updateAsyncTask, updateBalance, updateGuideInfo } from './utils'

/** 异步任务消息事件 */
enum MessageTypeEnum {
  /** 用户消息 */
  USER = 1,
  /** 首页通知 */
  DASHBOARD = 2,
}

/** socket 配置与初始化 */
export const useSocket = () => {
  const { isLogined, info } = useSelector((state) => state.user)
  const uid = info?.uid
  const isInit = useRef(false)

  // 用户消息
  useEffect(() => {
    return socket.onUserMessage((payload) => {
      // logger.track({ type: 'websocket', data: payload })
      // 后端没法保证用户消息都有messageType字段，兼容设置默认值1
      const { messageCount, userMessage, messageType = 1 } = payload
      switch (messageType) {
        case MessageTypeEnum.USER:
          store.dispatch(notificationActions.setUnReadMessageCount({ messageCount }))
          store.dispatch(notificationActions.setSocketMessageList([userMessage]))
          break
        case MessageTypeEnum.DASHBOARD:
          store.dispatch(notificationActions.setHasNotifyCount(true))
          break
        default:
          break
      }
    })
  }, [])

  // 导出任务消息
  useEffect(() => {
    return socket.onExportListMessage((payload) => {
      logger.track({ type: 'websocket', data: payload })
      const { exportCount, exportMessage } = payload
      store.dispatch(notificationActions.setUnReadDownloadCount({ exportCount }))
      store.dispatch(notificationActions.setSocketDownloadList([exportMessage]))
    })
  }, [])

  // 异步任务消息
  useEffect(() => {
    return socket.onAsyncResult((payload) => {
      logger.track({ type: 'websocket', data: payload })
      return updateAsyncTask(payload)
    })
  }, [])

  // 断开连接，重新获取未读消息
  useEffect(() => {
    return socket.onClosed(queryUnRead)
  }, [])

  // 监听余额更新
  useEffect(() => {
    return socket.onBalanceUpdate((payload) => {
      logger.track({ type: 'websocket', data: payload })
      return updateBalance(payload)
    })
  }, [])

  // 监听新手指引更新
  useEffect(() => {
    return socket.onGuideInfoUpdate((payload) => {
      logger.track({ type: 'websocket', data: payload })
      return updateGuideInfo(payload)
    })
  }, [])

  // 获取未读信息
  useEffect(() => {
    if (isLogined) {
      queryUnRead()
    }
  }, [isLogined])

  useEffect(() => {
    const udbSubAppId = Cookies.get('osudb_subappid')
    if (uid && udbSubAppId && !isInit.current) {
      isInit.current = true
      // 配置
      socket.configure(() => ({
        env: process.env.APP_ENV.toUpperCase(),
        /**
         * @todo
         * 此处根据打包环境，不能根据用户真正所在地区进行就近 websocket 连接
         * 后面可以根据服务器环境确认 socket 连接地址
         */
        wsDomain: process.env.WS_ENV === 'nation' ? process.env.WEBSOCKET_URL_NATION : process.env.WEBSOCKET_URL,
        appId: process.env.WEBSOCKET_APP_ID,
        privateKey: process.env.WEBSOCKET_PRIVATE_KEY,
        broadcasts: typeof process.env.WEBSOCKET_UDB_BOARDCASTS === 'string' ? process.env.WEBSOCKET_UDB_BOARDCASTS.split(',') : [],
        udbAuthUrl: `${window.location.origin}/udb/lgn/sdk/getCookieOtp.do`,
        udbAppId: process.env.WEBSOCKET_UDB_APP_ID,
        udbSubAppId,
        uid,
      }))

      socket.open()
    }

    return () => {
      if (isInit.current) {
        socket?.close()
      }
    }
  }, [uid])
}
