import classnames from 'classnames'
import React from 'react'
import { OneButton, OneModal } from '@yy/one-ui'
import styles from './index.module.less'

export interface ConfirmModalProps {
  open: boolean
  onClose: () => void
  title?: string | React.ReactNode
  content?: string | React.ReactNode
  buttons?: Array<
    | {
        type?: 'primary'
        text: string | React.ReactNode
        onClick?: () => void
      }
    | React.ReactElement
  >
  contentClassName?: string
  classes?: Record<string, string>
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const { open, onClose, title, content, buttons = [], contentClassName, classes: inputClasses } = props

  const renderTitle = () => {
    if (!title) {
      return null
    }

    const className = classnames([inputClasses?.titleWraper])
    return <div className={className}>{title}</div>
  }

  const renderContent = () => {
    if (!content) {
      return null
    }

    const className = classnames([inputClasses?.contentWraper, contentClassName])
    return <div className={className}>{content}</div>
  }

  const renderButtons = () => {
    if (!(Array.isArray(buttons) && buttons.length > 0)) {
      return null
    }

    const className = classnames([inputClasses?.buttonsWraper])
    return (
      <div className={className}>
        {buttons.map((item, idx) => {
          // 如果传入组件优先用组件
          if (React.isValidElement(item)) {
            return React.cloneElement(item, {
              key: idx,
            })
          }

          return (
            <OneButton
              type={item.type}
              className={!item.type && 'one-btn-secondary'}
              key={idx}
              onClick={() => {
                if (item.onClick) {
                  item.onClick()
                }
              }}
            >
              {item.text}
            </OneButton>
          )
        })}
      </div>
    )
  }

  return (
    <OneModal
      wrapClassName={styles.confirmModalBody}
      width={416}
      closable={false}
      className={classnames([inputClasses?.modal])}
      visible={open}
      onCancel={onClose}
      title={renderTitle()}
      footer={renderButtons()}
    >
      {renderContent()}
    </OneModal>
  )
}

ConfirmModal.displayName = 'ConfirmModal'
