import { useState } from 'react'
import { createContainer } from 'unstated-next'

function useContext() {
  const [senderIndex, setSenderIndex] = useState(0)
  const [consigneeIndex, setConsigneeIndex] = useState(0)

  return {
    senderIndex,
    setSenderIndex,
    consigneeIndex,
    setConsigneeIndex,
  }
}

export default createContainer(useContext)
