import type { RouteConfig } from 'react-router-config'
import { Redirect } from '@/containers/Redirect'

const routes: RouteConfig[] = [
  {
    path: '/redirect',
    component: Redirect,
  },
]

export default routes
