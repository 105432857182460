import type { FormInstance } from 'antd'
import { Checkbox, Col, Form, Input, Radio, Row, Select } from 'antd'
import { uniqBy } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import RULES, { EMPTY_RULES } from '@/bizComponent/AntdForm/rules'
import CountryWithCode from '@/bizComponent/CountryWithCode'
import { useSelector } from '@/store'
import type { IRecipientAddressFormValues } from '@/store/recipientAddressSlice/types'
import { filterCountryOption } from '@/utils'
import { ARRIVAL_METHOD } from '../../constant/arrivalMethod'
import { ExpectedSelect } from './ExpectedSelect'
import { Home } from './Home'
import { useRecipientAddressForm } from './hooks'
import { Store } from './Store'
import { is711CrossBorder } from './utils'
import isEmpty from 'lodash/isEmpty'
import { LogisticsType } from '@/store/logisticsSlice/type'
import { NullCom } from '../AntdForm/FromEmptyItem'

export interface RecipientAddressFormProps {
  /** 表单实例 */
  form: FormInstance
  /** 禁止项 */
  disables?: Array<keyof IRecipientAddressFormValues> | 'all'
  saveButton?: boolean
  /** 是否为逆向物流的批量创建 */
  isReverseBatch?: boolean
  /** 是否为逆向物流 */
  isReverse?: boolean
}

/** 收件人地址 */
export const RecipientAddressForm: React.FC<RecipientAddressFormProps> = (props) => {
  const { form, disables = [], saveButton = true, isReverse = false, isReverseBatch = false } = props
  const { t } = useTranslation()
  const { isLoading, update } = useRecipientAddressForm(form, { logisticsType: isReverse ? LogisticsType.reverse : undefined })

  /** 用户国家 */
  const userCountryCode = useSelector((state) => state?.user?.info?.countryCode)
  /** 国家 */
  const countries = useSelector((state) => state.dictionary.filterArrivalCountries)
  /** 收货国家 */
  const arrivalCountries = useMemo(() => (isReverse ? countries.filter((country) => country.countryCode === userCountryCode) : countries), [isReverse, countries, userCountryCode])
  /** 必填的字段 */
  const verifyFieldMap = useSelector((state) => state.address.consigneeVerifyFieldMap)
  /** 禁止的配送方式 */
  const disabledMethods = useSelector((state) => state.recipientAddressForm.disabledMethods)
  /** 手机区号映射 */
  const areaCodeMap = useSelector((state) => state.common?.areaCodeMap)

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prev, next) => {
        const fields = ['departure_countryCode', 'arrival_countryCode', 'arrival_method', 'arrival_store_companyCode']
        return -1 !== fields.findIndex((name) => prev[name] !== next[name])
      }}
    >
      {({ getFieldsValue }) => {
        const { departure_countryCode, arrival_countryCode, arrival_method, arrival_store_companyCode } = getFieldsValue(true)
        const IsfilledCountries = isReverseBatch || isReverse ? arrival_countryCode : departure_countryCode && arrival_countryCode
        const showStoreForm = isReverse ? arrival_countryCode && arrival_method === 2 : departure_countryCode && arrival_countryCode && arrival_method === 2
        // 是否显示宅配form
        const showHomeForm =
          IsfilledCountries &&
          (arrival_method === ARRIVAL_METHOD.home ||
            // 店配， 711跨境特殊逻辑需要用户手动输入地址信息https://shopline.yuque.com/mlu41d/gy9ldi/xr20sq#tzJk7
            (arrival_method === ARRIVAL_METHOD.store && is711CrossBorder(arrival_store_companyCode)))

        return (
          <>
            <Row gutter={20}>
              {/* 国家 */}
              <Col span={6}>
                <Form.Item rules={[RULES.required()]} required name="arrival_countryCode" label={t('order.create.countryRegion')}>
                  <ExpectedSelect expect={(arrivalCountryCode) => update({ arrivalCountryCode })}>
                    <Select
                      disabled={disables === 'all' || disables.includes('arrival_countryCode') || isEmpty(arrivalCountries)} // 没有服务产品支持的收货国家，置灰
                      loading={isLoading}
                      showSearch
                      data-id="shipment_reciptInfo_homeRegion"
                      filterOption={filterCountryOption}
                      placeholder={t('common.select.placeholder')}
                      className="country-select"
                    >
                      {uniqBy(arrivalCountries, 'countryCode').map((country) => (
                        <Select.Option title={country.countryName} key={country.countryCode} value={country.countryCode}>
                          <CountryWithCode item={country} />
                        </Select.Option>
                      ))}
                    </Select>
                  </ExpectedSelect>
                </Form.Item>
              </Col>
              {/* 配送方式(店配/宅配) */}
              <Col span={12}>
                <Form.Item
                  initialValue={1}
                  colon={false}
                  name="arrival_method"
                  label={' '}
                  rules={EMPTY_RULES}
                  help={disabledMethods.length === 2 ? <div className="ant-form-item-explain-error">{t('address.form.serviceValid')}</div> : null}
                >
                  {isReverse ? (
                    <NullCom />
                  ) : (
                    <Radio.Group disabled={disables === 'all' || disables.includes('arrival_method') || !IsfilledCountries}>
                      <Radio disabled={disabledMethods.includes('home')} value={1}>
                        {t('order.list.houseDelivery')}
                      </Radio>
                      <Radio disabled={disabledMethods.includes('store')} value={2}>
                        {t('order.list.storeDelivery')}
                      </Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>

            {/* 店配 */}
            <Store form={form} disables={disables} hidden={!showStoreForm} />
            {/* 宅配 */}
            <Home form={form} disables={disables} hidden={!showHomeForm} />

            <Row gutter={20}>
              {/* 姓名 */}
              <Col span={6}>
                <Form.Item
                  rules={[RULES.fullName({ checkRequired: !!+verifyFieldMap[arrival_countryCode]?.name })]}
                  required={!!+verifyFieldMap[arrival_countryCode]?.name}
                  name="arrival_fullName"
                  label={t('order.create.fullName')}
                >
                  <Input
                    disabled={disables === 'all' || disables.includes('arrival_fullName')}
                    data-id="shipment_reciptInfo_homeName"
                    placeholder={t('common.input.placeholder')}
                  />
                </Form.Item>
              </Col>
              {/* 手机号 */}
              <Col span={6}>
                <Form.Item
                  rules={[RULES.mobile({ checkRequired: !!+verifyFieldMap[arrival_countryCode]?.mobile, checkIsCN: true })]}
                  required={!!+verifyFieldMap[arrival_countryCode]?.mobile}
                  name="arrival_mobile"
                  label={t('order.create.mobile')}
                >
                  <Input
                    prefix={areaCodeMap[arrival_countryCode] ? `+${areaCodeMap[arrival_countryCode]}` : <span />}
                    disabled={disables === 'all' || disables.includes('arrival_mobile')}
                    data-id="shipment_reciptInfo_homePhone"
                    placeholder={t('common.input.placeholder')}
                  />
                </Form.Item>
              </Col>
              {/* 邮箱 */}
              <Col span={6}>
                <Form.Item
                  rules={[RULES.email({ checkRequired: !!+verifyFieldMap[arrival_countryCode]?.email })]}
                  required={!!+verifyFieldMap[arrival_countryCode]?.email}
                  name="arrival_email"
                  label={t('order.create.email')}
                >
                  <Input disabled={disables === 'all' || disables.includes('arrival_email')} data-id="shipment_reciptInfo_homeEmail" placeholder={t('common.input.placeholder')} />
                </Form.Item>
              </Col>
            </Row>

            {/* 店配地址可以保存 */}
            {showHomeForm && saveButton && (
              <Row gutter={20}>
                <Col span={6}>
                  <Form.Item valuePropName="checked" name="arrival_isSave" noStyle>
                    <Checkbox disabled={disables === 'all' || disables.includes('arrival_isSave')} data-id="shipment_reciptInfo_Save2AB">
                      {t('order.create.saveToAddressList')}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </>
        )
      }}
    </Form.Item>
  )
}

RecipientAddressForm.displayName = 'RecipientAddressForm'
