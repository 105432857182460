export const filterText = (text: string | null | undefined): string => {
  if (!text) {
    return ''
  }

  return text
}

interface AddressType {
  addressTwo?: string | null
  address?: string | null
  province?: string | null
  city?: string | null
  town?: string
  district?: string | null
  zipCode?: string | null
}

export const concatAddress = (addressContent: AddressType): string => {
  const { addressTwo = '', address = '', province = '', city = '', district = '', zipCode = '', town = '' } = addressContent
  const result = []
  result.push(filterText(addressTwo))
  result.push(filterText(address))
  result.push(filterText(town))
  result.push(filterText(district))
  result.push(filterText(city))
  result.push(filterText(province))
  result.push(filterText(zipCode))
  return result.filter((item) => !!item).join(',')
}
