import React from 'react'
import { Redirect } from 'react-router'
import type { RouteConfig } from 'react-router-config'
import { Arrival } from '@yy/one-icon'
import { withAsync } from '@/components/Async'
import { withAuthRedirect } from '@/components/Authorized/hoc/withAuthRedirect'
import Blank from '@/layouts/Blank'

const routes: RouteConfig[] = [
  // 轨迹中心
  {
    alias: 'tracking_center',
    path: '/track',
    component: withAuthRedirect(Blank, { first: '/track/setting' }),
    menuTitle: 'menu.trackCenter',
    icon: Arrival,
    permissionCode: 'track:waybillTracking,track:setting',
    routes: [
      // 运单追踪
      {
        alias: 'shipment_tracking',
        menuTitle: 'subscribe.waybillTracking.list.title',
        pageDescription: 'subscribe.waybillTracking.list.desc',
        path: '/waybillTracking',
        exact: true,
        permissionCode: 'track:waybillTracking',
        component: withAsync(() => import(/* webpackChunkName: "WaybillTracking" */ '@/containers/WaybillTracking/List')),
      },
      {
        path: '/waybillTracking/edit',
        exact: true,
        component: withAsync(() => import(/* webpackChunkName: "WaybillTrackingEdit" */ '@/containers/WaybillTracking/Edit')),
      },
      // 追踪设置
      {
        alias: 'tracking_settings',
        path: '/setting',
        permissionCode: 'track:setting',
        component: withAsync(() => import(/* webpackChunkName: "TrackSettings" */ '@/containers/TrackSettings')),
        menuTitle: 'subscribe.trackingSetting.name',
        exact: true,
      },
      // 邮件编辑
      {
        path: '/setting/email',
        component: withAsync(() => import('@/containers/TrackSettings/EmailEdit')),
        exact: true,
      },
      // 默认邮件预览
      {
        path: '/default-email-preiview',
        exact: true,
      },
      {
        path: '/waybillTracking/list',
        component() {
          return <Redirect to="/track/waybillTracking" />
        },
        exact: true,
      },
      {
        path: '/track/setting/trackingPage',
        component: withAsync(() => import('@/containers/TrackSettings/TrackPage')),
        exact: true,
      },
      {
        path: '/track/setting/sms',
        component: withAsync(() => import('@/containers/TrackSettings/TrackSms')),
        exact: true,
      },
    ],
  },
]

export default routes
