import type * as Types from '@/types'

/** 待处理 */
const PENDING = 'pending' as const
/** 已发货 */
const SHIPPED = 'shipped' as const
/** 已完成 */
const COMPLETED = 'completed' as const
/** 下单失败 */
const FAILED = 'failed' as const
/** 即时物流订单状态 */
export type TypeOneshipImmediateStatus = 'pending' | 'shipped' | 'completed' | 'failed'

const labelMap = {
  [PENDING]: 'order.list.shipStatus.pending',
  [SHIPPED]: 'order.list.shipStatus.shipped',
  [COMPLETED]: 'order.list.shipStatus.completed',
  [FAILED]: 'order.list.shipStatus.failed',
} as Record<TypeOneshipImmediateStatus, string>

export type OneshipImmediateStatusDict = Types.Dict<{ value: TypeOneshipImmediateStatus }>

export const ONESHIP_IMMEDIATE_ORDER_TAB: Types.Dict<{ value: TypeOneshipImmediateStatus[] }> = [
  {
    value: [],
    label: 'order.list.tabAll',
  },
  {
    value: [PENDING, FAILED],
    label: labelMap.pending,
  },
  {
    value: [SHIPPED],
    label: labelMap.shipped,
  },
  {
    value: [COMPLETED],
    label: labelMap.completed,
  },
]

export const ONESHIP_IMMEDIATE_ORDER_STATUS: OneshipImmediateStatusDict = [
  {
    value: PENDING,
    label: labelMap.pending,
  },
  {
    value: FAILED,
    label: labelMap.failed,
  },
  {
    value: SHIPPED,
    label: labelMap.shipped,
  },
  {
    value: COMPLETED,
    label: labelMap.completed,
  },
]

export const maxPriorityFee = 1000000000

export interface ExpressConfig {
  /** 发货地址是否门店 */
  senderStore: boolean
  /** 多收件地址数量 */
  consigneeAddressesLength: number
  /** 隐藏的字段 */
  hideFields: {
    [K in 'goodsForm' | 'service_type' | 'priority_fee' | 'need_delivery_proofUrl' | 'provider_pickup_type' | 'driverEdit' | 'provider_payment_type']?: boolean
  }
  /** 计费规则 trial：我们计费；express：第三方计费*/
  feeCalculate: 'trial' | 'express'
  /** 批量创建揽收日期类型 */
  pickupType?: BATCH_PICKUP_TYPE
  batchNewFields: {
    batchNeedFee?: boolean
    batchSupportShip?: boolean
  }
  supportFields: {
    /** 支持订单批量发货 */
    supportBatchShip: boolean
    /** 支持订单详情展示商品名称金额备注 */
    supportProductInfo: boolean
    /** 运单详情支持司机揽收和派送预估时间*/
    supportDetailTimeShow: boolean
  }
}

export enum BATCH_PICKUP_TYPE {
  /** 下拉 */
  'select' = 1,
  /** 时间选择器 */
  'picker' = 2,
}
/**
 * 服务产品特殊配置
 */
export const immediateExpressConfig = {
  pandago: {
    /** 发货地址是否门店 */
    senderStore: true,
    /** 多收件地址数量 */
    consigneeAddressesLength: 1,
    /** 隐藏的字段 */
    hideFields: {
      service_type: true,
      priority_fee: true,
      provider_pickup_date: true,
      provider_pickup_time_slots: true,
      /** 运单详情编辑司机信息隐藏 */
      driverEdit: true,
    },
    feeCalculate: 'trial',
    /** 批量创建揽收日期类型 */
    pickupType: BATCH_PICKUP_TYPE.picker,
    batchNewFields: {
      /** 批量新建需要计算运费 */
      batchNeedFee: true,
      /** 批量新建只支持发货 */
      batchSupportShip: true,
    },
    supportFields: {
      /** 支持批量发货 */
      supportBatchShip: true,
      supportProductInfo: true,
      supportDetailTimeShow: true,
    },
  },
} as const

/**
 * 默认配置
 */
export const defaultExpressConfig = {
  /** 发货地址是否门店 */
  senderStore: false,
  /** 多收件地址数量 */
  consigneeAddressesLength: 15,
  /** 隐藏的字段 */
  hideFields: {
    goodsForm: true,
    provider_payment_type: true,
  },
  feeCalculate: 'express',
} as const
