export const SUPPORT_COUNTRY = ['TW', 'HK', 'SG', 'MY', 'TH', 'VN']

export const BANK_ACCOUNT_NAME = {
  TW: {
    value: '帳戶：商線科技股份有限公司',
  },
  HK: {
    value: 'Account Name: Shopline Solutions Limited',
  },
  SG: {
    value: 'Account name: SHOPLINE COMMERCE PTE. LTD.',
  },
  MY: {
    value: 'Account Name： SHOPLINE (M) SDN. BHD.',
  },
  TH: {
    value: 'Account Name : SHOPLINE (THAILAND) CO LTD',
  },
  VN: {
    value: 'Tên tài khoản: CÔNG TY TNHH SHOPLINE VIETNAM',
  },
}

export const BANK_NAME = {
  TW: {
    value: '銀行：台新國際商業銀行',
  },
  HK: {
    value: 'Bank Name: Citibank N.A., Hong Kong Branch',
  },
  SG: {
    value: 'Bank name: The Hongkong and Shanghai Banking Corporation Limited',
  },
  MY: {
    value: 'Bank： HSBC Bank Malaysia Berhad',
  },
  TH: {
    value: 'Bank Name : THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED',
  },
  VN: {
    value: '',
  },
}

export const SWIFT_CODE = {
  TW: {
    value: '',
  },
  HK: {
    value: 'Swift Code: CITIHKHXXXX',
  },
  SG: {
    value: 'Swiftcode: HSBCSGSGXXX',
  },
  MY: {
    value: 'SWIFT Code： HBMBMYKLXXX',
  },
  TH: {
    value: 'Swift Code : HSBCTHBK',
  },
  VN: {
    value: 'Mã swift: VTCBVNVX',
  },
}

export const BRANCH_CODE = {
  TW: {
    value: '分行代碼：民生分行0436',
  },
  HK: {
    value: 'Branch code: 391',
  },
  SG: {
    value: '',
  },
  MY: {
    value: '',
  },
  TH: {
    value: '',
  },
  VN: {
    value: '',
  },
}

export const BANK_CODE = {
  TW: {
    value: '銀行代碼：812',
  },
  HK: {
    value: 'Bank Code: 006',
  },
  SG: {
    value: '',
  },
  MY: {
    value: '',
  },
  TH: {
    value: 'Bank code : 031',
  },
  VN: {
    value: 'Branch: Techcombank – Bui Thi Xuan Branch',
  },
}

export const ACCOUNT_PHONE = {
  TW: {
    value: '帳戶號碼：2043-01-0000434-1',
  },
  HK: {
    value: 'Account Number: 91320429 (HKD)',
  },
  SG: {
    value: 'Account No: 052-813292-001',
  },
  MY: {
    value: 'Account Number： 352-618615-101',
  },
  TH: {
    value: 'Account Number : 002-238020-001 (THB)',
  },
  VN: {
    value: 'Số tài khoản VND: 19036641674015',
  },
}

export const BANK_ADDRESS = {
  TW: {
    value: '',
  },
  HK: {
    value: 'Bank address: 3 Garden Road, Central, Hong Kong',
  },
  SG: {
    value: 'Bank Address: 21 Collyer Quay #07-02 HSBC Building Singapore 049320',
  },
  MY: {
    value: 'Bank Address： 2 LEBOH AMPANG,KUALA LUMPUR 50100, MALAYSIA',
  },
  TH: {
    value: 'Bank Address: HSBC BUILDING, 968 RAMA IV ROAD, SILOM, BANGRAK, BANGKOK 10500, THAILAND',
  },
  VN: {
    value: '',
  },
}

export const ALL = [
  {
    TW: {
      value: '帳戶：商線科技股份有限公司',
    },
    HK: {
      value: 'Account Name: Shopline Solutions Limited',
    },
    SG: {
      value: 'Account name: SHOPLINE COMMERCE PTE. LTD.',
    },
    MY: {
      value: 'Account Name： SHOPLINE (M) SDN. BHD.',
    },
    TH: {
      value: 'Account Name : SHOPLINE (THAILAND) CO LTD',
    },
    VN: {
      value: 'Tên tài khoản: CÔNG TY TNHH SHOPLINE VIETNAM',
    },
  },
]
