import React from 'react'

const NoData: React.FC = () => {
  return (
    <svg style={{ width: '136.39px', height: '104.94px' }} width="159" height="128" viewBox="0 0 159 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <rect x="20" y="10" width="114" height="88" rx="2" fill="white" />
      </g>
      <path d="M20 12C20 10.8954 20.8954 10 22 10H132C133.105 10 134 10.8954 134 12V20H20V12Z" fill="#ABB9CC" />
      <circle cx="28" cy="15" r="2" fill="#748399" />
      <circle cx="35" cy="15" r="2" fill="#748399" />
      <circle cx="42" cy="15" r="2" fill="#748399" />
      <circle cx="41.5" cy="40.5" r="9.5" fill="#E6EDF5" />
      <circle cx="41.5" cy="70.5" r="9.5" fill="#E6EDF5" />
      <rect x="62" y="35" width="61" height="10" rx="1" fill="#E6EDF5" />
      <rect x="62" y="65" width="61" height="10" rx="1" fill="#E6EDF5" />
      <path
        d="M135.88 94.4372C141.934 88.3841 141.934 78.57 135.88 72.5169C129.827 66.4638 120.013 66.4638 113.96 72.5169C107.907 78.57 107.907 88.3841 113.96 94.4372C120.013 100.49 129.827 100.49 135.88 94.4372ZM135.88 94.4372L145.426 103.983"
        stroke="#ABB9CC"
        strokeWidth="5"
      />
      <defs>
        <filter id="filter0_d" x="0" y="0" width="154" height="128" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

NoData.displayName = 'NoData'
export default NoData
