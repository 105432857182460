import API from '@/services/http'
import type { StationListItem } from '@/store/addressSlice/types'
import type { IResponse } from './type'

interface IGetStationVO {
  pageNum?: number
  pageSize?: number
  stationCode: string
  stationName?: string
  orderNo?: string
}

/**
 * 根据门店编码获取门店列表
 * @param data
 * @returns
 */
export const getStationList = (data: Pick<IGetStationVO, 'pageSize' | 'pageNum' | 'stationCode'>) => {
  const params = Object.assign(
    {
      pageNum: 1,
      pageSize: 20,
    },
    data
  )
  return API.admin.post<IResponse<{ data: StationListItem[] }>>('/api/station/list', params)
}

/**
 * 根据门店编码与订单号获取 station 列表
 * @param params
 * @returns
 */
export const getStationListByOrderNo = (params: Omit<IGetStationVO, 'pageNum' | 'pageSize'>) => {
  return API.admin.post('/api/sales/order_query/list_station', params)
}
