import type { AppDispatch, AppGetState, AppThunkDispatch } from '..'
import { updateCodValue } from '../newServiceProviderSlice'
import * as Types from './types'
import { FIELDS } from '@/bizComponent/AntdParcel/components/ParcelForm'
import { SIZE, SOURCE_TYPE } from '@/constant'
import { fetchRateList } from '@/services/apis/common'
import { postFeeConvert } from '@/services/apis/feeConvert'
import { setProps } from '@/store/newServiceProviderSlice'
import { isCodCurrencyCodeSpecified, formatSenderAddressToService, formatRecipientAddressToService, uuid } from '@/utils'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { FormInstance } from 'antd'
import { isNumber, pick } from 'lodash'
import { divide, times } from 'number-precision'
import { SHIP_TYPE_ENUM } from '@/store/enum'
import { isPlatformLogistics } from '@/utils/format/utils'

const initialState: Types.InitialState = {
  /**
   * 发货类型
   * @todo
   * 因为第二步与第三步均有发货的特殊场景
   * 这里应该统一在一个 reducer 中维护
   * @see /store/newServiceProviderSlice/index.ts
   */
  type: SHIP_TYPE_ENUM.NORMAL,
  currencyInfo: null,
  props: {},
}
const parcelSlice = createSlice({
  name: 'parcelSlice',
  initialState,
  reducers: {
    update(state, action) {
      return {
        ...state,
        ...action.payload,
        props: {
          ...state.props,
          ...action.payload?.props,
        },
      }
    },
    updateProps(state, action) {
      const { cover, ...props } = action.payload

      let updateProps = { ...(state?.props || {}), ...props }

      if (cover) {
        updateProps = props
      }

      return {
        ...state,
        props: updateProps,
      }
    },
    setConvertedInfo(state, action: PayloadAction<Types.CurrencyInfo>) {
      return {
        ...state,
        currencyInfo: {
          ...state.currencyInfo,
          ...action.payload,
        },
      }
    },
    clearParcel(state) {
      return {
        ...state,
        ...initialState,
      }
    },
  },
})
export const { setConvertedInfo, update, updateProps, clearParcel } = parcelSlice.actions

/** 勾选转换 || 币种change */
export const getProductsRateList1 = (params: Types.GetProductsRateParams, form: FormInstance) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { source, parcel_commodities } = params
  const values = form?.getFieldsValue()
  const parcelItems = parcel_commodities || values['parcel_commodities']
  const state = getState()
  const { provider } = state.newServiceProvider

  const commodities = parcelItems?.map((item) => ({
    goodsConvertId: uuid(),
    goodsPrice: times(Number(item.parcel_goods_price), 100),
  }))
  const address = {
    ...formatSenderAddressToService({ ...values }),
    ...formatRecipientAddressToService({ ...values }),
  }
  const feeConvertInfo = {
    currencyCode: values['parcel_currency_code'],
    sellerCodAmount: times(Number(values['provider_cod_amount'] || 0), 100),
    sellerCodCurrencyCode: values['provider_cod_currency_code'],
    commodities,
  }

  try {
    const res = await postFeeConvert({
      expressTypeCode: provider?.expressTypeCode,
      address,
      feeConvertInfo,
      needSellerCustomFreight: false,
      isECommerce: false,
      salesOrderFreight: 0,
      salesOrderCurrencyCode: '',
    })
    if (res.bizCode === 'SUCCESS') {
      const { commodities: list, currencyConverted, currencyCode } = res?.data
      const resField = parcelItems?.map((item, index) => {
        const goodsConvertId = commodities[index].goodsConvertId
        const goodsPrice = list.filter((item) => item.goodsConvertId === goodsConvertId)?.[0].goodsPrice
        const divideValue = !!goodsPrice || goodsPrice === 0 ? divide(Number(goodsPrice) || 0, 100) : ''
        return {
          ...item,
          parcel_goods_converted_price: divideValue,
        }
      })
      dispatch(
        setConvertedInfo({
          currencyRate: '',
          hasConverted: currencyConverted,
          fetchTime: '',
          convertedOrderAmount: '',
        })
      )
      form.setFieldsValue({
        parcel_commodities: resField,
        parcel_currency_code: source,
        parcel_converted_currency_code: currencyCode,
      })
      return resField
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

/** 勾选转换 || 币种change */
export const getProductsRateList = (params: Types.GetProductsRateParams, form?: FormInstance) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { source, target, parcel_commodities } = params
  const parcelItems = parcel_commodities || form.getFieldValue('parcel_commodities')
  const sourceAmountList = parcelItems?.map((item) => times(Number(item.parcel_goods_price), 100))
  const reqParams = {
    sourceCurrency: source || form.getFieldValue('parcel_currency_code'),
    targetCurrency: target || form.getFieldValue('parcel_converted_currency_code'),
    sourceAmountList,
  }

  // 这里补充一条逻辑，每次商品币种转换的时候，实付金额也需要币种转换，并且存储起来，合单发货的时候需要把转换后的实付金额传给后端 - start
  let convertedOrderAmount
  const { parcelSlice } = getState()
  const { originOrderAmount, originCurrencyCode } = parcelSlice.props
  if (isNumber(originOrderAmount)) {
    const reqParams = {
      sourceCurrency: originCurrencyCode,
      targetCurrency: target || form.getFieldValue('parcel_converted_currency_code'),
      sourceAmountList: [originOrderAmount],
    }
    const res = await fetchRateList(reqParams)
    const { targetAmountList } = res?.data
    convertedOrderAmount = targetAmountList[0]
  }
  // - end

  const res = await fetchRateList(reqParams)
  const { targetAmountList, currencyRate: rate, fetchTime, sourceAmountList: list } = res?.data
  const resField = list?.map((el, index) => {
    const divideValue = !!targetAmountList[index] || targetAmountList[index] === 0 ? divide(Number(targetAmountList[index]) || 0, 100) : ''
    return {
      ...parcelItems?.[index],
      parcel_goods_converted_price: divideValue,
    }
  })
  dispatch(
    setConvertedInfo({
      currencyRate: rate,
      hasConverted: true,
      fetchTime,
      convertedOrderAmount,
    })
  )
  form.setFieldsValue({
    parcel_commodities: resField,
    parcel_currency_code: source,
    parcel_converted_currency_code: target,
  })
  return resField
}

/**
 * 初始化包裹
 * 只会设置name为 parcel_ 开头的表单值
 * @param params
 * @returns
 */
export const initParcelForm = (params?: Types.IInitialParcel) => async (dispatch: AppThunkDispatch, getState: AppGetState, form: FormInstance) => {
  try {
    const { type, formValues, props } = params || {}

    // 初始化场景
    if (type) {
      dispatch(update({ type }))
    }

    // 初始化props
    if (props) {
      await dispatch(initProps(props))
    }

    const { parcelSlice } = getState()
    const { isConverted, originCurrencyCode, isCrossBorder, isChinaCrossBorder, convertedCurrencyCode } = parcelSlice.props

    // 默认商品信息
    const PARCEL_ITEM = isCrossBorder ? (isChinaCrossBorder ? FIELDS.IS_CHINA_CRORSS_ITEMS : FIELDS.IS_CRORSS_BORDER_ITEMS) : FIELDS.NORMAL_ITEMS

    const { parcel_commodities: commodities } = formValues || {}

    // 新建无初始值，给一行默认
    const parcel = Array.isArray(commodities) && commodities?.length > 0 ? commodities : [PARCEL_ITEM]

    // 创建订单场景，没formValues参数，需要设长度单位和重量单位默认值
    const updateFormValues = Object.assign({}, formValues, {
      parcel_length_unit: formValues?.parcel_length_unit || SIZE.cm,
      parcel_weight_unit: formValues?.parcel_weight_unit || 'kg',
      parcel_currency_code: originCurrencyCode,
    })

    const finalFormValues = pick(
      updateFormValues,
      Object.keys(updateFormValues).filter((key) => key.startsWith('parcel_'))
    )

    form.setFieldsValue({
      ...finalFormValues,
      /** 逆向物流原订单号 */
      reverseOrderNo: Array.isArray(formValues?.reverseOrderNo) ? formValues?.reverseOrderNo : undefined,
    })

    if (isConverted) {
      !form.getFieldValue('parcel_converted_currency_code') &&
        form.setFieldsValue({
          parcel_converted_currency_code: convertedCurrencyCode,
        })
      const { parcel_check_currency: checkCurrency } = form.getFieldsValue()
      if (checkCurrency === undefined) {
        // 勾选币种转换
        form.setFieldsValue({ parcel_check_currency: true })
      }
      await dispatch(
        getProductsRateList(
          {
            target: convertedCurrencyCode,
            source: originCurrencyCode,
            parcel_commodities: parcel,
          },
          form
        )
      )
    } else {
      form.setFieldsValue({ parcel_commodities: parcel })
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

/** 初始化参数 */
// TODO: codFee 临时写在这，后续需要跟initParcelForm组合，并去除此字段ß
export const initProps = (params: Types.IInitialParcel['props']) => async (dispatch: AppDispatch, getState) => {
  const { arrivalCountry, senderCountry, originCurrencyCode, convertedCurrencyCode, orderType, sourceType = SOURCE_TYPE.MUTUAL, expressLogisticsType } = params
  const state = getState()
  /** 用户的币种开关设置 */
  const setting = state?.common['setting'] as any
  /** 运单或订单重新编辑有可能订单币种和收货国家币种不一致，所以要把转换后的币种传入 */
  const target = convertedCurrencyCode || state.dictionary?.countryMapDics?.[arrivalCountry]?.currency
  /** 是否为跨境 */
  const isCrossBorder = arrivalCountry && senderCountry && arrivalCountry !== senderCountry
  /** 是否为中国跨境 */
  const isChinaCrossBorder = isCrossBorder && senderCountry === 'CN'
  /** 得力迅 */
  const isCN2US = senderCountry === 'CN' && arrivalCountry === 'US'
  // https://shopline.yuque.com/mlu41d/ltch1x/gydndl0kfgfgquoo#yWGrV
  const isUS2US = senderCountry === 'US' && arrivalCountry === 'US'
  /** 香港跨境 */
  const isHKCrossBorder = senderCountry === 'HK' && arrivalCountry !== 'HK'

  const isApiOder = !sourceType || Number(sourceType) !== SOURCE_TYPE.MUTUAL

  /** ID local */
  const IsIndonesia = senderCountry === 'ID' && arrivalCountry === 'ID'

  const settingBoolean = !!Number(setting?.['CURRENCY_RATE']?.dataValue)
  const isConvertedScene = !!(
    !isPlatformLogistics(expressLogisticsType) &&
    settingBoolean &&
    (![Types.ORDER_TYPE.ORDER_CREATE, Types.ORDER_TYPE.LOGISTICS_EDIT, Types.ORDER_TYPE.ORDER_EDIT].includes(orderType) || (isApiOder && Types.ORDER_TYPE.ORDER_EDIT === orderType))
  )
  /** 是否币种转换 */
  const isConverted =
    isConvertedScene &&
    !!originCurrencyCode && // 有订单运单原币种
    !!target && // 有收货国家或者转换后的币种
    originCurrencyCode !== target

  const props = {
    ...params,
    isCrossBorder,
    isChinaCrossBorder,
    isConverted,
    isShowCheckbox: isConverted,
    convertedCurrencyCode: target,
    isConvertedScene,
    isCN2US,
    isHKCrossBorder,
    IsIndonesia,
    isUS2US,
  }
  dispatch(updateProps(props))
}

/** 发货国家改变 */
export const handleSenderCountryChange =
  (
    departure_countryCode?: string,
    params?: {
      parcel_commodities: Types.ParcelFormFields['parcel_commodities']
    }
  ) =>
  async (dispatch: AppThunkDispatch, getState: AppGetState, form: FormInstance) => {
    const state = getState()
    const {
      props: { isConvertedScene, originCurrencyCode, orderType },
    } = state.parcelSlice
    const { provider } = state.newServiceProvider
    const { arrival_countryCode, provider_cod_amount } = form.getFieldsValue()
    const convertedCurrencyCode = state.dictionary.countryMapDics[arrival_countryCode]?.currency

    /** 是否为跨境 */
    const isCrossBorder = arrival_countryCode && departure_countryCode && arrival_countryCode !== departure_countryCode
    /** 是否为中国跨境 */
    const isChinaCrossBorder = isCrossBorder && departure_countryCode === 'CN'
    /** 得力迅 */
    const isCN2US = departure_countryCode === 'CN' && arrival_countryCode === 'US'
    // https://shopline.yuque.com/mlu41d/ltch1x/gydndl0kfgfgquoo#yWGrV
    const isUS2US = departure_countryCode === 'US' && arrival_countryCode === 'US'
    /** 香港跨境 */
    const isHKCrossBorder = departure_countryCode === 'HK' && arrival_countryCode !== 'HK'

    /** ID local */
    const IsIndonesia = departure_countryCode === 'ID' && arrival_countryCode === 'ID'

    const updateIsConverted = isConvertedScene && !!originCurrencyCode && !!convertedCurrencyCode && convertedCurrencyCode !== originCurrencyCode
    dispatch(
      updateProps({
        isConverted: updateIsConverted,
        isChinaCrossBorder,
        isCrossBorder,
        isShowCheckbox: updateIsConverted,
        isCN2US,
        isHKCrossBorder,
        IsIndonesia,
        isUS2US,
      })
    )
    form.setFieldsValue({ parcel_check_currency: updateIsConverted })

    if (updateIsConverted) {
      dispatch(
        getProductsRateList(
          {
            source: originCurrencyCode,
            target: convertedCurrencyCode,
            parcel_commodities: params?.parcel_commodities || form.getFieldsValue()?.['parcel_commodities'],
          },
          form
        )
      )
      dispatch(
        setProps({
          currencyCode: originCurrencyCode,
        })
      )
      // 收货国家修改，cod币种和币种转换相关处理
      const { codCurrencyConverted } = await dispatch(
        updateCodValue({
          codFee: provider_cod_amount,
          currencyCode: originCurrencyCode,
        })
      )
      if (!codCurrencyConverted) {
        // 重置cod币种
        form.setFieldsValue({ provider_cod_currency_code: isCodCurrencyCodeSpecified(provider?.codCurrencyType) ? provider.targetCodCurrencyCode : convertedCurrencyCode })
      }
    } else {
      if (orderType === Types.ORDER_TYPE.ORDER_CREATE) {
        // 订单创建 收货国家和包裹币种联动， 没有币种转换
        form.setFieldsValue({
          parcel_currency_code: convertedCurrencyCode,
          provider_cod_currency_code: isCodCurrencyCodeSpecified(provider?.codCurrencyType) ? provider.targetCodCurrencyCode : convertedCurrencyCode,
        })
        // 服务产品币种联动
        dispatch(
          setProps({
            currencyCode: convertedCurrencyCode,
          })
        )
      }
    }
  }

/** 收货国家改变 */
export const handleArrivalCountryChange =
  (
    arrival_countryCode?: string,
    params?: {
      parcel_commodities: Types.ParcelFormFields['parcel_commodities']
    }
  ) =>
  async (dispatch: AppThunkDispatch, getState: AppGetState, form: FormInstance) => {
    const state = getState()
    const {
      props: { isConvertedScene, originCurrencyCode, orderType },
    } = state.parcelSlice
    const { provider } = state.newServiceProvider
    const { departure_countryCode, provider_cod_amount } = form.getFieldsValue()
    const convertedCurrencyCode = state.dictionary.countryMapDics[arrival_countryCode]?.currency
    /** 是否为跨境 */
    const isCrossBorder = arrival_countryCode && departure_countryCode && arrival_countryCode !== departure_countryCode
    /** 是否为中国跨境 */
    const isChinaCrossBorder = isCrossBorder && departure_countryCode === 'CN'
    /** 得力迅 */
    const isCN2US = departure_countryCode === 'CN' && arrival_countryCode === 'US'
    // https://shopline.yuque.com/mlu41d/ltch1x/gydndl0kfgfgquoo#yWGrV
    const isUS2US = departure_countryCode === 'US' && arrival_countryCode === 'US'
    /** 香港跨境 */
    const isHKCrossBorder = departure_countryCode === 'HK' && arrival_countryCode !== 'HK'

    /** ID local */
    const IsIndonesia = departure_countryCode === 'ID' && arrival_countryCode === 'ID'

    const updateIsConverted = isConvertedScene && !!originCurrencyCode && !!convertedCurrencyCode && convertedCurrencyCode !== originCurrencyCode
    dispatch(
      updateProps({
        isConverted: updateIsConverted,
        isChinaCrossBorder,
        isCrossBorder,
        isShowCheckbox: updateIsConverted,
        isCN2US,
        isHKCrossBorder,
        IsIndonesia,
        isUS2US,
      })
    )
    form.setFieldsValue({ parcel_check_currency: updateIsConverted })

    if (updateIsConverted) {
      dispatch(
        getProductsRateList(
          {
            source: originCurrencyCode,
            target: convertedCurrencyCode,
            parcel_commodities: params?.parcel_commodities || form.getFieldsValue()?.['parcel_commodities'],
          },
          form
        )
      )
      dispatch(
        setProps({
          currencyCode: originCurrencyCode,
        })
      )
      // 收货国家修改，cod币种和币种转换相关处理
      const { codCurrencyConverted } = await dispatch(
        updateCodValue({
          currencyCode: originCurrencyCode,
          codFee: provider_cod_amount,
        })
      )
      if (!codCurrencyConverted) {
        // 重置cod币种
        form.setFieldsValue({ provider_cod_currency_code: isCodCurrencyCodeSpecified(provider?.codCurrencyType) ? provider.targetCodCurrencyCode : convertedCurrencyCode })
      }
    } else {
      if (orderType === Types.ORDER_TYPE.ORDER_CREATE) {
        // 订单创建 收货国家和包裹币种联动， 没有币种转换
        form.setFieldsValue({
          parcel_currency_code: convertedCurrencyCode,
          provider_cod_currency_code: isCodCurrencyCodeSpecified(provider?.codCurrencyType) ? provider.targetCodCurrencyCode : convertedCurrencyCode,
        })
        // 服务产品币种联动
        dispatch(
          setProps({
            currencyCode: convertedCurrencyCode,
          })
        )
      }
    }
  }

/** 取消｜勾选币种转换 */
export const handleCheckCurrency = (value) => async (dispatch: AppThunkDispatch, getState, form: FormInstance) => {
  const state = getState()
  const {
    props: { originCurrencyCode, convertedCurrencyCode },
  } = state.parcelSlice
  const currencyCode = form.getFieldValue('parcel_currency_code') || originCurrencyCode
  if (value) {
    dispatch(
      getProductsRateList(
        {
          source: currencyCode,
          target: form.getFieldValue('parcel_converted_currency_code') || convertedCurrencyCode,
          parcel_commodities: form.getFieldsValue()?.['parcel_commodities'],
        },
        form
      )
    )
  }
  dispatch(updateProps({ isConverted: value }))
}

export default parcelSlice.reducer
