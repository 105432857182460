import type React from 'react'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { OneModal } from '@yy/one-ui'

export const Fallback: React.FC = () => {
  const { t } = useTranslation()

  const reload = useCallback(() => {
    const { href, origin } = window.location
    const url = `${origin}/redirect?url=${encodeURIComponent(href.replace(origin, ''))}`
    window.location.href = url
  }, [])

  useEffect(() => {
    OneModal.info({
      title: t('systemUpdateMessage'),
      okText: t('systemUpdateConfirm'),
      onOk: reload,
    })
  }, [reload, t])

  return null
}

Fallback.displayName = 'Fallback'
