/** 订单表头key */
const OrderHeaderKeys = [
  'tableHeaderShopNo', // 原订单号
  'tableHeaderStore', // 店铺/商家
  'tableHeaderOrderStatus', // 订单状态
  'tableHeaderDeparture', // 发货地区
  'tableHeaderRecipient', // 收货地区
  'tableHeaderDeliveryTypeName', // 送货方式
  'tableHeaderPickupInfo', // 揽收信息
  'archivedTypeName', // 归档方式
  'tableHeaderCarriers', // 服务商
  'tableHeaderShipStatus', // OneShip发货状态
  'pickGoodStatus', // 拣货状态
  'pickGoodOrderNo', // 拣货单号
  'tableHeaderCreated', // 创建时间
  'tableHeaderRemarks', // 订单备注
  'scheduleDeliveryTime', // 预约派送时间
]

/** 运单表头key */
const LogisticHeaderKeys = [
  'orderListOneshipNo', // 运单号
  'orderListRefNo', // 参考号
  'orderListDeparture', // 发货地址
  'orderListArrival', // 收货地址
  'logisticInfo', // 物流信息
  'cod', // 代收货款
  'orderListStatus', // 运单状态
  'wayBillStatus', // 面单状态
  'latestTracking', // 最新轨迹
  'pickupMethod', // 揽收方式
  'orderListCreated', // 创建时间
  'freight', // 运费
  'orderListDeliveryType', // 配送类型
  'shipRemark', // 发货备注
  'pickupTime', // 预约揽收时间
  'pickupTimeActual', // 实际揽收时间
  'receivedTime', // 签收时间
  'scheduleDeliveryTime', // 预约派送时间
]

/** 退货单表头 */
const ReverseHeaderKeys = [
  'orderListOneshipNo', // 运单号
  'orderListRefNo', // 参考号
  'reverseOriginalOrderNo', // 退货原订单号
  'orderListDeparture', // 发货地址
  'orderListArrival', // 收货地址
  'logisticInfo', // 物流信息
  'orderListStatus', // 运单状态
  'wayBillStatus', // 面单状态
  'latestTracking', // 最新轨迹
  'pickupMethod', // 揽收方式
  'orderListCreated', // 创建时间
  'freight', // 运费
  'orderListDeliveryType', // 配送类型
  'shipRemark', // 发货备注
  'pickupTime', // 预约揽收时间
  'pickupTimeActual', // 实际揽收时间
  'receivedTime', // 签收时间
  'scheduleDeliveryTime', // 预约派送时间
]

/** 即时物流订单表头key */
const ImmediateOrderHeaderKeys = [
  'tableHeaderShopNo', // 原订单号
  'tableHeaderStore', // 店铺/商家
  'tableHeaderOrderStatus', // 订单状态
  'tableHeaderDeparture', // 发货地区
  'tableHeaderRecipient', // 收货地区
  'tableHeaderCarriers', // 服务商
  'tableHeaderShipStatus', // OneShip发货状态
  'serviceType', // 服务类型
  'tableHeaderCreated', // 创建时间
  'remark', // 备注
]

/** 即时物流运单表头key */
const ImmediateLogisticHeaderKeys = [
  'orderListOneshipNo', // 运单号
  'orderListRefNo', // 参考号
  'orderListDeparture', // 发货地址
  'orderListArrival', // 收货地址
  'logisticInfo', // 物流信息（服务商）
  'orderListStatus', // 运单状态
  'freight', // 运费
  'serviceType', // 服务类型
  'orderListCreated', // 创建时间
  'paymentCod', // 货到付款
  'shipRemark', // 备注
]

/** 订单新增默认不选中 */
const OrderUnSelectKeys = ['scheduleDeliveryTime']

/** 运单新增默认不选中 */
const LogisticUnSelectKeys = ['scheduleDeliveryTime']

/** 退货单表头默认不选中 */
const ReverseHeaderUnSelectKeys = ['scheduleDeliveryTime']

export enum HeaderType {
  order = 'order',
  logistic = 'logistic',
  reverse = 'reverse',
  immediateOrder = 'immediateOrder',
  immediateLogistic = 'immediateLogistic',
}

/** 默认不选中的表头 */
export const UnSelectKeysMap = {
  [HeaderType.order]: OrderUnSelectKeys,
  [HeaderType.logistic]: LogisticUnSelectKeys,
  [HeaderType.reverse]: ReverseHeaderUnSelectKeys,
  [HeaderType.immediateOrder]: OrderUnSelectKeys,
  [HeaderType.immediateLogistic]: LogisticUnSelectKeys,
}

/** 表头key */
export const HeaderKeysMap = {
  [HeaderType.order]: OrderHeaderKeys,
  [HeaderType.logistic]: LogisticHeaderKeys,
  [HeaderType.reverse]: ReverseHeaderKeys,
  [HeaderType.immediateOrder]: ImmediateOrderHeaderKeys,
  [HeaderType.immediateLogistic]: ImmediateLogisticHeaderKeys,
}

/** 表头设置 */
export const SettingHeaderMap = {
  [HeaderType.order]: 'SALES_COLUMN_FEATURE',
  [HeaderType.logistic]: 'ORDER_COLUMN_FEATURE',
  [HeaderType.reverse]: 'REVERSE_COLUMN_FEATURE',
  [HeaderType.immediateOrder]: 'ON_DEMAND_SALES_COLUMN_FEATURE',
  [HeaderType.immediateLogistic]: 'ON_DEMAND_ORDER_COLUMN_FEATURE',
}

/**
 * 表头排序设置
 * @description
 * 同时也作为兜底值使用，对应的是 store 的值
 */
export const SettingSortMap = {
  [HeaderType.order]: 'SALES_COLUMN_FEATURE_SORT',
  [HeaderType.logistic]: 'ORDER_COLUMN_FEATURE_SORT',
  [HeaderType.reverse]: 'REVERSE_COLUMN_FEATURE_SORT',
  [HeaderType.immediateOrder]: 'ON_DEMAND_SALES_COLUMN_FEATURE_SORT',
  [HeaderType.immediateLogistic]: 'ON_DEMAND_ORDER_COLUMN_FEATURE_SORT',
}

/**
 * 有对应feature key才展示这些列
 */
export const FeatureKeyHeaderKeysMap = {
  [HeaderType.order]: {
    os_picking: ['pickGoodStatus', 'pickGoodOrderNo'],
  },
} as const

/**
 * 编辑表头的多语言key前缀
 */
export const HeaderPrefixKeyMap = {
  [HeaderType.order]: 'order.list.',
  [HeaderType.logistic]: 'logistics.list.',
  [HeaderType.reverse]: 'logistics.list.',
  [HeaderType.immediateOrder]: 'order.list.',
  [HeaderType.immediateLogistic]: 'logistics.list.',
}
