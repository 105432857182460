import React from 'react'
import { LinearProgress } from '@/components/LinearProgress'

export type AuthorizedCustomElement = React.ReactElement | React.FC | (() => React.ReactElement)

export interface AuthorizedProps {
  isReady?: boolean
  isPermit?: boolean
  progress?: AuthorizedCustomElement
  fallback?: AuthorizedCustomElement
  children?: AuthorizedCustomElement
}

export const Authorized: React.FC<AuthorizedProps> = (props) => {
  const { isReady, isPermit, progress = () => <LinearProgress />, fallback, children } = props
  const render = (Renderer: AuthorizedCustomElement) => {
    if (React.isValidElement(Renderer)) {
      return Renderer
    }

    if (typeof Renderer === 'function') {
      return <Renderer />
    }

    return null
  }

  if (isReady === false) {
    return render(progress)
  }

  if (isPermit === true) {
    return render(children)
  }

  return render(fallback)
}

Authorized.displayName = 'Authorized'
