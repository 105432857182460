import API from '@/services/http'
import type * as Types from './type'

/** 查询默认模板 */
export const getTrackingDefaultTemplate = (params: Types.TemplateGetRequest) => API.toast.post<Types.BaseTemplateVo>('/api/tracking/get/default/template', params)

/** 查询自定义模板 */
export const getTrackingCustomTemplate = (params: Types.TemplateGetRequest) => API.toast.post<Types.BaseTemplateVo>('/api/tracking/get/custom/template', params)

/** 保存短信模板 */
export const saveSmsTemplate = (params: Types.SmsTemplateRequestVo) => API.toast.post('/api/tracking/save/sms/template', params)

/** 保存追踪页模板 */
export const saveTrackingpageTemplate = (params: Types.TrackingPageTemplateRequestVo) => API.toast.post('/api/tracking/save/track_page/template', params)

/**
 * 自定义模板暂存接口
 */

export const editUpdateTemplate = (params: Types.EditUpdateTemplateReq) => API.toast.post<Types.EditUpdateTemplateResponse>('/api/tracking/stage/template', params)

/** 自定义模板预览接口 */
export const previewEmailTemplate = (params: Types.PreviewEmailReq) => API.toast.post<Types.EditUpdateTemplateResponse>('/api/tracking/preview/template', params)

/** 保存并发布 */
export const saveEmailTemplate = (params: Types.SaveEmailTemplateReq) => API.toast.post<Types.EditUpdateTemplateResponse>('/api/tracking/save/email/template', params)
