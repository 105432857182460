import type { AppDispatch, AppGetState, AppThunk } from '..'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { OneMessage } from '@yy/one-ui'
import { GET_DEFAULT_PAGE_SIZE } from '@/constant'
import { awaitAsyncTask, createWsMsgId } from '@/hooks'
import { getMergeBatchGroupList, postFullUpdate } from '@/services/apis/batchMergeShip'
import i18n from '@/services/i18n'
import { getUrlParam } from '@/utils/getUrlParam'
import { EnumTabs } from './enum'
import type * as Types from './types'
import { track } from '@/services/track'
import { handleMessage } from '../newBatchShipSlice'
import type { BatchDeliveryFullUpdateInfoVoRequest } from '@/services/apis/batchMergeShip/types'
import { genTraceParent } from '@/utils'

export * from './enum'

export const getKeyInfo = (tab: string) => {
  const isInvalid = tab === EnumTabs.INVALID
  const isValid = tab === EnumTabs.VALID
  return {
    isInvalid,
    isValid,
  }
}

const initialState: Types.IInitialState = {
  tab: EnumTabs.VALID,
  // in case of users refresh the page
  taskId: '',
  taskSnapshotVersion: '',
  list: [],
  pagination: {
    pageSize: GET_DEFAULT_PAGE_SIZE(),
    pageNum: 1,
  },
  loading: false,
  totalFreight: 0,
  showPending: false,
  data: null,
  unsubsribes: [],
}

const batchMergeShipSlice = createSlice({
  name: 'newBatchMergeShipSlice',
  initialState: {
    ...initialState,
    // prevent refresh
    taskId: getUrlParam('taskId'),
    taskSnapshotVersion: getUrlParam('taskSnapshotVersion'),
    showPending: getUrlParam('needToPending') ? !!Number(getUrlParam('needToPending')) : false,
  },
  reducers: {
    update(state, action: PayloadAction<Partial<Types.IInitialState>>) {
      const pagination = {
        ...(state.pagination || {}),
        ...(action.payload.pagination || {}),
      }

      return {
        ...state,
        ...action.payload,
        pagination,
      }
    },
  },
})

export const { update } = batchMergeShipSlice.actions

export const clearBatchMergeSlice = (): AppThunk => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { newBatchMergeShipSlice } = getState()
  const unsubsribes = newBatchMergeShipSlice?.unsubsribes || []

  if (unsubsribes?.length) {
    unsubsribes.forEach((cb) => {
      cb?.()
    })
  }

  dispatch(
    update({
      ...initialState,
    })
  )
}

export default batchMergeShipSlice.reducer

/**
 *
 * @param data awaitTaskId  asnyc task id
 * @returns
 */
export const startListenForBatchMergeShip =
  (data: { taskId: string; taskSnapshotVersion: string; needToPending?: number; wsMsgId: string }, callback?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const { taskId, taskSnapshotVersion, needToPending, wsMsgId } = data
    const { configSlice } = getState()
    const { batchMergeShipTimeout = 10 } = configSlice

    const start = Date.now()

    try {
      await dispatch(clearBatchMergeSlice())

      await dispatch(
        update({
          wsMsgId,
          taskSnapshotVersion,
          taskId,
          loading: true,
          showPending: !!needToPending,
        })
      )

      callback?.()

      const res = await awaitAsyncTask(taskSnapshotVersion, {
        timeout: Number(batchMergeShipTimeout) * 1000,
        wsMsgId,
      })
      // 1. if current function context taskId not equal to url taskId, don't excute the function
      // 2. the function must be running in the special page
      if (getUrlParam('taskSnapshotVersion') !== taskSnapshotVersion || location.pathname !== '/ship/order/new-batch-merge') {
        return
      }

      if (!res) {
        dispatch(update({ loading: false }))
        return OneMessage.error(i18n.t('system.error.2'))
      }
      // get list
      if (res?.response?.success) {
        await dispatch(getList())

        // 统计第一次进来的接口市场
        track('businessTrack', {
          // 追踪id
          track_id: taskId,
          more_info: JSON.stringify({
            tti: Date.now() - start,
          }),
        })
        return
      }

      // timeout or others error
      handleMessage(res.response)
      dispatch(update({ loading: false }))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

export const getList = (): AppThunk => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { newBatchMergeShipSlice } = getState()
  const { taskId, taskSnapshotVersion, tab, pagination } = newBatchMergeShipSlice

  if (!taskId || !taskSnapshotVersion) return

  dispatch(update({ loading: true }))
  try {
    const res = await getMergeBatchGroupList({
      ...pagination,
      validGroup: Number(tab),
      taskId,
      sourceType: 1,
      taskSnapshotVersion,
    })

    const { batchAggDeliveryDetailResponses, ...data } = res?.data || {}

    dispatch(
      update({
        list: batchAggDeliveryDetailResponses?.data || [],
        data,
        showPending: false,
      })
    )
  } finally {
    dispatch(update({ loading: false }))
  }
}

export const updateQuery =
  (data: Partial<Pick<Types.IInitialState, 'pagination' | 'tab'>>): AppThunk =>
  async (dispatch: AppDispatch) => {
    await dispatch(update(data))

    dispatch(getList())
  }

export const fullBatchUpdate =
  (data: BatchDeliveryFullUpdateInfoVoRequest['data']): AppThunk =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState()
    const { batchMergeShipTimeout } = state.configSlice
    const { tab, taskId, taskSnapshotVersion } = state.newBatchMergeShipSlice

    const nonce = genTraceParent()
    const extraTaskOptions = createWsMsgId()

    try {
      await postFullUpdate({
        data,
        taskId,
        taskSnapshotVersion,
        validGroup: Number(tab),
        nonce,
        ...extraTaskOptions,
      })

      const _getList = () => {
        setTimeout(() => {
          dispatch(getList())
        }, 500)
      }

      const result = await awaitAsyncTask(nonce, { idType: 'nonce', timeout: Number(batchMergeShipTimeout) * 1000, ...extraTaskOptions })

      if (!result) {
        return OneMessage.error(i18n.t('system.error.2'))
      }
      // get list
      if (result?.response?.success) {
        _getList()
        // timeout or others error
      } else {
        handleMessage(result.response)
      }
    } finally {
      dispatch(update({ loading: false }))
    }
  }

export const pushUnsubsribes =
  (cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const { newBatchMergeShipSlice } = getState()

    const unsubsribes = newBatchMergeShipSlice.unsubsribes?.slice() || []

    if (cb) {
      unsubsribes.push(cb)
    }

    dispatch(
      update({
        unsubsribes,
      })
    )
  }
