export const ROOM_TEMPERATURE = '1'
export const REFRIGERATED = '2'
export const FROZEN = '3'

/**
 * 服务产品储存条件
 */

export default [
  // 常温
  { label: 'constant.roomTemperature', value: ROOM_TEMPERATURE },
  // 冷藏
  { label: 'constant.refrigerated', value: REFRIGERATED },
  // 冰冻
  { label: 'constant.frozen', value: FROZEN },
]
