import React from 'react'
import * as Sentry from '@sentry/react'
import { ERROR_TYPE_ENUM } from '@/services/sentry/constants'
import { ErrorPage } from './ErrorPage'
import styles from './index.module.less'

interface ErrorState {
  hasError?: boolean
  error?: string
  info?: {
    componentStack?: string
  }
}

class ErrorBoundary extends React.Component {
  state: ErrorState = {
    error: null,
    info: null,
    hasError: false,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    this.setState({
      error,
      info,
    })

    Sentry.captureException(error, (scope) => {
      scope.setTag('type', ERROR_TYPE_ENUM.RENDER)
      scope.setTag('errorType', error.name)
      return scope
    })
  }

  render() {
    const { children } = this.props
    const { error, info, hasError } = this.state
    if (hasError) {
      if (process.env.APP_ENV === 'product') {
        return <ErrorPage />
      }
      return (
        <div className={styles.page}>
          <h3>{error?.toString()}</h3>
          <pre>{info?.componentStack}</pre>
        </div>
      )
    }
    return children
  }
}

export default ErrorBoundary
