import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCopyToClipboard } from 'react-use'
import { Copy } from '@yy/one-icon'
import { OneMessage, OnePopover } from '@yy/one-ui'
import styles from './index.module.less'

interface OrderNosPopoverProps {
  orderNos?: string[]
  type?: string
}

const OrderNosPopover: React.FC<OrderNosPopoverProps> = ({ orderNos = [], type = 'sender' }) => {
  const { t } = useTranslation()
  const [, copyToClipboard] = useCopyToClipboard()

  return (
    <OnePopover
      placement="bottom"
      overlayClassName={styles.popoverInner}
      content={
        <div className={styles.popover}>
          {/* 'sender' | 'consignee' */}
          {type === 'sender' ? (
            <div className={styles.title}>{t('mergeAddressModal.countSenderOrders', { n: orderNos.length })}</div>
          ) : (
            <div className={styles.title}>{t('mergeAddressModal.countOrders', { n: orderNos.length })}</div>
          )}
          <div className={styles.orderWrap}>
            {orderNos.map((orderNum) => {
              return (
                <div className={styles.orderItem} key={orderNum}>
                  <span className={styles.orderNo}>{orderNum}</span>
                  <Copy
                    onClick={(e) => {
                      e.stopPropagation()
                      copyToClipboard(orderNum)
                      OneMessage.success(t('mergeAddressModal.copySuccess'))
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
      }
    >
      <span className={styles.number}>{orderNos.length}</span>
    </OnePopover>
  )
}

OrderNosPopover.displayName = 'OrderNosPopover'
export default OrderNosPopover
