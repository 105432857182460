/* eslint-disable @typescript-eslint/no-unused-vars */
// 日志
import { SLQ_ENV_MAP } from '@/services/track/configure'
import { APP_ID } from '@/services/track/constants'
import Cookies from 'js-cookie'
import { eatError } from '../function'
import { getTracker } from '@/services/track'
import { uuid } from '../common'

/** 默认配置 */
const DefaultEventOptions = {
  act: 'webappuserlogger',
  release: process.env.TRACE_RELEASE,
  env: SLQ_ENV_MAP[process.env.APP_ENV],
  project_id: 'weblogger',
  app_id: APP_ID,
  ua: navigator.userAgent,
}

let userSessionId = ''
let sceneList = []
let keepScene = false

function refreshUserSessionId() {
  userSessionId = `${uuid()}${uuid()}${uuid()}_${Date.now()}`
}

/**
 * 获取sceneList最后一项
 * @returns
 */
export const getLastScene = () => {
  return sceneList[sceneList.length - 1]
}

let userInfo = { sellerId: '' }

let tracker = null
let queue = []

function cleanQueue() {
  queue.forEach((reportData) => {
    tracker.report(reportData)
  })
  queue = []
}

interface LogData {
  data?: any
  type: string
}
interface RequestLogData extends LogData {
  type: 'request'
  url: string
  trace_id: string
  header?: any
}
interface ResponseLogData extends LogData {
  type: 'response'
  url: string
  trace_id: string
  tti: number
  data: any
}
interface WebsocketLogData extends LogData {
  type: 'websocket'
  data: any
}
interface NavigationLogData extends LogData {
  type: 'navigation'
  /** 旧链接 */
  data: string
}
interface LoadLogData extends LogData {
  type: 'load'
  /**建立链接时间 */
  // connectTime?: number
  /**域名解析时间 */
  // domainLookupTime?: number
  /**响应时间 */
  tti?: number
}

interface ActionLogData extends LogData {
  type: 'action'
  event_type: string
  event_target?: string
  url?: string
  trace_id?: string
}

const track = async (data: RequestLogData | ResponseLogData | WebsocketLogData | NavigationLogData | LoadLogData | ActionLogData) => {
  const reportData = {
    ...data,
    ...DefaultEventOptions,
    href: window.location.href,
    uid: Cookies.get('osudb_uid'),
    seller_id: userInfo.sellerId,
    scene_list: sceneList.join(','),
    user_session_id: userSessionId,
  }
  if (process.env.APP_ENV === 'product') {
    Object.keys(reportData).forEach((key) => {
      if (typeof reportData[key] === 'object' && reportData[key] !== null) reportData[key] = JSON.stringify(reportData[key])
    })
    if (reportData.data?.length > 1000) reportData.data = reportData.data.slice(0, 1000)
    if (!tracker) {
      queue.push(reportData)
      return
    }
    tracker.report(reportData)
  } else {
    // eslint-disable-next-line no-console
    // console.log(reportData)
  }
}

const _logger = {
  initUserInfo: (info: { sellerId: string }) => (userInfo = info),
  /**
   * 大流程里的一个小场景
   * @param s
   * @returns
   */
  setScene: (scene: string) => {
    if (sceneList[sceneList.length - 1] === scene) return
    sceneList.push(scene)
    track({ type: 'action', event_type: 'setScene', data: scene })
  },
  /**
   * 开始一个大流程
   * @param s
   * @returns
   */
  startScene: (scene: string) => {
    if (keepScene) {
      logger.setScene(scene)
      keepScene = false
      return
    }
    sceneList = [scene]
    refreshUserSessionId()
    track({ type: 'action', event_type: 'startScene', data: scene })
  },
  /**
   * 重置sceneList
   */
  resetScene: () => {
    refreshUserSessionId()
    sceneList = [window.location.pathname]
  },
  /**
   * 下次调用startScene将变成setScene
   */
  keepScene: () => {
    keepScene = true
  },
  track,
  log: track as (data: LogData) => void,
}

// 对外暴露的所有方法都不能抛错
Object.keys(_logger).forEach((key) => {
  _logger[key] = eatError(_logger[key])
})

export const logger = _logger

async function run() {
  try {
    logger.resetScene()
    const performanceNavigationTiming = performance.getEntriesByType('navigation')?.[0] as PerformanceNavigationTiming
    logger.track({ type: 'load', tti: performanceNavigationTiming?.responseStart })

    tracker = await getTracker()
    cleanQueue()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

setTimeout(run, 0)
