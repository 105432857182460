import type { RouteConfig } from 'react-router-config'
import { Package } from '@yy/one-icon'
import { withAsync } from '@/components/Async'
import Blank from '@/layouts/Blank'
import { withAuthRedirect } from '@/components/Authorized/hoc/withAuthRedirect'

const routes: RouteConfig[] = [
  {
    /** 拣货单 */
    alias: 'PickGoods',
    path: '/pickGoods',
    component: withAuthRedirect(Blank, { first: '/pickGoods/list' }),
    menuTitle: 'pickGoods.title',
    icon: Package,
    permissionCode: 'picking',
    routes: [
      /** 列表 */
      {
        alias: 'PickGoodsList',
        path: '/list',
        component: withAsync(() => import(/* webpackChunkName: "PickGoods" */ '@/containers/PickGoods/List')),
        exact: true,
        title: 'pickGoods.title',
      },
      /** 商品复核 */
      {
        alias: 'PickGoodsDetail',
        path: '/detail',
        component: withAsync(() => import(/* webpackChunkName: "PickGoods" */ '@/containers/PickGoods/PickGoodsDetail')),
        exact: true,
      },
    ],
  },
]

export default routes
