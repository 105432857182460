import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OneButton, OneModal } from '@yy/one-ui'
import { neverRemindApi } from '@/services/apis'
import complete from '@/static/images/guide/guideComplete.png'
import type { IGuideInfo } from '@/store/commonSlice/types'
import styles from './index.module.less'

/**
 * 新手任务完成弹窗
 */
interface ICompleteMissionModal {
  guideInfo: IGuideInfo
}
const CompleteMissionModal: React.FC<ICompleteMissionModal> = (props) => {
  const { guideInfo } = props
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    if (guideInfo?.taskDetailResBos?.length && guideInfo?.taskDetailResBos?.every((i) => i.completeStatus)) {
      setVisible(true)
    }
  }, [guideInfo])

  const handleComplete = async () => {
    await neverRemindApi()
    setVisible(false)
  }
  return (
    <OneModal
      width={680}
      maskClosable={false}
      closable={false}
      visible={visible}
      footer={null}
      onCancel={() => {
        setVisible(false)
      }}
      className={styles.modal}
      bodyStyle={{ padding: 0, display: 'flex' }}
    >
      <div className={styles.left}>
        <img src={complete} alt="" />
      </div>
      <div className={styles.right}>
        <p className={styles.title}>{t('dashbord.guide.finishAll')}</p>
        <p className={styles.content} dangerouslySetInnerHTML={{ __html: t('dashbord.guide.touchUsDanger') }}></p>
        <p className={styles.end}>{t('dashbord.guide.thanksTips')}</p>
        <OneButton onClick={handleComplete} style={{ width: '88px' }} type="primary">
          {t('auth.area.noAuth.okText')}
        </OneButton>
      </div>
    </OneModal>
  )
}

CompleteMissionModal.displayName = 'CompleteMissionModal'
export default CompleteMissionModal
