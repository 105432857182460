import type * as Types from '@/types'

export type TPaymentType = 1 | 2

export enum PaymentTypeEnum {
  cod = 1,
  notCod = 2,
}

export const PAYMENT_TYPE: Types.DictLabel<TPaymentType> = {
  1: 'orderDetail.cashOnDelivery',
  2: 'orderDetail.otherPayType',
}
