import Cookies from 'js-cookie'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { PERSONAL_TYPE } from '@/constant'
import { DOMAIN_STATUS } from '@/containers/TrackSettings/constant'
import { getLanguageList } from '@/hooks/useLanguage'
import type { UserSettingListType } from '@/services/apis'
import { getUserPermissionTree, getUserSetting } from '@/services/apis'
import Api from '@/services/http'
import { subscribeUrl, userUrl } from '@/services/url'
import type { AppDispatch, AppGetState, AppThunkDispatch } from '@/store'
import StorageUtil from '@/utils/storageUtil'
import { thunkDebounce, waitStore } from '../storeUtils'
import { getGuideQueue, initGuideQueue, saveGuideQueue } from './guideQueue'
import type * as Types from './types'
import { sentryReport } from '@/services/sentry/report'
import { logger } from '@/utils/logger'

const initialState: Types.UserInitState = {
  isLogined: !!Cookies.get('osudb_uid'),
  osudb_uid: Cookies.get('osudb_uid') || '',
  osudb_subappid: Cookies.get('osudb_subappid') || '',
  role: '',
  info: null,
  userSettingList: null,
  featureKeys: [],
  serviceKeys: [],
  languageList: [],
  // 新人指引
  guideQueue: initGuideQueue(),
  domainInfo: {
    domain: '',
    status: '',
    domainMappingId: '',
    rightFeatureKey: [],
  },
  walletList: [],
  authorization: '',
  permissionTree: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLogin(state, action: PayloadAction<Types.UserState>) {
      return {
        ...state,
        isLogined: action.payload.isLogined,
        osudb_uid: action.payload.osudb_uid,
        authorization: action.payload.authorization,
      }
    },
    setUserInfo(state, action: PayloadAction<Types.UserInfo>) {
      const newState = {
        ...state,
        info: {
          ...action.payload,
        },
      }
      logger.initUserInfo({ ...action.payload })
      if (newState.info.uid) newState.guideQueue = getGuideQueue(action.payload.uid)
      return newState
    },
    setWalletListInfo(state, action: PayloadAction<Types.IWallInfo[]>) {
      return {
        ...state,
        walletList: action.payload,
      }
    },
    // 更新钱包信息
    updateWalletListInfo(state, action: PayloadAction<Types.IWallInfo>) {
      const newWalletInfo = action.payload
      const walletList = [...state.walletList]
      const updateListInd = walletList.findIndex((item) => item.currencyCode === newWalletInfo.currencyCode)
      walletList[updateListInd] = {
        ...newWalletInfo,
        isDefault: walletList[updateListInd]?.isDefault,
      }

      return {
        ...state,
        walletList,
      }
    },
    setUserSettingList(state, action: PayloadAction<any>) {
      return {
        ...state,
        userSettingList: {
          ...action.payload,
        },
      }
    },
    setFeatureKeys(state, action: PayloadAction<any>) {
      return {
        ...state,
        featureKeys: action.payload,
      }
    },
    setServiceKeys(state, action: PayloadAction<any>) {
      return {
        ...state,
        serviceKeys: action.payload,
      }
    },
    setGuideQueue(state, action: PayloadAction<{ key: Types.GuideKeys; showed?: boolean }>) {
      const {
        payload: { key, showed },
      } = action
      const newGuideQueue = state.guideQueue.map((i) => (i.key === key ? { key, showed: showed ?? true } : i))
      saveGuideQueue(newGuideQueue, state.info.uid)
      return {
        ...state,
        guideQueue: newGuideQueue,
      }
    },
    setDomainInfo(state, action: PayloadAction<Types.DomainInfo>) {
      return {
        ...state,
        domainInfo: {
          ...action.payload,
        },
      }
    },
    setLanguageList(state, action: PayloadAction<Types.UserInitState['languageList']>) {
      return {
        ...state,
        languageList: action.payload,
      }
    },
    setPermissionTree(state, action: PayloadAction<Types.UserInitState['permissionTree']>) {
      return {
        ...state,
        permissionTree: action.payload,
      }
    },
  },
})

export const { setLogin, setUserSettingList, setUserInfo, setGuideQueue, setDomainInfo, setWalletListInfo, updateWalletListInfo, setLanguageList, setPermissionTree } =
  userSlice.actions

export default userSlice.reducer

export const checkLogin = () => async (dispatch: AppDispatch) => {
  const osudb_uid = Cookies.get('osudb_uid') || ''
  const osudb_subappid = Cookies.get('osudb_subappid') || ''
  const authorization = Cookies.get('authorization') || ''

  const isLogined = !!osudb_uid
  const newUserState: Types.UserState = {
    isLogined,
    osudb_uid,
    osudb_subappid,
    authorization,
  }

  dispatch(userSlice.actions.setLogin(newUserState))
  return newUserState
}

// 更新接收营销政策
export const updateAcceptMarketing = async (acceptMarketing = true) => {
  const resp = await Api.admin.post('/oneclub-api/api/user/info/get-basic')
  const oneshipName = resp?.data?.oneshipName
  return Api.admin.post('/oneclub-api/api/user/info/update-basic', { oneshipName, acceptMarketing })
}

export const loginout = () => async (dispatch: AppDispatch) => {
  const rst = await Api.udb.get(userUrl.logout, {
    params: {
      subappid: process.env.SUB_APPID,
      appid: process.env.APPID,
    },
  })
  const { rescode } = rst
  if (rescode === '0') {
    Cookies.remove('trace_chance')
    Cookies.remove('authorization')
    // 清除
    window.localStorage.removeItem('os-init-user-default-lang')
    StorageUtil.remove('interComConfig')
    dispatch(userSlice.actions.setLogin({ isLogined: false, osudb_uid: '', osudb_subappid: '' }))
    return Promise.resolve(rst)
  }
  return Promise.reject(rst)
}

/** 获取用户配置 */
export const getFeatureKeys = thunkDebounce(() => async (dispatch: AppDispatch) => {
  const response = await Api.admin.post(subscribeUrl.getFeatureKeys)
  const { data } = response
  dispatch(userSlice.actions.setFeatureKeys(data.featureKeys))
})

/** 商家有效service key查询 */
export const getServiceKeys = (params?: Types.ServiceKeys) => async (dispatch: AppDispatch) => {
  const response = await Api.admin.post(subscribeUrl.getServiceKeys, {
    params: {
      ...params,
    },
  })
  const { data, code, bizMessage, message } = response
  if (code === 'SUCCESS') {
    dispatch(userSlice.actions.setServiceKeys(data.serviceRights))
    return data.serviceRights
  }

  return Promise.reject({ code, bizMessage, message })
}

/**
 * 是否更新钱包信息
 * @param shouldUpdateWallet
 * @returns
 */
export const getUserInfo =
  (shouldUpdateWallet = false) =>
  async (dispatch: AppThunkDispatch) => {
    const { data: userInfo } = await Api.admin.post<Types.UserInfo>(userUrl.info)
    /** getLanguageList依赖配置 */
    await waitStore((s) => s.configSlice.ready)
    const { countryCode, userType, settlementMethod, displayWallet, countryCurrencyCode } = userInfo
    const isPersonal = userType === PERSONAL_TYPE
    // 是否预付
    const isPrepaid = settlementMethod === 'prepaid'
    dispatch(
      userSlice.actions.setUserInfo({
        ...userInfo,
        selfArea: countryCode,
        isPersonal,
        isPrepaid,
        currencyCode: countryCurrencyCode,
      })
    )
    dispatch(setLanguageList(getLanguageList(countryCode)))
    // 有流水就展示钱包
    if (shouldUpdateWallet && displayWallet) {
      dispatch(getWalletInfo())
    }
    return userInfo
  }

export const getWalletInfo = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { data } = await Api.admin.post<Types.walletInfoList>('/api/wallet/admin/info/list')
  const currencyCode = getState().user?.info?.currencyCode
  // 依赖用户的默认钱包币种数据
  await waitStore((s) => s.common.userSettingIsReady)
  const defaultCurrencyCode = getState().common.setting?.USER_DEFAULT_CURRENCY_CODE?.dataValue || currencyCode

  const walletList = data?.list || []
  const finalWalletList = walletList.map((item) => ({
    ...item,
    isDefault: item.currencyCode === defaultCurrencyCode,
  }))

  dispatch(userSlice.actions.setWalletListInfo(finalWalletList))
  return walletList
}

export const getUserSettingList = (params?: UserSettingListType) => async (dispatch: AppDispatch) => {
  const result = await getUserSetting(params)
  if (result) {
    const { data } = result
    const { settings } = data
    const settingsMap: Record<string, Types.SaasFormTypeKey[]> = {}
    settings.map((item) => {
      try {
        settingsMap[item.settingCode] = JSON.parse(item.dataValue)
      } catch (error) {
        const reason = new Error(`Setting ${item.settingCode} json invalid`)
        // eslint-disable-next-line no-console
        console.error(reason)
        sentryReport(reason)
      }
    })
    dispatch(setUserSettingList(settingsMap))
    return data
  }
}

// 获取域名信息
export const getCustomDomainInfo = () => async (dispatch: AppDispatch) => {
  const res = await Api.admin.post<Types.DomainInfoResType>(userUrl.domainInfo)
  if (res) {
    const { domain, status, domainMappingId, rightFeatureKey } = res.data || {}
    dispatch(
      setDomainInfo({
        domain,
        status,
        domainMappingId,
        rightFeatureKey,
      })
    )
  }
}

// 保存域名信息（包括新增与修改）
export const saveCustomDomainInfo = (param: Types.SaveDomainInfoReqType) => async () => {
  const url = param.domainMappingId && param.status === DOMAIN_STATUS.active ? userUrl.updateDomain : userUrl.insertDomain
  const res = await Api.toast.post<Types.SaveDomainInfoResType>(url, {
    domain: param.domain,
    domainMappingId: param.domainMappingId,
  })
  const { data } = res
  return data
}

// 重置域名
export const resetDomainInfo = (param: { domainMappingId: string }) => async () => {
  const res = await Api.admin.post<Types.ResetDomainInfoResType>(userUrl.deleteDomain, param)
  const { bizCode, data } = res
  if (bizCode === 'SUCCESS') {
    return data?.isSuccess
  }
}

/**
 * 初始化权限树
 * @returns
 */
export const initUserPermissionTree = () => async (dispatch: AppDispatch) => {
  const res = await getUserPermissionTree()
  dispatch(setPermissionTree(res.data.resourceTree))
}
