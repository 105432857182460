import type { TabPaneProps } from 'antd'
import { Button, Card, Progress, Row, Tabs } from 'antd'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Arrival, IconCheckCircle, Order, Warehouse } from '@yy/one-icon'
import { useGuide } from '@/hooks'
import addressImg from '@/static/images/guide/guideAddress.png'
import orderImg from '@/static/images/guide/guideOrder.png'
import trackImg from '@/static/images/guide/guideTrack.png'
import type { IGuideInfo } from '@/store/commonSlice/types'
import type { PaneContentProps } from './conpoments/PaneContent'
import PaneContent from './conpoments/PaneContent'
import styles from './index.module.less'

const { TabPane } = Tabs
interface GuideCardProps {
  showCancel: () => void
  guideInfo: IGuideInfo
}

const LANG_MAP = {
  'zh-CN': 'zh-cn',
  'zh-TW': 'zh-tw',
  'zh-HK': 'zh-tw',
  en: 'en-us',
  th: 'th',
  vi: 'vi',
}

const GuideCard: React.FC<GuideCardProps> = ({ showCancel, guideInfo }) => {
  const { t, i18n } = useTranslation()
  const { handleToAddress, handleToCreateOrder, handleToTrack } = useGuide(null)
  const tabConfig = useMemo<TabPaneProps[] | PaneContentProps[]>(() => {
    const finishList = {
      address: guideInfo?.taskDetailResBos?.find((i) => i.guideTaskType === 'ADDRESS_GUIDE_TASK')?.completeStatus,
      order: guideInfo?.taskDetailResBos?.find((i) => i.guideTaskType === 'SALES_ORDER_GUIDE_TASK')?.completeStatus,
      track: guideInfo?.taskDetailResBos?.find((i) => i.guideTaskType === 'TRACK_SETTING_GUIDE_TASK')?.completeStatus,
    }
    return [
      {
        key: 'address',
        tab: (
          <Row align="middle">
            {finishList['address'] ? <IconCheckCircle origin className={styles.icon} /> : <Warehouse className={styles.icon} style={{ color: '#02c8c6' }} />}{' '}
            {t('dashbord.guide.addAddress')}
          </Row>
        ),
        imgSrc: addressImg,
        btnText: t('dashbord.guide.addAddress'),
        handleClick: handleToAddress,
        title: t('dashbord.guide.addAddressTips'),
        content: t('dashbord.guide.createOrder'),
        faqUrl: `https://support.oneship.io/hc/${LANG_MAP[i18n.language] ?? 'en-us'}/articles/4402644086287-%E4%BB%80%E4%B9%88%E6%98%AF%E5%9C%B0%E5%9D%80%E7%B0%BF-`,
      },
      {
        key: 'order',
        tab: (
          <Row align="middle">
            {finishList['order'] ? <IconCheckCircle origin className={styles.icon} /> : <Order className={styles.icon} style={{ color: '#02c8c6' }} />}
            {t('dashbord.guide.createAnOrder')}
          </Row>
        ),
        imgSrc: orderImg,
        btnText: t('dashbord.guide.createAnOrder'),
        handleClick: handleToCreateOrder,
        title: t('dashbord.guide.firstOrder'),
        content: t('dashbord.guide.saveTime'),
        faqUrl: `https://support.oneship.io/hc/${
          LANG_MAP[i18n.language] ?? 'en-us'
        }/articles/4403544442127-%E8%AE%A2%E5%8D%95%E7%AE%A1%E7%90%86%E4%BB%8B%E9%9D%A2%E4%BB%8B%E7%BB%8D-%E8%AE%A2%E5%8D%95%E7%AE%A1%E7%90%86`,
      },
      {
        key: 'track',
        tab: (
          <Row align="middle">
            {finishList['track'] ? <IconCheckCircle origin className={styles.icon} /> : <Arrival className={styles.icon} style={{ color: '#02c8c6' }} />}
            {t('dashbord.guide.trackPage')}
          </Row>
        ),
        imgSrc: trackImg,
        btnText: t('dashbord.guide.trackPage'),
        handleClick: handleToTrack,
        title: t('dashbord.guide.email'),
        content: t('dashbord.guide.helpInfo'),
        faqUrl: `https://support.oneship.io/hc/${
          LANG_MAP[i18n.language] ?? 'en-us'
        }/articles/4597554970511-%E8%BF%90%E5%8D%95%E8%BF%BD%E8%B8%AA%E8%AE%BE%E7%BD%AE-%E9%80%9A%E7%9F%A5`,
      },
    ]
  }, [t, handleToAddress, handleToCreateOrder, handleToTrack, i18n.language, guideInfo?.taskDetailResBos])
  const showNeverRemainBtn = useMemo(() => {
    const now = new Date().getTime()
    if (+guideInfo?.taskInitDate + 14 * 24 * 60 * 60 * 1000 < now) {
      return true
    }
    return false
  }, [guideInfo])
  return (
    <Card className={styles.cardBody}>
      <div className={styles.header}>
        <div className={styles.title}>
          <h1>{t('dashbord.guide.title')}</h1>
          {showNeverRemainBtn && (
            <Button onClick={showCancel} type="text">
              {t('dashbord.guide.neverRemain')}
            </Button>
          )}
        </div>
        <div className={styles.progress}>
          <span>
            {guideInfo?.process}/{guideInfo?.taskDetailResBos?.length || 0}
          </span>
          <Progress width={200} percent={(guideInfo?.process / guideInfo?.taskDetailResBos?.length) * 100} showInfo={false} />
        </div>
      </div>
      {/* <Divider dashed /> */}
      <div className={styles.content}>
        <Tabs tabPosition="left">
          {tabConfig.map((item) => {
            return (
              <TabPane tab={item.tab} key={item.key}>
                <PaneContent faqUrl={item.faqUrl} imgSrc={item.imgSrc} btnText={item.btnText} handleClick={item.handleClick} title={item.title} content={item.content} />
              </TabPane>
            )
          })}
        </Tabs>
      </div>
    </Card>
  )
}

GuideCard.displayName = 'GuideCard'
export default React.memo(GuideCard)
