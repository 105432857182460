import React, { useEffect } from 'react'
import { OneModal } from '@yy/one-ui'
import { useGiveawayTracker, useGetGiveawayDetail } from '../hook'
import { useBoolean, useRequest, useMount } from 'ahooks'
import { getIsGiveawayPopUp } from '@/services/apis'
import { useHistory } from 'react-router-dom'
import styles from './index.module.less'
import { GiveawayActivityStatus } from '@/services/apis/giveaway/type'
let hasShow = false
/** 优惠活动弹窗 */
export const GiveawayModal: React.FC = () => {
  const [visible, { setTrue, setFalse }] = useBoolean()

  const history = useHistory()

  const { trackGiveawayModalOpen, trackGiveawayClick } = useGiveawayTracker()

  const { modalPic, status, seq } = useGetGiveawayDetail()

  // 判断是否弹出
  const { data: isModalShow, run: checkOpenModal } = useRequest(getIsGiveawayPopUp, {
    formatResult: (res) => res.data,
  })

  useEffect(() => {
    if (isModalShow && status === GiveawayActivityStatus.NotParticipate && !hasShow && modalPic) {
      const img = new Image()
      img.src = modalPic
      img.onload = () => {
        setTrue()
        trackGiveawayModalOpen()
        hasShow = true
      }
    }
  }, [isModalShow, modalPic, setTrue, status, trackGiveawayModalOpen])

  useMount(() => {
    checkOpenModal()
  })

  const onClose = () => {
    setFalse()
  }

  const onClick = () => {
    trackGiveawayClick()
    history.push(`/questionnaire/${seq}`)
    setFalse()
  }

  return (
    <OneModal maskClosable={false} visible={visible} footer={null} wrapClassName={styles.modal} onCancel={onClose}>
      <img width={620} src={modalPic} alt="giveaway" onClick={onClick} />
    </OneModal>
  )
}
