import { getTime, set, subDays } from 'date-fns'

/**
 * 计算最近{day}天的时间范围
 * @param day 天数
 * @returns
 */
export function getRecentDay(day: number): [number, number] {
  const start = set(subDays(new Date(), Number(day) - 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
  const end = set(new Date(), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })
  return [getTime(start), getTime(end)]
}
