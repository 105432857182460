import type { ColProps } from 'antd'
import { Col, Form } from 'antd'
import React from 'react'
import { AreaLabel, useFormContext } from '@/bizComponent/AntdForm'
import Select2Input from '@/bizComponent/AntdForm/Select2Input'
import { useImperativeScope } from '@/components/Scope'
import type { AddressConfig } from '@/store/dictionarySlice/type'
import ProvinceSelect from './components/ProvinceSelect'
import type { IDefaultValue } from './hooks'
import { useHelpers, type UseHelpersParams } from './hooks'
import { EMPTY_RULES } from '../rules'
import { FromEmptyItem } from '../FromEmptyItem'

export { ProvinceSelect } from './components/ProvinceSelect'
export { useHelpers, type UseHelpersParams } from './hooks'

export interface AreaFormProps extends UseHelpersParams {
  disabledList?: string[]
  colProps?: ColProps
  /**
   * 区分发件地址和收件
   */
  areaType: 'departure' | 'arrival'
  /**
   * 国家地址
   */
  countryCode: string
  /**
   * 是否展示必填样式
   */
  showRequired?: boolean

  keyPrefix?: boolean

  isCustomRule?: boolean
  customConfig?: AddressConfig
}

export type AreaFormNameTypes = 'province' | 'city' | 'district' | 'town' | 'countryCode' | 'language'

export interface AreaFormAPIs {
  resetProvince: (province?: IDefaultValue) => void
  resetProvinceWithoutDebounce: (province?: IDefaultValue) => void
  getAreaCode: () => { [key in 'provinceCode' | 'cityCode' | 'districtCode' | 'townCode']: string }
  setFieldsValue: (data: Record<string, any>) => void
}

export const AreaForm: React.FC<AreaFormProps> = (props) => {
  const { disabledList = [], colProps = { span: 12 }, areaType, countryCode, showRequired = true, isCustomRule = false, customConfig = null } = props
  const { setFieldsValue } = useFormContext()

  const {
    config,
    provinces,
    getAreaCode,
    cities,
    districts,
    towns,
    resetProvinceWithoutDebounce,
    resetProvince,
    provinceChange,
    cityChange,
    districtChange,
    names,
    provinceLoading,
    childrenLoading,
    _t,
  } = useHelpers({
    areaType,
    countryCode,
    showRequired,
  })

  const { provinceName, cityName, districtName, townName } = names
  const { addressConfig } = config
  const { cityRequired, townRequired, districtRequired, provinceRequired } = isCustomRule ? customConfig || {} : config
  useImperativeScope(areaType === 'arrival' ? 'antd_ArrivalAreaFormAPIs' : 'antd_DepartureAreaFormAPIs', () => {
    return {
      resetProvinceWithoutDebounce,
      resetProvince,
      getAreaCode,
      setFieldsValue,
    }
  })

  return (
    <>
      {/* 省 */}
      <Col {...colProps} className={addressConfig.provinceVisibility ? '' : 'hidden'}>
        <Form.Item rules={EMPTY_RULES} label={<AreaLabel label={_t(countryCode, 'stateProvince')} required={provinceRequired} />} name={provinceName} required={provinceRequired}>
          <ProvinceSelect loading={provinceLoading} list={provinces} disabled={disabledList.includes(provinceName)} onChange={provinceChange} />
        </Form.Item>
      </Col>
      {/* 市 */}
      <Col {...colProps} className={addressConfig.cityVisibility ? '' : 'hidden'}>
        <Form.Item rules={EMPTY_RULES} label={<AreaLabel label={_t(countryCode, 'city')} required={cityRequired} />} name={cityName} required={cityRequired}>
          <Select2Input loading={childrenLoading} list={cities} disabled={disabledList.includes(cityName)} onChange={cityChange} />
        </Form.Item>
      </Col>
      {/* 区 */}
      <Col {...colProps} className={addressConfig.districtVisibility ? '' : 'hidden'}>
        <Form.Item rules={EMPTY_RULES} label={<AreaLabel label={_t(countryCode, 'district')} required={districtRequired} />} name={districtName} required={districtRequired}>
          <Select2Input loading={childrenLoading} list={districts} disabled={disabledList.includes(districtName)} onChange={districtChange} />
        </Form.Item>
      </Col>

      {/* TOWN */}
      <Col {...colProps} className={addressConfig.townVisibility ? '' : 'hidden'}>
        <Form.Item rules={EMPTY_RULES} label={<AreaLabel label={_t(countryCode, 'town')} required={townRequired} />} name={townName} required={townRequired}>
          <Select2Input loading={childrenLoading} list={towns} disabled={disabledList.includes(townName)} />
        </Form.Item>
      </Col>

      <FromEmptyItem name={names.language} noStyle className="hidden" />
    </>
  )
}

AreaForm.displayName = 'AreaForm'
