import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from '@/store'
import { addressConfigCreator } from '@/store/addressSlice'

/**
 * 本土化配置
 * @returns
 */
export function useLocalConfig() {
  const dispatch = useDispatch()

  /* 获取当前国家地址表单显示隐藏 */
  const getAddressVisible = useCallback(() => {
    dispatch(addressConfigCreator())
  }, [dispatch])

  return { getAddressVisible }
}

/**
 * 获取123级地址本土化翻译
 * @returns
 */
export function useAddressLocalLanage() {
  const { t } = useTranslation()

  const _t = (countryCode: string, key: 'stateProvince' | 'city' | 'district' | 'town') => {
    const defaultKeyMap = {
      stateProvince: 'order.create.stateProvince',
      city: 'order.create.city',
      district: 'order.create.district',
      town: 'order.create.town',
    }
    const localKey = `${countryCode}.local.${key}`
    const w = t(localKey)
    return w === localKey ? t(defaultKeyMap[key]) : w
  }
  return { _t }
}

export default useLocalConfig
