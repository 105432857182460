import API from '@/services/http'
import type * as Types from './type'

/**
 * 获取服务产品列表
 * @param payload
 * @returns
 */
export const getExpressList = (payload: Types.ExpressListPayload) =>
  API.admin.post<Types.ExpressListState>('/api/express/list', { pageSize: 999, queryDeliveryDate: true, ...payload })

export const getMultiCountryExpressList = (data: Types.MultiCountryParams) => API.toast.post<Types.ExpressListData[] | null>('/api/express/multi_country_list_all', data)

/**
 * 服务产品配置
 * @param payload
 * @returns
 */
export const queryExpressConfig = (payload: Types.ExpressTypeConfigListRequest) => {
  return API.admin.post<Types.ExpressConfigRes>('/api/express/config', payload)
}

/**
 * 查询服务产品是否存在子账号、服务申请审批状态
 * @param payload
 * @returns
 */
export const getExpressSubDetail = (payload: Types.ExpressSubAccountDetailRequest) => {
  return API.admin.post<Types.ExpressSubDetailRes>('/api/express/express_sub_detail', payload)
}

/**
 * **接口** [查询服务产品信息↗](http://api-doc-sl.inshopline.com/#/project/Java/74/feature%2F20230525_2585_specified_cancel_delivery/interface/dea66475c780484d3f271cd5ffa03649)
 * @请求头 `POST /api/express/simpleInfo`
 * @更新时间 `2023-05-11 10:39:56`
 */
export function postSimpleInfo(data?: Types.ExpressTypeSimpleListRequest) {
  return API.admin.post<Types.SimpleInfoRes>('/api/express/simpleInfo', data)
}

/**

 *
 * @param payload
 * @returns
 */
export const validatorExpressFields = (payload: Types.IValitorProviderProps) => {
  return API.admin.post<Types.IProviderValidatorResult>('/api/express/restrict/validate', payload)
}

/*
 * get guidance express list
 */
export const getGuideExpress = () => {
  return API.toast.post<Types.CompanyExpressListRes>('/api/express/company_express_list')
}

/**
 * get guidance express list
 */
export const changeExpressState = (payload: { companyCode: string; status: string }) => {
  return API.toast.post('/api/express/company_switch', payload)
}

export const saveExpressConfig = (payload: Types.SellerCompanyRequest) => {
  return API.toast.post('/api/express/company_express_config_save', payload)
}
