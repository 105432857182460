import { Form } from 'antd'
import React from 'react'
import type { FormItemProps } from 'antd/es/form'

/**
 * 空组件
 * @returns
 */
export const NullCom = () => null

/**
 * 占位用的空Form.Item，防止Warning
 * @returns
 */
export const FromEmptyItem = (props: FormItemProps<any>) => (
  <Form.Item noStyle {...props}>
    <NullCom />
  </Form.Item>
)
