import type * as Types from '@/types'

/** 揽货类型 */
type PickUpType = 1 | 2

export const PICKUP_TYPE: Types.Dict<{ value: PickUpType }> = [
  {
    label: 'order.batchCreate.dropoff',
    value: 2,
  },
  {
    label: 'order.batchCreate.pickup',
    value: 1,
  },
]

export const SHIPMENT_PICKUP_TYPE = {
  /**  上门取货 */
  pickUp: 1,
  /** 自邮 */
  dropOff: 2,
  /** 60分钟内揽收 */
  min60: 3,
}
