import { Card } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../../index.module.less'

const HeaderCard: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Card className={styles.teachingCard}>
      <div className={styles.stepsRow}>
        <div className={styles.steps}>
          <div className={styles.step}>STEP</div>
          <div className={styles.stepNum}>1</div>
        </div>
        <div>
          <div className={styles.title}>{t('dashboard.teaching.step1title')}</div>
          <div className={styles.dec}>{t('dashboard.teaching.step1dec')}</div>
        </div>
      </div>
      <div className={styles.stepsRow}>
        <div className={styles.steps}>
          <div className={styles.step}>STEP</div>
          <div className={styles.stepNum}>2</div>
        </div>
        <div>
          <div className={styles.title}>{t('dashboard.teaching.step2title')}</div>
          <div className={styles.dec}>{t('dashboard.teaching.step2dec')}</div>
        </div>
      </div>
      <div className={styles.stepsRow} style={{ border: 'none', padding: '0', margin: '0' }}>
        <div className={styles.steps}>
          <div className={styles.step}>STEP</div>
          <div className={styles.stepNum}>3</div>
        </div>
        <div>
          <div className={styles.title}>{t('dashboard.teaching.step3title')}</div>
          <div className={styles.dec}>{t('dashboard.teaching.step3dec')}</div>
        </div>
      </div>
    </Card>
  )
}

HeaderCard.displayName = 'HeaderCard'
export default React.memo(HeaderCard)
