import type { FormInstance } from 'antd'
import { useCallback } from 'react'
import { getAddress } from '@/services/apis'
import { useDispatch } from '@/store'
import { setProps } from '@/store/newServiceProviderSlice'
import { initSenderAddressForm } from '@/store/senderAddressSlice'
import { encodeLanguageAndProvince } from '@/utils/address'
import { pick } from 'lodash'

export function useSenderDefaultAddress({ form }: { form?: FormInstance }) {
  const dispatch = useDispatch(form)
  const initDefaultSenderAddress = useCallback(async () => {
    const res = await getAddress({ isDefault: true })
    const addressData = res.data

    if (addressData) {
      const departure_province = encodeLanguageAndProvince({
        languages: addressData.addressLanguages,
        province: addressData.province,
      })

      const formValues = {
        departure_countryCode: addressData.countryCode,
        departure_province,
        departure_fullName: addressData.name,
        departure_locationName: addressData.locationName,
        departure_email: addressData.email,
        departure_city: addressData.city,
        departure_district: addressData.district,
        departure_mobile: addressData.mobile || addressData.phone,
        departure_zipCode: addressData.zipCode,
        departure_address: addressData.address,
        departure_addressTwo: addressData.addressTwo,
        departure_addressNo: addressData.addressNo,
        departure_town: addressData.town,
      }
      form.setFieldsValue(formValues)

      //  初始化 sender form 地址 options
      await dispatch(
        initSenderAddressForm({
          formValues: pick(
            formValues,
            'departure_countryCode',
            'departure_province',
            'departure_city',
            'departure_district',
            'departure_town',
            'departure_zipCode',
            'departure_address',
            'departure_addressTwo',
            'departure_fullName',
            'departure_mobile'
          ),
        })
      )

      // 设置provider发货地址
      await dispatch(
        setProps({
          addressVO: {
            ...pick(
              formValues,
              'departure_countryCode',
              'departure_city',
              'departure_district',
              'departure_town',
              'departure_zipCode',
              'departure_address',
              'departure_addressTwo',
              'departure_fullName',
              'departure_mobile'
            ),
            departure_province: addressData.province,
          },
        })
      )

      return true
    }

    return false
  }, [form, dispatch])

  return {
    initDefaultSenderAddress,
  }
}
