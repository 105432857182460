import type { SelectProps } from 'antd'
import { Select } from 'antd'
import React from 'react'

export const SelectLoading: React.FC<SelectProps<any>> = (props) => {
  const { loading, children, ...restProps } = props

  // const elLoading = useMemo(() => {
  //   return (
  //     <Select.Option value="">
  //       <div
  //         style={{
  //           width: '100%',
  //           height: '80px',
  //           display: 'flex',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //         }}
  //       >
  //         <Spin />
  //       </div>
  //     </Select.Option>
  //   )
  // }, [])

  return (
    <Select loading={loading} {...restProps}>
      {children}
    </Select>
  )
}

SelectLoading.displayName = 'SelectLoading'
