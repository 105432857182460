import type { RouteConfig } from 'react-router-config'
import { Onboard } from '@yy/one-icon'
import DashboardPage from '@/containers/Dashboard'

const routes: RouteConfig[] = [
  {
    alias: 'dashboard',
    path: '/dashboard',
    component: DashboardPage,
    menuTitle: 'dashboard.title',
    group: 'shipments',
    icon: Onboard,
    permissionCode: 'dashboard',
  },
]

export default routes
