import Cookies from 'js-cookie'
import { pick } from 'lodash'
import { useCallback } from 'react'
import useRequest from '@ahooksjs/use-request'
import Api from '@/services/http'
import { useSelector } from '@/store'

export interface FetchABTestParams {
  /** APP KEY */
  appkey: string
  /** 实验 CODE */
  codes: string[]
  /** 项目版本 */
  appVersion?: string
  /** 实验纬度 */
  dimension: Record<string, string>
  /** 白名单 */
  whitelist?: Record<string, string>
}

export interface FetchABTestResponse {
  /** 结果码 */
  code: string
  /** 说明信息 */
  message: string
  /** 命中列表 */
  data: Record<string, { param: Record<string, string> }>
}

/** 稳定版本的参数值; 约定此值为稳定版 */
export const ABTEST_VERSION_STABLE_VALUE = 'v1'

/** ABtest参数名; 约定此值为ABtest标识 */
export const ABTEST_VERSION_KEY = 'AQM_VERSION'

/** ABtest code; 约定此值为ABtest code */
export const ABTEST_VERSION_CODE = process.env.AB_TEST_VERSION_EXPERIMENT

export const fetchABTest = async (params: FetchABTestParams) => {
  const { codes, dimension, whitelist, ...rest } = params
  const payload = Object.assign(rest, { codes, ...dimension, ...whitelist })
  const response = await Api.get<FetchABTestResponse>(`${process.env.AB_TEST_URL}/experiment`, { params: payload })
  const { code, data, message } = response.data
  if (code === 'SUCCESS') {
    return pick(data, codes)
  }

  return Promise.reject(new Error(message))
}

interface UseAppVersionDetecter {
  codes?: string | string[]
  sellerId?: string
}

export const useAppVersionDetecter = (params?: UseAppVersionDetecter) => {
  const uid = useSelector((state) => state.user.info?.uid)
  const { codes = typeof process.env.AB_TEST_VERSION_EXPERIMENT === 'string' ? process.env.AB_TEST_VERSION_EXPERIMENT.split(';') : [], sellerId = uid } = params || {}

  const resolveCodes = useCallback((codes: string | string[]) => {
    return Array.isArray(codes) ? codes : [codes]
  }, [])

  const request = useCallback(
    (params?: Partial<UseAppVersionDetecter>) => {
      const { sellerId: customSellerId, codes: customCodes } = params || {}

      return fetchABTest({
        appkey: process.env.AB_TEST_APP_KEY,
        codes: resolveCodes(customCodes || codes),
        dimension: {
          sellerId: customSellerId || sellerId,
        },
      })
    },
    [codes, sellerId, resolveCodes]
  )

  const { run } = useRequest(request, {
    manual: true,
    onSuccess(data) {
      const cookies = resolveCodes(codes).reduce((cookies, name) => {
        const { param } = data?.[name] || {}
        Object.assign(cookies, param)
        return cookies
      }, {})

      const currentAbtestVersion = Cookies.get(ABTEST_VERSION_KEY)
      Object.keys(cookies).forEach((key) => {
        const value = cookies[key]
        Cookies.set(key, value, { path: '/', expires: 10 })
      })
      // 该用户不是稳定版，需要刷新页面重新进入
      if (data?.[ABTEST_VERSION_CODE]?.param?.AQM_VERSION !== currentAbtestVersion) {
        window.location.reload()
      }
    },
  })

  return run
}
