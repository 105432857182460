import { waitStore } from '../storeUtils'
import type * as Types from './type'
import { fetchDictionaryByKey, queryDictionary, queryEfficientProvider } from '@/services/apis/dictionary'
import Api from '@/services/http'
import type { AppDispatch, AppGetState } from '@/store'
import { isCNWebsiteFn } from '@/utils/address'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'

const initialState: Types.DicsType = {
  providerDic: {},
  providerList: [], // ONESHIP发货地区支持的全部物流服务商
  immediateProviderList: [],
  departureDics: [],
  arrivalDics: [],
  templateImageLimitDic: {
    trackPropagandaImageLimit: 5,
    trackItemImageLilmit: 5,
    emailBannerImageimit: 5,
  },
  basicCurrencyList: [],
  countryMapDics: {}, // 国家币种映射字典
  countryDics: [],
  currencyDics: [],
  filterSenderCountries: [],
  filterArrivalCountries: [],
  senderCountryInfo: null,
  storeOrderStatusMap: null,
  callPickupMap: null,
  taxTypeList: [],
  /**可运货物属性 */
  cargoTypesDic: [],
  dictionaryMap: {},
}

export const dictinarySlice = createSlice({
  name: 'dictinary',
  initialState,
  reducers: {
    setProviderDic(state, action: PayloadAction<Types.DicsType['providerDic']>) {
      return {
        ...state,
        providerDic: action.payload,
      }
    },
    setProviderList(state, action: PayloadAction<Types.DicsType['providerList']>) {
      return {
        ...state,
        providerList: action.payload,
      }
    },
    setImmediateProviderList(state, action: PayloadAction<Types.DicsType['providerList']>) {
      return {
        ...state,
        immediateProviderList: action.payload,
      }
    },
    setTemplateImageLimitDic(state, action: PayloadAction<Types.DicsType['templateImageLimitDic']>) {
      return {
        ...state,
        templateImageLimitDic: action.payload,
      }
    },
    setCargoTypesDic(state, action: PayloadAction<Types.DicsType['cargoTypesDic']>) {
      return {
        ...state,
        cargoTypesDic: action.payload,
      }
    },
    setBasicCurrencyListDic(state, action: PayloadAction<Types.DicsType['basicCurrencyList']>) {
      return {
        ...state,
        basicCurrencyList: action.payload,
      }
    },
    setCountryDics(state, action: PayloadAction<Types.DicsType['departureDics']>) {
      const departureList = action.payload.filter(({ isDeparture }) => isDeparture) || []
      const arrivalList = action.payload.filter(({ isArrival }) => isArrival) || []
      const countryDics = action.payload || []

      const countryMapDics = {}
      countryDics.forEach((country) => {
        countryMapDics[country.countryCode] = country
      })
      return {
        ...state,
        departureDics: departureList,
        arrivalDics: arrivalList,
        countryDics,
        countryMapDics,
      }
    },

    setCurrencyDic(state, action: PayloadAction<Types.DicsType['currencyDics']>) {
      return {
        ...state,
        currencyDics: action.payload,
      }
    },
    setSenderCountryInfo(state, action: PayloadAction<Types.DicsType['senderCountryInfo']>) {
      return {
        ...state,
        /**
         * 当前发货国家
         */
        senderCountryInfo: action.payload,
      }
    },

    // 可选的发货国家
    setValidSenderCountry(state, action: PayloadAction<Types.DicsType['filterSenderCountries']>) {
      return {
        ...state,
        filterSenderCountries: action.payload,
      }
    },

    // 可选的收货国家
    setValidArrialCountry(state, action: PayloadAction<Types.DicsType['filterArrivalCountries']>) {
      return {
        ...state,
        filterArrivalCountries: action.payload,
      }
    },

    setStoreOrderStatus(state, action) {
      return {
        ...state,
        storeOrderStatusMap: action.payload,
      }
    },
    setCallPickupMap(state, action) {
      return {
        ...state,
        callPickupMap: action.payload,
      }
    },
    update(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    setDictionaryMap(state, action) {
      return {
        ...state,
        dictionaryMap: {
          ...state.dictionaryMap,
          [action.payload.key]: action.payload.value,
        },
      }
    },
  },
})

export const {
  setCountryDics,
  setProviderDic,
  setCurrencyDic,
  setValidSenderCountry,
  setValidArrialCountry,
  setSenderCountryInfo,
  setStoreOrderStatus,
  setProviderList,
  setImmediateProviderList,
  setTemplateImageLimitDic,
  setCargoTypesDic,
  setBasicCurrencyListDic,
  setCallPickupMap,
  update,
  setDictionaryMap,
} = dictinarySlice.actions

/** 查询服务商配置 */
export const queryProviderConfigList = () => async (dispatch: AppDispatch) => {
  const res = await queryDictionary()
  const { data } = res
  const { providerLogoRespVos } = data || {}
  if (providerLogoRespVos?.length) {
    const companyList = providerLogoRespVos.map(({ providerCode, logoUrl, providerName }) => ({
      name: providerCode,
      url: logoUrl,
      label: providerName,
    }))
    const formatCompanyList = companyList.reduce((obj, item) => {
      const key = item.name
      if (key) obj[key] = item
      return obj
    }, {})
    dispatch(setProviderDic(formatCompanyList))
    return formatCompanyList
  }
}

/** 查询ONESHIP发货地区支持的全部物流服务商 */
export const queryFullProviderConfigList = () => async (dispatch: AppDispatch) => {
  const res = await queryEfficientProvider()
  const { data } = res
  const { providerLogoRespVos } = data || {}
  if (providerLogoRespVos?.length) {
    dispatch(setProviderList(providerLogoRespVos))
    return providerLogoRespVos
  }
}

export const queryImmediateProviderConfigList = () => async (dispatch: AppDispatch) => {
  const res = await queryEfficientProvider({ logisticsType: 'ondemand' })
  const { data } = res
  const { providerLogoRespVos } = data || {}
  if (providerLogoRespVos?.length) {
    dispatch(setImmediateProviderList(providerLogoRespVos))
    return providerLogoRespVos
  }
}

export const featchDictionaryCountryList = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  const res = await fetchDictionaryByKey('COUNTRY_LIST')
  const { user } = getState()
  const countryCode = user?.info?.selfArea

  const { data } = res
  const { dictionaryInfos } = data || {}
  if (dictionaryInfos) {
    const list = dictionaryInfos.map(({ value }) => JSON.parse(value))
    const currencyList = list?.map((item) => item.currency) || []
    const senderCountryInfo = list?.find((item) => item.countryCode === countryCode)
    const finalCurrencyList = Array.from(new Set(currencyList))
    // 国家和币种按a-z排序
    dispatch(setCountryDics(list?.sort((a, b) => (a.countryName > b.countryName ? 1 : -1))))
    dispatch(setCurrencyDic(finalCurrencyList.sort()))
    /** 用户所属国家对应的配置信息 */
    senderCountryInfo && dispatch(setSenderCountryInfo(senderCountryInfo))
    return list
  }
}

// 自定义模板图片限制走字典配置
export const featchDictionaryCustomTemplateImageLimit = () => async (dispatch: AppDispatch) => {
  const res = await fetchDictionaryByKey('CUSTOM_TEMPLATE_IMAGE_LIMIT')
  if (res?.data?.dictionaryInfos?.[0]?.value) {
    dispatch(setTemplateImageLimitDic(JSON.parse(res?.data?.dictionaryInfos?.[0]?.value)))
  }
}

// 自定义模板图片限制走字典配置
export const featchDictionaryBasicCurrencyList = () => async (dispatch: AppDispatch) => {
  const res = await fetchDictionaryByKey('BASIC_CURRENCY_LIST')
  if (res?.data?.dictionaryInfos) {
    dispatch(setBasicCurrencyListDic(res?.data?.dictionaryInfos))
  }
}

/**
 * 获取用户注册国家支持的收货国家
 */
export const fetchFilterCountry = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  const isCn = isCNWebsiteFn()
  const res = await Api.admin.post<Types.ResCountry>('/api/express/auth_country', {
    filterByUserCountry: !isCn,
  })

  await waitStore((s) => s.dictionary.countryMapDics !== initialState.countryMapDics)

  const { dictionary } = getState()
  const { senderCountryList, consigneeCountryList } = res?.data || {}

  const filterSenderCountries = []
  const filterArrivalCountries = []

  senderCountryList?.forEach((item) => {
    const { countryCode, language = '' } = item || {}
    const data = dictionary?.countryMapDics?.[countryCode]
    data && filterSenderCountries.push({ ...data, language })
  })

  consigneeCountryList?.forEach((item) => {
    const { countryCode, language = '' } = item || {}
    const data = dictionary?.countryMapDics?.[countryCode]
    data && filterArrivalCountries.push({ ...data, language })
  })

  // 根据国家countryName A-Z排序
  if (filterSenderCountries && filterSenderCountries.length) {
    filterSenderCountries.sort((a, b) => (a.countryName > b.countryName ? 1 : -1))
  }

  if (filterArrivalCountries && filterArrivalCountries.length) {
    filterArrivalCountries.sort((a, b) => (a.countryName > b.countryName ? 1 : -1))
  }

  dispatch(setValidSenderCountry(filterSenderCountries))
  dispatch(setValidArrialCountry(filterArrivalCountries))
}

/** 获取服务产品编码对应的服务名称 */
export const fetchProviderName = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  try {
    const res = await Api.admin.post('/api/dictionary_query/providers')
    const { providerInfoResponseList } = res.data
    const providerNameDic = {}
    providerInfoResponseList.forEach((item) => {
      const key = item.companyCode
      if (key) {
        providerNameDic[key] = item?.companyName || ''
      }
    })
    const { providerDic } = getState().dictionary
    const cloneProviderDic = cloneDeep(providerDic)
    Object.keys(cloneProviderDic).forEach((item) => {
      cloneProviderDic[item]['label'] = providerNameDic?.[item]
    })
    dispatch(setProviderDic(cloneProviderDic))
    return Promise.resolve(providerInfoResponseList)
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * 查询平台的拉单和归档状态可选列表
 */
export const featchOrderStatusList = () => async (dispatch: AppDispatch) => {
  const res = await fetchDictionaryByKey('STORE_ORDER_STATUS_LIST')
  const { data } = res
  const { dictionaryInfos } = data || {}
  if (dictionaryInfos) {
    const list = dictionaryInfos.map(({ value, key }) => ({ value: JSON.parse(value), key }))
    const status = list.reduce((sum, cur) => {
      const key = cur.key
      sum[key] = cur.value
      return sum
    }, {})

    dispatch(setStoreOrderStatus(status))
    return status
  }
}

/** 呼叫揽收服务商配置 start */
const addressInfoList = ['mobile', 'address', 'zipCode', 'fullName', 'addressTwo']
const areaList = ['province', 'city', 'district', 'town']
export const fetchCallPickupMap = () => async (dispatch: AppDispatch) => {
  const res = await fetchDictionaryByKey('ONESHIP_PICK_UP_CALL')
  const providerCallPickupMap = res.data.dictionaryInfos.reduce((sum, cur) => {
    const key = cur.key
    const value = JSON.parse(cur.value)
    const display = value?.display?.map((item) => (addressInfoList.includes(item) ? `departure_${item}` : item))
    const required = value?.required?.map((item) => (addressInfoList.includes(item) ? `departure_${item}` : item))
    const areaRequired = areaList.reduce((sum, cur) => {
      sum[`${cur}Required`] = value.required?.includes(cur)
      return sum
    }, {})
    sum[key] = {
      display,
      required,
      addressConfig: areaRequired,
    }
    return sum
  }, {})
  dispatch(setCallPickupMap(providerCallPickupMap))
  return providerCallPickupMap
} /** 呼叫揽收服务商配置 end */

export const featchDictionaryTaxTypeList = () => async (dispatch: AppDispatch) => {
  const res = await fetchDictionaryByKey('TAX_NO_TYPE_LIST')

  const { data } = res
  const { dictionaryInfos } = data || {}
  const taxTypeList = dictionaryInfos.map((item) => {
    return {
      value: item.value,
      key: item.key,
    }
  })

  dispatch(
    update({
      taxTypeList,
    })
  )
}

// 可运货物属性字典配置
export const featchDictionaryLmsCargoTypes = () => async (dispatch: AppDispatch) => {
  const res = await fetchDictionaryByKey('LMS_CARGO_TYPES')
  if (res?.data?.dictionaryInfos?.[0]?.value) {
    dispatch(setCargoTypesDic(JSON.parse(res?.data?.dictionaryInfos?.[0]?.value)))
  }
}

// 加载指定字典
export const fetchAndStoreDictionaryByKey = (key) => async (dispatch: AppDispatch) => {
  const res = await fetchDictionaryByKey(key)
  if (res?.data?.dictionaryInfos?.length > 0) {
    dispatch(
      setDictionaryMap({
        key,
        value: res?.data?.dictionaryInfos,
      })
    )
  }
}

export default dictinarySlice.reducer
