export interface GetGiveawayReq {
  /**优惠规则seq */
  discountRuleSeq?: string
  /**问卷调查数据 */
  data?: string
}

export enum TimeType {
  /** 固定周期 */
  FIXED = 1,
  /** 相对用户注册时间的周期：[注册时间，注册时间+倒计时] */
  RELATIVE = 2,
}

interface FeatureGiveawayLangDTO {
  /**语言 */
  language?: string
  /**h5图片 */
  imageUrlH5?: string
  /**pc图片 */
  imageUrlPc?: string
  /**是否默认 */
  isDefault?: boolean
}

interface FeatureGiveawayDTO {
  /**图片配置 */
  languageList?: FeatureGiveawayLangDTO[]
}

interface SellerDiscountFeatureDTO {
  /**字典表键 */
  key?: string
  /**字典表名称 */
  name?: string
  /**钱包赠送功能 */
  giveaway?: FeatureGiveawayDTO
}

interface DiscountTopupQtyDTO {
  /**国家/地区代码 */
  countryCode?: string
  /** 数量 */
  qty?: number
  /** 数量单位 */
  qtyUnit?: string
}

interface DiscountTopupDTO {
  /**地区优惠列表 */
  qtyList?: DiscountTopupQtyDTO[]
  /**有效天数 */
  effectiveDay?: number
}

interface SellerDiscountDetailDTO {
  /**字典表键 */
  key?: string
  /**字典表名称 */
  name?: string
  /**地区优惠 */
  topup?: DiscountTopupDTO
}

export enum GiveawayActivityStatus {
  /**未参与 */
  NotParticipate = 0,
  /**已领取 */
  Received = 1,
}
export interface GiveawayActivityRes {
  /**优惠规则seq */
  seq?: string
  /**规则key */
  ruleKey?: string
  /**规则名称 */
  ruleName?: string
  /**优惠描述 */
  ruleRemark?: string
  /**规则地区 */
  countryCodes?: string[]
  /**优惠规则支持的发放类型字典Key */
  discountKeys?: string[]
  /**发放具体规则 */
  discountDetails?: SellerDiscountDetailDTO[]
  /**触发优惠规则的功能字典Key */
  featureKeys?: string[]
  /**功能具体内容 */
  featureDetails?: SellerDiscountFeatureDTO[]
  /** 活动有效期类型 */
  timeType?: TimeType
  /** 规则有效开始时间 */
  effectiveStartTime?: string
  /** 规则有效结束时间 */
  effectiveEndTime?: string
  /** 活动有效倒计时，timeType为2时有效 */
  countDown?: number
  /** 参与开始时间， timeType为2时有效 */
  joinStartTime?: string
  /** 参与结束时间， timeType为2时有效 */
  joinEndTime?: string
  /**活动状态 活动状态 0.未参与 1.已领取 */
  status?: GiveawayActivityStatus
  /** 余额是否已赠送给商家 */
  giveawayForSeller?: boolean
  /** 余额有效期 */
  expireDate?: number
}
