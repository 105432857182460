/**
 * 浏览器本地页码
 */

export const GET_DEFAULT_PAGE_SIZE = () => {
  const localPageSize = window.localStorage.getItem('os-userPageSize')
  return localPageSize ? +localPageSize : 25
}

export const DEFAULT_PAGE_SIZE_OPTIONS = ['10', '25', '50', '100', '200']
